import React from 'react';
import {connect} from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import DateTimeSelection from 'Controls/DateTimeSelection';
import ChartHistories from 'BmsView/Customer/Histories/Chart';
import { getHistoryData } from 'BmsView/Customer/Histories/Actions';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import { withBMS  } from 'BmsView/Context/BMS';
import { getStartOfWeek } from 'Utils/Date';
import { withAreaCustomer } from '../../Hooks/useAreaCustomer';



class Trending extends React.Component {
    constructor(props) {
        super(props);

        let point = this.props.selectedpoint.get('pointconfiguration');

        let pointselected = Immutable.Map({
            'name': point.get('m_pointName'),
            'address': this.props.address.join('-'), 
            'engunits': [point.get('m_stateText').get(0)],
            'colour': 'blue'
        });

        let selectedpoints = Immutable.List([pointselected]);
 
        this.state = {
            selectedpoints: selectedpoints,
            records: [],
            dateTimeRange: {
                rangeType: 'This Week',
                startDate: getStartOfWeek(),
                endDate: new Date()
            }
        }
    }

    componentDidMount() {
       this.getHistory();
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    getHistory() {
        let {customer} = this.props;
        let {selectedpoints, dateTimeRange}= this.state; 
        let points = [];

        selectedpoints.map((point) => {
            points.push(point.get('address'));
        });

        let dateStartToMongo = new Date(dateTimeRange.startDate);
        dateStartToMongo.setHours(dateStartToMongo.getHours() - 1);

        var queryObject = {
            addrstring: { $in: points},
            datestring: { $gte: `${dateStartToMongo.getFullYear()}-${("0" + (dateStartToMongo.getMonth() + 1)).slice(-2)}-${("0" + dateStartToMongo.getDate()).slice(-2)}`,
             $lte: `${dateTimeRange.endDate.getFullYear()}-${("0" + (dateTimeRange.endDate.getMonth() + 1)).slice(-2)}-${("0" + dateTimeRange.endDate.getDate()).slice(-2)}` }
        }

        getHistoryData(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`,
            queryObject).then((data) => {
                this.setState({
                    records: (data || []).map((record) => {
                        return {
                            name: record.addrstring,
                            time: record.datestring,
                            samples: record.sample
                        }
                    })
                });
            })
    }

    setDateTimeRange(dateTimeRange) {
        this.setState({
            dateTimeRange: dateTimeRange
        }, this.getHistory);
    }

    render() {
        let {records, selectedpoints, dateTimeRange} = this.state;

        return (
            <div >       
                <div className="flex flex-row mt-4"> 
                    <DateTimeSelection dateTimeRange={dateTimeRange} updateDateTimeRange={this.setDateTimeRange.bind(this)}/>              
                </div>
                <div style={{ width: "100%", height: "500px" }}>
                    <ChartHistories records={records} dateSelectionType={dateTimeRange.rangeType} dateStart={dateTimeRange.startDate} dateEnd={dateTimeRange.endDate} selectedpoints={selectedpoints}/>
                </div>
            </div>    
        );
    }
}

// export default connect((state) => ({
//     customer: state.get('customer')
// }))(Trending);

// export default withBMS(Trending);
export default withAreaCustomer(Trending);
