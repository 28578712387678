import * as d3 from 'd3';
import { scaleDivergingPow } from 'd3';
import Immutable from 'immutable';
import { node, element } from 'prop-types';
import { DragPreviewImage } from 'react-dnd';

export const viewCache = {
    xml: {}
}

// export const loadedElements = {
//     elements: []
// }

window.viewCacheObject = viewCache;
// window.loadedElements = loadedElements;

async function* _d3xml (path) {
    // return new Promise((resolve, reject) => {
        
        let yieldThis = undefined;
        if(viewCache.xml[path]) {
            yieldThis =  viewCache.xml[path].cloneNode(true);
        } else {
            try {
                let xml = await d3.xml(path);
                viewCache.xml[path] = xml;
                yieldThis = xml.cloneNode(true);
            } catch(e) {
                yieldThis = e;
            }
            

        }
        yield yieldThis;

    // });
}



function d3xml(path) {
    return new Promise((resolve, reject) => {
        let xmlReturnGenerator = _d3xml(path);
        let xmlReturn = xmlReturnGenerator.next();
        let secondReturn = xmlReturnGenerator.next();
        // console.log("%cXMLRETURN IS THIS", "padding:20px;");
        // console.log(xmlReturn);
        // console.log("secondReturn");
        // console.log(secondReturn);
        xmlReturn.then((data) => {
            // console.log("this is what we have");
            // console.log(data);
            if(data.value instanceof Error) {
                reject(data.value);
            } else {
                resolve(data.value);
            }
            
        })
        
        // xmlReturn.reject(reject);
        return xmlReturn;
    })
    
    
}

// function* d3xmlSync(path) {

//     let _localData = undefined;
//     let _localError = undefined;
//     console.log("starting yielder");
//     d3xml(path).then((data) => {
//         console.log("finisehd promise for d3sync");
//         _localData = data;
//     }).catch((err) => {
//         console.log("error in yielder");
//         _localError = err;
//     });

//     let yielder = function* () {
//         while(!_localData && !_localError) {
//             yield false
//         }
//         yield true;
//     }

//     while(!yielder().next().value) {

//     }

//     console.log("finisehd yielder ")

    
//     yield _localData;
//     yield _localError;
    

// }

var setMenu = (element, options) => {
    
    // loadedElements.elements.push(element);
    
    
    if (options.editmode && options.dragBehaviour) {
        element.on('contextmenu', (evt) => {
            options.showContextMenu(evt, options.node, options.index);
        }).call(options.dragBehaviour);

    }
    else {
        element.on('click', (evt) => {
            options.onNodeConfiguration(options.index, options.node, options);
        }).on(".drag", null);
    }
}

var setMouseEvents = (element, options) => {
    element.on("mouseover", function(e) { 
        d3.select(this).style("opacity", "0.75");
      })
      .on("mouseout", function(e) { 
        d3.select(this).style("opacity", "1");
      })
}

var drawText = (element, node, text, getCustomerSettings, getUserSettings) => {

    let fontsize = 16;
    if(getCustomerSettings) {
        fontsize = getCustomerSettings('defaultFallbackFontSize') || fontsize;
    }
    if(getUserSettings) {
        fontsize = getUserSettings('defaultFallbackFontSize') || fontsize;
    }
   
    
    fontsize = node.getIn(['configuration', 'style', 'fontsize']) || fontsize;


    let newnode = element.append('text')
        .text(text)
        .attr('fill', node.getIn(['configuration', 'style', 'color'], 'black'))
        .attr('font-size', fontsize + 'px')
        .attr('font-style', node.getIn(['configuration', 'style', 'fontstyle'], 'normal'))
        .attr('font-weight', node.getIn(['configuration', 'style', 'fontstyle'], 'normal'))
        .attr('text-decoration', `${node.getIn(['configuration', 'underline']) ? 'underline' : 'none'}`)
    return newnode;
}


var drawTextBox = (element, textnode, node, getCustomerSettings, getUserSettings) => {
    var textBox = undefined;
    let displayStyle = node.getIn(['configuration', 'displaystyle']); 
  
    if ( displayStyle == 'boxed' || displayStyle == 'striped' || displayStyle == 'button'){
        
        // current font setting, fallback font size
        // add font size, will only be applied at creation of text as a default filled in value

        // make both configurable, in user, customer and component settings, also make a fallback
        let boxYOffset = 8;
        let boxXOffset = 8;

        if(getCustomerSettings) {
            boxYOffset = Number(getCustomerSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getCustomerSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        if(getUserSettings) {
            boxYOffset = Number(getUserSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getUserSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        boxYOffset = Number(node.getIn(['configuration','style','boxYOffset']) || boxYOffset);
        boxXOffset = Number(node.getIn(['configuration','style','boxXOffset']) || boxXOffset);

        let box = textnode.node().getBBox();
        let width = node.getIn(['configuration', 'width'], "");
        if (width.length > 0) {
            box.width = width;
        }

        textBox = element.insert('rect', 'text')
            .attr('x', box.x - boxXOffset)
            .attr('y', box.y - boxYOffset / 2)
            .attr('width', box.width + boxXOffset * 2)
            .attr('height', box.height + boxYOffset)
            .attr('fill', node.getIn(['configuration', 'style', 'background-color'], node.getIn(['component', 'defaultStyle', 'background-color'], '#ffffff')))
            .attr('stroke', node.getIn(['configuration', 'style', 'border-color'], '#000'));

        if ( displayStyle == 'button') textBox.attr('rx', 5); else textBox.attr('rx', node.getIn(['configuration', 'rx'], 0));
/*
            textBox.append('animate')
            .attr('attributeName', 'rx')
            .attr('values', '0;40;0')
            .attr('dur','10s')
            .attr('repeatCount', 'indefinite')  */
    }  

    return textBox;
}

var drawTextBoxStripe = (element, textnode, node, getCustomerSettings, getUserSettings) => {
    var stripe = undefined;
    let displayStyle = node.getIn(['configuration', 'displaystyle']); 
  
    if ( displayStyle == 'striped' ){
        let boxYOffset = 8;
        let boxXOffset = 8;

        if(getCustomerSettings) {
            boxYOffset = Number(getCustomerSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getCustomerSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        if(getUserSettings) {
            boxYOffset = Number(getUserSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getUserSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        let box = textnode.node().getBBox();
        let width = node.getIn(['configuration', 'width'], "");
        if (width.length > 0) {
            box.width = width;
        }

        let stripeYSize = 6;

        stripe = element.insert('rect', 'text')
            .attr('id', 'stripe')
            .attr('x', box.x - boxXOffset + 1) 
            .attr('y', box.y - boxYOffset / 2) 
            .attr('width', box.width + boxXOffset * 2 - 2)
            .attr('height', stripeYSize)
            .attr('fill', node.getIn(['configuration', 'style', 'top-border-color'], '#ffff00'));    
    }
    return stripe;
}

var drawLine = (element, x1, y1, x2, y2, strokecolour, strokewidth ) => {
    let newnode = element.append('line')
    .attr('x1', x1)
    .attr('y1', y1)
    .attr('x2', x2)
    .attr('y2', y2)
    .attr('stroke', strokecolour)
    .attr('stroke-width', strokewidth)

    return newnode;
}

const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')

var displayImage = (options, image, appendTo) => {
    let element = appendTo
        .append('g')
        .attr('data-index', options.index)
        .html(image.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `matrix(${options.node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${options.node.getIn(['configuration', 'scale'], 100.0) / 100},${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])})rotate(${options.node.getIn(['configuration', 'rotation'], 0)})`)

    if (options.node.getIn(['component', 'stateids'])) {    
        let states = options.node.getIn(['component', 'stateids']);

        states.forEach((state) => {
            if(!state.get('type')) element.select('#' + state.get('target')).style('display', 'none');
        //    if(state.get('type') == 'fill') element.select('#' + state.get('target')).style('display', 'block');
        });
    }

    if ( options.node.getIn(['configuration', 'value']) ) {
        let value = options.node.getIn(['configuration', 'value']);
        let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);
 
        options.node.getIn(['component', 'styles'], []).map((style) => {
            switch (style.get('type')) {
                case 'color' :
                    element.select('#' + style.get('target'))
                    .style(style.get('style'), value);
                    break;  
                    
                case 'calc' :
                    let r = parseInt(rgb[1], 16) + parseInt(style.get('rgb').get(0));
                    let g = parseInt(rgb[2], 16) + parseInt(style.get('rgb').get(1));
                    let b = parseInt(rgb[3], 16) + parseInt(style.get('rgb').get(2));

                    let rgbhex = rgbToHex(r, g, b);


                        element.select('#' + style.get('target'))
                        .style(style.get('style'), rgbhex);
                        break;       
            }

        })
    }  
    

    
    /*
    && options.node.getIn(['component', 'styles']).get(0).get('target') &&
            options.node.get('configuration')) { 

                    let test = options.node.getIn(['configuration', 'value'], '#212121ff');


                    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(test);

                    let r = parseInt(result[1], 16);
                    let g = parseInt(result[2], 16);
                    let b = parseInt(result[3], 16);
                




        element.select('#' + options.node.getIn(['component', 'styles']).get(0).get('target'))
        .style(options.node.getIn(['component', 'styles']).get(0).get('style'), options.node.getIn(['configuration', 'value'], '#212121ff'));
    }*/

    return element;
}

var drawTspan = (textnode, options) => {
    options.node.getIn(['configuration', 'value'], 'Text Area').split('\n').forEach((line) => {
        textnode.append('tspan')
        .attr('x', 0)
        .attr('dy', options.node.getIn(['configuration', 'style', 'fontsize'], '16') + 'px')
        .text(line)
    });
}    

var drawButton = (element, textnode, options) => {
    let boxYOffset = 8;
    let boxXOffset = 8;

    let box = textnode.node().getBBox();
    let width = options.node.getIn(['configuration', 'width'], "");
    if (width.length > 0) {
        box.width = width;
    }

    element.insert('rect', 'text')
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('x', box.x - boxXOffset)
        .attr('y', box.y - boxYOffset / 2)
        .attr('width', box.width + boxXOffset * 2)
        .attr('height', box.height + boxYOffset)
        .attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#efe'))
        .attr('stroke', options.node.getIn(['configuration', 'style', 'border-color'], '#000'));
}

var displayTooltip = (element, options) => {
    if (options.node.getIn(['configuration','istooltip']) && options.node.getIn(['configuration', 'tooltip'])) {
        element.on('mouseover', (evt) => {
            options.tooltip.text(options.node.getIn(['configuration', 'tooltip']));
            return options.tooltip.style('visibility','visible');
        });
        
        element.on('mousemove', (evt) => {
             return options.tooltip.style('top', (evt.pageY-10)+'px').style('left',(evt.pageX+10)+'px').style('background','white');
        })
        
        element.on('mouseleave', (evt) => {
            return options.tooltip.style('visibility', 'hidden');
        });

        return true;
    } 
}


var setStateids = (element, options, value, oldvalue) => {
    if (options.node.getIn(['component', 'stateids'])) {
        if (value != oldvalue) {
            oldvalue = value;
            let rules = options.node.getIn(['configuration', 'rules']);

            if (rules) {
                let states = options.node.getIn(['component', 'stateids']);

                states.forEach((state) => {
                    if (!state.get('type')) element.select('#' + state.get('target')).style('display', 'none');
                //    element.select('#' + state.get('target')).style('display', 'none');
                });

                

                rules.forEach((rule) => {
                    let activated = false;
                    let matchvalue = rule.get('matchvalue');
                    if (rule.get('condition') == '$nin' || rule.get('condition') == '$in' || rule.get('condition') == '$range') {
                        matchvalue = matchvalue.split(rule.get('condition') == '$range' ? '-' : ',').map((val) => { return parseInt(val) });
                    }
                    else {
                        matchvalue = parseInt(matchvalue);
                    }

                    if (rule.get('condition') == '$in' && matchvalue.indexOf(value) >= 0) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$nin' && matchvalue.indexOf(value) < 0) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$eq' && matchvalue == value) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$neq' && matchvalue != value) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$range' && value >= matchvalue[0] && value <= matchvalue[1]) {
                        activated = true;
                    }

                    if (rule.get('action') == 'show' && activated) {
                        element.select('#' + rule.get('target')).style('display', 'block');
                    }

                    if (rule.get('action') == 'fill' && activated) {
                        element.select('#' + rule.get('target')).style('fill', rule.get('fill'));  
                    //    element.select('#' + rule.get('target')).style('display', 'block');                  
                    }
                });
            } else {
                console.log('Warning - Rules not defined for component :', options.node.getIn(['component', 'name']));
            }
        }    
    }

    return oldvalue;
}

export function Image(options, path) {

    // console.log("d3XML TEST ON YIELD SO LETS SEE FIRST YIELD AND SECOND YIELD");
    // let thePromise = d3xmlSync(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).next();
    // console.log(thePromise);


    
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index)

        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);
            
            let placementElement = displayImage(options, image, appendTo);
            setMenu(placementElement, options);
            let tooltip = displayTooltip(placementElement, options);

            if ( options.node.getIn(['configuration', 'islink'])) placementElement.style("cursor", "hand");

            if (options.node.getIn(['component', 'stateids'])) {
                if(options.editmode) {
                    let states = options.node.getIn(['component', 'stateids']);

                    states.forEach((state) => {
                        placementElement.select('#' + state.get('target')).style('display', 'block');
                    });   
                } 
            }   

            let oldvalue = -1;

            resolve({
                setValue: (point) => {
                    options.node = options.node.setIn(['pointconfiguration'], point);
                    oldvalue = setStateids(placementElement, options, point.m_value, oldvalue);
                },
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement, options);

                    if (options.node.getIn(['component', 'stateids'])) {
                        if(editmode) {
                            let states = options.node.getIn(['component', 'stateids']);

                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        } else {
                            setStateids(placementElement, options, oldvalue, -1);
                        }
                    }    
                },
                redrawElement: (node) => {
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.remove();
                    image && image.remove();
                    options.node = node;
                    image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);
                    placementElement = displayImage(options, image, appendTo);
                    setMenu(placementElement, options);

                    tooltip = displayTooltip(placementElement, options);

                    if(options.editmode) {
                        if (options.node.getIn(['component', 'stateids'])) {
                            let states = options.node.getIn(['component', 'stateids']);

                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        }
                    } 
                    oldvalue = -1;
                },
                remove: () => {
                    placementElement.remove();
                    image && image.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        }).catch(reject);
    });
}

// SystemObject => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
export function SystemObject(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = displayImage(options, image, appendTo);
            setMenu(placementElement, options);
            let tooltip = displayTooltip(placementElement, options);

            placementElement.style("cursor", "hand");

            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement, options);
                },
                redrawElement: (node) => {
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.remove();
                    options.node = node;
                    placementElement = displayImage(options, image, appendTo);
                    setMenu(placementElement, options);
                    tooltip = displayTooltip(placementElement, options);
                    placementElement.style("cursor", "hand");
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}

function blink(textNode) {
    let animate = textNode.append('animate')
    .attr('attributeName', 'display')
    .attr('attributeType', 'CSS')
    .attr('keyTimes', '0;0.5;1')
    .attr('values', 'inline;none;none')
    .attr('dur','1s')
    .attr('repeatCount', 'indefinite')   
    
    return animate;
}

export function Text(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
        
        let scale = options.node.getIn(['configuration','scale'], 100.0) / 100;
        let rotation = options.node.getIn(['configuration','rotation'], 0);
        
        let createPlacement = (options) => appendTo.append('g').attr('data-index', options.index)
            // .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)
            .attr('transform', `matrix(${scale},0,0,${scale},${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`);
        let placementElement = createPlacement(options);

        let textNode = drawText(placementElement, options.node, options.node.getIn(['configuration', 'value'], 'polling'), options.getCustomerSettings, options.getUserSettings);
        let textBox = drawTextBox(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

        let textBoxStripe = drawTextBoxStripe(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);
        setMenu(placementElement, options);

        if (options.node.getIn(['configuration', 'displaystyle']) == 'button') {
            setMouseEvents(placementElement, options);
            placementElement.style("cursor", "hand");
        }    

        let tooltip = displayTooltip(placementElement, options);

        if ( options.node.getIn(['configuration', 'islink'])) placementElement.style("cursor", "hand");
        
        var animate;
        let rules = (options.node.get('configuration')) ? options.node.getIn(['configuration', 'rules']) : undefined;
             
        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                textNode.remove();

                let texttodisplay = point.m_formattedValueText ? point.m_formattedValueText : 'Error #' + point.m_errorNumber;
 
                let rule = (rules || Immutable.List([])).filter((rule) => {
                        return (rule.get('matchvalue') == point.m_value);
                    }).first();

                if (rule && (rule.get('action') == 'hide')) return;

                if (rule && rule.get('displaytext')) texttodisplay = rule.get('displaytext');
                
                textNode = drawText(placementElement, options.node, texttodisplay, options.getCustomerSettings, options.getUserSettings);

                if (textBox) textBox.remove();
                textBox = drawTextBox(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

                if (textBoxStripe) textBoxStripe.remove();
                textBoxStripe = drawTextBoxStripe(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

                if(rule && (rule.get('action') == 'flashing')) {
                    animate = blink(placementElement);
                }else {
                    if (animate) animate.remove();
                } 
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);

                if (animate) animate.remove();
                //textNode = drawText(placementElement, options.node, 'polling');
            },
            redrawElement: (node) => {
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                textNode.remove();
                placementElement.remove();

                 
                options.node = node;

                placementElement = createPlacement(options);

                setMenu(placementElement, options);
                
                let textToDisplay = options.node.getIn(['configuration', 'value'], 'polling');
                let point = options.pointparams;
                
                if (point && point.m_formattedValueText) textToDisplay = point.m_formattedValueText;
                textNode = drawText(placementElement, options.node, textToDisplay, options.getCustomerSettings, options.getUserSettings); 

                if (textBox) textBox.remove();
                textBox = drawTextBox(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

                if (textBoxStripe) textBoxStripe.remove();
                textBoxStripe = drawTextBoxStripe(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);  

                if (options.node.getIn(['configuration', 'displaystyle']) == 'button') {
                    setMouseEvents(placementElement, options);
                    placementElement.style("cursor", "hand");
                } 


                tooltip = displayTooltip(placementElement, options);
            },
            remove: () => {
                appendTo && appendTo.remove();
                placementElement.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}

export function TextMultiline(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacementElement(options);
            
        setMenu(placementElement, options);

        let textNode = drawText(placementElement, options.node, null, options.getCustomerSettings, options.getUserSettings);
        drawTspan(textNode, options); 
        displayTooltip(placementElement, options); 

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                textNode.text(point.m_formattedValueText);
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            redrawElement: (node) => {
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                textNode.remove();
                placementElement.remove();
                options.node = node;
                placementElement = createPlacementElement(options);

                setMenu(placementElement, options);

                textNode = drawText(placementElement, options.node, null, options.getCustomerSettings, options.getUserSettings);
                displayTooltip(placementElement, options); 
            //    drawTextBox(placementElement, textNode, options);
                drawTspan(textNode, options);  
            },
            remove: () => {
                placementElement.remove();
                appendTo && appendTo.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


// PointList => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`<=== ONLY IF options.node.getIn(['component', 'name']) != 'pointlist'
export function PointList(options, path) {
    return new Promise((resolve, reject) => {
        var pointListNode;
        var placementElement;

        var createPlacementElement;
        var image;
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);

        if ( options.node.getIn(['component', 'name']) != 'pointlist' ){
            d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
                image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);  
                placementElement = displayImage(options, image, appendTo);
            })
        } else {
            createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)
        
            placementElement = createPlacementElement(options);
            pointListNode = drawText(placementElement, options.node, options.node.getIn(['configuration', 'value'], 'Point List'), options.getCustomerSettings, options.getUserSettings);
            drawButton(placementElement, pointListNode, options);
            placementElement.style("cursor", "hand");
        }

        setMenu(placementElement, options);
        displayTooltip(placementElement, options);

        options.pointparams = {};    

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams[point.address] = point;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            redrawElement: (node) => {
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                options.node = node;
                if ( options.node.getIn(['component', 'name']) != 'pointlist' ){
                    placementElement.remove();
                    if(image) {
                        placementElement = displayImage(options, image, appendTo);
                    }
                }
                else {
                    pointListNode.remove();
                    placementElement.remove();
                    placementElement = createPlacementElement(options);
                    pointListNode = drawText(placementElement, options.node, options.node.getIn(['configuration', 'value'], 'Point List'), options.getCustomerSettings, options.getUserSettings);
                    drawButton(placementElement, pointListNode, options);
                    placementElement.style("cursor", "hand");
                }
                setMenu(placementElement, options);

                displayTooltip(placementElement, options);  
            },
            remove: () => {
                placementElement.remove();
                if ( options.node.getIn(['component', 'name']) != 'pointlist' ) image.remove();
                appendTo && appendTo.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    })
}

var displayEzyButton = (options, image, appendTo) => {
    let element = appendTo
        .append('g')
        .attr('data-index', options.index)
        .html(image.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .style('cursor', 'hand')
        .attr('transform', `matrix(${options.node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${options.node.getIn(['configuration', 'scale'], 100.0) / 100},${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])})`)

    return element;
}

var drawEzyButtonText = (element, options) => {
    let box1 = element.node().getBBox();

    let fontsize = options.node.getIn(['configuration', 'style', 'fontsize'], 24);
    let yoffset = box1.y + (box1.height / 2) + fontsize / 2;

    element.append('text')
        .text(options.node.getIn(['configuration', 'value'], 'ezyButton'))
        .attr('font-size', options.node.getIn(['configuration', 'style', 'fontsize'], '24') + 'px')
        .attr('font-style', options.node.getIn(['configuration', 'style', 'fontstyle'], 'normal'))
        .attr('font-weight', options.node.getIn(['configuration', 'style', 'fontweight'], 'normal'))
        .attr('x', box1.width / 2)
        .attr('y', yoffset)
        .attr('text-anchor', 'middle')
        .attr('fill', options.node.getIn(['configuration', 'style', 'color'], 'white'))
        .attr('text-decoration', `${options.node.getIn(['configuration', 'underline']) ? 'underline' : 'none'}`)

    return node;
}

// EzyButton => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
export function EzyButton(options, path) {
    return new Promise((resolve, reject) => {

        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g');
        
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let elementButton = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);
 
            let placementElement = displayEzyButton(options, elementButton, appendTo);
            drawEzyButtonText(placementElement, options);
            setMenu(placementElement, options); 
            let tooltip = displayTooltip(placementElement, options); 

            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement, options);
                },
                redrawElement: (node) => {
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.remove();
                    options.node = node;
                    placementElement = displayEzyButton(options, elementButton, appendTo);
                    drawEzyButtonText(placementElement, options);
                    setMenu(placementElement, options);
                    if (!tooltip) tooltip = displayTooltip(placementElement, options);
                },
                remove: () => {
                    placementElement.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        })
    })
}

var drawDigitalText = (options, image, appendTo) => {
    image.select('#background')
        .style('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#d3bc5f'));

    let digits = options.node.getIn(['configuration', 'digits'], 1);

    let element = new Array();
    let x = options.node.getIn(['position', 'x']);

    for (var i = 0; i < digits; i++) {
        element[i] = appendTo
            .append('g').attr('data-index', options.index).html(image.html())
            .attr('x', x)
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${x}, ${options.node.getIn(['position', 'y'])})`);
        x = x + 50;
    }

    return element;
}

var drawDigitalTextValues = (element, options, value) => {
    let digits = options.node.getIn(['configuration', 'digits'], 1);
    var num = 0;

    for (var i = digits; i > 0; i--) {
        num = parseInt(value % 10);
        value = value / 10;
        displayValue(element[i - 1], options, num);
    }
}

let displayValue = (element, options, value) => {
    let numberarray = [
        ["top", "topleft", "topright", "bottomleft", "bottomright", "bottom"],
        ["topright", "bottomright"],
        ["top", "topright", "middle", "bottomleft", "bottom"],
        ["top", "topright", "middle", "bottomright", "bottom"],
        ["topleft", "topright", "middle", "bottomright"],
        ["top", "topleft", "middle", "bottomright", "bottom"],
        ["top", "topleft", "middle", "bottomleft", "bottomright", "bottom"],
        ["top", "topright", "bottomright"],
        ["top", "topleft", "topright", "middle", "bottomleft", "bottomright", "bottom"],
        ["top", "topleft", "topright", "middle", "bottomright", "bottom"],
    ];

    numberarray[8].map((segment) => {
        element.select('#' + segment)
            .style('display', 'none');
    });

    let colour = options.node.getIn(['configuration', 'style', 'color'], '#000');

    numberarray[value].map((segment) => {
        element.select('#' + segment)
            .style('display', 'block')
            .style('fill', colour);
    });
};

// TextDigital => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
export function TextDigital(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = drawDigitalText(options, image, appendTo);
            drawDigitalTextValues(placementElement, options, 0);
            setMenu(placementElement[0], options);
            let tooltip = displayTooltip(placementElement[0], options);

            resolve({
                setValue: (point) => {
                    options.node = options.node.setIn(['pointconfiguration'], point);
                    let value = point.m_value;
                    drawDigitalTextValues(placementElement, options, value);
                },
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement[0], options);
                //    setMenu(placementElement[1], options);
                },
                redrawElement: (node) => {
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.map((element) => {
                        element.remove();
                    }); 
                    options.node = node;
                    placementElement = drawDigitalText(options, image,appendTo);
                    drawDigitalTextValues(placementElement, options, 0);
                    setMenu(placementElement[0], options);
                    tooltip = displayTooltip(placementElement[0], options);
                },
                remove: () => {
                    placementElement.map((element) => {
                        element.remove();
                    }); 
                    image.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        })
    })
}

// getImages => 
// getImages => `/files/editor/components_text-buttons_default.svg`
var getImages = async (rules, componentid) => {      
    let images = {};
    var layer;
    var xml;

    if (rules) {
        for (let i = 0; i < rules.size; i++) {
            xml = await d3xml(`/files/editor/components_${rules.get(i).get('name')}.svg`);
            layer = d3.select(xml.cloneNode(true).documentElement).select(`#${componentid}`);
            images[rules.get(i).get('matchvalue')] = {
                layer: layer }

            if(rules.get(i).get('action')) {
                Object.assign(images[rules.get(i).get('matchvalue')],{'action':rules.get(i).get('action')})
            } 
        }
    }    

    if ( !rules || Object.keys(images).length === 0 ) {
        xml = await d3xml(`/files/editor/components_text-buttons_default.svg`);
        layer = d3.select(xml.cloneNode(true).documentElement).select(`#${componentid}`);
        images[0] = {layer: layer}
    }

    return images;
}

// getDefaultTextButtonImage => `/files/editor/components_text-buttons_polling.svg`

var getDefaultTextButtonImage = async (componentid) => {      
    let image;
    var layer;
    var xml;

    xml = await d3xml(`/files/editor/components_text-buttons_polling.svg`);
    layer = d3.select(xml.cloneNode(true).documentElement).select(`#${componentid}`);
    image = {layer: layer}
    
    return image;
}

// TextButton => getImages options.node.getIn(['configuration', 'rules'])
// TextButton => getDefaultTextButtonImage 

export function StatusButton(options) {};

export function TextButton(options) {   
    return new Promise(async (resolve, reject) => {
        var animate;
        let image;
        var placementElement;
        var tooltip;
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        let defaultimage = await getDefaultTextButtonImage(options.node.getIn(['component', 'componentid']));

        let images = await getImages(options.node.getIn(['configuration', 'rules']), options.node.getIn(['component', 'componentid']));

        if (!options.node.getIn(['configuration', 'rules']) || options.node.getIn(['configuration', 'rules']).size === 0)  {
            image = images[0];
        }

        else {
            image = images[Object.keys(images)[0]];
        }

    //    placementElement = displayImage(options, image.layer);
        placementElement = displayImage(options, defaultimage.layer, appendTo);

        setMenu(placementElement, options);
        tooltip = displayTooltip(placementElement, options);
 
        //    if ( options.node.getIn(['configuration', 'islink'])) placementElement.style("cursor", "hand");

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                if ( placementElement) placementElement.remove();
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;

                if (!options.node.getIn(['configuration', 'rules']) || options.node.getIn(['configuration', 'rules']).size === 0) {
                    image = images[0];
                }

                else {
                    if ( image != images[point.m_value] ) { 
                        image = images[point.m_value];
                    }
                }
                
                if (image) {
                    placementElement = displayImage(options, image.layer, appendTo);
                    setMenu(placementElement, options);

                    tooltip = displayTooltip(placementElement, options);

                    if( !options.editmode && images[point.m_value] && "action" in images[point.m_value] ) {
                        if (images[point.m_value].action == 'flashing') {
                            animate = blink(placementElement);}
                        else {
                            if (animate) animate.remove();
                        } 
                    }    
                }  
            },
            setContextMenu: (editmode) => {
                if (animate) animate.remove();
                options.editmode = editmode;

                if (!image && images) {
                    image = images[Object.keys(images)[0]];
                    placementElement = displayImage(options, image.layer, appendTo);
                }

                setMenu(placementElement, options);  
            },
            redrawElement: async (node) => {
                if (images) {
                    let keys = Object.keys(images);
                    keys.forEach((key) => {
                        images[key].layer.selectAll("*").remove(); 
                    });
                }    
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                placementElement.remove();           
                options.node = node;

                images = await getImages(node.getIn(['configuration', 'rules']), node.getIn(['component', 'componentid']));

                if (!node.getIn(['configuration', 'rules'])) {
                    image = images[0];
                }

                else {
                    image = images[Object.keys(images)[0]];
                }    
            
                if (image) {
                    placementElement = displayImage(options, image.layer, appendTo);
                    setMenu(placementElement, options);      
                    tooltip = displayTooltip(placementElement, options);
                }
                oldvalue = -1;
            },
            remove: () => {
                if (images) {
                    let keys = Object.keys(images);
                    keys.forEach((key) => {
                        images[key].layer.selectAll("*").remove(); 
                    });
                } 

                placementElement.remove();               
                appendTo && appendTo.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


var drawTable = (options, appendTo) => {
    let element = appendTo
            .append("g").attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

    var rows = options.node.getIn(['configuration', 'rows'], 1);
    let xcol = 0;
    let ycol = 0;
    var row = 0;
    let rowheight = options.node.getIn(['configuration', 'rowheight'], 35)

    for (row = 0; row < rows; row++) {
        //Immutable.List([Immutable.Map({"width":160, "colour":"lightgrey"})])
        options.node.getIn(['configuration', 'cols'], Immutable.fromJS([{ "width": 160, "colour": "lightgrey" }])).map((col) => {
            element.append("rect")
                .attr("height", rowheight)
                .attr("width", col.get('width'))
                .attr("x", xcol)
                .attr("y", ycol)
                .style("fill", options.node.getIn(['configuration', 'style', 'color'],'lightgrey')).style("stroke-width", "0.5").style("stroke", "black");
            
            xcol = xcol + col.get('width');
        });

        xcol = 0;
        ycol = ycol + rowheight;
    }

    return element;
}

export function DynamicTable(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append("g").attr('data-index', options.index)
        let placementElement = drawTable(options, appendTo);
        setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                let value = point.m_value;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            redrawElement: (node) => {
            //    placementElement.selectAll("rect").remove()
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                placementElement.remove();
                options.node = node;
                placementElement = drawTable(options, appendTo);
                setMenu(placementElement, options);
                if (!tooltip) tooltip = displayTooltip(placementElement, options);
            },
            remove: () => {
                placementElement.remove();
                appendTo && appendTo.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


var drawCompass = (image, options, appendTo) => {
    let element = appendTo
        .append('g').attr('data-index', options.index).html(image.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

    return element;
}

var drawCompassPointer = (element, elementPointer, options) => {
    let box1 = element.node().getBBox();
    let pointerWidth = 16;
    let pointerHeight = 140;

    let x2 = box1.width / 2 - pointerWidth / 2;
    let y2 = (box1.height - pointerHeight) / 2;
    
    //TODO: Make it work using a dynamic value, the 95 represents the value that needs to change, according to value. The minimum is 0, and the maximum is 360
    let pointerElement = element.append('g').attr('data-index', options.index).html(elementPointer.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `rotate(0,${x2 + pointerWidth / 2}, ${box1.height / 2} ) translate(${x2}, ${y2})`);

    return pointerElement;    
}

// Compass => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
// Compass => '/files/editor/components_dials_compasspointer.svg'
export function Compass(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = drawCompass(image, options, appendTo);
            setMenu(placementElement, options);

            d3xml('/files/editor/components_dials_compasspointer.svg').then((pointerxml) => {
                let elementPointer = d3.select(pointerxml.cloneNode(true).documentElement).select('#layer1');
                let pointerElement = drawCompassPointer(placementElement, elementPointer, options);
  
                resolve({
                    setValue: (point) => {
                        options.node = options.node.setIn(['pointconfiguration'], point);
                        let value = point.m_value;

                        let box1 = placementElement.node().getBBox();
                        let pointerWidth = 16;
                        let pointerHeight = 140;            
                        let x2 = box1.width / 2 - pointerWidth / 2;
                        let y2 = (box1.height - pointerHeight) / 2;
                        pointerElement.attr('transform', `rotate(${value},${x2 + pointerWidth / 2}, ${box1.height / 2} ) translate(${x2}, ${y2})`);
                    },
                    setContextMenu: (editmode) => {
                        options.editmode = editmode;
                        setMenu(placementElement, options);
                    },
                    redrawElement: (node) => {
                        appendTo && appendTo.remove();
                        appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                        placementElement.remove();
                        pointerElement.remove();
                        options.node = node;
                        placementElement = drawCompass(image, options, appendTo);
                        setMenu(placementElement, options);
                        pointerElement = drawCompassPointer(placementElement, elementPointer, options);
                    },
                    remove: () => {
                        placementElement.remove();
                        pointerElement.remove();
                        appendTo && appendTo.remove();
                    },
                    resetIndex: (index) => {
                        options.index = index;
                        placementElement.attr('data-index', options.index);
                    }
                });
        })
        })
    })
}


var drawDial = (image, options, appendTo) => {
    let element = appendTo
        .append('g').attr('data-index', options.index).html(image.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

    element.select('#background').style('fill', `${options.node.getIn(['configuration', 'colourbackground'], '#dbe3db')}`);
    element.select('#rim').style('fill', `${options.node.getIn(['configuration', 'colourrim'], '#003c80')}`);

    var interval = options.node.getIn(['configuration', 'interval'], 250).toString();
    var total;

    var ctr = options.node.getIn(['configuration', 'endvalue'], 1500) / options.node.getIn(['configuration', 'interval'], 250) + 1;

    for (var i = 0; i < ctr; i++) {
        total = (interval * i).toString();
        element.select(`${'#interval' + (i + 1)}`)
            .style('fill', `${options.node.getIn(['configuration', 'colourtext'], 'White')}`)
            .text(total)
    }

    return element;
}

var drawPointer = (element, elementPointer, options, width, height) => {
    let box1 = element.node().getBBox();

    let x = box1.width / 2 - width / 2;
    let y = box1.height / 2 - height;
    
    //TODO: Make it work using a dynamic value, the 95 represents the value that needs to change, according to value. The minimum is 0, and the maximum is 360
    let pointerElement = element.append('g').attr('data-index', options.index).html(elementPointer.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `rotate(${options.node.getIn(['configuration', 'startangle'], 0)},${x + width / 2}, ${box1.height / 2} ) translate(${x}, ${y})`);

    return pointerElement;    
}

var setPointerValue = (element, pointerelement, options, width, height, value) => {
    let box1 = element.node().getBBox();
    let x = box1.width / 2 - width / 2;
    let y = box1.height / 2 - height;

    let newvalue = value - options.node.getIn(['configuration', 'startvalue'], -10);
    let maxrotation = Math.abs(options.node.getIn(['configuration', 'startangle'], -150)) + Math.abs(options.node.getIn(['configuration', 'endangle'], 90));
    let rotate = maxrotation / (Math.abs(options.node.getIn(['configuration', 'endvalue'], 30)) + Math.abs(options.node.getIn(['configuration', 'startvalue'], -10)));
    pointerelement
        .attr('transform', `rotate(${options.node.getIn(['configuration', 'startangle'], -150) + (newvalue * rotate)},${x + width / 2}, ${box1.height / 2} ) translate(${x}, ${y})`);
}

// Dial => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
// Dial => '/files/editor/components_dials_dial-pointer-style1.svg'

export function Dial(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = drawDial(image, options, appendTo);
            setMenu(placementElement, options);

            d3xml('/files/editor/components_dials_dial-pointer-style1.svg').then((pointerxml) => {
                let elementPointer = d3.select(pointerxml.cloneNode(true).documentElement).select('#layer1');
                let pointerElement = drawPointer(placementElement, elementPointer, options, 13, 70);
         
                resolve({
                    setValue: (point) => {
                        options.node = options.node.setIn(['pointconfiguration'], point);
                        setPointerValue(placementElement, pointerElement, options, 13, 70, point.m_value); 
                   },
                    setContextMenu: (editmode) => {
                        options.editmode = editmode;
                        setMenu(placementElement, options);
                    },
                    redrawElement: (node) => {
                        appendTo && appendTo.remove();
                        appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                        placementElement.remove();
                        pointerElement.remove();
                        options.node = node;
                        placementElement = drawDial(image, options, appendTo);
                        setMenu(placementElement, options);
                        pointerElement = drawPointer(placementElement, elementPointer, options, 13, 70);
                    },
                    remove: () => {
                        placementElement.remove();
                        image.remove();
                        appendTo && appendTo.remove();
                    },
                    resetIndex: (index) => {
                        options.index = index;
                        placementElement.attr('data-index', options.index);
                    }
                })
            })
        })
    })    
}

// Hotspot => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
export function Hotspot(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = displayImage(options, image, appendTo);
            setMenu(placementElement, options);

            let tooltip = displayTooltip(placementElement, options);

            placementElement.style("cursor", "hand");

            if (!options.editmode) {
                placementElement.style('opacity', '0');
                placementElement.style('fill-opacity', '0');
            } 
            
            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement, options);
                    if (!editmode) {
                        placementElement.style('opacity', '0');
                        placementElement.style('fill-opacity', '0');
                    } else {
                        placementElement.style('opacity', '100');
                        placementElement.style('fill-opacity', '100');
                    }
                },
                redrawElement: (node) => {
                    options.node = node;
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.remove();
                    placementElement = displayImage(options, image, appendTo);
                    setMenu(placementElement, options);
                    placementElement.style("cursor", "hand");
                    if (!options.editmode) {
                        placementElement.style('opacity', '0');
                        placementElement.style('fill-opacity', '0');
                    } 
                    tooltip = displayTooltip(placementElement, options);
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}

// Chart => path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`
export function Chart(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = displayImage(options, image, appendTo);
            setMenu(placementElement, options);

            placementElement.style("cursor", "hand");

            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement, options);
                },
                redrawElement: (node) => {
                    options.node = node;
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.remove();
                    placementElement = displayImage(options, image, appendTo);
                    setMenu(placementElement, options);

                    placementElement.style("cursor", "hand");
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}

export function Report(options, path) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`)
        .append('g').attr('data-index', options.index);
        d3xml(path || `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.cloneNode(true).documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = displayImage(options, image, appendTo);
            setMenu(placementElement, options);

            placementElement.style("cursor", "hand");

            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    setMenu(placementElement, options);
                },
                redrawElement: (node) => {
                    options.node = node;
                    appendTo && appendTo.remove();
                    appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                    placementElement.remove();
                    placementElement = displayImage(options, image, appendTo);
                    setMenu(placementElement, options);

                    placementElement.style("cursor", "hand");
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                    appendTo && appendTo.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}

const formatDateTime = (format) => {
    const dt= new Date();
    const yyyy = dt.getFullYear();
    let mm = dt.getMonth() + 1; // Months start at 0
    let dd = dt.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    let h = dt.getHours();
    let m = dt.getMinutes();

    if (h < 10) h = '0' + h;
    if (m < 10) m = '0' + m;

    var formattedToday = '';
   
    if ( format == 'DD/MM/YYYY HH:mm') {
        formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m;
    }

    if ( format == 'DD/MM/YYYY H:mm a') {
        if ( h >= 13 ){
            h = h - 12;
            formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ' pm';
        } else {
            formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ' am';
        }
    }

    if ( format == 'DD MMM YYYY HH:mm') {
        let month = dt.toLocaleString('default', { month: 'short' });
        formattedToday = dd + ' ' + month + ' ' + yyyy + ' ' + h + ':' + m;
    }

    return formattedToday;
}

export function DateTimeText(options) {
    let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
    return new Promise((resolve, reject) => {
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)
        
        let placementElement = createPlacementElement(options);

        setMenu(placementElement, options);

        var dateNow;
        let f = options.node.getIn(['configuration', 'format'], 'DD/MM/YYYY HH:mm');
        dateNow = formatDateTime(f);
        let textNode = drawText(placementElement, options.node, dateNow, options.getCustomerSettings, options.getUserSettings);
        
        let intervalS = setInterval(() => {
            dateNow = formatDateTime(f);
            textNode.text(dateNow);
        }, 5000);

        resolve({
            setValue: (point) => {
                dateNow = formatDateTime(f);
                textNode.text(dateNow);
            },
            remove: () => {
                clearInterval(intervalS);
                d3.select(placementElement).remove();
                appendTo && appendTo.remove();
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            redrawElement: (node) => {
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                textNode.remove();
                placementElement.remove();
                options.node = node;
                placementElement = createPlacementElement(options);

                setMenu(placementElement, options);

                clearInterval(intervalS);
                
                dateNow = formatDateTime(f);
                textNode = drawText(placementElement, options.node, dateNow, options.getCustomerSettings, options.getUserSettings);
        
                intervalS = setInterval(() => {
                    dateNow = formatDateTime(f);
                    textNode.text(dateNow);
                }, 5000);
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}

export function Shape(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
         //   .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)


            .attr('transform', `rotate(45,${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])}) translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

        let createRectangleShape = (placementElement, options) => placementElement.append('rect')
                .style("fill","url(#mainGradient)")
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 500)
                .attr('height', 200)
            //    .attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#fff'))
            //    .attr('stroke', options.node.getIn(['configuration', 'style', 'border-color'], '#000')); 

            
            
        //    .attr('d', "m 0,9 1,-8 292,22.5 -1,8 z") 


        let createPolygonShape = (placementElement, options) => placementElement.append('path')
                .attr('d', "m 0,9 1,-8 199.4349,15.02 -1,8 z")                
                .style("fill","url(#mainGradient)")
                .attr('x', 0)
                .attr('y', 0);
    
        let placementElement = createPlacementElement(options);


    /*    let svgDefs = (placementElement, options) => {
            placementElement.append('defs'); 

            var mainGradient = svgDefs.append('linearGradient')
            .attr('id', 'mainGradient')
            .attr('x1', 0)
            .attr('y1', 8)
            .attr('x2', 0)
            .attr('y2', 0)
            .attr('gradientTransform', 'matrix(1,0.0772,-0.0778,1,50,4)')
            .attr('gradientUnits', 'userSpaceOnUse');
        }   */ 

        let defs = (placementElement, options) => {
            var svgDefs = placementElement.append('defs');

            var mainGradient = svgDefs.append('linearGradient')
                    .attr('id', 'mainGradient')
                    .attr('x1', 0)
                    .attr('y1', 8)
                    .attr('x2', 0)
                    .attr('y2', 0)
                    .attr('gradientTransform', 'matrix(1,0.0772,-0.0778,1,50,4)')
                    .attr('gradientUnits', 'userSpaceOnUse');

            // Create the stops of the main gradient. Each stop will be assigned
                // a class to style the stop using CSS.
                mainGradient.append('stop')
                    .attr('style', "stop-color: #58595B")
                    .attr('offset', '0');

                mainGradient.append('stop')
                    .attr('style', "stop-color: #646568")
                    .attr('offset', '0.0668');

                
                mainGradient.append('stop')
                    .attr('style', "stop-color: #818385")
                    .attr('offset', '0.1938'); 
                    
                mainGradient.append('stop')
                    .attr('style', "stop-color: #A7A9AC")
                    .attr('offset', '0.3389');
                    
                mainGradient.append('stop')
                    .attr('style', "stop-color: #E0E1E2")
                    .attr('offset', '0.5025'); 
                    
                mainGradient.append('stop')
                    .attr('style', "stop-color: #F1F2F2")
                    .attr('offset', '0.5486');
                    
                mainGradient.append('stop')
                    .attr('style', "stop-color: #E3E4E5")
                    .attr('offset', '0.601');
                    
                mainGradient.append('stop')
                    .attr('style', "stop-color: #BEC0C2")
                    .attr('offset', '0.7045');
                    
                mainGradient.append('stop')
                    .attr('style', "stop-color: #A7A9AC")
                    .attr('offset', '0.7633');   
                                
                mainGradient.append('stop')
                    .attr('style', "stop-color: #6D6E71")
                    .attr('offset', '1');

            return svgDefs;        
        }        
                
        let shapetype = options.node.getIn(['configuration', 'shapetype']);    

        var shape;
             
        if ( shapetype == 'rect'){
        //    shape =  createRectangleShape(placementElement, options);
            shape =  createPolygonShape(placementElement, options);
            defs(placementElement, options);
        }

        setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;
                    let rules = options.node.getIn(['configuration', 'rules']);
                    let states = options.node.getIn(['component', 'stateids']);
        
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                d3.select(element).remove();
                appendTo && appendTo.remove();
            },
            redrawElement: (node) => {
                options.node = node;
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                placementElement.remove();
                placementElement = createPlacementElement(options);
                shapetype = options.node.getIn(['configuration', 'shapetype']);    
                if ( shapetype == 'rect'){
                    shape =  createPolygonShape(placementElement, options);
                    defs(placementElement, options);
                }
                setMenu(placementElement, options);
                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(element, options);
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


export function Pipe(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)
        //    .attr('transform', `rotate(${(options.node.getIn(['configuration', 'rotation'], 0))},${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])}) translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);
            

        let createPolygonShape = (placementElement, options) => {

        //    let rotation = options.node.getIn(['configuration', 'rotation'], 45);

        //    let angle = (rotation - 4.3) * (Math.PI / 180);

            let angle = 4.3 * (Math.PI / 180);


            var rads = Math.abs(Math.sin(angle));

            var hyp =  options.node.getIn(['configuration', 'length'], 200);
            
            var x_length = rads * hyp;
    
            rads = Math.abs(Math.cos(angle));
            var y_length = rads * hyp;

            let poly = `m 0,9 1,-${options.node.getIn(['configuration', 'width'], 8)} ${y_length},${x_length} -1,${options.node.getIn(['configuration', 'width'], 8)} z`

            placementElement.append('path')
                .attr('d', `${poly}`)                
                .style("fill","url(#mainGradient)")
                .attr('x', 0)
                .attr('y', 0);
        }        
    
        let placementElement = createPlacementElement(options);

        let defs = (placementElement, options) => {
            var svgDefs = placementElement.append('defs');

            var mainGradient = svgDefs.append('linearGradient')
                    .attr('id', 'mainGradient')
                    .attr('x1', 0)
                    .attr('y1', `${options.node.getIn(['configuration', 'width'], 8)}`)
                    .attr('x2', 0)
                    .attr('y2', 0)
                    .attr('gradientTransform', 'matrix(1,0.0772,-0.0778,1,50,4)')
                    .attr('gradientUnits', 'userSpaceOnUse');

            // Create the stops of the main gradient. Each stop will be assigned
            // a class to style the stop using CSS.
            mainGradient.append('stop')
                .attr('style', "stop-color: #58595B")
                .attr('offset', '0');

            mainGradient.append('stop')
                .attr('style', "stop-color: #646568")
                .attr('offset', '0.0668');

            
            mainGradient.append('stop')
                .attr('style', "stop-color: #818385")
                .attr('offset', '0.1938'); 
                
            mainGradient.append('stop')
                .attr('style', "stop-color: #A7A9AC")
                .attr('offset', '0.3389');
                
            mainGradient.append('stop')
                .attr('style', "stop-color: #E0E1E2")
                .attr('offset', '0.5025'); 
                
            mainGradient.append('stop')
                .attr('style', "stop-color: #F1F2F2")
                .attr('offset', '0.5486');
                
            mainGradient.append('stop')
                .attr('style', "stop-color: #E3E4E5")
                .attr('offset', '0.601');
                
            mainGradient.append('stop')
                .attr('style', "stop-color: #BEC0C2")
                .attr('offset', '0.7045');
                
            mainGradient.append('stop')
                .attr('style', "stop-color: #A7A9AC")
                .attr('offset', '0.7633');   
                            
            mainGradient.append('stop')
                .attr('style', "stop-color: #6D6E71")
                .attr('offset', '1');

            return svgDefs;        
        }        
                
        let shapetype = options.node.getIn(['configuration', 'shapetype']);    

        var shape;
        shape =  createPolygonShape(placementElement, options);
        defs(placementElement, options);


        setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;
                    let rules = options.node.getIn(['configuration', 'rules']);
                    let states = options.node.getIn(['component', 'stateids']);
        
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                d3.select(placementElement).remove();
                appendTo && appendTo.remove();
            },
            redrawElement: (node) => {
                options.node = node;
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                placementElement.remove();
                placementElement = createPlacementElement(options);
                shapetype = options.node.getIn(['configuration', 'shapetype']);    
                    shape =  createPolygonShape(placementElement, options);
                    defs(placementElement, options);
                setMenu(placementElement, options);
                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}

export function Gauge(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `translate(${options.node.getIn(['position', 'x']) }, ${options.node.getIn(['position', 'y'])})`)
        
        let placementElement = createPlacementElement(options);

        let gauge = drawGauge(placementElement, options.node);

        let needle = drawNeedle(placementElement, options.node, 0);

        setMenu(placementElement, options);
        displayTooltip(placementElement, options); 

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                needle.remove();
                needle = drawNeedle(placementElement, options.node, point.m_value);
            //    setPointerValue(placementElement, pointerElement, options, 13, 70, point.m_value); 
            //    textNode.text(point.m_formattedValueText);
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            redrawElement: (node) => {
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select(`#${options.node.getIn(['component', 'layer'])}`).append('g').attr('data-index', options.index);
                placementElement.remove();
                gauge.remove();
                needle.remove();
                options.node = node;
                placementElement = createPlacementElement(options);
                gauge = drawGauge(placementElement, options.node);
                needle = drawNeedle(placementElement, options.node, 0);
                setMenu(placementElement, options);
                displayTooltip(placementElement, options);
            },
            remove: () => {
                placementElement.remove();
                appendTo && appendTo.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


var drawGauge = (element, node) => {
    //draw a gauge with evenly spaces values based on the input data
    //alarm levels included
    //this may only work for a value that goes from low to high with high level alarms

    //data to be replaced with real values from a field controller
    var data = {
        startValue   : node.getIn(['configuration', 'startvalue'], 0),    //low sensor value
        endValue     : node.getIn(['configuration', 'endvalue'], 100),  //high sensor value
        alarm1       : node.getIn(['configuration', 'alarmlevel1']),  //first alarm value
        alarm2       : node.getIn(['configuration', 'alarmlevel2']),  //second alarm value
        alarm3       : node.getIn(['configuration', 'alarmlevel3']),  //third alarm value
        currentValue : node.getIn(['configuration', 'testvalue'], 0), //21,   //current value
        units        : ''
    };
  
    var dec = 0; //number of decimal places

    //arc colours
    var base      = node.getIn(['configuration', 'colourrim'], 'limegreen'); //"limegreen";
    var alarm1col = node.getIn(['configuration', 'colouralarm1'], 'gold'); //"gold";
    var alarm2col = node.getIn(['configuration', 'colouralarm2'], 'goldenrod');
    var alarm3col = node.getIn(['configuration', 'colouralarm3'], 'firebrick'); //"gold";

    //data.currentValue = (Math.random() * (data.endValue - data.startValue)).toFixed(2);

    //fixed data of the gauge arc
    var multiplier        = 0.8; //determines the start and end points of the arc
    var radius            = node.getIn(['configuration', 'gaugesize'], 200);  //overall gauge size
    var gaugeInnerArc     = node.getIn(['configuration', 'gaugethickness'], 0.2);  //change the gauge thickness and align needle point
    var labelRadiusOffset = node.getIn(['configuration', 'labelradiusoffset'], 4);    //adjust this to offset the labels from the gauge centre
                                //this can account for text size and number of digits taking up space
    var dialIndexes       = node.getIn(['configuration', 'dialindexes'], 11);    //number of index value labels around the dial

    var numDialIndexes    = dialIndexes - 1;  //adjusted for the calculations

    var gaugeAngleFunction = function(v){
        //get data value and return radians
        var inMin = data.startValue
        var inMax = data.endValue
        var outRange = (PI * multiplier) * 2

        var percent = (v - inMin) / (inMax - inMin)
        return (percent * outRange) - (outRange / 2)
    };

    var labels = [];
    if (numDialIndexes > 2){
        var dataRange = data.endValue - data.startValue;
        var indexStep = dataRange / numDialIndexes;
        for (var i = 0; i < numDialIndexes + 1; i++){
            labels.push((data.startValue + i * indexStep).toFixed(dec));
        }
        labels.push('');
    } else {
        labels.push(data.startValue.toFixed(dec));
        labels.push(data.endValue.toFixed(dec));
        labels.push('');
    }

    var PI         = Math.PI;
    var labelRange = (multiplier * PI) * 2;
    var arcStart   = -multiplier * PI;
    var labelData  = [];
    // Omar 26-12-2022 segment seems to be undefined, was it ever in here?
    var segment = 0;
    
    if (numDialIndexes > 2){
        var labelSteps = labelRange / numDialIndexes;
        for (var j = 0; j < labels.length - 1; j++){
            labelData.push({startAngle : arcStart + (labelSteps * j),  endAngle : arcStart + (labelSteps * j),  label : labels[j]});
        }
        labelData.push({startAngle : PI,       endAngle : PI,       label : labels[labels.length - 1]});
    } else {
        labelData.push({startAngle : -segment, endAngle : -segment, label : labels[0]});
        labelData.push({startAngle : segment,  endAngle : segment,  label : labels[1]});
        labelData.push({startAngle : PI,       endAngle : PI,       label : labels[2]});
    }

    function createArc(inputData){
        //return an array from the start of the arc to the end, taking into account alarm levels
        var arr = [];
        if (inputData.alarm1 != null){
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.alarm1),
                col : base});
            if (inputData.alarm2 != null){
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.alarm2),
                    col : alarm1col});
                    if (inputData.alarm3 != null){
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.alarm3),
                            col : alarm2col});
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm3),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm3col});
                    } else {
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm2col});
                    }
            } else {
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.endValue),
                    col : alarm1col});
            }
        } else {
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.endValue),
                col : base});
        }
        return arr;
    }
    

    var innerGaugeData = createArc(data);

    var innerGaugeGenerator = d3.arc()
        // .innerRadius(radius - (0.2 * radius))
        .outerRadius(radius - (0.1 * radius))
        .innerRadius(radius - (gaugeInnerArc * radius))
        .cornerRadius(2)

    // Create a path element and set its d attribute
    let newnode = element
        .selectAll('path')
        .data(innerGaugeData)
        .enter()
        .append('path')
        .attr('d', innerGaugeGenerator)
        .each(function(d) {
            d3.select(this)
                .style("fill", d.col)
        })


    // Create an arc generator with configuration
    var labelArcGenerator = d3.arc()
        .innerRadius(radius + labelRadiusOffset)
        .outerRadius(radius + labelRadiusOffset)

   
    // Create a path element and set its d attribute
    element
        .selectAll('labels')
        .data(labelData)
        .enter()
        .append('labels')
        .attr('d', labelArcGenerator)

    // Add labels, using .centroid() to position
    element
        .selectAll('text')
        .data(labelData)
        .enter()
        .append('text')
        .attr('fill', node.getIn(['configuration', 'colourtext'], 'black'))
        .attr('font-size', node.getIn(['configuration', 'style', 'fontsize'], '12') + 'px')
        .each(function(d) {
            var centroid = labelArcGenerator.centroid(d);
            d3.select(this)
                .attr('x', centroid[0])
                .attr('y', centroid[1])
                .attr('dx', '-1.00em')
                .attr('dy', '0.50em')
                .text(d.label + data.units)
        })

    var limit = function (val, min, max){
        return (val > max ? max : val < min ? min : val)
    }

    return newnode;
}

var drawNeedle = (element, node, value) => {
    //draw a gauge with evenly spaces values based on the input data
    //alarm levels included
    //this may only work for a value that goes from low to high with high level alarms

    //data to be replaced with real values from a field controller
    var data = {
        startValue   : node.getIn(['configuration', 'startvalue'], 0),    //low sensor value
        endValue     : node.getIn(['configuration', 'endvalue'], 100),  //high sensor value
        alarm1       : node.getIn(['configuration', 'alarmlevel1']),  //first alarm value
        alarm2       : node.getIn(['configuration', 'alarmlevel2']),  //second alarm value
        alarm3       : node.getIn(['configuration', 'alarmlevel3']),  //third alarm value
        currentValue : value, 
    };
  
    var dec = 0; //number of decimal places

    var base      = node.getIn(['configuration', 'colourrim'], 'limegreen'); //"limegreen";
    var alarm1col = node.getIn(['configuration', 'colouralarm1'], 'gold'); //"gold";
    var alarm2col = node.getIn(['configuration', 'colouralarm2'], 'goldenrod');
    var alarm3col = node.getIn(['configuration', 'colouralarm3'], 'firebrick'); //"gold";

    //data.currentValue = (Math.random() * (data.endValue - data.startValue)).toFixed(2);

    //fixed data of the gauge arc
    var multiplier        = 0.8; //determines the start and end points of the arc
    var radius            = node.getIn(['configuration', 'gaugesize'], 200);  //overall gauge size
    var gaugeInnerArc     = node.getIn(['configuration', 'gaugethickness'], 0.2);  //change the gauge thickness and align needle point
    var labelRadiusOffset = 4;    //adjust this to offset the labels from the gauge centre
                                //this can account for text size and number of digits taking up space
    var dialIndexes       = node.getIn(['configuration', 'dialindexes'], 11);    //number of index value labels around the dial

    var numDialIndexes    = dialIndexes - 1;  //adjusted for the calculations

    var PI         = Math.PI;

    var gaugeAngleFunction = function(v){
        //get data value and return radians
        var inMin = data.startValue
        var inMax = data.endValue
        var outRange = (PI * multiplier) * 2

        var percent = (v - inMin) / (inMax - inMin)
        return (percent * outRange) - (outRange / 2)
    };

    function createArc(inputData){
        //return an array from the start of the arc to the end, taking into account alarm levels
        var arr = [];
        if (inputData.alarm1 != null){
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.alarm1),
                col : base});
            if (inputData.alarm2 != null){
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.alarm2),
                    col : alarm1col});
                    if (inputData.alarm3 != null){
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.alarm3),
                            col : alarm2col});
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm3),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm3col});
                    } else {
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm2col});
                    }
            } else {
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.endValue),
                    col : alarm1col});
            }
        } else {
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.endValue),
                col : base});
        }
        return arr;
    }
    

    var innerGaugeData = createArc(data);

    var limit = function (val, min, max){
        return (val > max ? max : val < min ? min : val)
    }

    //draw needle
    //1 degree = 57.2958 rads
    var needleRotation = 57.2958 * gaugeAngleFunction(limit(data.currentValue, data.startValue, data.endValue))
    var needleColour;
    //if needle is inside an alarm range do something??
    for (var i = 0; i < innerGaugeData.length; i++){
        if (needleRotation >= 57.2958 * innerGaugeData[i].startAngle){
            needleColour = innerGaugeData[i].col
            //if the current value is above the last alarm level by ANY amount, it shows that colour!
        }
    }

    let newnode = element
        .append('line')
        .style('stroke', needleColour)
        // .style('stroke', 'silver')
        .style('stroke-width', node.getIn(['configuration', 'needlewidth'], 3),)
        .style('stroke-opacity', 0.7)
        .style('stroke-linecap', 'round')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -(1 - gaugeInnerArc - 0.02) * radius)//this should be factored against the gauge inner radius!
        .attr('transform', 'rotate('+needleRotation+')')

    return newnode;
}


var drawPolygon = (element, poly2, strokeWidth, strokeColour) => {
    let poly = element.append("polygon")
    //   .data([poly])
    // .enter().append("polygon")
    .style("fill","url(#mainGradient)")
    .attr("points",poly2)
    .attr("stroke",strokeColour)
    .attr("stroke-width",strokeWidth);

    return poly;
} 

export function Duct(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select('#layer1').append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacementElement(options);    

        let createPolygonShape = (placementElement, options, height, hyp,  x1, y1, x2) => {
            let angle = options.node.getIn(['configuration', 'angle'],1.85) * (Math.PI / 180);
            var rads = Math.abs(Math.sin(angle));      
            var y_length = rads * hyp;
            rads = Math.abs(Math.cos(angle));
            var x_length = rads * hyp;
            var l = x_length + parseInt(x2);

            // console.log('x_length : ', x_length, ' x2 : ', x2, ' l : ', l);

            let poly = [{"x":x1,"y":y1},
                        {"x":x2,"y":height},
                        {"x":l,"y":height - y_length},
                        {"x":x_length,"y":0 - y_length}];

            let p1 = poly.map((xy) => {
                return [xy.x,xy.y].join(",")
            });

            // console.log(p1);
            let p = drawPolygon(placementElement, p1,
                options.node.getIn(['configuration', 'strokewidth'], 0),
                options.node.getIn(['configuration', 'strokecolour'], 'Black'));
                
            return p;
        }
        
        let defs = (placementElement, options) => {
            var svgDefs = placementElement.append('defs');

            var mainGradient = svgDefs.append('linearGradient')
                    .attr('id', 'mainGradient')
                    .attr('x1', 0)
                    .attr('y2', 0)
                    .attr('x2', 0)
                    .attr('y1', 1)


            // Create the stops of the main gradient. Each stop will be assigned
            // a class to style the stop using CSS.
            mainGradient.append('stop')
                .attr('style', "stop-color: #EBEBEB")
                .attr('offset', '0%');

            mainGradient.append('stop')
                .attr('style', "stop-color: #6B6B6B")
                .attr('offset', '100%');
                   
           // mainGradient.append('stop')
           //     .attr('style', "stop-color: #212121")
           //     .attr('offset', '100%');

            return svgDefs;        
        } 

        defs(placementElement, options);
        
        let shape =  createPolygonShape(placementElement, options,
            options.node.getIn(['configuration', 'height'],85),
            options.node.getIn(['configuration', 'length'],400),
            options.node.getIn(['configuration', 'x1'],0),
            options.node.getIn(['configuration', 'y1'],0),
            options.node.getIn(['configuration', 'x2'],0));

    /*    createPolygonShape(placementElement, options,
            options.node.getIn(['configuration', 'height'],85),
            options.node.getIn(['configuration', 'length'],400),
            100,
            options.node.getIn(['configuration', 'y1'],0),
            options.node.getIn(['configuration', 'x2'],0));
    */
        setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;
                    let rules = options.node.getIn(['configuration', 'rules']);
                    let states = options.node.getIn(['component', 'stateids']);
        
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                d3.select(element).remove();
                appendTo && appendTo.remove();
            },
            redrawElement: (node) => {
                options.node = node;
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select('#layer1').append('g').attr('data-index', options.index);
                placementElement.remove();
                placementElement = createPlacementElement(options);
                shape =  createPolygonShape(placementElement, options,
                    options.node.getIn(['configuration', 'height'],85),
                    options.node.getIn(['configuration', 'length'], 400),
                    options.node.getIn(['configuration', 'x1'],0), options.node.getIn(['configuration', 'y1'],0),
                    options.node.getIn(['configuration', 'x2'],0));
                defs(placementElement, options);
                setMenu(placementElement, options);
                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


export function DuctTop(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select('#layer1').append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacementElement(options);    

        let createPolygonShape = (placementElement, options, x1, y1) => {
            let height = options.node.getIn(['configuration', 'height'],85);
            let angle = options.node.getIn(['configuration', 'angle'],1.85) * (Math.PI / 180);
            var rads = Math.abs(Math.sin(angle));
            var hyp =  options.node.getIn(['configuration', 'length'], 400);       
            var y_length = rads * hyp;
            rads = Math.abs(Math.cos(angle));
            var x_length = rads * hyp;

            let poly = [{"x":x1, "y":y1},
                        {"x":x1, "y":height},
                        {"x":x_length,"y":height - y_length},
                        {"x":x_length,"y":0 - y_length}];

            let p1 = poly.map((xy) => {
                return [xy.x,xy.y].join(",")
            });

            // console.log(p1);
            let p = drawPolygon(placementElement, p1,
                options.node.getIn(['configuration', 'strokewidth'], 0),
                options.node.getIn(['configuration', 'strokecolour'], 'Black'))
            return p;
        }
        
        let defs = (placementElement, options) => {
            var svgDefs = placementElement.append('defs');

            var mainGradient = svgDefs.append('linearGradient')
                    .attr('id', 'mainGradient')
                    .attr('x1', 0)
                    .attr('y2', 0)
                    .attr('x2', 0)
                    .attr('y1', 1)


            // Create the stops of the main gradient. Each stop will be assigned
            // a class to style the stop using CSS.
            mainGradient.append('stop')
                .attr('style', "stop-color: #EBEBEB")
                .attr('offset', '0%');

            mainGradient.append('stop')
                .attr('style', "stop-color: #6B6B6B")
                .attr('offset', '100%');
                   
           // mainGradient.append('stop')
           //     .attr('style', "stop-color: #212121")
           //     .attr('offset', '100%');

            return svgDefs;        
        } 

        defs(placementElement, options);
        
        //let shape =  createPolygonShape(placementElement, options, 0, 0);
        let shape =  createPolygonShape(placementElement, options, 400, 0);
     
        setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;
                    let rules = options.node.getIn(['configuration', 'rules']);
                    let states = options.node.getIn(['component', 'stateids']);
        
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                d3.select(element).remove();
                appendTo && appendTo.remove();
            },
            redrawElement: (node) => {
                options.node = node;
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select('#layer1').append('g').attr('data-index', options.index);
                placementElement.remove();
                placementElement = createPlacementElement(options);
                shape =  createPolygonShape(placementElement, options);
                defs(placementElement, options);
                setMenu(placementElement, options);
                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}


export function Line(options) {
    return new Promise((resolve, reject) => {
        let appendTo = d3.select(options.target).select('#layer1user').append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacementElement(options);    
        
        let createLine = (placementElement, options) => {
            let angle = options.node.getIn(['configuration', 'angle'],0) * (Math.PI / 180);
            var rads = Math.abs(Math.sin(angle));
            var hyp =  options.node.getIn(['configuration', 'length'], 400);       
            var y2 = rads * hyp;
            rads = Math.abs(Math.cos(angle));
            var x2 = rads * hyp;
        
            if (options.node.getIn(['configuration', 'angle'],1.85) > 90) {
                x2 = -x2;
            }

            let p = drawLine(placementElement, 0, 0, x2, y2, 
                options.node.getIn(['configuration', 'strokecolour'], 'Black'),
                options.node.getIn(['configuration', 'strokewidth'], 2) );
          
            return p;
        }

        let line = createLine(placementElement, options);

        setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);


        resolve({
            draw: (target) => {
                
            },
            remove: () => {
                d3.select(element).remove();
                appendTo && appendTo.remove();
            },
            redrawElement: (node) => {
                options.node = node;
                placementElement.remove();
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).select('#layer1user').append('g').attr('data-index', options.index);
                placementElement = createPlacementElement(options);
                line = createLine(placementElement, options);
                setMenu(placementElement, options);
                tooltip = displayTooltip(placementElement, options);
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                setMenu(placementElement, options);
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}

