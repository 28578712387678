import React from 'react';
import { searchPoints, updatePointConfiguration } from 'BmsView/Customer/Site/Actions';
import Immutable from 'immutable';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';
import Select from 'Controls/Form/Select';
import Input from 'Controls/Form/Input';

class Calibration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currententry: Immutable.Map({})
        }
    }
    async componentDidMount() {
        let { customer, address } = this.props;

        let customername = customer.get('name').replace(/ /g, "").toLowerCase();
        let siteaddress = customer.get('ipaddress').toLowerCase();

        let points = await searchPoints(customername, siteaddress, {
            addr: address.map((byte) => { return parseInt(byte) })
        });

        if (points.length > 0) {
            this.setState({
                pointconfiguration: Immutable.fromJS(points[0])
            });
        }
    }

    updateCurrentEntry(field, value) {
        this.setState(({ currententry }) => ({
            currententry: currententry.setIn(field, value)
        }));
    }

    async addEntry() {
        let { currentuser, customer } = this.props;
        let { pointconfiguration, currententry } = this.state;

        let customername = customer.get('name').replace(/ /g, "").toLowerCase();
        let siteaddress = customer.get('ipaddress').toLowerCase();

        let name = `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`;

        if (!pointconfiguration.get('calibrationdata')) {
            pointconfiguration = pointconfiguration.set('calibrationdata', Immutable.Map({}));
        }
        if (!pointconfiguration.getIn(['calibrationdata', 'calibrationentries'])) {
            pointconfiguration = pointconfiguration.setIn(['calibrationdata', 'calibrationentries'], Immutable.List([]));
        }

        pointconfiguration = pointconfiguration.updateIn(['calibrationdata', 'calibrationentries'], arr => arr.push(currententry.set('datetime', Date.now()).set('calibratedby', name).delete('nextupdate')));
        pointconfiguration = pointconfiguration.setIn(['calibrationdata', 'nextcalibrationdue'], this.setNextCalibrationDate(parseInt(currententry.get('nextupdate', "12"))));

        await updatePointConfiguration(customername, siteaddress, pointconfiguration);

        this.setState({
            pointconfiguration: undefined,
            currententry: Immutable.Map({})
        });

        this.componentDidMount();
    }

    formatDateTime (dt, format) {
        const yyyy = dt.getFullYear();
        let mm = dt.getMonth() + 1; // Months start at 0
        let dd = dt.getDate();
    
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
    
        let h = dt.getHours();
        let m = dt.getMinutes();
        let s = dt.getSeconds();
    
        if (h < 10) h = '0' + h;
        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
    
        var formattedToday = '';
       
        if ( format == 'DD/MM/YYYY HH:mm:ss') {
            formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ':' + s;
        }
    
        if ( format == 'DD/MM/YYYY') {
            formattedToday = dd + '/' + mm + '/' + yyyy
        }
    
        return formattedToday;
    }

    setNextCalibrationDate(period) {       
        const dt= new Date();
        var d = dt.getDate();

        dt.setMonth(dt.getMonth() + period);
        if (dt.getDate() != d) {
          dt.setDate(0);
        }

        return dt.getTime();
    }

    render() {
        let { pointconfiguration, currententry } = this.state || {};
        return (
            <div className="px-4 py-2">
                {!pointconfiguration && <h3>Loading point configuration...</h3>}
                {
                    pointconfiguration &&
                    <React.Fragment>
                        <div className="flex text-xl border-b border-grey py-2 font-semibold">Calibration</div>
                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-3">User</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">Date</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-10">Notes</Grid.Column>
                            </Grid.Header>
                            <Grid.Data>
                                {pointconfiguration.getIn(['calibrationdata', 'calibrationentries'], []).map((entry) => {
                                    return (
                                        <Grid.Row>
                                            <Grid.Column classes="col-span-16 md:col-span-3">{entry.get('calibratedby')}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-3">{this.formatDateTime(new Date(entry.get('datetime')),'DD/MM/YYYY HH:mm:ss')}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-10">{entry.get('note')}</Grid.Column>
                                        </Grid.Row>
                                    )
                                })}
                            </Grid.Data>
                        </Grid>
                        <div className="grid grid-cols-16 w-full mt-2">
                            <div className="col-span-16 md:col-span-10 px-2">
                                <Input label="Notes" value={currententry.get('note')} onChange={this.updateCurrentEntry.bind(this, ['note'])} />
                            </div>
                            <div className="col-span-16 md:col-span-4 px-2">
                                <Select 
                                    label="Next calibration in"
                                    value={
                                    currententry.get('nextupdate', "12")}
                                    options={[
                                        { value: '3', text: '3 Months' },
                                        { value: '6', text: '6 Months' },
                                        { value: '12', text: '12 Months' }
                                    ]}
                                    onChange={this.updateCurrentEntry.bind(this, ['nextupdate'])}
                                />
                            </div>
                            <div className="col-span-16 md:col-span-2 pt-8">
                                <Button role="save" onClick={this.addEntry.bind(this)}>Add Entry</Button>
                            </div>
                        </div>
                        {pointconfiguration.getIn(['calibrationdata', 'nextcalibrationdue']) && <div className="grid grid-cols-16">
                            <div className="col-span-16 md:col-span-4 px-2">Next calibration due: </div>
                            <div className="col-span-16 md:col-span-4 px-2">{this.formatDateTime(new Date(pointconfiguration.getIn(['calibrationdata', 'nextcalibrationdue'])),'DD/MM/YYYY')}</div>
                        </div>}
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default Calibration;