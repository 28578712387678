import TransitioningDialog from "../../Controls/Dialog/TransitioningDialog";
import { useAreaDialogContext, ActiveAreaDialogProvider } from "./Context";
import Dialog, { DialogBody, DialogFooter, DialogContent } from 'Controls/Dialog/Dialog';
import { getNodeMeta } from "../Helpers/NodeHelper";
import { AreaDialogV2Panel } from "../Customer/Site/Area/AreaDialogV2";
import { SecondArea as AreaDialogV1Panel } from "../Customer/Site/Area/AreaDialog";
import { useMemo } from "react";
import { useActiveAreaDialog } from "./Context";
import { useCustomerSettings } from "Context/CustomerSettings";
import CustomerSettingsWrapper, { CustomerSettingsProvider } from "../../Context/CustomerSettings";
import AreaPointProvider from "../../Context/AreaPoint";

export default function AreaDialog() {

    const { dialogOpened, openDialog, closeDialog, dialogUrl } = useAreaDialogContext();

    if(!dialogOpened) {
        return <data></data>
    }

    return <ActiveAreaDialogProvider url={dialogUrl}>
        <Dialog onClose={closeDialog} opened={!!dialogOpened} className="max-w-max" >
            <DialogContent className="w-fit max-h-min">
                <DialogBody>
                    <div>
                        <AreaDialogPanel />
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="p-2 flex justify-end">
                        <button className="bg-[#6c757d] p-2 text-[white]" onClick={closeDialog}>Cancel</button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    </ActiveAreaDialogProvider>


};

function AreaDialogPanel() {

    const { dialogUrl } = useAreaDialogContext();
    const { customer, site, loading } = useActiveAreaDialog();

    const { customerSettings } = useCustomerSettings(); 

    console.log("AreaDialogPanel settings", customerSettings);

    let match = dialogUrl.match(/.*?\/customer\/(?<customer>.*?)\/site\/(?<site>.*?)\/(.*)/);
    let customerName = match?.groups?.customer;
    let siteName = match?.groups?.site;

    let nodepath = dialogUrl.replace(/.*?\/customer\/.*?\/site\/.*?\/(.*)/,'$1');;
    let { levels, currentNode, nodes, customerMainDirectory } =  useMemo(() => {
        if(loading || !customer || !site) {
            return {};
        }
        return getNodeMeta(nodepath, customer, site) 
    }, [nodepath, customer, site, loading]);
    // let [lvl1, lvl2, lvl3, lvl4, lvl5, lvl6] = levels;
    // let [lvl1node, lvl2node, lvl3node, lvl4node, lvl5node, lvl6node] = nodes;
    
    if(loading || (!customer || !site)) {
        return <div>Loading...</div>
    }

    let AreaVersionComponent = null;
    console.log("09-07 AreaDialogPanel currentNode", currentNode)
    if(currentNode && currentNode.get('isV2')) {
        
        AreaVersionComponent = AreaDialogV2Panel;
    } else {
        AreaVersionComponent = AreaDialogV1Panel;
    }

    return <CustomerSettingsWrapper customer={customer}>
        <AreaPointProvider customer={customerName}>
            <AreaVersionComponent 
                customer={customer}
                site={site} 
                selecteditem={dialogUrl} 
                customerMainDirectory={customerMainDirectory}
            />
        </AreaPointProvider>
    </CustomerSettingsWrapper>
}