import React from 'react';
import { Link } from 'react-router-dom';

class MenuItem extends React.Component {

    render() {
        let { children, to, onClick } = this.props;
        //TODO: If an item didn't have a link, a border will be added in the top.
        if (onClick) {
            return (
                <ul className="p-0 flex-row flex-grow self-center flex-no-wrap">
                    <li className={`menuitem`} onClick={onClick}><a>{children}</a></li>
                </ul>
            )
        }
        return (
            <ul className="p-0 flex-row flex-grow self-center flex-no-wrap">
                <li className={`menuitem`}><Link className={`menulink`} to={to}>{children}</Link></li>
            </ul>
        )
    }
}

export default MenuItem;