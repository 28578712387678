import React from 'react';
import Select from 'Controls/Form/Select';
import { convertDoubleToTimeString } from 'Utils/Timestamp';


import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function TimezoneDialog({selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <TimezoneDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class TimezoneDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedtimezone: props.selecteditem
        }
    }

    timezoneChanged(field, value) {
        this.setState(({ selectedtimezone }) => ({
            selectedtimezone: selectedtimezone.setIn(field, value)
        }))
    }

    save(){
        let {onSave} = this.props;

        if (onSave){
            let {selectedtimezone} = this.state;
            onSave(selectedtimezone);
        }
    }

    render() {
        let { onClose } = this.props;
        let { selectedtimezone } = this.state;

        let times = [];
        for (let i = 0; i < 289; i++) {
            times.push({
                value: i / 12,
                text: convertDoubleToTimeString(i / 12)
            });
        }
        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Select time</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="px-3 py-2">
                        <div className="grid grid-cols-16 gap-2">
                            <div className="col-span-8 px-2">
                                <Select
                                    label="Start Time"
                                    value={selectedtimezone.getIn(['startindouble'], 0)}
                                    onChange={this.timezoneChanged.bind(this, ['startindouble'])}
                                    options={times}
                                />
                            </div>
                            <div className="col-span-8 px-2">
                                <Select
                                    label="End Time"
                                    value={selectedtimezone.getIn(['endindouble'], 0)}
                                    onChange={this.timezoneChanged.bind(this, ['endindouble'])}
                                    options={times}
                                />
                            </div>
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={onClose}>Close</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.save.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>

            
        )
    }
}

export default TimezoneDialog;