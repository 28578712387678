import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import Select from './Form/Select';

const DateTimeSelection = ({dateTimeRange, updateDateTimeRange, containerClassName= '' }) => {
    const selectionTypes = ['Last Hour', 'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Custom'];
 
    const changeRange = (newtype, value) => {
        let newStartDate = new Date();
        let newEndDate = new Date();

        let range = dateTimeRange.rangeType;

        switch (newtype){
            case "startDate":
                newStartDate = value;
                newEndDate = dateTimeRange.endDate;
                break;

            case "endDate":
                newEndDate = value;
                newStartDate = dateTimeRange.startDate;
                break;
                
            default:

                newStartDate = new Date(new Date().setHours(0,0,0,0));
                newEndDate = new Date(new Date().setHours(23,59,59,999));

                console.log("starting with", newStartDate, newEndDate);

            range = value;
            let dow = newStartDate.getDay();
            switch (range) {
                case "Last Hour":
                    newStartDate = new Date();
                    newStartDate.setHours(newStartDate.getHours()-1);
                    newEndDate = new Date();
                    break;

                case "Yesterday":
                    // newStartDate.setDate(newStartDate.getDate()-1);
                    // newEndDate.setDate(newEndDate.getDate()-1);
                    newStartDate.setDate(newStartDate.getDate() - 1);
                    newStartDate.setHours(0, 0, 0, 0); // Start of yesterday
                    newEndDate.setDate(newEndDate.getDate() - 1);
                    newEndDate.setHours(23, 59, 59, 999); // End of yesterday
                    break;
                
                case "This Week":
                    // if ( dow == 0 ) dow = 6; else dow = dow - 1;
                    // newStartDate.setDate(newStartDate.getDate()-dow);
                    // newEndDate.setDate(newStartDate.getDate()+5);
                    const currentDayOfWeek = newStartDate.getDay();
                    const daysFromMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1; // Sunday = 6
                    newStartDate.setDate(newStartDate.getDate() - daysFromMonday);
                    newStartDate.setHours(0, 0, 0, 0); // Start of the day
                    newEndDate.setDate(newStartDate.getDate() + 6); // Add 6 days to get to Sunday
                    newEndDate.setHours(23, 59, 59, 999); // End of the day
                    break;  
                    
                case "Last Week":
                    // if ( dow == 0 ) dow = 6; else dow = dow - 1;
                    // newStartDate.setDate(newStartDate.getDate()-dow - 7);
                    // newEndDate.setDate(newStartDate.getDate()+6);
                    // console.log("Last week", newStartDate, newEndDate);
                    const lastWeekDow = newStartDate.getDay();
                    const lastWeekDaysFromMonday = lastWeekDow === 0 ? 6 : lastWeekDow - 1;
                    newStartDate.setDate(newStartDate.getDate() - lastWeekDaysFromMonday - 7);
                    newStartDate.setHours(0, 0, 0, 0); // Start of the day
                    newEndDate = new Date(newStartDate);
                    newEndDate.setDate(newStartDate.getDate() + 6);
                    newEndDate.setHours(23, 59, 59, 999); // End of the day

                    console.log("Last week", newStartDate, newEndDate);
                    break;
    
                case "This Month":
                    // if ( dow == 0 ) dow = 6; else dow = dow - 1;
                    // newStartDate.setDate(1);
                    // newEndDate.setDate(1);
                    // newEndDate.setMonth(newStartDate.getMonth()+1);
                    // newEndDate.setDate(newEndDate.getDate()-1);
                    newStartDate.setDate(1);
                    newStartDate.setHours(0, 0, 0, 0); // Start of the month
                    newEndDate.setMonth(newStartDate.getMonth() + 1);
                    newEndDate.setDate(0); // Last day of the previous month (end of the current month)
                    newEndDate.setHours(23, 59, 59, 999); // End of the day
                    break;

                case "Last Month":
                    // if ( dow == 0 ) dow = 6; else dow = dow - 1;
                    // newStartDate.setDate(1);
                    // newStartDate.setMonth(newStartDate.getMonth()-1);
                    // newEndDate.setDate(1);
                    // newEndDate.setDate(newEndDate.getDate()-1);                   
                    newStartDate.setMonth(newStartDate.getMonth() - 1);
                    newStartDate.setDate(1);
                    newStartDate.setHours(0, 0, 0, 0); // Start of the previous month
                    newEndDate = new Date(newStartDate);
                    newEndDate.setMonth(newStartDate.getMonth() + 1);
                    newEndDate.setDate(0); // Last day of the previous month
                    newEndDate.setHours(23, 59, 59, 999); // End of the day
                    break; 
                    
                case "Custom": 
                    newStartDate = dateTimeRange.startDate;
                    newEndDate = dateTimeRange.endDate;
                    break;
            }
        }

        let newRange = {
            rangeType:range,
            startDate:newStartDate,
            endDate:newEndDate
        }

        updateDateTimeRange(newRange);
    }

    // if (!dateTimeRange.startDate) changeRange('rangeType', dateTimeRange.rangeType);

    useEffect(() => {
        if (!dateTimeRange.startDate) {
            changeRange('rangeType', dateTimeRange.rangeType);
        }
    }, [dateTimeRange]);
    
    return (
        <div className={`mb-4 ${containerClassName}`}>
            <div className="flex flex-row">
                <div className="px-4">
                    <Select
                        containerClassName={containerClassName}
                        label="Period"
                        value={dateTimeRange.rangeType}
                        onChange={t => changeRange('rangeType', t)}
                        options={selectionTypes.map((t) => {
                            return({
                                text: t,
                                value: t})        
                        })}
                    />
                </div>
                {dateTimeRange.rangeType == 'Custom' &&
                <React.Fragment>
                    <div className="px-4">
                        <label>From</label>
                        <div>
                            <DatePicker 
                                dateFormat="dd/MM/yyyy h:mm:ss aa"
                                selected={dateTimeRange.startDate}
                                onChange={date => changeRange('startDate', date)}
                                showTimeInput
                            />
                        </div>
                    </div>
                    <div className="px-4">    
                        <label>To</label>
                        <div>    
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm:ss aa"
                                selected={dateTimeRange.endDate}
                                onChange={date => changeRange('endDate', date)}
                                showTimeInput
                            />
                        </div>
                    </div>
                </React.Fragment>}
            </div>
        </div>);
}

export default DateTimeSelection;