import React from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';

import ServiceBus from 'Services/ServiceBus';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

import {getControllers} from './Actions';
import Select from 'Controls/Form/Select';
import { withBMS } from '../../../../Context/BMS';

function TimeProgramListDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} >
        <TimeProgramListDialogPanel selecteditem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}

class TimeProgramListDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.timeProgramNamesHandler = this.timeProgramNamesHandler.bind(this);

        this.state = {
            controllers: Immutable.List([]),
            timeprogramnames: Immutable.List([])
        }

        props.setSaveHandler(this.saveSelection.bind(this));
    }

    componentDidMount() {
        ServiceBus.on('GET_TIME_PROGRAM_NAMES_RESPONSE', this.timeProgramNamesHandler);

        this.getControllerList();
    }

    componentWillUnmount() {
        ServiceBus.off('GET_TIME_PROGRAM_NAMES_RESPONSE', this.timeProgramNamesHandler);
    }

    getControllerList() {
        let { customer } = this.props;

        getControllers(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`).then((data) => {
                this.setState({
                    controllers: Immutable.fromJS(data.map((controller) => {
                        return {
                            subsystem: controller.subsystem,
                            number: controller.controllernumber,
                            name: controller.identifier,
                            type: controller.type
                        }
                    }))
                });
            }
        )
    }

    getTimeProgramNames(controllerNumber) {
        let { selectedsubsystem } = this.state;
        let { customer } = this.props;

        let request = {
            m_level: 60,
            m_communicNum: 20,
            m_subsystemNumber: selectedsubsystem,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr',
            type: 'GENERIC'
        }

        let comm = {
            m_controllerNumber: controllerNumber
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    timeProgramNamesHandler(msg) {
        let names = [];

        for (let name = 0; name < msg.m_names.length; name++) {
            names.push(msg.m_names[name]);
        }

        this.setState({
            timeprogramnames: Immutable.fromJS(names),
            dirty: false
        });
    }

    valueChanged(value) {
        this.setState({
            selectedsubsystem: value
        });
    }

    controllerChanged(value) {
        this.setState({
            selectedcontroller: value,
            timeprogramnames: Immutable.List([])
        });
   
        this.getTimeProgramNames(value);
    }

    programChanged(value) {
        this.setState({
            selectedprogram: value
        });

    }

    saveSelection() {
        let {timeprogramnames, controllers, selectedsubsystem, selectedcontroller, selectedprogram} = this.state;
        let { onSave, customer } = this.props;

        let selections = `Subsystem : ${controllers.filter((controller) => {
            return controller.get('subsystem') == parseInt(selectedsubsystem) && controller.get('number') == parseInt(selectedcontroller);
        }).getIn([0, 'name'])}`;


        selections = selections + `, Controller : ${(customer.get('subsystems').filter((subsystem) => {
            return subsystem.get('number') == parseInt(selectedsubsystem);
        })).getIn([0, 'name'])}`;

        selections = selections + `, Time Program : ${timeprogramnames.get(selectedprogram)}`;

        onSave(selectedsubsystem, selectedcontroller, selectedprogram, selections);
    }


    render() {
        let { timeprogramnames, selectedsubsystem, controllers, selectedcontroller, selectedprogram} = this.state;
        let { customer } = this.props;

        let gridFields = [
            { value: 'time', name: 'Time', width: 'w-g-2' },
            { value: 'state', name: 'State', width: 'w-g-2' },
            { value: 'optimization', name: 'Optimization', width: 'w-g-2' }
        ];

        return (
            <div>
                <div className="col-16 col-md-4">
                    <Select label="Subsystem"
                        value={selectedsubsystem}
                        onChange={this.valueChanged.bind(this)}
                        options={[Immutable.Map({ name: "Select the subsystem", number: -1 }), ...customer.get('subsystems' || []).filter((subsystem) => {
                            return subsystem.get('type') == 6;})].map((item) => {
                                return {
                                    text: item.get('name'),
                                    value: item.get('number')
                                }
                            })
                        }
                    />
                </div>
                <div className="col-16 col-md-4">
                    <Select label="Controller"
                        value={selectedcontroller}
                        onChange={this.controllerChanged.bind(this)}
                        options={[Immutable.Map({ name: "Select the controller", number: -1 }), ...controllers.filter((controller) => {
                            return controller.get('subsystem') == selectedsubsystem;})].map((item) => {
                                return {
                                    text: item.get('name'),
                                    value: item.get('number')
                                }
                            })
                        }
                    />
                </div>
                <div className="col-16 col-md-4">
                    <Select label="Time Programs"
                        value={selectedprogram}
                        onChange={this.programChanged.bind(this)}
                        options={[Immutable.Map({ name: "Select the time program", number: -1 }), ...timeprogramnames.map((item, index) => {
                                return {
                                    text: item,
                                    value: index
                                }
                            })]
                        }
                    />
                </div>
            </div> 
        )
    }
}




// export default (TimeProgramListDialog)(connect((state) => ({
//     customer: state.get('customer')
// }))(TimeProgramListDialog));

export default (TimeProgramListDialog)(withBMS(TimeProgramListDialog));


