import React, { useState, Fragment, useEffect } from 'react';
import { Route, Routes, Link, Outlet, useParams, NavLink } from 'react-router-dom';
import Immutable, { Map, set } from 'immutable';
import { LeftSlide } from 'BmsView/Layout';
import Site from './Site';
import { connect, useSelector } from 'react-redux';
import { setCustomer, getCustomerUserGroups, setCustomerUserGroups } from 'BmsView/Actions/Customers';
import Histories from './Histories';
import HistoriesTemplatesList from './Histories/HistoriesTemplatesList';
import TimeTemplates from './Configuration/TimeTemplates';
import CurrentPointAlarmsDialog from './Logs/CurrentPointAlarmsDialog';
import AlarmLogDialog from './Logs/AlarmLogDialog';
import EventLogDialog from './Logs/EventLogDialog';
import PointsInAlarmDialog from './Logs/PointsInAlarmDialog';
import Subsystems from './Configuration/Subsystems';
import PointEditor from './Configuration/PointEditor';
import PointGroups from './Configuration/PointGroups';
import PointListStatus from './Configuration/PointListStatus';
import UserGroups from './Configuration/UserGroups';
import Calendars from './Configuration/Calendars';
import AlarmSetup from './Configuration/AlarmSetup';
import AlarmFilterGroups from './Configuration/AlarmSetup/AlarmFilterGroups';
import SubsystemStatus from './Configuration/SubsystemStatus';
import ConnectedUsers from './Configuration/ConnectedUsers';
import DatabaseMaintenance from './Configuration/Maintenance';
import BmsMenu from 'BmsView/BmsMenu';
import SwitchSite from 'BmsView/BmsMenu/SwitchSite';
import SiteSidebar from './Site/Sidebar';
import AssetList from './Assets';
import DocumentList from './Documents';
import CustomerSetup from './Configuration';
import IpTables from './IpTables';
import CreateCustomerDialog from '../BmsMenu/CreateCustomerDialog';
import Dropdown, { Item as DropDownItem } from 'Controls/Dropdown';
import DesktopService from 'Services/DesktopService';
import { useCustomerSettings } from '../../Context/CustomerSettings';
import { useUserSettings } from '../../Context/UserSettings';
import SystemNotifications from '../SystemNotification';
import RotatingScreen from './RotatingScreen';
import { useBMS } from '../Context/BMS';
import AlarmFilterGroupsProvider from './Context/AlarmFilterGroups';

import debuggerService from '../../Services/DebuggerService';
import AreaDialog from '../AreaDialog';

const logger = debuggerService.getDebugger("Customer", "gold");

function Customer({ params }) {

    const [dialogState, setDialogState] = useState({});
    const { customers } = useBMS();
    const { customer } = useBMS();
    const { currentUser } = useBMS();

    


    const toggleShowCurrentPointAlarmsDialog = () => {

        setDialogState((state) => {
            return {
                ...state,
                showCurrentPointAlarmsDialog: !state.showCurrentPointAlarmsDialog
            }
        });

    }

    const toggleShowAlarmLogDialog = () => {
        setDialogState((state) => {
            return {
                ...state,
                showAlarmLogDialog: !state.showAlarmLogDialog
            }
        });

    }

    const toggleShowEventLogDialog = () => {
        setDialogState((state) => {
            return {
                ...state,
                showEventLogDialog: !state.showEventLogDialog
            }
        });
    }

    const toggleShowPointsInAlarmDialog = () => {
        setDialogState((state) => {
            return {
                ...state,
                showPointsInAlarmDialog: !state.showPointsInAlarmDialog
            }
        });
    }

    const openConnectedUsersDialog = (type) => {
        setDialogState({
            [type]: {}
        });
    }

    const openNewCustomerDialog = () => {
        setDialogState({ customercreating: {} });
    
    }


    const desktopMenuHandle = (menuAction) => {
        switch (menuAction) {
            case 'logs-current-point-alarms':
                toggleShowCurrentPointAlarmsDialog();
                break;
            case 'logs-alarm-log':
                toggleShowAlarmLogDialog();
                break;
            case 'logs-event-log':
                toggleShowEventLogDialog();
                break;
            case 'points-in-alarm':
                toggleShowPointsInAlarmDialog();
                break;
            case 'configuration-users':
                openConnectedUsersDialog('connectedusers');
                break;
            case 'configuration-create-customer':
                openNewCustomerDialog();
                break;
        }
    }

    const openDialog = (name) => {
        setDialogState((state) => {
            if (!state) {
                state = {};
            }
            state[`${name}dialog`] = true;
            return state;
        });
    }

    const closeDialog = (name) => {
        setDialogState((state) => {
            state[`${name}dialog`] = false;
            return state;
        });
    }

    const clearDialog = () => {
        setDialogState({ customercreating: false });
    }

    const clearConnectedUsersDialog = () => {
        setDialogState({ connectedusers: false });

    }

    const customerCreated = () => {
        clearDialog();
    }

    const setExpanded = (value) => {
        setDialogState({ expanded: value })
    }

    // useEffect(() => {
    //     console.log("20-03 mounting Customer");
    //     return () => {
    //         console.log("20-03 unmounting Customer");
    //     }
    // }, [])

    useEffect(() => {
        DesktopService.subscribe('menu-action', desktopMenuHandle);
        return () => {
            DesktopService.unsubscribe('menu-action', desktopMenuHandle);
        }
    
    }, [])

    // console.log("params", params)

    useEffect(() => {

        if(customer && params?.customer) {
            DesktopService.send('update-customer-in-menu', { customer: customer.toJS() });
        }

    }, [params?.customer, customer])

    const { customercreating, connectedusers, expanded } = dialogState;

    const defaultLinkClassName = classNames('text-gray-700', 'block px-4 py-1', 'hover:bg-[rgb(0,0,0,0.15)] whitespace-nowrap');
    const mobileActiveClassName = expanded ? '' : 'hidden xl:flex';

    console.log("Customer", customer?.toJS(), currentUser?.toJS());




    return (
        <AlarmFilterGroupsProvider customer={customer}>
            <AreaDialog />
            <RotatingScreen customer={customer} />
            <CreateCustomerDialog selecteditem={customercreating} onSave={customerCreated} onClose={clearDialog} />
            <ConnectedUsers show={connectedusers} customer={customer} sa={currentUser?.get('isSuperAdmin')} onClose={clearConnectedUsersDialog} />
            <SystemNotifications />
            <MenuZoomWrapper>
                <BmsMenu key="customers" expanded={expanded} setExpanded={setExpanded}>
                    {(customer &&
                        <React.Fragment>
                            <CurrentPointAlarmsDialog
                                onClose={toggleShowCurrentPointAlarmsDialog}
                                customer={customer}
                                show={dialogState.showCurrentPointAlarmsDialog} />
                            <AlarmLogDialog
                                onClose={toggleShowAlarmLogDialog}
                                customer={customer}
                                show={dialogState.showAlarmLogDialog} />
                            <EventLogDialog
                                onClose={toggleShowEventLogDialog}
                                customer={customer}
                                show={dialogState.showEventLogDialog} />
                            <PointsInAlarmDialog
                                onClose={toggleShowPointsInAlarmDialog}
                                customer={customer}
                                show={dialogState.showPointsInAlarmDialog} />
                            <div className={`flex flex-1 flex-col xl:flex-row ${mobileActiveClassName}`}>
                                <SwitchSite />
                                <Dropdown title="Logs">
                                    {/* <NavDropdown.Item as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/alarmlogs`}>Alarm Log</NavDropdown.Item> */}
                                    <DropDownItem className={defaultLinkClassName} onClick={toggleShowCurrentPointAlarmsDialog}>Current Point Alarms</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} onClick={toggleShowAlarmLogDialog}>Alarm Log</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} onClick={toggleShowEventLogDialog}>Event Log</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} onClick={toggleShowPointsInAlarmDialog}>Points In Alarm</DropDownItem>
                                </Dropdown>
                                <Dropdown title="Histories">
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/histories`}>Point History</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/historiestemplates`}>History Templates</DropDownItem>
                                </Dropdown>
                                {(currentUser.get('isSuperAdmin') || currentUser.get('isAdmin')) && <Dropdown title="Configuration">
                                    {currentUser.get('mc') &&
                                        <DropDownItem className={defaultLinkClassName} onClick={openNewCustomerDialog}>Create Customer</DropDownItem>}
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/customersetup`}>Customer Setup</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/subsystems`}>Subsystem Setup</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/alarmsetup`}>Alarm Retransmission Setup</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/alarmfiltergroups`}>Alarm Filter Groups</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/pointeditor`}>Point Editor</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/pointliststatus`}>Point List Status</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/pointgroups`}>Point Groups</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/usergroups`}>User Groups</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/subsystemstatus`}>Subsystem Status</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} onClick={() => openConnectedUsersDialog('connectedusers')}>Users</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/calendars`}>Calendar</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/time-templates`}>Time Templates</DropDownItem>
                                    <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/databasemaintenance`}>Database Maintenance</DropDownItem>
                                </Dropdown>}
                                {!currentUser.get('isReadOnly') &&
                                    <Dropdown title="Assets">
                                        <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/assets`}>Asset List</DropDownItem>
                                        <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/documents`}>Document List</DropDownItem>
                                        <DropDownItem className={defaultLinkClassName} as={Link} to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/iptables`}>IP Address Tables</DropDownItem>
                                    </Dropdown>
                                }
                                <div>
                                        <NavLink to={`/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/alarms`}
                                            className="inline-flex w-full xl:justify-center items-center px-6 py-3 text-[rgba(255,255,255,0.5)] hover:text-[rgba(255,255,255,0.75)] hover:bg-[#1c3d5a]"
                                            >Alarms</NavLink>
                                    </div>
                                    
                            </div>
                        </React.Fragment>
                    )}
                    {(!customer && (currentUser.get('isSuperAdmin') || currentUser.get('isAdmin')) &&
                        <React.Fragment>
                            {/* <Nav className="mr-auto"> */}

                            <div className={`flex flex-1 flex-col xl:flex-row ${mobileActiveClassName}`}>
                                <div onClick={openNewCustomerDialog} className={`inline-flex w-full xl:justify-center items-center px-6 py-3 text-[rgba(255,255,255,0.5)] hover:text-[rgba(255,255,255,0.75)] hover:bg-[#1c3d5a] cursor-pointer`}>Create Customer</div>
                            </div>

                            {/* <Nav.Link onClick={this.openNewCustomerDialog.bind(this)}>Create Customer</Nav.Link> */}
                            {/* </Nav>     */}
                        </React.Fragment>
                    )}
                </BmsMenu>
            </MenuZoomWrapper>


            {/* {<LeftSlide>
                <Switch>
                    <Route path="/customer/:customer/site/:site" render={(props) => {
                        return <SiteSidebar />;
                    }} />
                </Switch>
            </LeftSlide>} */}

            <Outlet />
        </AlarmFilterGroupsProvider>
    )
}

export function HistoriesRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <Histories customer={customer} />;
    }
    return null;
}

export function HistoriesTemplatesRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <HistoriesTemplatesList params={customer} />;
    }
    return null;
}

export function CalendarsRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <Calendars customer={customer} />;
    }
    return null;
}

export function TimeTemplatesRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <TimeTemplates customer={customer} />;
    }
    return null;
}

export function SubsystemsRoute() {
    const { customer } = useBMS();
    const params = useParams();
    if(customer) {
        return <Subsystems params={params} />;
    }
    return null;
}

export function SubsystemStatusRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <SubsystemStatus params={customer} />;
    }
    return null;
}

export  function ConnectedUsersRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <ConnectedUsers params={customer} />;
    }
    return null;
}

export function DatabaseMaintenanceRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <DatabaseMaintenance customer={customer} />;
    }
    return null;
}

export function PointEditorRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <PointEditor customer={customer} />;
    }
    return null;
}

export function PointGroupsRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <PointGroups customer={customer} />;
    }
    return null;
}

export function UserGroupsRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <UserGroups customer={customer} />;
    }
    return null;
}

export function CustomerSetupRoute() {
    const { customer } = useBMS();
    const {params} = useBMS();
    if(customer) {
        return <CustomerSetup params={params} />;
    }
    return null;
}

export function AlarmSetupRoute() {
    const { customer } = useBMS();
    const {params} = useBMS();
    if(customer) {
        return <AlarmSetup params={params}  />;
    }
    return null;
}

export function AlarmFilterGroupsRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <AlarmFilterGroups customer={customer} />;
    }
    return null;
}

export function PointListStatusRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <PointListStatus params={customer} />;
    }
    return null;
}

export function AssetListRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <AssetList customer={customer} />;
    }
    return null;

}

export function DocumentListRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <DocumentList customer={customer} />;
    }
    return null;

}

export function IpTablesRoute() {
    const { customer } = useBMS();
    if(customer) {
        return <IpTables customer={customer} />;
    }
    return null;

}



function MenuZoomWrapper({ children }) {

    const { customerSettings } = useCustomerSettings();
    const { userSettings } = useUserSettings();

    let defaultZoom = 1;

    if (customerSettings && customerSettings.get('defaultZoomLevel')) {
        defaultZoom = Number(customerSettings.get('defaultZoomLevel')) / 100;
    }

    if (userSettings && userSettings.get('defaultZoomLevel')) {
        defaultZoom = Number(userSettings.get('defaultZoomLevel')) / 100;
    }


    return <div style={{ zoom: defaultZoom }}>
        {children}
    </div>
}



export default connect((state) => ({
    editmode: state.get('editmode')
}))(Customer);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
