import React, { useEffect, useRef } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import ServiceBus from 'Services/ServiceBus';
import { POLLING_TIME } from './';
import TransitioningDialog from '../../../../Controls/Dialog/TransitioningDialog';

function AreaPointList({ customer, points }) {   
    const helpers = useRef();
    const [polledPoints, setPolledPoints] = useStateIfMounted(points);

    helpers.current = {
        getPolledPoints: () => polledPoints,
        setPolledPoints: setPolledPoints
    };

    useEffect(() => {
        // console.log('%c Mounting AreaPointList', 'color:yellow')

        let unbindPointStatus = ServiceBus.on('300-11', processPointStatus);
        setTimeout(getPointsStatusses);
        let polling = setInterval(getPointsStatusses, POLLING_TIME);
       
        return () => {
            // console.log('%c unmounting AreaPointList', 'color:yellow')
            unbindPointStatus();
            clearInterval(polling);
        }
    }, []);

    const getPointsStatusses = () => {
        let polledPoints = helpers.current.getPolledPoints();

        // console.log('polled point : ',polledPoints);

        // console.log('%c processPointStatusses in AreaPointListDialog', 'color:blue');
        if(!polledPoints) {
            // console.log('%c returning with none - processPointStatusses in AreaPointListDialog', 'color:red');
            return;
        }

        for (let i in polledPoints) {
            if (i != 'lastupdated') {
                let request = {
                    trackMessage: true,
                    m_level: 10,
                    m_communicNum: 300,
                    target: 'signalr',
                    type: "GENERIC",
                    m_subsystemNumber: i.split('-')[0],
                    databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
                }
                let comm = {
                    m_pointAddr: i.split('-').map((bit) => { return parseInt(bit); })
                }
                ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
            }
        }
    }

    const processPointStatus = (message) => {
        let points = helpers.current.getPolledPoints();
        // console.log('%c processPointStatus in AreaPointListDialog2', 'color:purple');
        let { m_pointStatusData } = message;
        if (!m_pointStatusData) {
            m_pointStatusData = message;
        }
    //    let points = polledPoints;

        if (m_pointStatusData && m_pointStatusData.m_pointAddr) {
            let address = [
                m_pointStatusData.m_pointAddr[0],
                m_pointStatusData.m_pointAddr[1],
                m_pointStatusData.m_pointAddr[2],
                m_pointStatusData.m_pointAddr[3],
                m_pointStatusData.m_pointAddr[4],
                m_pointStatusData.m_pointAddr[5],
                m_pointStatusData.m_pointAddr[6],
                m_pointStatusData.m_pointAddr[7],
                m_pointStatusData.m_pointAddr[8],
                m_pointStatusData.m_pointAddr[9]
            ].join('-');
            
            if (points[address]) {
                points[address].value = m_pointStatusData.m_formattedValueText;
                points[address].components.forEach((component) => {
                    m_pointStatusData.address = address;
                    component.setValue(m_pointStatusData);
                });
                points[address].pointconfiguration = m_pointStatusData;
            }
        }
        points.lastupdated = Date.now();
        helpers.current.setPolledPoints(points);
        // console.log("points to set", points);
    }

    const formatPointAddress = (address) => {
        var prefix = ["I", "W", "S", "K", "TZ", "D", "PT", "PT", "PT", "PT", "PT", "PT", "TZ" ];
        if(!address) {
            return address;
        }

        let addr = address.split('-');
        if(addr[1] == 5) {
            let newAddress = `L${addr[3]} O${addr[4]} ${prefix[addr[2]]}${addr[5]}`;
            return newAddress;
        }
        return "Not Factored";
    }

    function *renderPoints(points) {
        if (!points) {
            return;
        }

        let ctr = 0;
        let p = Object.keys(polledPoints);

        for (var i in points) {
            if (i == 'lastupdated') {
                return;
            }
            let point = points[i];
            if (!point.pointconfiguration) {
                
                yield (
                    <div key={i} className="col-span-8">
                        <p className="text-sm text-gray-900">Please wait while we are getting the point data for: {p[ctr]}</p>
                    </div>
                );
                ctr++;
            }
            else {
                yield (
                    <li key={i} className="bg-white" >
                        <div className="relative px-1 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">        
                            <div className="grid grid-cols-12 h-full">
                                <div className="col-span-3">
                                    <p className="text-sm text-gray-900 py-1">{point.pointconfiguration.m_pointName}</p>
                                </div>
                                <div className="col-span-3">
                                    <p className="text-sm text-gray-900 py-1">{formatPointAddress(i)}</p>
                                </div>
                                <div className="col-span-3">
                                    <p className="text-sm text-gray-900 py-1">{i}</p>
                                </div>
                                <div className="col-span-3">
                                    <p className="text-sm text-gray-900 py-1">{point.pointconfiguration.m_formattedValueText}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                )
            }
        }
    }

    return (
        <React.Fragment>
            <div className="mt-2 sm:mt-3">
                <h2 className="text-xl leading-6 font-semibold text-gray-900">
                    Point Status List
                </h2>
            </div>
            <div className="sm:px-4 lg:px-6 p-2  h-full overflow-y-scroll"  style={{ minHeight: "70vh", maxHeight: "70vh" }}>
                <ul role="list" className="relative z-0">
                    <li key={'header'} className="bg-blue">
                    <div className="relative px-1 py-1">
                        <div className="grid grid-cols-12 text-white h-full">
                            <div className="col-span-3 py-1">Name</div>
                            <div className="col-span-3 py-1">Address</div>
                            <div className="col-span-3 py-1">Reference</div>
                            <div className="col-span-3 py-1">Value</div>
                        </div>
                    </div>    
                    </li>
                    {[...renderPoints(polledPoints)]}                                   
                </ul>
            </div>
        </React.Fragment>       
    )
}

function AreaPointListDialog({selecteditem, customer, points, onClose }) {   
    useEffect(() => {
        // console.log('%c mounting AreaPointListDialog', 'color:red')
        return () => {
            // console.log('%c unmounting AreaPointListDialog', 'color:red')
        }
    }, [])

    return <TransitioningDialog zIndex="z-20" opened={!!selecteditem} onClose={onClose} > 
        <div> 
            <div>
                 <AreaPointList customer={customer} points={points} /> 
            </div>
            <div className="p-2 flex justify-end">
                <button className="bg-[#6c757d] p-2 text-[white]" onClick={onClose}>Close</button>
            </div>
        </div>
    </TransitioningDialog>
}

export default AreaPointListDialog;


