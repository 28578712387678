import React, {useState, useEffect} from 'react';
import Grid from 'Controls/GridV2';
import ServiceBus from 'Services/ServiceBus';

import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function ConnectUsers({show, onClose, ...props}) {
    return <Dialog opened={!!show} onClose={onClose}>
        <ConnectUsersPanel show={show} onClose={onClose} {...props} />
    </Dialog>
}


function ConnectUsersPanel({show, customer, sa, onClose}) {
    let [users, setUsers] = useState();

    useEffect(() => {
        let unsubscribe = ServiceBus.on('CONNECTED_USERS_RESP', (msg) => {setUsers(msg)}, true);//getConnectedUsersHandler);

        if (customer && show) getConnectedUsers();
      
        return () => {
            setUsers([]);
            // ServiceBus.off('CONNECTED_USERS_RESP', getConnectedUsersHandler);
            unsubscribe();
        };
    },[show]);

    const getConnectedUsers = () => {
        return new Promise((resolve, reject) => {
            ServiceBus.send('WEB_MESSAGE_REQ', {
                type:'GET_CONNECTED_USERS',
                target:'webserver',
                customer:customer.get('name').toLowerCase().replace(/ /g, ""),
                sa:sa
            });
        });
    }

    const getConnectedUsersHandler = (msg) => {
        setUsers(msg);
    }

    const formattedDateTime = (datetime) => {
        let dt = new Date(datetime);
        return `${("0" + dt.getDate()).slice(-2)}/${("0" + (dt.getMonth()+1)).slice(-2)}/${dt.getFullYear()} ${("0" + dt.getHours()).slice(-2)}:${("0" + dt.getMinutes()).slice(-2)}:${("0" + dt.getSeconds()).slice(-2)}`;
    }

    let c=sa ? ["col-span-16 md:col-span-5","col-span-16 md:col-span-2","col-span-16 md:col-span-3","col-span-16 md:col-span-3"]:["col-span-16 md:col-span-5","col-span-16 md:col-span-3","col-span-16 md:col-span-4","col-span-16 md:col-span-4"];

    return (
        <DialogContent className="">
            <DialogHeader>
                <DialogTitle>
                    Connected Users   
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="my-4">
                    <h4>User List</h4>
                    <div>
                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes={c[0]}>E-Mail</Grid.Column>
                                <Grid.Column classes={c[1]}>IP Address</Grid.Column>
                                <Grid.Column classes={c[2]}>Connected Time</Grid.Column>
                                <Grid.Column classes={c[3]}>Session ID</Grid.Column>
                                {sa && <Grid.Column classes="col-span-16 md:col-span-3">Customer</Grid.Column>}
                            </Grid.Header>    
                            <Grid.Data>
                                {(users || []).map((user, index) => {
                                    return (
                                        <Grid.Row key={index} className="hover:bg-green-lighter">
                                            <Grid.Column classes={c[0]}>{user.email}</Grid.Column>
                                            <Grid.Column classes={c[1]}>{user.lastloggedinaddress}</Grid.Column>
                                            <Grid.Column classes={c[2]}>{formattedDateTime(user.lastloggedindatetime)}</Grid.Column>
                                            <Grid.Column classes={c[3]}>{user.sessionid}</Grid.Column>
                                            {sa && <Grid.Column classes="col-span-16 md:col-span-3">{user.customer}</Grid.Column>}
                                            
                                        </Grid.Row>
                                    );    
                                })}
                            </Grid.Data>
                        </Grid>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={() => onClose()}>Close</Button>
            </DialogFooter>
        </DialogContent>
    )
}

export default ConnectUsers;
