import React, { useState, Fragment, useRef } from 'react';
import { Map, List } from 'immutable';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function timeIndexToString(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

export default function Draggable({ className, children, elementIndex, span, onChange, show24 }) {

    const [randomNumber, forceRerender ] = useState(Math.random().toString());

    const currentElement = useRef(null);
    const dragging = useRef(false);
    const initialY = useRef(0);
    const newIndex = useRef(elementIndex);

    const resetState = () => {
        // console.log("should have resetted state");
        forceRerender(Math.random().toString());
        dragging.current = false;
        initialY.current = 0;
        newIndex.current = elementIndex;
    } 

    const adjustElement = (offset) => {
        let boundries = currentElement.current.getBoundingClientRect();
        let height = boundries.height;
        let cells = span;
        let heightPerCell = height / cells;
        let maxOffsetUp = (elementIndex % (48 * 30)) * heightPerCell * -1;
        let maxOffsetBottom = heightPerCell * 48 * 30 + maxOffsetUp - (height);
        let newIndex = elementIndex % (48 * 30) + (Math.round(offset / heightPerCell));
        newIndex = newIndex - (newIndex % 5);
        if(offset < maxOffsetUp || offset > maxOffsetBottom) {
            if(offset < maxOffsetUp) {
                newIndex = 0;
                offset = maxOffsetUp;
            }
            if(offset > maxOffsetBottom) {
                newIndex = 48 * 30 - span;
                offset = maxOffsetBottom;
            }
        }
        currentElement.current.style.position = "absolute";
        currentElement.current.style.top = Math.round(offset) + "px";
        currentElement.current.style.width = Math.round(boundries.width) + "px";
        currentElement.current.style.height = Math.round(boundries.height) + "px";
        currentElement.current.style.maxWidth = Math.round(boundries.width) + "px";
        currentElement.current.style.maxHeight = Math.round(boundries.height) + "px";
        let newStart = Number(newIndex);
        let newEnd = Number(newIndex) + Number(span);
        let startString = timeIndexToString(newStart);
        let endString = timeIndexToString(newEnd);
        currentElement.current.style.backgroundColor = "rgba(255,255,0,0.5)";
        currentElement.current.innerHTML = `${startString} - ${endString == "24:00" ? show24 && endString || "00:00" : endString}`;
        currentElement.current.style.zIndex = 99
        return newIndex;
    }

    const onMouseDown = (e) => {
       if(!currentElement.current) {
            // console.log("no current element found, ignoring");
            return;
       }
        e.preventDefault(e);
        if(e.button != 0) {
            return;
        }
        dragging.current = true;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
       
    }

    const onMouseLeave = (e) => {
        if(dragging.current) {
            onChange(newIndex.current);
        }
        dragging.current = false;
    }

    const onMouseUp = (e) => {
        if(dragging.current) {
            let currentY = e.movementY;
            initialY.current += Number(currentY);
            let result = adjustElement(initialY.current);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            if(result > -1) {
                newIndex.current = result;
                if((elementIndex % (48 * 30)) == newIndex.current) {
                    resetState();
                }
                onChange(result);
            } else {
                resetState();
            }
        } else {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }
    }

    const onMouseMove = (e) => {
        
        if(!dragging.current) {
            return;
        }
        let currentY = e.movementY;
        let result = adjustElement(initialY.current + Number(currentY))
        if(result > -1) {
            initialY.current += Number(currentY);
            newIndex.current = result;
        }
        e.preventDefault();
    }



    return <div key={randomNumber}
        // onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        // onMouseMove={onMouseMove}
        className={` cursor-move ${className}`} 
        // onMouseLeave={onMouseLeave}
        ref={currentElement}>
        {children}
        
    </div>
}