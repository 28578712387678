import React from 'react';

class ContextMenu extends React.Component {

    constructor(props) {
        super(props)
        this.contextDiv = React.createRef();

    }

    componentDidUpdate() {
        let { contextMenu } = this.props;
        let { contextDiv } = this;
        if(contextMenu) {
            let screenWidth = document.body.clientWidth;
            let screenHeight = Math.max(document.body.clientHeight, window.innerHeight);
            let elementWidth = this.contextDiv.current.offsetWidth;
            let elementHeight = this.contextDiv.current.offsetHeight;
            let top = contextMenu.y;
            let left = contextMenu.x;

            if(top + elementHeight > screenHeight) {
                contextDiv.current.style.top = (top - elementHeight) + "px";
            } 

            if(left + elementWidth > screenWidth) {
                contextDiv.current.style.left = (left - elementWidth) + "px";
            }
        }
    }

    render() {
        let { contextMenu, children, onClose, onPositionChange, className = '' } = this.props;

        if (!contextMenu) {
            return <data></data>
        }
        return (
            <React.Fragment>
                <div className={`fixed z-40 pin cursor-default w-full h-full ${className}`} onClick={onClose} onContextMenu={onPositionChange}></div>
                <div ref={this.contextDiv} className="flex fixed z-50 border border-grey bg-white cursor-pointer flex-col" style={{ left: contextMenu.x, top: contextMenu.y }}>
                    {children}
                </div>
            </React.Fragment>

        )
    }
}


ContextMenu.Item = (props) => {
    let { children, ...other } = props;
    return (
        <a className="block flex-1 w-full hover:bg-blue w-full px-4 py-2 hover:text-white" {...other}>{children}</a>
    )
}

export default ContextMenu;