import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import ColorPicker from 'Controls/ColorPicker';

class Chart extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }
    render() {
        let { selectedcomponent } = this.props;

        let dateSelectionTypeFields = ['Previous Hour','Today','Yesterday','This Week','Last Week','This Month','Last Month','Custom'
        ];

        return (
            <React.Fragment>
                <Input
                    label="Point Name"
                    type="text"
                    value={selectedcomponent.getIn(['configuration', 'pointname'], '')}
                    onChange={this.valueChanged.bind(this, ['configuration', 'pointname'])}
                />  
                <Input
                    label="Engineering Unit"
                    type="text"
                    value={selectedcomponent.getIn(['configuration', 'engunit'], '')}
                    onChange={this.valueChanged.bind(this, ['configuration', 'engunit'])}
                />  
                <Select
                        label="Default Period"
                        value={selectedcomponent.getIn(['configuration', 'period'])}
                        onChange={this.valueChanged.bind(this, ['configuration', 'period'])}
                        options={dateSelectionTypeFields.map((type) => {
                            return {
                                value: type,
                                text: type
                            }
                        })}
                />
                <Input
                    label="Chart Header"
                    type="text"
                    value={selectedcomponent.getIn(['configuration', 'header'])}
                    onChange={this.valueChanged.bind(this, ['configuration', 'header'])}
                /> 
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Line colour" value={selectedcomponent.getIn(['configuration', 'linecolour'])} onChange={this.valueChanged.bind(this, ['configuration', 'linecolour'])} /></div>
                </div>
            </React.Fragment>
        )
    }
}

export default Chart;