import React, {useState, useEffect } from 'react';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import Timezones from 'BmsView/Customer/Site/Area/PointStatusDialog/Timezones';
import Immutable from 'immutable';

function SchedulePointGroupDialog({selecteditem, onClose, currentuser, customer, address,  ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose} currentuser={currentuser} customer={customer} address={address} >
        <SchedulePointGroupDialogPanel group={selecteditem} onClose={onClose} currentuser={currentuser} customer={customer}  address={address} {...props} />
    </Dialog>
}

const SchedulePointGroupDialogPanel = ({currentuser, group, customer, address, onClose}) => {
    //let selectedpoint = 

    return (
        <DialogContent> 
            <DialogHeader>
                <DialogTitle as="h3" className="text-lg leading-6 font-medium text-black">Schedule Point Group</DialogTitle>
            </DialogHeader> 
            <DialogBody className="md:w-[50rem]">
            <Timezones currentuser={currentuser} customer={customer} selectedpoint={group} group={group} address={address} />

            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={onClose}>Close</Button></div>
            </DialogFooter>
        </DialogContent>
    )
}


export default SchedulePointGroupDialog;