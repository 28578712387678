import React from 'react';
import { ExclamationTriangleIcon, BellSlashIcon, BellAlertIcon, InformationCircleIcon, WrenchScrewdriverIcon, CheckCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export const alarmSounds = ['ding', 'chimes', 'chord', 'buzzer_rd', 'buzzer_x', 'buzzer3_x'];

export const evaluatableConditions = {
    "$range": "In range of",
    "$eq": "Equal to",
    "$neq": "Not equal to",
    "$in": "In (comma seperated)",
    "$nin": "Not in (comma seperated)"
}

export const evaluateCondition = (condition, compare, value) => {
    let subjectValue = compare;
    if(['$in','$nin','$range'].indexOf(condition) > -1) {
        let splitCharacter = condition == '$range' ? '-' : ',';
        subjectValue = compare.split(splitCharacter).map(v => parseInt(v));
    } else {
        subjectValue = parseInt(compare);
    }

    switch(condition) {
        case '$eq':
            return subjectValue == value;
        case '$neq':
            return subjectValue != value;
        case '$in':
            return subjectValue.indexOf(value) > -1;
        case '$nin':
            return subjectValue.indexOf(value) == -1;
        case '$range':
            return value >= subjectValue[0] && value <= subjectValue[1];
    }

    return value;

}

window.evaluateCondition = evaluateCondition;

export const alarmDisplayInfo = [
    {
        "type" : "Alarm Cleared",
        "colour" : "green",
        "Icon" : BellSlashIcon 
    },
    {   "type" : "Alarm",
        "colour" : "red",
        "Icon" : BellAlertIcon 
    },
    {   "type" : "Warning",
        "colour" : "orange",
        "Icon" : ExclamationTriangleIcon 
    }, 
    {   "type" : "Information",
        "colour" : "blue",
        "Icon" : InformationCircleIcon 
    },
    {   "type" : "Maintenance",
        "colour" : "gray",
        "Icon" : WrenchScrewdriverIcon 
    },
    {   "type" : "Critical Alarm",
        "colour" : "darkred",
        "Icon" : BellAlertIcon ,
        "animate" : true
    }
]

export const backgroundColour = {
    'orange': '!bg-orange-50',
    'green': '!bg-green-50',
    'blue': '!bg-green-50',
    'red': '!bg-red-50',
    'darkred': '!bg-red-500',
    'gray': '!bg-gray-50'
}

export const backgroundBorderColour = {
    'orange': 'border-orange-500',
    'green': 'border-green-500',
    'blue': 'border-green-500',
    'red': 'border-red-500',
    'darkred': 'border-red-500',
    'gray': 'border-gray-500'
}

export const alarmTextColour = {
    'orange': 'text-orange-600',
    'green': 'text-green-600',
    'blue': 'text-blue-600',
    'red': 'text-red-600',
    'darkred': 'text-white-600',
    'gray': 'text-gray-600'
}
