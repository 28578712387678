import React from 'react';
import Immutable from 'immutable';
import ColorPicker from 'Controls/ColorPicker';
import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';
import Gradient from './Gradient';

class DynamicTable extends React.Component {
    constructor(props) {
        super(props);
    }

    valueChanged(field, convert, value) {
        if (convert) {
            value = convert(value);
        }
        let { onChange } = this.props;
        onChange(field, value);
    }

    colWidthChanged(col, index, value) {
        let { selectedcomponent, onChange } = this.props;

        var cols = selectedcomponent.getIn(['configuration', 'cols'], Immutable.List([Immutable.Map({"width":160})])); 
        let newcol = cols.get(index);

        newcol = newcol.set('width', parseInt(value));

        cols = cols.set(index, newcol);
        onChange(['configuration', 'cols'], cols);
    }

    addColumn() {
        let { selectedcomponent, onChange } = this.props;

        let cols = selectedcomponent.getIn(['configuration', 'cols'], Immutable.List([Immutable.Map({'width':160})]));       
        cols = cols.push(Immutable.Map({'width':160}));

        onChange(['configuration', 'cols'], cols);
    }

    removeColumn(index) {
        let {selectedcomponent, onChange} = this.props;

        let cols = selectedcomponent.getIn(['configuration','cols']).delete(index);

        onChange(['configuration', 'cols'], cols);
    }

    render() {
        let {selectedcomponent} = this.props;
        let { onChange } = this.props;

        return (
            <React.Fragment>
                <div className="grid grid-cols-16 gap-2 pl-2">
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background Colour" value={selectedcomponent.getIn(['configuration', 'style', 'color'],'lightgrey')} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'], undefined)} /></div>
                </div>
                <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradient'])}
                    gradients={selectedcomponent.getIn(['configuration', 'grads'])}
                    onChange={onChange}/>
                <div className="grid grid-cols-16 mt-2 gap-2 pl-2">
                    <div className='col-span-16 md:col-span-3'>
                        <Input
                            label="Rows"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rows'], 1)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rows'], parseInt)}
                        />
                    </div>  
                    <div className='col-span-16 md:col-span-3'>
                        <Input
                            label="Height"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rowheight'], 35)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rowheight'], parseInt)}
                        />
                    </div>  
                </div>    
                    <div className="grid grid-cols-16 gap-2 pl-2">
                        <div className="col-span-16 md:col-span-3">
                            <label className="block label">Column Width</label>
                        </div>
                        <div className="col-span-16 md:col-span-13">
                        </div>
                    </div>    
                    { selectedcomponent.getIn(['configuration', 'cols'], Immutable.List([Immutable.Map({"width":160, "colour":"lightgrey"})])).map((col, index) => {
                        return (
                            <div className="grid grid-cols-16 gap-2 pl-2">
                                <div className='col-span-16 md:col-span-3'>
                                    <Input
                                        type="number"
                                        value={col.get('width')}
                                        onChange={this.colWidthChanged.bind(this, ['configuration', 'cols'], index )}
                                    />
                                </div>
                                <div className="col-span-16 md:col-span-3">
                                    <div className="pl-1"><Button role="cancel" onClick={this.removeColumn.bind(this, index)}>Remove Column</Button></div>
                                </div>
                                <div className="col-span-16 md:col-span-10">
                                </div>
                            </div>
                        )})
                    } 
                    <div>
                        <div className="pl-2"><Button role="success" onClick={this.addColumn.bind(this)}>Add Column</Button></div>
                    </div>                      
            </React.Fragment>
        )
    }
}

export default DynamicTable;