import React, { useState, useEffect, useRef } from 'react';
import ContextMenu from 'Controls/ContextMenu';
import { Map, List } from 'immutable';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

let transparentImage = new Image();
transparentImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR4nGNgYGBgAAAABQABpfZFQAAAAABJRU5ErkJggg=='


const layerManagerStyle = {

    width: '130px',
    cursor: 'pointer',
    background: 'rgba(255,255,255,0.9)',
    // border: '1px solid black',

}

const layerManagerExpandedStyle = {
    ...layerManagerStyle,
    minHeight: '200px',
    bottom: '10px',
    right:'10px',
}

const layerManagerHeaderStyle = {
    padding: '10px',
    fontWeight: 'bold'
}

const layerStyle = {
    padding: '10px',
    background: "#eee"
}

const layerSelectedStyle = {
    ...layerStyle,
    background: "blue",
    color: "white"
    
}

export default function LayerManager({ layers, onChange, onSelect, currentLayerIndex }) {

    const [contextMenu, setContextMenu] = useState(false);
    const [expanded, setExpanded] = useState(true);


    const styleRef = useRef({ marginTop: 0  });
    const buttonRef = useRef(null);

    const setMarginTop = () => {

    
        let marginTop = styleRef.current.marginTop;
        let elementY = styleRef.current.elementY;
        let startY = styleRef.current.startY;
        let startCursorYRelativeToElement = startY - elementY;
        let initialY = elementY + startCursorYRelativeToElement - marginTop;
        let currentY = styleRef.current.y;
        let diff = currentY - initialY;
        let marginTopPX = diff + 'px';
        buttonRef.current.style.marginTop = marginTopPX;
       
        return diff;
    }

    const buttonDragStartEvent = (evt) => {
        let startPosition = buttonRef.current.getBoundingClientRect();
        let elementY = startPosition.y;
        let startY = Math.max(elementY, evt.clientY);
    
        let yCorrection = expanded ? evt.clientY - elementY : 0;

        styleRef.current.elementY = elementY;
        styleRef.current.startY = evt.clientY; 
        styleRef.current.y = styleRef.current.startY ;
        setMarginTop();

        buttonRef.current.addEventListener('drag', buttonDragEvent);
        buttonRef.current.addEventListener('dragend', buttonDragEndEvent);
        
        evt.dataTransfer.setDragImage(transparentImage, 0, 0);
    }

    const buttonDragEvent = (evt) => {
        // console.log("button drag event called");

        // console.log(evt);
        let draggedY = Math.max(styleRef.current.startY - styleRef.current.marginTop, evt.clientY)
        styleRef.current.y = draggedY; //expanded ? draggedY : evt.clientY;
        setMarginTop();
    }

    const buttonDragEndEvent = (evt) => {
        let draggedY = Math.max(styleRef.current.startY - styleRef.current.marginTop, evt.clientY)
        styleRef.current.y = draggedY; //expanded ? draggedY : evt.clientY;
        let marginTop = setMarginTop();
        buttonRef.current.removeEventListener('drag', buttonDragEvent);
        buttonRef.current.removeEventListener('dragend', buttonDragEndEvent);
        styleRef.current.marginTop = marginTop;


    }

    const onRightClickLayer = (layer, x, y) => {
        setContextMenu({
            layer: layer,
            x: x,
            y, y
        })
    }

    const moveLayerUp = (layer) => {
        let currentIndex = layer.get('originalIndex');
        if(currentIndex + 1 == layers.size)  {
            return;
        }
        let nextIndex = currentIndex + 1;
        let nextLayer = layers.get(nextIndex);
        onChange(layers.set(currentIndex, nextLayer).set(nextIndex, layer.delete('originalIndex')));
        setContextMenu(false);
    }

    const moveLayerDown = (layer) => {
        let currentIndex = layer.get('originalIndex');
        if(currentIndex == 0)  {
            return;
        }
        let nextIndex = currentIndex - 1;
        let nextLayer = layers.get(nextIndex);
        onChange(layers.set(currentIndex, nextLayer).set(nextIndex, layer.delete('originalIndex')));
        setContextMenu(false);
    }

    const deleteLayer = (layer) => {
        onChange(layers.delete(layer.get('originalIndex')));
    }

    let _layerManagerStyle = expanded ? layerManagerExpandedStyle : layerManagerStyle;

    return <React.Fragment>
        <div ref={buttonRef} draggable onDragStart={buttonDragStartEvent}  className={`border border-black absolute ${expanded ? 'right-2' : 'right-0'} mt-2 bg-white`}>
            
             <ToolboxToggleButton 
            expanded={expanded}
            toggleExpanded={() => setExpanded((expanded) => !expanded)}
            className={`-left-[2.1rem] absolute border box-border border-black py-2 ${expanded ? 'bg-white' : 'text-white bg-blue-500'}`} />
        
             <div className={`${expanded ? 'block' : 'hidden'} shadow-md `}>
                <div id="toolbox-portal-space"></div>
                    <div style={_layerManagerStyle}>
                        <div style={layerManagerHeaderStyle}>
                            Layers
                        </div>
                        { expanded && layers && layers.map((x, i) => x.set('originalIndex', i)).reverse().map((layer, index) => {
                            return <Layer key={index} selected={layer.get('originalIndex') == currentLayerIndex} onNormalClick={() => { onSelect(layer.get('originalIndex')) }} onClick={(x, y) => onRightClickLayer(layer, x, y)}>
                                {layer.get('name')}
                            </Layer>
                        }).toList()}
                    </div>
                    <ContextMenu contextMenu={contextMenu} onClose={() => { setContextMenu(false) }} onPositionChange={(e) => {  
                        setContextMenu(false);
                        e.stopPropagation();
                        e.preventDefault();
                    
                    }}>
                        {contextMenu.layer && <ContextMenu.Item onClick={() => moveLayerUp(contextMenu.layer)}>Move layer up</ContextMenu.Item>}
                        {contextMenu.layer && <ContextMenu.Item onClick={() => moveLayerDown(contextMenu.layer)}>Move layer down</ContextMenu.Item>}
                        {contextMenu.layer && <ContextMenu.Item onClick={() => deleteLayer(contextMenu.layer)}>Remove layer</ContextMenu.Item>}
                        {<ContextMenu.Item onClick={() => { onChange(layers.push(Map({ nodes: List(), name: `new layer #${layers.size}`}) ));setContextMenu(false); }}>Add new layer</ContextMenu.Item>}
                    </ContextMenu>
                </div> 
            
        </div>
    </React.Fragment>

}

function ToolboxToggleButton({ className = '', expanded, toggleExpanded }) {
    return <button className={`${className}`} onClick={toggleExpanded} >
        { !expanded && <ChevronDoubleLeftIcon className="w-8 h-8" /> }
        { expanded && <ChevronDoubleRightIcon className="w-8 h-8" /> }
    </button>

}

export function Layer({ children, onClick, onNormalClick, selected }) {

    const innerRef = useRef(null);

    const handleClick = (e) => {
        onClick(e.pageX, e.pageY);
        e.preventDefault();
        e.stopPropagation();
    }

    // useEffect(() => {
    //     const div = innerRef.current;
    //     div.addEventListener("contextmenu", handleClick);
    //     return () => {
    //         div.removeEventListener('contextmenu', handleClick);
    //     }
    // })

    return <div style={selected ? layerSelectedStyle : layerStyle} ref={innerRef} onClick={onNormalClick} onContextMenu={handleClick}>
        {children}
    </div>

}