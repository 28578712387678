import React, { useState, Fragment, useRef } from 'react';
import { Map, List } from 'immutable';
import { Menu, Transition } from '@headlessui/react'

function timeIndexToString(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

export default function Resizable({ className, children, elementIndex, span, onChange, show24  }) {

    const [randomNumber, forceRerender ] = useState(Math.random().toString());
    
    const upBorderRef = useRef(null);
    const downBorderRef = useRef(null);
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    const innerContainerRef = useRef(null);

    const resizing = useRef(false);
    const movement = useRef({ '-1': 0,  '1': 0 });
    const direction = useRef(-1);
    const initialDimensions = useRef(0);
    const newIndex = useRef(elementIndex);

    const resetState = () => {
        forceRerender(Math.random().toString());
        resizing.current = false;
        movement.current = { '-1': 0, '1': 0 };
        newIndex.current = elementIndex;
    } 

    const adjustElement = (offset, direction) => {
    
        let startIndex = elementIndex % (48 * 30);
        let height = initialDimensions.current.height;
        let cells = span;
        let heightPerCell = height / cells;
        let currentHeight = height + offset;
        let currentSpan = Math.round(currentHeight / heightPerCell);
        let maxOffsetUp = startIndex * heightPerCell * -1 - (direction == 1 ? height : 0);
        let maxOffsetBottom = heightPerCell * 48 * 30 + maxOffsetUp; //- (direction == -1 ? height : 0);
        let newIndex = Math.round(startIndex + (( offset) / heightPerCell));
        let newEndIndex = Math.round(startIndex + currentSpan);
        newIndex = newIndex - (newIndex % 5);
        newEndIndex = newEndIndex - (newEndIndex % 5);
        if(offset < maxOffsetUp || offset > maxOffsetBottom) {
            if(offset < maxOffsetUp) {
                newIndex = 0;
                newEndIndex = 0;
                offset = maxOffsetUp;
            }
            if(offset > maxOffsetBottom) {
                newIndex = 48 * 30 - (direction == -1 ? span : 0);
                newEndIndex = 48 * 30 - (direction == -1 ? span : 0);
                offset = maxOffsetBottom ;
            }
        }
        containerRef.current.style.minHeight = Math.round(initialDimensions.current.height) + "px";
        containerRef.current.style.height = Math.round(initialDimensions.current.height) + "px";
        innerContainerRef.current.style.position = "absolute";
        innerContainerRef.current.style.backgroundColor = "rgba(255,255,0,0.5)";
        innerContainerRef.current.style.zIndex = 99;
        if(direction == -1) {
            innerContainerRef.current.style.top = Math.round(offset) + "px";
            innerContainerRef.current.style.height = Math.round(initialDimensions.current.height + (-1 * offset)) + "px";
        } else {
            innerContainerRef.current.style.height = Math.round(initialDimensions.current.height + offset) + "px";
        }
        let newStart = direction == -1 ? Number(newIndex) : startIndex;
        let newEnd = direction == -1 ? startIndex + span : newEndIndex;
        let startString = timeIndexToString(newStart);
        let endString = timeIndexToString(newEnd);
        contentRef.current.innerHTML = `${startString} - ${endString == "24:00" ? show24 && endString || "00:00" : endString}`;
        contentRef.current.style.zIndex = 99
        return direction == -1 ? newIndex : newEndIndex;
    }

    const onMouseDown = (e, mouseDirection) => {
        e.stopPropagation(e);
        e.preventDefault(e);
        if(e.button != 0) {
            return;
        }
        initialDimensions.current = containerRef.current.getBoundingClientRect();
        direction.current = mouseDirection;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        resizing.current = true;
    }

    const onMouseUp = (e) => {
        e.stopPropagation(e);
        e.preventDefault(e);
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        // console.log("offset", movement.current[direction.current]);
        let newStartIndex = direction.current == -1 ? newIndex.current : elementIndex % (48 * 30);
        let newEndIndex = direction.current == -1 ? (elementIndex % (48 * 30)) + span : newIndex.current;
        if(newEndIndex <= newStartIndex) {
            resetState();

        } else {
            onChange(newStartIndex, newEndIndex);
        }
        
        
        resizing.current = false;
    }

    const onMouseMove = (e) => {
        let movementY = e.movementY;
        if(resizing.current) {
            movement.current[direction.current] += movementY;
            newIndex.current = adjustElement(movement.current[direction.current], direction.current);
        }
        e.preventDefault(e);
        e.stopPropagation();

    }

    const onMouseLeave = (e, direction) => {
        e.stopPropagation();
    }

    // flex flex-col 
    return <div ref={containerRef} key={randomNumber} className={`${className || ''} relative `}>
        <div ref={innerContainerRef} className="relative h-full w-full flex flex-col">
            <div ref={upBorderRef}
                onMouseDown={(e) => onMouseDown(e, -1)}
                // onMouseUp={(e) => onMouseUp(e, -1)}
                // onMouseMove={(e) => onMouseMove(e, -1)}
                // onMouseLeave={(e) => onMouseLeave(e, -1)}
                className="h-1 min-h-1 bg-[rgba(0,0,0,0.2)] w-full flex-none cursor-ns-resize">
                    

                </div>
            <div className="flex-1 border-x-4 border-[rgba(0,0,0,0.2)]" ref={contentRef}>
                {children}
            </div>
            <div 
                ref={downBorderRef}
                onMouseDown={(e) => onMouseDown(e, 1)}
                // onMouseUp={(e) => onMouseUp(e, 1)}
                // onMouseMove={(e) => onMouseMove(e, 1)}
                // onMouseLeave={(e) => onMouseLeave(e, 1)}
                className="h-1 min-h-1 bg-[rgba(0,0,0,0.2)] w-full flex-none cursor-ns-resize"></div>
        </div>
    </div>

}