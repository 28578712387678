import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import Grid from 'Controls/GridV2';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import Input from 'Controls/Form/Input';
import SubsystemEditDialog from './SubsystemEditDialog';
import TrendSetupDialog from './Trend';
import Ex500SetupDialog from './Ex500';
import BacnetSetupDialog from './Bacnet';
import CylonSetupDialog from './Cylon';
import ModbusSetupDialog from './Modbus';
import Button from 'Controls/Button/UserDefined';

import ModuleTypes from 'Configuration/ModuleTypes';
import {loadCustomers} from 'BmsView/Actions/Customers';
import {saveCustomer} from '../Actions';
import { withBMS } from '../../../Context/BMS';

class Subsystems extends React.Component {
    constructor(props) {
        super(props);

        let {customer} = this.props;      
        
        this.state = {
            currentcustomer: customer,
            currentsubsystem: undefined,
            opentrendsetup: false,
            openex500setup: false,
            openbacnetsetup: false
        };
    }

    saveSubsystem(subsystem) {
        let {customers} = this.props;
        let {currentcustomer, currentsubsystem} = this.state;

        let subsystems = currentcustomer.get('subsystems', Immutable.List([])); 
        let operation = "Updated"; 
        let operationerror ="Updating";

        let subsystemindex = subsystems.findIndex((item) => {
            return ( item.get('number') === subsystem.get('number') || (item.get('name') === subsystem.get('name')) );
        });

        // new entry ?
        if ( !currentsubsystem ) {
            if( subsystemindex != -1 ) {
                Swal.fire("Error", "Duplicate Name or Subsystem Number not allowed", "error");
                return;
            }

            else {
                subsystems = subsystems.push(subsystem);
                operation = "Added";
                operationerror ="Adding";
            }
        }

        else {
            // name and number changed ?
            if(!(currentsubsystem.get('name') === subsystem.get('name') && currentsubsystem.get('number') === subsystem.get('number'))) {
                // name changed ?
                if(currentsubsystem.get('name') !== subsystem.get('name')) {
                    subsystemindex = subsystems.findIndex((item) => {
                        return ( item.get('name') === subsystem.get('name') );
                    });

                    if (subsystemindex != -1 ) {
                        Swal.fire("Error", "Duplicate Name not allowed", "error");
                        return;
                    }
                }

                // number changed ?
                if(currentsubsystem.get('number') !== subsystem.get('number')) {
                    subsystemindex = subsystems.findIndex((item) => {
                        return ( item.get('number') === subsystem.get('number') );
                    });

                    if (subsystemindex != -1 ) {
                        Swal.fire("Error", "Duplicate Subsystem Number not allowed", "error");
                        return;
                    }
                }
            }

            subsystems = subsystems.set(subsystemindex, subsystem);
        }
                   
        currentcustomer = currentcustomer.set('subsystems', subsystems);

        let index = customers.findIndex((item) => {
            return item.get('name') === currentcustomer.get('name');
        }); 
        
        customers = customers.set(index, currentcustomer);
        
        saveCustomer(currentcustomer).then(() => {
            loadCustomers();

            this.setState({
                currentcustomer:currentcustomer
            });

            Swal.fire("Success", `Subsystem ${operation} Successfully"`, "success");
        }).catch((err) => {
            Swal.fire("Error", `"Error ${operationerror} Subsystem"`, "error");
        });
    }

    addSubsystem() {
        this.setState({
            selecteditem: Immutable.Map({
                'type': 5,
                'local': true,
                'signalR': 'localhost'
            })
        });
    }

    selectSubsystem(subsystem) {
        this.setState({
            selecteditem: subsystem,
            currentsubsystem: subsystem
        });
    }

    removeSubsystem(subsystem) {
        let {currentcustomer} = this.state;

        let subsystems = currentcustomer.get('subsystems');  
        subsystems = subsystems.delete(subsystem);
    //    subsystems.splice(subsystem, 1);
        
        currentcustomer = currentcustomer.set('subsystems', subsystems);
        
        saveCustomer(currentcustomer).then(() => {
            loadCustomers();

            this.setState({
                currentcustomer:currentcustomer
            });

            Swal.fire("Success", "Subsystem Removed Successfully", "success");
        }).catch((err) => {
            Swal.fire("Error", "Error Removing Subsystem", "error");
        });
    }

    configureSubsystem(subsystem) {                  
        if (subsystem.get('type') == 4) {
            this.setState({
                mbusselectedsubsystem: subsystem,
                currentsubsystem: subsystem
            });
        }

        if (subsystem.get('type') == 5) {              
            this.setState({
                opentrendsetup: true,
                trendselectedsubsystem: subsystem,
                currentsubsystem: subsystem
            });
        }

        if (subsystem.get('type') == 6) {
            this.setState({
                openex500setup: true,
                ex500selectedsubsystem: subsystem,
                currentsubsystem: subsystem
            });
        }

        if (subsystem.get('type') == 9) {
            this.setState({
                openbacnetsetup: true,
                bacnetselectedsubsystem: subsystem,
                currentsubsystem: subsystem
            });
        }

        if (subsystem.get('type') == 11) {
            this.setState({
                cylonselectedsubsystem: subsystem,
                currentsubsystem: subsystem
            });
        }

        if (subsystem.get('type') == 14) {
            this.setState({
                modbusselectedsubsystem: subsystem,
                currentsubsystem: subsystem
            });
        }
    }

    clearSelection() {
        this.setState({
            selecteditem: undefined,
            trendselectedsubsystem: undefined,
            ex500selectedsubsystem: undefined,
            bacnetselectedsubsystem: undefined,
            cylonselectedsubsystem: undefined,
            modbusselectedsubsystem: undefined,
            mbusselectedsubsystem: undefined,
            currentsubsystem:undefined,
            opentrendsetup: false,
            openex500setup: false
        });
    }

    render() {
        let {currentcustomer, opentrendsetup, openex500setup, openbacnetsetup} = this.state;

        return (
            <div className="container mx-auto my-4">
                {this.state.trendselectedsubsystem && <TrendSetupDialog opendialog={opentrendsetup} selecteditem={this.state.trendselectedsubsystem} customer={currentcustomer} onClose={this.clearSelection.bind(this)} />}
                {this.state.ex500selectedsubsystem && <Ex500SetupDialog opendialog={openex500setup} selecteditem={this.state.ex500selectedsubsystem} customer={currentcustomer} onClose={this.clearSelection.bind(this)} />}
                {this.state.bacnetselectedsubsystem && <BacnetSetupDialog opendialog={openbacnetsetup} selecteditem={this.state.bacnetselectedsubsystem} customer={currentcustomer} onClose={this.clearSelection.bind(this)} />}
                {this.state.cylonselectedsubsystem && <CylonSetupDialog selecteditem={this.state.cylonselectedsubsystem} customer={currentcustomer} onClose={this.clearSelection.bind(this)} /> }
                {this.state.modbusselectedsubsystem && <ModbusSetupDialog selecteditem={this.state.modbusselectedsubsystem} customer={currentcustomer} modbustype={0} onClose={this.clearSelection.bind(this)} />}
                <SubsystemEditDialog selecteditem={this.state.selecteditem} customer={currentcustomer} onSave={this.saveSubsystem.bind(this)} onClose={this.clearSelection.bind(this)} />
                <h2>Subsystem Setup</h2>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-4">
                        <Input className="" disabled={true} label="Customer" value={currentcustomer.get('name')}/>
                    </div>
                    
                </div>
                <h4>Subsystem List</h4>
                <div>
                    <Grid>
                        <Grid.Header>
                            <Grid.Column classes="col-span-16 md:col-span-5">Name</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-2">Number</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-3">Type</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-6" type="complex" name="add">
                                <Grid.Controls>
                                    <Grid.Control>
                                        <PlusIcon className="w-6 text-white cursor-pointer" onClick={this.addSubsystem.bind(this)} />
                                        {/* <Button role="success" >Add</Button> */}
                                    </Grid.Control>
                                </Grid.Controls>
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            {(currentcustomer.get('subsystems') || []).map((subsystem, index) => {
                                return (
                                    <Grid.Row className={"hover:bg-gray-300 group"} key={index}>
                                        <Grid.Column classes="col-span-16 md:col-span-5">{subsystem.get('name')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">{subsystem.get('number')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-3">{ModuleTypes[subsystem.get('type')].name}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-6">
                                            <Grid.Controls>
                                            {/* <Button role="secondary" className="w-100" onClick={this.configureSubsystem.bind(this, subsystem)}>Configure</Button>
     
                                            <Button role="primary" className="w-100" onClick={this.selectSubsystem.bind(this, subsystem)}>Edit</Button>

                                            <Button role="danger" className="w-100" onClick={this.removeSubsystem.bind(this, index)}>Remove</Button> */}
                                                <Grid.Control><Cog6ToothIcon title="Configure" className="text-gray-300 group-hover:text-gray-800  group-hover:hover:text-blue w-6" onClick={this.configureSubsystem.bind(this, subsystem)} /></Grid.Control>
                                                <Grid.Control><PencilSquareIcon title="Edit" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue w-6" onClick={this.selectSubsystem.bind(this, subsystem)} /></Grid.Control>
                                                <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 w-6" onClick={this.removeSubsystem.bind(this, index)} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid.Data>
                    </Grid>
                </div>
            </div>
        );
    }
}

// export default connect((state) => ({
//     customers: state.get('customers'),
//     customer: state.get('customer')
// }))(Subsystems);


export default withBMS(Subsystems);