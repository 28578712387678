import { useState, useEffect, useRef } from 'react';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import axios from 'axios';
import * as d3 from 'd3';
import { fromJS, Map } from 'immutable';
import { getSite, saveSiteConfiguration } from './Actions';
import { convertAreaToV2 } from './Area/Actions';
import { useBMS } from '../../Context/BMS';
import { useRevalidator } from 'react-router-dom';

export default function Version2ConversionDialog({ opened, onClose, area, customer, site }) {

    const { revalidateSite } = useBMS();
    const revalidator = useRevalidator();
    const [conversionFinished, setConversionFinished] = useState(false);

    const [convertedAreas, setConvertedAreas] = useState({});
    const [converting, setConverting] = useState(false);
    const convertedTemplates = useRef({});

    const params = { site: site.get('siteName'), customer: site.get('customerName') };

    function convertSubAreaToV2Configuration(area, baseDirectory, lvl = 1) {
        if(area.get('treenodes')) {
            let newBaseDirectory = `${baseDirectory}_${area.get('name').toLowerCase().split(' ').join('-')}`;
            area = area.update('treenodes', x => 
                x.map((node) => convertSubAreaToV2Configuration(node, newBaseDirectory, lvl + 1))
            );
        }
        let popupBackdrop = (area.getIn(['backdrop','file']) == 'general_background-popup');
        if(popupBackdrop) {
            area = area.update('style', Map(), x => x.set('width', 1080).set('height', 600));
        }
        area = area.set('isV2', true);
        area = area.update('name', (name) => `${name}${lvl == 1 ? ' v2' : ''}`);
        return area;
    }
    
    

    async function convertSubAreaToV2Directories(linkMap, customerBaseDirectory, area, baseDirectory, lvl = 1, siteBasePath, destinationDirectory = "") {
    
        let newBaseDirectory = `${baseDirectory}_${area.get('name').toLowerCase().split(' ').join('-')}`;
        let newDestinationDirectory = `${destinationDirectory}_${`${area.get('name')}${lvl == 1 ? ' v2' : ''}`.toLowerCase().split(' ').join('-')}`;
    
        let sourcepath = `${baseDirectory}_${area.get('name').toLowerCase().split(' ').join('-')}.json`;
        let destinationpath = `${destinationDirectory}_${`${area.get('name')}${lvl == 1 ? ' v2' : ''}`.toLowerCase().split(' ').join('-')}.json`;
        
        let response = undefined;

        let areaNodeUrl = `/files/customers/${sourcepath}`;
        let newAreaNodeUrl = `/files/customers/${destinationpath}`;
        let dealingWithTemplate = false;

        if(area.get('hasTemplate')) {
            dealingWithTemplate = true;
            areaNodeUrl = `/files/customers/${customerBaseDirectory}_templates_${area.get('template')}`;
            newAreaNodeUrl = `/files/customers/${customerBaseDirectory}_templates-v2_${area.get('template')}`;
           
        } 

        if(!dealingWithTemplate || !convertedTemplates.current[areaNodeUrl]) {
            try {
                setConvertedAreas((convertedAreas) => {
                    return {...convertedAreas, [area.get('name')]: 'in progress'}
                })
                response = await axios.get(areaNodeUrl);
            } catch(err) {
                setConvertedAreas((convertedAreas) => {
                    return {...convertedAreas, [area.get('name')]: 'area not found'}
                })
                // console.log("area no found");
            }
            
            if(response) {
                
                let backdropUrl = "";
                let popupBackdrop = (area.getIn(['backdrop','file']) == 'general_background-popup') 
                if(popupBackdrop) {
                    let pArea = area.setIn(['backdrop', 'file'], undefined);
                    pArea = pArea.setIn(['backdrop', 'width'], 1080);
                    pArea = pArea.setIn(['backdrop', 'height'], 600);
                } 
                let file = area.getIn(['backdrop','file']);
                if(file) {
                    backdropUrl = `${file.indexOf('~') == 0 ? `/files/customers/${siteBasePath}_backdrops_${file.replace('~', '')}.svg` : `/files/editor/backdrops_${file}.svg`}`;
                }
                let svgLayerFiles = await saveBackdropLayer(backdropUrl);
    
    
                let nodes = fromJS(response.data);
        
        
                let counter = 0;
                while(counter < nodes.size) {
                    let node = nodes.get(counter);

                    if(node.getIn(['configuration', 'linkto'])) {

                        nodes = nodes.updateIn([counter, 'configuration', 'linkto'], (linkto) => {
                            return linkMap.get(linkto, linkto);
                        });
                        // console.log("configuration linkto", node.getIn(['configuration', 'linkto']));

                    }

                    try {
                        if (node.getIn(['component', 'name']).indexOf('~') == 0) {
                            let path = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.svg`;
                            let newPath = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.svg`;
                            newPath = newPath.replace('components_', 'components-v2_');
                            try {
                                let response = await axios.get(path);
                                await axios.post(newPath, response.data);
                            } catch(err) {
                                // console.log("component no found");
                            }
            
                        }
                    } catch(e) {
                        // console.log("erronous component", node);
                        throw e;
                    }
                    
                    
                    counter++;
                }
        
        
        
                let newLayers = [];
                let id = 1;
                Object.keys(svgLayerFiles).forEach((layer, index) => {
                    
                    let layerComponents = nodes.toJS().filter((component) => component.component.layer === layer);
                    
                    let newLayer = {
                        "name": layer,
                        "nodes": [
                            {
                                "id": id++,
                                "name": "backdrop-component",
                                "type": "layer",
                                "component": {
                                    "name": "backdrop-component",
                                    "type": "layer",
                                },
                                "url": svgLayerFiles[layer]
                            }
                        ]
                    }
                    // console.log("layerComponents", layerComponents);
                    layerComponents.forEach((component) => {
                        component.layer = `layer`;
                        newLayer.nodes.push({...component, id: id++ });
                    });
                    newLayers.push(newLayer);
                })
        
                try {
                    
                    await axios.post(newAreaNodeUrl, newLayers)
                    if(dealingWithTemplate) {
                        convertedTemplates.current[areaNodeUrl] = true;
                    }
                    setConvertedAreas((convertedAreas) => {
                        return {...convertedAreas, [area.get('name')]: 'new area file saved'}
                    })
                } catch(err) {
                    // console.log("error saving file");
                    setConvertedAreas((convertedAreas) => {
                        return {...convertedAreas, [area.get('name')]: 'new area file saving failed'}
                    })
                }
                
            }
        } else {
            setConvertedAreas((convertedAreas) => {
                return {...convertedAreas, [area.get('name')]: 'area was template, template already converted'}
            })
        }
        
        // console.log("response nodes", response.data);
    
        if(area.get('treenodes')) {
            let children = area.get('treenodes');
            let treenodeCounter = 0;
            while(treenodeCounter < children.size) {
                let node = children.get(treenodeCounter);
                await convertSubAreaToV2Directories(linkMap, customerBaseDirectory, node, newBaseDirectory, lvl + 1, siteBasePath, newDestinationDirectory);
                treenodeCounter++;
            }
            return true;
        }
    
           
        return true;
    }
    
    async function saveBackdropLayer(backdropUrl) {
        let noLayer = {};
        noLayer["layer1"] = "";
        noLayer["layer1user"] = "";
        noLayer["layer2"] = "";
        noLayer["layer2user"] = "";
        if(!backdropUrl) {
            
            return noLayer;
        }
        let svgLayerFiles = {};
        try {
            let xml = await d3.xml(backdropUrl);
            const svgElement = document.createElement('div');
            const originalSvg = xml.documentElement;
            const originalWidth = originalSvg.getAttribute('width') || '100%';
            const originalHeight = originalSvg.getAttribute('height') || '100%';
            svgElement.appendChild(originalSvg.cloneNode(true));
            
            const svgLayers = Array.from(svgElement.children[0].children).filter(
                (layer) => layer.tagName === 'g' || layer.tagName === 'svg:g'
            );
            if(svgLayers.length == 0) {
    
                return noLayer;
            }
    
            for(let i = 0; i < svgLayers.length; i++){
                let layer = svgLayers[i];
                let index = i;
                const layerId = layer.getAttribute('id') || `layer-${index + 1}`;    
                const layerSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                layerSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                layerSvg.setAttribute('version', '1.1');
                layerSvg.setAttribute('width', originalWidth);
                layerSvg.setAttribute('height', originalHeight);
        
                const svgDefs = svgElement.children[0].querySelector('defs');
                if (svgDefs) {
                    const layerDefs = svgDefs.cloneNode(true);
                    layerSvg.appendChild(layerDefs);
                }
        
                layer.setAttribute('id', layerId);
                layerSvg.appendChild(layer.cloneNode(true));
        
                const svgData = new XMLSerializer().serializeToString(layerSvg);
                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        
                svgLayerFiles[layerId] = `${backdropUrl.replace('backdrops_',`backdrops_v2conversion_${layerId}_`)}`;
        
                const formData = new FormData();
                formData.append('file', svgBlob, `${layerId}.svg`);
        
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                await axios.post(`${svgLayerFiles[layerId]}/upload`, formData, config)
            }
            return svgLayerFiles;
        } catch(err) {
            return noLayer;
        }
    }
    
    async function convertRootAreaToV2(area, site, customer, params) {
    
        const createBaseDirectory = (customer, site) => {
            return `${customer.get('name').toLowerCase().split(' ').join('-')}_${site.get('name').split(' ').join('-').toLowerCase()}`;
        }

        const createBaseLinkDirectory = (customer, site) => {
            return `/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/site/${site.get('name').split(' ').join('-').toLowerCase()}`;
        }

        const createCustomerBaseDirectory = (customer) => {
            return `${customer.get('name').toLowerCase().split(' ').join('-')}`;
        }
    
        // console.clear();
        // console.log("area", area);
        // console.log("customer", customer);
        // console.log("site", site);
        // console.log("params", params);
        // return;
        let areaNode = site.get('treenodes').find((node) => {
            return node == area;
        });
    
        const createLinkMap = (oldBase, newBase, linkMap, node, versionString = '') => {
            let oldAreaLink = `${oldBase}/${`${node.get('name')}`.toLowerCase().split(' ').join('-')}`;
            let newAreaLink = `${newBase}/${`${node.get('name')}${versionString}`.toLowerCase().split(' ').join('-')}`;
            linkMap = linkMap.set(oldAreaLink, newAreaLink);
            if(node.get('treenodes')) {
                let children = node.get('treenodes');
                let treenodeCounter = 0;
                while(treenodeCounter < children.size) {
                    let childNode = children.get(treenodeCounter);
                    linkMap = createLinkMap(oldAreaLink, newAreaLink, linkMap, childNode);
                    treenodeCounter++;
                }
            }
            return linkMap;
        }

        if(areaNode) {
            let nodes = site.get('treenodes');
            let siteBaseDirectory = createBaseDirectory(customer, site);
            // let areaOldLink = `${createBaseLinkDirectory(customer, site)}/${`${area.get('name')}`.toLowerCase().split(' ').join('-')}`;
            // let areaNewLink = `${createBaseLinkDirectory(customer, site)}/${`${area.get('name')} v2`.toLowerCase().split(' ').join('-')}`;

            let linkMap = createLinkMap(
                createBaseLinkDirectory(customer, site),
                createBaseLinkDirectory(customer, site), 
                Map(), 
                areaNode,
               " v2"
            );

            // console.log(areaNode.get('name') + " linkMap ", linkMap);

            let customerBaseDirectory = createCustomerBaseDirectory(customer);
            // console.log("lvl1path", siteBaseDirectory);
            nodes = nodes.push(convertSubAreaToV2Configuration(areaNode, siteBaseDirectory));
           
            setConverting(true)
            // console.log("params in convertRootAreaToV2", params);
            // console.log("nodes", nodes);
            // return;
           await saveSiteConfiguration(params.customer, params.site, nodes);
    
    
           await convertSubAreaToV2Directories(linkMap, customerBaseDirectory, areaNode, siteBaseDirectory, 1, siteBaseDirectory, siteBaseDirectory);
           setConverting(false);
        //    getSite(params.customer, params.site, site.get('nodepath'));
    
    
        }
    
    }

    const startConversion = async (area, site, customer, params) => {
        await convertRootAreaToV2(area, site, customer, params);
        setConversionFinished(true);
        revalidateSite();
        revalidator.revalidate();
    }

    return <Dialog opened={opened} onClose={() => {
          
            

            if(!converting) {
                setConvertedAreas({});
                onClose();
            }

          
            
        }}>


        <DialogBody>
            <DialogContent>
                <div>
                    <div>
                        {Object.keys(convertedAreas).map((areaName) => {
                            return <div>{areaName}: {convertedAreas[areaName]}</div>    
                        })}

                    </div>
                    { !conversionFinished && <button disabled={converting} onClick={() => startConversion(area, site, customer, params)}>Start conversion</button> }
                    { conversionFinished && <button onClick={() => onClose()}>Close</button> }
                </div>
            </DialogContent>
        </DialogBody>
    </Dialog>

}

