import ServiceBus from "../../../../../Services/ServiceBus";


class CustomerSubscription {

    constructor(customerName) {
        this.addresses = {};
        this.customerName = customerName;
        this.poll = this.poll.bind(this);
        this.interval = setInterval(this.poll, 20000);
        this.lastValues = {};
    }

    unsubscribe() {
        clearInterval(this.interval);
        this.lastValues = {};
        this.interval = null;
    }

    emit(message) {

        let { m_pointStatusData } = message;
        if (!m_pointStatusData) {
            m_pointStatusData = message;
        }
        if (m_pointStatusData && m_pointStatusData.m_pointAddr) {
            let address = [
                m_pointStatusData.m_pointAddr[0],
                m_pointStatusData.m_pointAddr[1],
                m_pointStatusData.m_pointAddr[2],
                m_pointStatusData.m_pointAddr[3],
                m_pointStatusData.m_pointAddr[4],
                m_pointStatusData.m_pointAddr[5],
                m_pointStatusData.m_pointAddr[6],
                m_pointStatusData.m_pointAddr[7],
                m_pointStatusData.m_pointAddr[8],
                m_pointStatusData.m_pointAddr[9]
            ].join('-');


            if (this.addresses[address]) {
                this.addresses[address].forEach(callback => {

                    let value = m_pointStatusData.m_formattedValueText;
                    let retrievedValue = { 
                        pointconfiguration: m_pointStatusData, 
                        address: address, 
                        value: value, 
                        ...m_pointStatusData 
                    }

                    this.lastValues[address] = retrievedValue;

                    callback(retrievedValue);
                });
            }
        }
    }

    pollIndividualAddress(address) {
        let request = {
            trackMessage: true,
            m_level: 10,
            m_communicNum: 300,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: address.split('-')[0],
            databaseName: `${this.customerName}`
        }
        let comm = {
            m_pointAddr: address.split('-').map((bit) => { return parseInt(bit); })
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    getLastValue(address) {
        return this.lastValues[address];
    }

    poll() {
        Object.keys(this.addresses).forEach(address => {
            if (this.addresses[address].length < 1) {
                delete this.lastValues[address];
                return;
            }
           this.pollIndividualAddress(address);
            

        });
    }

    subscribe(address, callback, abortController) {

        if (!this.addresses[address]) {
            this.addresses[address] = [];
        }
        this.addresses[address].push(callback);
        setTimeout(() => {
            if(abortController && abortController.signal.aborted) {
                // console.log("28-06-2023 ABORTED SUBSCRIPTION LISTENER")
            } else {
                // console.error("who called this");
                // console.log("28-06-2023 LISTENING TO SUBSCRIPTION");
                this.pollIndividualAddress(address);
            }
            
        }, 100);
        const unsubscribe = () => {
            // console.log("20-03-2024 UNSUBSCRIBED");
            const index = this.addresses[address].indexOf(callback);
            if (index !== -1) {
                this.addresses[address].splice(index, 1);
            } 
        }
        // console.log("20-03-2024 SUBSCRIBED");
        return unsubscribe;
    }

}

class AreaPointService {

    constructor() {

        this.subscriptions = [];
        this.serviceBusUnSubscribe = undefined;

    }

    serviceBusListeners() {
        if(this.subscriptions.length > 0) {
            if(this.serviceBusUnSubscribe) {
                return;
            } else {
                this.serviceBusUnSubscribe = ServiceBus.on('300-11', (message) => {
                    this.subscriptions.forEach((subscription) => {
                        subscription.emit(message);
                    });
                });
            }
        } else {
            if(this.serviceBusUnSubscribe) {
                this.serviceBusUnSubscribe();
                this.serviceBusUnSubscribe = undefined;
            }
        }
    }

    subscribe(customerName) {

        let customerSubscription = new CustomerSubscription(customerName);
        this.subscriptions.push(customerSubscription);
        this.serviceBusListeners()
        const unsubscribe = () => {
            const index = this.subscriptions.indexOf(customerSubscription);
            // unsubscribe customer
            customerSubscription.unsubscribe();
            if (index !== -1) {
                this.subscriptions.splice(index, 1);
                this.serviceBusListeners()
            } 
        }
        return {
            subscription: customerSubscription,
            unsubscribe
        };

    }


}

export default new AreaPointService();