export function convertTimeStringToDouble(time) {
    var timesplitted = time.split(':');
    var hours = parseInt(timesplitted[0]);
    var minutesInDouble = Math.round((parseFloat(timesplitted[1]) / 60) * 100) / 100;
    return hours + minutesInDouble;
}

export function convertDoubleToTimeString(input, roundby) {
    let timeInDouble = parseFloat(input);
    let minutes = input * 60;
    let restMinutes = minutes % 60;
    let hours = (minutes - restMinutes) / 60;
    minutes = Math.round(restMinutes);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        if (roundby && minutes % roundby > 0){
            minutes = Math.round((minutes / roundby)) * roundby;
        }
        minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
}
