import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Immutable from 'immutable';
import ServiceBus from 'Services/ServiceBus';
import {getFileCommunic} from 'BmsView/Actions/Common';
import {getCollection, deleteDevice, saveItem} from '../Actions';
import OutstationList from './OutstationList';
import LanList from './LanList';
import PointList from './PointList';
import ScanDialog from '../ScanDialog';
import ScanForPointsDialog from '../ScanForPoints';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';

export default function TrendSetupDialog({ opendialog, onClose, customer, selecteditem }) {
	const [open, setOpen] = useState(opendialog);
	const [lans, setLans] = useState(Immutable.List());
	const [selectedlan, setSelectedLan] = useState(undefined);
	const [outstations, setOutstations] = useState(Immutable.List());
	const [points, setPoints] = useState(Immutable.List());
	const [selectedoutstation, setSelectedOutstation] = useState(undefined);
	const [dialogtype, setDialogType] = useState(undefined);
	const [overwrite, setOverwrite] = useState(false);
	const [histories, setHistories] = useState(false);
	const [paneltypes, setPanelTypes] = useState(undefined);

    useEffect(() => {
      let unsubscribe = ServiceBus.on('309-11', getFileHandler);
      getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,selecteditem.get('number'),'trendtemplates','paneltype.json');

      return () => unsubscribe();
    }, []);

    const getFileHandler = (msg) => {
      setPanelTypes(JSON.parse(msg.m_jsonFileData));
    }

	useEffect(() => {
		getTrendLanList();
	}, [selecteditem]);

	useEffect(() => {
		getTrendOutstationList();
	}, [selectedlan]);

	useEffect(() => {
		getPointList();
	}, [selectedoutstation]);

	const getTrendLanList = () => {
		let query = {};
		query['subsystem'] = selecteditem.get('number');
		let sort = {};
		sort['number'] = 1;

		getCollection(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
			`${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'trendlans', query, sort).then((data) => {
				setLans(Immutable.fromJS(data.map((lan) => {
					return lan;
				})));
			});
	}

	const getTrendOutstationList = () => {
		let query = {};
		if (!selectedlan) return;
		query['subsystem'] = selecteditem.get('number');
		query['lannumber'] = selectedlan.get('number');
		let sort = {};
		sort['outnumber'] = 1;

		getCollection(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
			`${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'trendoutstations', query, sort).then((data) => {
				setOutstations(Immutable.fromJS(data.map((outstation) => {
					return outstation;
				})));
			}, setCurrentOutstation);
	}

	const getPointList = () => {
		let query = {};
		if (!selectedlan || !selectedoutstation) return;

		query['addr.0'] = selecteditem.get('number');
		query['addr.3'] = selectedlan.get('number');
		query['addr.4'] = selectedoutstation.get('outnumber');

		getCollection(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
			`${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'points', query).then((data) => {
				setPoints(Immutable.fromJS(data.map((point) => {
					return {
						subsystem: point.addr[0],
						subsystemtype: point.addr[1],
						type: point.addr[2],
						lan: point.addr[3],
						outstation: point.addr[4],
						name: point.name,
						number: point.addr[5]
					}
				})));
			})
	}


	const updateItem = (type, item) => {
		saveItem(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
			`${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, type, item).then(() => {
				Swal.fire("Success", `The ${type == "trendlans" ? "Lan" : "Outstation"} has been succesfully saved`, "success");
				if (type == 'trendlans') getTrendLanList();
				else getTrendOutstationList();
			}).catch((err) => {
				Swal.fire('Error', `An error occurred while saving the ${type == "trendlans" ? "Lan" : "Outstation"}, please contact the administrator.`, "error");
			})
	}

	const deleteItem = (objecttype, device) => {
		Swal.fire({
			title: `Are you sure you want to delete the ${objecttype == "trendlans" ? "Lan" : "Outstation"} ?`,
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.value) {
				await deleteDevice(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
					`${customer.get('ipaddress').toLowerCase()}`, objecttype, device);

				objecttype == "trendlans" ? getTrendLanList() : getTrendOutstationList();
				Swal.fire(
					'Deleted!',
					`${objecttype == "trendlans" ? "The Lan " : "The Outstation "} has been deleted`,
					'success'
				);
			}
		});
	}

	const setCurrentOutstation = (outstation) => {
		setSelectedOutstation(outstation);
	}

	const scan = (type) => {
		setDialogType(type);
	}

	const scanForPoints = async () => {

		const { value: formValues } = await Swal.fire({
			title: 'Are you sure you want to scan for points ?',
			icon: 'warning',
			html:
				'<label for="overwrite">Overwrite existing points</label></br>' +
				'<input type="checkbox" id="overwrite" value=0></br>' +
				'<label for="histories">Enable Histories</label></br>' +
				'<input type="checkbox" id="histories" value=0></br>',
			focusConfirm: false,
			showCancelButton: true,
			preConfirm: () => {
				return [
					document.getElementById('overwrite').checked,
					document.getElementById('histories').checked
				]
			}
		})

		if (formValues) {
			setOverwrite(formValues[0] ? 1 : 0);
			setHistories(formValues[1] ? 1 : 0);

			setDialogType('scanforpoints');
		}
	};

	const closeDialog = (name) => {
		if (name == 'discoversite') {
			this.getTrendLanList();
		}

		if (name == 'scanforlans') {
			getTrendLanList();
		}

		if (name == 'scanforoutstations') {
			getTrendOutstationList();
		}

		if (name == 'scanforpoints') {
			getPointList();
		}

		setDialogType(undefined);
	}

	return (
		<React.Fragment>
			{dialogtype == "scanforlans" && <ScanDialog selecteditem={dialogtype} customer={customer} selectedSubsystem={selecteditem} onClose={() => closeDialog('scanforlans')} />}
			{dialogtype == "scanforoutstations" && selectedlan && <ScanDialog selecteditem={dialogtype} customer={customer} selectedSubsystem={selecteditem} selectedlan={selectedlan.get('number')} onClose={() => closeDialog('scanforoutstations')} />}
			{dialogtype == "scanforpoints" && selectedoutstation && <ScanForPointsDialog selecteditem={dialogtype} type={"trend"} customer={customer} selectedSubsystem={selecteditem.get('number')} selectedlan={selectedlan.get('number')} selectedcontroller={selectedoutstation.get('outnumber')} overwrite={overwrite} histories={histories} onClose={() => closeDialog('scanforpoints')} />}
			{dialogtype == "discoversite" && <ScanForPointsDialog selecteditem={dialogtype} type={"trend"} customer={customer} selectedSubsystem={selecteditem.get('number')} overwrite={overwrite} histories={histories} onClose={() => closeDialog('scanforpoints')} />}
			<Transition.Root show={opendialog} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative bg-white rounded-lg mx-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full sm:p-6">
									<div>
										<div className="mt-2 sm:mt-3">
											<Dialog.Title as="h2" className="text-xl leading-6 font-semibold text-gray-900">
												Trend Configuration Setup
											</Dialog.Title>
											<div class="grid grid-cols-11 gap-8 mt-8">
												<div class="col-span-3">
													<LanList customer={customer}
														lans={lans}
														selectedsubsystem={selecteditem.get('number')}
														selectedlan={selectedlan}
														setSelectedLan={(lan) => setSelectedLan(lan)}
														scan={(type) => scan(type)}
														updateItem={(type, lan) => updateItem(type, lan)}
														deleteItem={(type) => deleteItem(type, selectedlan)} />
												</div>
												<div class="col-span-4">
													<OutstationList customer={customer}
														outstations={outstations}
														paneltypes={paneltypes}
														selectedsubsystem={selecteditem.get('number')}
														selectedlan={selectedlan}
														selectedoutstation={selectedoutstation}
														setSelectedOutstation={(outstation) => setSelectedOutstation(outstation)}
														scanForPoints={() => scanForPoints()}
														updateItem={(type, outstation) => updateItem(type, outstation)}
														deleteItem={(type, outstation) => deleteItem(type, outstation)} />
												</div>
												<div class="col-span-4">
													<PointList
														selectedsubsystem={selecteditem.get('number')}
														selectedlan={selectedlan}
														selectedoutstation={selectedoutstation}
														points={points}
													/>
												</div>
											</div>
										</div>
										<div className="mt-6 flex justify-end">
											
											<div className="pl-1">
												<Button
													role="primary"
													onClick={() => scan('discoversite')}>Auto Point Discovery</Button>	
											</div>
											<div className="pl-1">
												<Button
													role="secondary"
													onClick={() => onClose()}>Exit</Button>
											</div>
											
											
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</React.Fragment>
	)
}



