import * as d3 from 'd3';
import { displayImage, displayTooltip, drawText } from '../Utils';
import ComponentCache from '../ComponentCache';

export default function PointList(options) {
    return new Promise((resolve, reject) => {
        var pointListNode;
        var placementElement;

        var createPlacementElement;
        var image;

        if ( options.node.getIn(['component', 'name']) != 'pointlist' ){
            // Omar 26-12-2022 path was never defined, so i removed it from the or 
            ComponentCache.getXml(`/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
                image = d3.select(xml.documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);  
                placementElement = displayImage(options, image);
            })
        } else {
            createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)
        
            placementElement = createPlacementElement(options);
            pointListNode = drawText(placementElement, options.node, options.node.getIn(['configuration', 'value'], 'Point List'), options.getCustomerSettings, options.getUserSettings);
            drawButton(placementElement, pointListNode, options);
            placementElement.style("cursor", "hand");
        }

        displayTooltip(placementElement, options);

        options.pointparams = {};    

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams[point.address] = point;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                options.node = node;
                if ( options.node.getIn(['component', 'name']) != 'pointlist' ){
                    placementElement.remove();
                    placementElement = displayImage(options, image);}
                else {
                    pointListNode.remove();
                    placementElement.remove();
                    placementElement = createPlacementElement(options);
                    pointListNode = drawText(placementElement, options.node, options.node.getIn(['configuration', 'value'], 'Point List'), options.getCustomerSettings, options.getUserSettings);
                    drawButton(placementElement, pointListNode, options);
                    placementElement.style("cursor", "hand");
                }
                displayTooltip(placementElement, options);  
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            remove: () => {
                placementElement.remove();
                if ( options.node.getIn(['component', 'name']) != 'pointlist' ) image.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    })
}

function drawButton(element, textnode, options) {
    // systemSettings.graphics.box.yOffset
    // systemSettings.graphics.box.xOffset
    
    let boxYOffset = 8;
    let boxXOffset = 8;

    let box = textnode.node().getBBox();
    let width = options.node.getIn(['configuration', 'width'], "");
    if (width.length > 0) {
        box.width = width;
    }

    element.insert('rect', 'text')
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('x', box.x - boxXOffset)
        .attr('y', box.y - boxYOffset / 2)
        .attr('width', box.width + boxXOffset * 2)
        .attr('height', box.height + boxYOffset)
        .attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#efe'))
        .attr('stroke', options.node.getIn(['configuration', 'style', 'border-color'], '#000'));
}