import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Checkbox from 'Controls/Form/Checkbox';
import ColorPicker from 'Controls/ColorPicker';
import {fromJS} from 'immutable';
import Gradient from './Gradient';

class Path extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    render() {
        let { selectedcomponent, onChange } = this.props;
        let grads = fromJS([
            { "stop": "0%", "colour":"#00aad4" },
            { "stop": "100%", "colour":"#004050"}]);

        return (
            <div className="my-4">
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Length"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'length'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'length'])}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'width'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'width'])}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>
                </div>  
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-6 my-2">
                        <Input
                            label="Path"
                            value={selectedcomponent.getIn(['configuration', 'path'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'path'])}
                        />
                    </div>
                </div> 
                <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradient'])} label={"Gradient"} gradientField={""}
                    gradients={selectedcomponent.getIn(['configuration', 'grads'])} defaultGrads={grads}
                    onChange={onChange} type={selectedcomponent.getIn(['configuration','gradienttype'], 'simple')}/>
                <div className="grid grid-cols-16">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Border" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'border-color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Stripe" value={selectedcomponent.getIn(['configuration', 'style', 'top-border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'top-border-color'])} /></div>
                </div>
            </div>
        )
    }
}

export default Path;