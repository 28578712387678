import React from 'react';


export default function FileInput({ onChange, label, error, className, ...others }) {

    
    return (
        <div className="form-group">
            {label && <label>{label}</label>}
            <input type="file" className={`form-control ${className} ${error ? `error` : ``}`} onChange={(evt) => onChange(evt.target.files[0])} {...others}  />
            {error && <small class="form-text text-danger">{error}</small>}
        </div>
    )
}