import React from 'react';
import {connect} from 'react-redux';
import Immutable, { List } from 'immutable';
import Swal from 'sweetalert2';

import { getAddresses, saveAddress, deleteAddress } from './Actions';
import Grid from 'Controls/GridV2';
import IpAddressDialog from './IpAddressDialog';
import Select from 'Controls/Form/Select';

import Button from 'Controls/Button/UserDefined';


class IpTables extends React.Component {
    constructor(props) {
        super(props);

        let { customer } = this.props;

        let sites = (customer.get('sites') || List());
        sites  = sites.push(Immutable.Map({'name':'All Sites'}));

        this.state = {
            addresses: null,
            currentsite: 'All Sites',
            sites: sites
        }

        this.siteChanged = this.siteChanged.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.removeAddress = this.removeAddress.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
    }

    componentDidMount() {
        let { customer } = this.props;

        getAddresses(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                this.setState({
                    addresses: Immutable.fromJS(data.map((address) => {
                        return address;
                    }))
                })
            });
    }

    selectAddress(address) {
        this.setState({
            selectedaddress: address
        });
    }

    saveAddress(address) {
        let { customer } = this.props;

        saveAddress(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, address).then(() => {
                this.clearSelection();

                getAddresses(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                        this.setState({
                            addresses: Immutable.fromJS(data.map((address) => {
                                return address;
                            }))
                        })
                    });

                Swal.fire("Success", "The IP Address has been succesfully saved.", "success");
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while saving the IP Address, please contact the administrator.", "error");
            });
    }

    removeAddress(address) {
        let { customer } = this.props;

        Swal.fire({
            title: `Are you sure you want to delete the IP Address ?`,
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value) {
                await deleteAddress(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`, address);
                await getAddresses(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                        this.setState({
                            addresses: Immutable.fromJS(data.map((address) => {
                                return address;
                            }))
                        })
                    });
                Swal.fire(
                    'Deleted!',
                    'The IP Address has been deleted',
                    'success'
                );
            }
        })
    }

    siteChanged(value) {
        this.setState({
            currentsite: value
        });
    }

    clearSelection() {
        this.setState({
            selectedaddress: null
        });
    }

    setAddress() {
        return 'Lan 20';
    }

    render() {
        let { customer } = this.props;
        let { selectedaddress, sites, currentsite } = this.state || {};
        let { addresses } = this.state;

        let gridFields = [
            { value: 'address', name: 'IP Address', width: 'col-span-16 md:col-span-2' },
            { value: 'location', name: 'Location', width: 'col-span-16 md:col-span-2' },
            { value: 'device', name: 'Device', width: 'col-span-16 md:col-span-2' },
            { value: 'deviceaddress', name: 'Device Address', width: 'col-span-16 md:col-span-3' },
            { value: 'notes', name: 'Notes', width: 'col-span-16 md:col-span-5' }
        ];

        return (
            <div style={{marginLeft:"5%", marginRight:"5%"}} >
                <div className="container-fluid my-4">
                    <IpAddressDialog selecteditem={selectedaddress} onClose={this.clearSelection} customer={customer} onSave={this.saveAddress} />
                    <h2>Ip Address Table</h2>
                    <div className='grid grid-cols-16 my-4'>
                        <div className="col-span-16 md:col-span-4">
                            <Select
                                value={currentsite}
                                onChange={this.siteChanged.bind(this)}
                                options={sites.map((site) => {
                                    return ({
                                        text: site.get('name'),
                                        value: site.get('name')
                                    });
                                })
                            }></Select>
                        </div>
                    </div>
                    <h4>Subsystem List</h4>
                    <Grid>
                        <Grid.Header>
                            {gridFields.map((field) => {
                                return (
                                    <Grid.Column key={field.name} classes={`${field.width}`}>{field.name}</Grid.Column>
                                );
                            })}
                            <Grid.Column key={"edit"} classes='col-span-16 md:col-span-1' name="edit"></Grid.Column>
                            <Grid.Column key={"delete"} classes='col-span-16 md:col-span-1' name="delete" type="complex">
                                <Button className="w-100" role="success" onClick={this.selectAddress.bind(this, Immutable.Map({}))}>Add</Button>
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            {(addresses || []).filter((address) => {
                                return (currentsite == 'All Sites' || address.get('site') == currentsite)
                            }).map((address, index) => {
                                return (
                                    <Grid.Row key={index}>
                                        <Grid.Column>
                                            {address.get('ipaddress')}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {address.get('location')}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {address.get('devicetype')}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {address.get('devicetype') == 'Trend IQ3' && address.get('lan') && address.get('outstation') &&
                                            `Lan: ${address.get('lan')} - Outstation: ${address.get('outstation')} `}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {address.get('notes')}
                                        </Grid.Column>
                                        <Grid.Column classes='col-span-16 md:col-span-1'>
                                            <Button className="w-100" role="primary" size="sm" onClick={this.selectAddress.bind(this, address)}>Edit</Button>
                                        </Grid.Column>
                                        <Grid.Column classes='col-span-16 md:col-span-1'>
                                            <Button className="w-100" role="danger" size="sm" onClick={this.removeAddress.bind(this, address)}>Remove</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            })}
                        </Grid.Data>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default IpTables;
