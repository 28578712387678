import { REVALIDATE_ALL, REVALIDATE_MAIN, REVALIDATE_CUSTOMER, REVALIDATE_SITE, REVALIDATE_AREA } from 'BmsView/Context/BMS';
import { routerDataService } from 'BmsView/Context/BMS';

export function authenticatorCheckerRevalidator({ defaultShouldRevalidate, ...rest }) {
    // console.log("1001 authenticatorCheckerRevalidator, rest", rest)
    let verdict = defaultShouldRevalidate;
    verdict = verdict && routerDataService.shouldRevalidate(REVALIDATE_ALL);
    // console.log("1001 authenticatorCheckerRevalidator", verdict)
    return verdict;
}

export function mainRevalidator({ defaultShouldRevalidate }) {
    let verdict = defaultShouldRevalidate;
    verdict = !verdict && routerDataService.revalidationSignalPresent() || verdict;
    verdict = verdict && routerDataService.shouldRevalidate(REVALIDATE_MAIN);
    // console.log("1001 mainRevalidator", verdict)
    return verdict;
}

export function usersRevalidator({ defaultShouldRevalidate }) {
    let verdict = defaultShouldRevalidate;
    verdict = !verdict && routerDataService.revalidationSignalPresent() || verdict;
    verdict = verdict && routerDataService.shouldRevalidate(REVALIDATE_MAIN);
    // console.log("1001 usersRevalidator", verdict)
    return verdict;
}

export function customerRevalidator({ defaultShouldRevalidate }) {
    let verdict = defaultShouldRevalidate;
    verdict = !verdict && routerDataService.revalidationSignalPresent() || verdict;
    verdict = verdict && routerDataService.shouldRevalidate(REVALIDATE_CUSTOMER);
    // console.log("1001 customerRevalidator", verdict)
    return verdict;
}

export function siteRevalidator({ defaultShouldRevalidate }) {
    let verdict = defaultShouldRevalidate;
    verdict = !verdict && routerDataService.revalidationSignalPresent()  || verdict;
    verdict = verdict && routerDataService.shouldRevalidate(REVALIDATE_SITE);
    // console.log("1001 siteRevalidator", verdict)
    return verdict;
}

export function areaRevalidator({ defaultShouldRevalidate }) {
    let verdict = defaultShouldRevalidate;
    verdict = !verdict && routerDataService.revalidationSignalPresent()  || verdict;
    verdict = verdict && routerDataService.shouldRevalidate(REVALIDATE_AREA);
    // console.log("1001 areaRevalidator", verdict)
    return verdict;
}
