import { List } from 'immutable';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import debuggerService from '../Services/DebuggerService';

const logger =  debuggerService.getDebugger('AreaContext', 'pink');

const AreaContext = React.createContext({});




export const AreaProvider = AreaContext.Provider;



export function useArea() {
    return useContext(AreaContext);
}



export default function AreaWrapper({ children }) {
    const [area, setArea] = useStateIfMounted({});
    const [points, setPoints] = useStateIfMounted({});
    const [nodes, _setNodes] = useStateIfMounted(List());
    const [nodesLastUpdated, setNodesLastUpdated] = useStateIfMounted(0);
    const [layers, setLayers] = useStateIfMounted(List());

    const setNodes = (value) => {
        _setNodes(value);

    }

    logger.log("render layers", layers);

    useEffect(() => {
        logger.log("useEffect (mount)", layers);
        return () => {
            logger.log("useEffect (unmount)", layers);
        }
    }, [])

   

    const value = { area, points, nodes, nodesLastUpdated, layers, setArea, setPoints, setNodes, setNodesLastUpdated, setLayers}
    
    // window.areaContext = value;
    
    return <AreaProvider value={value}>
        {children}
    </AreaProvider>
}