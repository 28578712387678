import React, { Fragment } from 'react';
import Immutable from 'immutable';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/solid';
import Swal from 'sweetalert2';
import Input from 'Controls/Form/Input';
import PointSelectionDialog from './PointControl/PointSelectionDialog';
import Grid from 'Controls/GridV2';
import {connect} from 'react-redux';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { withBMS } from '../../../Context/BMS';

// No site or customer dependencies, at the moment i don't even know why withBMS is used here

function PointListDialog({selecteditem, onClose, ...props}){
    return <Dialog className="md:min-w-[70vw]" zIndex="z-20" opened={!!selecteditem} onClose={onClose}>
        <PointListDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class PointListDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedcomponent: props.selecteditem.setIn(['component'], Immutable.fromJS({
                "type": "pointlist",
                "componentid": "layer1",
                "name": "pointlist",
                "description": "Pointlist",
                "layer": "layer1user",
                "stateids": []
            })),
            dialogshowing : false
        };
    }
   
    addPoint(point) {
        let { selectedcomponent } = this.state;

        if (!selectedcomponent.getIn(['configuration', 'points'])) {
            selectedcomponent = selectedcomponent.setIn(['configuration', 'points'], Immutable.List([]));
        }

        let newpoint = Immutable.fromJS({
            label: point.get('name'),
            value: point.get('addr').join('-')
        });

        selectedcomponent = selectedcomponent.updateIn(['configuration', 'points'], arr => arr.push(newpoint));

        this.setState({
            selectedcomponent: selectedcomponent,
            dialogshowing : false
        });
    }

    removePoint(point, index) {
        let { selectedcomponent } = this.state;

        selectedcomponent = selectedcomponent.deleteIn(['configuration', 'points', index]); 

        this.setState({
            selectedcomponent: selectedcomponent
        });
    }

    valueChanged(field, value) {
        this.setState(({ selectedcomponent }) => ({
            selectedcomponent: selectedcomponent.setIn(field, value)
        }));
    }

    save() {
        let {onSave} = this.props;
        let {selectedcomponent} = this.state;

        if(!selectedcomponent.getIn(['configuration', 'value'])) {
            Swal.fire(
                'Error!',
                'Please enter list name',
                'error'
            );
            return;
        } 

        if (onSave) {
            onSave(selectedcomponent);
        }
    }

    displayDialog(){
        this.setState({
            dialogshowing: true
        });
    }

    hideDialog() {
        this.setState({
            dialogshowing: false
        });
    }

    render() {
        let { selectedcomponent, dialogshowing } = this.state;

        return (
            <Fragment>
                <PointSelectionDialog selecteditem={dialogshowing} onClose={this.hideDialog.bind(this)} onSelect={this.addPoint.bind(this)} />
            
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Point List</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                    <div className="my-4">
                        {/* <h4>User List</h4> */}
                        <div>
                        <div className="grid grid-cols-16">
                            <div className="col-span-12">
                                <Input
                                    type="text"
                                    label="Point List Name"
                                    value={selectedcomponent.getIn(['configuration', 'value'])}
                                    onChange={this.valueChanged.bind(this, ['configuration', 'value'])}
                                />
                            </div>
                        </div>
                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-6">Name</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-5">Address</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-5" name="delete"  type="complex">
                                    <Grid.Controls>
                                        <Grid.Control>
                                            <PlusIcon className="w-6 text-white cursor-pointer" title="add new" onClick={this.displayDialog.bind(this)} />
                                            {/* <Button role="success" >Add</Button> */}
                                        </Grid.Control>
                                    </Grid.Controls>
                                </Grid.Column>
                            </Grid.Header>
                            <Grid.Data>
                                {selectedcomponent.getIn(['configuration', 'points'], []).map((point, index) => {
                                    return (
                                        <Grid.Row key={index}>
                                            <Grid.Column classes="col-span-16 md:col-span-6">{point.get('label')}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-5">{point.get('value')}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-5">
                                                <Grid.Controls>
                                                    <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500" onClick={this.removePoint.bind(this, point, index)} /></Grid.Control> 
                                                </Grid.Controls>
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                })}
                            </Grid.Data>
                        </Grid>
                        </div>
                        </div>
                    </DialogBody>
                    <DialogFooter>
                        <div className="pl-1"><Button role="secondary" onClick={this.props.onClose}>Cancel</Button></div>
                        <div className="pl-1"><Button role="primary" onClick={this.save.bind(this)}>Save</Button></div>
                    </DialogFooter>
                </DialogContent>
            </Fragment>
        );
    }
}

// export default connect((state) => ({
//     customer: state.get('customer')   
// }))(PointListDialog);

export default withBMS(PointListDialog);
