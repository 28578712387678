import React, { Fragment, useState, useEffect, useMemo } from 'react';
import Select from 'Controls/Form/Select';
import DateTimeSelection from 'Controls/DateTimeSelection';

import ChartHistories from './Chart';
import { getHistoryData } from './Actions';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import debuggerService from 'Services/DebuggerService';
const logger = debuggerService.getDebugger('HistoriesDialog');

function HistoriesDialog({ selecteditem, onClose, ...props }) {
    return (
        <Dialog opened={!!selecteditem} onClose={onClose}>
            <HistoriesDialogPanel {...props} selecteditem={selecteditem} onClose={onClose} />
        </Dialog>
    );
}

export function currentDay() {
    const date = new Date();
    return formatDate(date);
}

export function nextDay(dateStr) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1); // Increment the day by one
    return formatDate(date);
}

export function previousDay(dateStr) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 1); // Decrement the day by one
    return formatDate(date);
}

export function formatDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns month from 0 to 11
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export function HistoriesTablePanel({ selecteditem, customer, onClose }) {




    const selectedpoints = selecteditem?.get('points');
    const [records, setRecords] = useState([]);
    const [currentDate, setCurrentDate] = useState(currentDay());

    logger.log("selectedpoints", selectedpoints);
    logger.log("selecteditem", selecteditem);
    logger.log("records", records);

    useEffect(() => {
        (async () => {
            let points = selecteditem.get('points').map((point) => {
                return point.get('address');
            });
            let queryObject = {
                addrstring: { $in: points},
                datestring: { $gte: currentDate, $lte: currentDate }
            }
            let data =  await getHistoryData(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`,queryObject);
            setRecords(data.map((record) => {
                return {
                    name: record.addrstring,
                    time: record.datestring,
                    samples: record.sample
                }
            }));
        })()
    }, [currentDate])

    return <div className="flex flex-col gap-3">
        <div className="text-2xl font-bold">Histories table</div>
        <div className="flex flex-row gap-3 items-center justify-between">
            <div><span className="font-bold">Date:</span><span> {currentDate}</span></div>
            <div className="flex flex-row gap-3">
                <div><Button role="primary" onClick={() => setCurrentDate(previousDay(currentDate))}>Previous day</Button></div>
                <div><Button role="primary" onClick={() => setCurrentDate(nextDay(currentDate))}>Next day</Button></div>
            </div>
        </div>
        <HistoriesTable selectedpoints={selectedpoints} records={records} />
    </div>
}


function HistoriesChartPanel({ selecteditem, customer, onClose }) {

    const [records, setRecords] = useState([]);
    const [charttype, setChartType] = useState('XYChartScrollbar');
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType: 'Today',
        ...(() => {
            let newStartDate = new Date();
            newStartDate.setHours(0,0,0);
            let newEndDate = new Date();
            
            return {
                startDate: newStartDate,
                endDate: newEndDate
            }
        })()

    });

    const selectedpoints = selecteditem?.get('points');


    const changeChartType = (chart) => {
        setChartType(chart);
    }

    const changeDateTimeRange = (dateTimeRange) => {
        setDateTimeRange(dateTimeRange);
    }

    const getHistory = async () => {
        let points = selecteditem.get('points').map((point) => {
            return point.get('address');
        });

        let dateStartToMongo = new Date(dateTimeRange.startDate);
        dateStartToMongo.setHours(dateStartToMongo.getHours() - 1);

        var queryObject = {
            addrstring: { $in: points},
            datestring: { $gte: `${dateStartToMongo.getFullYear()}-${("0" + (dateStartToMongo.getMonth() + 1)).slice(-2)}-${("0" + dateStartToMongo.getDate()).slice(-2)}`,
             $lte: `${dateTimeRange.endDate.getFullYear()}-${("0" + (dateTimeRange.endDate.getMonth() + 1)).slice(-2)}-${("0" + dateTimeRange.endDate.getDate()).slice(-2)}` }
        }

        let data = await getHistoryData(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`,queryObject);
        setRecords(data.map((record) => {
            return {
                name: record.addrstring,
                time: record.datestring,
                samples: record.sample
            }
        }));
    }

    useEffect(() => {
        getHistory();
    }, [dateTimeRange])

    const chartTypes = [
        { value: 'XYChartScrollbar', text: 'XY Series'  },
        { value: 'AreaSeries', text: 'Area Chart' }
    ];


    return <div>
        <div>
            <div className="w-g-3 float-left px-2">
                <Select
                    label="Chart Type"
                    value={charttype}
                    onChange={changeChartType}
                    options={chartTypes}
                />
            </div>   
            <div className="flex flex-row mt-4"> 
                <DateTimeSelection dateTimeRange={dateTimeRange} updateDateTimeRange={changeDateTimeRange}/>              
            </div> 
        </div>  
        <div>     
            {/* { showTable && <HistoriesTable selectedpoints={selectedpoints} records={records} /> || null } */}
           <ChartHistories 
                records={records} 
                dateSelectionType={dateTimeRange.rangeType} 
                dateStart={dateTimeRange.startDate} 
                dateEnd={dateTimeRange.endDate} 
                selectedpoints={selectedpoints} 
                charttype={charttype} />
            
        </div> 
    </div>


}

function HistoriesDialogPanel({ selecteditem, customer, onClose }) {
    
    const [showTable, setShowTable] = useState(false);



    return (
        <DialogContent className="md:w-[70rem]">
            <DialogBody>
                { !showTable && <HistoriesChartPanel selecteditem={selecteditem} customer={customer} onClose={onClose} /> || null }
                { showTable && <HistoriesTablePanel selecteditem={selecteditem} customer={customer} onClose={onClose} /> || null }
                <div className="flex flex-row justify-end">
                    { !showTable && <button 
                        className="px-6 py-2 bg-blue-500 text-white rounded-lg" 
                        onClick={() => setShowTable(() => true)}>Show table</button> || null }
                    { showTable && <button 
                        className="px-6 py-2 bg-blue-500 text-white rounded-lg" 
                        onClick={() => setShowTable(() => false)}>Show chart</button> || null }
                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={onClose}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    );    


}

function getTimestamp(isoString) {
    
    let timestamp = new Date(isoString).getTime();
    // logger.log(`formatting to timestamp: ${isoString} - timestamp: ${timestamp}`)
    return timestamp;
}

export function HistoriesTable({ records, selectedpoints, showDate = false, reverseOrder = false }) {
    
    // Point        Point
    // Time value   Time value

    const createTable = () => {
        let mainHeaderColumns = [];
        let subHeaderColumns = [];
    
        let valueCells = [];

        selectedpoints.forEach((selectedpoint, index) => {
            mainHeaderColumns.push(<div className="col-span-3 bg-white pl-2 pr-6 py-1 border-b">{selectedpoint.get('name')}</div>);
            subHeaderColumns.push(<div className="bg-white px-2  py-1 border-b">Time</div>);
            subHeaderColumns.push(<div className="bg-white px-2  py-1 border-b text-right">Value</div>);
            subHeaderColumns.push(<div className="bg-white px-2  py-1 border-b"></div>);
            let pointRecords = records.filter((record) => record.name == selectedpoint.get('address'));
            let pointRecord = pointRecords[0];
            if(pointRecord) {
                const samplesSorter = (a,b) => !reverseOrder ? getTimestamp(a.t) - getTimestamp(b.t) : getTimestamp(b.t) - getTimestamp(a.t)     
                pointRecord.samples.sort(samplesSorter).forEach((sample) => {
                    let timeDisplay = sample.t;
                    if (showDate) {
                        timeDisplay = sample.t.replace(/.*?([0-9]{4})\-?([0-9]{2})\-?([0-9]{2})T([0-9]{2}\:[0-9]{2})\:[0-9]{2}.*/,'$3-$2-$1 $4');
                    } else {
                        timeDisplay = sample.t.replace(/.*?([0-9]{2}\:[0-9]{2})\:[0-9]{2}.*/,'$1');
                    }
    
                    valueCells.push(<>
                        <div className="px-2 py-1" key={`time-${sample.t}`}>{ timeDisplay }</div>
                        <div className="px-2 py-1 text-right"  key={`value-${sample.t}`}>{Math.round(sample.v * 100) / 100 }</div>
                        <div className="px-2 py-1"  key={`padding-${sample.t}`}></div>
                    </>);
                });
            }
        })

        return { mainHeaderColumns, subHeaderColumns, valueCells };
    }

    const { mainHeaderColumns, subHeaderColumns, valueCells } = useMemo(() => createTable(), [records]);
    

    let rowBoundry = selectedpoints.size * 3;
    let gridCols = rowBoundry;

    return <div className="max-h-full">
        <div className="w-max">        
            <div className={`grid`} style={{gridTemplateColumns:`repeat(${gridCols},minmax(0,1fr))`}}>
                {mainHeaderColumns}
                {subHeaderColumns}
                <div className="grid grid-cols-subgrid h-96 overflow-y-scroll  border-b" style={{gridColumn: `span ${gridCols} / span ${gridCols}`}}>
                    {((selectedpoints, valueCells) => {
                        const numCols = selectedpoints.size;
                        const numRows = Math.ceil(valueCells.length / numCols);
                        let rowArray = new Array(valueCells.length);
                        for (let i = 0; i < valueCells.length; i++) {
                            // Calculate the column and row for the current index
                            let col = Math.floor(i / numRows);
                            let row = i % numRows;
                            // Calculate the new index in the row-wise array
                            let newIndex = row * numCols + col;
                            // Assign the value to the new index
                            rowArray[newIndex] = valueCells[i];
                        }
                        return rowArray;
                    })(selectedpoints, valueCells)}
                </div>
            </div>
        </div>
    </div>
}

class __HistoriesDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        let selectedtemplate = this.props.selecteditem;

        this.state = {
            selectedpoints: selectedtemplate.get('points'),
            recordsToDisplay: false,
            records: [],
            charttype: 'XYChartScrollbar',
            dateTimeRange: {
                rangeType: 'Today'
            }
        }
    }

    chartTypeChanged(chart) {
        this.setState({
            charttype: chart
        }); 
    }

    getHistory(type) {
        let {customer} = this.props;
        let {dateTimeRange}= this.state; 
        let selectedtemplate = this.props.selecteditem;

        let points = [];

        selectedtemplate.get('points').map((point) => {
            points.push(point.get('address'));
        });

        // include this for summer time hour difference
        // records saved in mongo are grouped by day and in UTC format
        let dateStartToMongo = new Date(dateTimeRange.startDate);
        dateStartToMongo.setHours(dateStartToMongo.getHours() - 1);

        var queryObject = {
            addrstring: { $in: points},
            datestring: { $gte: `${dateStartToMongo.getFullYear()}-${("0" + (dateStartToMongo.getMonth() + 1)).slice(-2)}-${("0" + dateStartToMongo.getDate()).slice(-2)}`,
             $lte: `${dateTimeRange.endDate.getFullYear()}-${("0" + (dateTimeRange.endDate.getMonth() + 1)).slice(-2)}-${("0" + dateTimeRange.endDate.getDate()).slice(-2)}` }
        }

        getHistoryData(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`,queryObject).then((data) => {
                this.setState({
                    records: data.map((record) => {
                        return {
                            name: record.addrstring,
                            time: record.datestring,
                            samples: record.sample
                        }
                    })
                });
            })
    }

    setDateTimeRange(dateTimeRange) {
        this.setState({
            dateTimeRange: dateTimeRange
        }, this.getHistory);
    }

    render() {
        let {records, selectedpoints, dateTimeRange, charttype} = this.state;
        let { onClose } = this.props;
        let chartTypes = [{
            value: 'XYChartScrollbar', label: 'XY Series' 
        },{
            value: 'AreaSeries', label: 'Area Chart'
        }]
     
        return (
            <DialogContent className="md:w-[70rem]">
                <DialogBody>
                    <div>
                        <div>
                            <div className="w-g-3 float-left px-2">
                                <Select
                                    label="Chart Type"
                                    value={this.state.charttype}
                                    onChange={this.chartTypeChanged.bind(this)}
                                    options={chartTypes.map((type) => {
                                        return {
                                            value: type.value,
                                            text: type.label
                                        }
                                    })}
                                />
                            </div>   
                            <div className="flex flex-row mt-4"> 
                                <DateTimeSelection dateTimeRange={dateTimeRange} updateDateTimeRange={this.setDateTimeRange.bind(this)}/>              
                            </div> 
                        </div>  
                        <div>     
                            <ChartHistories records={records} dateSelectionType={dateTimeRange.rangeType} dateStart={dateTimeRange.startDate} dateEnd={dateTimeRange.endDate} selectedpoints={selectedpoints} charttype={charttype}/>
                            <div className="flex flex-row justify-end">
                                <button className="px-6 py-2 bg-blue-500 text-white rounded-lg" onClick={() => {} }>Show table</button>
                            </div>
                        </div> 
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button role="secondary" onClick={onClose}>Cancel</Button>
                </DialogFooter>
            </DialogContent>
        );    
    }
}

export default HistoriesDialog;