import * as d3 from 'd3';
import Immutable, {fromJS} from 'immutable';
import ComponentCache from "../ComponentCache";

const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')

export const displayImage = (options, image) => {
    let element = d3.select(options.target)
        .append('g')
        .attr('data-index', options.index)
        .html(image.html())
        .attr('x', options.node.getIn(['position', 'x'], 0))
        .attr('y', options.node.getIn(['position', 'y'], 0))
        .attr('transform', `matrix(${options.node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${options.node.getIn(['configuration', 'scale'], 100.0) / 100},${options.node.getIn(['position', 'x'], 0)},${options.node.getIn(['position', 'y'], 0)})rotate(${options.node.getIn(['configuration', 'rotation'], 0)})`)

        // console.log('element', element);

    if (options.node.getIn(['component', 'stateids'])) {    
        let states = options.node.getIn(['component', 'stateids']);

        states.forEach((state) => {
            element.select('#' + state.get('target')).style('display', 'none');
        });
    }

    if ( options.node.getIn(['configuration', 'value']) ) {
        let value = options.node.getIn(['configuration', 'value']);
        let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);
 
        options.node.getIn(['component', 'styles'], []).map((style) => {
            switch (style.get('type')) {
                case 'color' :
                    element.select('#' + style.get('target'))
                    .style(style.get('style'), value);
                    break;  
                    
                case 'calc' :
                    let r = parseInt(rgb[1], 16) + parseInt(style.get('rgb').get(0));
                    let g = parseInt(rgb[2], 16) + parseInt(style.get('rgb').get(1));
                    let b = parseInt(rgb[3], 16) + parseInt(style.get('rgb').get(2));

                    let rgbhex = rgbToHex(r, g, b);


                        element.select('#' + style.get('target'))
                        .style(style.get('style'), rgbhex);
                        break;       
            }

        })
    }  

    
/*    if (options.node.getIn(['component', 'styles']) && options.node.getIn(['component', 'styles']).get(0).get('target')) { 
        element.select('#' + options.node.getIn(['component', 'styles']).get(0).get('target'))
        .style(options.node.getIn(['component', 'styles']).get(0).get('style'), options.node.getIn(['configuration', 'stop-color'], '#ffff00'));
    }*/

    return element;
}

export const displayAlarm = (textNode, x,y,target,index) => {
/*
    textNode.append("svg:image")
    .append('g')
    .attr('data-index', index)
.attr('x', x)
.attr('y', y)
.attr('transform', `matrix(1,0,0,1,${x},${y})`)
.attr("xlink:href", "images/alarm.svg") */

    /*
    let element = ComponentCache.getXml(`/images/alarm.svg`).then((xml) => {
        let image = d3.select(xml.documentElement);
        
        let e = d3.select(target)
            .append('g')
            .attr('data-index', index)
            .html(image.html())
            .attr('x', x)
            .attr('y', y)
            .attr('transform', `matrix(1,0,0,1,${x},${y})`)
       
            console.log('target, e value ',target, e);

    //    textNode.append(e);
        return e;
    })*/

//    return element;


}

export const displayTooltip = (element, options) => {
    if (options.node.getIn(['configuration','istooltip']) && options.node.getIn(['configuration', 'tooltip'])) {
        element.on('mouseover', (evt) => {
            options.tooltip.text(options.node.getIn(['configuration', 'tooltip']));
            return options.tooltip.style('visibility','visible');
        });
        
        element.on('mousemove', (evt) => {
            let tooltipWidth = options.tooltip.node().clientWidth;
            let windowWidth = window.innerWidth;
            let leftPosition = evt.pageX + 10;
            options.tooltip.style('top', (evt.pageY-10)+'px');
            if(windowWidth > leftPosition + tooltipWidth) {
                options.tooltip.style('left',(evt.pageX+10)+'px');
            } else {
                options.tooltip.style('left',(evt.pageX-tooltipWidth-10)+'px');
            }
            return options.tooltip.style('background','white');
        })
        
        element.on('mouseleave', (evt) => {
            return options.tooltip.style('visibility', 'hidden');
        });

        return true;
    } 
}

export const setStateids = (element, options, value, oldvalue, id) => {
    if (options.node.getIn(['component', 'stateids'])) {
        if (value != oldvalue) {
            oldvalue = value;
            let rules = options.node.getIn(['configuration', 'rules']);

            if (rules) {
                let states = options.node.getIn(['component', 'stateids']);

                states.forEach((state) => {
                    element.select('#' + state.get('target')).style('display', 'none');
                });

                rules.forEach((rule) => {
                    let activated = false;
                    let matchvalue = rule.get('matchvalue');
                    if (rule.get('condition') == '$nin' || rule.get('condition') == '$in' || rule.get('condition') == '$range') {
                        matchvalue = matchvalue.split(rule.get('condition') == '$range' ? '-' : ',').map((val) => { return parseInt(val) });
                    }
                    else {
                        matchvalue = parseInt(matchvalue);
                    }

                    if (rule.get('condition') == '$in' && matchvalue.indexOf(value) >= 0) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$nin' && matchvalue.indexOf(value) < 0) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$eq' && matchvalue == value) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$neq' && matchvalue != value) {
                        activated = true;
                    }
                    if (rule.get('condition') == '$range' && value >= matchvalue[0] && value <= matchvalue[1]) {
                        activated = true;
                    }

                //    if (rule.get('action') == 'show' && activated) {
                //        element.select('#' + rule.get('target')).style('display', 'block');
                //    }

                    // console.log('fill here ', rule.get('action'), activated);

                    if (rule.get('action') == 'fill' && activated && id)  {
                        element.select('#' + id).style('fill', rule.get('fill'));
                    }
                    
                    if (rule.get('action') == 'fill' && activated && !id)  {
                        element.select('#' + rule.get('target')).style('fill', rule.get('fill'));
                    }

                    if (rule.get('action') == 'show' && activated) {
                        element.select('#' + rule.get('target')).style('display', 'block');
                    }

                    if (rule.get('action') == 'show' && !activated) {
                        element.select('#' + rule.get('target')).style('display', 'none');
                    }
                });
            } else {
                console.log('Warning - Rules not defined for component :', options.node.getIn(['component', 'name']));
            }   
        }    
    }

    return oldvalue;
}

export const drawText = (element, node, text,getCustomerSettings, getUserSettings) => {
    let fontsize = 16;
    if(getCustomerSettings) {
        fontsize = getCustomerSettings('defaultFallbackFontSize') || fontsize;
    }
    if(getUserSettings) {
        fontsize = getUserSettings('defaultFallbackFontSize') || fontsize;
    }
    fontsize = node.getIn(['configuration', 'style', 'fontsize']) || fontsize;
    let newnode = element.append('text')
        .text(text)
        .attr('fill', node.getIn(['configuration', 'style', 'color'], 'black'))
        .attr('font-size', fontsize + 'px')
        .attr('font-style', node.getIn(['configuration', 'style', 'fontstyle'], 'normal'))
        .attr('font-weight', node.getIn(['configuration', 'style', 'fontstyle'], 'normal'))
        .attr('text-decoration', `${node.getIn(['configuration', 'underline']) ? 'underline' : 'none'}`)
    return newnode;
}

export const createTextNode = (element, text, x, y, fontsize, fontstyle, underline, color ) => {
    let newnode = element.append('text')
        .text(text)
        .attr('x', x)
        .attr('y', y)
        .attr('fill', color)
        .attr('font-size', fontsize + 'px')
        .attr('font-style', fontstyle)
        .attr('font-weight', fontstyle)
        .attr('text-decoration', underline)
        .attr('text-anchor', 'middle')
    return newnode;
}

export const drawTextBox = (element, textnode, node, getCustomerSettings, getUserSettings) => {
    var textBox = undefined;
    let displayStyle = node.getIn(['configuration', 'displaystyle']); 
  
    if ( displayStyle == 'boxed' || displayStyle == 'striped' || displayStyle == 'button'){
        // current font setting, fallback font size
        // add font size, will only be applied at creation of text as a default filled in value
        // make both configurable, in user, customer and component settings, also make a fallback
        let boxYOffset = 8;
        let boxXOffset = 8;

        if(getCustomerSettings) {
            boxYOffset = Number(getCustomerSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getCustomerSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        if(getUserSettings) {
            boxYOffset = Number(getUserSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getUserSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        boxYOffset = Number(node.getIn(['configuration','style','boxYOffset']) || boxYOffset);
        boxXOffset = Number(node.getIn(['configuration','style','boxXOffset']) || boxXOffset);

        let box = textnode.node().getBBox();
        let width = node.getIn(['configuration', 'width'], "");
        if (width.length > 0) {
            box.width = width;
        }

        textBox = element.insert('rect', 'text')
            .attr('x', box.x - boxXOffset)
            .attr('y', box.y - boxYOffset / 2)
            .attr('width', box.width + boxXOffset * 2)
            .attr('height', box.height + boxYOffset)
            .attr('fill', node.getIn(['configuration', 'style', 'background-color'], node.getIn(['component', 'defaultStyle', 'background-color'], '#ffffff')))
            .attr('stroke', node.getIn(['configuration', 'style', 'border-color'], '#000'));

        if ( displayStyle == 'button') textBox.attr('rx', 5); else textBox.attr('rx', node.getIn(['configuration', 'rx'], 0));

    }  

    return textBox;
}

export const drawTextBoxStripe = (element, textnode, node, getCustomerSettings, getUserSettings) => {
    var stripe = undefined;
    let displayStyle = node.getIn(['configuration', 'displaystyle']); 
  
    if ( displayStyle == 'striped' ){
        let boxYOffset = 8;
        let boxXOffset = 8;

        if(getCustomerSettings) {
            boxYOffset = Number(getCustomerSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getCustomerSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        if(getUserSettings) {
            boxYOffset = Number(getUserSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getUserSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        let box = textnode.node().getBBox();
        let width = node.getIn(['configuration', 'width'], "");
        if (width.length > 0) {
            box.width = width;
        }

        let stripeYSize = 6;

        stripe = element.insert('rect', 'text')
            .attr('id', 'stripe')
            .attr('x', box.x - boxXOffset + 1) 
            .attr('y', box.y - boxYOffset / 2) 
            .attr('width', box.width + boxXOffset * 2 - 2)
            .attr('height', stripeYSize)
            .attr('fill', node.getIn(['configuration', 'style', 'top-border-color'], '#ffff00'));    
    }

    if ( displayStyle == 'caret' ){
        let boxYOffset = 8;
        let boxXOffset = 8;

        if(getCustomerSettings) {
            boxYOffset = Number(getCustomerSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getCustomerSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        if(getUserSettings) {
            boxYOffset = Number(getUserSettings('fallbackTextboxYOffset') || boxYOffset);
            boxXOffset = Number(getUserSettings('fallbackTextboxXOffset') || boxXOffset);
        }

        let box = textnode.node().getBBox();
        let width = node.getIn(['configuration', 'width'], "");
        if (width.length > 0) {
            box.width = width;
        }

        let stripeYSize = 6;

        stripe = element.insert('rect', 'text')
            .attr('id', 'stripe')
            .attr('x', box.x - boxXOffset + 1) 
            .attr('y', box.y - boxYOffset / 2) 
            .attr('width', box.width + boxXOffset * 2 - 2)
            .attr('height', stripeYSize)
            .attr('fill', node.getIn(['configuration', 'style', 'top-border-color'], '#ffff00'));    
    }
    return stripe;
}

export const setMouseEvents = (element, options) => {
    element.on("mouseover", function(e) { 
        d3.select(this).style("opacity", "0.75");
      })
      .on("mouseout", function(e) { 
        d3.select(this).style("opacity", "1");
      })
}

export function blink(textNode) {
    let animate = textNode.append('animate')
    .attr('attributeName', 'display')
    .attr('attributeType', 'CSS')
    .attr('keyTimes', '0;0.5;1')
    .attr('values', 'inline;none;none')
    .attr('dur','1s')
    .attr('repeatCount', 'indefinite')   
    
    return animate;
}

export const drawTspan = (textnode, x, fontsize, text) => {
    // console.log('tspan ', text, x, fontsize);
    text.split('\n').forEach((line) => {
        // console.log('tspan ', line);
        textnode.append('tspan')
        .attr('x', x)
        .attr('dy', fontsize + 'px')
        .text(line)
    });
}  

export const displayDigitalValue = (placementElement, h, options, value, backgroundEnabled, backgroundcolour, backgroundGradient, digits, textcolor, textGradient ) => {
    let h1 = h / 2;
    let h2 = h1 / 12;

    let x = 0;
    let y = 0;

    let dot1 = h2 * 2;

    var numberarray;
    var topleft, bottomleft, topright, bottomright, top, middle, bottom, dot;

    backgroundGradient && setDefs(placementElement, "idPathBackground", 
        options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
        options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
        backgroundGradient);
    textGradient && setDefs(placementElement, "idPathText",
        options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
        options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
        textGradient);
     
    var num = 0;
    var nums = (""+value).split("");

    if ( backgroundEnabled && digits ) nums = Array(parseInt(digits) - nums.length).fill('0').concat(nums);

    for (var i = 0; i < nums.length; i++) {
        let height = h + (h2 * 2);

        if (backgroundEnabled) {
            drawRect(placementElement, x - h2,y - h2, h1 + h2 * 2, height,
                options.node.getIn(['configuration', 'borderwidth'], 1),options.node.getIn(['configuration', 'border-color'], '#000000'),
                options.node.getIn(['configuration', 'ry'], 0), backgroundGradient ,"idPathBackground", backgroundcolour, 1); }

        if ( nums[i] == '.' ) {
            dot = `M ${x + (h1 / 2)} ${h - dot1} L ${x + (h1 / 2) + dot1} ${h - dot1 * 2} L ${x + (h1 / 2) + dot1 * 2} ${h - dot1} L ${x + (h1 / 2) + dot1} ${h} Z`;
            drawPath(placementElement, dot, textGradient,"idPathText", textcolor, 0);
        }

        else {
            num = nums[i];
            topleft = `M ${x} ${2 * h2} L ${x + h2} ${h2} L ${x + h2 * 2} ${h2 * 2} L ${x + h2 * 2} ${h1 - h2} L ${x + h2} ${h1} L ${x} ${h1 - h2}Z`;
            bottomleft = `M ${x} ${h1 + h2} L ${x + h2} ${h1} L ${x + h2 * 2} ${h1 + h2} L ${x + h2 * 2} ${h - h2 * 2} L ${x + h2} ${h - h2} L ${x} ${h - h2 * 2} Z`;
            topright = `M ${x + h1 - h2 * 2} ${2 * h2} L ${x + h1 - h2} ${h2} L ${x + h1} ${2 * h2} L ${x + h1} ${h1 - h2} L ${x + h1 - h2} ${h1} L ${x + h1 - h2 * 2} ${h1 - h2} Z`;
            bottomright = `M ${x + h1 - h2 * 2} ${h1 + h2} L ${x + h1 - h2} ${h1} L ${x + h1} ${h1 + h2} L ${x + h1} ${h - h2 * 2} L ${x + h1 - h2} ${h - h2} L ${x + h1 - h2 * 2} ${h - h2 * 2} Z`;
            top = `M ${x + h2} ${h2} L ${x + h2 * 2} ${0} L ${x + h1 - h2 * 2} ${0} L ${x + h1 - h2} ${h2} L ${x + h1 - h2 * 2} ${h2 * 2} L ${x + h2 * 2} ${h2 * 2} Z`;
            middle = `M ${x + h2} ${h1} L ${x + h2 * 2} ${h1 - h2} L ${x + h1 - h2 * 2} ${h1 - h2} L ${x + h1 - h2} ${h1} L ${x + h1 - h2 * 2} ${h1 + h2} L ${x + h2 * 2} ${h1 + h2} Z`;
            bottom = `M ${x + h2} ${h - h2} L ${x + h2 * 2} ${h - h2 * 2} L ${x + h1 - h2 * 2} ${h - h2 * 2} L ${x + h1 - h2} ${h - h2} L ${x + h1 - h2 * 2} ${h} L ${x + h2 * 2} ${h} Z`;

            numberarray = [
                [top, topleft, topright, bottomleft, bottomright, bottom],
                [topright, bottomright],
                [top, topright, middle, bottomleft, bottom],
                [top, topright, middle, bottomright, bottom],
                [topleft, topright, middle, bottomright],
                [top, topleft, middle, bottomright, bottom],
                [top, topleft, middle, bottomleft, bottomright, bottom],
                [top, topright, bottomright],
                [top, topleft, topright, middle, bottomleft, bottomright, bottom],
                [top, topleft, topright, middle, bottomright, bottom],
            ]; 

            numberarray[num].map((path) => {
                drawPath(placementElement, path, textGradient,"idPathText", textcolor, 0);
            });
        }      
            
        x = x + h1 + 2 * h2; 
    } 
};

export const drawPath = (placementElement,  path, gradient, id, backgroundcolour, rotation) => {
    placementElement.append('path')
        .attr('d', `${path}`)
        .attr('transform', `rotate(${rotation})`)
    //    .attr('transform', `rotate(${rotation})`)
        .style("fill", gradient ? `url(#mainGradient${id})`:`${backgroundcolour}`);
} 

export const drawRect = (placementElement, x, y, width, height, strokewidth, stroke, ry, gradient, id, backgroundcolour, opacity) => {
    let rect = placementElement.append('rect')
        .attr('id', `rect${id}`)
        .attr('x', x)
        .attr('y', y)
        .attr('width', width) 
        .attr('height', height)
        .attr('stroke-width', strokewidth) 
        .attr('stroke', stroke) 
        .attr('ry', ry)
        .style("opacity", opacity)
        .style("fill", gradient ? `url(#mainGradient${id})`:`${backgroundcolour}`);
       
    return rect;
} 


export const drawCircle = (placementElement, x, y, radius, strokewidth, stroke, gradient, id, backgroundcolour) => {
    placementElement.append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('id', `circle${id}`)
        .attr('r', radius)
        .attr('stroke-width', strokewidth) 
        .attr('stroke', stroke) 
        .style("fill", gradient ? `url(#mainGradient${id})`:`${backgroundcolour}`);
}

export const createPolygonShape = (placementElement, options, height, hyp,  x1, y1, x2, opacity) => {
    let angle = options.node.getIn(['configuration', 'angle'],1.85) * (Math.PI / 180);
    var rads = Math.abs(Math.sin(angle));      
    var y_length = rads * hyp;
    rads = Math.abs(Math.cos(angle));
    var x_length = rads * hyp;
    var l = x_length + parseInt(x2);

    // console.log('x_length : ', x_length, ' x2 : ', x2, ' l : ', l);

    let poly = [{"x":x1,"y":y1},
                {"x":x2,"y":height},
                {"x":l,"y":height - y_length},
                {"x":x_length,"y":0 - y_length}];

    let p1 = poly.map((xy) => {
        return [xy.x,xy.y].join(",")
    });

    let p = drawPolygon(placementElement, p1,
        options.node.getIn(['configuration', 'strokewidth'], 0),
        options.node.getIn(['configuration', 'strokecolour'], 'black'), options.node.getIn(['configuration', 'gradient'] ),
        options.node.get('id'), options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'),
        options.node.getIn(['configuration', 'style', 'opacity'],opacity));
        
    return p;
}

export const drawPolygon = (element, poly2, strokeWidth, strokeColour, gradient, id, background, opacity) => {
    let poly = element.append("polygon")
    .attr("points",poly2)
    .attr("stroke",strokeColour)
    .attr("stroke-width",strokeWidth)
    .style("opacity", opacity)
    .style("fill", gradient ? `url(#mainGradient${id})`:`${background}`);

    return poly;
} 

export const setDefs = (element, id, x1, y1, x2, y2, grads) => {
    let svgDefs = element.append('defs');

    var mainGradient = svgDefs.append('linearGradient')
            .attr('id', 'mainGradient' + id)
            .attr('x1', x1)
            .attr('y1', y1)
            .attr('x2', x2)
            .attr('y2', y2);

    // console.log('grads :', id , grads)        

    grads.map((g) => {
        mainGradient.append('stop')
            .attr('style', "stop-color: " + g.get('colour'))
            .attr('offset', g.get('stop'));
    });

    return svgDefs;        
}