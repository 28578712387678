import * as d3 from 'd3';
import { displayImage, displayTooltip } from '../Utils';
import ComponentCache from '../ComponentCache';


export default function Hotspot(options, path) {
    return new Promise((resolve, reject) => {
        ComponentCache.getXml(path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = displayImage(options, image);

            let tooltip = displayTooltip(placementElement, options);

            placementElement.style("cursor", "hand");

            if (!options.editmode) {
                placementElement.style('opacity', '0');
                placementElement.style('fill-opacity', '0');
            } 
            
            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                    if (!editmode) {
                        placementElement.style('opacity', '0');
                        placementElement.style('fill-opacity', '0');
                    } else {
                        placementElement.style('opacity', '100');
                        placementElement.style('fill-opacity', '100');
                    }
                },
                getElement: () => {
                    return placementElement;
                },
                setOptions: (newOptions) => {
                    options = {...options, ...newOptions}
                },
                redrawElement: (node, isSelected) => {
                    options.node = node;
                    placementElement.remove();
                    placementElement = displayImage(options, image);
                    tooltip = displayTooltip(placementElement, options);
                    placementElement.style("cursor", "hand");
                    if (!options.editmode) {
                        placementElement.style('opacity', '0');
                        placementElement.style('fill-opacity', '0');
                    } 
                    if(isSelected) {
                        placementElement.attr('class', 'node-selected');
                    }
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}