import React from 'react';
import Button from 'Controls/Button/UserDefined';
import ModuleTypes from 'Configuration/ModuleTypes';

export function PointDetailsPanel({ customer, onClose, value }) {

    
    let addr = value.split('-').map((byte) => {
        return parseInt(byte);
    });
    
    let fields = ModuleTypes[addr[1]].pointtypes[addr[2]].fields;
    let subsystem = customer.get('subsystems', []).filter((subsystem) => {
        return addr[0] == subsystem.get('number')});

    return   <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
    <h2 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Point Details</h2>
    <div className="grid grid-cols-12 mt-2 gap-2">
        <div className="col-span-4">
            Subsystem
        </div>
        <div className="col-span-7">
            {subsystem.length > 0 && subsystem.get(0).get('name')}
        </div>
        <div className="col-span-4">
            Type
        </div>
        <div className="col-span-7">
            {ModuleTypes[addr[1]].name}
        </div>
        <div className="col-span-4">
            Point Type
        </div>
        <div className="col-span-7">
            {ModuleTypes[addr[1]].pointtypes[addr[2]].name}
        </div>
        <div className="col-span-4">
            {fields[0].label}
        </div>
        <div className="col-span-5">
            {addr[3].toString()} 
        </div>
        {fields[1] &&
            <div className="col-span-4">
                {fields[1].label}
            </div>}
        {fields[1] &&
            <div className="col-span-5">
                {addr[4].toString()}
            </div>}
        {fields[2] &&
            <div className="col-span-4">
                {fields[2].label}
            </div>}
        {fields[2] &&    
            <div className="col-span-5">
                {addr[5].toString()}
            </div>}
        {fields[3] &&
            <div className="col-span-4">
                {fields[3].label}
            </div>}
        {fields[3] &&
            <div className="col-span-5">
                {addr[6].toString()}
            </div>}
    </div>

</div>    

}


