import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

const WindowContext = React.createContext({});




export function useWindow() {
    return useContext(WindowContext);
}

export default function WindowProvider({ children }) {
    
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    
    let windowClass = "lg";
    if (innerWidth < 992) {
        windowClass = "sm";
    }



    useEffect(() => {
        const handleResize = () => {
            console.log("resizing", window.innerWidth);
            setInnerWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const value = useMemo(() => ({
       windowClass: innerWidth < 992 ? "sm" : "lg"
    }), [windowClass])

    return <WindowContext.Provider value={value}>
        {children}
    </WindowContext.Provider>
}