import React, {useState} from 'react';
import Immutable from 'immutable';
import DeviceDialog from './DeviceDialog';
import Swal from 'sweetalert2';
import { TrashIcon, PlusIcon, Cog6ToothIcon, SignalIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import Grid from 'Controls/GridV2';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OutstationList = ({customer, selectedsubsystem, selectedlan, outstations, paneltypes, selectedoutstation, setSelectedOutstation, scanForPoints, updateItem, deleteItem}) => {
    const [selecteditem, setSelectedItem] = useState(undefined);

    const addItem = () => {
      setSelectedItem(Immutable.Map({'subsystem':selectedsubsystem,'lannumber':selectedlan.get('number'), 'intermmode':0, 'iqlstrategy': 0, 'revision': '', 'settime':0}));
    }

    const buttonClick = (task) => {
      if (selectedlan == undefined) {
        Swal.fire('Error', 'Outstation not selected', "error");
        return;
      }

      task();
    }

    const editSettings = (selectedoutstation) => {
      setSelectedItem(selectedoutstation);
    }

    const clearDialog = () => {
      setSelectedItem(undefined);
    } 

    const getPanelType = (type) => {
      if (!paneltypes) return type;
      let panel = paneltypes.filter((paneltype) => paneltype.number == type)

      return panel[0].text;
    }

    return (
      <div>
      { selecteditem && 
        <DeviceDialog customer={customer} selectedItem={selecteditem} selectedSubsystem={selectedsubsystem} type={'trendoutstations'} saveItem={(item) => updateItem('trendoutstations', item)} onClose={() => clearDialog()}></DeviceDialog> }
        <div className="">
          <div className="border-b border-gray-200 bg-white px-2">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap pb-2">
              <div>
                <h1 className="text-lg leading-6 font-medium text-gray-900">Outstations</h1>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <div className="pl-4">
                  <PlusCircleIcon className="h-6 w-6 text-gray-700 cursor-pointer" onClick={() => addItem()} />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
            <table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
              <thead className="bg-blue-500">
                <tr>
                  <th
                    scope="col"
                    className="w-1/12 sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 px-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="sticky w-5/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky w-2/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Revision
                  </th>
                  <th className="sticky w-3/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">

                  </th>
                  
                </tr>
              </thead>
              <tbody className="bg-white">
                {(outstations || []).sort((a,b) => a.get('outnumber') - b.get('outnumber')).map((outstation, index) => (
 
                  <tr key={outstation.get('_id')} onClick={() => setSelectedOutstation(outstation)} className= {selectedoutstation && (outstation.get('_id') == selectedoutstation.get('_id')) ? "group relative px-1 bg-green-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500":
                  "group relative px-1 hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"}>
                    <td
                      className={classNames(
                        index !== outstations.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 '
                      )}
                    >
                      {outstation.get('outnumber')}
                    </td>
                    <td
                      className={classNames(
                        index !== outstations.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}
                    >
                      {outstation.get('identifier')}
                    </td>
                    <td
                      className={classNames(
                        index !== outstations.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}
                    >
                      {outstation.get('revision')}
                    </td>
                    <td
                      className={classNames(
                        'border-b border-gray-200',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}>
                      <Grid.Controls>
                        <Grid.Control>
                          {/* <a className="hidden group-hover:block pl-2 cursor-pointer text-blue" onClick={() => { 
                            setSelectedOutstation(outstation)
                            scanForPoints();
                          }} title="Scan for outstations">scan</a> */}
                          <SignalIcon title="Scan for points" className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {

                            setSelectedOutstation(outstation)
                            scanForPoints();
                          }}/>
                        </Grid.Control>
                        <Grid.Control>
                          <Cog6ToothIcon className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {

                            setSelectedOutstation(outstation);
                            editSettings(outstation)
                          }} />
                        </Grid.Control>
                        {/* <Grid.Control><PencilSquareIcon className="hidden text-gray-500 group-hover:block w-5"  /></Grid.Control> */}
                                              <Grid.Control>
                          <TrashIcon className="cursor-pointer  hidden w-5 text-gray-500 group-hover:block " onClick={() => {
                            setSelectedOutstation(outstation);
                            deleteItem('trendoutstations', outstation);
                          }} />
                        </Grid.Control> 
										  </Grid.Controls>
                    </td>
                  </tr>

                ))}  
              </tbody>
            </table>
          </div>
      </div>
      </div>

    );
}

export default OutstationList;
