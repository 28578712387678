import React, { Fragment, useState, useEffect } from 'react';
import Immutable, { List, Map, set } from 'immutable';
import Button from 'Controls/Button/UserDefined';
import Select from 'Controls/Form/Select';
import ModuleTypes from 'Configuration/ModuleTypes';
import DateTimeSelection from 'Controls/DateTimeSelection';
import { getHistoryData } from './Actions';
import { searchPoints } from './Actions';
import ChartHistories from './Chart';
import { HistoriesTablePanel, currentDay, HistoriesTable, previousDay, nextDay } from './HistoriesDialog';
import debuggerService from 'Services/DebuggerService';
const logger = debuggerService.getDebugger('Histories');

function pointAddrToText(addr) {
    let selectedSubsystemType = ModuleTypes[addr[1]];
    let pointtypes = selectedSubsystemType.pointtypes;
    let pointtype = pointtypes[addr[2]];

    return `Lan ${addr[3]} O/S ${addr[4]} ${pointtype.name} ${addr[5]}`
}

function Histories({ customer }) {


    logger.log("customer", customer);

    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    const [points, setPoints] = useState(List());
    const [pointselected, setPointSelected] = useState(undefined);
    const [subsystemIndex, setSubsystemIndex] = useState(0);
    const [subsystemNumber, setSubsystemNumber] = useState(customer?.get('subsystems')?.get(0)?.get('number'));
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType: 'Today',
        startDate: startDate,
        endDate: new Date()
    });
    const [records, setRecords] = useState([]);
    const [charttype, setChartType] = useState('XYChartScrollbar');
    const [displayMode, setDisplayMode] = useState('chart');
    const [selectedpoints, setSelectedPoints] = useState(List());
    const [currentDate, setCurrentDate] = useState(currentDay());
    
    useEffect(() => {

        (async () => {
            const customerName = customer.get('name').toLowerCase().replace(/ /g, "");
            const ipAddress = customer.get('ipaddress').toLowerCase();
            const queryObject = {
                historysetup: { $exists: true } , "historysetup.enabled": { $eq: 1 }
            };
            const data =  await searchPoints(customerName, ipAddress, queryObject);
            let points = Immutable.fromJS(
                data.map((point) => ({
                    addr: point.addr,
                    address: point.addr.join('-'),
                    addresstext: pointAddrToText(point.addr),
                    name: point.name,
                    engunits: point.engunits,
                    period: point.historysetup.interval
                })
            ));
            setPoints(points);
            setPointSelected(points.get(0).get('address'));
        })();
    }, [])

    const displayChart = () => {
        let point = points.filter((item) => pointselected == item.get('address')).map((item) => item);
        if(point && point.get(0)) {
            setSelectedPoints(List([
                Map({
                    'name': point.get(0).get('name'),
                    'address': point.get(0).get('address'),
                    'engunits': [point.get(0).get('engunits').get(0)],
                    'colour': 'blue'
                })
            ]));
        }
    }

    const getHistory = async () => {
        // let {pointselected, dateTimeRange}= this.state; 
        let points = [];
        points.push(pointselected);

        // include this for summer time hour difference
        // records saved in mongo are grouped by day and in UTC format
        let dateStartToMongo = new Date(dateTimeRange.startDate);
        dateStartToMongo.setHours(dateStartToMongo.getHours() - 1);

        let startDate = `${dateStartToMongo.getFullYear()}-${("0" + (dateStartToMongo.getMonth() + 1)).slice(-2)}-${("0" + dateStartToMongo.getDate()).slice(-2)}`;
        let endDate = `${dateTimeRange.endDate.getFullYear()}-${("0" + (dateTimeRange.endDate.getMonth() + 1)).slice(-2)}-${("0" + dateTimeRange.endDate.getDate()).slice(-2)}`;
        if(displayMode == 'table') {
            startDate = currentDate;
            endDate = currentDate;
        }


        var queryObject = {
            addrstring: { $in: points},
            datestring: { $gte: startDate,
             $lte: endDate }
        }

        const customerName = customer.get('name').toLowerCase().replace(/ /g, "");
        const ipAddress = customer.get('ipaddress').toLowerCase();
        const data = await getHistoryData(customerName, ipAddress, queryObject);
        setRecords((data || []).map((record) => {
            return {
                name: record.addrstring,
                time: record.datestring,
                samples: record.sample
            }
        }));
        return true;
       
    }

    useEffect(() => { // performed after displayChart
        (async () => {
            // console.log("trying to get the history");
            await getHistory();
            // console.log("history retrieved");
            displayChart();
        })();

    }, [pointselected, currentDate, displayMode, dateTimeRange])

    const pointChanged = (point) => {
        setPointSelected(point);
        setRecords([]);
    }

    const subsystemNumberChanged = (subsystemIndex) => {
        let subsystemNumber = customer.get('subsystems').get(subsystemIndex).get('number');
        let selectedpoints= (points || []).filter((point) => point.getIn(['addr', 0]) == Number(subsystemNumber)).map((point) => {
                return point.get('address')
            }
        );
        setPointSelected(selectedpoints.get(0));
        setSubsystemNumber(subsystemNumber);
        setSubsystemIndex(subsystemIndex);
        setRecords([]);
    }

    let chartTypes = [{
        value: 'XYChartScrollbar', label: 'XY Series' 
    },{
        value: 'AreaSeries', label: 'Area Chart'
    }]

    return (
        <div className="mt-12 container mx-auto py-4 px-2">
            <div className="flex flex-row gap-6 items-center">
                <h2>Histories</h2>
                <div className="flex flex-row h-min text-gray-600 cursor-pointer" onClick={() => setDisplayMode((x) => x == "chart" ? 'table' : 'chart')}>
                    <div className={`px-3 py-1 rounded-l-lg ${displayMode == 'chart' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                        Chart
                    </div>
                    <div className={`px-3 py-1 rounded-r-lg ${displayMode == 'table' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                        Table
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-row gap-2 w-full">
                    <div className="px-2">
                        <Select
                            label="Subsystem"
                            value={subsystemIndex}
                            onChange={subsystemNumberChanged}
                            options={customer?.get('subsystems').map((module, index) => {
                                return {
                                    value: index,
                                    text: module.get('name')
                                }
                            })}
                        />
                    </div>
                    <div className="px-2">
                        <Select
                            label="Bound point"
                            value={pointselected}
                            onChange={pointChanged}
                            options={(points || []).filter((point, index) => 
                                point.getIn(['addr', 0]) == Number(subsystemNumber))?.map((point, index) => {
                                    return {
                                        value: point.get('address'),
                                        text: `${point.get('name')} (${point.get('addresstext')})`
                                    }
                                })
                            }
                        />
                    </div>
                    { displayMode == 'chart' && <Fragment>
                        <div className="px-2">
                            <Select
                                label="Chart Type"
                                value={charttype}
                                onChange={(value) => setChartType(value)}
                                options={chartTypes.map((type) => {
                                    return {
                                        value: type.value,
                                        text: type.label
                                    }
                                })}
                            />
                        </div>
                        <div className="flex flex-row"> 
                            <DateTimeSelection dateTimeRange={dateTimeRange} updateDateTimeRange={setDateTimeRange}/>              
                        </div>
                    </Fragment> }
                    { displayMode == 'table' && <div className="flex flex-col gap-1">
                        <div className="whitespace-nowrap"><span className="font-bold">Date:</span><span> {currentDate}</span></div>
                        <div className="flex flex-row gap-3 items-center justify-between">
                            
                            <div className="flex flex-row gap-3">
                                <div><Button className="whitespace-nowrap" role="primary" onClick={() => setCurrentDate(previousDay(currentDate))}>Previous day</Button></div>
                                <div><Button className="whitespace-nowrap" role="primary" onClick={() => setCurrentDate(nextDay(currentDate))}>Next day</Button></div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            { displayMode == 'table' && <Fragment>
                <HistoriesTable records={records} selectedpoints={selectedpoints} />
            </Fragment>}
            { displayMode == 'chart' && <Fragment>
                <div className="w-g-12 py-2 px-2">
                    <Button role="primary" onClick={displayChart}>Display Chart </Button>
                </div>
                <div>
                    <ChartHistories records={records} dateSelectionType={dateTimeRange.rangeType} dateStart={dateTimeRange.startDate} dateEnd={dateTimeRange.endDate} selectedpoints={selectedpoints} charttype={charttype}/>
                </div>
            </Fragment> }
        </div>
    );
}

export default Histories;
