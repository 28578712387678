import React, { useState, useMemo, useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

const ClipboardContext = React.createContext({});

export const ClipboardProvider = ClipboardContext.Provider;



export function useClipboard() {
    return useContext(ClipboardContext);
}

// This will be needed as long as we still use class components since they can't make use of react hooks
export function withClipboard(Component) {
    return (props) => {
        const { clipboard, setClipboard } = useClipboard();
        return <Component clipboard={clipboard} setClipboard={setClipboard} {...props} />
    }
}

export default function Clipboard({ children }) {
    const [clipboard, setClipboard] = useStateIfMounted({});
    const value = useMemo(() => ({ clipboard, setClipboard }), [clipboard]);
    return <ClipboardProvider value={value}>
        {children}
    </ClipboardProvider>
}