import React, { useState } from 'react';
import Input from 'Controls/Form/Input';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function DeviceDialog({ selectedItem, onClose, ...props }) {
    return <Dialog opened={!!selectedItem} onClose={onClose} >
        <DeviceDialogPanel selectedItem={selectedItem} onClose={onClose} {...props}/>
    </Dialog>
}

const DeviceDialogPanel = ({customer, selectedItem, selectedSubsystem, type, saveItem, onClose}) => {
    const definitionFields = [{
        'type' : 'ex500controllers',
        'fields' : [
            {
                'field': 'identifier',
                'label': 'Identifier',
                'functionname': undefined
            },{
                'field': 'controllernumber',
                'label': 'Controller Number',
                'functionname': parseInt
            },{
                'field': 'type',
                'label': 'Type',
                'functionname': parseInt
            }]
    }];

    let [item, setItem] = useState(selectedItem);

    const fieldChanged  = (field, value, transform) => {
        if (transform) {
            value = transform(value);
        }

        setItem(item.set(field, value));
    }

    const onSave = () => {
        saveItem(item);
    }


    let addField = (field) => {
        let fieldname = field.field;
        let functionname = field.functionname;

        return (
            <div className="col-16 col-md-16">
                <Input label={field.label} value={item.get(fieldname)} onChange={ (value) => {fieldChanged(fieldname, value, functionname)}} />
            </div>
        )
    }

    let fields = definitionFields.filter((fieldtype) => {
        return fieldtype;
    });

    fields = fields[0].fields;

    return (
        <div className="container-fluid">
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Controller Definition</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    {addField(fields[0])}
                    {addField(fields[1])}
                    {addField(fields[2])}
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={ () => onClose()}>Close</Button></div>
                    <div className="pl-1"><Button role="primary" onClick={ () => onSave()}>Save changes</Button></div>
                </DialogFooter>
            </DialogContent>
        </div>
    )
};

export default DeviceDialog;