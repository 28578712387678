import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import Immutable from "immutable";
import { useStateIfMounted } from "use-state-if-mounted";

async function getNotificationsFromServer() {
    let response = await axios.get("/api/notifications");
    return Immutable.fromJS(response.data);
}

const NotificationContext = React.createContext({});

export const NotificationProvider = NotificationContext.Provider;

export function useNotifications() {
    return useContext(NotificationContext);
}

export function withNotifications(Component) {
    return props => {
        const notifications = useNotifications();
        return <Component notifications={notifications} {...props} />;
    };
}

export default function NotificationWrapper({ children }) {
    const [notifications, setNotifications] = useStateIfMounted(
        Immutable.List()
    );

    useEffect(() => {
        getNotificationsFromServer().then(notifications => {
            setNotifications(notifications);
        });
    }, []);

    const value = useMemo(() => ({ notifications, setNotifications }), [
        notifications
    ]);

    return (
        <NotificationProvider value={value}>
            {children}
        </NotificationProvider>
    );
}