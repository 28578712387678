import React from 'react';
import {Routes, Route} from 'react-router-dom';
import AreaSidebar from 'BmsView/Customer/Site/Area/Sidebar';


/**
 * I have the feeling that this component is not in use anymore that's why I'm not going to refactor it.
 * potential usages seem to be commented out.
 */

class Sidebar extends React.Component{
    render(){
        return (
            <Routes>
                <Route path="/customer/:customer/site/:site/*" component={(props) => {
                    return  (
                        <AreaSidebar {...props}/>
                    )
                }} />
            </Routes>
        )
    }
}

export default Sidebar;