import React, {useState, useEffect} from 'react';
import Immutable from 'immutable';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import ColorPicker from 'Controls/ColorPicker';
import Button from 'Controls/Button/UserDefined';

export default function Rules ({selectedcomponent, currentValue, onChange}) {
    const [currentvalue, setCurrentValue] = useState(currentValue);

    useEffect (() => {
        if (selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' ) {
            setCurrentValue(currentvalue.setIn(['action'], 'fill'));}
    }, [])

    const displayHeader = (type, stateids) => {
        return (
            <div className="my-4">
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16 md:col-span-3">
                        <label className="block label">Condition</label>
                    </div>
                    <div className="col-span-16 md:col-span-3">
                        <label className="block label">Value</label>
                    </div>
                    <div className="col-span-16 md:col-span-3">
                        <label className="block label">Action</label>
                    </div>
                    { type != "shape" &&
                        <div className="col-span-16 md:col-span-3">
                            <label className="block label">Target</label>
                        </div>}
                    {stateids.get(0) && stateids.get(0).get('action') == 'fill' && 
                        <div className="col-span-16 md:col-span-1">
                            <label className="block label">Colour</label>
                        </div>} 
                </div>
            </div>)
    }

    const change = (rule, index, currentvalue, field, value) => {
        if (rule) {
            onChange(['configuration', 'rules', index, field], value);
        } else {
            setCurrentValue(currentvalue.setIn([field], value));
        }
    }

    const displayCondition = (rule, index, currentvalue) => {
        let rulevalue = rule || currentvalue;

        return (
            <React.Fragment>
                <div className="col-span-16 md:col-span-3">
                    <Select
                        options={[
                            { value: "$range", text: "In range" },
                            { value: "$eq", text: "Equals" },
                            { value: "$neq", text: "Not equals" },
                            { value: "$in", text: 'In' },
                            { value: '$nin', text: "Not in" }
                        ]}
                        onChange={(value) => change(rule, index, currentvalue, 'condition', value)}
                        value={rulevalue.get('condition')}
                    />
                </div>
                <div className="col-span-16 md:col-span-3">
                    {rulevalue.get('condition') && <Input
                        onChange={(value) => change(rule, index, currentvalue, 'matchvalue', value)}
                        value={rulevalue.get('matchvalue', null)}
                    />}
                </div>
            </React.Fragment>
        )
    }

    const displayAction = (rule, index, currentvalue, selectedcomponent) => {
        let rulevalue = rule || currentvalue;

        return(
            <div className="col-span-16 md:col-span-3">
                {rulevalue.get('action') == 'fill' && 
                    <Select
                        options={[
                            { value: 'fill', text: 'Fill' }
                        ]}
                        onChange={(value) => change(rule, index, currentvalue, 'action', value)}
                        value={rulevalue.get('action')}
                    />}
                {rulevalue.get('action') != 'fill' && 
                    <Select
                        options={[
                            { value: 'show', text: 'Show' },
                            { value: 'hide', text: 'Hide' }
                        ]}
                        onChange={(value) => change(rule, index, currentvalue, 'action', value)}
                        value={rulevalue.get('action')}
                />}    
            </div>
        )
    }

    const displayTarget = (rule, index, currentvalue, selectedcomponent, action) => {
        let rulevalue = rule || currentvalue;

        return(
            <React.Fragment>
                {rulevalue.get('action') != 'fill' && !selectedcomponent.getIn(['component', 'sitespecific']) &&
                    <div className="col-span-16 md:col-span-4"> 
                        <Select
                            options={selectedcomponent.getIn(['component', 'stateids']).map((state) => {
                                return {
                                    value: state.get('target'),
                                    text: state.get('description')
                                }
                            })}
                            onChange={(value) => change(rule, index, currentvalue, 'target', value)}
                            value={rulevalue.get('target')}
                        />
                    </div>}
                    {rulevalue.get('action') == 'show' && selectedcomponent.getIn(['component', 'sitespecific']) &&
                        <div className="col-span-16 md:col-span-4">
                            <Input onChange={(value) => change(rule, index, currentvalue, 'target', value)} value={rulevalue.get('target')}/>
                        </div> } 
                    {rulevalue.get('action') == 'fill' && selectedcomponent.getIn(['type', 'shape']) && 
                        <div className="col-span-16 md:col-span-3">
                            <Input onChange={(value) => change(rule, index, currentvalue, 'target', value)} value={rulevalue.get('target')}/>
                        </div> } 
                    {rulevalue.get('action') == 'fill' && selectedcomponent.getIn(['component', 'sitespecific']) && 
                        <div className="col-span-16 md:col-span-1">
                            <ColorPicker value={rulevalue.get('fill')} onChange={(value) => change(rule, index, currentvalue, 'fill', value)}/>
                        </div> }  
                    {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' && 
                        !selectedcomponent.getIn(['component', 'sitespecific']) && 
                        <div className="col-span-16 md:col-span-1">
                            <ColorPicker value={rulevalue.get('fill')} onChange={(value) => change(rule, index, currentvalue, 'fill', value)}/>
                        </div> } 
            </React.Fragment>)
    }

    const addRule = () => {
        let rules = selectedcomponent.getIn(['configuration', 'rules'], Immutable.List([]));
        rules = rules.push(currentvalue);

        onChange(['configuration', 'rules'], rules);

        selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' ?
            setCurrentValue(Immutable.Map({'action': 'fill'})) : setCurrentValue(Immutable.Map({}));            
    }

    const removeRule = (index) => {
        let rules = selectedcomponent.getIn(['configuration', 'rules']);
        rules = rules.delete(index);

        onChange(['configuration', 'rules'], rules);
    }

    return (
        <div>          
            {displayHeader(selectedcomponent.getIn(['component', 'type']),selectedcomponent.getIn(['component', 'stateids']))}  
            {selectedcomponent.getIn(['configuration', 'rules'], []).map((rule, index) => {
                return (
                    <div className="grid grid-cols-16 gap-2">
                        {displayCondition(rule, index, null)}
                        {displayAction(rule, index, null, selectedcomponent)}
                        {displayTarget(rule, index, null, selectedcomponent)}
                        <div className="col-span-12 md:col-span-2">
                            <Button role="danger" className="w-28" onClick={() => removeRule(index)}>Remove</Button>
                        </div>      
                    </div>
                )})} 
                <div>
                    <div className="grid grid-cols-16 gap-2">
                        {displayCondition(null, 0, currentvalue)}
                        {displayAction(null, 0, currentvalue, selectedcomponent)}
                        {displayTarget(null, 0, currentvalue, selectedcomponent, selectedcomponent.getIn(['component', 'stateids']).get(0) ? selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') : null) }
                        <div className="col-span-12 md:col-span-2">
                            <Button role="success" className="w-28" onClick={() => addRule()}>Add</Button>
                        </div>
                    </div>
                </div>
        </div>
    )
}


// {displayTarget(null, 0, currentvalue, selectedcomponent)}

/*

                            {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') != 'fill' &&
                                selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') != 'show' &&
                                    <div className="col-span-16 md:col-span-4"> 
                                        <Select options={selectedcomponent.getIn(['component', 'stateids']).map((state) => {
                                            return {
                                                value: state.get('target'),
                                                text: state.get('description')}
                                            })}
                                            onChange={(value) => valueChanged(['target'], value)}
                                            value={currentvalue.get('target')}/>
                                    </div>} 
                            {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'show' &&
                                <div className="col-span-16 md:col-span-3">
                                    <Input onChange={(value) => valueChanged(['target'], value)} value={currentvalue.get('target')}/>
                                </div> } 
                            {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' &&
                                selectedcomponent.getIn(['component', 'type']) != "shape" &&
                                <div className="col-span-16 md:col-span-3">
                                    <Input onChange={(value) => valueChanged(['target'], value)} value={currentvalue.get('target')}/>
                                </div> }  
                            {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' &&
                                <div className="col-span-16 md:col-span-1">
                                    <ColorPicker value={currentvalue.get('fill')} onChange={(value) => valueChanged(['fill'], value)}/>
                                </div>}
                            {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('type') == 'fill' && 
                                <div className="col-span-12 md:col-span-1">
                                    <ColorPicker value={currentvalue.get('fill')} onChange={(value) => valueChanged(['fill'], value)}/>
                                </div>} 

                                */