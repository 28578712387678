import React, { useState, useEffect, useRef } from 'react';
import { useArea } from '../../../../../Context/Area';
import PointDataListPoint from './PointDataListPoint';


export default function PointDataList({ localConfigureNode }) {
    
    let { layers } = useArea();
    let points = {};
    layers && layers.forEach((layer) => {
        layer.get('nodes') && layer.get('nodes').forEach((node) => {
            let address = undefined;
            if (node.getIn(['configuration', 'boundto']) && node.getIn(['component', 'type']) != 'chart') {
                address = node.getIn(['configuration', 'boundto']);
                points[address] = { node: node }
            }
            if (node.getIn(['configuration', 'points'])) {
                node.getIn(['configuration', 'points']).forEach((point) => {
                    let address = point.get('value');
                    points[address] = { node: node }
                });
            }
        })
    })

    return <div className="">
        {Object.keys(points).map((address) => {

            return <PointDataListPoint localConfigureNode={localConfigureNode} key={address} address={address} point={points[address]} />
        })}
    </div>;
}