import React from 'react';

import Immutable from 'immutable';
import Swal from 'sweetalert2';
import Grid from 'Controls/GridV2';
import {getHistoryTemplates, saveTemplate, deleteTemplate} from './Actions';
import HistoriesTemplateSetupDialog from './HistoriesTemplateSetupDialog';
import HistoriesDialog from './HistoriesDialog';
import Button from 'Controls/Button/UserDefined';

class HistoriesTemplatesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            templates: undefined
        }
    }

    componentDidMount() {
        this.getTemplates();
    }

    getTemplates() {
        let customer = this.props.params;

        getHistoryTemplates(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
         `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
            this.setState( {
                templates: Immutable.fromJS(data.map((template) => {
                    return ({
                        _id: template._id,
                        name: template.name,
                        points: template.points 
                    });
                })) 
            });       
        })
    }

    addItem() {
        this.setState({
            selectedtemplate: Immutable.Map()
        });
    }

    displayChart(template) {
        this.setState({
            selectedtemplatedisplay: template
        })
    }

    saveTemplate(template) {
        if (!template.get('name')) {
            Swal.fire("Error", "Please enter template name", "error");
            return;
        }

        if (!template.get('points')) {
            Swal.fire("Error", "Add at least one point to the template", "error");
            return;
        }

        let customer = this.props.params;

        saveTemplate(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, template).then(() => {
                this.clearSelection();
                this.getTemplates();
                Swal.fire("Success", "The Histories Template has been succesfully saved.", "success");
            });
    }

    editTemplate(template) {
        this.setState({
            selectedtemplate: template
        })
    }

    removeTemplate(template) {
        let customer = this.props.params;

        deleteTemplate(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                `${customer.get('ipaddress').toLowerCase()}`,
                template).then(() => {
            this.getTemplates();
        })
    }

    clearSelection() {
        this.setState({
            selectedtemplate: undefined,
            selectedtemplatedisplay: undefined
        });
    } 

    render() {
        let customer = this.props.params;
        let {selectedtemplate, selectedtemplatedisplay, templates} = this.state;

        return (
            <div className="px-3 py-2">           
                <HistoriesTemplateSetupDialog customer={customer} selecteditem={selectedtemplate} onSave={this.saveTemplate.bind(this)} onClose={this.clearSelection.bind(this)} />
                <HistoriesDialog customer={customer} selecteditem={selectedtemplatedisplay} onClose={this.clearSelection.bind(this)} />
                <Grid>
                    <Grid.Header>
                        <Grid.Column classes="col-span-16 md:col-span-10">Name</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-2" name="display" type="complex"></Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-2" name="edit" type="complex"></Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-2" name="delete" type="complex">
                            <Button role="success" onClick={this.addItem.bind(this)}>Add new</Button>
                        </Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        { (templates || []).map((template, index) => {
                            return (
                                <Grid.Row>
                                    <Grid.Column classes="col-span-16 md:col-span-10">{template.get('name')}</Grid.Column>
                                    <Grid.Column classes="col-span-16 md:col-span-2">
                                        <Button role="secondary" onClick={this.displayChart.bind(this, template)}>Display Histories</Button>
                                    </Grid.Column>
                                    <Grid.Column classes="col-span-16 md:col-span-2">
                                        <Button role="primary" onClick={this.editTemplate.bind(this, template)}>Edit Template</Button>
                                    </Grid.Column>
                                    <Grid.Column classes="col-span-16 md:col-span-2">
                                        <Button role="danger" onClick={this.removeTemplate.bind(this, template)}>Remove Template</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        }) }
                    </Grid.Data>
                </Grid> 


            </div>
        );
    }
}

export default HistoriesTemplatesList;

//                <Chart selectedtemplate={selectedtemplate2} />