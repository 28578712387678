import { forwardRef, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import * as d3 from 'd3';

export default forwardRef(function SelectionBox({ configuration, onResize, onResizeEnd, node }, ref) {

    const topLeftButtonRef = useRef(null);
    const leftButtonRef = useRef(null);
    const topButtonRef = useRef(null);
    const bottomLeftButtonRef = useRef(null);
    const topRightButtonRef = useRef(null);
    const bottomRightButtonRef = useRef(null);
    const rightButtonRef = useRef(null);
    const bottomButtonRef = useRef(null);
   

    const startCoords = useRef(null);
    const currentCoords = useRef({ dx: 0, dy: 0 });
    const circleCenter = useRef(null);

    function drag(isLeft, isTop, direction) {

        return d3.drag().on('start', function () {
            let bounds = ref.current.getBoundingClientRect();
            startCoords.current = { x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height };
            currentCoords.current = { dx: isLeft ? 0 : bounds.width, dy: isTop ? 0 :  bounds.height };
            circleCenter.current = { x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 };
            d3.select(this).raise();
    
        })
    }


    function onDrag(isLeft, isTop, direction,  evt) {
        let { x, y, width, height } = startCoords.current;
        currentCoords.current = { 
            dx: currentCoords.current.dx + (evt.x - (isLeft ? 0 : currentCoords.current.dx)), 
            dy: currentCoords.current.dy + (evt.y - (isTop ? 0 : currentCoords.current.dy))
        };
        let { dx , dy } = currentCoords.current;
        let selection = d3.select(ref.current);

        let isYDirection = direction.indexOf('y') > -1;
        let isXDirection = direction.indexOf('x') > -1;

        if(isYDirection) {
            if(isTop && direction) {
                selection.style('top', (y + dy) + 'px')
                selection.style('height', (height - dy) + 'px')
            } else {   
                selection.style('height', (height - height + dy) + 'px')
            }
        }

        if(isXDirection) {
            if(isLeft) {
                selection.style('left', (x + dx) + 'px')
                selection.style('width', (width - dx) + 'px')
            } else {
                selection.style('width', (width - width + dx) + 'px')
            }
        }
        
        let resizeX = isLeft ? dx : 0 - width + dx;
        let resizeY = isTop ? dy : 0 - height + dy;
        onResize(
            isXDirection && resizeX || 0, 
            isYDirection && resizeY || 0, 
            isLeft, 
            isTop, 
            direction
        );
    } 

    function dragEnd(isLeft, isTop, direction, evt) {
        let { x, y, width, height } = startCoords.current;
        currentCoords.current = { 
            dx: currentCoords.current.dx + (evt.x - (isLeft ? 0 : currentCoords.current.dx)), 
            dy: currentCoords.current.dy + (evt.y - (isTop ? 0 : currentCoords.current.dy))
        };
        let { dx , dy } = currentCoords.current;
        let resizeX = isLeft ? dx : 0 - width + dx;
        let resizeY = isTop ? dy : 0 - height + dy;
        let isYDirection = direction.indexOf('y') > -1;
        let isXDirection = direction.indexOf('x') > -1;

        // console.log("drag end detected", evt);
        onResizeEnd(
            isXDirection && resizeX || 0, 
            isYDirection && resizeY || 0, 
            isLeft, 
            isTop, 
            direction
        );

    }

    useEffect(() => {
       
        d3.select(topLeftButtonRef.current)
        .call(drag(true, true, "xy")
        .on('drag', onDrag.bind(null, true, true, "xy"))
        .on('end', dragEnd.bind(null, true, true, "xy")))

        d3.select(leftButtonRef.current)
        .call(drag(true, true, "x")
        .on('drag', onDrag.bind(null, true, true, "x"))
        .on('end', dragEnd.bind(null, true, true, "x")))

        d3.select(topButtonRef.current)
        .call(drag(true, true, "y")
        .on('drag', onDrag.bind(null, true, true, "y"))
        .on('end', dragEnd.bind(null, true, true, "y")))


        d3.select(bottomLeftButtonRef.current)
        .call(drag(true, false, "xy")
        .on('drag', onDrag.bind(null, true, false, "xy"))
        .on('end', dragEnd.bind(null, true, false, "xy")))

        d3.select(topRightButtonRef.current)
        .call(drag(false, true, "xy")
        .on('drag', onDrag.bind(null, false, true, "xy"))
        .on('end', dragEnd.bind(null, false, true, "xy")))

        d3.select(bottomRightButtonRef.current)
        .call(drag(false, false, "xy")
        .on('drag', onDrag.bind(null, false, false, "xy"))
        .on('end', dragEnd.bind(null, false, false, "xy")))

        d3.select(rightButtonRef.current)
        .call(drag(false, false, "x")
        .on('drag', onDrag.bind(null, false, false, "x"))
        .on('end', dragEnd.bind(null, false, false, "x")))

        d3.select(bottomButtonRef.current)
        .call(drag(false, false, "y")
        .on('drag', onDrag.bind(null, false, false, "y"))
        .on('end', dragEnd.bind(null, false, false, "y")))

    },[node])

    return createPortal(<div ref={ref} className="selection-box" style={{ position:'absolute', display: 'none', 'pointer-events':'none' }}>

    
        <div ref={topLeftButtonRef} className="cursor-nwse-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', top: '0px', left: '0px'}}></div>
        <div ref={leftButtonRef} className="cursor-ew-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', top: 'calc(50% - 5px)', left: '0px'}}></div>
        <div ref={topButtonRef} className="cursor-ns-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', top: '0px', left: 'calc(50% - 5px)'}}></div>
        <div ref={bottomLeftButtonRef} className="cursor-nesw-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', bottom: '0px', left: '0px'}}></div>
        <div ref={topRightButtonRef} className="cursor-nesw-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', top: '0px', right: '0px'}}></div>
        <div ref={bottomRightButtonRef} className="cursor-nwse-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', bottom: '0px', right: '0px'}}></div>
        <div ref={rightButtonRef} className="cursor-ew-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', top: 'calc(50% - 5px)', right: '0px'}}></div>
        <div ref={bottomButtonRef} className="cursor-ns-resize" style={{'pointer-events': 'auto', width: '10px', height: '10px', background: 'rgba(0,0,0,0.5)', position: 'absolute', bottom: '0px', left: 'calc(50% - 5px)'}}></div>

    </div>, document.body)

});
