import React from 'react';
import { connect } from 'react-redux';
import './Reducers';
import LS from 'BmsView/Layout/LeftSlide';
import TM from './TopMenu';

class DefaultLayout extends React.Component {
    
    render() {
        let { children, activeMenus } = this.props;
        return (
            <div className={`h-full w-full overflow-visible`}>
                {children}
            </div>
        );
    }
}

export default connect((state) => ({
    activeMenus: state.get('activeMenus') || 0
}))(DefaultLayout);


export const LeftSlide = LS;
export const TopMenu = TM;
