import ModuleTypes from 'Configuration/ModuleTypes';

export default function getPointReference(point) {
    let selectedSubsystemType = ModuleTypes[point.get('subsystemtype')];
    let pointtypes = selectedSubsystemType.pointtypes;
    let pointtype = pointtypes[point.get('type')];

    return pointtype.prefix + point.get('number');
}


export function  pointAddrToText(_addr) {
    let addr = _addr.split('-');

    let selectedSubsystemType = ModuleTypes[addr[1]];
    let pointtypes = selectedSubsystemType.pointtypes;
    let pointtype = pointtypes[addr[2]];
    let fields = pointtype.fields;

    if ( selectedSubsystemType == 9 ) return `${fields[0].label} ${addr[3]} ${pointtype.name} ${addr[5]}`

    return `${fields[0].label} ${addr[3]} ${fields[1].label} ${addr[4]} ${pointtype.name} ${addr[5]}`          
}

