import React from 'react';
import TransitioningDialog from 'Controls/Dialog/TransitioningDialog';
import Select from 'Controls/Form/Select';
import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';
import Swal from 'sweetalert2';

const types = [ 'Alarm','Calendar','Schedule','Point Transfer','Program','Bacnet Schedule' ];

class PointGroupEditDialog extends React.Component {
    constructor(props) {
        super(props);

        // console.log('PointGroupEditDialog : Constructor')

        let group = this.props.selecteditem;

        this.state = {
            currentgroup: group,
            isOpened: this.props.isOpened
        }

        this.onSave = this.onSave.bind(this);
    }

    valueChanged(field, transform, value) {
        if (transform) {
            value = transform(value);
        }

        this.setState(({currentgroup}) => ({
            currentgroup: currentgroup.setIn(field, value)
        }));
    }

    onSave() {
        let {currentgroup} = this.state;
        let {onSave} = this.props;

        if (!currentgroup.get('name')) {
            Swal.fire("Invalid Name", "Cannot be blank", "Error");
            return;
        }

        onSave(currentgroup);
    }

    render() {
        let {onClose} = this.props;
        let {currentgroup} = this.state;

        // console.log('PointGroupEditDialog : Render')

        return <TransitioningDialog panelWidthClass="max-w-fit" onClose={onClose} opened={true}>
            <div  className='container'>
                <div className="w-full  m-2">
                    <h2>Point Group Setup</h2>              
                </div>
            </div>
            <div className="mt-4">
                <Input label='Name' value={currentgroup.get('name')} onChange={this.valueChanged.bind(this, ['name'], undefined)}/>
                <Select
                    label="Type"
                    value={currentgroup.get('type')}
                    onChange={this.valueChanged.bind(this, ['type'], undefined)}
                    options={types.map((type) => {
                        return ({
                            text: type,
                            value: type
                        });
                    })}
                />
                <Input label="Description" value={currentgroup.get('description')} onChange={this.valueChanged.bind(this, ['description'], undefined)}/>
            </div>
            <div className="flex p-3 items-center justify-end gap-2">
                <Button role="save" className="" onClick={this.onSave}>Save</Button>
                <Button role="close" className="" onClick={onClose}>Close</Button>
            </div>
        </TransitioningDialog>
    }
}

export default PointGroupEditDialog;
