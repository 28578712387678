import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

export default function AreaSaveButton({ onSave }) { 
    // console.log("AreaSaveButton rendered");
    return <AreaSaveButtonPortal><button 
        className="w-full inline-flex items-center px-6 py-3 border border-transparent text-sm rounded-md text-white bg-[#007bff] hover:bg-[#0062cc]" 
        onClick={e => {
            // console.log("save clicked");
            onSave()
        }}>
            <DiskIcon className="w-5 h-5 text-white" />
    </button>
    </AreaSaveButtonPortal>
}

export function DiskIcon({ className }) {
    
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={2}  fill="none" stroke="currentColor" className={className}>
        <g>
            <path strokeLinecap="round" strokeLinejoin="round" d="M22,22 H2 V3 A1,1,0,0,1,3,2 H7 V8 H17  V2 l5,4Z M7,2H17V8H7Z "/>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12,11a4,4,0,1,0,4,4A4,4,0,0,0,12,11Z"/>
        </g>
    </svg>
}

export function AreaSaveButtonPortal({ children }) {

    const elRef = useRef(document.createElement('div'));

    useEffect(() => {

        const el = elRef.current;
        const container = document.getElementById('area-save-button');
        container.appendChild(el);

        return () => {
            container.removeChild(el);
        }

    }, [])

    return ReactDOM.createPortal(
        children,
        elRef.current
    );
}