import React from 'react';

class Title extends React.Component{
    render(){
        return (
            <li className="dropdownTitle">{this.props.children}</li>
        )
    }
}

export default Title;