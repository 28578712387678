import React, {useState} from 'react';
import Immutable from 'immutable';
import Grid from 'Controls/GridV2';
import AlarmDetailsDialog from './AlarmDetailsDialog';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const CurrentPointAlarmsLog = ({points}) => {
    const [selectedAlarm, setSelectedAlarm] = useState();
        
    let gridFields = [
        { value: 'datetime', name: 'Last Occured', width: 'col-span-16 md:col-span-2' },
        { value: 'point', name: 'Point', width: 'col-span-16 md:col-span-3' },
        { value: 'paneltext', name: 'Message', width: 'col-span-16 md:col-span-10' }
    ];

    const setAlarm = (point) => {
        let alarm = point.get('alarm');

        // console.log(alarm);

        let ackedlist = Immutable.List((alarm.get('ackedby') || []).map((user) => {
            return Immutable.Map(user);
        }));

        alarm = alarm.set('ackedby', ackedlist);
        //alarm = alarm.set('ackedby', Immutable.List(alarm.get('ackedby')));
        setSelectedAlarm(alarm)
    }

    // console.log(selectedAlarm);

    return (
        <React.Fragment>  
            {selectedAlarm && <AlarmDetailsDialog selecteditem={selectedAlarm} onClose={()=>setSelectedAlarm(undefined)} />} 
            <Grid>
                <Grid.Header>
                    {gridFields.map((field) => {
                        return <Grid.Column key={field.name} classes={`${field.width}`}>{field.name}</Grid.Column>
                    })}
                    <Grid.Column key={"buttons"} classes={''} name="button"></Grid.Column>
                </Grid.Header>
                <Grid.Data className="max-h-100 h-100 overflow-x-scroll">
                    {(points || []).map((point, index) => {
                        return (<Grid.Row key={index}>
                            {gridFields.map((field2, _index) => {
                                return <Grid.Column key={_index} className={`${field2.width}`}>{point.get(field2.value)}</Grid.Column>;
                            })}
                            <Grid.Column>
                                <Grid.Column>
                                    <Grid.Controls>
                                        <Grid.Control>
                                            <MagnifyingGlassIcon  className="cursor-pointer text-gray-500 group-hover:text-gray-700 group-hover:hover:text-blue-500 w-6" onClick={()=>setAlarm(point)} />
                                        </Grid.Control>
                                    </Grid.Controls>
                                </Grid.Column>
                            </Grid.Column>
                        </Grid.Row>)
                    })}
                </Grid.Data>
            </Grid>
        </React.Fragment>
    );
}

export default CurrentPointAlarmsLog;
