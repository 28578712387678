import * as d3 from 'd3';
import {fromJS} from 'immutable';
import { displayTooltip, setDefs, drawPath, drawCircle, } from '../Utils';


export default function Pipe(options) {
    return new Promise((resolve, reject) => {
        let rotation = options.node.getIn(['configuration','rotation'], 0);

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`)
        
        let createPolygonShape = (placementElement, options) => {
            //let angle = 4.3 * (Math.PI / 180);
        /*    let angle = 4.3 * (Math.PI / 180);
            var rads = Math.abs(Math.sin(angle));
            var hyp =  options.node.getIn(['configuration', 'length'], 200);
            
            var x_length = rads * hyp;
    
            rads = Math.abs(Math.cos(angle));
            var y_length = rads * hyp;  */

            let w = `${options.node.getIn(['configuration', 'width'], 10)}`;
            let l = `${options.node.getIn(['configuration', 'length'], 5)}`;

            let path = `m 0,0 -${l},0 c -${w/2} ,${w/4}  -${w/2} ,${w/4 * 3}  -0,${w} l ${l},0 z`;

            let idPath = options.node.get('id');

            let grads = fromJS([
                { "stop": "0%", "colour":"#00aad4" },
                { "stop": "100%", "colour":"#004050"}]);

            options.node.getIn(['configuration', 'gradient'], true ) && setDefs(placementElement, idPath,
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads'], grads));
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey', 150);

            let r = `${options.node.getIn(['configuration', 'radius'], 5)}`;
            drawCircle(placementElement, -4, -4, r, 0,'#000000', undefined, idPath, '#4a4a4a');

            path = `m -29,-1 -${l},0 c -${w/2} ,${w/4}  -${w/2} ,${w/4 * 3}  -0,${w} l ${l},0 z`;
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey', 5);


            drawCircle(placementElement, -29, 1, r, 0,'#000000', undefined, idPath, '#4a4a4a');

            w = 8;
            l = 15;

            path = `m -15,0 -${l},0 c -${w/2} ,${w/4}  -${w/2} ,${w/4 * 3}  -0,${w} l ${l},0 z`;
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey', 5);

        //    path = `m -14,7 -${l},0 c -${w/2} ,${w/4}  -${w/2} ,${w/4 * 3}  -0,${w} l ${l},0 z`;
        //    drawPath(placementElement, -23, 20, path, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey',150);

            
            path = `m 16,1 -${l},0 c -${w/2} ,${w/4}  -${w/2} ,${w/4 * 3}  -0,${w} l ${l},0 z`;
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey',150);
     
        } 


        let createPipe = (placementElement, options) => {

            let idPath = options.node.get('id');

            let grads = fromJS([
                { "stop": "0%", "colour": "#6B6B6B" },
                { "stop": "50%", "colour": "#EBEBEB" },
                { "stop": "100%", "colour": "#6B6B6B" }]);

            options.node.getIn(['configuration', 'gradient'], false ) && setDefs(placementElement, idPath, 
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads'], grads));


            let path = `m 0,0 c 0,0.002 -1.002,0.043 -1.49,0.32 -1.959,1.129 -2.844,3.85 -2.133,6.069 0.432,1.357 1.273,2.222 2.416,2.421 0.063,0.009 4.063,0.418 4.063,0.418 l 1.453,-8.969 z`;



            options.node.getIn(['configuration', 'gradient'], true ) && setDefs(placementElement, idPath,
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads'], grads));
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey', 0);

        /*    placementElement.append('path')
                .attr('d', `${poly}`)                
                .style("fill","url(#mainGradient)")
                .attr('x', 0)
                .attr('y', 0);
*/
            let z = 40;

            let p3 = `m 7,2.70 c -0.711,-2.226 -2.857,-3.13 -4.799,-2.011 -1.939,1.115 -2.934,3.83 -2.223,6.061 0.713,2.228 2.859,3.132 4.799,2.013 1.94,-1.119 2.934,-3.831 2.223,-6.063 z`;
            drawPath(placementElement, p3, undefined, idPath, '#4d4d4dff', 0);


            let p4 = `m 14,1.6 c -11.039,-0.867 -10.125,-0.796 -10.018,-0.788 -0.031,0.002 -0.051,0.01 -0.078,0.013 -0.533,-0.028 -1.092,0.086 -1.635,0.399 -1.701,0.978 -2.572,3.359 -1.949,5.315 0.404,1.266 1.338,2.032 2.408,2.157 0.061,0.013 0.113,0.042 0.176,0.052 l 10.709,0.842 z`;
            drawPath(placementElement, p4, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey', 0);  


            let p5 = `m 16, 8.5 c 0.373,-0.308 0.695,-0.681 0.932,-1.141 0.971,-1.886 0.252,-4.276 -1.605,-5.342 -1.131,-0.654 -2.428,-0.658 -3.486,-0.132 l -9.543,5.565 3.949,6.737 c -10e-4,0.001 9.753,-5.683 9.753,-5.687 z`;
            drawPath(placementElement, p5, options.node.getIn(['configuration', 'gradientHeader'], grads), idPath, 'lightgrey', 0);  


        } 
        
   
        let placementElement = createPlacementElement(options);

        let defs = (placementElement, options) => {
            var svgDefs = placementElement.append('defs');

            var mainGradient = svgDefs.append('linearGradient')
            .attr('id', 'mainGradient')
            .attr('x1', 0)
            .attr('y2', 0)
            .attr('x2', 0)
            .attr('y1', 1)

            // Create the stops of the main gradient. Each stop will be assigned
            // a class to style the stop using CSS.
            mainGradient.append('stop')
                .attr('style', "stop-color: #6B6B6B")
                .attr('offset', '0%');

            mainGradient.append('stop')
                .attr('style', "stop-color: #EBEBEB")
                .attr('offset', '50%');

            mainGradient.append('stop')
                .attr('style', "stop-color: #6B6B6B")
                .attr('offset', '100%');
            
            return svgDefs;        
        }        
                
        let shapetype = options.node.getIn(['configuration', 'shapetype']);    

        var shape;
        defs(placementElement, options);
        shape =  createPolygonShape(placementElement, options);
    //    shape =  createPipe(placementElement, options);
        

        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;       
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                d3.select(placementElement).remove();
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                options.node = node;
                placementElement.remove();
                placementElement = createPlacementElement(options);
            //    shapetype = options.node.getIn(['configuration', 'shapetype']); 
                defs(placementElement, options);   
                shape =  createPolygonShape(placementElement, options);

                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}