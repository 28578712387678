import React from 'react';
import { useCustomerSettings } from 'Context/CustomerSettings';
import { useUserSettings } from 'Context/UserSettings';
import { Map } from 'immutable';

const preDefinedRoles = {
    'ok': {
        defaultBackgroundClassName: 'bg-indigo-600',
        backgroundSettingKey: 'defaultOkButtonBackground',
        textSettingKey: 'defaultOkButtonText'
    },
    'cancel': {
        defaultBackgroundClassName: 'bg-red-600',
        backgroundSettingKey: 'defaultCancelButtonBackground',
        textSettingKey: 'defaultCancelButtonText'
    },
    'save': {
        defaultBackgroundClassName: 'bg-green-600',
        backgroundSettingKey: 'defaultSaveButtonBackground',
        textSettingKey: 'defaultSaveButtonText'
    },
    'close': {
        defaultBackgroundClassName: 'bg-gray-600',
        backgroundSettingKey: 'defaultCloseButtonBackground',
        textSettingKey: 'defaultCloseButtonText'
    },
    "primary": {
        defaultBackgroundClassName: 'bg-[#007bff]',
        backgroundSettingKey: 'defaultOkButtonBackground',
        textSettingKey: 'defaultOkButtonText'
    },
    "success": {
        defaultBackgroundClassName: 'bg-[#28a745]',
        backgroundSettingKey: 'defaultSaveButtonBackground',
        textSettingKey: 'defaultSaveButtonText'
    },
    "danger": {
        defaultBackgroundClassName: 'bg-[#dc3545]',
        backgroundSettingKey: 'defaultCancelButtonBackground',
        textSettingKey: 'defaultCancelButtonText'
    },
    "secondary": {
        defaultBackgroundClassName: 'bg-[#6c757d]',
        backgroundSettingKey: 'defaultCloseButtonBackground',
        textSettingKey: 'defaultCloseButtonText'
    },
}


function UserDefinedButton(props) {

    const { customerSettings } = useCustomerSettings();
    const { userSettings } =  useUserSettings();
    let {className, onClick, children, role, ...others} = props;

    let style = {};

    let defaultBackgroundClassName = ``;
    let defaultClassName = `relative inline-flex items-center border border-transparent shadow-sm text-sm font-medium rounded-md `
    let roleSettings = preDefinedRoles[role];
    if(roleSettings) {
        
        defaultBackgroundClassName = roleSettings.defaultBackgroundClassName;

        if(customerSettings && customerSettings.get(roleSettings.backgroundSettingKey)) {
            style.backgroundColor = customerSettings.get(roleSettings.backgroundSettingKey);
        }

        if(customerSettings && customerSettings.get(roleSettings.textSettingKey)) {
            style.color = customerSettings.get(roleSettings.textSettingKey);
        }

        if(userSettings && userSettings.get(roleSettings.backgroundSettingKey)) {
            style.backgroundColor = userSettings.get(roleSettings.backgroundSettingKey);
        }

        if(userSettings && userSettings.get(roleSettings.textSettingKey)) {
            style.color = userSettings.get(roleSettings.textSettingKey);
        }

        if(!style.color) {
            defaultClassName += ` text-white`; 
        }

    }

    return (
        <button className={`${defaultClassName} ${defaultBackgroundClassName} ${className}`} style={style} onClick={onClick} {...others}>
            <div className="inset-0  w-full h-full px-4 py-2  hover:bg-[rgba(0,0,0,0.2)]">
                {children}
            </div>
        </button>
    )

}
export default UserDefinedButton;