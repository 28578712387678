import React, {useState, useEffect} from 'react';
import ServiceBus from 'Services/ServiceBus';
import Button from 'Controls/Button/UserDefined';
import Input from 'Controls/Form/Input';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function PointPrioritiesDialog({selectedItem, type, updatePriorities, onClose, onSetValue, ...props}) {
    return <Dialog opened={!!selectedItem} onClose={onClose}>
        <PointPrioritiesDialogPanel selecteditem={selectedItem} type={type} updatepriorities={updatePriorities} onClose={onClose}  setValue={onSetValue} {...props} />
    </Dialog>
}

function PointPrioritiesDialogPanel({selecteditem, type, updatepriorities, onClose, setValue, customer}) {
    const [priorities, setPriorities] = useState([]);
    const [values, setValues] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);

    useEffect( ()=> {
        let unsubscribeGetPointPropertiesResp = ServiceBus.on('21-151', processGetPointPropertiesResp);
        getPointProperties();

        return() => {
            unsubscribeGetPointPropertiesResp();
        } 
    },[]);

    useEffect( ()=> {
        getPointProperties();
    },[updatepriorities]);

    const getPointProperties = () => {
        let address = selecteditem.getIn(['configuration', 'boundto']);

        let request = {
            trackMessage: true,
            m_level: 150,
            m_communicNum: 21,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: address.split('-')[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: address.split('-').map((bit) => { return parseInt(bit); }),
            m_informationType: 1
        }
    
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const processGetPointPropertiesResp = (msg) => {
        // console.log(msg);
        setPriorities(msg.m_text);
        setValues(msg.m_priorityValues);
    }

    const onChangeValue = (index, value) => {
        let newvalues = values;

        newvalues[index] = value;
        setValues(newvalues);
    }

    return(
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Priority Information</DialogTitle>
            </DialogHeader>
            <DialogBody>

                <div className="grid grid-cols-6 gap-2 -mt-2">
                    {(priorities || []).map((p, index) => {
                        return (
                            <React.Fragment>
                                <div className="col-span-8 md:col-span-2">
                                    {index < 16 ? p.split(" ")[0] : p}
                                </div>
                                <div className="col-span-8 md:col-span-1">
                                    {index < 16 && <Input className="w-full mb-0 px-3 rounded border border-[#ced4da]" containerClassName="mb-0" value={values[index]} onBlur={(value) => onChangeValue(index,value)} />}
                                </div>
                                <div className="col-span-8 md:col-span-2">
                                    {index < 16 && type == "Analog" && 
                                        <Button className="pl-1" role="success" onClick={() => setValue(2, values[index], index + 1)}>Set</Button>}
                                    {index < 16 && type == "Digital" && 
                                        <Button className="pl-1" role="success" onClick={() => setValue(1, 0, index + 1)}>false</Button>}
                                    {index < 16 && type == "Digital" && 
                                        <Button className="ml-2" role="success" onClick={() => setValue(1, 1, index + 1)}>true</Button>}
                                     {index < 16 && 
                                    <Button className="pl-1 ml-2" role="close" onClick={() => setValue(3, values[index], index + 1)}>Release</Button>}
                                </div>
                            </React.Fragment> )
                    })}
                </div>

            </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={onClose}>Close</Button></div>
            </DialogFooter>
        </DialogContent>          
    )
}
export default PointPrioritiesDialog;