import * as d3 from 'd3';
import {displayTooltip, displayDigitalValue } from '../Utils';

export default function TextDigital(options, path) {
    return new Promise((resolve, reject) => {
        let rotation = options.node.getIn(['configuration','rotation'], 0);

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`);

        let drawText = (placementElement, options, value) => {
            let idPath = 1000 - options.node.get('id');

            displayDigitalValue(placementElement, parseInt(options.node.getIn(['configuration', 'height'], '140')), options, value,
                options.node.getIn(['configuration', 'backgroundEnabled'], false),
                options.node.getIn(['configuration', 'style', 'background-color'], 'lightgrey'),
                options.node.getIn(['configuration', 'gradient']) ? options.node.getIn(['configuration', 'grads'], undefined) : undefined,
                options.node.getIn(['configuration', 'digits']),
                options.node.getIn(['configuration', 'style', 'color'], 'black'),
                options.node.getIn(['configuration', 'gradientText']) ? options.node.getIn(['configuration', 'gradsText'], undefined) : undefined
            );           
        }

        let placementElement = createPlacementElement(options);
        drawText(placementElement, options, 0);
        
        let tooltip = displayTooltip(placementElement, options); 

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                placementElement.remove();
                options.node = options.node.setIn(['pointconfiguration'], point);
                let value = parseFloat(point.m_formattedValueText);
                oldvalue = value;
                placementElement = createPlacementElement(options);
                drawText(placementElement, options, value);
   
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                placementElement.remove();
                options.node = node;

                placementElement = createPlacementElement(options);
                drawText(placementElement, options);

                if (!tooltip) tooltip = displayTooltip(placementElement, options);
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            remove: () => {
                placementElement.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        })
    })
}