import React, { Fragment, useEffect, useState } from 'react';
import TransitioningDialog from "../../../../../../Controls/Dialog/TransitioningDialog";
import { Map, fromJS, List } from 'immutable';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';

import { ModifyPeriodDialog } from '../WeekTimesDialog';
import { SketchPicker } from 'react-color';
import { Popover } from '@headlessui/react'

import DayTimesVertical from './DayTimesVertical';
import { removeCollidingElements, timeIndexToString, stringTimeToIndex } from './Utils';

const redButtonClassName = "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm";
const greenButtonClassName = "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm";


export default function DayTimesDialogVertical({ onClose, isOpened, onSave, template = Map() }) {

    return <TransitioningDialog panelExtraClass='overflow-visible' panelWidthClass=" max-w-min " onClose={onClose} opened={isOpened}>
        <DayTimesDialogVerticalPanel onClose={onClose} isOpened={isOpened} onSave={onSave} template={template} />
    </TransitioningDialog>
}

export  function DayTimesDialogVerticalPanel({ onClose, isOpened, onSave, template = Map() }) {

    const [name, setName] = useState((template || Map()).get('name'));
    const [defaultColor, setDefaultColor] = useState((template || Map()).get('defaultcolor') || '#0088cc');

    const [events, setEvents] = useState((template || Map()).get('periods', List()).toJS());

    const [newEvent, setNewEvent] = useState({});
   
    const [selectedEvent, setSelectedEvent] = useState(events[0]);
    const [eventToModify, setEventToModify] = useState(null);

    useEffect(() => {
        setEvents((template || Map()).get('periods', List()).toJS());
        setName((template || Map()).get('name'));
        setDefaultColor((template || Map()).get('defaultcolor') || '#0088cc');
    }, [template])

    const _onSave = () => {
        if(name) {
            let periods = fromJS(events);
            periods = periods.filter(x => !x.get('isDeleted'))
            return onSave(
                template.set('name', name)
                .set('periods', periods)
                .set('defaultcolor', defaultColor)
            )
        }
        Swal.fire('Not complete', 'You need to specify a name for this template', 'error');
    }


    const onChange = (event, changedEvent) => {
        let newEvent = {...event, ...changedEvent };
        newEvent.starttime = timeIndexToString(newEvent.startindex);
        newEvent.endtime = timeIndexToString(newEvent.endindex);

        onSelect(newEvent.isDeleted ? null : newEvent);
        setEvents((events) => {
            let index = events.indexOf(event);
            let newEvents = [];
            events.forEach((oldEvent, i) => {
                if(i == index) {

                    newEvents.push(newEvent)
                } else {
                    newEvents.push(oldEvent);
                }
              
            })
            newEvents = removeCollidingElements(newEvent, newEvents);

            // console.log("setting events to", newEvents);
            return newEvents;
        })
    }

    const onCreate = (eventToCreate) => {
        eventToCreate.starttime = timeIndexToString(eventToCreate.startindex);
        eventToCreate.endtime = timeIndexToString(eventToCreate.endindex);
        setEvents((events) => {
            let newEvents = [...events];
            newEvents.push(eventToCreate);   
            newEvents = removeCollidingElements(eventToCreate, newEvents);         
            return newEvents;
        });
        onSelect(eventToCreate);
    }

    const onSelect = (event) => {
        setNewEvent({});
        setSelectedEvent(event);
    }

    const onModifyRequested = (event) => {
        setEventToModify(event);
    }

    /*const useValues = [
        { text: "Next", value: 0 },
        { text: "Every", value: 1 },
        { text: "Free", value: 2 },
    ]

    const priorityValues = [1,2,3,4,5,6].map(p => ({ text:p, value:p }));*/

    return <Fragment>
        <ModifyPeriodDialog 
            period={eventToModify ? fromJS(eventToModify) : null} 
            onSave={(period, secondPeriod) => {
                let newStartIndex = stringTimeToIndex(period.get('starttime'));
                let newEndIndex = stringTimeToIndex(period.get('endtime'));
                if(newEndIndex <= newStartIndex) {
                    newEndIndex = 30 * 48;
                }
                onChange(eventToModify, { startindex: newStartIndex, endindex: newEndIndex })
            }} 
            onClose={() => setEventToModify(null)} isOpened={(!!eventToModify)} />
        <div className="">
            <div className="">
                <h3 className="text-lg font-medium text-gray-900">Day Times Template Setup</h3>
                <div className="py-2">
                    <div className="flex items-center">
                        <DayTimesInput type="text" label="Name" value={name} onChange={(v) => setName(v)} />
                    </div>
                </div>
            </div>
        </div>

        <div className="flex items-center">
            <label className="block px-2">Default event color</label>
            <ColorPickerField 
                value={defaultColor} 
                onChange={(v)=> {setDefaultColor(v)}} />
        </div>

        <div className="pt-8">
            <DayTimesVertical onModifyRequested={onModifyRequested} onCreate={onCreate} events={events.filter(x => !x.isDeleted)} onChange={onChange} selectedEvent={selectedEvent} onSelect={setSelectedEvent}/>
        </div>
        <div className="flex justify-end pt-8">
            <div className="flex-none px-2">
                {/* <button className={greenButtonClassName} onClick={_onSave}>Save</button> */}
                <Button role="save" onClick={_onSave}>Save</Button>
            </div>
            <div className="flex-none">
                {/* <button className={redButtonClassName} onClick={() => onClose()}>Close</button> */}
                <Button role="close" onClick={() => onClose()}>Close</Button>
            </div>
        </div>

    </Fragment>

}

export function DayTimesInput({ type="number", label, value, onChange, className, inputClassName }) {
    return <div className={`flex py-1 items-center ${className || ''}`}>
        <div className="flex-none p-1 px-2">
            { label }
        </div>
        <div className="flex-1">
            <input type={type} className={`p-1 border ${inputClassName || 'w-full'}`} value={value} onChange={(e) => onChange(e.target.value)}/>
        </div>
    </div>
}

export function DayTimesColorInput({ label, value, onChange, className, inputClassName }) {
    return <div className={`flex py-1 items-center ${className || ''}`}>
        <div className="flex-none p-1 px-2">
            { label }
        </div>
        <div className="flex-1">
            <input type="color" className={`p-1 border ${inputClassName || 'w-full'}`} value={value} onChange={(e) => onChange(e.target.value)}/>
        </div>
    </div>
}

export function ColorPickerField({ onChange, value }) {
    const onPickerChange = (color) => {
        onChange(color.hex);
    }
    let colorStyle = { backgroundColor: value }
    return <div className="border flex border-gray-200 ">
        <input className="py-1 px-2 w-24" onChange={(e) => onChange(e.target.value)} type="text" value={value || ""}/>
        <div style={colorStyle} className="border-l border-gray-200  w-10 text-center">
            <PickerPopover><SketchPicker color={value || "#FFF"} onChange={onPickerChange}  /></PickerPopover>
        </div>
    </div>
}

export function PickerPopover({ children }) {
    return (
      <Popover className="relative w-full h-full ">
        <Popover.Button className={`w-full h-full`}>
            <div className="cursor-pointer h-full p-2 "></div>
        </Popover.Button>
        <Popover.Panel className="absolute top-0 left-full z-20">
            {children}
        </Popover.Panel>
      </Popover>
    )
}



