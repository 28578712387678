import React from 'react';
import Immutable, { List } from 'immutable';

import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';

import { getDeviceTypes } from './Actions';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function IpAddressDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <IpAddressDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class IpAddressDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        let { customer, selecteditem } = this.props;
        
        if ( !selecteditem.get('site') && customer.get('sites') ) {
            selecteditem = selecteditem.set('site',customer.get('sites').get(0).get('name'));
        }

        this.state = {
            currentaddress: selecteditem,
            displaylist: false
        //    devicetypes: [] //Immutable.Map({})
        }

        this.onSave = this.onSave.bind(this);
    }


    async componentDidMount() {
        let devicetypes = await getDeviceTypes();

        this.setState({
            devicetypes: devicetypes //Immutable.fromJS(devicetypes)
        });
    }

    onSave() {
        let { currentaddress } = this.state;
        let { onSave } = this.props;

        if (onSave) {
            onSave(currentaddress)
        }
    }

    valueChanged(field, value) {
        this.setState(({ currentaddress }) => ({
            currentaddress: currentaddress.setIn(field, value)
        }));

        if ( field[0] == 'devicetype') {
            this.setState({
                displaylist:false})
        }
    }

    setDisplayList() {
        let display = this.state.displaylist;
        display = !display;
        this.setState({
            displaylist:display})
    }

    render() {
        let { customer, onClose } = this.props;
        let { currentaddress } = this.state || {};
        let { devicetypes, displaylist } = this.state;

        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Edit IP Address</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="flex flex-row mt-4 gap-2">
                        <div className="basis-1/2">
                            <Input label="IP Address" value={currentaddress.get('ipaddress')} onChange={this.valueChanged.bind(this, ['ipaddress'])} />  
                        </div>
                        <div className="basis-1/2">
                            <Select
                                label="Site"
                                value={currentaddress.get('site')}
                                onChange={this.valueChanged.bind(this, ['site'])}
                                options={(customer.get('sites') || List()).map((site) => {
                                    return ({
                                        text: site.get('name'),
                                        value: site.get('name')
                                    });
                                })}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row mt-4 gap-2">
                        <div className="basis-1/2">
                            <Input label="Subnet" value={currentaddress.get('subnet')} onChange={this.valueChanged.bind(this, ['subnet'])} />  
                        </div>
                        <div className="basis-1/2">
                            <Input label="Default Gateway" value={currentaddress.get('gateway')} onChange={this.valueChanged.bind(this, ['gateway'])} />
                        </div>
                    </div>
                    <div className="flex flex-row mt-4">
                        <div className="basis-1/2">
                            <Input label="Location" value={currentaddress.get('location')} onChange={this.valueChanged.bind(this, ['location'])} />
                        </div>
                    </div>
                    <div className="flex flex-row mt-4 gap-2">
                        <div className="basis-3/8">
                            <Input label={'Device Type'} value={currentaddress.get('devicetype')} onChange={this.valueChanged.bind(this, ['devicetype'])} />
                        </div>
                        <div className="basis-1/8 mt-8"> 
                            <Button role="secondary" onClick={this.setDisplayList.bind(this)}>..</Button>
                        </div>
                        {displaylist &&
                        <div className="basis-3/8">
                            <Select
                                label="Device Type"
                                value={currentaddress.get('devicetype')}
                                onChange={this.valueChanged.bind(this, ['devicetype'])}
                                options={(devicetypes || []).map((devicetype) => {
                                    return ({
                                        text: devicetype.type,
                                        value: devicetype.type
                                    });
                                })}
                            />
                        </div>}
                    </div> 
                    <div className="flex flex-row mt-4">
                        <div className="grow">
                            <Input label="Notes" value={currentaddress.get('notes')} onChange={this.valueChanged.bind(this, ['notes'])} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button role="close" onClick={onClose}>Close</Button>
                    <Button role="success" onClick={this.onSave}>Save</Button>
                </DialogFooter>

            </DialogContent>
        );
    }
}

export default IpAddressDialog;