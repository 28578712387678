import * as d3 from 'd3';
import debuggerService from '../../../../../../Services/DebuggerService';

const logger = debuggerService.getDebugger("ComponentCache", "red");

class ComponentCache {

    constructor() {
        this.viewCache = {};
        this.cachedObjects = [];
        this.pendingPaths = {};
    }

    getXml(path) {
        return new Promise((resolve, reject) => {
            if(this.viewCache[path]) {
                logger.log("09-06 ComponentCache: cache hit performed")
                return resolve(this.viewCache[path].cloneNode(true));
            }
            if(this.pendingPaths[path]) {
                logger.log("09-06 ComponentCache: pending path")
                return this.pendingPaths[path].then((xml) => {
                    this.pendingPaths[path] = null;
                    return resolve(this.viewCache[path].cloneNode(true));
                }).catch(reject);
            }
            logger.log("09-06 ComponentCache: cache miss")
            let promise = d3.xml(path).then((xml) => {
                this.cache(path, xml);
                logger.log("09-06 ComponentCache: cache miss performed")
                resolve(xml.cloneNode(true));
                return xml;
            }).catch(reject);   
            this.pendingPaths[path] = promise;
        })

    }

    cache(path, xml) {
        // console.log("18-03 ComponentCache: cache save performed");
        this.viewCache[path] = xml;
        this.cachedObjects.push(path);
        if(this.cachedObjects.length > 150) {
            let oldPath = this.cachedObjects.shift();
            delete this.viewCache[oldPath];
        }
      
    }


}

export default new ComponentCache();