import React, { useState, useEffect, useRef } from 'react';
import Immutable, { Map, List, OrderedMap } from 'immutable';
import ContextMenu from 'Controls/ContextMenu';
import AreaPointListDialog from './AreaPointListDialog';
import { withClipboard } from '../../../../../Context/Clipboard';
import { useArea } from '../../../../../Context/Area';
import SVGPanel from './SVGPanel';
import PointDataList from './PointDataList';
import { localReferences } from '../indexV2';
import CreateCompoundDialog from './CreateCompoundDialog';
import Toolbox from './Toolbox';
import { useWindow } from 'Context/Window';
import useAreaCopy from '../Hooks/useAreaCopy';



export default withClipboard(Graphic);

function extractRegionToImage(sourceCanvas, x, y, width, height) {
    let destinationCanvas = document.createElement('canvas');
    destinationCanvas.width = width;
    destinationCanvas.height = height;
    let ctx = destinationCanvas.getContext('2d');
    ctx.drawImage(sourceCanvas, x, y, width, height, 0, 0, width, height);
    return destinationCanvas.toDataURL("image/png");
}

function createThumbnail(element, x, y, width, height, fullWidth, fullHeight) {
    return new Promise((resolve, reject) => {
        let xml = new XMLSerializer().serializeToString(element);
        let svgDataUrl = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(xml)));
        let img = new Image();
        img.onload = function() {
            let canvas = document.createElement('canvas');
            canvas.width = fullWidth;
            canvas.height = fullHeight;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            let thumbnailImageUrl = extractRegionToImage(canvas, x,y,width,height);
            resolve(thumbnailImageUrl);
        };
        img.src = svgDataUrl;
    })
    
    
}

// let renderList = window.innerWidth < 1000;
function Graphic({ backdrop, configureNode, editmode, ...props}) {

    let { windowClass } = useWindow();
    let renderList = windowClass == 'sm';

    const panelRef = useRef();
    const helpers = useRef();
    const [mouseMovement, setMouseMovement] = useState({ x: 0, y: 0 });
    const [selection, setSelection] = useState({});
    const [localState, setLocalState] = useState({});
    const { nodes } = useArea();
    const { layers } = useArea();
    const [displayData, setDisplayData] = useState(OrderedMap());
    const [compoundToCreate, setCompoundToCreate] = useState(undefined);

    const { copyCurrentArea } = useAreaCopy();

    const onNodeMount = (nodeId, displayData) => {
        setDisplayData((dd) => dd.set(nodeId, Map(displayData)));
    }

    let { selectednode, selectedindex } = selection;

    helpers.current = {
        getLocalState: () => localState,
        getLayers: () => layers,
        getProps: () => props,
        getSelection: () => selection,
        getEditmode: () => editmode,
        getNodes: () => nodes,
        getDisplayData: () => displayData,
    }
        

    const createCompoundOutOfSelection = async (evt) => {
        let localState = helpers.current.getLocalState();
        let { selectedIds } = localState;
        let displayData = helpers.current.getDisplayData();
        let mostTop = 999999;
        let mostBottom = 0;
        let mostLeft = 999999;
        let mostRight = 0;
        let svgBounds = panelRef.current.getBoundingClientRect();
        window.Immutable = Immutable;
        selectedIds.forEach((id) => {
            let bound = displayData.getIn([id, 'bound']);
            let elementWidth = bound?.width || displayData.getIn([id, 'bound', 'width']);
            let elementHeight = bound?.height || displayData.getIn([id, 'bound','height']);
            let positionX = (bound?.x || displayData.getIn([id, 'bound','x'])) - svgBounds.x;
            let positionY = (bound?.y || displayData.getIn([id, 'bound', 'y'])) - svgBounds.y;
            let boxX = positionX;
            let boxY = positionY;
            if(boxX < mostLeft) {
                mostLeft = boxX;
            }
            if((boxX + elementWidth) > mostRight) {
                mostRight = (boxX + elementWidth);
            }
            if(boxY < mostTop) {
                mostTop = boxY;
            }
            if((boxY + elementHeight) > mostBottom) {
                mostBottom = (boxY + elementHeight);
            }
        });

        let compoundNodes = Immutable.OrderedMap();
        selectedIds.forEach((id) => {
            let node = undefined;
            let layerIndex = undefined;
            layers.forEach((layer, _layerIndex) => {
                if(!node) {
                    layerIndex = _layerIndex;
                }
                node = node || layer.get('nodes').filter(x => x.get('id') == id).first();
                compoundNodes = compoundNodes.set(node.get('id'), node);     
            })
        });

        compoundNodes = compoundNodes.map((node) => {
            node = node.updateIn(['position','x'], x => x - mostLeft);
            node = node.updateIn(['position','y'], y => y - mostTop);
            return node;
        });

        let dataUrl = await createThumbnail(panelRef.current, mostLeft, mostTop, mostRight - mostLeft, mostBottom - mostTop, svgBounds.width, svgBounds.height);

        setCompoundToCreate({
            nodes: compoundNodes,
            thumbnailImageUrl: dataUrl
        })
        contextMenuClosed()

    }

    const alignSelected = (direction) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let displayData = helpers.current.getDisplayData();

        let { multiNodeChanged, layersChanged } = props;
        let { selectionmode, selectedIds } = localState;
        if(selectionmode) {
            let saveLayers = undefined;
            let mostLeft = 999999;
            let mostRight = 0;
            selectedIds.forEach((id) => {
                let elementWidth = displayData.getIn([id, 'width']);
                let positionX = displayData.getIn([id, 'positionX']);
                let positionY = displayData.getIn([id, 'positionY']);
                let boxX = positionX;
                let boxY = positionY;
                if(boxX < mostLeft) {
                    mostLeft = boxX;
                }
                if((boxX + elementWidth) > mostRight) {
                    mostRight = (boxX + elementWidth);
                }
            });
            selectedIds.forEach((id) => {
                let elementWidth = displayData.getIn([id, 'width']);
                let positionX = displayData.getIn([id, 'positionX']);
                let positionY = displayData.getIn([id, 'positionY']);
                let boxX = positionX;
                let boxY = positionY;
                if(direction == "right") {   
                    boxX = mostRight - elementWidth;
                }
                if(direction == "left") {
                    boxX = mostLeft;
                }
                if (multiNodeChanged) {
                    let node = undefined;
                    let layerIndex = undefined;
                    layers.forEach((layer, _layerIndex) => {
                        if(!node) {
                            layerIndex = _layerIndex;
                        }
                        node = node || layer.get('nodes').filter(x => x.get('id') == id).first();
                    })
                    saveLayers = multiNodeChanged(layerIndex, node.setIn(['position', 'x'], boxX).setIn(['position', 'y'], boxY), saveLayers);
                }
            });
            if(saveLayers && layersChanged) {
                layersChanged(saveLayers);
            }
        }
    }

    const onMultiDragFinished = (dx,dy) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let { multiNodeChanged, layersChanged } = props;
        let { selectedIds } = localState;
        let saveLayers = undefined;
        selectedIds.forEach((id) => {
            if (multiNodeChanged) {
                let node = undefined;
                let layerIndex = undefined;
                layers.forEach((layer, _layerIndex) => {
                    if(!node) {
                        layerIndex = _layerIndex;
                    }
                    node = node || layer.get('nodes').filter(x => x.get('id') == id).first();
                })
                saveLayers = multiNodeChanged(layerIndex, node.updateIn(['position', 'x'], 0, (x) => x + dx).updateIn(['position', 'y'], 0, y => y + dy), saveLayers);
            }
        })
        if(saveLayers && layersChanged) {
            layersChanged(saveLayers);
        }
        setMouseMovement({x: 0, y: 0})
    }

    const handleKeyPress = (e) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let { nodeChanged, multiNodeChanged, layersChanged } = props;
        let { selectionmode, selectedIds, singleSelection } = localState;
        let event = window.event ? window.event : e;
        let adjustX = 0;
        let adjustY = 0;
        if(event.keyCode == 37) {  // left = 37
            adjustX = -1;
        } 
        if(event.keyCode == 38) { // up = 38
            adjustY = -1;
        }
        if(event.keyCode == 39) { // right = 39
            adjustX = 1;
        }
        if(event.keyCode == 40) { // down = 40
            adjustY = 1;
        }
        if(selectionmode && !(adjustX == 0 && adjustY == 0)) {
            let saveLayers = undefined;
            selectedIds.forEach((id) => {
                if (multiNodeChanged) {
                    let node = undefined;
                    let layerIndex = undefined;
                    layers.forEach((layer, _layerIndex) => {
                        if(!node) {
                            layerIndex = _layerIndex;
                        }
                        node = node || layer.get('nodes').filter(x => x.get('id') == id).first();
                    })
                    saveLayers = multiNodeChanged(layerIndex, node.updateIn(['position', 'x'], 0, (x) => x + adjustX).updateIn(['position', 'y'], 0, y => y + adjustY), saveLayers);
                }
            })
            if(saveLayers && layersChanged) {
                layersChanged(saveLayers);
            }
        } else if(singleSelection && !(adjustX == 0 && adjustY == 0)) {
            if (nodeChanged) {   
                let node = undefined;
                let layerIndex = undefined;
                layers.forEach((layer, _layerIndex) => {
                    if(!node) {
                        layerIndex = _layerIndex;
                    }
                    node = node || layer.get('nodes').filter(x => x.get('id') == singleSelection).first();
                })
                let changedNode = node.updateIn(['position', 'x'], 0, x => x + adjustX).updateIn(['position', 'y'], 0, y => y + adjustY);
                nodeChanged(layerIndex, changedNode);
            }
        }
    }

    const toggleAreaPointListDialog = () => {
        setLocalState((localState) => ({ ...localState, areaPointListDialogOpened: !localState.areaPointListDialogOpened }))
    }

    const onCompleteAreaCopy = (e) => {
        copyCurrentArea();
        e && e.preventDefault();
    }

    const onCompleteAreaPaste = (e) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let editmode = helpers.current.getEditmode();
        let { placeNodes, clipboard } = props;
        let { contextMenu } = localState;   
        if(editmode ) {
            let parsed = clipboard; //JSON.parse(data);
            if(parsed && parsed.type == 'complete-area-nodes') {
                let pastedNodes = Immutable.fromJS(parsed.data);
                // console.log("nodes to paste", pastedNodes);
                placeNodes(pastedNodes);
            }
        }
    }

    //
    const handleCopyEvent = (event) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let editmode = helpers.current.getEditmode();
        let { setClipboard } = props;
        let { selectedIds, selectionmode } = localState;
        if(selectionmode && editmode) {
            let copies = List();
            layers.forEach((layer) => {
                let nodes = layer.get('nodes');
                nodes.forEach((node) => {
                    if(selectedIds.indexOf(node.get('id')) > -1) {
                        copies = copies.push(node);
                    }
                })
            })
            let jsCopies = copies.toJS();
            setClipboard({ type: 'area-nodes', data: jsCopies })
            event && event.preventDefault();
        }
      
    }

    const pasteNodes = (relativePosition) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let editmode = helpers.current.getEditmode();
        let { placeNodes, clipboard } = props;
        let { contextMenu } = localState;   
        if(editmode ) {
            let parsed = clipboard; //JSON.parse(data);
            if(parsed && parsed.type == 'area-nodes') {
                let pastedNodes = Immutable.fromJS(parsed.data);
                // console.log("nodes to paste", pastedNodes);
                if(relativePosition) {
                    let mostLeft = 9999999;
                    let mostTop = 9999999;
                    pastedNodes.forEach((node) => {
                        let x = node.getIn(['position','x']);
                        let y = node.getIn(['position', 'y']);
                        if(x < mostLeft) {
                            mostLeft = x;
                        }
                        if(y < mostTop) {
                            mostTop = y;
                        }
                    })
                    let svg = panelRef.current;
                    let svgPoint = svg.createSVGPoint();
                    svgPoint.x = contextMenu.x;
                    svgPoint.y = contextMenu.y;
                    let svgTransformedPoint = svgPoint.matrixTransform(svg.getScreenCTM().inverse());
                    let leftDiff = svgTransformedPoint.x - mostLeft;
                    let topDiff = svgTransformedPoint.y - mostTop;
                    pastedNodes = pastedNodes.map((node) => {
                        node = node.updateIn(['position','x'], x => x + leftDiff);
                        node = node.updateIn(['position','y'], y => y + topDiff);
                        return node;
                    })
                }
                pastedNodes = pastedNodes.map(x => x.set('id', ++localReferences.nodes));
                setLocalState({
                    ...localState,
                    selectedIds: pastedNodes.map(x => x.get('id')).toList()
                })    
                placeNodes(pastedNodes);
            }
        }
    }

    const handleResize = () => {
        let areaMenuElement = document.getElementById('area-menu');
        let areaMenuHeight = 0;
        if(areaMenuElement) {
            areaMenuHeight = areaMenuElement.clientHeight;
        }
        areaMenuElement = document.getElementById('nav-bar');
        let navBarHeight = 50;
        if(areaMenuElement) {
            navBarHeight = areaMenuElement.clientHeight;
        }
        setLocalState((localState) => ({...localState,
            defaultHeight: window.innerHeight - navBarHeight - areaMenuHeight
        }))
    }

    // componentDidMount
    useEffect(() => {
        let areaMenuElement = document.getElementById('area-menu');
        let areaMenuHeight = 0;
        if(areaMenuElement) {
            areaMenuHeight = areaMenuElement.clientHeight;
        }
        setLocalState((localState) => ({
            ...localState,
            defaultHeight: window.innerHeight - 50 - areaMenuHeight
        }))
        window.addEventListener('keydown', handleKeyPress)
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const toggleSelectionMode = () => {
        setLocalState((localState) => ({...localState,
            selectionmode: !localState.selectionmode,
            singleSelection: undefined,
            selectedIds: List()
        }))
    }

    const toggleSingleSelection = (id, shiftKeyPressed) => {
        setLocalState((localState) => ({...localState,
            singleSelection: id == localState.singleSelection ? undefined : id
        }));
    }

    const clearSelection = () => {
        setLocalState((localState) => ({...localState,
            singleSelection: undefined,
            selectedIds: List()
        }))
    }

    const toggleSelection = (id, shiftKeyPressed) => {
        setLocalState(({ selectedIds, ...localState }) => {
            if(shiftKeyPressed) {
                let index = selectedIds.indexOf(id);
                if(index > -1) {
                    selectedIds = selectedIds.delete(index);
                } else {
                    selectedIds = selectedIds.push(id);
                }
            } else {   
                let index = selectedIds.indexOf(id);
                if(index > -1) {
                    selectedIds = selectedIds.delete(index);
                } else {
                    selectedIds = selectedIds.push(id);
                }
                selectedIds = selectedIds.filter(x => x == id);
            }
            return {
                ...localState,
                selectedIds
            }
        })
    }
    
    const nodeCreation = (value, x, y) => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let { onNodeCreation } = props;
        let { contextMenu } = localState;
        let svg = panelRef.current;
        let svgPoint = svg.createSVGPoint();
        svgPoint.x = contextMenu.x;
        svgPoint.y = contextMenu.y;
        let svgTransformedPoint = svgPoint.matrixTransform(svg.getScreenCTM().inverse());
        if (onNodeCreation) {
            let position = {
                x: svgTransformedPoint.x,
                y: svgTransformedPoint.y,
            }
            onNodeCreation(position, value);
        }
        setLocalState({
            ...localState,
            contextMenu: undefined
        });
    }

    const displayContextMenu = (evt, node, index) => {
        let localState = helpers.current.getLocalState();
        let position = {
            x: evt.clientX,
            y: evt.clientY
        }
        setLocalState({
            ...localState,
            contextMenu: position
        });
        setSelection({
            selectednode: node,
            selectedindex: index
        })
        evt.preventDefault();
        evt.stopPropagation();
    }

    const contextMenuRequested = (evt) => {
        displayContextMenu(evt);
    }

    const contextMenuClosed = () => {
        setLocalState((localState) => ({
            ...localState,
            contextMenu: undefined
        }));
    }

    const localConfigureNode = (node) => {
        let props = helpers.current.getProps();
        let { onNodeConfiguration } = props;
        onNodeConfiguration(undefined, node);
    }

    const removeSelectedComponents = () => {
        let props = helpers.current.getProps();
        let localState = helpers.current.getLocalState();
        let { selectedIds } = localState;
        // console.log("selection", localState);
        // console.log("removeSelectedComponents", selectedIds);
        let { deleteNodes } = props;
        deleteNodes(selectedIds);
        contextMenuClosed();
    }

    const deleteNode = () => {
        let props = helpers.current.getProps();
        let selection = helpers.current.getSelection();
        let { deleteNode } = props;
        let { selectednode, selectedindex } = selection;
        deleteNode(selectedindex, selectednode);
    }

    
    const moveUpLayer = () => {
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let selection = helpers.current.getSelection();
        let { selectednode } = selection;
        let { layersChanged } = props;
        let currentLayerIndex = selectednode.get('layer');
        let currentLayer = layers.get(currentLayerIndex);
        let currentNodeIndex = currentLayer.get('nodes').indexOf(selectednode);
        if(layers.get(currentLayerIndex + 1)) {
            layers = layers.deleteIn([currentLayerIndex, 'nodes', currentNodeIndex]);
            layers = layers.updateIn([currentLayerIndex + 1, 'nodes'], x => x.push(selectednode));
            layersChanged && layersChanged(layers);
            contextMenuClosed()
        }
    }

    const moveDownLayer = () => {
        let props = helpers.current.getProps();
        let layers = helpers.current.getLayers();
        let selection = helpers.current.getSelection();
        let { selectednode } = selection;
        let { layersChanged } = props;
        let currentLayerIndex = selectednode.get('layer');
        let currentLayer = layers.get(currentLayerIndex);
        let currentNodeIndex = currentLayer.get('nodes').indexOf(selectednode);
        if(layers.get(currentLayerIndex - 1)) {
            layers = layers.deleteIn([currentLayerIndex, 'nodes', currentNodeIndex]);
            layers = layers.updateIn([currentLayerIndex - 1, 'nodes'], x => x.push(selectednode));
            layersChanged(layers);
            contextMenuClosed()
        }
    }

    const onSelectionBox = (startX, startY, endX, endY) => {
        let selectedIds = List();
        let left = startX;
        let top = startY;
        if(left > endX) {
            left = endX;
        }
        if(top > endY) {
            top = endY
        }
        let selectionWidth = Math.abs(endX - startX);
        let selectionHeight = Math.abs(endY - startY);
        let leftEnd = selectionWidth + left;
        let topEnd = selectionHeight + top;
        layers && layers.forEach((layer) => {    
            let nodes = layer.get('nodes');
            nodes && nodes.forEach((node) => {
                let handle = displayData.get(node.get('id'));
                handle = handle ? handle.toJS() : undefined;
                if(handle) {
                    // let htmlNode = handle.handle.getElement().node();
                    let sizes = handle.bound;
                    let { x,y, width, height } = sizes;
                            let boxStartX = x;
                            let boxEndX = x + width;
                            let boxStartY = y;
                            let boxEndY = y + height;
                            let c1 = boxStartX >= left && boxStartX <= leftEnd;
                            let c2 = boxEndX >= left && boxEndX <= leftEnd;
                            let c3 = boxStartY >= top && boxStartY <= topEnd;
                            let c4 = boxEndY >= top && boxEndY <= topEnd;
                    if(c1 && c2 && c3 && c4) {
                        selectedIds = selectedIds.push(node.get('id'))
                    }
                } 
            });

        })
        setLocalState((localState) => ({...localState, selectedIds: selectedIds}))
    }

    useEffect(() => {
        if(!editmode && selectionmode) {
            setLocalState((localState) => ({...localState,
                selectionmode: false,
                singleSelection: undefined,
                selectedIds: List()
            }))
        }

    }, [editmode])

    const onToolboxAction = (action) => {

        if(action == 'paste') {
            pasteNodes();
        }
        if(action == 'copy') {
            handleCopyEvent();
        }

        if(action == 'delete') {
            removeSelectedComponents();
        }

        if(action == 'selection-mode-toggle') {
            toggleSelectionMode();
        }

        if(action == 'align-left') {
            alignSelected('left');
        }

        if(action == 'align-right') {
            alignSelected('right');
        }

    };

    let { showMobileGraphic, nodeMovedToFront, nodeMovedToBack, onConvertArea, customer, site, renderedInDialog, clipboard, nodeChanged, onNodeConfiguration, nodeConfiguration, onReIndexArea, openAreaDialog } = props;
    let { areaPointListDialogOpened, contextMenu, selectionmode, selectedIds, singleSelection } = localState;

    const hasContentToPaste = selectionmode && clipboard && clipboard.data && clipboard.type == 'area-nodes';
    const hasCompleteAreaToPaste = clipboard && clipboard.data && clipboard.type == 'complete-area-nodes';

    const calculateSelectedItems = () => {
        let count = 0;
        if(selectednode && selectedindex) {
            count = 1;
        }

        if(selectedIds && selectedIds.size > 0) {
            count = selectedIds.size;
        }

        return count;
    }

    return (
        <div className="">
        <AreaPointListDialog 
            selecteditem={areaPointListDialogOpened}
            onClose={toggleAreaPointListDialog}
        />
        <CreateCompoundDialog 
            compoundToCreate={compoundToCreate}
            onClose={() => setCompoundToCreate(null)}
        />
            {editmode && <ContextMenu contextMenu={contextMenu} onClose={contextMenuClosed} onPositionChange={contextMenuRequested}>
               
                {!selectednode && <ContextMenu.Item onClick={() => nodeCreation(undefined)}>Add Component</ContextMenu.Item>}
                {!selectednode && <ContextMenu.Item onClick={() => nodeCreation('pointlist')}>Add Pointlist</ContextMenu.Item>}
                {!selectednode && <ContextMenu.Item onClick={toggleAreaPointListDialog}>Point List</ContextMenu.Item>}
                {!selectednode && <ContextMenu.Item onClick={toggleSelectionMode}>{selectionmode ? 'Disable selection mode' : 'Enable selection mode'}</ContextMenu.Item>}
                {!selectednode && selectionmode && selectedIds && selectedIds.size > 0 && <ContextMenu.Item onClick={createCompoundOutOfSelection}>Create compound out of selection</ContextMenu.Item>}
                {!selectednode && selectionmode && selectedIds && selectedIds.size > 0 && <ContextMenu.Item onClick={removeSelectedComponents}>Remove Selected Components</ContextMenu.Item>}
                {!selectednode && onConvertArea && <ContextMenu.Item onClick={onConvertArea}>Convert area</ContextMenu.Item>}
                
                { selectionmode && <ContextMenu.Item onClick={() => alignSelected('right')}>Align selected right</ContextMenu.Item>}
                { selectionmode && <ContextMenu.Item onClick={() => alignSelected('left')}>Align selected left</ContextMenu.Item>}
                
                {selectednode && <ContextMenu.Item onClick={() => { configureNode(selectedindex, selectednode); contextMenuClosed() }}>Configure</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { nodeMovedToFront(selectednode, false); contextMenuClosed(); }}>Move up</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { nodeMovedToBack(selectednode, false); contextMenuClosed(); }}>Move down</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { nodeMovedToFront(selectednode, true); contextMenuClosed(); }}>Move to top of layer</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { nodeMovedToBack(selectednode, true); contextMenuClosed(); }}>Move to bottom of layer</ContextMenu.Item>}
                {selectednode && selectednode.get('layer') > 0 && <ContextMenu.Item onClick={moveDownLayer}>Move to previous layer</ContextMenu.Item>}
                {selectednode && layers.get(selectednode.get('layer') + 1) && <ContextMenu.Item onClick={moveUpLayer}>Move to next layer</ContextMenu.Item>}
                
                {selectednode && <ContextMenu.Item onClick={() => { deleteNode(selectedindex, selectednode); contextMenuClosed() }}>Remove</ContextMenu.Item>}

                { selectionmode && <ContextMenu.Item onClick={(event) => { handleCopyEvent(); contextMenuClosed() }}>Copy</ContextMenu.Item> }
                { hasContentToPaste && <ContextMenu.Item onClick={() => { pasteNodes(true); contextMenuClosed() }}>Paste</ContextMenu.Item> }


                <ContextMenu.Item onClick={(event) => { onCompleteAreaCopy(); contextMenuClosed() }}>Copy complete area</ContextMenu.Item>
                { hasCompleteAreaToPaste && <ContextMenu.Item onClick={() => { onCompleteAreaPaste(true); contextMenuClosed() }}>Paste complete area</ContextMenu.Item> }

                { hasContentToPaste && <ContextMenu.Item onClick={() => { pasteNodes(false); contextMenuClosed() }}>Paste to original position</ContextMenu.Item> }
                <ContextMenu.Item onClick={() => {  onReIndexArea(); clearSelection(); contextMenuClosed() }}>Re-index area</ContextMenu.Item>
            </ContextMenu>}
            { ((!renderList || showMobileGraphic) || renderedInDialog) && <SVGPanel 
                key={site?.get('name')}
                ref={panelRef}
                backdrop={backdrop}
                openAreaDialog={openAreaDialog}
                renderedInDialog={renderedInDialog}
                onSelectionBox={onSelectionBox}
                onNodeMount={onNodeMount}
                displayData={displayData}
                layers={layers} 
                onNodeChange={nodeChanged} 
                nodeConfiguration={nodeConfiguration}
                editmode={editmode}
                selection={{ selectedIds, singleSelection }}
                toggleSelection={toggleSelection}
                toggleSingleSelection={toggleSingleSelection}
                mouseMovement={mouseMovement}
                onMultiDragFinished={onMultiDragFinished}
                setMouseMovement={setMouseMovement}
                selectionmode={selectionmode}
                onShowContextMenu={displayContextMenu} 
                onPanelContextMenu={editmode && contextMenuRequested || undefined} 
                onNodeConfiguration={onNodeConfiguration} /> }
            {renderList && !showMobileGraphic &&
                <PointDataList localConfigureNode={localConfigureNode} />
            }
            { editmode && <Toolbox onToolboxAction={onToolboxAction} toolboxState={{ selectionmode, selectedItems: calculateSelectedItems(), hasContentToPaste }} /> }

        </div>
    )

}
