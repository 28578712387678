import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import Input from 'Controls/Form/Input';
import axios from 'axios';
import Swal from 'sweetalert2';
import { saveSiteConfigFile, saveSiteSubDirectoryConfigFile } from 'BmsView/Customer/Configuration/Actions';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';


function AboutDialog({ onClose, opened, ...props }) {
    return <Dialog opened={!!opened} onClose={onClose}>
        <AboutDialogPanel onClose={onClose} {...props} />
    </Dialog>
}

const AboutDialogPanel = ({onSave, onClose }) => {
    
    const [versions, setVersions] = useState(Immutable.Map());

    useEffect(() => {

        let abortController = new AbortController();
        let signal = abortController.signal;
        (async () => {
            try {
                let response = await axios.get('/api/versions', { signal });
                setVersions(Immutable.fromJS(response.data));
            } catch(err) {
                // console.log(err);
            }
        })();

        return () => {
            abortController.abort();
        }

    }, [])

    return (
        <DialogContent className="w-[500px]">
            <DialogHeader>
                <DialogTitle>About</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="grid grid-cols-2">
                    <div className="">Main version</div>
                    <div className="">{versions.get('version')}</div>
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={onClose}>Close</Button></div>
            </DialogFooter>
        </DialogContent>
        
    )
};
export default AboutDialog;