import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Immutable from 'immutable';
import ImageSelectionDialog from './ImageSelectionDialog';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';
import ColorPicker from 'Controls/ColorPicker';

class CustomTextButton extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            currentvalue:  Immutable.Map({})
        }
    }
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    currentValueChanged(field, value) {
        this.setState(({ currentvalue }) => ({
            currentvalue: currentvalue.setIn(field, value)
        }));
    }

    ruleChanged(field, value) {
        let { onChange } = this.props;

        onChange(field, value);
    }

    addRule() {
        let { currentvalue } = this.state;
        let { selectedcomponent, onChange } = this.props;

        if (!currentvalue.get('matchvalue')) {
            Swal.fire("Error", "A value must be input", "error");
            return;
        }

        let rules = selectedcomponent.getIn(['configuration', 'rules'], Immutable.List([]));
        rules = rules.push(currentvalue);

        onChange(['configuration', 'rules'], rules);
        this.setState({
            currentvalue: Immutable.Map({})
        })
    }

    removeRule(index){
        let {onChange, selectedcomponent} = this.props;

        let rules = selectedcomponent.getIn(['configuration', 'rules']);
        rules = rules.delete(index);

        onChange(['configuration', 'rules'], rules);
    }

    clearSelection() {
        this.setState({
            currentindex: "0",
            currentrule: undefined
        });
    }

    displayImageSelectionDialog(rule, index) {
    //    if (index == undefined) index = 0;
        this.setState({
            currentindex: index,
            currentrule: rule
        });
    }

    ruleImageChanged(component) {
        let {currentindex} = this.state;

        if (currentindex == undefined) this.currentValueChanged(['name'], component.get('name'));
        else this.ruleChanged(['configuration', 'rules', currentindex,  'name'], component.get('name'));

        this.setState({
            currentindex: undefined,
            currentrule: undefined
        });
    }

    
    getPath(component) {
        return `/files/editor/components_${component}.svg`;
    }

    render() {
        let { selectedcomponent } = this.props;
        let { currentvalue, currentrule } = this.state;

        return (
            <React.Fragment>
                <ImageSelectionDialog
                    selecteditem={currentrule} 
                    onClose={this.clearSelection.bind(this)}
                    onChange={this.ruleImageChanged.bind(this)}
                />    
                <div className="my-4">
                    <div className="grid grid-cols-16 gap-2">
                        <div className="col-span-12 md:col-span-2">
                            <Input
                                label="Width"
                                type="number"
                                value={selectedcomponent.getIn(['configuration', 'width'])}
                                onChange={this.valueChanged.bind(this, ['configuration', 'width'])}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <Input
                                label="Height"
                                type="number"
                                value={selectedcomponent.getIn(['configuration', 'height'])}
                                onChange={this.valueChanged.bind(this, ['configuration', 'height'])}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <Input
                                label="Border"
                                type="number"
                                value={selectedcomponent.getIn(['configuration', 'borderwidth'])}
                                onChange={this.valueChanged.bind(this, ['configuration', 'borderwidth'])}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <Input
                                label="Rotate Angle"
                                type="number"
                                value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                                onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                            /> 
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <Input
                                label="Scale %"
                                type="number"
                                value={selectedcomponent.getIn(['configuration', 'scale'], '') || ''}
                                onChange={this.valueChanged.bind(this, ['configuration', 'scale'])}
                            /> 
                        </div>
                    </div>
                    <div className="my-4">
                        <div className="grid grid-cols-16 gap-2">
                            <div className="col-span-12 md:col-span-2">
                                <label className="block label">Value</label>
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                <label className="block label">Display Text</label>
                            </div>
                            <div className="col-span-12 md:col-span-2">
                                <label className="block label">Text Colour</label>
                            </div>
                            <div className="col-span-12 md:col-span-2">
                                <label className="block label">Background</label>
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                <label className="block label">Action</label>
                            </div>
                        </div>
                        {selectedcomponent.getIn(['configuration', 'rules'], []).map((rule, index) => {
                            return (
                                <div className="grid grid-cols-16 gap-2 my-1" key={index}>
                                    <div className="col-span-12 md:col-span-2">
                                        <Input
                                            onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index,  'matchvalue'])}
                                            value={rule.get('matchvalue')}
                                        />
                                    </div>
                                    <div className="col-span-12 md:col-span-3">
                                        <Input
                                            onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index,  'text'])}
                                            value={rule.get('text')}
                                        />
                                    </div>
                                    <div className="col-span-12 md:col-span-2">
                                        <ColorPicker value={rule.get('text-color')} onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'text-color'])} />
                                    </div>
                                    <div className="col-span-12 md:col-span-2">
                                        <ColorPicker value={rule.get('background-color')} onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'background-color'])} />
                                    </div>
                                    <div className="col-span-12 md:col-span-3">
                                        <Select
                                            options={[
                                                { value: 'static', text: 'Static' },
                                                { value: 'flashing', text: 'Flashing' }
                                            ]}
                                            onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'action'])}
                                            value={rule.get('action')}
                                        />
                                    </div>
                                    <div className="col-span-12 md:col-span-2">
                                        <Button role="danger" className="w-100" onClick={this.removeRule.bind(this, index)}>Remove</Button>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="grid grid-cols-16 gap-2 my-1">
                            <div className="col-span-12 md:col-span-2">
                                <Input
                                    onChange={this.currentValueChanged.bind(this, ['matchvalue'])}
                                    value={currentvalue.get('matchvalue')}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                <Input
                                    onChange={this.currentValueChanged.bind(this, ['text'])}
                                    value={currentvalue.get('text')}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-2">
                                <ColorPicker value={currentvalue.get('text-color')} onChange={this.currentValueChanged.bind(this, ['text-color'])} />
                            </div>
                            <div className="col-span-12 md:col-span-2">
                                <ColorPicker value={currentvalue.get('background-color')} onChange={this.currentValueChanged.bind(this, ['background-color'])} />
                            </div>

                            <div className="col-span-12 md:col-span-3">
                                <Select
                                    options={[
                                        { value: 'static', text: 'Static' },
                                        { value: 'flashing', text: 'Flashing' }
                                    ]}
                                    onChange={this.currentValueChanged.bind(this, ['action'])}
                                    value={currentvalue.get('action')}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-2 px-2">
                                <Button role="success" className="w-100" onClick={this.addRule.bind(this)}>Add</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CustomTextButton;
