import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import Immutable, { List } from 'immutable';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import DayTimesVertical from '../../../../Configuration/Calendars/TimeTemplates/DayTimes/DayTimesVertical';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { DayTemplateSelectionDialog } from '../../../../Configuration/Calendars/TimeTemplates/WeekTimesDialog';


function TimeZoneExceptionDialog({selectedItem, onClose, customer, ...props}) {
    return <Dialog opened={!!selectedItem} onClose={onClose}>
        <TimeZoneExceptionDialogPanel customer={customer} selectedItem={selectedItem} onClose={onClose} {...props} />
    </Dialog>
}

function TimeZoneExceptionDialogPanel({selectedItem, customer, saveException, onClose}) {
    const useValues = ["Next", "Every", "Free"];
    const actions = ["Off", "On"];
    
    const [exception, setException] = useState(selectedItem);
    const [selectedRange, setSelectedRange] = useState();
    const [isSelectedDayTemplate, toggleIsSelectedDayTemplate] = useState(false);

    useEffect( () => {
        setException(selectedItem);
    }, [] );  

    const changeValue = (field, value, transform) => {
        if (transform) {
            value = transform(value);
        }

        if (field == 'dateStart' && (exception.get('dateEnd') < value)) {
            setException(exception.set('dateStart', value).set('dateEnd', value));
        }

        else setException(exception.set(field, value));
    }

    const changeTimeActionValue = (field, index, value) => {
        

        setException((exception) => {
            let periods = exception.get('m_prds');
            
            let newperiods = (periods.map((period, i) => {
                if (index == i) {
                    let newperiod = period;
                    if (field == 'time') {
                        newperiod = newperiod.set('h', value.getHours());
                        newperiod = newperiod.set('m', value.getMinutes());
                    } else newperiod = newperiod.set(field, value);
                    return newperiod;
                }

                else return period;
            }));
            
            return exception.set('m_prds', newperiods)
        });
    }

    const onSave = () => {
        // console.log("exception to be saved", exception);
        saveException(exception, 0);
    }

    const onAdd = () => {
        let newExceptionTime = Immutable.Map({'h':1, 'm':20, 'v': 1});

        let exceptionTimes = exception.get('m_prds', Immutable.List([]));
        exceptionTimes = exceptionTimes.push(newExceptionTime);

        setException(exception.set('m_prds', exceptionTimes));
    }

    const removePeriod = (index) => {
        let exceptionTimes = exception.get('m_prds', Immutable.List([]));
        exceptionTimes = exceptionTimes.remove(index);

        setException(exception.set('m_prds', exceptionTimes));
    }

    // console.log("selectedItem in TimezoneExeceptionDialog", selectedItem);

    const {formattedTimes, formattedSelection} = (() => {
        let exceptionList = (exception.get('m_prds') || List()).toJS();
        exceptionList = exceptionList.map((x, i) => ({...x, initialIndex: i }))
        exceptionList = exceptionList.sort((a,b) => {
            return (a.h * 100 + a.m) - (b.h * 100 + b.m)
        })
        let convertedExceptions = [];
        let convertedException = undefined;
        let selectedItem = undefined;
        while(exceptionList.length > 0) {
            let currentException = exceptionList.shift();
            
            if(convertedException) {
                convertedException.endReference = currentException.initialIndex
                convertedException.endindex =  currentException.h * 60 + currentException.m;
                convertedExceptions.push(convertedException)
                if(currentException.v == 1) {
                    convertedException = { 
                        startReference: currentException.initialIndex,
                        startindex: currentException.h * 60 + currentException.m 
                    }
                    if(currentException.initialIndex == selectedRange) {
                        selectedItem = convertedException;
                    }
                } else {
                    convertedException = undefined;
                }
            } else if(currentException.v == 1) {
                convertedException = { 
                    startReference: currentException.initialIndex,
                    startindex: currentException.h * 60 + currentException.m 
                }
                if(currentException.initialIndex == selectedRange) {
                    selectedItem = convertedException;
                }
            }
            
        }

        if(convertedException) {
            convertedException.endindex = 1440;
            convertedExceptions.push(convertedException)
        }

        return { formattedSelection: selectedItem, formattedTimes: convertedExceptions}

    })()

    const onChangeRange = (event, changedEvent) => {
        // console.log("onChangeRange event", event);
        // console.log("changedEvent", changedEvent);
        changeTimeActionValue('time', event.startReference, { 
            getHours: () => (changedEvent.startindex - (changedEvent.startindex % 60)) / 60 ,
            getMinutes: () => changedEvent.startindex % 60
        })
        if(event.endReference) {
            changeTimeActionValue('time', event.endReference, { 
                getHours: () => (changedEvent.endindex - (changedEvent.endindex % 60)) / 60 ,
                getMinutes: () => changedEvent.endindex % 60
            })
        } else {
            let newExceptionTimeOff = Immutable.Map({
                'h': (changedEvent.endindex - changedEvent.endindex % 60) / 60, 
                'm': changedEvent.endindex % 60, 
                'v': 0
            });
            setException((exception) => exception.update('m_prds', x => x.push(newExceptionTimeOff)))
        }
    }

    const onSelectRange = (event) => {
        setSelectedRange(event.startReference);
    }

    const onCreateRange = (event) => {
        let newExceptionTime = Immutable.Map({
            'h': (event.startindex - event.startindex % 60) / 60, 
            'm': event.startindex % 60, 
            'v': 1
        });

        let newExceptionTimeOff = Immutable.Map({
            'h': (event.endindex - event.endindex % 60) / 60, 
            'm': event.endindex % 60, 
            'v': 0
        });

        let exceptionTimes = exception.get('m_prds', Immutable.List([]));
        exceptionTimes = exceptionTimes.push(newExceptionTime);
        exceptionTimes = exceptionTimes.push(newExceptionTimeOff);

        setException(exception.set('m_prds', exceptionTimes));
    }

    const processDayAction = (action) => {
        switch(action) {
            case 'onAllDay':
                onAllDay();
            break;
            case 'offAllDay':
                offAllDay();
            break;
            case 'selectTemplate':
                toggleIsSelectedDayTemplate(() => true);
            break;
        }
    }

    const onApplyDayTemplate = (template) => {
        // console.log("onApplyDayTemplate", template);
        let periods = template.get('periods') || List();
        let exceptionTimes = Immutable.List();
        periods.forEach((period) => {
            let newExceptionTime = Immutable.Map({
                'h': (period.get('startindex') - period.get('startindex') % 60) / 60, 
                'm': period.get('startindex') % 60, 
                'v': 1
            });
    
            let newExceptionTimeOff = Immutable.Map({
                'h': (period.get('endindex') - period.get('endindex') % 60) / 60, 
                'm': period.get('endindex') % 60, 
                'v': 0
            });

            exceptionTimes = exceptionTimes.push(newExceptionTime);
            exceptionTimes = exceptionTimes.push(newExceptionTimeOff);
        })

        setException(exception.set('m_prds', exceptionTimes));
        toggleIsSelectedDayTemplate(() => false);
        

    }

    const onAllDay = () => {
        let exceptionTimes = exception.get('m_prds', Immutable.List([]));
        let newExceptionTime = Immutable.Map({
            'h': 0, 
            'm': 0, 
            'v': 1
        });

        let newExceptionTimeOff = Immutable.Map({
            'h': 24, 
            'm': 0, 
            'v': 0
        });

        exceptionTimes = exceptionTimes.push(newExceptionTime);
        exceptionTimes = exceptionTimes.push(newExceptionTimeOff);

        setException(exception.set('m_prds', exceptionTimes));
    }

    const offAllDay = () => {
        setException(exception.set('m_prds', Immutable.List([])));
    }

    return(
        <DialogContent>
            <DayTemplateSelectionDialog 
                customer={customer} 
                isOpened={isSelectedDayTemplate} 
                onSave={onApplyDayTemplate} 
                onClose={() => toggleIsSelectedDayTemplate(false)} /> 
            <DialogHeader>
                <DialogTitle>Exception Setup</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="flex flex-row mt-1 ">
                    <div className="w-g-8">
                        <Input value={exception.get('desc')} onChange={(value) => changeValue('desc', value, undefined)} label="Identifier"/>
                    </div>
                    <div className="w-g-4 px-4">
                        <Select
                            label="Use"
                            value={exception.get('use')}
                            onChange={ (value) => changeValue('use', value, undefined)}
                            options={useValues.map((use,index) => {
                                return({
                                    text: use,
                                    value: index
                                })
                            })}
                        />
                    </div>
                    <div className="w-g-4 px-4">
                        <Select
                            label="Priority"
                            value={exception.get('prty')}
                            onChange={ (value) => changeValue('prty', value, parseInt)}
                            options={[1,2,3,4,5,6].map((p) => {
                                return({
                                    text: p,
                                    value: p
                                })
                            })}
                        />
                    </div>
                </div>
                <div className="flex flex-row mt-2">
                    <div className="w-g-6 relative z-40">
                        <label className="block-label">From</label>
                        <DatePicker
                            dateFormat="dd/MM"
                            placeholderText="Click to select a date"
                            selected={new Date(exception.get('dateStart')) || null}
                            onChange={(value) => {changeValue('dateStart', value)}}
                            isClearable
                        />
                    </div>
                    <div className="w-g-6 relative z-40 px-2">
                        <label className="block-label mx-4">To</label>        
                        <DatePicker
                            className="mx-4"
                            dateFormat="dd/MM"
                            placeholderText="Click to select a date"
                            selected={new Date(exception.get('dateEnd')) || null}
                            onChange={(value) => {changeValue('dateEnd', value)}}
                            isClearable
                        />
                    </div>
                </div>
                <div className="mt-2">
                    
                    <DayTimesVertical onDayMenuSelect={processDayAction} onCreate={onCreateRange} onSelect={onSelectRange} selectedEvent={formattedSelection} onChange={onChangeRange} events={formattedTimes} />
                </div>    
            </DialogBody>
            <DialogFooter>
                <Button onClick={() => {onClose()}}>Close</Button>
                <Button onClick={() => {onSave()}}>Save</Button>
            </DialogFooter>
        </DialogContent>
    )
    
}

export default TimeZoneExceptionDialog;
