import axios from 'axios';
import Store from 'Store';
import Configuration from 'Configuration';
import Immutable, {fromJS} from 'immutable';

export function setSaveAreaHandler(saveAreaHandler) {
    Store.dispatch({
        type: 'SAVE_AREA_HANDLER',
        handler: saveAreaHandler
    })
}


export function uploadBackdrop(customer, site, filename, file) {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('file', file, file.name);
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(`/files/customers/${customer}_${site}_backdrops_${filename}/upload`, data, config).then(resolve).catch(reject);
    })
} 

export function saveCustomerBackdrops(customer, site, backdrops) {
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${customer}_${site}_backdrops_backdrops.json`, backdrops).then((response) => {
            resolve();
        }).catch((err) => {
            reject();
        });
    })
}

export function uploadComponent(customer, site, filename, file) {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('file', file, file.name);
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(`/files/customers/${customer}_${site}_components_${filename}/upload`, data, config).then(resolve).catch(reject);
    })
} 

export function saveCustomerComponents(customer, site, components) {
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${customer}_${site}_components_components.json`, components).then((response) => {
            resolve();
        }).catch((err) => {
            reject();
        });
    })
}

export function saveSiteConfiguration(customer, site, configuration) {
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${customer}_${site}_configuration.json`, configuration).then((response) => {
            resolve();
        }).catch((err) => {
            reject();
        });
    })
}

export async function saveAreaTemplate(customer, site, nodetemplate, variables){
    nodetemplate = Immutable.Iterable.isIterable(nodetemplate) ? nodetemplate.toJS() : nodetemplate;
    variables = Immutable.Iterable.isIterable(variables) ? variables.toJS() : variables;

    if(nodetemplate.isV2){
        await axios.post(`/files/customers/${customer}_templates-v2_${nodetemplate.name.toLcHyphen()}.template.json`, nodetemplate.layers);
        await axios.post(`/files/customers/${customer}_templates-v2_${nodetemplate.name.toLcHyphen()}.config.json`, variables);
    } else {
        await axios.post(`/files/customers/${customer}_templates_${nodetemplate.name.toLcHyphen()}.template.json`, nodetemplate.nodes);
        await axios.post(`/files/customers/${customer}_templates_${nodetemplate.name.toLcHyphen()}.config.json`, variables);
    }

   
    return true;
}

export async function getSiteFile(customer, site) {
    
    let response = await axios.get(`/files/customers/${customer}_${site}_configuration.json`);
    return Immutable.fromJS(response.data);
}

export function getSite(customer, site, nodepath, abortController = new AbortController()) {
    // console.log(`%c 111 site: /files/customers/${customer}_${site}_configuration.json`, 'color: yellow')
    return new Promise((resolve, reject) => {
        axios.get(`/files/customers/${customer}_${site}_configuration.json`, {
            signal: abortController.signal
        }).then((response) => {
            let dataToDispatch = fromJS({
                name: site,
                treenodes: response.data,
                nodepath: nodepath,
                customerName: customer,
                siteName: site
            });
            let dispatchResponse = Store.dispatch({
                type: 'SITE_READY',
                data: dataToDispatch
            });

            // console.log("111 DISPATCH RESPONSE", dispatchResponse);

            resolve(dataToDispatch);
        })
    });
}

export function updateSite(site, nodepath) {
    Store.dispatch({
        type: 'SITE_READY',
        data: site
    });
    return site;
}

export function fetchSite(customer, site, additionalParams, abortController = new AbortController()) {
    return new Promise((resolve, reject) => {
        axios.get(`/files/customers/${customer}_${site}_configuration.json`, {
            signal: abortController.signal
        }).then((response) => {
            resolve(fromJS({
                name: site,
                treenodes: response.data,
                customerName: customer,
                siteName: site
            }));
        }).catch(reject);
    });
}

export function getPoints(customer, connection) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/points?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function searchPoints(customer, connection, query){
    return new Promise((resolve, reject) => {
        axios.post(`/api/points/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: query }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function updatePointConfiguration(customer, connection, point){
    return new Promise((resolve, reject) => {
        let rawData = Immutable.Iterable.isIterable(point) ? point.toJS() : point;
        axios.put(`/api/points/${point.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, rawData).then(resolve).catch(reject);
    });
}