import React from 'react';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Immutable from 'immutable';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function NotesDialog({selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <NotesDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class NotesDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedarea: props.selecteditem,
            message: Immutable.Map({
                name: '',
                message: ''
            })
        }
    }

    save() {
        let { onSave } = this.props;

        if (onSave) {
            let { selectedarea } = this.state;
            onSave(selectedarea);
        }
    }

    addMessage() {
        let { currentuser } = this.props;
        this.setState(({ selectedarea, message }) => ({
            selectedarea: selectedarea.updateIn(['notes'], arr => arr.push(Immutable.Map({
                user: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
                time: Date.now(),
                message: message.get('message'),
                name: message.get('name')
            }))),
            message: Immutable.Map({
                name: "",
                message: ""
            })
        }));
    }

    updateMessage(field, value) {
        this.setState(({message}) => ({
            message: message.set(field, value)
        }));
    }

    valueChanged(field, value){
        this.setState(({selectedarea}) => ({
            selectedarea: selectedarea.setIn(field, value)
        }));
    }

    delete(note, index){
        this.setState(({selectedarea}) => ({
            selectedarea: selectedarea.deleteIn(['notes', index])
        }));
    }

    render() {
        let { onClose, currentuser } = this.props;
        let { selectedarea, message } = this.state;
        let currentusername = `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`;
        return (
            <DialogContent className="md:w-[70rem]">
                <DialogHeader>Notes</DialogHeader>
                <DialogBody>
                    <div className="mt-4">
                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-3">Name</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-8">Message</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">User</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2" name="option"></Grid.Column>
                            </Grid.Header>
                            <Grid.Data>
                                {selectedarea.get('notes').map((note, index) => {
                                    return (
                                        <Grid.Row key={index}>
                                            <Grid.Column classes="col-span-16 md:col-span-3"><Input noMargin disabled={currentusername != note.get('user')} value={note.get('name')} onChange={this.valueChanged.bind(this, ['notes', index, 'name'])} /></Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-8"><Input noMargin disabled={currentusername != note.get('user')} value={note.get('message')} onChange={this.valueChanged.bind(this, ['notes', index, 'message'])} /></Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-3">{note.get('user')}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-2">
                                                {currentusername == note.get('user') && <Button role="danger" onClick={this.delete.bind(this, note, index)}>Remove</Button>}
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })}
                            </Grid.Data>
                        </Grid>
                    </div>
                    <div className="grid grid-cols-16 gap-2">
                        <div className="col-span-16 md:col-span-3 ">
                            <Input label="Name" value={message.get('name')} onChange={this.updateMessage.bind(this, 'name')} />
                        </div>
                        <div className="col-span-16 md:col-span-8 ">
                            <Input label="Message" value={message.get('message')} onChange={this.updateMessage.bind(this,'message')} />
                        </div>
                        <div className="col-span-16 md:col-span-3 flex items-end">
                            <div className="pl-1 mb-4"><Button role="success" onClick={this.addMessage.bind(this)}>Add</Button></div>
                        </div>
                    </div>
                    <div className="flex items-end justify-end">
                        <div className="p-1">
                            <Button role="primary" onClick={this.save.bind(this)}>Save</Button>
                        </div>
                        <div className="p-1">
                            <Button role="secondary" onClick={onClose}>Close</Button>
                        </div>
                        
                        
                    </div>
                </DialogBody>
            </DialogContent>
        );
    }
}

export default NotesDialog;