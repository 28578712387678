import React, {useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import Area from './Area';
import AreaV2 from './Area/indexV2';
import { Routes, Route, Navigate, useRouteMatch, useParams, useMatches, Outlet, redirect, useRevalidator, useNavigate } from 'react-router-dom';
import Immutable, { Map, fromJS, set } from 'immutable';
import './Reducers';
import { connect, useSelector } from 'react-redux';
import { getSite, saveSiteConfiguration } from './Actions';
import Layout from 'BmsView/Layout';
import AreaMenu from './AreaMenu';
import {duplicateArea, saveArea, renameArea, convertAreaToV2} from './Area/Actions';
import Alarms from './Alarms';
import AreaDialog from './Area/AreaDialog'
import AreaDialogV2 from './Area/AreaDialogV2'
import { getNodeMeta } from '../../Helpers/NodeHelper';
import axios from 'axios';
import AreaPointProvider from '../../../Context/AreaPoint';
import * as d3 from 'd3';
import Version2ConversionDialog from './Version2ConversionDialog';
import { useNavigation } from 'react-router-dom';
import { useBMS } from '../../Context/BMS';
import AreaMenuContext from '../../../Context/AreaMenu';
import AreaContext from '../../../Context/Area';
import debuggerService from '../../../Services/DebuggerService';
import { useTransparentLoader } from '../../../Context/TransparentLoader';
import UnacknowledgedAlarmsProvider from '../Context/UnacknowledgedAlarms';

// This is the file where Alarms are being used


let AreaVersionComponent = Area; 
let AreaDialogComponent = AreaDialog;
const MemoSitePage = SitePage;

function AreaRouteLoader() {
    const navigation = useNavigation();
    const revalidator = useRevalidator();
    const transparentLoader = useTransparentLoader();
    // console.log("111 AreaRouterLoader", navigation.state);

    return (transparentLoader.loading || revalidator.state == "loading" || navigation.state == 'loading') && <div className={`w-full h-full top-0 fixed left-0 p-4 cursor-wait`}></div> || null 
}


export function Site({ params, site, customer, skipRender }) {


    // const currentUser = useSelector(state => state.get('currentUser'));
    const { currentUser, revalidateSite } = useBMS();
    const revalidator = useRevalidator();

    // useEffect(() => {
    //     console.log("111 Site useEffect found change in revalidator");
    // }, [revalidator])

    // const navigate = useNavigate();
    // const nodepath = params?.['*'];

    // console.log("%c 111 Site render", 'color:deepskyblue', site?.get('siteName'))
    // console.log('%c 111 Site render site params', 'color:deepskyblue', params?.site, params?.customer, params?.['*'])

    

    const onGraphOrderChange = async (newSite, renameQueue) => {
        await saveSiteConfiguration(params.customer, params.site, newSite);
        await Promise.all(renameQueue.map(async (rename) => {
            let filesource = `${params.customer}_${rename.originalPath}`
            filesource = filesource.split('/').join('_');
            let filedest = `${params.customer}_${rename.newPath}`
            filedest = filedest.split('/').join('_');
            try {
                await renameArea(filesource, filedest, true);
            } catch(err) {
                // console.log(`%c failed renaming `, "color: red", err.message, filesource, filedest);
            }
           
        }));
        revalidateSite();
        revalidator.revalidate();
        return true;
        //await getSite(params.customer, params.site, params?.params?.[0]);
    };

    const updateOrderInConfiguration = async (nodes) => {
        await saveSiteConfiguration(params.customer, params.site, nodes);
        // getSite(params.customer, params.site, params?.params?.[0]);
        revalidateSite();
        revalidator.revalidate();
        return true;
    }


    const updateSiteAttributes = async (path, node, indexOfNode) => {
        let nodes = site.get('treenodes', Immutable.List([]));
        let updateInPath = [];
        let currentNodes = site.get('treenodes', Immutable.List([]));
        if (path.length > 0) {
            path.forEach((slug) => {
                let currentNode = currentNodes.filter((cNode) => { return cNode.get('name').toLowerCase().split(' ').join('-') == slug; }).first();
                if (currentNode) {
                    let indexOfLevel = currentNodes.indexOf(currentNode);
                    updateInPath.push(indexOfLevel);
                    updateInPath.push('treenodes');
                    currentNodes = currentNode.get('treenodes', Immutable.List([]));
                }
            });
            if (!nodes.getIn(updateInPath)) {
                nodes = nodes.setIn(updateInPath, Immutable.List([]));
            }
            if (indexOfNode < 0) {
                nodes = nodes.updateIn(updateInPath, arr => arr.push(node));
            }
            else {
                nodes = nodes.setIn([...updateInPath, indexOfNode], node);
            }
        }
        else {
            if (indexOfNode < 0) {
                nodes = nodes.push(node);
            }
            else {
                nodes = nodes.set(indexOfNode, node);
            }
        }

        await saveSiteConfiguration(params.customer, params.site, nodes);
        revalidateSite();
        revalidator.revalidate();
        // getSite(params.customer, params.site, params?.params?.[0]);
    }

    const updateConfiguration = async (path, node, indexOfNode, source, destination, components) => {
        // return;
        let nodes = site.get('treenodes', Immutable.List([]));
        let updateInPath = [];
        let currentNodes = site.get('treenodes', Immutable.List([]));
        if (path.length > 0) {
            path.forEach((slug) => {
                let currentNode = currentNodes.filter((cNode) => { return cNode.get('name').toLowerCase().split(' ').join('-') == slug; }).first();
                if (currentNode) {
                    let indexOfLevel = currentNodes.indexOf(currentNode);
                    updateInPath.push(indexOfLevel);
                    updateInPath.push('treenodes');
                    currentNodes = currentNode.get('treenodes', Immutable.List([]));
                }
            });
            if (!nodes.getIn(updateInPath)) {
                nodes = nodes.setIn(updateInPath, Immutable.List([]));
            }
            if (indexOfNode < 0) {
                nodes = nodes.updateIn(updateInPath, arr => arr.push(node));
            }
            else {
                nodes = nodes.setIn([...updateInPath, indexOfNode], node);
            }
        }
        else {
            if (indexOfNode < 0) {
                nodes = nodes.push(node);
            }
            else {
                nodes = nodes.set(indexOfNode, node);
            }
        }

        await saveSiteConfiguration(params.customer, params.site, nodes);

        // console.log("%c source", "color: red", source);
        // console.log("%c destination", "color: red", destination);
        let redirectTo = null;
        //TODO: Nodes save logic here.
        if (source && destination){
            if (source != destination && indexOfNode >= 0){
                let filesource = `${params.customer}_${params.site}_${source}`
                filesource = filesource.split('/').join('_');
                let filedest = `${params.customer}_${params.site}_${destination}`
                filedest = filedest.split('/').join('_');
                await renameArea(filesource, filedest);
                redirectTo = `/customer/${params.customer}/site/${params.site}/${destination}`
            }
            else if (!components){
                let filesource = `${params.customer}_${params.site}_${source}`
                filesource = filesource.split('/').join('_');
                let filedest = `${params.customer}_${params.site}_${destination}`
                filedest = filedest.split('/').join('_');
                await duplicateArea(filesource, filedest);
                redirectTo = `/customer/${params.customer}/site/${params.site}/${destination}`
            }
            else{
                await saveArea(components, destination);
            }
        }
      
        return true;

    }

    // second in remove process
    const deleteInConfiguration = async (path, node, noRefresh = false) => {
        let nodes = site.get('treenodes', Immutable.List([]));
        let updateInPath = [];
        let currentNodes = site.get('treenodes', Immutable.List([]));
        if (path.length > 0) {
            path.forEach((slug) => {
                let currentNode = currentNodes.filter((cNode) => { return cNode.get('name').toLowerCase().split(' ').join('-') == slug; }).first();
                if (currentNode) {
                    let indexOfLevel = currentNodes.indexOf(currentNode);
                    updateInPath.push(indexOfLevel);
                    updateInPath.push('treenodes');
                    currentNodes = currentNode.get('treenodes', Immutable.List([]));
                }
            });
            if (!nodes.getIn(updateInPath)) {
                nodes = nodes.setIn(updateInPath, Immutable.List([]));
            }
            let indexOfNode = nodes.getIn(updateInPath).findIndex((currnode) => {
                return currnode.get('name') == node.get('name');
            });
            nodes = nodes.deleteIn([...updateInPath, indexOfNode]);

        }
        else {
            let indexOfNode = nodes.findIndex((currnode) => {
                return currnode.get('name') == node.get('name');
            });
            nodes = nodes.delete(indexOfNode);
        }

        await saveSiteConfiguration(params.customer, params.site, nodes);

        if (!noRefresh) {
            revalidateSite();
            revalidator.revalidate();
        }
        return true;
    }

    // console.log("0611 Site render")


    const contextKey = `${params?.customer}-${params?.site}-${params?.['*']}`;
                
    return <Fragment>
        <UnacknowledgedAlarmsProvider>
        <AreaMenuContext>
            <AreaContext key={contextKey}>
            <AreaRouteLoader />
        { site && customer && <MemoSitePage 
        site={site} 
        customer={customer} 
        currentUser={currentUser}
        updateConfiguration={updateConfiguration}
        updateOrderInConfiguration={updateOrderInConfiguration}
        updateSiteAttributes={updateSiteAttributes}
        deleteInConfiguration={deleteInConfiguration}
        onGraphOrderChange={onGraphOrderChange}
        /> || null}
        </AreaContext>
                                </AreaMenuContext>
                                </UnacknowledgedAlarmsProvider>
        </Fragment>


}

export default Site;



function SitePage({ site, customer, currentUser, ...props}) {

    const [areaForDialog, setAreaForDialog] = useState(null);
    const [areaToConvert, setAreaToConvert] = useState(null);
    const [fakeSite, setFakeSite] = useState(null);

    let { updateConfiguration, updateOrderInConfiguration, deleteInConfiguration, onGraphOrderChange, updateSiteAttributes } = props;
    let nodepath = (fakeSite || site).get('nodepath') || "";
    let siteName = (fakeSite || site).get('siteName');
    let customerName = (fakeSite || site).get('customerName');
    let { levels, currentNode, nodes, baseDirectory, customerMainDirectory, siteMainDirectory, indexOfCurrent, siteGraph } =  useMemo(() => getNodeMeta(nodepath, customer, fakeSite || site), [nodepath, customer, site, fakeSite]);
    let [lvl1, lvl2, lvl3, lvl4, lvl5, lvl6] = levels;
    let [lvl1node, lvl2node, lvl3node, lvl4node, lvl5node, lvl6node] = nodes;
    
    if(currentNode && currentNode.get('isV2')) {
        AreaVersionComponent = AreaV2;
        AreaDialogComponent = AreaDialogV2;
    } else {
        AreaVersionComponent = Area;
        AreaDialogComponent = AreaDialog;
    }

    const convertRootAreaToV2 = async (area) => {
        let areaNode = (fakeSite || site).get('treenodes').find((node) => {
            return node == area;
        });

        if(areaNode) {
            setAreaToConvert(areaNode);
        }
    } 

    return (
        <React.Fragment>
            <Version2ConversionDialog
                area={areaToConvert}
                opened={!!areaToConvert}
                onClose={() => setAreaToConvert(undefined)}
                customer={customer}
                site={(fakeSite || site)}
                // params={params}
                />
            <AreaMenu
                currentNode={currentNode}
                convertRootAreaToV2={convertRootAreaToV2}
                onSave={updateConfiguration}
                onOrderChange={updateOrderInConfiguration}
                onAttributesSave={updateSiteAttributes}
                onDelete={deleteInConfiguration}
                site={fakeSite || site}
                siteGraph={siteGraph}
                setFakeSite={setFakeSite}
                onGraphOrderChange={onGraphOrderChange}
                currentareapath={nodepath}
                nodepath={{
                    lvl1,
                    lvl2,
                    lvl3,
                    lvl4,
                    lvl5,
                    lvl6
                }}
                sitename={siteName}
                nodes={{
                    lvl1node,
                    lvl2node,
                    lvl3node,
                    lvl4node,
                    lvl5node,
                    lvl6node
                }}
                customername={customerName}
                customer={customer}
                baseDirectory={baseDirectory}
                customerMainDirectory={customerMainDirectory}
            />
            <Layout>
                {/* <AreaDialogComponent 
                    onClose={() => setAreaForDialog(undefined)}
                    // params={params}
                    site={(fakeSite || site)}
                    customer={customer}
                    customerMainDirectory={customerMainDirectory}
                    selecteditem={areaForDialog} /> */}
                { currentNode && currentNode.get('isV2') && <Fragment>
                    <AreaPointProvider customer={customer.get('name').toLowerCase().replace(/ /g, "")}>

                    <AreaVersionComponent
                    openAreaDialog={setAreaForDialog}
                    key={`${(fakeSite || site).get('name')}-${nodepath}`} 
                    // params={params} 
                    currentNodeConfiguration={currentNode} 
                    site={(fakeSite || site)} 
                    nodepath={nodepath} 
                    baseDirectory={baseDirectory}
                    siteMainDirectory={siteMainDirectory} 
                    indexOfCurrentNodeConfiguration={indexOfCurrent}
                    customerMainDirectory={customerMainDirectory}
                    renderedInDialog={false}
                />
                    </AreaPointProvider>
                    </Fragment> || <Fragment>
                    <AreaVersionComponent
                    openAreaDialog={setAreaForDialog}
                    key={`${(fakeSite || site).get('name')}-${nodepath}`} 
                    // params={params} 
                    currentNodeConfiguration={currentNode} 
                    site={(fakeSite || site)} 
                    nodepath={nodepath} 
                    baseDirectory={baseDirectory}
                    siteMainDirectory={siteMainDirectory} 
                    indexOfCurrentNodeConfiguration={indexOfCurrent}
                    customerMainDirectory={customerMainDirectory}
                    renderedInDialog={false}
                />
                    </Fragment>}
                
                <Alarms customer={customer} site={(fakeSite || site)} user={currentUser} urlsound={`/wav/${currentUser.get('alarmSound' || 'ding')}.wav`} />
            </Layout>
        </React.Fragment>
    );
 
}
