import React from 'react';
import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function ConversionForm({ changeCoords, x, y, onSave, onClose }) {


    return <DialogContent>
        <DialogBody>
            <h3>Convert area</h3>
            <div className="my-4">
                <Input label="X"  onChange={(value) => changeCoords(value, y)} value={x} />
            </div>
            <div className="my-4">
                <Input label="Y"  onChange={(value) => changeCoords(x, value)} value={y} />
            </div>
        </DialogBody>
        <DialogFooter>
            <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
            <div className="pl-1"><Button role="success" onClick={onSave}>Save</Button></div>
        </DialogFooter>
    </DialogContent>
}


// export default Dialog({ width: '2xl' })(ConversionForm);

export default function ConversionFormDialog({ selecteditem, onClose, ...props }) {

    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <ConversionForm selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>

}