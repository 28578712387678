import * as d3 from 'd3';
import ComponentCache from '../ComponentCache';
import { displayImage, setStateids, blink, displayTooltip } from "../Utils";

export default function TextButton(options, path) {
    return new Promise(async (resolve, reject) => {
        var animate;
        var image;
        var defaultimage = await getDefaultTextButtonImage(options.node.getIn(['component', 'componentid']));

        let images = await getImages(options.node.getIn(['configuration', 'rules']), options.node.getIn(['component', 'componentid']));

        if (!options.node.getIn(['configuration', 'rules']) || options.node.getIn(['configuration', 'rules']).size === 0)  {
            image = images[0];
        }

        else {
            image = images[Object.keys(images)[0]];
        }

        let placementElement = displayImage(options, defaultimage.layer);
        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                if ( placementElement) placementElement.remove();
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;

                let value = point.m_value;

                if (!options.node.getIn(['configuration', 'rules']) || options.node.getIn(['configuration', 'rules']).size === 0) {
                    image = images[0];
                }

                else {
                //    if (value != oldvalue) {
                //        oldvalue = value;
                    if ( image != images[point.m_value] ) { 
                        image = images[point.m_value];
                    }
                }
                
                if (image) {
                    placementElement = displayImage(options, image.layer);  
                    tooltip = displayTooltip(placementElement, options);

                    if( !options.editmode && images[point.m_value] && "action" in images[point.m_value] ) {
                        if (images[point.m_value].action == 'flashing') {
                            animate = blink(placementElement);}
                        else {
                            if (animate) animate.remove();
                        } 
                    }    
                }  
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            setContextMenu: (editmode) => {
                if (animate) animate.remove();
                options.editmode = editmode;

                if (!image && images) {
                    image = images[Object.keys(images)[0]];
                    placementElement = displayImage(options, image.layer);
                }
            },
            redrawElement: async (node) => {
                if (images) {
                    let keys = Object.keys(images);
                    keys.forEach((key) => {
                        images[key].layer.selectAll("*").remove(); 
                    });
                }    

                placementElement.remove();           
                options.node = node;

                images = await getImages(node.getIn(['configuration', 'rules']), node.getIn(['component', 'componentid']));

                if (!node.getIn(['configuration', 'rules'])) {
                    image = images[0];
                }

                else {
                    image = images[Object.keys(images)[0]];
                }    
            
                if (image) {
                    placementElement = displayImage(options, image.layer);

                    tooltip = displayTooltip(placementElement, options);
                }
            //    oldvalue = -1;
            },
            remove: () => {
                if (images) {
                    let keys = Object.keys(images);
                    keys.forEach((key) => {
                        images[key].layer.selectAll("*").remove(); 
                    });
                } 

                placementElement.remove();               
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    })

}


async function getImages(rules, componentid) {      
    let images = {};
    var layer;

    if (rules) {
        for (let i = 0; i < rules.size; i++) {
            await ComponentCache.getXml(`/files/editor/components_${rules.get(i).get('name')}.svg`).then((xml) => {
                layer = d3.select(xml.cloneNode(true).documentElement).select(`#${componentid}`);
                images[rules.get(i).get('matchvalue')] = {layer: layer }

                if(rules.get(i).get('action')) {
                    Object.assign(images[rules.get(i).get('matchvalue')],{'action':rules.get(i).get('action')})
                } 
            })
        }
    }    

    if ( !rules || Object.keys(images).length === 0 ) {
        await ComponentCache.getXml(`/files/editor/components-v2_text-buttons_default.svg`).then((xml) => {
            layer = d3.select(xml.cloneNode(true).documentElement).select(`#${componentid}`);  
            images[0] = {layer: layer}
        })
    }

    return images;
}

async function getDefaultTextButtonImage (componentid) {      
    var image;
    var layer;
    var xml;

    await ComponentCache.getXml(`/files/editor/components-v2_text-buttons_polling.svg`).then((xml) => {
        layer = d3.select(xml.cloneNode(true).documentElement).select(`#${componentid}`);
        image = {layer: layer}
    })

    return image;
}

