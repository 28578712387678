import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function TransitioningDialog({ opened, onClose, children, panelStyle = {}, zIndex = "z-10", panelWidthClass = "md:max-w-[60vw]", panelExtraClass = "" }) {
    return (
        <Transition.Root show={opened} as={Fragment}>
            <Dialog as="div" className="relative z-[1050]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className={`fixed ${zIndex} inset-0 overflow-y-auto`}>
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={`relative bg-white rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 min-w-fit ${panelWidthClass} ${panelExtraClass} sm:w-full sm:p-6`} style={panelStyle}>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default TransitioningDialog;
