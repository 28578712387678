import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Checkbox from 'Controls/Form/Checkbox';
import ColorPicker from 'Controls/ColorPicker';
import Immutable from 'immutable';

class Line extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }
    render() {
        let { selectedcomponent } = this.props;
        return (
            <div className="my-4">
                <div className="grid grid-cols-16">
                    <div className="col-span-4">
                        <Input
                            label="Length"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'length'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'length'])}
                        />
                    </div>
                    <div className="col-span-4">
                        <Input
                            label="Stroke Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'strokewidth'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'strokewidth'])}
                        />
                    </div>
                    <div className="col-span-12">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'angle'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'angle'])}
                        /> 
                    </div>
                </div>  
                <div className="grid grid-cols-16">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Stroke Colour" value={selectedcomponent.getIn(['configuration', 'strokecolour'])} onChange={this.valueChanged.bind(this, ['configuration', 'strokecolour'])} /></div>
                </div>
            </div>
        )
    }
}

export default Line;