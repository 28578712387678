import React, { useEffect, useState } from 'react';
import './grid.scss';
import Immutable from 'immutable';
import Header from './Header';
import Column from './Column';
import Row from './Row';
import Data from './Data';
import Fragment from './Fragment';

function Controls({ children }) {
    return <div className="flex flex-row justify-end w-full">
        { children }
    </div>
}

function Control({ children }) {

    return <div className="flex-none px-1">
        { children }
    </div>
}

class Grid extends React.Component {
    static Column = Column;
    static Header = Header;
    static Row = Row;
    static Data = Data;
    static Fragment = Fragment;
    static Controls = Controls;
    static Control = Control;

    constructor(props) {
        super(props);

        this.state = {
            headerfields: Immutable.List([])
        }

        this.setHeaderFields = this.setHeaderFields.bind(this);
    }

    setHeaderFields(fields){
        this.setState({
            headerfields: Immutable.fromJS(fields)
        });
    }

    render() {
        let {children, className, style} = this.props;
        let {headerfields} = this.state;

        return (
            <div className={`grid grid-cols-16 w-full ${className || ''}`} style={style}>
                {React.Children.map(children, (child) => {
                    try{
                        return React.cloneElement(child, {
                            setHeaderFields: this.setHeaderFields,
                            fields: headerfields
                        });
                    }
                    catch(err){
                    }
                })}
            </div>
        );
    }
}

export default Grid;