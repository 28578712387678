import React, { useState, Fragment, useRef } from 'react';
import { Map, List } from 'immutable';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function timeIndexToString(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

const TIMESTEP = 30;

export default function Expandable({ className, children, elementIndex, span, onChange, show24 }) {

    const [randomNumber, forceRerender ] = useState(Math.random().toString());

    const currentElement = useRef(null);
    const dragging = useRef(false);
    const initialY = useRef(0);
    const newIndex = useRef(elementIndex);
    const elementBoundries = useRef(null);

    const resetState = () => {
        // console.log("should have resetted state");
        forceRerender(Math.random().toString());
        dragging.current = false;
        initialY.current = 0;
        newIndex.current = elementIndex;
    } 

    const adjustElement = (offset) => {
        let startIndex = elementIndex % (48 * 30);
        // console.log("offset", offset)
        let boundries = elementBoundries.current;
        // console.log("height", boundries.height);
        let height = boundries.height;
  
        let cells = span;
        let heightPerCell = height / cells;
        let currentHeight = height + offset;
        let currentSpan = Math.round(currentHeight / heightPerCell);
        // console.log("offset", offset);
        let direction = offset < 0 ? -1 : 1;

      

        let newSpan = (Math.round((offset + height) / heightPerCell))
        
        
        
        let maxOffsetUp = (elementIndex % (48 * 30)) * heightPerCell * -1 - (direction == 1 ? height : 0);
        let maxOffsetBottom = heightPerCell * 48 * 30 + maxOffsetUp;;
        
        let newIndex = Math.round(startIndex + (( offset) / heightPerCell));
        let newEndIndex = Math.round(startIndex + currentSpan);

        newIndex = newIndex - (newIndex % TIMESTEP);
        newEndIndex = newEndIndex - (newEndIndex % TIMESTEP);
        if(offset < maxOffsetUp || offset > maxOffsetBottom) {
            if(offset < maxOffsetUp) {
                newIndex = 0;
                newEndIndex = 0;
                offset = maxOffsetUp;
            }
            if(offset > maxOffsetBottom) {
                newIndex = 48 * 30 - (direction == -1 ? span : 0);
                newEndIndex = 48 * 30 - (direction == -1 ? span : 0);
                offset = maxOffsetBottom ;
            }
        }

        let borders = (Math.abs(offset) / heightPerCell) / span;
        // console.log("borders", borders);
        currentElement.current.style.position = "absolute";
        currentElement.current.style.width = Math.round(boundries.width) + "px";
        currentElement.current.style.maxWidth = Math.round(boundries.width) + "px";
        if(direction == -1) {
            currentElement.current.style.top = Math.round(offset - borders) + "px";
            currentElement.current.style.height = Math.round(elementBoundries.current.height + borders + (-1 * offset)) + "px";
        } else {
            currentElement.current.style.height = Math.round(elementBoundries.current.height + borders + offset) + "px";
        }
        let newStart = direction == -1 ? Number(newIndex) : startIndex;
        let newEnd = direction == -1 ? startIndex + span : newEndIndex;
        let startString = timeIndexToString(newStart);
        let endString = timeIndexToString(newEnd);
        currentElement.current.style.backgroundColor = "rgba(255,255,0,0.5)";
        currentElement.current.innerHTML = `${startString} - ${endString == "24:00" ? show24 && endString || "00:00" : endString}`;
        currentElement.current.style.zIndex = 99
        return direction == -1 ? newIndex : newEndIndex;
    }

    const onMouseDown = (e) => {
        elementBoundries.current = currentElement.current.getBoundingClientRect()
       if(!currentElement.current) {
            // console.log("no current element found, ignoring");
            return;
       }
        e.preventDefault(e);
        if(e.button != 0) {
            return;
        }
        dragging.current = true;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
       
    }


    const onMouseUp = (e) => {

        // console.log("onmouse up event");

        if(dragging.current) {
            let currentY = e.movementY;
            initialY.current += Number(currentY);
            let direction = initialY.current < 0 ? -1 : 1;
            let newStartIndex = direction == -1 ? newIndex.current : elementIndex % (48 * 30);
            let newEndIndex = direction == -1 ? (elementIndex % (48 * 30)) + span : newIndex.current;
            if(newEndIndex <= newStartIndex) {
                resetState();

            } else {
                // console.log("want tot trigger onChange", newStartIndex, newEndIndex, initialY.current);
                if(initialY.current != 0) {
                    
                    onChange(newStartIndex, newEndIndex);
                }
                
                resetState();
            }
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);

        } else {
            // console.log("not dragging");
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }
    }

    const onMouseMove = (e) => {
        if(!dragging.current) {
            return;
        }
        let currentY = e.movementY;
        let result = adjustElement(initialY.current + Number(currentY))
        if(result > -1) {
            initialY.current += Number(currentY);
            newIndex.current = result;
        }
        e.preventDefault();
    }



    return <div key={randomNumber}
        // onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        // onMouseMove={onMouseMove}
        className={` cursor-move ${className}`} 
        // onMouseLeave={onMouseLeave}
        ref={currentElement}>
        {children}
        
    </div>
}