import React from 'react';
import ModuleTypes from 'Configuration/ModuleTypes';

import Select from 'Controls/Form/Select';
import Input from 'Controls/Form/Input';

class AddressFieldTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addr : this.props
        }
    }
  
    changeValue(field, value) {
        let {onSave} = this.props;
        onSave(field, parseInt(value)); 
    }

    render() {
        let {addr} = this.props;
        let {editPoint, moduleName} = this.props;

        if (!addr) return (<data></data>);

        let fields = ModuleTypes[addr[1]].pointtypes[addr[2]].fields;

        return (
            <div>
                <div className="grid grid-cols-11 gap-4">
                    <div className="col-span-4">
                        Module Name
                    </div>
                    <div className="col-span-7">
                        {moduleName}
                    </div>
                    <div className="col-span-4 mt-2">
                        Module Type
                    </div>
                    <div className="col-span-7 mt-2">
                        {ModuleTypes[addr[1]].name}
                    </div>


                    <div className="col-span-4 mt-4">
                        Point Type
                    </div>
                    <div className="col-span-5 mt-4">
                        <Select 
                            title="Point Type"
                            value={addr[2]}
                            disabled={true}
                            key={addr[2]}
                            options={
                                ModuleTypes[addr[1]].pointtypes.map((pointtype, index) => {
                                    return ({
                                        value: index,
                                        text: pointtype.name
                                    });
                                })
                            }
                        />
                    </div>


                    <div className="col-span-4">
                        {fields[0].label}
                    </div>
                    <div className="col-span-5">
                        <Input type="number" disabled={editPoint} onChange={this.changeValue.bind(this, 3)} value={addr[3].toString()} />
                    </div>

                {fields[1] &&
                    <div className="col-span-4">
                        {fields[1].label}
                    </div>}
                    {fields[1] &&
                <div className="col-span-5">
                    <Input type="number" disabled={editPoint} onChange={this.changeValue.bind(this, 4)} value={addr[4].toString()} />
                </div>}
 
                {fields[2] &&
                    <div className="col-span-4">
                        {fields[2].label}
                    </div>}
                    {fields[2] &&    
                <div className="col-span-5">
                    <Input type="number" disabled={editPoint} onChange={this.changeValue.bind(this, 5)} value={addr[5].toString()} />
                </div>}

                {fields[3] &&
                    <div className="col-span-4">
                            {fields[3].label}
                        </div>}

                        {fields[3] &&
                        <div className="col-span-5">
                            <Input type="number" disabled={editPoint} onChange={this.changeValue.bind(this, 6)} value={addr[6].toString()} />
                        </div>   }
            </div>
            </div>
        )
    }
};

export default AddressFieldTable;




