import React from 'react';
import Immutable from 'immutable';

import Grid from  'Controls/GridV2';
import Select from 'Controls/Form/SelectSearch';
import Input from 'Controls/Form/Input';

import {searchPoints} from 'BmsView/Customer/Configuration/Actions';
import Swal from 'sweetalert2';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function HistoriesTemplateSetupDialog({ selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <HistoriesTemplateSetupDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

// @Dialog({ width:'2xl'})
class HistoriesTemplateSetupDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        // props.setSaveHandler(this.onSave.bind(this));

        this.state = {
            points: [],
            pointselected: [],
            selectedtemplate: props.selecteditem,
            newitem: Immutable.Map({})
        }
    }

    componentDidMount() {
        let {customer, points, usePortal} = this.props;

        if (usePortal) {
            this.setState({
                points: points
            });
            return;
        } 
        
        const queryObject = {
            historysetup: { $exists: true } , "historysetup.enabled": { $eq: 1 }
        };

        searchPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, queryObject).then((data) => {
            this.setState({
                points: Immutable.fromJS(data.map((point) => {
                    return {
                        address: point.addr.join('-'),
                        name: point.name
                    }
                }))
            })
        });
    }

    componentDidUpdate(oldProps) {
        let {points, usePortal} = this.props;

        if (usePortal && points != oldProps.points){
            return this.setState({
                points: points.filter((point) => {
                    return point.getIn(['historysetup', 'enabled']) == 1;
                }).map((point) => {
                    return Immutable.Map({
                        address: point.get('addr').join('-'),
                        name: point.get('name')
                    })
                })
            });
        }
    }

    addPoint() {
        let {selectedtemplate, newitem} = this.state;

        if (newitem.size == 0) {
            Swal.fire("Error", "Please select point first", "error");
            return;
        }

        if ( !selectedtemplate.get('points')) {
            selectedtemplate = selectedtemplate.set('points', Immutable.List([]));
        }

        selectedtemplate = selectedtemplate.update('points', arr => arr.push(newitem));

        this.setState({
            selectedtemplate:selectedtemplate,
            newitem:Immutable.Map({})
        })
    }

    removePoint(selectedpoint) {
        let {selectedtemplate} = this.state;

        let points = selectedtemplate.get('points').filter((point) => {
            return point != selectedpoint;
        });

        this.setState(({ selectedtemplate }) => ({
            selectedtemplate: selectedtemplate.set('points', points)
        }));
    }

    onSave() {
        let {onSave} = this.props;

        if (onSave) {
            onSave(this.state.selectedtemplate);
        }
    }

    onChange(value) {
        this.setState(({ selectedtemplate }) => ({
            selectedtemplate: selectedtemplate.set('name', value)
        }));
    }

    pointSelected(args) {
        this.setState(({ newitem }) => ({
            newitem: newitem.set('name', args.label.split('(')[0]).set('address', args.value)
        }));
    }

    changeColour(value) {
        this.setState(({ newitem }) => ({
            newitem: newitem.set('colour', value)
        }));
    }

    render() {
        let { points, newitem, selectedtemplate } = this.state;
        let { onClose } = this.props;

        return (
            <DialogContent className="md:w-[70rem]">
                <DialogBody>
                    <div className="w-full">
                        <div className="flex flex-row">
                            <div className="px-2">
                                <Input
                                    type="text"
                                    label="Template Name"
                                    value={selectedtemplate.get('name')}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </div>    
                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-8">Name</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-4 hideMobile">Address</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2">Colour</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2" name="delete"></Grid.Column>
                            </Grid.Header>
                            { (selectedtemplate.get('points') || []).map((point, index) => {
                                return(
                                    <Grid.Row className="hover:bg-green-lighter">
                                        <Grid.Column classes="col-span-16 md:col-span-8">{point.get('name')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4 hideMobile">{point.get('address')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">{point.get('colour')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">
                                            <Button role="danger" onClick={this.removePoint.bind(this, point)}>Remove</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            })}
                        </Grid>
                        <div className="flex flex-col">
                            <div className="grid grid-cols-16">
                                <div className="col-span-16 md:col-span-12 px-2">
                                    <Select
                                        returnObject
                                        value={newitem.get('address')}
                                        onChange={this.pointSelected.bind(this)}
                                        options={(points || []).map((point) => {
                                            return {
                                                value: point.get('address'),
                                                text: `${point.get('name')} (${point.get('address')})`
                                            }
                                        })}
                                    />
                                </div>
                                <div className="col-span-16 md:col-span-2 px-2">
                                    <Select
                                        value={newitem.get('colour')}
                                        onChange={this.changeColour.bind(this)}
                                        options={[
                                            { value: 'red', text: 'Red'},
                                            { value: 'blue', text: 'Blue' },
                                            { value: 'yellow', text: 'Yellow' },
                                            { value: 'green', text: 'Green'},
                                            { value: 'pink', text: 'Pink'},
                                            { value: 'purple', text: 'Purple'},
                                            { value: 'black', text: 'Black'},
                                            { value: 'orange', text: 'Orange'}
                                        ]} /> 
                                </div>
                                <div className="col-span-16 md:col-span-2 px-2 py-2">
                                    <Button role="success" onClick={this.addPoint.bind(this)}>Add point</Button>
                                </div>
                            </div>
                        </div>
                    </div>    
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="seconday" onClick={onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.onSave.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default HistoriesTemplateSetupDialog;
