import { List } from 'immutable';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveUser } from 'BmsView/Users/Actions';
import { Map, fromJS } from 'immutable';
import { login } from 'Actions/Authentication';
import axios from 'axios';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useBMS } from '../BmsView/Context/BMS';

async function saveSystemSettings(systemSettings) {
    await axios.put('/api/system-configuration', systemSettings.toJS());
    return true;
}

async function getSystemSettings() {
    let response = await axios.get('/api/system-configuration');
    return response.data;
}

const SystemSettingsContext = React.createContext({});

export function useSystemSettings() {
    return useContext(SystemSettingsContext);
}



function SystemSettingsProvider({ children }) {

    const [systemSettings, setSystemSettings] = useStateIfMounted(Map());

    const save = async (systemSettings) => {
        return await saveSystemSettings(systemSettings);
    }

    const reset = async () => {
        let data = await getSystemSettings();
        setSystemSettings(fromJS(data));
    }

    useEffect(() => {
        (async () => {
            await reset();
        })();
        return () => {}
    }, []);

    const value = useMemo(() => ({ systemSettings, save, reset }), [systemSettings]);

    return <SystemSettingsContext.Provider value={value}>
        {children}
    </SystemSettingsContext.Provider>
}

export default SystemSettingsProvider