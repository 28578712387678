import React, { useState} from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Checkbox from 'Controls/Form/Checkbox';
import ColorPicker from 'Controls/ColorPicker';
import Immutable from 'immutable';
import Button from 'Controls/Button/UserDefined';

function Text({ onChange, selectedcomponent }) {

    const [currentvalue, setCurrentValue] = useState(Immutable.Map({}));

    const valueChanged = (field, value) => {
        onChange(field, value);
    }

    const currentValueChanged = (field, value) => {
        setCurrentValue((currentvalue) => currentvalue.setIn(field, value));
    }

    const ruleChanged = (field, value) => {
        onChange(field, value);
    }

    const addRule = () => {
        let rules = selectedcomponent.getIn(['configuration', 'rules'], Immutable.List([]));
        rules = rules.push(currentvalue);

        onChange(['configuration', 'rules'], rules);
        setCurrentValue(Immutable.Map({}));
    }

    const removeRule = (index) => {
        let rules = selectedcomponent.getIn(['configuration', 'rules']);
        rules = rules.delete(index);

        onChange(['configuration', 'rules'], rules);
    }

    let backgroundcolor = '#000000';

    if ( selectedcomponent.getIn(['configuration','displaystyle']) == "boxed") {
        backgroundcolor = '#808080';
    }

    return (
        <div className="my-4">
            <div className="grid grid-cols-16">
                <div className="col-span-12 md:col-span-10">
                    <Input
                        label="Text value"
                        value={selectedcomponent.getIn(['configuration', 'value'])}
                        onChange={(v) => valueChanged(['configuration', 'value'], v)}
                    />
                </div>
            </div>
            <div className="grid grid-cols-16 gap-2">
                <div className="col-span-12 md:col-span-3">
                    <Select
                        label="Display Style"
                        value={selectedcomponent.getIn(['configuration','displaystyle'], selectedcomponent.getIn(['component', 'defaultStyle', 'displaystyle'], 'plaintext') + '')}
                        onChange={(v) => valueChanged(['configuration','displaystyle'], v)}
                        options={Immutable.List([{'value':'plaintext', 'text':'Plain Text'}, {'value':'boxed', 'text' :'Boxed'}, {'value':'striped', 'text' :'Striped'},
                            {'value':'button', 'text' :'Button'}])}
                    />    
                </div>
                <div className="col-span-12 md:col-span-2">
                    <Input
                        label="Width"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'width'])}
                        onChange={(v) => valueChanged(['configuration', 'width'], v)}
                    />
                </div>
                <div className="col-span-12 md:col-span-3">
                    <Input
                        label="Rounded Corner"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'rx'])}
                        onChange={(v) => valueChanged(['configuration', 'rx'], v)}
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <Input
                        label="Rotate Angle"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                        onChange={(v) => valueChanged(['configuration', 'rotation'], v)}
                    /> 
                </div>

                <div className="col-span-12 md:col-span-2">
                    <Input
                        label="Box X Offset"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'style', 'boxXOffset'])}
                        onChange={(v) => valueChanged(['configuration', 'style', 'boxXOffset'], v)} />
                </div>

                <div className="col-span-12 md:col-span-2">
                    <Input
                        label="Box Y Offset"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'style', 'boxYOffset'])}
                        onChange={(v) => valueChanged(['configuration', 'style', 'boxYOffset'], v)} />
                </div>
        
            </div>
            <div className="grid grid-cols-16 my-4 gap-2">  
                <div className="col-span-8 md:col-span-3">
                    <Select
                        label="Font Style"
                        type="number"
                        value={selectedcomponent.getIn(['configuration','style', 'fontstyle'], 'Normal') + ''}
                        onChange={(v) => valueChanged(['configuration','style', 'fontstyle'], v)}
                        options={Immutable.List([{'value':'Normal', 'text':'Normal'}, {'value':'Italic', 'text' :'Italic'}, {'value':'Bold', 'text' :'Bold'}])}
                    /> 
                </div>
                <div className="col-span-8 md:col-span-2">
                    <Input
                        showDeleteControl={true}
                        label="Font Size"
                        type="number"
                        value={selectedcomponent.getIn(['configuration','style', 'fontsize'], '') + ''}
                        onChange={(v) => valueChanged(['configuration','style', 'fontsize'], v)}
                    /> 
                </div>
                <div className="col-span-8 md:col-span-2 pt-4">
                    <Checkbox
                        label="Underline"
                        className="mt-3"
                        checked={selectedcomponent.getIn(['configuration', 'underline'])}
                        onChange={(v) => valueChanged(['configuration', 'underline'], v)} />
                </div> 
            </div> 
            <div className="grid grid-cols-16 gap-2">
                <div className="col-span-16">Colours</div>
                <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={(v) => valueChanged(['configuration', 'style', 'color'], v)} /></div>
                <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'], selectedcomponent.getIn(['component', 'defaultStyle', 'background-color']))} onChange={(v) => valueChanged(['configuration', 'style', 'background-color'], v)} /></div>
                <div className="col-span-16 md:col-span-2"><ColorPicker label="Border" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={(v) => valueChanged(['configuration', 'style', 'border-color'], v)} /></div>
                <div className="col-span-16 md:col-span-2"><ColorPicker label="Stripe" value={selectedcomponent.getIn(['configuration', 'style', 'top-border-color'], selectedcomponent.getIn(['component', 'defaultStyle', 'top-border-color']))} onChange={(v) => valueChanged(['configuration', 'style', 'top-border-color'], v)} /></div>
            </div>
            <div className="my-4">
                <div className="grid grid-cols-16">
                    <div className="col-span-12 md:col-span-2">
                        <label className="block label">Value</label>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <label className="block label">Display Text</label>
                    </div>
                    <div className="col-span-12 md:col-span-3">
                        <label className="block label">Action</label>
                    </div>
                </div>
                {selectedcomponent.getIn(['configuration', 'rules'], []).map((rule, index) => {
                    return (
                        <div className="grid grid-cols-16">
                            <div className="col-span-12 md:col-span-2">
                                <Input
                                    onChange={(r) => ruleChanged(['configuration', 'rules', index,  'matchvalue'], r)}
                                    value={rule.get('matchvalue')}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-4 px-2">
                                <Input
                                    onChange={(r) => ruleChanged(['configuration', 'rules', index, 'displaytext'], r)}
                                    value={rule.get('displaytext')}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-3 px-2">
                                <Select
                                    options={[
                                        { value: 'static', text: 'Static' },
                                        { value: 'flashing', text: 'Flashing' },
                                        { value: 'hide', text: 'Hide'}
                                    ]}
                                    onChange={(r) => ruleChanged(['configuration', 'rules', index, 'action'], r)}
                                    value={rule.get('action')}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-2 px-2">
                                <Button className="w-28" role="danger" onClick={() => removeRule(index)}>Remove</Button>
                            </div>
                        </div>
                    )
                })}
                <div className="grid grid-cols-16">
                    <div className="col-span-12 md:col-span-2">
                        <Input
                            onChange={(v) => currentValueChanged(['matchvalue'], v)}
                            value={currentvalue.get('matchvalue')}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-4 px-2">
                        <Input
                            onChange={(v) => currentValueChanged(['displaytext'], v)}
                            value={currentvalue.get('displaytext')}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-3 px-2">
                        <Select
                            options={[
                                { value: 'static', text: 'Static' },
                                { value: 'flashing', text: 'Flashing' },
                                { value: 'hide', text: 'Hide'}
                            ]}
                            onChange={(v) => currentValueChanged(['action'], v)}
                            value={currentvalue.get('action')}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-2 px-2">
                        <Button className="w-28" role="success" onClick={addRule}>Add</Button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Text;

/*

<div className="row">
                    <div className="col-16 my-4">Overrides</div>
                    <Grid>
                        <Grid.Header>
                            <Grid.Column classes="col-16 col-md-2">Value</Grid.Column>
                            <Grid.Column classes="col-16 col-md-2">Text</Grid.Column>
                            <Grid.Column classes="col-16 col-md-2">Type</Grid.Column>
                            <Grid.Column classes="col-16 col-md-2" name="delete" type="complex">
                                <a className="btn btn-success w-100" onClick={this.addOverride.bind(this, Immutable.Map({}))}>Add</a>
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            <Grid.Row>
                                <Grid.Column>0</Grid.Column>
                                <Grid.Column>My State</Grid.Column>
                                <Grid.Column>Flashing</Grid.Column>
                                <Grid.Column>
                                    <Button className="btn btn-danger">Remove</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Data>
                    </Grid>
                </div>

                */