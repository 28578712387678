import React, {useState, useEffect} from 'react';
import getPointReference from '../../PointInfo';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PointList = ({points}) => {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 bg-white px-4  sm:px-6">
          <h1 className="mb-2 text-lg leading-6 font-medium text-gray-900">Points</h1>
        </div>
        <div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
          <table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
            <thead className="bg-blue-500">
              <tr>
                <th
                  scope="col"
                  className="sticky w-1/6 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Name
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {(points || []).map((point, index) => (
                <tr key={point.get('_id')}>
                  <td
                    className={classNames(
                      index !== points.length - 1 ? 'border-b border-gray-200' : '',
                        'whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                    )}
                  >
                    {getPointReference(point)}
                  </td>
                  <td
                    className={classNames(
                      index !== points.length - 1 ? 'border-b border-gray-200' : '',
                        'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                    )}
                  >
                    {point.get('name')}
                  </td>
                </tr>
              ))}  
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default PointList;
