import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import Swal from 'sweetalert2';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const NetworkList = ({ customer, selectedsubsystem, lans, selectedlan, setSelectedLan, scan, updateItem, deleteItem }) => {
	const [selecteditem, setSelectedItem] = useState(undefined);

	const addItem = () => {
		setSelectedItem(Immutable.Map({ 'subsystem': selectedsubsystem }));
	}

	const buttonClick = (task) => {
		if (selectedlan == undefined) {
			Swal.fire('Error', 'Lan not selected', "error");
			return;
		}

		task();
	}

	const editSettings = () => {
		setSelectedItem(selectedlan);
	}

	const clearDialog = () => {
		setSelectedItem(undefined);
	}

	return (
		<div>
			<div>
				<div className="border-b border-gray-200 bg-white mt-3 sm:px-6">
					<div className="-mt-4 flex justify-between sm:flex-nowrap">
						<div>
							<h1 className="text-lg leading-6 font-medium text-gray-900">Lans</h1>
						</div>
						<div className="flex flex-shrink-0">
							<button
								type="button"
								className="whitespace-nowrap relative inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								onClick={() => scan('scanforlans')}
							>Start Lan Discovery
							</button>
							<img src="/images/scan.svg" alt="Scan" onClick={() => buttonClick(scan('scanforoutstations'))} data-bs-toggle="tooltip" title="Scan for outstations" />
							<img src="/images/delete.svg" alt="Del" onClick={() => buttonClick(deleteItem('trendlans'))} data-bs-toggle="tooltip" title="Delete" />
							<img src="/images/settings.svg" alt="Set" onClick={() => buttonClick(editSettings)} data-bs-toggle="tooltip" title="Settings" />
							<img src="/images/add.svg" alt="Add" onClick={() => addItem()} data-bs-toggle="tooltip" title="Add" />
						</div>
					</div>
				</div>
			</div>
			<div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
				<table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
					<thead className="bg-blue-500">
						<tr>
							<th
								scope="col"
								className="sticky w-1/5 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
							>
								No
							</th>
							<th
								scope="col"
								className="sticky w-4/5 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
							>
								Name
							</th>
						</tr>
					</thead>
					<tbody className="bg-white">
						{(lans || []).filter((lan) => (
							lan.get('subsystem') == selectedsubsystem)).map((lan, index) => (

								<tr key={lan.get('_id')} onClick={() => setSelectedLan(lan)} className={selectedlan && (lan.get('_id') == selectedlan.get('_id')) ? "relative px-1 bg-green-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500" :
									"relative px-1 hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"}>
									<td
										className={classNames(
											index !== lans.length - 1 ? 'border-b border-gray-200' : '',
											'whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
										)}
									>
										{lan.get('number')}
									</td>
									<td
										className={classNames(
											index !== lans.length - 1 ? 'border-b border-gray-200' : '',
											'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
										)}
									>
										{lan.get('name')}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default NetworkList;
