export default   {

    graphics: {
        fontSize: 16,
        box: {
            xOffset: 8,
            yOffset: 8,
            stripeYSize: 6
        }
    }



}