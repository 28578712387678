import React, {useState} from 'react';
import Immutable from 'immutable';
import ColorPicker from 'Controls/ColorPicker';
import Swal from 'sweetalert2';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';

const StatusButton = ({selectedcomponent, onChange}) => 
{
    let [currentValue, setCurrentValue] = useState(Immutable.Map({}));

    const currentValueChanged = (field, value) => {
        let v = currentValue;
        // console.log('value ', v);
        v = v.setIn(field, value);
        // console.log('value ', v);
        setCurrentValue(v);

/*


        setCurrentValue(({ currentvalue }) => ({
            currentvalue: currentvalue.setIn(field, value)
        })); */
    } 

    const addRule = () => {
        if (!currentValue.get('matchvalue')) {
            Swal.fire("Error", "A value must be input", "error");
            return;
        }

        let rules = selectedcomponent.getIn(['configuration', 'rules'], Immutable.List([]));
        rules = rules.push(currentValue);

        onChange(['configuration', 'rules'], rules);
        setCurrentValue(Immutable.Map({}));
    }

    const removeRule = (index) => {
        let rules = selectedcomponent.getIn(['configuration', 'rules']);
        rules = rules.delete(index);

        onChange(['configuration', 'rules'], rules);
    }

    const ruleChanged = (field, value) => {
        onChange(field, value);
    }


    return (
        <div>
            <div className="grid grid-cols-16 gap-2 mt-4">
                <div className="col-span-12 md:col-span-2">
                    <Input
                        label="Radius"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'radius'], 50)}
                        onChange={(value) => onChange(['configuration', 'radius'], value)}
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <Input
                        label="Button Border"
                        type="number"
                        value={selectedcomponent.getIn(['configuration', 'buttonborderwidth'], 2)}
                        onChange={(value) => onChange(['configuration', 'buttonborderwidth'], value)}
                    />
                </div>
            </div>   
            <div className="my-4">
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-2">
                        <label className="block label">Value</label>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <label className="block label">Button Colour</label>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <label className="block label">Border Colour</label>
                    </div>
                    <div className="col-span-12 md:col-span-3">
                        <label className="block label">Action</label>
                    </div>
                </div>
            </div>
            {selectedcomponent.getIn(['configuration', 'rules'], []).map((rule, index) => {
                return (
                    <div className="grid grid-cols-16 gap-2 my-1" key={index}>
                        <div className="col-span-12 md:col-span-2">
                            <Input
                                onChange={(value) => ruleChanged(['configuration', 'rules', index,  'matchvalue'], value)}
                                value={rule.get('matchvalue')}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <ColorPicker value={rule.getIn(['button-color'])} onChange={(value) => ruleChanged(['configuration', 'rules', index, 'button-color'], value)} />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <ColorPicker value={rule.getIn(['button-border-color'])} onChange={(value) => ruleChanged(['configuration', 'rules', index, 'button-border-color'], value)} />
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <Select
                                options={[
                                    { value: 'static', text: 'Static' },
                                    { value: 'flashing', text: 'Flashing' }
                                ]}
                                onChange={(value) => ruleChanged(['configuration', 'rules', index, 'action'], value)}
                                value={rule.get('action')}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <Button role="danger" className="w-100" onClick={() => removeRule(index)}>Remove</Button>
                        </div>
                    </div>
                )
            })}
            <div className="grid grid-cols-16 gap-2 my-1">
                <div className="col-span-12 md:col-span-2">
                    <Input
                        onChange={(value) => currentValueChanged(['matchvalue'], value)}
                        value={currentValue.get('matchvalue')}
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <ColorPicker value={currentValue.getIn(['button-color'])} onChange={(value) => currentValueChanged(['button-color'], value)} />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <ColorPicker value={currentValue.getIn(['button-border-color'])} onChange={(value) => currentValueChanged(['button-border-color'], value)} />
                </div>
                <div className="col-span-12 md:col-span-3">
                    <Select
                        options={[
                            { value: 'static', text: 'Static' },
                            { value: 'flashing', text: 'Flashing' }
                        ]}
                        onChange={(value) => currentValueChanged(['action'], value)}
                        value={currentValue.get('action')}
                    />
                </div>
                <div className="col-span-12 md:col-span-2 px-2">
                    <Button role="success" className="w-100" onClick={() => addRule()}>Add</Button>
                </div>
            </div>
        </div>)
}

export default StatusButton;