import React from 'react';
import ColorPicker from 'Controls/ColorPicker';
import Input from 'Controls/Form/Input';
import SelectSearch from 'Controls/Form/SelectSearch';

class DateTimeText extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;

        if (field[2] == "fontstyle" && value == 'bold') 
        {
            onChange(['configuration','style', 'fontweight'], 'bold');
            onChange(field, 'normal');
            return;
        }

        if (field[2] == "fontstyle" && value == 'bold italic')
        {
            onChange(['configuration','style', 'fontweight'], 'bold');
            onChange(field, 'italic');
            return;
        }

        onChange(field, value);
    }

    render() {
        let { selectedcomponent } = this.props;
        let fontstyle = selectedcomponent.getIn(['configuration','style', 'fontstyle'], 'normal');
        let fontweight = selectedcomponent.getIn(['configuration','style', 'fontweight'], 'normal');

        if (fontweight && fontweight == 'bold') {
            if (fontstyle == 'normal') fontstyle = 'bold';
            else fontstyle = 'bold italic';
        }

        return (
            <React.Fragment>
                <SelectSearch 
                    label="Format"
                    value={selectedcomponent.getIn(['configuration', 'format'], 'DD/MM/YYYY HH:mm')}
                    onChange={this.valueChanged.bind(this, ['configuration', 'format'])}
                    options={[{'value':'DD/MM/YYYY HH:mm', 'text':'01/01/2020 13:00'},
                             {'value':'DD/MM/YYYY H:mm a', 'text':'01/01/2020 1:00 am'},
                             {'value':'DD MMM YYYY HH:mm', 'text':'01 Jan 2020 13:00'}]}
                />    
                <SelectSearch
                    label="Font Style"
                    value={fontstyle}
                    onChange={this.valueChanged.bind(this, ['configuration','style', 'fontstyle'])}
                    options={[{'value':'normal', 'text':'Normal'}, {'value':'italic', 'text' :'Italic'}, {'value':'bold', 'text' :'Bold'}, {'value':'bold italic', 'text' :'Bold Italic'}]}
                /> 
                <Input
                    showDeleteControl={true}
                    label="Font Size"
                    type="number"
                    value={selectedcomponent.getIn(['configuration', 'style', 'fontsize'], '') + ''}
                    onChange={this.valueChanged.bind(this, ['configuration', 'style', 'fontsize'])}
                /> 
                <ColorPicker label="Background color" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'])} />
                <ColorPicker label="Text color" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'])} />
                <ColorPicker label="Border color" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'border-color'])} />
            </React.Fragment>
        )
    }
}

export default DateTimeText;