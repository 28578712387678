
import axios from 'axios';

function getImageMeta(imageURL) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height
            });
        }
        img.src = imageURL;
    });

}  



class ImageCache {

    constructor() {
        this.viewCache = {};
        this.cachedObjects = [];
        this.pendingPaths = {};
    }

    async getImage(path) {
        if(this.viewCache[path]) {
            return this.viewCache[path];
        }
        if(this.pendingPaths[path]) {
            return await this.pendingPaths[path];
        }

        let response = await axios.get(path, { responseType: 'blob' }) // Change this to the path of your PNG image
        const imgURL = URL.createObjectURL(response.data);
        const imageData = await getImageMeta(imgURL);

        this.cache(path, { imgURL, imageData });
        return this.viewCache[path];
    }

    cache(path, image) {
        // console.log("18-03 ComponentCache: cache save performed");
        this.viewCache[path] = image;
        this.cachedObjects.push(path);
        if(this.cachedObjects.length > 150) {
            let oldPath = this.cachedObjects.shift();
            delete this.viewCache[oldPath];
        }
      
    }


}

export default new ImageCache();