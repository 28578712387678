import { List } from 'immutable';
import { useArea } from "../../../../../Context/Area";
import { useClipboard } from "../../../../../Context/Clipboard";

export default function useAreaCopy() {
    const { layers } = useArea();
    const { setClipboard } = useClipboard();

    const copyCurrentArea = () => {
        let copies = List();
        layers.forEach((layer) => {
            let nodes = layer.get('nodes');
            nodes.forEach((node) => {
                copies = copies.push(node);
            })
        })
        let jsCopies = copies.toJS();
        setClipboard({ type: 'complete-area-nodes', data: jsCopies })
        console.log("should have set the clipboard");
    }

    return {
        copyCurrentArea
    }
}
