import { useMemo, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {svgComponents, svgComponentConfigurations } from './Utils';
import { convertNodeTypeLabelToTypeText, convertNodeTypeStripedTextToTypeText, transformXYOnElement } from './Utils';
import { useCustomerSettings } from '../../../../../Context/CustomerSettings';
import { useUserSettings } from '../../../../../Context/UserSettings';
import Immutable, { Map } from 'immutable';
import * as d3 from 'd3';
import { useBMS } from 'BmsView/Context/BMS';

import axios from "axios";



export default function SVGComponentCompound({ node, editmode, onNodeChange, selection, selectionmode,
  onShowContextMenu, onMultiDragFinished, layerIndex, setMouseMovement, toggleSelection, toggleSingleSelection
, nodeIndex, onNodeConfiguration, scale, mouseMovement, onNodeMount }) {

    const compoundRef = useRef(null);
    const componentCacheRef = useRef(null);
    const componentHandles = useRef(null);
    const selectionRef = useRef(null);
    const dragRef = useRef({ x: 0, y: 0 });


    const {userSettings} = useUserSettings();
    const {customerSettings} = useCustomerSettings();

    const componentSelectionBoxConfiguration = svgComponentConfigurations[node.getIn(['component', 'type'])]

    const helpers = useRef(null);

    helpers.current = {
        getCustomerSettings: () => {
            return customerSettings;
        },
        getUserSettings: () => {
            return userSettings;
        }
    }

    useEffect(() => {
        let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
        if(!compoundRef.current) return;
        if(!mouseMovement || !isSelected) return;
        let dx = mouseMovement.x;
        let dy = mouseMovement.y;
        let _dx = dx < 0 ? (dx - (1 - scale) * dx) : (dx + (1 - scale) * dx);
        let _dy = dy < 0 ? (dy - (1 - scale) * dy) : (dy + (1 - scale) * dy);        
        let subjectElement = d3.select(compoundRef.current);
        // subjectElement = handleRef.current.getElement();
        transformXYOnElement(subjectElement, _dx, _dy);
    }, [mouseMovement.x, mouseMovement.y])

    const onSelection = (_node, shiftKeyPressed) => {
        // console.log("onSelection");
        toggleSelection(node.get('id'), shiftKeyPressed);
    }

    const onSingleSelection = (_node, shiftKeyPressed) => {
        // console.log("onSinleSelection");
        toggleSingleSelection(node.get('id'), shiftKeyPressed);
    }

    helpers.current.onSelection = onSelection;
    helpers.current.onSingleSelection = onSingleSelection;

    const getCustomerSettings = (key) => {
        return (helpers.current.getCustomerSettings() || Map()).get(key);
    }

    const getUserSettings = (key) => {
        return (helpers.current.getUserSettings() || Map()).get(key);
    }

    // const customer = useSelector(state => state.get('customer'));
    // const site = useSelector(state => state.get('site'));
    const {customer } = useBMS();
    const { site } = useBMS();

    const siteBasePath = useMemo(() => `/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}`, [customer, site]);

    const temporaryHideSelectionBox = (show = false) => {
        let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
        if(isSelected && !selectionmode && selectionRef.current) {
            if(show) {
                selectionRef.current.style.display = 'block';
            } else {
                selectionRef.current.style.display = 'none';
            }
        }
    }

    helpers.current.temporaryHideSelectionBox = temporaryHideSelectionBox;

    const onSingleDrag = (e, element) => {
        let subjectElement = d3.select(element);
        let dx = e.dx;
        let dy = e.dy;
        let { x, y } = dragRef.current;
        dragRef.current = { x: dx + x , y: dy + y }
        transformXYOnElement(subjectElement, dx, dy);
    }

    const onMultiDrag = ({ dx, dy }) => {
        let { x, y } = dragRef.current;
        dragRef.current = { x: dx + x , y: dy + y }
        setMouseMovement(({ x, y }) => ( { x: dx, y:dy} ))
        
    }

    const onSingleDragEnd = (e,element) => {
        // console.log("dragged ", element);
        helpers.current.temporaryHideSelectionBox(true);
        let { x: dx, y: dy } = dragRef.current;
        if(dx == 0 && dy == 0) return;
        let changedNode = node.updateIn(['position', 'x'], 0, (x) => x + dx ).updateIn(['position', 'y'], 0, y => y + dy);
        onNodeChange(layerIndex, changedNode);
        dragRef.current = { x: 0, y: 0 }
    }

    const onMultiDragEnd = ({ dx, dy, sourceEvent: { offsetX, offsetY} }) => {
        let { x, y } = dragRef.current;
        onMultiDragFinished(x, y);
        dragRef.current = { x: 0, y: 0 }

    }

   


    useEffect(() => {

        if(compoundRef.current == null) return;
        if(editmode) {
            d3.select(compoundRef.current)
            .attr('x', node.getIn(['position', 'x'], 0))
            .attr('y', node.getIn(['position', 'y'], 0))
            .attr('transform', `matrix(${node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${node.getIn(['configuration', 'scale'], 100.0) / 100},${node.getIn(['position', 'x'], 0)},${node.getIn(['position', 'y'], 0)})rotate(${node.getIn(['configuration', 'rotation'], 0)})`)
            .on('contextmenu', (evt) => {
                if(!selectionmode) {
                    onShowContextMenu(evt, node, nodeIndex);
                }
            })
            .on('.drag', null)
            .call(d3.drag().on('start', function () {
                if(!selectionmode) {
                   
                    d3.select(this).raise();
                    helpers.current.temporaryHideSelectionBox();
                }
            }).on('drag', function (evt) {
                if(selectionmode) {
                    onMultiDrag(evt, this);
                } else {
                    onSingleDrag(evt, this);
                }
            }).on('end', function (evt) {
                if(selectionmode) {
                    onMultiDragEnd(evt, this);
                } else {
                    onSingleDragEnd(evt, this);
                }
            }))
            .on('click', (evt) => {
                if(selectionmode) {
                    helpers.current.onSelection(node, evt.shiftKey);
                    // onSelection(node, evt.shiftKey);
                    evt.preventDefault();
                    evt.stopPropagation();
                } else {
                    helpers.current.onSingleSelection(node, evt.shiftKey);
                    // onSingleSelection(node, evt.shiftKey);
                    evt.preventDefault();
                    evt.stopPropagation();
                }
            })
            
            let nodeX = node.getIn(['position', 'x']);
            let nodeY = node.getIn(['position', 'y']);
            let { width, height, y, x } = compoundRef.current.getBBox({ stroke: true });
            let bound = compoundRef.current.getBoundingClientRect();
            onNodeMount(node.get('id'), { bound: bound, width, height, y, x, positionX: nodeX, positionY: nodeY });
        
        } else {
            d3.select(compoundRef.current)
            .attr('x', node.getIn(['position', 'x'], 0))
            .attr('y', node.getIn(['position', 'y'], 0))
            .attr('transform', `matrix(${node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${node.getIn(['configuration', 'scale'], 100.0) / 100},${node.getIn(['position', 'x'], 0)},${node.getIn(['position', 'y'], 0)})rotate(${node.getIn(['configuration', 'rotation'], 0)})`)
            
            .on('click', (evt) => {
                onNodeConfiguration(nodeIndex, node);
            }).on(".drag", null);
        }

    },[editmode, selectionmode, node])

    useEffect(() => {
        
        let path = undefined;
        if (node.getIn(['component', 'name']).indexOf('~') == 0) {
            path = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.json`;
        } else {
            path = `/files/editor/components_${node.getIn(['component', 'name'])}.json`;
        }
        let abortController = new AbortController();
        if(componentCacheRef.current && componentCacheRef.current[path]) {

        } else {
            (async () => {
                try {
                    let response = await axios.get(path, { signal: abortController.signal });
                    if(componentCacheRef.current == null) {
                        componentCacheRef.current = {};
                    }
                    componentCacheRef.current[path] = Immutable.fromJS(response.data);
                    if(componentHandles.current == null) {
                        componentHandles.current = [];
                    }
                    for(let i = 0; i < componentCacheRef.current[path].get('nodes').size; i++) {
                        let handle = await createElement(componentCacheRef.current[path].getIn(['nodes',i]), {aborted: false});
                        componentHandles.current.push(handle);
                    }

                    // console.log("24-08 response", response);
                } catch (error) {
                    // console.log("24-08 error", error);
                }
    
            })()
        }
        // console.log("24-08 path", path);
        return () => {
            abortController.abort();
            
        }
        
    }, [node]);

    useEffect(() => {
        drawSelectionOnElement();
    }, [selection, selection.selectionIds, selection.singleSelection])

    const drawSelectionOnElement = () => {
        let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
        if (isSelected && !selectionmode) {
            compoundRef.current.classList.add('node-selected');
            
            if(!selectionmode && componentSelectionBoxConfiguration) {
                let {x,y,width,height} = compoundRef.current.getBoundingClientRect();
                d3.select(selectionRef.current)
                .style('display', 'block')
                .style('top', (y - 10) + 'px')
                .style('left', (x - 10) + 'px')
                .style('width', (width + 20) + 'px')
                .style('height', (height + 20) + 'px')
            }
        } else if(isSelected && selectionmode) {
            compoundRef.current.classList.add('node-selected');
        } else {
            if(compoundRef.current.classList.contains('node-selected')) {
                compoundRef.current.classList.remove('node-selected');
                if(selectionRef.current) {
                    d3.select(selectionRef.current)
                        .style('display', 'none')
                }
            }
            
        }
    }

    const createElement = async (elementNode, abortSignal) => {
        let handle = null;
        let svgComponent = svgComponents[elementNode.getIn(['component', 'type'])];
        elementNode = convertNodeTypeLabelToTypeText(elementNode);
        elementNode = convertNodeTypeStripedTextToTypeText(elementNode);
        let path = undefined;
        if (elementNode.getIn(['component', 'name']).indexOf('~') == 0) {
            path = `${siteBasePath}_components_${elementNode.getIn(['component', 'name']).replace('~', '')}.svg`;
        }
        handle = await svgComponent({
            getCustomerSettings: getCustomerSettings,
            getUserSettings: getUserSettings,
            node: elementNode,
            target: compoundRef.current,
            index: null,
            editmode: editmode,
            tooltip: null
        }, path);
        if(abortSignal.aborted) {
            handle.remove();
        } 
        return handle;
    }

    // will almost be like a normal component
    
    // will be loaded inside the SVGLayer component

    // will initialially not support resizing, due to complexity?

    // only top level point subscriptions will be supported
    // console.log("24-08 rendering component compound", node);
    

    return <g ref={compoundRef}>

    </g>
}