import React, {useState} from 'react';
import Input from 'Controls/Form/Input';
import Checkbox from 'Controls/Form/Checkbox';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';
import ModuleTypes from 'Configuration/ModuleTypes';
import AddressFieldTable from './AddressFieldTable';
import EngUnit from './EngUnit';

const PointDetails = ({pointconfiguration, selectedsubsystem, changedInput, changeEngUnitType, changeMultistate, editPoint, engunitlist, engUnitChange, states, incrementMultistate, multistate}) => {

    let modbusregisterformat = ['int16', 'int32', 'int64', 'float32', 'schneiderpf'];
    
    let [change, setChange] = useState(false);
    let [engUnitIndex, setChangeEngUnitIndex] = useState(undefined);

    let addr = pointconfiguration.get('addr').toArray();
    let engunittype = ModuleTypes[addr[1]].pointtypes[addr[2]].engunit;

    if ( (addr[1] == 5 && (addr[2] == 5 || addr[2] == 11)) && pointconfiguration.getIn(['engunits', 1])) engunittype = 'digital';

    return(
        <div className="flex flex-col md:flex-row px-8 py-4 min-h-full">
            <div className="flex flex-col basis-5/12 m-1">
            <AddressFieldTable addr={addr} onSave={(field, value) => changedInput(['addr',field], parseInt, value)} moduleName={selectedsubsystem.get(0).get('name')} editPoint={editPoint}/>
            </div>

            <div className="flex flex-col basis-7/12 m-1">
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-3">
                        Name
                    </div>    
                    <div className="col-span-8">
                        <Input onChange={(value) => changedInput(['name'], undefined, value)} value={pointconfiguration.get('name')} />
                    </div>
                
                { !(addr[1] == 5 && addr[2] == 13) && <React.Fragment>
                    <div className="col-span-3">
                        Engineering Units
                    </div>
                    <div className="col-span-9"/>
                    {pointconfiguration.get('engunits' || []).map((engunit, index) => {
                            return (
                                <EngUnit key={index} engunitlist={engunitlist[engunittype].engunits}
                                    changedInput={(value) => changedInput(['engunits', index], undefined, value)}
                                    engunit={engunit}/>);
                        }) 
                    }
                </React.Fragment>}
                {multistate && engunittype == 'analog' &&
                    <React.Fragment>
                        <div className="col-span-3">    
                            <Button role="success" onClick={() => incrementMultistate(1)}>Add</Button>
                        </div>  
                        <div className="col-span-9"/>
                    </React.Fragment>}  
                {engunittype == 'analog' && !multistate &&
                    <React.Fragment>
                        <div className="col-span-3">
                            Decimal Places
                        </div>   
                        <div className="col-span-2">
                            <Input type="number" onChange={(value) => changedInput(['numdecplaces'], parseInt, value)} value={pointconfiguration.get('numdecplaces', 1)} />
                        </div>
                        <div className="col-span-5"/>
                    </React.Fragment>}
                {(addr[1] == 5 && (addr[2] == 5 || addr[2] == 11) &&
                    <div className="col-span-3">
                        <Checkbox label="Digital"
                            checked={(pointconfiguration.getIn(['engunits', 1]) != undefined)}
                            onChange={(value) => changeEngUnitType(value)} />
                    </div>)}
                {(addr[1] == 5 && addr[2] == 3) &&
                    <div className="col-span-4">
                        <Checkbox label="Multistate"
                            checked={multistate}
                            onChange={(value) => changeMultistate(value)} />
                    </div>}
                {(addr[1] == 9 && (addr[2] == 4 || addr[2] == 5)) &&
                    <div className="col-span-4">
                        <Checkbox label="Invert Digital"
                            checked={pointconfiguration.get('reverse', false)}
                            onChange={(value) => changedInput(['reverse'], Number, value)} />
                    </div>}
                </div>
                { addr[1] == 14 && (addr[2] > 2) &&
                    <div className="row">
                        <div className="col-12 col-md-4 mt-2">
                            Type
                        </div> 
                        <div className="col-12 col-md-4">
                            <Select
                                value={pointconfiguration.getIn(['modbusregisterformat'], 1)}
                                onChange={(value) => changedInput(['modbusregisterformat'], undefined, value)}
                                options={modbusregisterformat.map((type, index) => {
                                    return {
                                        value:type,
                                        text:type
                                    }
                                })}
                            />
                        </div> 
                        <div className="col-12 col-md-2 mt-2">
                            Scaling
                        </div> 
                        <div className="col-12 col-md-4">
                            <Input onChange={(value) => changedInput(['scaling'], undefined, value)} value={pointconfiguration.get('scaling')} />
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default PointDetails;