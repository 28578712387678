import { useEffect, useState } from 'react';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { useNotifications } from './Context/Notifications';
import Button from 'Controls/Button/UserDefined';

export default function SystemNotifications() {

    const { notifications } = useNotifications();
    const [showNotifications, setShowNotifications] = useState(false);

    useEffect(() => {

        if (notifications && notifications.size > 0) {
            setShowNotifications(true);
        }


    }, [notifications]);

    const hideNotifications = () => {
        setShowNotifications(false);
    }

    return (
        <Dialog opened={showNotifications} onClose={hideNotifications} >
            <DialogHeader>
                <DialogTitle>System Notifications</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <DialogContent>
                    <div className="">
                        {notifications && notifications.map((notification, index) => {
                            return <div className="first:border-none first:pt-0 border-t py-2" key={index}>
                                <h2 className="text-xl">{notification.get('header')}</h2>
                                {notification.get('body')}
                            </div>
                        })}
                    </div>
                </DialogContent>
            </DialogBody>
            <DialogFooter>
                <Button role="close" className="" onClick={() => hideNotifications()}>Close</Button>
            </DialogFooter>
        </Dialog>
    )


}