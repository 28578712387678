import React, {useState} from 'react';
import Immutable from 'immutable';
import { TrashIcon, Cog6ToothIcon, SignalIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import DeviceDialog from './DeviceDialog';
import Grid from 'Controls/GridV2';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ControllerList = ({customer, selectedSubsystem, modbustype, controllers, selectedcontroller, setCurrentController, scan, saveItem, updateItem, deleteItem}) => {
    const [selecteditem, setSelectedItem] = useState(undefined);

    const addItem = () => {
      setSelectedItem(Immutable.Map({'subsystem':selectedSubsystem, 'identifier':'New Device', 'devicetype':'Generic Modbus', 'slaveaddress':1}));
    }

    const editSettings = () => {
      setSelectedItem(selectedcontroller);
    }

    const clearDialog = () => {
      setSelectedItem(undefined);
    } 

    let gridFieldsIP = [
      { value: 'identifier', name: 'Name', width: 'w-2/6' },
      { value: 'deviceid', name: 'Index', width: 'w-1/6' },
      { value: 'slaveaddress', name: 'Addr', width: 'w-1/6' },
      { value: 'devicetype', name: 'Type', width: 'w-1/6' },
      { value: 'ipaddress', name: 'IP Addr', width: 'w-1/6' }
  ];

  let gridFieldsSerial = [
      { value: 'identifier', name: 'Name', width: 'w-2/6' },
      { value: 'deviceid', name: 'Index', width: 'w-1/6' },
      { value: 'slaveaddress', name: 'Addr', width: 'w-1/6' },
      { value: 'serialportnumber', name: 'Port', width: 'w-1/6' },
      { value: 'devicetype', name: 'Type', width: 'w-1/6' }
  ];

    return (
      <div>
        {selecteditem && <DeviceDialog customer={customer} selectedItem={selecteditem} selectedSubsystem={selectedSubsystem} modbustype={modbustype} selectedDevice={selectedcontroller} saveItem={(item) => saveItem(item)} onClose={() => clearDialog()}></DeviceDialog> }
          <div>
            <div className="border-b border-gray-200 bg-white px-2">
              <div className=" flex justify-between sm:flex-nowrap pb-2">
                <div>
                  <h1 className="text-lg leading-6 font-medium text-gray-900">Controllers</h1>
                </div>
                <div className="flex flex-shrink-0 text-right">
                  <PlusCircleIcon className="h-6 w-6 text-gray-700 cursor-pointer" onClick={() => addItem()} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
          <table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
            <thead className="bg-blue-500">
              <tr>
                {modbustype ? gridFieldsSerial.map((field, index) => {
                  return (
                    <th
                      scope="col"
                      className="sticky w-2/12 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >{field.name}</th>
                  )}) : gridFieldsIP.map((field) => {
                  return (
                    <th
                      scope="col"
                      className="sticky w-2/12 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >{field.name}</th>
                  )
                  })}  
                 <th className="sticky w-5/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                 </th>
              </tr>
            </thead>
					<tbody className="bg-white">
            {(controllers || []).filter((_controller) => {
              return (_controller.get('subsystem') == selectedSubsystem)}).map((controller, index) => (
                <tr key={controller.get('_id')} onClick={() => setCurrentController(controller)} className= {selectedcontroller && (controller.get('_id') == selectedcontroller.get('_id')) ? "relative bg-green-100 focus-within:ring-2 focus-within:ring-inset group focus-within:ring-indigo-500":
                  "relative hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset group focus-within:ring-indigo-500"}>
                  {modbustype ? gridFieldsSerial.map((field) => {
                    return (
                      <td
                        className={classNames(
                          index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                        )}
                        >{controller.get(field.value)}
                      </td>)}):
                    gridFieldsIP.map((field) => {
                      return (
                        <td
                          className={classNames(
                            index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                          )}
                          >{controller.get(field.value)}
                        </td>);
                      })}
                      <td className={classNames(
                        'border-b border-gray-200',
                        'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900'
                      )}>
                      <Grid.Controls>
                        <Grid.Control>
                          <SignalIcon title="Scan for points" className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {
                            setCurrentController(controller);
                            scan('scanforpoints')
                          }}/>
                        </Grid.Control>
                        <Grid.Control>
                          <Cog6ToothIcon className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {
                            setCurrentController(controller);
                            editSettings(controller);
                          }} />
                        </Grid.Control>
                        <Grid.Control>
                          <TrashIcon className="cursor-pointer  hidden w-5 text-gray-500 group-hover:block " onClick={() => {
                            setCurrentController(controller);
                            deleteItem('modbusdevices')();
                          }} />
                        </Grid.Control> 
                      </Grid.Controls>
                    </td>
                  </tr>
              ))}  
            </tbody>
				</table>
			</div>
    </div>
  );
}

export default ControllerList;
