import React from 'react';
import Input from 'Controls/Form/Input';

class Hotspot extends React.Component {
    constructor(props) {
        super(props)
    }

    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    render() {
        let { selectedcomponent } = this.props;
        return (
            <React.Fragment>
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Scale %"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'scale'], '') || ''}
                            onChange={this.valueChanged.bind(this, ['configuration', 'scale'])}
                        /> 
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Hotspot;