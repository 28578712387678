import React from 'react';
import Immutable from 'immutable';

import ServiceBus from 'Services/ServiceBus';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';
import Select from 'Controls/Form/Select';
import DailyScheduleDialog from './DailyScheduleDialog';

function WeeklyScheduleDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} >
        <WeeklyScheduleDialogPanel selecteditem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}

class WeeklyScheduleDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.timeProgramNamesHandler = this.timeProgramNamesHandler.bind(this);
        this.timeProgramDailyScheduleNamesHandler = this.timeProgramDailyScheduleNamesHandler.bind(this);
        this.timeProgramWeeklyScheduleIndexesHandler = this.timeProgramWeeklyScheduleIndexesHandler.bind(this);
        this.timeProgramDailyScheduleDetailsHandler = this.timeProgramDailyScheduleDetailsHandler.bind(this);

        this.state = {
            dailyschedulenames: Immutable.List([]),
            weeklyscheduleindexes: Immutable.List([]),
            dailyScheduleDialogOpened: false
        }
    }

    componentDidMount() {
        ServiceBus.on('GET_TIME_PROGRAM_NAMES_RESPONSE', this.timeProgramNamesHandler);
        ServiceBus.on('GET_TIME_PROGRAM_DAILY_SCHEDULE_NAMES_RESPONSE', this.timeProgramDailyScheduleNamesHandler);
        ServiceBus.on('GET_TIME_PROGRAM_WEEKLY_SCHEDULE_INDEXES_RESPONSE', this.timeProgramWeeklyScheduleIndexesHandler);
        ServiceBus.on('GET_TIME_PROGRAM_DAILY_SCHEDULE_DETAILS_RESPONSE', this.timeProgramDailyScheduleDetailsHandler);     

        this.getTimeProgramWeeklyScheduleIndexes();
    }

    componentWillUnmount() {
        ServiceBus.off('GET_TIME_PROGRAM_NAMES_RESPONSE', this.timeProgramNamesHandler);
        ServiceBus.off('GET_TIME_PROGRAM_DAILY_SCHEDULE_NAMES_RESPONSE', this.timeProgramDailyScheduleNamesHandler);
        ServiceBus.off('GET_TIME_PROGRAM_WEEKLY_SCHEDULE_INDEXES_RESPONSE', this.timeProgramWeeklyScheduleIndexesHandler);
        ServiceBus.off('GET_TIME_PROGRAM_DAILY_SCHEDULE_DETAILS_RESPONSE', this.timeProgramDailyScheduleDetailsHandler);
    }

    getTimeProgramNames() {
        let { customer, subsystemNumber, controllerNumber } = this.props;

        let request = {
            m_level: 60,
            m_communicNum: 20,
            m_subsystemNumber: subsystemNumber,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr',
            type: 'GENERIC'
        }

        let comm = {
            m_controllerNumber: controllerNumber
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    getTimeProgramDailyScheduleNames() {
        let { customer, subsystemNumber, controllerNumber, timeProgramIndex } = this.props;

        let request = {
            m_level: 60,
            m_communicNum: 21,
            m_subsystemNumber: subsystemNumber,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr',
            type: 'GENERIC'
        }

        let comm = {
            m_controllerNumber: controllerNumber,
            m_timeProgramIndex: timeProgramIndex
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    getTimeProgramWeeklyScheduleIndexes() {
        let { customer, subsystemNumber, controllerNumber, timeProgramIndex } = this.props;

        let request = {
            m_level: 60,
            m_communicNum: 22,
            m_subsystemNumber: subsystemNumber,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr',
            type: 'GENERIC'
        }

        let comm = {
            m_controllerNumber: controllerNumber,
            m_timeProgramIndex: timeProgramIndex
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    getTimeProgramDailyScheduleDetails() {
        let { customer, subsystemNumber, controllerNumber, timeProgramIndex } = this.props;

        let request = {
            m_level: 60,
            m_communicNum: 23,
            m_subsystemNumber: subsystemNumber,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr',
            type: 'GENERIC'
        }

        let comm = {
            m_controllerNumber: controllerNumber,
            m_timeProgramIndex: timeProgramIndex,
            m_dailyScheduleIndex: 1
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    timeProgramNamesHandler(msg) {
        let events = [];
    }

    timeProgramDailyScheduleNamesHandler(msg) {
        let names = [];

        for (let name = 0; name < msg.m_names.length; name++) {
            names.push(msg.m_names[name]);
        }

        this.setState({
            dailyschedulenames: Immutable.fromJS(names),
            dirty: false
        });

        //this.getTimeProgramWeeklyScheduleIndexes();
    }

    timeProgramWeeklyScheduleIndexesHandler(msg) {
        let indexes = [];

        for (let index = 0; index < msg.m_scheduleIndex.length; index++) {
            indexes.push(msg.m_scheduleIndex[index]);
        }

        this.setState({
            weeklyscheduleindexes: Immutable.fromJS(indexes),
            dirty: false
        });

        this.getTimeProgramDailyScheduleNames();
    }

    timeProgramDailyScheduleDetailsHandler(msg) {
        let events = [];
    }

    save() {
        let { onSave } = this.props;

//        onSave(selectedsubsystem);
    }

    changeSchedule(day) {

    }

    clearSelection() {
        this.setState({
            dailyScheduleDialogOpened: false
        })


    }

    changeDailySchedule() {
        this.setState({
            dailyScheduleDialogOpened: true
        })


    }

    render() {
        let {dailyScheduleDialogOpened, dailyschedulenames, weeklyscheduleindexes} = this.state;
        let {customer, currentuser, timeProgramName, subsystemNumber, controllerNumber, timeProgramIndex} = this.props;

        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        let gridFields = [
            { value: 'number', name: 'Day', width: 'w-g-3' },
            { value: 'name', name: 'Daily Schedule', width: 'w-g-4' }
        ];

        return (
            <div>
                <DailyScheduleDialog 
                    currentuser={currentuser} 
                    customer={customer} 
                    selecteditem={dailyScheduleDialogOpened}
                    timeProgramName={timeProgramName} 
                    subsystemNumber={subsystemNumber}
                    controllerNumber={controllerNumber}
                    timeProgramIndex={timeProgramIndex}
                    onClose={this.clearSelection.bind(this)} /> 
                <div className="row">
                    <div className="col-12 col-md-12">
                        <Input label="Time Program" disabled={true} value={timeProgramName}/>
                    </div>
                </div>
                    { (dailyschedulenames.size == 0 || weeklyscheduleindexes == 0) &&
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <Input value="Please Wait ... Program Loading ..." disabled={true}/>
                            </div>
                        </div>}    
                <Grid>
                    <Grid.Header>
                        {gridFields.map((field, index) => {
                            return <Grid.Column key={index} classes={`${field.width}`}>{field.name}</Grid.Column>
                        })}
                        <Grid.Column classes={`${'w-g-4'}`} name="button"></Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        {days.map((day, index) => {
                            return (
                                <Grid.Row>
                                    <Grid.Column key={index} classes={'w-g-3'}>{day}</Grid.Column>
                                    <Grid.Column key={index} classes={'w-g-3'}>
                                        <Select 
                                            value={dailyschedulenames.get(weeklyscheduleindexes.get(index))}
                                            onChange={this.changeSchedule.bind(this)}
                                            options={ (dailyschedulenames || []).map((name) => {
                                                return {
                                                    text: name,
                                                    value: name
                                                }
                                            })}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="pl-1"><Button role="primary" onClick={this.changeDailySchedule.bind(this)}>Change</Button></div>
                                    </Grid.Column>
                                </Grid.Row>
                            ) 
                        })}
                    </Grid.Data>
                </Grid>    
            </div>
        )
    }
}

export default WeeklyScheduleDialog;