import React from 'react';
import Immutable from 'immutable';

import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function SiteEditDialog({selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <SiteEditDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

// @Dialog({ width: 'md', noContainer: true })
class SiteEditDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        // props.setSaveHandler(this.saveSite.bind(this));
    }

    saveSite() {
        let {onSave} = this.props;
        let {newSite} = this.state;

        onSave(newSite);
    }

    changeSite(site) {
        this.setState({
            newSite: Immutable.fromJS({
                name: site,
                treenodes:null,
                type:"directory"
            })
        });
    }

    render() {
        let {onClose} = this.props;
        return(
            <DialogContent className="md:w-[500px]">
                <DialogHeader>
                    <DialogTitle>Add site</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <Input label="Site name" onChange={this.changeSite.bind(this)} />
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.saveSite.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>
        );
    }
}

export default SiteEditDialog;