import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import DeviceDialog from './DeviceDialog';
import Swal from 'sweetalert2';
import { TrashIcon, Cog6ToothIcon, SignalIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import Grid from 'Controls/GridV2';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const LanList = ({ customer, selectedsubsystem, lans, selectedlan, setSelectedLan, scan, updateItem, deleteItem }) => {
	const [selecteditem, setSelectedItem] = useState(undefined);



	useEffect(() => {
		// console.log('lan ',lans, lans.size, lans.count());

		// console.log('selected lan 1',selectedlan);
		if (selectedlan == undefined && lans.size > 0) {
			// console.log('selectedlan 2', lans.get(0));
			setSelectedLan(lans.get(0));
		}	
		
	}, [lans]);

	const addItem = () => {
		setSelectedItem(Immutable.Map({ 'subsystem': selectedsubsystem }));
	}

	const buttonClick = (task) => {
		if (selectedlan == undefined) {
			Swal.fire('Error', 'Lan not selected', "error");
			return;
		}

		task();
	}

	const editSettings = (selectedlan) => {
		setSelectedItem(selectedlan);
	}

	const clearDialog = () => {
		setSelectedItem(undefined);
	}

	return (
		<div>
			{selecteditem &&
				<DeviceDialog customer={customer} selectedItem={selecteditem} type={'trendlans'} saveItem={(item) => updateItem('trendlans', item)} onClose={() => clearDialog()}></DeviceDialog>}
			<div>
				<div className="border-b border-gray-200 bg-white px-2">
					<div className=" flex justify-between sm:flex-nowrap pb-2">
						<div>
							<h1 className="text-lg leading-6 font-medium text-gray-900">Lans</h1>
						</div>
						<div className="flex flex-shrink-0">
							<a className="pl-2 cursor-pointer text-blue" onClick={() => scan('scanforlans')}>Start Lan Discovery</a>
							<div className="pl-4">
								<PlusCircleIcon className="h-6 w-6 text-gray-700 cursor-pointer" onClick={() => addItem()} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
				<table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
					<thead className="bg-blue-500">
						<tr>
							<th
								scope="col"
								className="w-min sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 px-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
							>
								No
							</th>
							<th
								scope="col"
								className="sticky w-4/5 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
							>
								Name
							</th>
							<th className="sticky w-4/5 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">

							</th>
						</tr>
					</thead>
					<tbody className="bg-white">
						{(lans || []).sort((a,b) => a.get('number') - b.get('number')).filter((lan) => (
							lan.get('subsystem') == selectedsubsystem)).map((lan, index) => (

								<tr key={lan.get('_id')} onClick={() => setSelectedLan(lan)} className={selectedlan && (lan.get('_id') == selectedlan.get('_id')) ? "relative px-1 bg-green-100 group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500" :
									"relative hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset group focus-within:ring-indigo-500"}>
									<td
										className={classNames(
											index !== lans.length - 1 ? 'border-b border-gray-200' : '',
											'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 '
										)}
									>
										{lan.get('number')}
									</td>
									<td
										className={classNames(
											index !== lans.length - 1 ? 'border-b border-gray-200' : '',
											'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
										)}
									>
										{lan.get('name')}
									</td>
									<td className={classNames(
											'border-b border-gray-200',
											'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900'
										)}>
										<Grid.Controls>
											<Grid.Control>
												{/* <a className="hidden group-hover:block pl-2 cursor-pointer text-blue" onClick={() => { 
													setSelectedLan(lan)
													scan('scanforoutstations')
												}} title="Scan for outstations">scan</a> */}
												<SignalIcon title="Scan for outstations" className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {

													setSelectedLan(lan)
													scan('scanforoutstations')
												}}/>
											</Grid.Control>
											<Grid.Control>
												<Cog6ToothIcon className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {

													setSelectedLan(lan)
													editSettings(lan)
												}} />
											</Grid.Control>
											{/* <Grid.Control><PencilSquareIcon className="hidden text-gray-500 group-hover:block w-5"  /></Grid.Control> */}
                                            <Grid.Control>
												<TrashIcon className="cursor-pointer  hidden w-5 text-gray-500 group-hover:block " onClick={() => {
													setSelectedLan(lan)
													deleteItem('trendlans')();
												}} />
											</Grid.Control> 
										</Grid.Controls>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default LanList;
