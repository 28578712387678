import axios from 'axios';
import Store from 'Store';

export function setTopMenu(topMenu) {
    Store.dispatch({
        type: 'TOPMENU_READY',
        data: topMenu
    });
}

export function toggleLeftSlide() {
    return new Promise((resolve, reject) => {
        Store.dispatch({
            type: "TOGGLE_LEFT_SLIDE"
        });
        resolve();
    })
}