import React, { useState, useEffect, useLayoutEffect, useRef, useMemo, memo, forwardRef, useCallback, Fragment } from 'react';
// import history from 'BmsView/History';
import * as d3 from 'd3';
import { viewCache, PointList, Text, Image, EzyButton, Compass, TextDigital, TextButton, StatusButton, DynamicTable, Dial, Hotspot, Chart, Report, DateTimeText, TextMultiline, Shape, Line, Pipe, SystemObject, Gauge, Duct } from './SvgComponents';
import ContextMenu from 'Controls/ContextMenu';
import Button from 'Controls/Button/UserDefined';
import AreaPointListDialog from './AreaPointListDialog';
import { useArea } from '../../../../Context/Area';
import { List, Map } from 'immutable';
import Swal from 'sweetalert2';
import { useEditMode } from '../../../../Context/EditMode';
import { useUserSettings } from '../../../../Context/UserSettings';
import { useCustomerSettings } from '../../../../Context/CustomerSettings';
import { element } from 'prop-types';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useNavigate } from 'react-router-dom';


const orderLog = (message) => {
    return; // only remove in development
    console.log(`%c ${message}`, 'color:blue;font-weight:bold');
}

const svgComponents = {
    'label': Text,
    'animation': Image,
    'striped-text': Text,
    'pointlist': PointList,
    'text': Text,
    'image': Image,
    'ezybutton': EzyButton,
    'compass': Compass,
    'text-digital': TextDigital,
    'text-button': TextButton,
    'status-button': StatusButton,
    'text-multiline': TextMultiline,
    'dynamic-table': DynamicTable,
    'dial': Dial,
    'hotspot': Hotspot,
    'chart': Chart,
    'report':Report,
    'date-time-text': DateTimeText,
    'shape': Shape,
    'pipe': Pipe,
    'systemobject': SystemObject,
    'gauge': Gauge,
    'duct': Duct,
    'line': Line
}
let glob = { xmlTarget: undefined }
let setXMLTarget = (xml) => {
    // console.log("SETTING XML TARGET!!!", xml);
    glob.xmlTarget = xml;
}
// let glob.xmlTarget;
let renderList = window.innerWidth < 1000;
function Graphic({ 
    queuePoints, queuePoint, unqueuePoint, clearQueue,
    configureNode: configureNodeLocal, renderedInDialog, onConvertArea, customer, site, backdrop, onLoaded, 
    nodeChanged, editmode, onNodeCreation, updated, deleteNode: localDeleteNode, showMobileGraphic,
    onNodeConfiguration, nodeMovedToFront, nodeMovedToBack, ...props
}) {
    
    const localXmlTarget = useRef(undefined);
    const [redrawDirective, setRedrawDirective] = useStateIfMounted(0);

    const helpers = useRef({});

    const navigate = useNavigate();

    const { disableEditmode, setDisableEditmode } = useEditMode();
    const [loading, setLoading] = useState(0);
    const [loaded, setLoaded] = useState(0);

    const { userSettings } = useUserSettings();
    const { customerSettings } = useCustomerSettings();

    const svgPlaceholder = useRef(null);

    const [backdropXml, setBackdropXml] = useState(undefined);

    const siteBasePath = useMemo(() => `/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}`, [customer, site]);
    const backdropPath = useMemo(() => backdrop && backdrop.indexOf('~') == 0 ? `${siteBasePath}_backdrops_${backdrop.replace('~', '')}.svg` : `/files/editor/backdrops_${backdrop}.svg`, [backdrop, siteBasePath]);

    const [corruptNodes, setCorruptNodes] = useState(List());

    const [areaPointListDialogOpened, setAreaPointListDialogOpened] = useState();
    const [handles, setHandles] = useState({});
    const [selection, setSelection] = useState({});
    const [localState, setLocalState] = useState({})
    const { points, setPoints } = useArea();
    const { nodes, setNodes } = useArea();
    
    let { selectednode, selectedindex } = selection;

    const onRenderingError = (index, node, message) => {
        // console.log("onRenderingError, adding to corrupt nodes...");
        // TO BE APPLIED LATER
        // setCorruptNodes((corruptNodes) => {

        //     return corruptNodes.push(Map({ index, node, message }));

        // })
    }

    helpers.current = {
        getNodes: () => nodes,
        getHandles: () => handles,
        getLocalState: () => localState,
        getSelection: () => selection,
        getBackdropXml: () => backdropXml,
        getSiteBasePath: () => siteBasePath,
        getBackdropPath: () => backdropPath,
        getLoading: () => loading,
        getEditMode: () => editmode,
        getUserSettings: () => userSettings,
        getCustomerSettings: () => customerSettings

    }

    const getCustomerSettings = (key) => {
        return helpers.current.getCustomerSettings().get(key);
    }

    const getUserSettings = (key) => {
        return helpers.current.getUserSettings().get(key);
    }
    
    const toggleAreaPointListDialog = () => {
        setAreaPointListDialogOpened(x => !x);
    }
    

    const afterNodeUpdate = () => {
        let selection = helpers.current.getSelection();
        let localState = helpers.current.getLocalState();
        let nodes = helpers.current.getNodes();
        
        let { selectedindex, selectednode } = selection;
        let { deletednode } = localState;
        setSelection({ selectedindex: -1, selectednode: undefined });
        if (!nodes) return;
        setRedrawDirective((v) => v + 1);
        if ( deletednode ) {
            setLocalState({...localState,
                deletednode: undefined
            });
            updateComponents(selectedindex);
            return;
        }
    }

    const loadBackdrop = () => {


        let nodes = helpers.current.getNodes();
        let backdropXml = helpers.current.getBackdropXml();
        
        if (renderList && !showMobileGraphic) {
            orderLog("loadBackDrop renderList && !showMobileGraphic");
            (nodes || []).forEach((node, index) => {
                if (node.getIn(['configuration', 'boundto'])) {
                    // orderLog("loadBackDrop queuePoint");
                    // queuePoint(node.getIn(['configuration', 'boundto']), undefined, node.set('_ownindex', index));
                }
            });
            // orderLog("loadBackDrop onLoaded");
            onLoaded();
            return;
        }
        if (!backdropXml) {
            return;
        }


        if(backdropXml && svgPlaceholder.current) {
            let xml = backdropXml.cloneNode(true);
            xml.documentElement.classList.add('w-full');
            if (!renderedInDialog && backdrop != "general_background-popup") {
                d3.select(xml.documentElement).attr('preserveAspectRatio','xMidYMin meet');
                d3.select(xml.documentElement).attr('height','100%');
                d3.select(xml.documentElement).attr('width','100%');
            }
            let areaMenuElement = document.getElementById('area-menu');
            let areaMenuHeight = 0;
            if(areaMenuElement) {
                areaMenuHeight = areaMenuElement.clientHeight;
            }
            // clearQueue();
            if (localXmlTarget.current) {
                try {
                    svgPlaceholder.current.removeChild(localXmlTarget.current);
                } catch(e) {
                    // console.log(localXmlTarget.current);
                    // console.log("NOT A CHILD TO BE REMOVED");
                }
                localXmlTarget.current = undefined;
            }
            window.svgPlaceholder = svgPlaceholder.current;
            svgPlaceholder.current.appendChild(xml.documentElement);
            orderLog("loadBackDrop setXMLTarget");
            try {
                localXmlTarget.current = d3.select(svgPlaceholder.current).select('svg').node();
            } catch(e) {

                // console.log("XML ERROR TARGET");
                // console.log(e);

                if (localXmlTarget.current) {
                    try {
                        svgPlaceholder.current.removeChild(localXmlTarget.current);
                    } catch(e) {
                        // console.log("NOT A CHILD TO BE REMOVED");
                    }
                }
                // console.log("loading set to 0");
                
            }
            // console.log("SHOULD HAVE SET XML CURRENT TO");
            // console.log(localXmlTarget.current);
            setDisableEditmode(0);
        }
    };
    // console.log("should render graphic 3");

    const updateComponentsAfterOrderChange = (firstIndex, secondIndex) => {
        let nodes = helpers.current.getNodes();
        setSelection({ selectedindex: secondIndex, selectednode: nodes.get(secondIndex) });
    }

    const updateComponents = (index) => {  

    }
    
    // console.log("should render graphic 5");
    const handleResize = useCallback(() => {
        let areaMenuElement = document.getElementById('area-menu');
        let areaMenuHeight = 0;
        if(areaMenuElement) {
            areaMenuHeight = areaMenuElement.clientHeight;
        }

        areaMenuElement = document.getElementById('nav-bar');
        let navBarHeight = 50;
        if(areaMenuElement) {
            navBarHeight = areaMenuElement.clientHeight;
        }

        setLocalState((localState) => ({...localState,
            defaultHeight: window.innerHeight - navBarHeight - areaMenuHeight
        }))
    });
    

    // console.log("should render graphic 6");
    const nodeCreation = (value) => {
        let localState = helpers.current.getLocalState();
        let backdropXml = helpers.current.getBackdropXml();
        
        let { contextMenu } = localState;

        if(!backdropXml) {

            return Swal.fire({
                title: 'This area has no background',
                text: `There is no background set to this area, therefore you cannot add a component. Set a clear background if you don't have any.`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
              }).then((result) => {

                return true;
              })
        }

        let svg = svgPlaceholder.current.querySelector('svg:first-child');
        let svgPoint = svg.createSVGPoint();
        svgPoint.x = contextMenu.x;
        svgPoint.y = contextMenu.y;
        let svgTransformedPoint = svgPoint.matrixTransform(svg.getScreenCTM().inverse());
        if (onNodeCreation) {
            let position = {
                x: svgTransformedPoint.x,
                y: svgTransformedPoint.y,
            }
            onNodeCreation(position, value);
        }
        setLocalState({
            contextMenu: undefined
        });
    }

    const displayContextMenu = useCallback((evt, node, index) => {
        let position = {
            x: evt.clientX,
            y: evt.clientY
        }
        setSelection({ selectedindex: index, selectednode: node });
        setLocalState((localState) => ({ ...localState, contextMenu: position }));
        evt.preventDefault();
        evt.stopPropagation();
    });

    const contextMenuRequested = (evt) => {
        displayContextMenu(evt);
    }

    const contextMenuClosed = () => {
        setLocalState({
            contextMenu: undefined
        });
    }

    const configureNode = (node) => {
        onNodeConfiguration(undefined, node);
    }

    const deleteNode = () => {
        setLocalState({
            deletednode: selectednode
        });
        localDeleteNode(selectedindex, selectednode);
    }

     // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
     function* renderPoints(points) {
        if (!points) {
            return;
        }
        for (var i in points) {
            if (i == 'lastupdated') {
                return;
            }
            let point = points[i];
            if (!point.pointconfiguration) {
                yield (
                    <div className="row border-b mb-2 pb-2" key={i + 'loading'}>
                        <div className="col-16"><strong>Please wait while we are getting the point data for: </strong>{i}</div>
                    </div>
                );
            }
            else {
                point.node = point.node.setIn(['pointconfiguration'], point.pointconfiguration);
                yield (
                    <div className="row border-b mb-2 pb-2" key={i}>
                        <div className="col-16">
                            {point.pointconfiguration.m_pointName}
                        </div>
                        <div className="col-8">
                            {point.pointconfiguration.m_formattedValueText}
                        </div>
                        <div className="col-8">
                            <Button role="primary" className="w-100" onClick={() => configureNode(point.node)}>Point status</Button>
                        </div>
                    </div>
                )
            }
        }
    }

    const renderLinks = () => {
        let nodes = helpers.current.getNodes();
        
        let links = [];
        (nodes || []).forEach((node, index) => {
            if (node.getIn(['configuration', 'islink']) || node.getIn(['configuration','isdialog'])) {
                links.push(
                    <div className="row border-b mb-2 pb-2" key={index}>
                        {node.getIn(['configuration', 'tooltip']) ? 
                            <div className="col-16">
                                <Button className={"w-full"} role="primary" variant="primary" onClick={() => navigate(node.getIn(['configuration', 'linkto']))}>{node.getIn(['configuration', 'tooltip'])}</Button>
                            </div>:
                            <div className="col-16">
                                <Button className={"w-fill"} role="primary" variant="primary" onClick={() => navigate(node.getIn(['configuration', 'linkto']))}>{`Jump to ${node.getIn(['configuration', 'linkto'])}`}</Button>
                            </div>
                            }  
                    </div>);
        }});
        return links;
    }

    useEffect(() => {
        let backdropPath = helpers.current.getBackdropPath();
        (async () => {
            // console.log("running effect to get xml", backdropPath);
            let xml = await d3.xml(backdropPath);
            // console.log("retrieved element", xml);
            setBackdropXml(xml);
        })()
        
       
    }, [backdropPath])

    useEffect(() => {

        let areaMenuElement = document.getElementById('area-menu');
        let areaMenuHeight = 0;
        if(areaMenuElement) {
            areaMenuHeight = areaMenuElement.clientHeight;
        }
        areaMenuElement = document.getElementById('nav-bar');
        let navBarHeight = 50;
        if(areaMenuElement) {
            navBarHeight = areaMenuElement.clientHeight;
        }
        setLocalState((localState) => ({...localState,
            defaultHeight: window.innerHeight - navBarHeight - areaMenuHeight
        }));
        setDisableEditmode(1);
        window.addEventListener('resize', handleResize);
        // component will unmount
        return () => {
            // console.log("UNMOUNTING GRAPHICS");
            setDisableEditmode(0);
            viewCache.xml = {};
            window.removeEventListener('resize', handleResize);
        }
    },[])

    useEffect(() => {
        let backdropXml = helpers.current.getBackdropXml();

        if(backdropXml) {
            loadBackdrop();
        }

    }, [backdropXml])

    // useEffect(() => {
    //     let backdropXml = helpers.current.getBackdropXml();
    //     if(backdropXml) {
    //         loadBackdrop();
    //     }
        
    // }, [nodes, updated, showMobileGraphic, customerSettings, userSettings]);

    useEffect(() => {
        let selection = helpers.current.getSelection();
        let { selectedindex } = selection;
        if(selectedindex >= 0) {
            afterNodeUpdate();
        }
    }, [nodes]);

    useEffect(() => {

        let pointsToQueue = [];
        nodes.forEach((node) => {
            if(node.getIn(['configuration', 'boundto']) && node.getIn(['component', 'type']) != 'chart' && node.getIn(['component', 'type']) != 'report') {
                pointsToQueue.push([node.getIn(['configuration', 'boundto']), { setValue: () => {} }, node]);
            }
            if (node.getIn(['configuration', 'points'])) {
                node.getIn(['configuration', 'points']).forEach((point, index) => {
                    // orderLog("afterNodeUpdate queuePoint");
                    pointsToQueue.push([point.get('value'), { setValue: () => {} } , node]);
                });
            }
        })
        
        queuePoints(pointsToQueue);

    }, [nodes]);

    useEffect(() => {
        // console.log("%c useEffect on change edit mode", "color:yellow;font-weight:bold");
        let editmode = helpers.current.getEditMode();
        if(!editmode) {
            // onLoaded();
        }
        // loadBackdrop();
    }, [editmode])

    useLayoutEffect(() => {
        let loading = helpers.current.getLoading();
        if(!loading) {
            handleResize();
        }
    }, [loading, svgPlaceholder.current]);


    let {  contextMenu, defaultHeight } = localState;

    const renderSVGElements = function* () {
        for(const node of nodes) {
            let index = nodes.findIndex((item) => item == node);

            let nodePointData = {};
            if (node.getIn(['configuration', 'boundto']) && node.getIn(['component', 'type']) != 'chart'&& node.getIn(['component', 'type']) != 'report') {
                let point = points[node.getIn(['configuration', 'boundto'])];
                if(point) {
                    nodePointData[node.getIn(['configuration', 'boundto'])] = point;
                    
                }
            }
            if (node.getIn(['configuration', 'points'])) {
                node.getIn(['configuration', 'points'],[]).forEach((point, index) => {
                    let actualPoint = points[point];
                    if(actualPoint) {
                        nodePointData[node.getIn(['configuration', 'boundto'])] = actualPoint;
                    }
                });          
            }

            yield (<SVGReactComponent key={index + "-" +node.hashCode()}
                onDeleteNode={localDeleteNode}
                pointData={nodePointData}
                redrawDirective={redrawDirective}
                getUserSettings={getUserSettings}
                target={localXmlTarget.current}
                getCustomerSettings={getCustomerSettings}
                index={index}
                node={node}
                onNodeConfiguration={onNodeConfiguration}
                editmode={editmode}
                displayContextMenu={displayContextMenu}
                siteBasePath={siteBasePath}
                nodeChanged={nodeChanged}
                onRenderingError={onRenderingError}
                setSelection={setSelection} />
            )


        }
    }

    // TO BE APPLIED LATER
    // onRenderingError(localData.current.index, nodeToRender, message);

    // console.log("Rendering Graphics");
    // console.log(corruptNodes);

    return (
        <React.Fragment>
            <Fragment>
                <AreaPointListDialog 
                selecteditem={areaPointListDialogOpened}
                points={points}
                onClose={toggleAreaPointListDialog}
                customer={customer}
                site={site}
            />
            {editmode && <ContextMenu contextMenu={contextMenu} onClose={contextMenuClosed} onPositionChange={e => contextMenuRequested(e)}>
                {!selectednode && <ContextMenu.Item onClick={() => nodeCreation(undefined)}>Add Component</ContextMenu.Item>}
                {!selectednode && <ContextMenu.Item onClick={() => nodeCreation('pointlist')}>Add Pointlist</ContextMenu.Item>}
                {!selectednode && <ContextMenu.Item onClick={() => { toggleAreaPointListDialog(); contextMenuClosed(); } }>Point List</ContextMenu.Item>}
                {!selectednode && onConvertArea && <ContextMenu.Item onClick={onConvertArea}>Convert area</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { configureNodeLocal(selectedindex, selectednode); contextMenuClosed() }}>Configure</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { nodeMovedToFront(selectednode, selectedindex); contextMenuClosed(); updateComponentsAfterOrderChange(selectedindex, selectedindex + 1) }}>Move up</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { nodeMovedToBack(selectednode, selectedindex); contextMenuClosed(); updateComponentsAfterOrderChange(selectedindex, selectedindex - 1) }}>Move down</ContextMenu.Item>}
                {selectednode && <ContextMenu.Item onClick={() => { deleteNode(selectedindex, selectednode); contextMenuClosed() }}>Remove</ContextMenu.Item>}
            </ContextMenu>}
            {(renderedInDialog) && <div onContextMenu={editmode && (e => contextMenuRequested(e)) || undefined} ref={svgPlaceholder}>{[...renderSVGElements()]}</div>
           }
            {((!renderList || showMobileGraphic) && !renderedInDialog) && <SVGContainer defaultHeight={defaultHeight} onContextMenu={editmode && (e => contextMenuRequested(e)) || (undefined)} ref={svgPlaceholder}>{[...renderSVGElements()]}</SVGContainer>}
            {renderList && !showMobileGraphic &&
                <div className="px-3 py-2">
                    {[...renderPoints(points)]}
                </div>
            }
            {renderList && !showMobileGraphic &&
                <div className="px-3 py-2">
                    {renderLinks()}
                </div>
            }

                {/* <div>
                    Rendering error { corruptNodes.size }
                </div> */}

            </Fragment> 
        </React.Fragment>
    )

}

function _SVGContainer({ onContextMenu, defaultHeight, children }, ref) {

    // console.log("_SVGCONTAINER_WAS_RENDERED");
    // console.log("defaultHeight", defaultHeight);

    const localOnContextMenu = (e) => {
        // console.log("i did received a context menu request");
        onContextMenu && onContextMenu(e)
    }

    return <data>
        <div onContextMenu={localOnContextMenu} style={{position: "fixed", zIndex: -1, width: "100%", height: `${defaultHeight}px`}} ref={ref}></div>
        {children}
    </data>
}

const SVGContainer = (forwardRef(_SVGContainer));

export default Graphic;


function SVGReactComponent({ onRenderingError, redrawDirective, pointData, getUserSettings, target, getCustomerSettings, index, node, onNodeConfiguration, editmode, displayContextMenu, siteBasePath, nodeChanged, setSelection, onDeleteNode }) {

    const elementHandle = useRef(null);
    const d3Handle = useRef(null);
    const pointsHandle = useRef(null);
    const isUnmounted = useRef(false);
    const localData = useRef();

    localData.current = {
        nodeChanged: nodeChanged,
        setSelection: setSelection,
        index: index,
        node: node,
        target: target
    };

    const onStart = function (e, d) {
        d3.select(this).raise().classed("active", true);
        let box = d3.select(this).node().getBBox();
    };

    const onDrag = function (e, d) {
        let scale = localData.current.node.getIn(['configuration','scale'], 100.0) / 100;
        let rotation = localData.current.node.getIn(['configuration','rotation'], 0);
        let box = d3.select(this).node().getBBox();
        d3.select(this).attr('transform', `matrix(${scale},0,0,${scale},${e.x - (box.width / 2)}, ${e.y - (box.height / 2)})rotate(${rotation})`);
    }

    const onEnd = function (e, d) {
        let box = d3.select(this).node().getBBox();
        if (localData.current.nodeChanged) {
            let changedNode = localData.current.node.setIn(['position', 'x'], e.x - (box.width / 2)).setIn(['position', 'y'], e.y - (box.height / 2));
           
            localData.current.setSelection({ selectedindex: localData.current.index, selectednode: changedNode });
            localData.current.nodeChanged(changedNode, localData.current.index);   
            
        }
        d3.select(this).classed("active", false);
    }

    const dragBehaviour = () => {
        return d3.drag()
        .on("start", onStart)
        .on("drag", onDrag)
        .on('end', onEnd); 
    }

    const tooltip = createTooltip();

    const adjustType = (node) => {
        if (node.getIn(['component', 'type']) == 'label') {
            node = node.setIn(['component', 'type'], 'text');
            node = node.setIn(['component', 'name'], 'text');
            node = node.setIn(['configuration','displaystyle'], 'boxed');
        }
        if (node.getIn(['component', 'type']) == 'striped-text') {
            node = node.setIn(['component', 'type'], 'text');
            node = node.setIn(['component', 'name'], 'text');
            node = node.setIn(['configuration','displaystyle'], 'striped');
        }
        return node;
    }

    const createHandle =  () => {
        if(isUnmounted.current || !localData.current.target) {
            return;
        }
        
        let nodeToRender = localData.current.node;
        nodeToRender = adjustType(nodeToRender);
        let svgComponent = svgComponents[nodeToRender.getIn(['component', 'type'])];
        let path = undefined;
        if (nodeToRender.getIn(['component', 'name']).indexOf('~') == 0) {
            path = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.svg`;
        }
        tooltip.style('visibility', 'hidden');
        if(svgComponent) {
            let handle = svgComponent({
                getUserSettings, getCustomerSettings,
                node: nodeToRender,
                target: target,
                index: index,
                onNodeConfiguration: onNodeConfiguration,
                editmode: editmode,
                dragBehaviour: dragBehaviour(),
                tooltip: tooltip,
                showContextMenu: displayContextMenu
            }, path);
            handle.then((handle) => {
                if(d3Handle.current) {
                    d3Handle.current.remove();
                    d3Handle.current = undefined;
                }
                d3Handle.current = handle;
                if(target != localData.current.target) {
                    // console.log("Found non matching targets");
                }
            }).catch((e) => {
                // console.log("something got wrong making this handle");
                console.error(e);
                if(target != localData.current.target) {
                    // console.log("Found non matching targets");
                }
                // console.log(e.stack)

                let message = `The component ${nodeToRender.getIn(['component','name'])} in this area is corrupt, `;
                if(e.message && e.message.match('Not Found')) {
                    message += `a dependency was not found.`;
                } 
                message += `The issue needs to be fixed, or the component needs to be removed to be able to edit this area. `;
                message += `Would you like to remove this component from the area?`
                
                // TO BE APPLIED LATER
                // onRenderingError(localData.current.index, nodeToRender, message);
                
                // Swal.fire({
                //     title: 'A corrupt component was found',
                //     text: message,
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes, delete it!'
                //   }).then((result) => {
                //     if (result.isConfirmed) {
                //         let shouldSaveArea = true;
                //         onDeleteNode(localData.current.index, nodeToRender, shouldSaveArea);
                //         console.log("here the logic to remove the node");
                        
                //     }
                //     return true;
                // })


            })
        }
        return true;
    }

    useEffect(() => {
        if (node.getIn(['configuration', 'boundto']) && node.getIn(['component', 'type']) != 'chart') {
            let point = pointData[node.getIn(['configuration', 'boundto'])];
            if(point) {
                let data = point.pointconfiguration;
                if(data) {
                    data.address = point.address
                    if(d3Handle.current) {
                        d3Handle.current.setValue(data); 
                    }
                }   
            }
        }
        if (node.getIn(['configuration', 'points'])) {
            node.getIn(['configuration', 'points'],[]).forEach((point, index) => {
                let actualPoint = pointData[point];
                if(actualPoint) {
                    let data = actualPoint.pointconfiguration;
                    if(data) {
                        data.address = actualPoint.address;
                        if(d3Handle.current) {
                            d3Handle.current.setValue(data);
                        }
                    }
                }
            });          
        }
    }, [JSON.stringify(Object.values(pointData))])

    useLayoutEffect(() => {
        if(isUnmounted.current) return;
        if(!d3Handle.current && localData.current.target) {
            createHandle();
        } else if(d3Handle.current) {
            let localNode = adjustType(node);
            if (localNode.getIn(['component', 'type']) != 'text-button' && d3Handle.current) {
                d3Handle.current.redrawElement(localNode) 
            } else {
                // console.log(d3Handle.current);
            }
        }
        return () => {
            pointsHandle.current = undefined;
        }
    }, [node, redrawDirective])


    useLayoutEffect(() => {
        if(isUnmounted.current) return;
        return () => {
            // console.log("SVGReactComponent Component is unmounting");
            isUnmounted.current = true;
            if(d3Handle.current) {
                d3Handle.current.remove();
                d3Handle.current = undefined;
            }
        }
    }, [])

    useLayoutEffect(() => {
        if(isUnmounted.current) return;
        if(target) {
            createHandle();
        }
    }, [target, index, editmode])

    return <g ref={elementHandle}>

    </g>

}

function createTooltip() {
    let tooltip = d3.select("#tooltip");
    if (tooltip._groups[0] == 0) {
        tooltip = d3.select('body')
        .append('div')
        .attr('id', 'tooltip')
        .style('z-index', '2000')
        .style('position', 'absolute')
        .style('visibility','hidden')
        .style('border-width', '2px')
        .style('border_color', 'grey')
        .style('border-style', 'ridge')
        .style('padding', '2px')
        .style('font-size', '12px')
    }
    return tooltip
}