import React, { useState, useEffect } from 'react';
import Input from 'Controls/Form/Input';
import { login } from 'Actions/Authentication';
import Button from 'Controls/Button/UserDefined';
import { useBMS } from '../BmsView/Context/BMS';
import { useRouteLoaderData, useRevalidator } from 'react-router-dom';
import { useInactivityDetector } from '../Context/InactivityDetector';


import { redirect } from 'react-router-dom';


function Authentication({ children }) {
    const [{ loading, error }, setLoginState] = useState({ loading: false, error: undefined }); 
    const [{ email, password }, setCredentials] = useState({ email: "", password: "" });
    // const [justExpired, setJustExpired] = useState(false);

    let { currentUser, signalLoaded, loginExpired, loggedIn, setLoggedIn } = useBMS(); 
    const justExpired = loginExpired;

    const _currentUser = useRouteLoaderData('authentication-checker');
    const revalidator = useRevalidator();

    useInactivityDetector(currentUser);

    useEffect(() => {
        // console.log("A change occurred in the currentUser state");
        if(revalidator.state == "idle") {
            signalLoaded();
        }
    }, [revalidator.state]);



    if(revalidator.state == "loading" && !_currentUser) {
        return <div>Loading...</div>
    }



    if(!currentUser) {
        currentUser = _currentUser;
    } 

    

    const localLogin = async () => {
        setLoginState({ loading: true, error: undefined });
        try {
            let loginResponse = await login(email, password);
            setLoggedIn(() => true);
            setCredentials({ email: "", password: "" });
            return revalidator.revalidate();
        } catch(e) {
            setLoginState({ 
                loading: false, error: "The given username and/or password is not correct, please try again." });
            // setCredentials({ email: "", password: "" });
        }
    }

    if(currentUser && loggedIn) {
        return children
    }


    return (
        <div className="flex flex-row w-screen h-screen">
            <div className="px-16 flex flex-col justify-center gap-4 min-w-[25%]">
                <h1 className="text-2xl">BMSView login</h1>
                { justExpired && <div className="p-4 bg-red-500 text-white border rounded-lg border-red-500">
                    Your session has expired, please login again.
                </div> || null }
                <div className="flex flex-col gap-2 ">
                    
                    {error && <div className="py-3 px-5 mb-4 rounded border-[transparent] text-[#721c24] bg-[#f8d7da] border-[#f5c6cb]">
                        <strong>Error: </strong>{error}
                    </div>}
                    <div className="relative">
                        <Input label="Email" onEnter={localLogin} className="" value={email} onChange={(value) => setCredentials((creds) => ({ ...creds, email: value}))} autoFocus={true} />
                    </div>
                    <div className="relative">
                        <Input label="Password" onEnter={localLogin} type="password" className="" value={password} onChange={(value) => setCredentials((creds) => ({ ...creds, password: value }))}  />
                        
                    </div>
                    <div className="flex justify-end">
                        <Button role="primary" className="!bg-[rgb(18,40,58)] px-6" onClick={localLogin}>Login</Button>
                    </div>
                </div>
            </div>
            <div className="flex-1 bg-[rgb(18,40,58)]">
                <img src="/login-background.webp" className="object-cover w-full h-full" />
            </div>

        </div>
    )
}

export default Authentication;
// export default withBMS(Authentication);