import React from 'react';

import Immutable from 'immutable';
import {getDevices, saveItem, deleteDevice} from '../Actions';
import { getPoints } from 'BmsView/Customer/Site/Actions';
import ControllerList from './ControllerList';
import PointList from './PointList';
import ScanDialog from '../ScanDialog';
import ScanForPointsDialog from '../ScanForPoints';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import Swal from 'sweetalert2';

function ModbusSetupDialog({ selecteditem, onClose, ...props }) {
    return <Dialog className="min-w-[80vw] max-w-[80vw] " opened={!!selecteditem} onClose={onClose} >
        <ModbusSetupDialogPanel selecteditem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}

class ModbusSetupDialogPanel extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            controllers: [],
            points: [],
            selectedcontroller: 1
        }
    }

    componentDidMount() {
        this.getControllerList();
        this.getPointsList();
    }

    getControllerList() {
        let { customer } = this.props;

        getDevices(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'modbusdevices').then((data) => {
                this.setState({
                    controllers: Immutable.fromJS(data.map((controller) => {
                        return controller;
                    }))
                });
            }
        )
    }
 
    getPointsList() {
        let { customer } = this.props;

        getPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`).then((data) => {
                this.setState({
                    points: Immutable.fromJS(data.map((point) => {
                        return {
                            subsystem: point.addr[0],
                            subsystemtype: point.addr[1],
                            type: point.addr[2],
                            lan:0,
                            controller: point.addr[3],
                            name: point.name,
                            number: point.addr[4]
                        }
                    }))
                })
            })
    }

    selectController(controller) {
        this.setState({
            selectedController: controller //.get('number')
        });
    }

    convertIPAddressStringToArray(ipAddress) {
        let addr = ipAddress.split(".");

        let ipAddr = addr.map((byte) => {
            return parseInt(byte);
        });
    }

    saveItem(device) {
        let {customer, saveItem} = this.props;
        let {controllers} = this.state;

        if (!device.get('deviceid')) {
            var newdeviceid = -1;
            var devicefound = 0;

            do {
                newdeviceid++;

                devicefound = controllers.find((controller) => {
                    return controller.get('deviceid') == newdeviceid;
                });
            }
            while (devicefound)  
        }    

        device = device.set('deviceid', newdeviceid);
        
        saveItem(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'modbusdevices', device).then(() => {
                Swal.fire("Success", "The device has been succesfully saved.", "success");
                this.getControllerList();
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while saving the device, please contact the administrator.", "error");
            })
    }

    deleteItem(objecttype) {
        let { customer } = this.props;
        let device = this.state.selectedController;
        
        Swal.fire({
            title: `Are you sure you want to delete the device ?`,
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value) {
                await deleteDevice(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`, objecttype, device);
                
                this.getControllerList();
                Swal.fire(
                    'Deleted!',
                    'The device has been deleted',
                    'success'
                );
            }
        })  
    }

    scanForPoints(controller) {
        Swal.fire({
            title: `Are you sure you want to scan for points ?`,
            text: "This may take several minutes!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, continue'
        }).then((result) => {
            if (result.value) {
                this.openDialog('scanforpoints')
            }
        });
    }

    openDialog(name) {
        this.setState((state) => {
            if (!state) {
                state = {};
            }
            state[`${name}dialog`] = true;
            return state;
        });
    }

    closeDialog(name) {
        this.setState((state) => {
            state[`${name}dialog`] = false;
            return state;
        });

        if (name == 'scanforcontrollers') {
            this.getControllerList();
        }

        if (name == 'scanforpoints') {
            this.getPointsList();
        }
    }

    render() {
        let { controllers, points, selectedController, scanforcontrollersdialog, scanforpointsdialog } = this.state;
        let selectedSubsystem = this.props.selecteditem.get('number');
        let { customer, onClose } = this.props;

        return (
            <DialogContent>
                { scanforcontrollersdialog && <ScanDialog selecteditem={scanforcontrollersdialog} customer={customer} selectedsubsystem={this.props.selecteditem} selectedlan={0} onClose={this.closeDialog.bind(this, 'scanforcontrollers')} />}
                { scanforpointsdialog && <ScanForPointsDialog selecteditem={scanforpointsdialog} customer={customer} type={"modbus"} selectedSubsystem={this.props.selecteditem} selectedlan={0} selectedcontroller={selectedController.get('identifier')} onClose={this.closeDialog.bind(this, 'scanforpoints')} />}
                <DialogHeader>
                    <DialogTitle>Modbus Subsystem Configuration</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-8 mt-8">
                        <div className="w-full">
                        <ControllerList
                            customer={customer}
                            selectedSubsystem={selectedSubsystem}
                            controllers={controllers}
                            modbustype={this.props.selecteditem.get('modbustype', 0)}
                            updateItem={this.saveItem.bind(this)}
                            deleteItem={this.deleteItem.bind(this)}
                            saveItem={this.saveItem.bind(this)}
                            setCurrentController={this.selectController.bind(this)}
                            selectedcontroller={selectedController}
                            scan={this.scanForPoints.bind(this)} />
                        </div>
                        <div class="w-full">
                            <PointList
                                selectedSubsystem={selectedSubsystem}
                                points={points}
                                selectedlan={0}
                                selectedController={selectedController && selectedController.get('deviceid')} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={onClose}>Exit</Button></div>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default ModbusSetupDialog;