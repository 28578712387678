import React from 'react';
// import history from 'BmsView/History';
import { redirect } from 'react-router-dom';

class Item extends React.Component{
    constructor(props){
        super(props);

        this.visit = this.visit.bind(this);
    }

    visit(evt){
        let {onClick, to} = this.props;
        this.context.toggleDropdown();
        if (onClick){
            return onClick();
        }
        redirect(to);
    }

    render(){
        let {to, children} = this.props;

        return (
            <li className="dropdownitem" data-link={to} onClick={this.visit}><a>{children}</a></li>
        )
    }
}

export default Item;