import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const contextMenuRoot = document.getElementById('slide-portal');

function ContextMenu({ children, contextMenu, onClose, className = '', onPositionChange }) {

    const menuRef = useRef(null);

    useEffect(() => {
        if(contextMenu) {
            let screenWidth = document.body.clientWidth;
            let screenHeight = Math.max(document.body.clientHeight, window.innerHeight);
            let elementWidth = menuRef.current.offsetWidth;
            let elementHeight = menuRef.current.offsetHeight;
            let top = contextMenu.y;
            let left = contextMenu.x;

            if(top + elementHeight > screenHeight) {
                menuRef.current.style.top = (top - elementHeight) + "px";
            } 

            if(left + elementWidth > screenWidth) {
                menuRef.current.style.left = (left - elementWidth) + "px";
            }
        }
    }, [contextMenu])

    if (!contextMenu) {
        return <data></data>
    }


    return (
        <ContextMenuPortal>
            <div className={`fixed z-[1060] pin cursor-default w-full h-full ${className}`} onClick={onClose} onContextMenu={onPositionChange}></div>
            <div 
                style={{ left: contextMenu.x, top: contextMenu.y }}
                ref={menuRef} 
                className="fixed z-[1060] border border-grey bg-white cursor-pointer flex-col">
                {children}
            </div>
        </ContextMenuPortal>
    )
}

ContextMenu.Item = (props) => {
    let { children, ...other } = props;
    return (
        <a className="block flex-1 w-full hover:bg-blue w-full px-4 py-2 hover:text-white" {...other}>{children}</a>
    )
}

export default ContextMenu;



function ContextMenuPortal({ children }) {

    const internalRef = useRef(document.createElement('div'));

    useEffect(() => {
        contextMenuRoot.appendChild(internalRef.current);
        return () => {
            contextMenuRoot.removeChild(internalRef.current);
        }
    },[]);

    return ReactDOM.createPortal(children, internalRef.current);

}