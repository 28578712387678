import * as d3 from "d3";
import Immutable from 'immutable';
import { displayTooltip, setDefs } from "../Utils";


var drawTable = (options) => {
    let element = d3.select(options.target)
            .append("g").attr('data-index', options.index)
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

    if ( options.node.getIn(['configuration', 'gradient'])) setDefs(element, options.node.get('id'), 
        options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
        options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
        options.node.getIn(['configuration', 'grads']));

    var rows = options.node.getIn(['configuration', 'rows'], 1);
    let xcol = 0;
    let ycol = 0;
    var row = 0;
    let rowheight = options.node.getIn(['configuration', 'rowheight'], 35)

    var style;

    for (row = 0; row < rows; row++) {
        //Immutable.List([Immutable.Map({"width":160, "colour":"lightgrey"})])
        options.node.getIn(['configuration', 'cols'], Immutable.fromJS([{ "width": 160, "colour": "lightgrey" }])).map((col) => {
        //    if ( options.node.getIn(['configuration', 'gradient'] )) {
                style = options.node.getIn(['configuration', 'gradient'] ) ? `url(#mainGradient${options.index})`:`${options.node.getIn(['configuration', 'style', 'color'],'lightgrey')}`; 
        //    }
        //    else {
                 //.style("stroke-width", "0.5").style("stroke", "black")`;
        //    }

            element.append("rect")
                .attr("height", rowheight)
                .attr("width", col.get('width'))
                .attr("x", xcol)
                .attr("y", ycol)
                .attr("stroke",col.get('color', "lightgrey"))
                .style("stroke-width", "0.5")
                .style("stroke", "black")
                .style("fill", options.node.getIn(['configuration', 'gradient'] ) ? `url(#mainGradient${options.index})`:`${options.node.getIn(['configuration', 'style', 'color'],'lightgrey')}`);

/*element.append("text")
                .text("Hello")
                .attr("x", xcol + 20)
                .attr("y", ycol + 20)
                .attr('font-size', '16px');*/
                            
            xcol = xcol + col.get('width');
        });

        xcol = 0;
        ycol = ycol + rowheight;
    }

    return element;
}

export default function DynamicTable(options) {
    return new Promise((resolve, reject) => {
        let placementElement = drawTable(options);
    //    setMenu(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                let value = point.m_value;
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
    //            setMenu(placementElement, options);
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
            //    placementElement.selectAll("rect").remove()
                placementElement.remove();
                options.node = node;
                placementElement = drawTable(options);
        //        setMenu(placementElement, options);
                if (!tooltip) tooltip = displayTooltip(placementElement, options);
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            remove: () => {
                placementElement.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}
