import React, { useEffect, useState, useRef } from 'react';
import Grid from 'Controls/GridV2';
import Immutable from 'immutable';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { useSubscription } from '../../../../../Context/AreaPoint';

function PointListStatusDialog({ selecteditem, onClose, ...props }) {

    

    return <Dialog opened={!!selecteditem} className="" zIndex="z-20" layer="top" onClose={onClose}>
        <PointListStatusDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

function PointListStatusDialogPanel({ onSelect, selecteditem, lastupdated, onClose }) {

    const subscription = useSubscription();

    const [node, setNode] = useState(selecteditem);
    const setNodeRef = useRef(null);
    setNodeRef.current = { setNode}

    const registerSubscribers = (node) => {

     
        let subsciptions = [];
        let abortController = new AbortController();
     
        if (node && node.getIn(['configuration', 'points'])) {
            node.getIn(['configuration', 'points']).forEach((point, index) => {
                let address = point.get('value');
                    let pointSubscription = subscription.subscribe(address, (value) => {

                        let { m_pointStatusData } = value;
                        if(!m_pointStatusData) {
                            m_pointStatusData = value;
                        }

                        let newPoint = point.set('currentvalue', m_pointStatusData.m_formattedValueText)
                        .set('currentalarmstate', m_pointStatusData.m_formattedAlarmText)
                        // .set('rawvalue', m_pointStatusData.m_value)
                        // .set('lastcommanded', m_pointStatusData.m_whoCommanded)
                        // .set('pointname', m_pointStatusData.m_pointName)
                        // .set('description', m_pointStatusData.m_description)
                        // .set('pointconfiguration', Immutable.fromJS(m_pointStatusData))
                        // .set('error', m_pointStatusData.m_errorNumber);

                        setNodeRef.current.setNode((node) => {
                            return node.setIn(['configuration', 'points', index], newPoint)
                        });


                        // if(handleRef?.current && handleRef?.current?.setValue) {
                        //     handleRef.current.setValue(value);
                        // }
                    }, abortController);
                    subsciptions.push(pointSubscription);
                // }
            });
        }

        return {
            subscriptions: subsciptions,
            abortController: abortController

        }
    }

    useEffect(() => {
        if(selecteditem) {

            let { subscriptions, abortController } = registerSubscribers(selecteditem)

            return () => {
                subscriptions.forEach((unsubscribe) => {
                    unsubscribe();
                });
                abortController.abort();
            }

        }
    }, [selecteditem])


    const _onSelect = (point) => {
        if (onSelect) {
            onSelect(Immutable.Map({}).setIn(['configuration', 'boundto'], point.get('value')).set('pointconfiguration', point.get('pointconfiguration')));
        }
    }

    return <DialogContent className="md:w-[500px] lg:w-[800px] xl:w-[1140px]">
        <DialogBody>
            <div>
                <h1 className="flex">{node.getIn(['configuration', 'value'])}</h1>
                <Grid className="mt-3" key={lastupdated}>
                    <Grid.Header>
                        <Grid.Column classes="col-span-16 md:col-span-10">Point Name</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-3">Status</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-3">Alarm</Grid.Column>
                    </Grid.Header>
                    {node.getIn(['configuration', 'points'], []).map((point, key) => {
                        return (
                            <Grid.Row key={key} onClick={() => _onSelect(point)}>
                                <Grid.Column classes="col-span-16 md:col-span-10">{point.get('label')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">{point.get('currentvalue', 'pending')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">{point.get('currentalarmstate', 'pending')}</Grid.Column>
                            </Grid.Row>
                        )
                    })}
                </Grid>
            </div>
        </DialogBody>
        <DialogFooter>
            <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
        </DialogFooter>
    </DialogContent>
}


export default PointListStatusDialog;