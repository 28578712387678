import React, { useState, useMemo, useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

const TransparentLoaderContext = React.createContext({});




export function useTransparentLoader() {
    return useContext(TransparentLoaderContext);
}

export default function TransparentLoaderProvider({ children }) {
    
    const [loading, setLoading] = useState()

    const executePromise = async (promise) => {
        setLoading(true);
        try {
            await promise
        } catch(e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
        return promise
       
    }

    const value = {
        loading,
        executePromise
    }

    return <TransparentLoaderContext.Provider value={value}>
        {children}
    </TransparentLoaderContext.Provider>
}