import React from 'react';
import Immutable from 'immutable';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import {getIGroups, deleteIGroup, saveIGroup} from './Actions';
import UserGroupEditDialog from './UserGroupEditDialog';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';

import Swal from 'sweetalert2';

class UserGroups extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openUserGroupEditDialog:false
        }

        // console.log('UserGroups constructor  ');
    }        

    componentDidMount() {
        // console.log('UserGroups componentDidMount ');
        this.getGroups();
    }

    componentDidUpdate(oldprops) {
        // console.log('UserGroups componentDidUpdate  ', this.props, oldprops, this.state);

        if (this.props.selecteditem) {
        this.setState({
            openUserGroupEditDialog:true
        });} 
    }

    getGroups() {
        getIGroups('usergroups').then((data) => {
            this.setState({
                groups: Immutable.fromJS(data.map((group) => {
                    return group;
                }))
            });    
        });
    }

    displayDialog(group) {
        this.setState({
            openUserGroupEditDialog:true,
            selecteditem: group
        });
    }

    closeDialog() {
        this.setState({
            openUserGroupEditDialog:false,
            selecteditem: undefined
        });
    }

    saveGroup(group) {
        saveIGroup('usergroups', group).then(() => {
            Swal.fire("Success", "The group has been added successfully", "success");
            this.getGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error adding group", "error");
        });
    }

    removeGroup(group) {
        deleteIGroup('usergroups', group.get('_id')).then(() => {
            Swal.fire("Success", "The group has been removed successfully", "success");
            this.getGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error removing group", "error");
        });
    }

    render() {
        let {customer} = this.props;
        let {groups, selecteditem, openUserGroupEditDialog} = this.state;

        // console.log('UserGroups render  ', selecteditem);

        return (
            <div className='container mx-auto my-4'>
                <UserGroupEditDialog customer={customer} selecteditem={selecteditem} openDialog={openUserGroupEditDialog} onClose={this.closeDialog.bind(this)} onSave={this.saveGroup.bind(this)}></UserGroupEditDialog>
                <h2>User Group Setup</h2>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-4">
                        <Input className="col-span-16 md:col-span-4" disabled={true} label="Customer" value={customer.get('name')}/>
                    </div>
                </div>
                <h4>Group List</h4>
                <div>
                    <Grid>
                        <Grid.Header>
                            <Grid.Column classes="col-span-16 md:col-span-5">Name</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-7">Description</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-4" name="add" type="complex">
                                <Grid.Controls>
                                    <Grid.Control>
                                        <PlusIcon className="w-6 text-white cursor-pointer" title="add new" onClick={this.displayDialog.bind(this, Immutable.Map({customer:customer.get('name')}))} />
                                        {/* <Button role="success" >Add</Button> */}
                                    </Grid.Control>
                                </Grid.Controls>
                                {/* <Button role="success" className="w-100" onClick={this.displayDialog.bind(this, Immutable.Map({customer:customer.get('name')}))}>Add</Button> */}
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            {(groups || []).map((group, index) => {
                                return(
                                    <Grid.Row key={index} className="hover:bg-gray-300 group">
                                        <Grid.Column classes="col-span-16 md:col-span-5">{group.get('name')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-7">{group.get('description')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4">
                                            <Grid.Controls>
                                                <Grid.Control><PencilSquareIcon title="Edit" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue w-6" onClick={this.displayDialog.bind(this, group)} /></Grid.Control>
                                                <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 w-6" onClick={this.removeGroup.bind(this, group)} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid.Data>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default UserGroups;