import React from 'react';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import DocumentDialog from './DocumentDialog';
import { getDocuments, saveDocument, deleteDocument } from './Actions';
import Grid from 'Controls/GridV2';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';


class DocumentList extends React.Component {
    constructor(props) {
        super(props);

        let { customer } = this.props;

        let sites = customer.get('sites') || Immutable.List();
        sites  = sites.push(Immutable.Map({'name':'All Sites'}));

        this.state = {
            documents: null,
            currentsite: 'All Sites',
            sites: sites
        }

        this.saveDocument = this.saveDocument.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
    }

    componentDidMount() {
        let { customer } = this.props;

        getDocuments(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                this.setState({
                    documents: Immutable.fromJS(data.map((document) => {
                        return document;
                    }))
                })
            });
    }

    selectDocument(document) {
        this.setState({
            selecteddocument: document
        });
    }

    saveDocument(document) {
        let { customer } = this.props;

        saveDocument(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, document).then(() => {
                this.clearSelection();

                getDocuments(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                        this.setState({
                            documents: Immutable.fromJS(data.map((document) => {
                                return document;
                            }))
                        })
                    });

                Swal.fire("Success", "The document has been succesfully saved.", "success");
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while saving the document, please contact the administrator.", "error");
            });
    }

    removeDocument(document) {
        let { customer } = this.props;

        Swal.fire({
            title: `Are you sure you want to delete the document ?`,
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value) {
                await deleteDocument(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`, document);
                await getDocuments(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                        this.setState({
                            documents: Immutable.fromJS(data.map((document) => {
                                return document;
                            }))
                        })
                    });
                Swal.fire(
                    'Deleted!',
                    'The document has been deleted',
                    'success'
                );
            }
        })
    }

    siteChanged(value) {
        this.setState({
            currentsite: value
        });
    }

    clearSelection() {
        this.setState({
            selecteddocument: null
        });
    }

    render() {
        let { customer } = this.props;
        let { selecteddocument, sites, currentsite } = this.state || {};
        let { documents } = this.state;

        let gridFields = [
            { value: 'type', name: 'Type', width: 'col-span-16 md:col-span-4' },
            { value: 'document', name: 'Document', width: 'col-span-16 md:col-span-5' }
        ];

        return (
            <div className='container mx-auto my-4'>
                <DocumentDialog selecteditem={selecteddocument} onClose={this.clearSelection} customer={customer} onSave={this.saveDocument} />
                <h2>Documents Setup</h2>
                <div className='grid grid-cols-16 my-4'>
                    <div className="col-span-16 md:col-span-5">
                        <Select
                            value={currentsite}
                            onChange={this.siteChanged.bind(this)}
                            options={sites.map((site) => {
                                return ({
                                    text: site.get('name'),
                                    value: site.get('name')
                                });
                            })
                        }></Select>
                    </div> 
                </div>
                <Grid>
                    <Grid.Header>
                        {currentsite == 'All Sites' && <Grid.Column classes={'col-span-16 md:col-span-3'}>Site</Grid.Column>}
                        {gridFields.map((field) => {
                            return (
                                <Grid.Column key={field.name} classes={`${field.width}`}>{field.name}</Grid.Column>
                            );
                        })}
                        <Grid.Column key={"edit"} classes='col-span-16 md:col-span-1' name="edit"></Grid.Column>
                        <Grid.Column key={"delete"} classes='col-span-16 md:col-span-1' name="delete" type="complex">
                            <Button className="w-100" role="success" onClick={this.selectDocument.bind(this, Immutable.Map({}))}>Add</Button>
                        </Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        {(documents || []).filter((document) => {
                            return (currentsite == 'All Sites' || document.get('site') == currentsite)
                        }).map((document, index) => {
                            return (
                                <Grid.Row key={index}>
                                    {currentsite == 'All Sites' && <Grid.Column classes={'col-span-16 md:col-span-3'}>{document.get('site')}</Grid.Column>}
                                    <Grid.Column>
                                        {document.get('type')}
                                    </Grid.Column>
                                    <Grid.Column>
                                        {document.get('document')}
                                    </Grid.Column>
                                    <Grid.Column classes='col-span-16 md:col-span-1'>
                                        <Button className="w-100" variant="primary" onClick={this.selectDocument.bind(this, document)}>Edit</Button>
                                    </Grid.Column>
                                    <Grid.Column classes='col-span-16 md:col-span-1'>
                                        <Button className="w-100" variant="danger" onClick={this.removeDocument.bind(this, document)}>Del</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        })}
                    </Grid.Data>
                </Grid>
            </div>
        );
    }
}

export default DocumentList;
