import React, { useState, useEffect, useMemo, useCallback, useRef, Fragment } from 'react';
import Graphic from './Graphic';
// import Graphic from './GraphicV2';
import ComponentSelectionDialog from './ComponentSelectionDialog';
import PointListDialog from './PointListDialog';
import Immutable, { List } from 'immutable';
import { connect, useSelector } from 'react-redux';
import ComponentConfigurationDialog from './ComponentConfigurationDialog';
import { getArea, saveArea, updateNodeConfiguration } from './Actions';
import ServiceBus from 'Services/ServiceBus';
import PointStatusDialog from './PointStatusDialog';
import PointListStatusDialog from './PointListStatusDialog';
import HistoriesDialog from './HistoriesDialog';
import ReportsDialog from './ReportsDialog';
import EmailFormDialog from './EmailFormDialog';
import Excel500WeeklyScheduleDialog from 'BmsView/Customer/Configuration/Subsystems/Ex500/WeeklyScheduleDialog';
import ControllerPointListDialog from 'BmsView/Customer/Configuration/ControllerPointListDialog';
import ConversionDialog from './ConversionDialog';
import Swal from 'sweetalert2';
import './Reducers';
import './PointControl/Reducers';
// import history from 'BmsView/History';
import { useArea } from '../../../../Context/Area';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useUserSettings } from '../../../../Context/UserSettings';
import { useCustomerSettings } from '../../../../Context/CustomerSettings';
import LeftSlide from '../../../Layout/LeftSlide';
import AreaSidebar from 'BmsView/Customer/Site/Area/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useBMS } from '../../../Context/BMS';
import AreaSaveButton from './SaveAreaButton';
import useAreaCustomer from './Hooks/useAreaCustomer';
import useAreaSite from './Hooks/useAreaSite';
import { useAreaDialogContext } from '../../../AreaDialog/Context/';

// V1 area link example
// /customer/wvac/site/north-campus/mk-block/mitsubishi-units/mk-print-room

// V2 area link example
// /customer/wvac/site/north-campus/mk-block-v2/mitsubishi-units/mk-reception

export const POLLING_TIME = 30000;
export const INITIAL_POLLING = 2000;
export const DELAY_TIME = 2000;

function Area(props) {

    const helpers = useRef({});

    let p1 = useRef(null);

    const [loading, setLoading] = useStateIfMounted();
    const [polling, setPolling] = useStateIfMounted();
    const componentSelectedCategory = useSelector(state => state.get('componentSelectedCategory'));
    const editmode =  useSelector(state => state.get('editmode'));
    // const customer = useSelector(state => state.get('customer'));
    // const site = useSelector(state => state.get('site'));

    const { openDialog } = useAreaDialogContext();

    // const currentuser = useSelector(state => state.get('currentUser'));

    const { currentUser:currentuser } = useBMS();
    const { customer } = useAreaCustomer();
    const { site } = useAreaSite();

    const openAreaDialog = (url) => {
        openDialog(url);
    }
    // console.log("customer in v1 area", customer);
    // console.log("site in v1 area", site);

    let editmodeAuthorized = false;

    if (customer && site) {
        let signature = `${customer.get('name').toLcHyphen()}:${site.get('name').toLcHyphen()}`;
        let editAuthorization = currentuser.getIn(['sites'], Immutable.List([])).filter((siteauth) => {
            if (!siteauth) {
                return false;
            }
            return siteauth.get('name') == signature && siteauth.get('canedit');
        }).first();
        editmodeAuthorized = !!editAuthorization;
    }



    const showMobileGraphic = useSelector(state => state.get('showMobileGraphic'));

    const { userSettings } = useUserSettings();
    const { customerSettings } = useCustomerSettings();

    const navigate = useNavigate();


    const [localState, setLocalState] = useStateIfMounted({ 
        points: {},
        conversionDialogOpened: false,
        conversionCoords: { x: 0, y: 0 }  
    }); 

    const { points:localPoints, setPoints: setLocalPoints } = useArea();
    const { nodes, setNodes } = useArea();
    const { nodesLastUpdate, setNodesLastUpdated } = useArea();

    const startPollingAfter = Number(userSettings.get('startPollingAfter') || customerSettings.get('startPollingAfter') || INITIAL_POLLING);
    const pollingInterval = Number(userSettings.get('pollingInterval') || customerSettings.get('pollingInterval') || POLLING_TIME);


    helpers.current = {
        getCustomer: () => customer,
        getCurrentUser: () => currentuser,
        getLocalPoints: () => localPoints,
        getLocalState: () => localState,
        getNodes: () => nodes,
        getEditMode: () => editmode,
        getProps: () => props,
        setNodes: () => setNodes,
        setNodesLastUpdated: () => setNodesLastUpdated
    }

    const getPin = (subsystemNumber) => {
        let customer = helpers.current.getCustomer()
        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }

    const commandPoint = (selectedpoint, type, value) => {
        let customer = helpers.current.getCustomer();
        let currentuser = helpers.current.getCurrentUser();
        
        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');
        let subsystemNumber = address.map((value) => { return parseInt(value); })[0];
        if (type >= 2){
            value = parseFloat(value);
        }
        else{
            type = value;
        }
        let request = {
            type: 'GENERIC',
            m_level: 10,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 301,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: address.map((value) => { return parseInt(value); }),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_typeOfCommand: type,
            m_priority: getPin(subsystemNumber),
            m_value: value,
            m_engUnitGuarded: 0,
            m_oldValue: selectedpoint.getIn(['pointconfiguration', 'm_value'])
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const processPointStatus = (message) => {
        let localState = helpers.current.getLocalState();
        let localPoints = helpers.current.getLocalPoints();
        
        let { m_pointStatusData } = message;
        if (!m_pointStatusData) {
            m_pointStatusData = message;
        }
        let { currentNode, pointlistnode } = localState;
        let points = localPoints;

        if (m_pointStatusData && m_pointStatusData.m_pointAddr) {
            let address = [
                m_pointStatusData.m_pointAddr[0],
                m_pointStatusData.m_pointAddr[1],
                m_pointStatusData.m_pointAddr[2],
                m_pointStatusData.m_pointAddr[3],
                m_pointStatusData.m_pointAddr[4],
                m_pointStatusData.m_pointAddr[5],
                m_pointStatusData.m_pointAddr[6],
                m_pointStatusData.m_pointAddr[7],
                m_pointStatusData.m_pointAddr[8],
                m_pointStatusData.m_pointAddr[9]
            ].join('-');
            
            if (points[address]) {
                points[address].value = m_pointStatusData.m_formattedValueText;
                points[address].components.forEach((component) => {
                    m_pointStatusData.address = address;
                    component.setValue(m_pointStatusData);
                });
                points[address].pointconfiguration = m_pointStatusData;

                if (currentNode && currentNode.getIn(['configuration', 'boundto']) == address) {
                    setLocalState({...localState,
                        currentNode: currentNode.set('pointconfiguration', Immutable.fromJS(m_pointStatusData))
                            .set('rawvalue', m_pointStatusData.m_value)
                            .set('currentvalue', points[address].value)
                            .set('currentalarmstate', m_pointStatusData.m_formattedAlarmText)
                            .set('lastcommanded', m_pointStatusData.m_whoCommanded)
                            .set('pointname', m_pointStatusData.m_pointName)
                            .set('description', m_pointStatusData.m_description)
                            .set('error', m_pointStatusData.m_errorNumber)
                    });
                }
                if (pointlistnode) {
                    setLocalState({...localState,
                        pointlistnode: pointlistnode.updateIn(['configuration', 'points'], (currentPoints) => {
                            return currentPoints.map((point) => {
                                if (point.get('value') == address) {
                                    point = point
                                        .set('currentvalue', points[address].value)
                                        .set('currentalarmstate', m_pointStatusData.m_formattedAlarmText)
                                        .set('rawvalue', m_pointStatusData.m_value)
                                        .set('lastcommanded', m_pointStatusData.m_whoCommanded)
                                        .set('pointname', m_pointStatusData.m_pointName)
                                        .set('description', m_pointStatusData.m_description)
                                        .set('pointconfiguration', Immutable.fromJS(m_pointStatusData))
                                        .set('error', m_pointStatusData.m_errorNumber);
                                }
                                return point;
                            });
                        })
                    });
                }
            }
        }
        points.lastupdated = Date.now();
        setLocalPoints(() => ({...points}));
    }

    const pointUpdated = (point) => {
        let customer = helpers.current.getCustomer();
        
        let request = {
            trackMessage: true,
            m_level: 10,
            m_communicNum: 300,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: point.getIn(['configuration', 'boundto']).split('-')[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: point.getIn(['configuration', 'boundto']).split('-').map((bit) => { return parseInt(bit); }),
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const queuePoints = (queue) => {
        let localPoints = helpers.current.getLocalPoints();
        let points = {};
        queue.forEach((args) => {
            let [point, componentHandle, node] = args;
            if (!points[point]) {
                points[point] = {
                    components: []
                }
            }
            points[point].node = node;
            if (componentHandle) {
                points[point].components.push(componentHandle);
            }
        })
        setLocalPoints({...points});
    }

    // uses state (points) only used by graphic
    const queuePoint = (point, componentHandle, node) => {
        let localPoints = helpers.current.getLocalPoints();
        
        let points = localPoints;
        if (!points[point]) {
            points[point] = {
                components: []
            }
        }
        points[point].node = node;
        if (componentHandle) {
            points[point].components.push(componentHandle);
        }
        setLocalPoints({...points});
    }

    // uses state (points) only used by graphic
    const unqueuePoint = (componentHandle, address) => {
        let localPoints = helpers.current.getLocalPoints();
        let points = localPoints;
        if (!points) return;
        let newpoints = {};
        let newcomponents = [];
        for (let handle in points[address].components) {
            if (points[address].components[handle] != componentHandle) {
                newcomponents.push(points[address].components[handle]);
            }    
        }    
        points[address].components = newcomponents;
        for (let i in points) {
            if ( i != 'lastupdated' ) {
                if ( points[i].components.length != 0 ) {
                    newpoints[i] = points[i];
                }    
            }    
        }
        setLocalPoints(() => ({...points}));
    }

    // only used by graphics
    const onGraphFinish = () => {
        let dt = new Date();
        // console.log('graphic loaded, sending status @ ' + dt.getHours() + ':' + dt.getMinutes()+ ':' + dt.getSeconds());
    //    setTimeout(() => { this.getPointsStatusses(); }, DELAY_TIME);

        getPointsStatusses();
    }

    // sets state only used by graphics
    const clearQueue = () => {
        // console.log('clearing q');
        setLocalPoints(() => {});
    }

    // sets state (nodes, updated) only used by graphics
    const nodeChanged = (node, index) => {
        let setNodes = helpers.current.setNodes();
        let setNodesLastUpdated = helpers.current.setNodesLastUpdated();
        setNodes(nodes => nodes.set(index, node));
        setNodesLastUpdated(() => Date.now());
    }

    // only used by graphics, sets state (nodes, updated, action)
    const nodeMovedToFront = (node, index) => {
        let nodes = helpers.current.getNodes();
        let firstNode = nodes.get(index);
        let secondNode = nodes.get(index + 1);
        // console.log(`firstNode, ${index}, secondNode, ${index + 1}`);
        if(!secondNode) {
            return { nodes }
        }
        let mutatedList = nodes.withMutations(nodes => {
            firstNode = nodes.get(index);
            secondNode = nodes.get(index + 1);
            return nodes.set(index, secondNode)
            .set(index + 1, firstNode) 
        })
        setLocalState((localState) => ({...localState, action: 'placement' }))
        setNodes(() => mutatedList);
        setNodesLastUpdated(() => Date.now());
    }

    // only used by graphics, sets state (nodes, updated, action)
    const nodeMovedToBack = (node, index) => {
        let nodes = helpers.current.getNodes();
        // console.log("index of moved to back", index);
        let firstNode = nodes.get(index);
        let secondNode = nodes.get(index - 1);
        // console.log(`firstNode, ${index}, secondNode, ${index - 1}`);
        if(!secondNode || (index - 1) < 0) {
            return { nodes }
        }
        setLocalState((localState) => ({...localState, action: 'placement' }))
        setNodes(nodes => nodes.withMutations(nodes => nodes.set(index, secondNode).set(index - 1, firstNode)));
        setNodesLastUpdated(() => Date.now());
    }

    // uses points out of state, 
    const getPointsStatusses = (randomNumber) => {
        // console.log("getPointsStatusses is called");
        let editmode = helpers.current.getEditMode();
        let customer = helpers.current.getCustomer();
        let localPoints = helpers.current.getLocalPoints();
 
        // let { customer, site, editmode } = props;
        if (editmode) {
            return;
        }
        let points = localPoints;
        let tNow = Date.now();
        let poll = true;
        if (points['lastupdated'] && tNow > points['lastupdated'] + 3 * pollingInterval) poll = false;
        for (let i in points) {
            if (i != 'lastupdated') {
                let request = {
                    trackMessage: true,
                    m_level: 10,
                    m_communicNum: 300,
                    target: 'signalr',
                    type: "GENERIC",
                    m_subsystemNumber: i.split('-')[0],
                    databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
                }
                let comm = {
                    m_pointAddr: i.split('-').map((bit) => { return parseInt(bit); })
                }
                ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
            }
        }
    }

    const createNodeConfiguration = () => {
        const { currentNodeConfiguration } = props; 
        return currentNodeConfiguration.set('nodes', nodes)
    }

    // sets state (nodes, updated)
    const loadArea = useCallback((abortController) => {
        let props = helpers.current.getProps();
        let { currentNodeConfiguration, baseDirectory, customerMainDirectory } = props;
        if (!currentNodeConfiguration) {
            // console.log('removing nodes');
            setNodes(() => Immutable.List([]));
            setNodesLastUpdated(() => Date.now());
            return;
        }
        setLoading(() => 1);
        let promise = undefined;
        if(currentNodeConfiguration.get('hasTemplate')) {
            const setTemplateVariables = (templateString) => {
                let props = helpers.current.getProps();
                let { currentNodeConfiguration, baseDirectory, customerMainDirectory } = props;
                if(currentNodeConfiguration.get('templatevariables')) {
                    currentNodeConfiguration.get('templatevariables').map((val, key) => {
                        if ( key=="dbacsunit") {
                            let expr = templateString.substring(templateString.lastIndexOf("["), templateString.lastIndexOf("]") + 1);
                            var num = expr.replace(/[^0-9]/g,'');
                            if ( num != "" ) {
                                let newval = 256 * val + parseInt(num); 
                                templateString = templateString.replace(expr, newval);
                            } 
                        } else {
                            let expr = new RegExp(`\\[${key}\\]`, 'g');
                            // console.log(`replacing [${key}] with ${val}`);
                            templateString = templateString.replace(expr, val);
                        }    
                    })
                }
                return templateString;
            }    
            if(abortController.signal.aborted) {
                return;
            }
            promise = getArea(`${customerMainDirectory}_templates_${currentNodeConfiguration.get('template')}`, abortController ).promise.then((data) => {
                if(abortController.signal.aborted) {
                    return;
                }
                let props = helpers.current.getProps();
                let { currentNodeConfiguration, baseDirectory, customerMainDirectory } = props;
                let nodes = Immutable.fromJS(data);

                // console.log(`Loaded nodes ${typeof nodes}`);
                // console.log(nodes);

                nodes = nodes.map(x => x.set('_uuid', ))

                // console.log("template nodes", nodes);
                nodes = nodes.map(node => {   
                    if(node.getIn(['configuration','boundto'])) {
                        node = node.updateIn(['configuration','template_boundto'], x => node.getIn(['configuration','boundto']))
                        node = node.updateIn(['configuration','boundto'], x => setTemplateVariables(x))
                    }
                    if(node.getIn(['configuration','value'])) {
                        node = node.updateIn(['configuration','template_value'], x => node.getIn(['configuration','value']))
                        node = node.updateIn(['configuration','value'], x => setTemplateVariables(x))
                    }
                    if(node.getIn(['configuration','linkto'])) {
                        node = node.updateIn(['configuration','template_linkto'], x => node.getIn(['configuration','linkto']))
                        node = node.updateIn(['configuration','linkto'], x => setTemplateVariables(x))
                    }
                    if(node.getIn(['configuration','points'])) {
                        let points = node.getIn(['configuration','points']);
                        points = points.map(point => {
                            if(point.get('value')){
                                point = point.set('template_value', point.get('value'))
                                point = point.update('value', x=> setTemplateVariables(x))
                            }
                            return point;
                        })
                        node = node.setIn(['configuration','points'], points);
                    }
                    return node;
                })
                // console.log("template nodes after change", nodes);
                // updateNodeConfiguration(currentNodeConfiguration.set('nodes', nodes));
                setNodes(() => nodes);
            })  
        } else {
            let areapath = currentNodeConfiguration.get('path') || currentNodeConfiguration.get('name', '').toLowerCase().split(' ').join('-');
            if(abortController.signal.aborted) {
                return;
            }
            promise = getArea(`${baseDirectory}_${areapath}.json`, abortController).promise.then((data) => {
                if(abortController.signal.aborted) {
                    return;
                }
                let props = helpers.current.getProps();
                let { currentNodeConfiguration, baseDirectory, customerMainDirectory } = props;
                // console.log("resolved nodes from " + areapath);
                let newareapath = currentNodeConfiguration.get('path') || currentNodeConfiguration.get('name', '').toLowerCase().split(' ').join('-');
                // console.log("resolved nodes should be from " + newareapath);
                if(newareapath != areapath) {
                    return;
                }
                // updateNodeConfiguration(currentNodeConfiguration.set('nodes', Immutable.fromJS(data)));
                setNodes(() => Immutable.fromJS(data));
            })
        }
        promise.then(() => {
            if(abortController.signal.aborted) {
                return;
            }
            setLoading(() => 0);
        })
        promise.catch((err) => {
            if(abortController.signal.aborted) {
                return;
            }
            // console.log('err removing nodes');
            if (err && err.response && err.response.status == 404) {
                // console.log("%c Area not found", "color: red; font-size: 20px;");
                setNodes(() => Immutable.fromJS([]));
                setNodesLastUpdated(() => Date.now());
            }
            setLoading(() => 0);
        });
    }, [props.currentNodeConfiguration]);



    // in here should be created some logic that saved the area  saving the area
    // uses nodes from state
    const localSaveArea = (_nodes) => {
        let nodes = helpers.current.getNodes();
        let props = helpers.current.getProps();
        // console.log("called once");
        let { currentNodeConfiguration, baseDirectory, siteMainDirectory, customerMainDirectory } = props;
        let nodesToSave = _nodes || nodes;
        let path = `${baseDirectory}_${currentNodeConfiguration.get('name').toLowerCase().split(' ').join('-')}.json`;
        if(currentNodeConfiguration.get('hasTemplate')) {
            nodesToSave = nodesToSave.map(node => {   
                if(node.getIn(['configuration','template_boundto'])) {
                    node = node.updateIn(['configuration','boundto'], x => node.getIn(['configuration','template_boundto']))
                    node = node.deleteIn(['configuration','template_boundto']);
                }
                if(node.getIn(['configuration','template_value'])) {
                    node = node.updateIn(['configuration','value'], x => node.getIn(['configuration','template_value']));
                    node = node.deleteIn(['configuration','template_value']);
                }
                if(node.getIn(['configuration','template_linkto'])) {   
                    node = node.updateIn(['configuration','linkto'], x => node.getIn(['configuration','template_linkto']))
                    node = node.deleteIn(['configuration','template_linkto']);
                }
                if(node.getIn(['configuration','points'])) {
                    let points = node.getIn(['configuration','points']);
                    points = points.map(point => {
                        if(point.get('template_value')){
                            point = point.set('value', point.get('template_value'))
                            point = point.delete('template_value')
                        }
                        return point;
                    })
                    node = node.setIn(['configuration','points'], points);
                }
                return node;
            })
            path = `${customerMainDirectory}_templates_${currentNodeConfiguration.get('template')}`;
        }
        let rawNodes = nodesToSave.toJS();
        
        // console.log("supposedly saving the area but no nodes?", nodesToSave);
        
        saveArea(rawNodes, path).then(() => {
            Swal.fire("Success", "The area is saved successfully", "success");
            // updateNodeConfiguration(currentNodeConfiguration.set('nodes', nodesToSave));
        }).catch((err) => {
            Swal.fire("Error", "An error occurred while saving the area", "error");
        });
    }

    // sets state(conversionDialogOpened) used by graphics onConvertArea
    const toggleConversionDialogOpened = () => {
        setLocalState((localState) => ({...localState,
            conversionDialogOpened: !localState.conversionDialogOpened
        }))
    }

    // sets state(conversionCoords) not used by graphics
    const changeConversionCoords = (x, y) => {
        setLocalState((localState) => ({ ...localState, conversionCoords: { x, y } }));
    }

    // uses state (nodes, conversionCoords )
    // sets state (nodes, conversionDialogOpened )
    const convertArea = () => {
        let localState = helpers.current.getLocalState();
        let props = helpers.current.getProps();
        let nodes = helpers.current.getNodes();
        
        let { conversionCoords } = localState;
        let { currentNodeConfiguration, baseDirectory, customerMainDirectory } = props;
        let path = `${baseDirectory}_${currentNodeConfiguration.get('name').toLowerCase().split(' ').join('-')}.json`;
        let x = Number(conversionCoords.x);
        let y = Number(conversionCoords.y);
        let changedNodes = nodes.map((node) => {
            node = node && node.setIn(['position', 'x'], node.getIn(['position', 'x']) + (x) );
            node = node && node.setIn(['position', 'y'], node.getIn(['position', 'y']) + (y) );
            return node;
        })

        if(currentNodeConfiguration.get('hasTemplate')) {
            changedNodes = changedNodes.map(node => {   
                if(node.getIn(['configuration','template_boundto'])) {
                    node = node.updateIn(['configuration','boundto'], x => node.getIn(['configuration','template_boundto']))
                    node = node.deleteIn(['configuration','template_boundto']);
                }
                if(node.getIn(['configuration','template_value'])) {
                    node = node.updateIn(['configuration','value'], x => node.getIn(['configuration','template_value']));
                    node = node.deleteIn(['configuration','template_value']);
                }
                if(node.getIn(['configuration','template_linkto'])) {   
                    node = node.updateIn(['configuration','linkto'], x => node.getIn(['configuration','template_linkto']))
                    node = node.deleteIn(['configuration','template_linkto']);
                }
                if(node.getIn(['configuration','points'])) {
                    let points = node.getIn(['configuration','points']);
                    points = points.map(point => {
                        if(point.get('template_value')){
                            point = point.set('value', point.get('template_value'))
                            point = point.delete('template_value')
                        }
                        return point;
                    })
                    node = node.setIn(['configuration','points'], points);
                }
                return node;
            })
            path = `${customerMainDirectory}_templates_${currentNodeConfiguration.get('template')}`;
        }


        let rawNodes = changedNodes.toJS();
        saveArea(rawNodes, path).then(() => {
            // updateNodeConfiguration(currentNodeConfiguration.set('nodes', changedNodes));
            setLocalState(() => ({...localState,
                conversionDialogOpened: false
            }));
            setNodes(() => changedNodes);
            Swal.fire("Success", "The area is saved successfully", "success");
        }).catch((err) => {
            setLocalState({...localState,
                conversionDialogOpened: false
            });
            Swal.fire("Error", "An error occurred while saving the area", "error");
        });
    }
    
    // sets state (action, currentNode)
    // only used by graphics
    const createNode = (evt, type) => {
        setLocalState((localState) => ({...localState,
            action: type || 'creation',
            currentNode: Immutable.fromJS({
                position: {
                    x: evt.x,
                    y: evt.y
                }
            })
        }));
    }

    // sets state (action, currentNode, nodes, updated) not used by graphics
    const placeNode = (node) => {
        let nodes = helpers.current.getNodes();
        setLocalState((localState) => ({...localState,
            action: 'placement',
            currentNode: undefined
        }));
        setNodes(nodes.push(node));
        setNodesLastUpdated(Date.now());
    }

    // sets state(nodes, updated, currentNodeIndex, currentNode, chartNode, emailNode, ex500TimeProgramNode, ex500TimeProgramNode, controllerPointListNode, action)
    const saveNode = (node) => {
        let localState = helpers.current.getLocalState();
        let nodes = helpers.current.getNodes();
        
        if (node.get('pointconfiguration')) {
            node = node.delete('pointconfiguration');
        }

        let { currentNodeIndex } = localState;
        if (currentNodeIndex === undefined) {
            setNodes(nodes.push(node));
            setNodesLastUpdated(Date.now());
            return setLocalState({...localState,
                currentNodeIndex: undefined,
                currentNode: undefined,
                chartNode:undefined,
                reportNode:undefined,
                emailNode:undefined,
                ex500TimeProgramNode:undefined,
                controllerPointListNode:undefined,
                action: undefined
            });
        }
        setNodes(nodes.set(currentNodeIndex, node));
        setNodesLastUpdated(Date.now());
        return setLocalState(({ nodes, currentNodeIndex }) => ({...localState,
            currentNodeIndex: undefined,
            currentNode: undefined,
            chartNode:undefined,
            reportNode:undefined,
            emailNode:undefined,
            ex500TimeProgramNode:undefined,
            controllerPointListNode:undefined,
            action: undefined
        }));
    }

    // called by graphic onNodeConfiguration sets state (pointlistnode)
    // state(chartNode, action, currentNodeIndex)
    // state(emailNode, action, currentNodeIndex)
    // state(ex500TimeProgramNode, action, currentNodeIndex)
    // state(controllerPointListNode, action, currentNodeIndex)
    // state(currentNode, action, currentNodeIndex)
    const configureNode = (index, node, options) => {
        let localState = helpers.current.getLocalState();
        let editmode = helpers.current.getEditMode();
        let localPoints = helpers.current.getLocalPoints();

        
        if (index == undefined){
            index = node.get('_ownindex');
        }

        // let { openAreaDialog } = props;
        // console.log("configureNode editmode", editmode);
        let action = editmode && 'placement';
        if (node.getIn(['component', 'type']) == 'pointlist') {
            action = 'pointlist';  
            if (!editmode) {
                let points = localPoints;
                node = node.updateIn(['configuration', 'points'], (arr) => {
                    arr = arr.map((point) => {
                        let existingpoint = points[point.get('value')];
                        if (!existingpoint || !existingpoint.pointconfiguration) {
                            return point;
                        }
                        return point
                            .set('currentvalue', existingpoint.pointconfiguration.m_formattedValueText)
                            .set('rawvalue', existingpoint.pointconfiguration.m_value)
                            .set('currentalarmstate', existingpoint.pointconfiguration.m_formattedAlarmText)
                            .set('lastcommanded', existingpoint.pointconfiguration.m_whoCommanded)
                            .set('pointname', existingpoint.pointconfiguration.m_pointName)
                            .set('description', existingpoint.pointconfiguration.m_description)
                            .set('pointconfiguration', Immutable.fromJS(existingpoint.pointconfiguration))
                    })
                    return arr;
                })
                return setLocalState({...localState,
                    pointlistnode: node
                });
            }
        }

        if (!action && node.getIn(['configuration', 'islink'])) {

            if(node.getIn(['configuration','isdialog'])) {

                openAreaDialog(node.getIn(['configuration', 'linkto']));
            } else {
                navigate(node.getIn(['configuration', 'linkto']));
            }

            
            // history.push(node.getIn(['configuration', 'linkto']));
            return;
        }

        if (node.getIn(['configuration', 'directcommand'])) {
            let texts = node.getIn(['pointconfiguration', 'm_stateText'], Immutable.List([])); //.toJS();
            let value =node.getIn(['pointconfiguration', 'm_value']);

            Swal.fire({
                title: `Command ${node.getIn(['pointconfiguration', 'm_pointName'])} to`,
                text: `${texts[value == 1 ? 0 : 1]}`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(
                commandPoint(node, value == 1 ? 0 : 1, value == 1 ? 0 : 1)
            )
            return;
        }

        if (node.getIn(['component', 'type']) == 'chart') {
            setLocalState({...localState,
                chartNode: node,
                action: action,
                currentNodeIndex: index
            });

        } else if (node.getIn(['component', 'type']) == 'report') {
            setLocalState({...localState,
                reportNode: node,
                action: action,
                currentNodeIndex: index
            });

        } else if (node.getIn(['component', 'type']) == 'systemobject') {
            if (node.getIn(['component', 'subtype']) == 'email') {
                setLocalState({...localState,
                    emailNode: node,
                    action: action,
                    currentNodeIndex: index
                }); 
            } else if (node.getIn(['component', 'subtype']) == 'ex500timeprogram') {
                setLocalState({...localState,
                    ex500TimeProgramNode: node,
                    action: action,
                    currentNodeIndex: index
                }); 
            } else if (node.getIn(['component', 'subtype']) == 'controllerpointlist') {
                setLocalState({...localState,
                    controllerPointListNode: node,
                    action: action,
                    currentNodeIndex: index
                });
            }
        } else {
            setLocalState({...localState,
                currentNode: node,
                action: action,
                currentNodeIndex: index
            });
        } 
    }

    // sets state(currentNode) => not used by Graphics
    const selectNode = (node) => {
        let rawNode = node.toJS();
        setLocalState((localState) => ({...localState,
            currentNode: node
        }));
    }

    // sets state(nodes) => only used by graphics
    const deleteNode = (index, node, shouldSaveArea) => {
        let nodes = helpers.current.getNodes();
        if(shouldSaveArea) {
            
            localSaveArea(nodes.delete(index));
        }
        setNodes(nodes => nodes.delete(index));
    }

    // sets state(currentNode, currentNodeIndex, chartNode, emailNode, ex500TimeProgramNode, action) not used by graphics
    const clearSelection = () => {
        setLocalState((localState) => ({...localState,
            currentNode: undefined,
            currentNodeIndex: undefined,
            chartNode:undefined,
            reportNode:undefined,
            emailNode:undefined,
            ex500TimeProgramNode:undefined,
            action: undefined
        }));
    }

    // sets state(pointlistnode) not used by graphics
    const clearPointlist = () => {
        setLocalState((localState) => ({...localState,
            pointlistnode: undefined
        }));
    }

    // sets state(controllerPointListNode) not used by graphics
    const clearControllerPointListDialog = () => {
        setLocalState((localState) => ({...localState,
            controllerPointListNode: undefined
        }));
    }


    // componentDidMount
    useEffect(() => {
        let props = helpers.current.getProps();
        // const abortController = new AbortController();
        // let { baseDirectory, params } = props;
        // let op saveArea kwam eerst van this
        // updateNodeConfiguration(currentNodeConfiguration, indexOfCurrentNodeConfiguration);
        // console.log("processPointStatus", processPointStatus);
        let unbindPointStatus = ServiceBus.on('300-11', processPointStatus, true);
        //setPolling(setInterval(getPointsStatusses, POLLING_TIME));
        let randomNumber = Math.random();
        // console.log("%c This mount created " + randomNumber + "as random number", "color:blue;font-size:18px;");

        p1.current = setInterval(() => { 
            getPointsStatusses(randomNumber);
        }, pollingInterval);

        setTimeout(() => {
            getPointsStatusses(randomNumber);
        }, startPollingAfter)

        // this is here to avoid switching between customers and to avoid invalid refresh when load Area of 
        // malformed directory
        // let baseCustomerDirectory = baseDirectory.split('_');
        // if ( baseCustomerDirectory[0] + '_' + baseCustomerDirectory[1] == params.customer + '_' + params.site ) {
        //     console.log("12-06-2023 AREA MOUNT ORIGINAL MOUNT");
        //     loadArea(abortController);  // commented out because of loading twice problem
        // } 
        
        // component will unmount
        return () => {
            // console.log("Area UnInitializing");
            // console.log("%c index component seems to be unmount", "font-size:20px;color:yellow")
            // abortController.abort();
            setLocalPoints(() => ({}));
            setNodes(() => List())
            unbindPointStatus();
            //clearInterval(polling);
            // console.log("%c This unmount should have removed " + randomNumber + "as random number", "color:blue;font-size:18px;");
            clearInterval(p1.current);
            
            // if(!props.renderedInDialog) {
            //     setSaveAreaHandler(undefined);
            // }
        }

    }, []);

    // component did update pt1
    useEffect(() => {
        let editmode = helpers.current.getEditMode();
        let props = helpers.current.getProps();
        
        // This needs to run on every rerender else it will lose track of dependencies
        // if(!editmode && !props.renderedInDialog) {
        //     setSaveAreaHandler(undefined);
        // }
        // if(editmode && !props.renderedInDialog) {
        //     setSaveAreaHandler(() => localSaveArea())
        // }
    });

    useEffect(() => {
        
        const abortController = new AbortController();
        let { baseDirectory } = props;
        let customerName = site.get('customerName');
        let siteName = site.get('siteName');
        let baseCustomerDirectory = baseDirectory.split('_');
        if ( baseCustomerDirectory[0] + '_' + baseCustomerDirectory[1] == customerName + '_' + siteName ) {
            loadArea(abortController);
        }
    
        return () => {
            abortController.abort();
        }

    }, [props.baseDirectory, props.currentNodeConfiguration, site]);

    useEffect(() => {
        if(!editmode) {
            getPointsStatusses();
        }
    }, [editmode]);

    let { currentNodeConfiguration, renderedInDialog } = props;
    let { conversionCoords, conversionDialogOpened, currentNode, chartNode, reportNode, emailNode, ex500TimeProgramNode, controllerPointListNode, action, pointlistnode, controllerpointlistdialognode } = localState;
    let points = localPoints;

    if (!currentNodeConfiguration) {
        return <data></data>
    }

    // console.log("nodes", nodes);

    return (
        <React.Fragment>
            { !renderedInDialog && editmodeAuthorized && editmode && <AreaSaveButton onSave={localSaveArea} />  }
            { !renderedInDialog && <Fragment>
                <LeftSlide>
                    <AreaSidebar 
                        match={ {  params: [site.get('nodepath')] } }
                        nodeconfigurationindex={props.indexOfCurrentNodeConfiguration}
                        nodeconfiguration={createNodeConfiguration()} />
                </LeftSlide>
                </Fragment> }
            <ConversionDialog
                selecteditem={conversionDialogOpened}
                changeCoords={changeConversionCoords}
                x={conversionCoords.x}
                y={conversionCoords.y}
                onSave={convertArea}
                onClose={toggleConversionDialogOpened} />
            <ComponentSelectionDialog 
                selectedCategory={componentSelectedCategory}
                customer={customer} 
                site={site} 
                selecteditem={action == 'creation' && currentNode} 
                placeNode={placeNode} 
                onClose={clearSelection} />
            <ComponentConfigurationDialog 
                customer={customer} 
                selecteditem={action == 'placement' && (currentNode || chartNode || reportNode || emailNode || ex500TimeProgramNode || controllerPointListNode)} 
                onSave={saveNode} 
                onClose={clearSelection} />
            <PointListDialog 
                selecteditem={action == 'pointlist' && currentNode} 
                onSave={saveNode} 
                onClose={clearSelection} />
            <PointStatusDialog 
                currentuser={currentuser} 
                customer={customer} 
                site={site} 
                selecteditem={!action && currentNode && currentNode.getIn(['configuration', 'boundto']) && currentNode} 
                onClose={clearSelection} 
                updatePoint={pointUpdated} />
            <PointListStatusDialog 
                lastupdated={points.lastupdated} 
                onSelect={selectNode} 
                selecteditem={!currentNode && pointlistnode || undefined} 
                onClose={clearPointlist} />
            <HistoriesDialog 
                customer={customer} 
                selecteditem={!action && chartNode && chartNode.getIn(['configuration', 'boundto']) && chartNode} 
                onClose={clearSelection} />
            <ReportsDialog 
                customer={customer} 
                selecteditem={!action && reportNode && reportNode.getIn(['configuration', 'points']) && reportNode} 
                onClose={clearSelection} />
            <EmailFormDialog 
                currentuser={currentuser} 
                customer={customer} 
                selecteditem={!action && emailNode} 
                onClose={clearSelection} />
            <Excel500WeeklyScheduleDialog 
                currentuser={currentuser} 
                customer={customer} 
                selecteditem={!action && ex500TimeProgramNode} 
                timeProgramName={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'objectName'], '')}
                subsystemNumber={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'subsystemNumber'], 0)}
                controllerNumber={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'controllerNumber'], 1)}
                timeProgramIndex={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'timeProgramIndex'], 0)}
                onClose={clearSelection} />  
            { controllerPointListNode && !editmode && <ControllerPointListDialog
                currentuser={currentuser} 
                customer={customer} 
                onSelect={selectNode} 
                selecteditem={!action && controllerPointListNode} 
                subsystemNumber={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'subsystemNumber'], 0)}
                subsystemType={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'subsystemType'], 5)}
                controllerNumber={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'controllerNumber'], 1)}
                networkNumber={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'networkNumber'], 0)}
                pointtype={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'pointType'], 0)}
                onClose={clearControllerPointListDialog} />}  
            {!loading && <Graphic
                // key={(this.state.updated || "").toString()}
                key={(showMobileGraphic ? "showMobileGraphic" : "dontShowMobileGraphic")}
                onConvertArea={currentuser && currentuser.get('isSuperAdmin') ? toggleConversionDialogOpened : undefined}
                points={points}
                backdrop={currentNodeConfiguration.getIn(['backdrop', 'file'])}
                template={currentNodeConfiguration.get('template')}
                onNodeCreation={createNode}
                nodeChanged={nodeChanged}
                nodeMovedToBack={nodeMovedToBack}
                nodeMovedToFront={nodeMovedToFront}
                onNodeConfiguration={configureNode}
                configureNode={configureNode}
                queuePoints={queuePoints}
                queuePoint={queuePoint}
                unqueuePoint={unqueuePoint}
                clearQueue={clearQueue}
                editmode={editmode}
                deleteNode={deleteNode}
                customer={customer}
                site={site}
                onLoaded={onGraphFinish}
                pointUpdated={pointUpdated}
                showMobileGraphic={showMobileGraphic}
                renderedInDialog={renderedInDialog}
            /> }
        </React.Fragment>
    )

}

export default Area;



