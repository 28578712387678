import React from 'react';
import ModuleTypes from 'Configuration/ModuleTypes';
import AdvancedPointStatus from './AdvancedPointStatus';
import Timezones from './Timezones';
import AdvancedTimezones from './AdvancedTimezones';
import TimezoneExceptions from './TimezoneExceptions'
import Trending from './Trending';
import Logging from './Logging';
import Calibration from './Calibration';
import Asset from './Asset';
import PointProperties from './PointProperties';
import Immutable from 'immutable';
import PointEditDialog from 'BmsView/Customer/Configuration/PointEditor/PointEditDialog';
import PointPropertiesDialog from './PointPropertiesDialog';
import TransitioningDialog from '../../../../../Controls/Dialog/TransitioningDialog';
import Button from 'Controls/Button/UserDefined'; 

const redButtonClassName = "whitespace-nowrap inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm";
const greenButtonClassName = "whitespace-nowrap inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm";

const statusComponents = {
    'advancedpointstatus': AdvancedPointStatus,
    'timezone': Timezones,
    'advancedtimezone': AdvancedTimezones,
    'timezoneexceptions': TimezoneExceptions,
    'trending': Trending,
    'logging': Logging,
    'calibration': Calibration,
    'pointproperties': PointProperties,
    'asset': Asset
}

class PointStatusDialog extends React.Component {
    constructor(props) {
        super(props);
        let selectedpoint = Immutable.fromJS(props.selecteditem.toJS());
        this.state = { 
            selectedpoint: selectedpoint,
            activeComponent: selectedpoint && this.getInitialSelectedTab(selectedpoint) || 'advancedpointstatus',
            selectedpointforedit: undefined
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.selecteditem != oldProps.selecteditem && this.props.selecteditem) {
            this.setState({
                selectedpoint: Immutable.fromJS(this.props.selecteditem.toJS())
            });
        }
    }

    showPointEditDialog() {
        let {selectedpoint} = this.state;

        this.setState({
            selectedpointforedit: selectedpoint.getIn(['configuration', 'boundto']).split('-')
        })
    }

    showPointPropertiesDialog(type) {
        let {selectedpoint} = this.state;

        this.setState({
            selectedpointforproperties: selectedpoint,
            propertytype:type
        })
    }

    clearSelection() {
        this.setState({
            selectedpointforedit: undefined,
            propertytype: undefined,
            selectedpointforproperties: undefined
        })
    }

    getInitialSelectedTab(selectedpoint) {
        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');
        let hasTrending = !!selectedpoint.getIn(['pointconfiguration', 'm_isIntegratorTrendingSetup']);
        let hasLogging = address[1] == 5 && address[2] == 2;
        let hasAsset = address[2] != 4 && address[2] != 12;
        // check in address to see if this has timezone
        let hasTimezone = 
            (address[1] == 5 && address[2] == 4) || 
            (address[1] == 5 && address[2] == 12) || 
            (address[1] == 11 && address[2] == 9) || 
            (address[1] == 9 && address[2] == 17);
        let hasExceptions = (address[1] == 5 && address[2] == 12) || (address[1] == 11 && address[2] == 9);
        let hasAdvancedTimezone = (address[1] == 9 && address[2] == 22);
        let hasCalibration = address[2] == 2;

        let defaultTab = 'advancedpointstatus';
        if(hasAsset && selectedpoint.getIn(['configuration','defaulttab']) == 'asset') {
            defaultTab = 'asset';
        } else if(hasTrending && selectedpoint.getIn(['configuration','defaulttab']) == 'trending') {
            defaultTab = 'trending';
        } else if(hasLogging && selectedpoint.getIn(['configuration','defaulttab']) == 'logging') {
            defaultTab = 'logging';
        } else if(hasTimezone && selectedpoint.getIn(['configuration','defaulttab']) == 'timezone') {
            defaultTab = 'timezone';
        } else if(hasAdvancedTimezone && selectedpoint.getIn(['configuration','defaulttab']) == 'advancedtimezone') {
            defaultTab = 'advancedtimezone';
        } else if(hasExceptions && selectedpoint.getIn(['configuration','defaulttab']) == 'timezoneexceptions') {
            defaultTab = 'timezoneexceptions';
        } else if(hasCalibration && selectedpoint.getIn(['configuration','defaulttab']) == 'calibration') {
            defaultTab = 'calibration';
        } 
        return defaultTab;
    }

    render() {
        let { selectedpoint, selectedpointforedit, selectedpointforproperties, propertytype, activeComponent } = this.state;
        let { onClose, updatePoint, customer, site, currentuser, selecteditem } = this.props;
        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');

        let activeModuleType = ModuleTypes[address[1]];
        let EmptyComponent = () => { return <data></data> };
        let ActiveComponent = statusComponents[activeComponent] || EmptyComponent;
        let hasTrending = !!selectedpoint.getIn(['pointconfiguration', 'm_isIntegratorTrendingSetup']);
        let hasLogging = address[1] == 5 && address[2] == 2;
        let hasAsset = address[2] != 4 && address[2] != 12;
        // check in address to see if this has timezone
        let hasTimezone = 
            (address[1] == 5 && address[2] == 4) || 
            (address[1] == 5 && address[2] == 12) || 
            (address[1] == 11 && address[2] == 9) || 
            (address[1] == 9 && address[2] == 17);
        let hasExceptions = (address[1] == 5 && address[2] == 12) || (address[1] == 11 && address[2] == 9);
        let hasAdvancedTimezone = (address[1] == 9 && address[2] == 22);
        let hasCalibration = address[2] == 2;
        let hasProperties = address[1] == 9;
        
        return (
            <React.Fragment>
                {selectedpointforproperties && <PointPropertiesDialog customer={customer} selectedItem={selectedpointforproperties} type={propertytype} onClose={this.clearSelection.bind(this)} />}
                {selectedpointforedit && <PointEditDialog customer={customer} selectedItem={selectedpointforedit} editPoint={true} editEnabled={true} subsystemName={customer.get('subsystems').get(0) && customer.get('subsystems').get(0).get('name')} onClose={this.clearSelection.bind(this)} />}
                <div className="flex flex-row w-full p-2">
                    <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'advancedpointstatus' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'advancedpointstatus' }) }}>Point Status</a>
                    {hasAsset && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'asset' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'asset' }) }}>Asset</a>}
                    {hasTrending && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'trending' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'trending' }) }}>Histories</a>}
                    {hasLogging && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'logging' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'logging' }) }}>Logging</a>}
                    {hasAdvancedTimezone && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'advancedtimezone' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'advancedtimezone' }) }}>Timezone</a>}
                    {hasTimezone && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'timezone' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'timezone' }) }}>Timezone</a>}
                    {hasExceptions && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'timezoneexceptions' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} currentuser={currentuser} onClick={() => { this.setState({ activeComponent: 'timezoneexceptions'}) }}>Exceptions</a>}
                    {hasCalibration && <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'calibration' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'calibration' }) }}>Calibration</a>}
                </div>
                <div>{/* <Modal.Body> */}
                    <div className="bg-white w-full h-full md:h-auto mx-auto rounded">
                        <ActiveComponent currentuser={currentuser} customer={customer} site={site} updatePoint={updatePoint} selectedpoint={selectedpoint} address={address} activeModuleType={activeModuleType} />
                    </div>
                </div>{/* </Modal.Body> */}
                <div className="flex justify-end gap-2">{/* <Modal.Footer> */}
                        { hasProperties && <Button className="pl-1" role="save" onClick={this.showPointPropertiesDialog.bind(this, 0)}>Properties</Button>}
                        {(currentuser.get('isSuperAdmin') || currentuser.get('isAdmin')) && <Button className="pl-1" role="save" onClick={this.showPointEditDialog.bind(this)}>Edit Point</Button>}
                        <Button className="pl-1" role="close" onClick={onClose}>Close</Button>
                </div>{/* </Modal.Footer> */}
            </React.Fragment>
        )
    }
}

export default (props) => {
    return <TransitioningDialog  panelWidthClass="min-w-[85rem] max-w-[85rem]" onClose={props.onClose} opened={!!props.selecteditem} >
            <PointStatusDialog {...props} />
        </TransitioningDialog>
};