import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { toggleLeftSlide } from 'BmsView/Layout/TopMenu/Actions';
import {MdClose} from 'react-icons/md';
import { Transition, Dialog } from '@headlessui/react';
import { useEffect, useRef } from 'react';

const slideRoot = document.getElementById('slide-portal');

export default function PointGroupPanelSlide({ children, onToggle, opened, title }) {


    let slideClassName = "fixed z-[1050]  top-0 left-0 right-0 bottom-0 bg-[white] md:bg-[rgba(0,0,0,0.2)]";
    let slideInnerClassName = "relative top-20 z-[1050] bg-white w-full h-full overflow-x-hidden overflow-y-auto md:w-2/4 rounded-lg";

    return (<div>
        <Transition 
            show={!!opened} 
            className={slideClassName} 
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onClick={() => onToggle()}>
                <Transition.Child 
                    enter="transition-transform duration-300"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition-transform duration-300"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                    className={slideInnerClassName}
                    >
                    <div onClick={evt => evt.stopPropagation()}>
                        <div className="flex flex-row  text-black items-end justify-between p-2 cursor-hand text-right">
                           <div className="flex-init text-2xl pl-16">{title}</div>
                           <div className="flex-init">
                                <MdClose size="2rem"  onClick={() => onToggle()} />
                            </div>
                        </div>
                        {/* <div className="w-full bg-gray-500 text-white px-3 py-2">
                            Welcome, {currentuser && currentuser.get('firstname')}
                        </div> */}
                        
                        {children}
                    </div>
                </Transition.Child>
        </Transition>
        </div>
    )
}

function SlidePortal({ children }) {

    const internalRef = useRef(document.createElement('div'));

    useEffect(() => {
        slideRoot.appendChild(internalRef.current);
        return () => {
            slideRoot.removeChild(internalRef.current);
        }
    },[]);



    return ReactDOM.createPortal(children, internalRef.current);

}
