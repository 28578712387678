import * as d3 from 'd3';
import { displayTooltip } from '../Utils';


export default function Gauge(options) {
    return new Promise((resolve, reject) => {
        
        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `translate(${options.node.getIn(['position', 'x']) }, ${options.node.getIn(['position', 'y'])})`)
        
        let placementElement = createPlacementElement(options)

        let gauge = drawGauge(placementElement, options.node);

        let needle = drawNeedle(placementElement, options.node, 0);

        displayTooltip(placementElement, options); 

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
            //    textNode.text(point.m_formattedValueText);
            },
            
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node) => {
                placementElement.remove();
                gauge.remove();
                needle.remove();
                options.node = node;
                placementElement = createPlacementElement(options);
                gauge = drawGauge(placementElement, options.node);
                needle = drawNeedle(placementElement, options.node, 0);
                displayTooltip(placementElement, options);
            },
            remove: () => {
                placementElement.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}

function drawGauge(element, node) {
    //draw a gauge with evenly spaces values based on the input data
    //alarm levels included
    //this may only work for a value that goes from low to high with high level alarms

    //data to be replaced with real values from a field controller
    var data = {
        startValue   : node.getIn(['configuration', 'startvalue'], 0),    //low sensor value
        endValue     : node.getIn(['configuration', 'endvalue'], 100),  //high sensor value
        alarm1       : node.getIn(['configuration', 'alarmlevel1']),  //first alarm value
        alarm2       : node.getIn(['configuration', 'alarmlevel2']),  //second alarm value
        alarm3       : node.getIn(['configuration', 'alarmlevel3']),  //third alarm value
        currentValue : node.getIn(['configuration', 'testvalue'], 0), //21,   //current value
        units        : ''
    };
  
    var dec = 0; //number of decimal places

    //arc colours
    var base      = node.getIn(['configuration', 'colourrim'], 'limegreen'); //"limegreen";
    var alarm1col = node.getIn(['configuration', 'colouralarm1'], 'gold'); //"gold";
    var alarm2col = node.getIn(['configuration', 'colouralarm2'], 'goldenrod');
    var alarm3col = node.getIn(['configuration', 'colouralarm3'], 'firebrick'); //"gold";

    //data.currentValue = (Math.random() * (data.endValue - data.startValue)).toFixed(2);

    //fixed data of the gauge arc
    var multiplier        = 0.8; //determines the start and end points of the arc
    var radius            = node.getIn(['configuration', 'gaugesize'], 200);  //overall gauge size
    var gaugeInnerArc     = node.getIn(['configuration', 'gaugethickness'], 0.2);  //change the gauge thickness and align needle point
    var labelRadiusOffset = node.getIn(['configuration', 'labelradiusoffset'], 4);    //adjust this to offset the labels from the gauge centre
                                //this can account for text size and number of digits taking up space
    var dialIndexes       = node.getIn(['configuration', 'dialindexes'], 11);    //number of index value labels around the dial

    var numDialIndexes    = dialIndexes - 1;  //adjusted for the calculations

    var gaugeAngleFunction = function(v){
        //get data value and return radians
        var inMin = data.startValue
        var inMax = data.endValue
        var outRange = (PI * multiplier) * 2

        var percent = (v - inMin) / (inMax - inMin)
        return (percent * outRange) - (outRange / 2)
    };

    var labels = [];
    if (numDialIndexes > 2){
        var dataRange = data.endValue - data.startValue;
        var indexStep = dataRange / numDialIndexes;
        for (var i = 0; i < numDialIndexes + 1; i++){
            labels.push((data.startValue + i * indexStep).toFixed(dec));
        }
        labels.push('');
    } else {
        labels.push(data.startValue.toFixed(dec));
        labels.push(data.endValue.toFixed(dec));
        labels.push('');
    }

    var PI         = Math.PI;
    var labelRange = (multiplier * PI) * 2;
    var arcStart   = -multiplier * PI;
    var labelData  = [];
    // Omar 26-12-2022 segment seems to be undefined, was it ever in here?
    var segment = 0;
    
    if (numDialIndexes > 2){
        var labelSteps = labelRange / numDialIndexes;
        for (var j = 0; j < labels.length - 1; j++){
            labelData.push({startAngle : arcStart + (labelSteps * j),  endAngle : arcStart + (labelSteps * j),  label : labels[j]});
        }
        labelData.push({startAngle : PI,       endAngle : PI,       label : labels[labels.length - 1]});
    } else {
        labelData.push({startAngle : -segment, endAngle : -segment, label : labels[0]});
        labelData.push({startAngle : segment,  endAngle : segment,  label : labels[1]});
        labelData.push({startAngle : PI,       endAngle : PI,       label : labels[2]});
    }

    function createArc(inputData){
        //return an array from the start of the arc to the end, taking into account alarm levels
        var arr = [];
        if (inputData.alarm1 != null){
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.alarm1),
                col : base});
            if (inputData.alarm2 != null){
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.alarm2),
                    col : alarm1col});
                    if (inputData.alarm3 != null){
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.alarm3),
                            col : alarm2col});
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm3),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm3col});
                    } else {
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm2col});
                    }
            } else {
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.endValue),
                    col : alarm1col});
            }
        } else {
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.endValue),
                col : base});
        }
        return arr;
    }
    

    var innerGaugeData = createArc(data);

    var innerGaugeGenerator = d3.arc()
        // .innerRadius(radius - (0.2 * radius))
        .outerRadius(radius - (0.1 * radius))
        .innerRadius(radius - (gaugeInnerArc * radius))
        .cornerRadius(2)

    // Create a path element and set its d attribute
    let newnode = element
        .selectAll('path')
        .data(innerGaugeData)
        .enter()
        .append('path')
        .attr('d', innerGaugeGenerator)
        .each(function(d) {
            d3.select(this)
                .style("fill", d.col)
        })


    // Create an arc generator with configuration
    var labelArcGenerator = d3.arc()
        .innerRadius(radius + labelRadiusOffset)
        .outerRadius(radius + labelRadiusOffset)

   
    // Create a path element and set its d attribute
    element
        .selectAll('labels')
        .data(labelData)
        .enter()
        .append('labels')
        .attr('d', labelArcGenerator)

    // Add labels, using .centroid() to position
    element
        .selectAll('text')
        .data(labelData)
        .enter()
        .append('text')
        .attr('fill', node.getIn(['configuration', 'colourtext'], 'black'))
        .attr('font-size', node.getIn(['configuration', 'style', 'fontsize'], '12') + 'px')
        .each(function(d) {
            var centroid = labelArcGenerator.centroid(d);
            d3.select(this)
                .attr('x', centroid[0])
                .attr('y', centroid[1])
                .attr('dx', '-1.00em')
                .attr('dy', '0.50em')
                .text(d.label + data.units)
        })

    var limit = function (val, min, max){
        return (val > max ? max : val < min ? min : val)
    }

    return newnode;
}

function drawNeedle(element, node, value) {
    //draw a gauge with evenly spaces values based on the input data
    //alarm levels included
    //this may only work for a value that goes from low to high with high level alarms

    //data to be replaced with real values from a field controller
    var data = {
        startValue   : node.getIn(['configuration', 'startvalue'], 0),    //low sensor value
        endValue     : node.getIn(['configuration', 'endvalue'], 100),  //high sensor value
        alarm1       : node.getIn(['configuration', 'alarmlevel1']),  //first alarm value
        alarm2       : node.getIn(['configuration', 'alarmlevel2']),  //second alarm value
        alarm3       : node.getIn(['configuration', 'alarmlevel3']),  //third alarm value
        currentValue : value, 
    };
  
    var dec = 0; //number of decimal places

    var base      = node.getIn(['configuration', 'colourrim'], 'limegreen'); //"limegreen";
    var alarm1col = node.getIn(['configuration', 'colouralarm1'], 'gold'); //"gold";
    var alarm2col = node.getIn(['configuration', 'colouralarm2'], 'goldenrod');
    var alarm3col = node.getIn(['configuration', 'colouralarm3'], 'firebrick'); //"gold";

    //data.currentValue = (Math.random() * (data.endValue - data.startValue)).toFixed(2);

    //fixed data of the gauge arc
    var multiplier        = 0.8; //determines the start and end points of the arc
    var radius            = node.getIn(['configuration', 'gaugesize'], 200);  //overall gauge size
    var gaugeInnerArc     = node.getIn(['configuration', 'gaugethickness'], 0.2);  //change the gauge thickness and align needle point
    var labelRadiusOffset = 4;    //adjust this to offset the labels from the gauge centre
                                //this can account for text size and number of digits taking up space
    var dialIndexes       = node.getIn(['configuration', 'dialindexes'], 11);    //number of index value labels around the dial

    var numDialIndexes    = dialIndexes - 1;  //adjusted for the calculations

    var PI         = Math.PI;

    var gaugeAngleFunction = function(v){
        //get data value and return radians
        var inMin = data.startValue
        var inMax = data.endValue
        var outRange = (PI * multiplier) * 2

        var percent = (v - inMin) / (inMax - inMin)
        return (percent * outRange) - (outRange / 2)
    };

    function createArc(inputData){
        //return an array from the start of the arc to the end, taking into account alarm levels
        var arr = [];
        if (inputData.alarm1 != null){
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.alarm1),
                col : base});
            if (inputData.alarm2 != null){
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.alarm2),
                    col : alarm1col});
                    if (inputData.alarm3 != null){
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.alarm3),
                            col : alarm2col});
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm3),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm3col});
                    } else {
                        arr.push({startAngle : gaugeAngleFunction(inputData.alarm2),
                            endAngle : gaugeAngleFunction(inputData.endValue),
                            col : alarm2col});
                    }
            } else {
                arr.push({startAngle : gaugeAngleFunction(inputData.alarm1),
                    endAngle : gaugeAngleFunction(inputData.endValue),
                    col : alarm1col});
            }
        } else {
            arr.push({startAngle : gaugeAngleFunction(inputData.startValue),
                endAngle : gaugeAngleFunction(inputData.endValue),
                col : base});
        }
        return arr;
    }
    

    var innerGaugeData = createArc(data);

    var limit = function (val, min, max){
        return (val > max ? max : val < min ? min : val)
    }

    //draw needle
    //1 degree = 57.2958 rads
    var needleRotation = 57.2958 * gaugeAngleFunction(limit(data.currentValue, data.startValue, data.endValue))
    var needleColour;
    //if needle is inside an alarm range do something??
    for (var i = 0; i < innerGaugeData.length; i++){
        if (needleRotation >= 57.2958 * innerGaugeData[i].startAngle){
            needleColour = innerGaugeData[i].col
            //if the current value is above the last alarm level by ANY amount, it shows that colour!
        }
    }

    let newnode = element
        .append('line')
        .style('stroke', needleColour)
        // .style('stroke', 'silver')
        .style('stroke-width', node.getIn(['configuration', 'needlewidth'], 3),)
        .style('stroke-opacity', 0.7)
        .style('stroke-linecap', 'round')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -(1 - gaugeInnerArc - 0.02) * radius)//this should be factored against the gauge inner radius!
        .attr('transform', 'rotate('+needleRotation+')')

    return newnode;
}