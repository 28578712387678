import axios from 'axios';
import Configuration from 'Configuration';

export function getDevices(customer, connection, collection) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/${collection}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    })
}

export function getCollection(customer, connection, collection, query, sort) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/${collection}/search?database=${Configuration.product}${customer}&connection=${connection}`, {selector:query}, {sort:sort}).then((response) => {
            resolve(response.data);
        }).catch(reject);
    })
}

export function saveItem(customer, connection, collection, item) {
    return new Promise((resolve,reject) => {
        if (item.get('_id')) {
            axios.put(`/api/${collection}/${item.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, item.toJS()).then(resolve).catch(reject);
        }
        else {
            axios.post(`/api/${collection}?database=${Configuration.product}${customer}&connection=${connection}`, item.toJS()).then(resolve).catch(reject);
        }
    });
}

export function deleteDevice(customer, connection, collection, device) {
    return new Promise((resolve,reject) => {
        if (device.get('_id')) {
            axios.delete(`/api/${collection}/${device.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            })
        }
    });
}

export function getModbusTemplates() {
    return new Promise((resolve, reject) => {
        axios.get('/files/common/modbustemplates.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}