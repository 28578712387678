import * as d3 from "d3";
import ComponentCache from "../ComponentCache";
import { displayImage, setStateids, displayTooltip } from "../Utils";

export default function LegacyBackdropLayer(options, path) {
    return new Promise((resolve, reject) => {
        ComponentCache.getXml(path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.documentElement);
            let placementElement = displayImage(options, image);
            let tooltip = displayTooltip(placementElement, options);
            if ( options.node.getIn(['configuration', 'islink'])) placementElement.style("cursor", "hand");
            let oldvalue = -1;

            resolve({
                setValue: (point) => {
                    options.node = options.node.setIn(['pointconfiguration'], point);

                    oldvalue = setStateids(placementElement, options, point.m_value, oldvalue);
                },
                getElement: () => {
                    return placementElement;
                },
                setContextMenu: (editmode) => {
                    options.editmode = editmode;

                    if (options.node.getIn(['component', 'stateids'])) {
                        if(editmode) {
                            let states = options.node.getIn(['component', 'stateids']);

                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        } else {
                            setStateids(placementElement, options, oldvalue, -1);
                        }
                    }    
                },
                setOptions: (newOptions) => {
                    options = {...options, ...newOptions}
                },
                redrawElement: (node, isSelected) => {
                    placementElement.remove();
                    options.node = node;
                    placementElement = displayImage(options, image);
                    tooltip = displayTooltip(placementElement, options);
                    if(options.editmode) {
                        if (options.node.getIn(['component', 'stateids'])) {
                            let states = options.node.getIn(['component', 'stateids']);

                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        }
                    } 
                    oldvalue = -1;

                    if(isSelected) {
                        placementElement.attr('class', 'node-selected');
                    }
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}