import React, { useState } from 'react';
import './calendar.scss';
import classnames from 'classnames';

class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = { start: -1, end: -1, selection: -1 };

        this.click = this.click.bind(this);
        this.slotHover = this.slotHover.bind(this);
    }

    click(evt) {
        let { onSelect } = this.props;
        let { start, end } = this.state;

        if (start < 0) {
            return this.setState({
                start: parseInt(evt.target.dataset.index)
            });
        }
        if (end < 0) {
            if (onSelect) {
                onSelect(start, parseInt(evt.target.dataset.index));
            }
            return this.setState({
                start: -1, 
                end: -1, 
                selection: -1
            });
        }
        return this.setState({
            start: parseInt(evt.target.dataset.index),
            end: -1,
            selection: -1
        });
    }

    slotHover(evt) {
        let { start, end } = this.state;

        if (start < 0) {
            return;
        }
        if (end > 0) {
            return;
        }
        this.setState({
            selection: parseInt(evt.target.dataset.index)
        });
    }

    componentDidMount(){
        let height = this.refs.backgroundLayer.offsetHeight;
        let width = this.refs.backgroundLayer.offsetWidth;

        this.setState({
            colheight: height / 48,
            colwidth: width / 7
        });
    }

    render() {
        let { selection, start, end, colheight, colwidth } = this.state;
        let { render, onDayAction } = this.props;
        let days = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        let times = [];
        let slots = ["", ""];
        for (let i = 0; i < 24; i++) {
            times.push(`${i < 10 ? '0' + i : i}:00`);
        }
        //data-day-index={index} data-time-index={timeIndex} data-slot-index={slotIndex}

        return (
            <React.Fragment>
                {render && (
                    <div className="shadow-container">
                        <div className='schedule-container shadow ' style={{ gridTemplateColumns: '0.25fr repeat(1, 7fr)' }}>
                            <div style={{ height: `2rem`, padding: '0.5rem 0.25rem' }}><div style={{ width: '2.5rem' }}></div></div>
                            <div></div>
                            <div style={{ height: `30rem`, padding: '0.5rem 0.25rem' }}><div style={{ width: '2.5rem' }}></div></div>
                            <div style={{ width: '100%', height: '100%;' }} ref="backgroundLayer">
                                {render({...this.props, colheight, colwidth })}
                            </div>
                        </div>
                    </div>
                )}
                <div className="schedule-container" style={{ gridTemplateColumns: '0.25fr repeat(7, 1fr)' }}>

                    {days.map((day, index) => {
                        return (
                            <div className="column">
                                <div className="grid-cell header">
                                    {day} { day && <ExtraDayMenu 
                                        onCopy={() => { onDayAction(index, "copyDay") }}
                                        onPaste={() => { onDayAction(index, "pasteDay") }}
                                        onOnAllDay={() => { onDayAction(index, "onAllDay") }}
                                        onOffAllDay={() => { onDayAction(index, "offAllDay") }}
                                        onRepeatAllDays={() => { onDayAction(index, "repeatOnAllDays") }}
                                    /> }
                                </div>
                                {index == 0 ? times.map((time) => {
                                    return (
                                        <div className="grid-cell text-column">{time}</div>
                                    );
                                }) : times.map((time, timeIndex) => {
                                    return (
                                        <div className="grid-cell time-container" style={{ gridTemplateRows: `repeat(${slots.length}, ${1 / slots.length}fr);` }}>
                                            {slots.map((slot, slotIndex) => {
                                                let currentIndex = ((index - 1) * times.length * slots.length) + (timeIndex * slots.length) + slotIndex;
                                                return (
                                                    <div className={`grid-time-cell ${classnames({ active: currentIndex >= start && currentIndex <= (end < 0 ? selection : end) })}`} data-index={currentIndex} onClick={this.click} onMouseOver={this.slotHover}></div>
                                                )
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>

        )
    }
}

function ExtraDayMenu({ onPaste, onCopy, onOnAllDay, onOffAllDay, onRepeatAllDays }) {

    const [expanded, setExpanded] = useState(false);

    const setOnAllDay = (e) => { onOnAllDay(); }

    const setOffAllDay = (e) => { onOffAllDay(); }

    const copy = (e) => { onCopy(); }

    const paste = (e) => { onPaste(); }

    const setRepeatAllDays = (e) => { onRepeatAllDays(); }

    return <div style={{zIndex:"9999", float:"right", position:"relative", display:"grid", height:"100%", alignContent:"center", cursor:"pointer"}} onMouseLeave={() => setExpanded(false)} onClick={() => setExpanded(!expanded)}>
        <span style={{display:"block",width:"15px",height:"2px",background:"#666"}}></span>
        <span style={{display:"block",width:"15px",height:"2px",background:"#666",marginTop:"2px"}}></span>
        <span style={{display:"block",width:"15px",height:"2px",background:"#666",marginTop:"2px"}}></span>
        <div style={{display: expanded ? "block" : "none", position:"absolute",width:"fit-content",height:"200px"}}>
            <div style={{marginTop:"20px", background:"#FFF", border:"1px solid #777"}}>
                <div onClick={(e) => { setOnAllDay(e) }} style={{padding:"5px 10px", whiteSpace:"nowrap"}}>On all day</div>
                <div onClick={(e) => { setOffAllDay(e) }} style={{padding:"5px 10px", whiteSpace:"nowrap"}}>Off all day</div>
                <div onClick={(e) => { copy(e) }} style={{padding:"5px 10px", whiteSpace:"nowrap"}}>Copy</div>
                <div onClick={(e) => { paste(e) }} style={{padding:"5px 10px", whiteSpace:"nowrap"}}>Paste</div>
                <div onClick={(e) => { setRepeatAllDays(e) }} style={{padding:"5px 10px", whiteSpace:"nowrap"}}>Repeat on all days</div>
            </div>
        </div>
    </div>
}

export default Calendar;
