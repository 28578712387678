

class Debugger {

    

    constructor(component, debuggerService, style) {
        this.component = component || "";
        this.style = style || "";
        this.debuggerService = debuggerService;
    }

    log(message, ...args) {
        if(this.debuggerService.debug) {
            console.log(`%c${this.component} : ${message}`, this.style, ...args);
        }
    }


}

class DebuggerService {
    

    constructor() {

        this.debug = false;

    }

    getDebugger(component, color = "white") {
        let style = `color:${color}`;
        return new Debugger(component, this, style);
    }

}

const debuggerService = new DebuggerService();

window.debuggerService = debuggerService;

export default debuggerService;