import React, {useState, useEffect} from 'react';
import Input from 'Controls/Form/Input';
import Checkbox from 'Controls/Form/Checkbox';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Swal from 'sweetalert2';
import { ExclamationTriangleIcon, BellSlashIcon, BellAlertIcon, InformationCircleIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { alarmSounds, alarmDisplayInfo } from '../../../../Components/Alarms/constants';

const displayTexts = {
    "$range": "In range of",
    "$eq": "Equal to",
    "$neq": "Not equal to",
    "$in": "In (comma seperated)",
    "$nin": "Not in (comma seperated)"
}

// let alarmDisplayInfo = [
//     {
//         "type" : "Alarm Cleared",
//         "colour" : "green",
//         "Icon" : BellSlashIcon },
//     {   "type" : "Alarm",
//         "colour" : "red",
//         "Icon" : BellAlertIcon },
//     {   "type" : "Warning",
//         "colour" : "orange",
//         "Icon" : ExclamationTriangleIcon }, 
//     {   "type" : "Information",
//         "colour" : "blue",
//         "Icon" : InformationCircleIcon },
//     {   "type" : "Maintenance",
//         "colour" : "gray",
//         "Icon" : WrenchScrewdriverIcon },
//     {   "type" : "Critical Alarm",
//         "colour" : "red",
//         "Icon" : BellAlertIcon ,
//         "animate" : true}
// ]

function AlarmFilterGroupSetupDialog({selecteditem, onClose, onSave, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <AlarmFilterGroupSetupDialogPanel selecteditem={selecteditem} onClose={onClose} onSave={onSave} {...props} />
    </Dialog>
}

function AlarmFilterGroupSetupDialogPanel({selecteditem, customer, onClose, onSave}) {
    const [group, setGroup] = useState(selecteditem);
    const [priority, setPriority] = useState();
    
    const changeValue = (field, value, convert) => {
        if (convert) value = convert(value);
        let updategroup = group;

        updategroup = updategroup.set(field, value);

        // console.log('alarms ',field, value, updategroup);

        if ( field == "priorityenabled" && value && !updategroup.get('prioritycondition')) {
          updategroup = updategroup.set('prioritycondition', "$eq");}
        updategroup = updategroup.set(field, value);

        // console.log('alarms after ',field, value, updategroup);

        setGroup(updategroup);
    }

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>
                    Alarm Filter Setup
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-8">
                        <Input label="Name" value={group.get('name')} onChange={(value) => changeValue('name', value)} />
                    </div>   
                </div>

                    <div className="col-span-16 md:col-span-16">
                        <Input label="Description" value={group.get('description')} onChange={(value) => changeValue('description', value)} />
                    </div>   
 
                    <div className="col-span-16 md:col-span-4 mt-3">
                        <Checkbox className="mt-4" label="Filter by Priority" checked={group.get('priorityenabled', false)} onChange={(value) => changeValue('priorityenabled', value, undefined)}/>
                    </div>
                    <div className="mt-4">
                        <Select label="Alarm Sound"
                            value={group.get('alarmsound')}
                            onChange={(value) => changeValue('alarmsound', value)}
                            options={[{ text: "Default alarm sound.", value: -1 }, ...(alarmSounds || []).map((sound) => {
                                return {
                                    text: sound,
                                    value: sound
                                }
                            })]}
                        />
                    </div>
                    {group.get('priorityenabled') &&
                        <div className="col-span-16 md:col-span-16 mt-4">
                            <div className="grid grid-cols-8 gap-2">
                                <div className="col-span-8 md:col-span-4">
                                    <Select
                                        options={[
                                            { value: "$range", text: "In range" },
                                            { value: "$eq", text: "Equals" },
                                            { value: "$neq", text: "Not equals" },
                                            { value: "$in", text: 'In' },
                                            { value: '$nin', text: "Not in" }
                                        ]}
                                        onChange={(value) => changeValue('prioritycondition', value)}
                                        value={group.get('prioritycondition')}
                                    />
                                </div>
                                <div className="col-span-8 md:col-span-4">
                                    <Input onChange={(value) => changeValue('priority', value)}
                                            value={group.get('priority', null)}
                                    />
                                </div>
                            </div>
                        </div>}
                    <div className="col-span-16 md:col-span-8 mt-4">
                        <Checkbox className="mt-4" label="Filter by Subsystem" checked={group.get('subsystemenabled', false)} onChange={(value) => changeValue('subsystemenabled', value, undefined)}/>
                    </div>
                    {group.get('subsystemenabled') &&
                    <div className="col-span-16 md:col-span-8 mt-4">
                         <Select
                            value={group.get('subsystemfilter')}
                            onChange={(value) => changeValue('subsystemfilter', value, parseInt)}
                            options={ (customer.get('subsystems') || []).map((subsystem, index) => {
                                return { 
                                    value:subsystem.get('number'),
                                    text:subsystem.get('name')
                                }; 
                            })}
                        />
                    </div>}
                    <div className="col-span-16 md:col-span-8 mt-4">
                        <Checkbox className="mt-4" label="Use Alarm Profile" checked={group.get('alarmprofileenabled', false)} onChange={(value) => changeValue('alarmprofileenabled', value, undefined)}/>
                    </div>
                    {group.get('alarmprofileenabled') &&
                    <div className="col-span-16 md:col-span-8 mt-4">
                         <Select
                            value={group.get('alarmprofile')}
                            onChange={(value) => changeValue('alarmprofile', value, undefined)}
                            options={ alarmDisplayInfo.map((alarmInfo, index) => {
                                return { 
                                    value:alarmInfo.type,
                                    text:alarmInfo.type
                                }; 
                            })}
                        />
                    </div>}

            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={ () => onClose()}>Close</Button></div>
                <div className="pl-1"><Button role="primary" onClick={ () => onSave(group)}>Save changes</Button></div>
            </DialogFooter>
        </DialogContent>
    );
}

export default AlarmFilterGroupSetupDialog;