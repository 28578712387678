import React from 'react';
import Immutable from 'immutable';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class Column extends React.Component {
    render() {
        let { classes, children, className, field } = this.props;

        if (!classes) {
            classes = "";
        }
        let arr = classes.split(' ');
        classes = {};
        arr.forEach((item) => {
            classes[item] = true;
        });

        return (
            <div className={`py-1 px-2 ${classnames(classes)} ${className || ''}`}>
                {field && !field.get('hideMobile') && field.get('value') && (
                    <div className="mobile-header md:hidden">{field.get('value')}</div>
                )}
                {children}
            </div>
        )
    }
}

export default Column;