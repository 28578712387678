import React, {useState} from 'react';
import Select from 'Controls/Form/Select';
import DateTimeSelection from 'Controls/DateTimeSelection';
import Button from 'Controls/Button/UserDefined';
import { FunnelIcon } from '@heroicons/react/24/outline';

const AlarmLogFilter = ({customer, logtype, type, setType, subsystem, setSubsystem, alarmTypes, dateTimeRange, setDateTimeRange, hideToggle = false, defaultOpen = false}) => {
    let eventtypes = ["Informational", "Point Transfers", "Program Events", "Commands", "Access", "System", "Trending Errors"];
    const [open, setOpen] = useState(hideToggle || defaultOpen);

    return(
        <div className="flex flex-col md:flex-row gap-2 px-3 items-center">
           {!hideToggle && <div className="">
                <button
                    onClick={() => setOpen(!open)}
                    aria-controls="filters"
                    className="py-1"
                    aria-expanded={open} ><FunnelIcon className="w-6 h-6" /></button>
            </div> }
            { open && !logtype && 
            <div className="">
                <Select
                    containerClassName="!mb-0"
                    label="Subsystem"
                    value={subsystem}
                    onChange={ value => setSubsystem(value)}
                    options={[ {value: -1, text: "All Subsystems" }, ...customer.get('subsystems').map((subsystem,index) => {
                        return {
                            value: subsystem.get('number'),
                            text: subsystem.get('name')
                        }
                    })]}
                />
            </div>}
            {open && logtype == 'event' &&
                <div className="">
                    <Select
                        containerClassName="!mb-0"
                        label="Type"
                        value={type}
                        onChange={value => setType(parseInt(value))}
                        options={[ {value: -1, text: "All Types" }, ...eventtypes.map((type,index) => {
                            return {
                                value: index,
                                text: type
                            }
                        })]}    
                    />
                </div>}
            { open && !logtype &&
            <div className="">
                <Select
                    containerClassName="!mb-0"
                    label="Alarm Type"
                    value={type}
                    onChange={value => setType(value)}
                    options={[{ value: "All Alarms", text: "All Alarms" }, ...(alarmTypes || []).map((alarm, index) => {
                        return {
                            value: alarm['text'],
                            text: alarm['text']
                        }
                    })]}
                />
            </div>}
            { open &&
            <div className="flex-1">
                <DateTimeSelection containerClassName="!mb-0" dateTimeRange={dateTimeRange} updateDateTimeRange={setDateTimeRange}/>
            </div>}
        </div>    
    );
}

export default AlarmLogFilter;

