import React, {useState} from 'react';
import TransitioningDialog from 'Controls/Dialog/TransitioningDialog';
import SettingsPanel from 'Controls/SettingsForm/SettingsPanel';
import { useCustomerSettings } from 'Context/CustomerSettings';
import Button from 'Controls/Button/UserDefined'
import { useSystemSettings } from '../../Context/SystemSettings';
import { uploadBackground } from '../Actions';
import { set } from 'immutable';

export const SystemSettingsDialogButton = () => {
    const [active, setActive] = useState(false);
    return <div>
        <Button onClick={() => setActive(true)}>System settings</Button>
        <SystemSettingsDialog onClose={() => setActive(false)} active={active} />
    </div>
}

const SystemSettingsDialog = ({ onClose, active }) => {

    const { systemSettings, save, reset } = useSystemSettings();
    const backgroundRef = React.createRef();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const imageUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : null;

    const closeDialog = () => {
        onClose();
    }

    const onSave = async () => {
        setUploading(() => true);
        await uploadBackground(uploadedFile);
        let updatedSystemSettings = systemSettings.set('loginBackground', `private/backgrounds/${uploadedFile.name}`);
        await save(updatedSystemSettings);
        setUploading(() => false);
    }

    return (<TransitioningDialog panelWidthClass="min-w-min max-w-max " onClose={onClose} opened={!!active}>

        <div className="">
            <div className="text-2xl">System settings</div>
        </div>

        <div className=" p-2">
            <strong>Login Background:</strong> (click to change)
            <div className="">
                <div className="w-96" onClick={() => {
                    console.log("Click detected");
                    backgroundRef?.current?.click()
                }}>
                    <input type="file" className="hidden" onChange={(e) => setUploadedFile(e.target.files[0])} ref={backgroundRef}/>
                    <img  src={`${imageUrl || '/login-background.webp'}`} alt="Login background"  />
                </div>
            </div>

        </div>


        <div className="flex p-2 gap-2 justify-end">
                <Button role="save" className="disabled:opacity-70" disabled={uploading} onClick={() => onSave()}>
                    {uploading ? "Saving..." : "Save"}
                </Button>
                <Button role="close" className="" onClick={() => closeDialog()}>Close</Button>
        </div>

    </TransitioningDialog>)

}

export default SystemSettingsDialog;


