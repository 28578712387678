import React, { useContext, useState, useEffect, useMemo, createContext } from "react";

import { fromJS, List, OrderedMap } from "immutable";
import { useStateIfMounted } from "use-state-if-mounted";
import { fetchSite } from 'BmsView/Customer/Site/Actions';
import { useBMS } from "../../Context/BMS";


const AreaDialogContext = createContext({ });
const ActiveAreaDialogContext = createContext({ });

export function useActiveAreaDialog() {
    return useContext(ActiveAreaDialogContext);

}

export function useAreaDialogContext() {
    return useContext(AreaDialogContext);
}

function getCustomer(customerName, customers) {
    return customers.find((customer) => customer.get('name').toLowerCase().replace(/ /g, "-") == customerName);
}

export function ActiveAreaDialogProvider({ children, url }) {

    const { customers } = useBMS();
    const [site, setSite] = useStateIfMounted(OrderedMap());
    const [customer, setCustomer] = useStateIfMounted(OrderedMap());
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if(url) {


            const abortController = new AbortController();

            (async (abortController) => {


                let match = url.match(/.*?\/customer\/(?<customer>.*?)\/site\/(?<site>.*?)\/(.*)/);
                let customerName = match?.groups?.customer;
                // console.log("customerName to check", customerName);
                // console.log("customers", customers);    
                let siteName = match?.groups?.site;


                // console.log("list of customers", customers);
                // console.log("customerName", customerName);
                // console.log("siteName", siteName);

                let customer = getCustomer(customerName, customers);
                let site = await fetchSite(customerName, siteName, undefined, abortController.signal);

                // console.log('loaded customer', customer);
                // console.log('loaded site', site);

                setCustomer(() => customer);
                setSite(() => site);
                setLoading(() => false);

                console.log("mounting active area dialog");
            })(abortController);
            return () => {
                console.log("unmounting active area dialog");
                abortController.abort();
            }
        }
     
    }, [url])
    
    const value = { site, setSite, customer, setCustomer, loading, setLoading, isAreaInDialog: true }

    


    return (
        <ActiveAreaDialogContext.Provider value={value}>
            {children}
        </ActiveAreaDialogContext.Provider>
    );


}

export default function AreaDialogProvider({ children }) {
    const [dialogUrl, setDialogUrl] = useState(undefined);
    
    const openDialog = (url) => {
        console.log("setting url", url);
        setDialogUrl(url);
    }

    const closeDialog = () => {
        setDialogUrl(undefined);
    }


    const value = { openDialog, dialogOpened:!!dialogUrl, closeDialog, dialogUrl }


    return (
        <AreaDialogContext.Provider value={value}>
            {children}
        </AreaDialogContext.Provider>
    );
}