import React, {useState} from 'react';
import Grid from 'Controls/GridV2';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const EventLog = ({records}) => {
    const [selectedRecord, setSelectedRecord] = useState();
    const [show,setShow] = useState();
    
    let gridFields = [
        { value: 'datetime', name: 'Time', width: 'col-span-16 md:col-span-2' },
        { value: 'type', name: 'Type', width: 'col-span-16 md:col-span-1' },
        { value: 'panel', name: 'Panel', width: 'col-span-16 md:col-span-2' },
        { value: 'point', name: 'Point', width: 'col-span-16 md:col-span-2' },
        { value: 'event', name: 'Event', width: 'col-span-16 md:col-span-7' },
        { value: 'user', name: 'User', width: 'col-span-16 md:col-span-1' }
    ];

    let eventtypes= ['','Informational','Transfer','Command','Access','System','Trending','Calendar'];

    return (
        <React.Fragment>   
            <Grid>
                <Grid.Header>
                    {gridFields.map((field) => {
                        return <Grid.Column key={field.name} classes={`${field.width}`}>{field.name}</Grid.Column>
                    })}
                    <Grid.Column key={"buttons"} classes={''} name="button"></Grid.Column>
                </Grid.Header>
                <Grid.Data className="max-h-100 h-100 overflow-x-scroll">
                    {(records || []).map((record, index) => {
                        return (<Grid.Row key={index} className="group hover:bg-gray-200">
                            {gridFields.map((field2, _index) => {
                                if (field2.value == 'type') return <Grid.Column key={_index} className={`${field2.width}`}>{eventtypes[record.get(field2.value)]}</Grid.Column>;
                                else return <Grid.Column key={_index} className={`${field2.width}`}>{record.get(field2.value)}</Grid.Column>
                            })}
                            <Grid.Column>
                                <Grid.Controls>
                                    <Grid.Control>
                                        <MagnifyingGlassIcon  className="cursor-pointer text-gray-500 group-hover:text-gray-700 group-hover:hover:text-blue-500 w-6" />
                                    </Grid.Control>
                                </Grid.Controls>
                            </Grid.Column>
                        </Grid.Row>)
                    })}
                </Grid.Data>
            </Grid>
        </React.Fragment>
    );
}

export default EventLog;
