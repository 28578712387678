import React from 'react';
import Title from './Title';
import Item from './Item';
import Arrow from 'Controls/Arrow';

const DropdownContext = React.createContext();


Title.contextType = DropdownContext;
Item.contextType = DropdownContext;
class Dropdown extends React.Component {
    static Title = Title;
    static Item = Item;

    constructor(props) {
        super(props);

        this.state = {}

        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown() {
        this.refs.dropdown.classList.toggle('max-h-zero');
        this.refs.dropdown.classList.toggle('max-h-sm');
        this.setState(({ open }) => ({
            open: !open
        }));
    }

    render() {
        let { children, title, float } = this.props;
        let {open} = this.state;
        
        return (
            <React.Fragment>
                <div className={`fixed pin ${!open && 'hidden' || ''} ${float == 'right' ? 'float-right' : ''}`} onClick={this.toggleDropdown}></div>
                <ul className="p-0 flex-row flex-grow self-center flex-no-wrap">
                    <li className={`menuitem self-end ${open ? `bg-blue-darker` : ``}`} onClick={this.toggleDropdown}>
                        <a className={`no-underline text-white`}>{title}</a>
                        <Arrow className={`h-3 w-3 mx-2`} rotateDegree={this.state && this.state.open ? 180 : 0} />
                    </li>
                    <ul ref="dropdown" className="dropdown overflow-hidden overflow-y-auto slide-from-t z-30 max-h-zero">
                        <DropdownContext.Provider value={{ toggleDropdown: this.toggleDropdown.bind(this) }}>
                            {children}
                        </DropdownContext.Provider>
                    </ul>
                </ul>
            </React.Fragment>
        )
    }
}

export default Dropdown;