import React from 'react';
import Input from 'Controls/Form/Input';
import TextArea from 'Controls/Form/TextArea'
import Select from 'Controls/Form/SelectSearch';
import Checkbox from 'Controls/Form/Checkbox';
import ColorPicker from 'Controls/ColorPicker';
import Immutable from 'immutable';

class TextMultiline extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }
    render() {
        let { selectedcomponent } = this.props;
        return (
            <div className="my-4">
                <div className="w-100">
                    <TextArea
                        label="Text"
                        rows="10"
                        value={selectedcomponent.getIn(['configuration','value'], '')}
                        onChange={this.valueChanged.bind(this, ['configuration', 'value'])}
                    />
                </div>
                <div className="grid grid-cols-16">
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            label="Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'width'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'width'])}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-16 my-4 gap-2">
                    <div className="col-span-8 md:col-span-3">
                        <Select
                            label="Font Style"
                            type="number"
                            value={selectedcomponent.getIn(['configuration','style', 'fontstyle'], 'Normal') + ''}
                            onChange={this.valueChanged.bind(this, ['configuration','style', 'fontstyle'])}
                            options={Immutable.List([{'value':'Normal', 'text':'Normal'}, {'value':'Italic', 'text' :'Italic'}, {'value':'Bold', 'text' :'Bold'}])}
                        /> 
                    </div>
                    <div className="col-span-8 md:col-span-2">
                        <Input
                            showDeleteControl={true}
                            label="Font Size"
                            type="number"
                            value={selectedcomponent.getIn(['configuration','style', 'fontsize'], '') + ''}
                            onChange={this.valueChanged.bind(this, ['configuration','style', 'fontsize'])}
                        /> 
                    </div>
                    <div className="col-span-8 md:col-span-2 pt-4">
                        <Checkbox
                            label="Underline"
                            className="mt-3"
                            checked={selectedcomponent.getIn(['configuration', 'underline'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'underline'])} />
                    </div>
                </div>   
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Border" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'border-color'])} /></div>
                </div>
            </div>
        )
    }
}

export default TextMultiline;