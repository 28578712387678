import React from 'react';
import { Dropdown } from './Menu';
import './Reducers';
import { toggleLeftSlide } from './Actions';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useBMS } from 'BmsView/Context/BMS';

class TopMenu extends React.Component {
    toggleMenu() {
        toggleLeftSlide();
    }

    componentDidUpdate(oldProps){
        if (oldProps.leftslide_toggled != this.props.leftslide_toggled){
            if (!this.props.leftslide_toggled){
                this.refs.hamburger.classList.remove('is-active');
            }
            else{
                this.refs.hamburger.classList.add('is-active');
            }
        }
    }

    render() {
        let { topmenu, currentuser } = this.props;

        return (
            <div className="bg-blue-darkest w-screen pin-t fixed z-30 flex flex-col">
                <div className="flex flex-row justify-between  flex-no-grow mx-auto h-12 w-full px-2">
                    <div className="flex-no-grow flex text-white items-center mx-4">
                        <div className="three col" onClick={this.toggleMenu.bind(this)}>
                            <div className="hamburger" ref="hamburger" id="hamburger-3">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 flex-no-grow flex text-white self-center">
                        <Link to="/" className="no-underline text-2xl hover:text-blue-lighter text-white font-bold cursor-pointer">BmsView</Link>
                    </div>
                    <div className="hidden lg:flex w-full justify-between">
                        {this.props.children}
                    </div>
                    <div className="float-right">
                        <Dropdown title={`Hello ${currentuser.get('firstname')}`}>
                            <Dropdown.Item onClick={async () => {
                                await axios.get('/logout');
                                window.location = '/';
                            }}>Logout</Dropdown.Item>
                        </Dropdown>
                    </div>

                    <div className="flex lg:hidden text-white p-2 pr-6 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className="fill-current w-10 h-8" version="1.1" x="0px" y="0px" viewBox="0 0 490.3 490.3" style={{ enableBackground: "new 0 0 490.3 490.3" }} space="preserve" >
                            <g>
                                <g>
                                    <path d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3    s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6    c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1    C27.9,58.95,0,86.75,0,121.05z" fill="#FFFFFF" />
                                    <path d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9    c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63    C380.6,325.15,380.6,332.95,385.4,337.65z" fill="#FFFFFF" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div >
        )
    }
}


export default connect((state) => ({
    topmenu: state.get('topmenu'),
    // currentuser: state.get('currentUser', Immutable.Map({ firstname: "Test" })),
    leftslide_toggled: state.get('leftslide_toggled')
}))((props) => {

    const { currentUser } = useBMS();

    return <TopMenu {...props} currentuser={currentUser} />
});