import { Fragment, useEffect, useState } from 'react';
import { searchPoints, setSaveAreaHandler } from "../../../Actions"
import { getGroups } from '../../../../Configuration/Actions';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { RectangleGroupIcon as RectangleGroupIconSolid } from '@heroicons/react/24/solid';
import Select from 'Controls/Form/TailwindSelect';
import PointGroupPanelSlide from './PointGroupPanelSlide';
import ListSelection from '../../../../../../Controls/ListSelection';
import { List, fromJS } from 'immutable';
import ContextMenu from 'Controls/ContextMenu/Portal';
import PointInGroupDialog from './PointInGroupDialog';

export default function PointGroupPanel({ onSelect, selectedGroups, className, children, opened, onToggle, selectedpoint, customer, address, type = 'Bacnet Schedule' }) {

    const [groups, setGroups] = useState(List());
    const [selectedGroup, setSelectedGroup] = useState(undefined);
    const [contextMenuItem, setContextMenuItem] = useState(undefined);

    const nonSelectedGroups = groups.filter(x => !selectedGroups.some(y => y.get('_id') == x.get('_id')));

    useEffect(() => {
        if(opened) {
            (async () => {
                let _groups = await getGroups(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, 'pointgroups');
                let _selectedpoints = await searchPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, {
                    addr: address.map((byte) => { return parseInt(byte) })
                });
                

                let pointGroups = undefined;
                let relevantGroups = [];
                let groupedGroups = _groups.reduce((acc, group) => {
                    acc[group._id] = group;
                    return acc;
                }, {});

                if(_selectedpoints && _selectedpoints.length > 0) {
                    let point = _selectedpoints[0];
                    pointGroups = point.pointgroups;
                    // console.log(point);
                    if(pointGroups) {
                        pointGroups.forEach((group) => {
                            if(groupedGroups[group.groupid]) {
                                relevantGroups.push(groupedGroups[group.groupid]);
                            }
                        })
                    }
                }   

                relevantGroups = _groups.filter(x => x.type == type);

                let _points = await searchPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, {
                    pointgroups: { $elemMatch: { groupid: { $in: relevantGroups.map(x => x._id) } } }
                });
                relevantGroups = relevantGroups.map(x => {
                    let points = _points.filter((point) => {
                        return point.pointgroups.some(y => y.groupid == x._id)
                    })
                    x.points = points;
                    return x;
                })
                
                setGroups(fromJS(relevantGroups))
            })()

        }
    }, [opened])


    const onGroupsChange = (selectedGroups) => {
        onSelect(selectedGroups)
    }

    const onContextMenu = (e, index, list) => {
        e.preventDefault();
        // console.log("context menu requested", e)
        // console.log("context menu requested", index, list)
        let selectedGroup = undefined;
        if(list == "left") {
            selectedGroup = selectedGroups.get(index);
        } else if(list == "right") {
            selectedGroup = nonSelectedGroups.get(index);
        }
        setContextMenuItem({
            x: e.clientX,
            y: e.clientY,
            selectedGroup: selectedGroup
            
        });
    }

    const onPositionChange = (e) => {
        setContextMenuItem(null);
        e.preventDefault();
    }

    const onShowPoints = () => {
        // console.log("showing group", contextMenuItem.selectedGroup);
        setSelectedGroup(contextMenuItem.selectedGroup);
        setContextMenuItem(null);
    }

    const innerPanel = <div className={`flex flex-col gap-2 p-2 ${className}`}>
        
        <div className="">
            <ListSelection 
                onContextMenu={onContextMenu}
                rightList={nonSelectedGroups.map(x => x.set('label', x.get('name')))} 
                leftList={selectedGroups.map(x => x.set('label', x.get('name')))} 
                onChange={(left, right) => { onGroupsChange(left) }} />
        </div>
        <div className="text-xl">
            Selected groups:
        </div>
        <div className="flex flex-col gap-2">
            {selectedGroups.filter(x => x.get('type') == type).map((group) => {
                return <div>
                    <div className="font-bold">
                        {group.get('name')}
                    </div>
                    <div>
                        {group.get('points', List()).map((point) => {
                            return <div>
                                {point.get('name')}
                            </div>
                        })}
                    </div>
                </div>
            })}
        </div>
       
        <div className="">
            {/* <Select 
                placeholder="Select group" 
                options={groups.filter(x => x.type == type).map(x => ({ value: x._id, text: x.name}))} 
                onChange={(value) => { onGroupChange(value) }}
                /> */}
        </div>
        <div>
            {children}
        </div>
    </div>

    return <Fragment>
        <PointInGroupDialog selecteditem={selectedGroup} onClose={() => setSelectedGroup(undefined)} />
        <ContextMenu className={'left-0 top-0'} 
            contextMenu={contextMenuItem} 
            onPositionChange={onPositionChange}
            onClose={() => setContextMenuItem(null)}>
            <ContextMenu.Item onClick={onShowPoints}>List points</ContextMenu.Item>
        </ContextMenu>
        <div className="relative z-[1051] bg-white w-8 h-8 p-1 border border-gray-500 rounded-lg cursor-pointer" onClick={onToggle}>
            <RectangleGroupIcon className="w-6 h-6" />
        </div>
        <PointGroupPanelSlide 
            title={`Set timezone to groups`}
            opened={opened} 
            onToggle={onToggle} 
            className={className}>
                {innerPanel}
        </PointGroupPanelSlide>
    </Fragment>

}