import React, {useState} from 'react';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
// import { backgroundBorderColour, backgroundColour, alarmDisplayInfo, alarmTextColour } from './AlarmListPanel';
import { alarmDisplayInfo, backgroundColour, backgroundBorderColour, alarmTextColour } from 'Components/Alarms/constants';
function AlarmDetailsDialog({ selecteditem, onClose, ...props }) {
    return <Dialog className={`rounded-md ${backgroundColour[alarmDisplayInfo[selecteditem.get('icon')].colour]} p-2 border-4 border-double ${backgroundBorderColour[alarmDisplayInfo[selecteditem.get('icon')].colour]} md:min-w-[40vw] md:max-w-[50vw]`} opened={!!selecteditem} onClose={onClose}>
        <AlarmDetailsDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

const displayMultilineText = (mltext) => {
    const textWithBreaks = mltext.split('\n').map((text, index) => (
        <React.Fragment key={index}>
            {text}<br/>
        </React.Fragment>
    ));
    
    return textWithBreaks;
}

const AlarmDetailsDialogPanel = ({selecteditem, onClose}) => {

    let Icon = alarmDisplayInfo[selecteditem.get('icon')].Icon;

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle className="flex flex-row w-full justify-between items-center">
                    <div>Alarm Details</div>
                    <div>
                        <Icon className={`${alarmDisplayInfo[selecteditem.get('icon')].animate ? 'animate-ping h-6 w-6' : 'h-7 w-7'} ${alarmTextColour[alarmDisplayInfo[selecteditem.get('icon')].colour]} aria-hidden=true`} />
                    </div>
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
            <div className="grid grid-cols-8 gap-2 px-2">
                <div className="col-span-8 md:col-span-2">Date</div>
                <div className="col-span-8 md:col-span-2">{selecteditem.get('datetime').substr(0,10)}</div>
                <div className="col-span-8 md:col-span-1">Time</div>
                <div className="col-span-8 md:col-span-2">{selecteditem.get('datetime').substr(11,16)}</div>
            </div> 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Type</div>
                <div className="col-span-8 md:col-span-2">{selecteditem.get('typetext')}</div>
            </div> 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Point</div>
                <div className="col-span-8 md:col-span-4">{selecteditem.get('point')}</div>
            </div> 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Panel</div>
                <div className="col-span-8 md:col-span-4">{selecteditem.get('panel')}</div>
            </div> 
            { selecteditem.get('description') && 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Description</div>
                <div className="col-span-8 md:col-span-4">{selecteditem.get('description')}</div>
            </div>}
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Message</div>
                <div className="col-span-8 md:col-span-6">{selecteditem.get('messagetext')}</div>
            </div> 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Detail</div>
                <div className="col-span-8 md:col-span-6">{displayMultilineText(selecteditem.get('paneltext'))}</div>
            </div> 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Priority</div>
                <div className="col-span-8 md:col-span-6">{selecteditem.get('priority')}</div>
            </div> 
            <div className="grid grid-cols-8 gap-2 px-2 my-8">
                <div className="col-span-8 md:col-span-2">Alarm Occured At</div>
                <div className="col-span-8 md:col-span-6">{selecteditem.get('paneltime')}</div>
            </div> 
                <div className="row my-4">
                    {selecteditem.get('ackedby') && 
                        <div className="mt-4 col-16 col-md-16">
                            <Grid>
                                <Grid.Header>
                                    <Grid.Column classes={"col-span-16 md:col-span-8"}>Acknowledged by</Grid.Column>
                                    <Grid.Column classes={"col-span-16 md:col-span-4"}>Date</Grid.Column>
                                    <Grid.Column classes={"col-span-16 md:col-span-4"}>Time</Grid.Column>
                                </Grid.Header>
                                <Grid.Data className="max-h-100 h-100 overflow-x-scroll">
                                    {(selecteditem.get('ackedby') || []).map((user, index) => {
                                        return (
                                            <Grid.Row key={index}>
                                                <Grid.Column className={"col-span-16 md:col-span-8"}>{user.get('user')}</Grid.Column>
                                                <Grid.Column className={"col-span-16 md:col-span-4"}>{new Date(user.get('datetime')).toLocaleString().substr(0,10)}</Grid.Column>
                                                <Grid.Column className={"col-span-16 md:col-span-4"}>{new Date(user.get('datetime')).toLocaleString().substr(11,16)}</Grid.Column>
                                            </Grid.Row>)
                                    })}
                                </Grid.Data>
                            </Grid>
                        </div> }
                </div>     
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={() => onClose()}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    )
}

export default AlarmDetailsDialog;