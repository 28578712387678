import React from 'react';
import Immutable from 'immutable';
import ServiceBus from 'Services/ServiceBus';
import Swal from 'sweetalert2';
import ModuleTypes from 'Configuration/ModuleTypes';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function ScanDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} >
        <ScanDialogPanel selecteditem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}

class ScanDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.processScanControllersResponse = this.processScanControllersResponse.bind(this);
        this.processScanLansResponse = this.processScanLansResponse.bind(this)


        ServiceBus.on('303-11', this.processScanLansResponse);
        ServiceBus.on('304-11', this.processScanControllersResponse);
    }

    componentDidMount() {
        let {onClose, selectedlan} = this.props;

        if ( selectedlan === undefined ) {
            this.scanLans().then((message) => {
                Swal.fire("Scanned!", `The following lans have been imported<br />${message.m_formattedText.split(';').join('<br />')}`, "success");
                onClose();
            });
        } else {
            this.scanDevices();       
        }    
              
        this.setState({
            message: selectedlan >= 0 && `Please wait while we are scanning for the outstations.` || `Please wait while we are scanning for the lans.`
        });      
    }

    componentWillUnmount() {
        ServiceBus.off('303-11', this.processScanLansResponse);
        ServiceBus.off('304-11', this.processScanControllersResponse);
    }

    processScanLansResponse(message){
        let {onClose} = this.props;
        Swal.fire("Scanned!", `The following lans have been imported<br />${message.m_lanList.map((lan) => {return 'Lan ' + lan.m_lanNumber + ' - ' + lan.m_name} )}`, "success");
        onClose();
    }

    processScanControllersResponse(message){
        let {selectedSubsystem} = this.props;

        if ( selectedSubsystem.get('type') == 6 ) {               
            this.setState(({ messages }) => ({
                messages: messages.push(Immutable.Map({ message: `${ModuleTypes[selectedSubsystem.get('type')].scanfields.device} ${message.m_controllerAddress} - ${message.m_name} - ${message.m_revisionInfo}`, time: Date.now() }))
            }));
        } else if (selectedSubsystem.get('type') == 9) {
            this.setState(({ messages }) => ({
                messages: messages.push(Immutable.Map({ message: message.m_displayText, time: Date.now() }))
            }));
        } else {
            this.setState(({ messages }) => ({
                messages: messages.push(Immutable.Map({ message: 'Lan ' + message.m_lanNumber + ' Outstation ' + message.m_controllerAddress + ' - ' + 
                    message.m_name + ' - ' + message.m_revisionInfo, time: Date.now() }))
            }));
        }     

        if (message.m_scanFinished) {
            this.setState(({ messages }) => ({
                messages: messages.push(Immutable.Map({message: 'Scan Completed', time: Date.now() }))
            }))

            Swal.fire(`Scan For ${ModuleTypes[selectedSubsystem.get('type')].scanfields.deviceheader}s`, "Scan Completed", "success");  
        }
    }

    scanDevices(){
        let {customer, selectedSubsystem, selectedlan} = this.props;

        if (selectedlan >= 0 ){
            ServiceBus.send('WEB_MESSAGE_REQ', {
                m_level: 10,
                m_communicNum: 304,
                m_subsystemNumber: selectedSubsystem.get('number'),
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                target: 'signalr',
                type: 'GENERIC'
            },{
                m_lanIndex: selectedlan
            });
        }

        this.setState({
            messages: Immutable.List([]).push(Immutable.Map({ time: Date.now(), message: "Started scanning" }))
        });
    }
    
    scanLans() {
        let {customer, selectedSubsystem} = this.props;

        return new Promise((resolve, reject) => {
            ServiceBus.send('WEB_MESSAGE_REQ', {
                m_level: 10,
                m_communicNum: 303,  
                m_subsystemNumber: selectedSubsystem.get('number'),
                type: 'GENERIC',
                target: 'signalr',
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
            },{});

            this.promise = {
                resolve: resolve,
                reject: reject
            }
        })
    }

    render() {
        let {message, messages} = this.state || {};
        let {scanforlansdialog, selectedlan, selectedSubsystem, onClose} = this.props;

        if (selectedlan === undefined ) {
            return (
                <h3>{message}</h3>
            )
        }

    /*    if (scanforlansdialog) {
            return (
                <h3>{message}</h3>
            )
        }*/

        return (
            <DialogContent className="md:min-w-[50vw]">
                <DialogBody>
                    <h3>Please wait while we are scanning for the {ModuleTypes[selectedSubsystem.get('type')].scanfields.device}</h3>
                    {messages && messages.sort((a, b) => b.get('time') - a.get('time')).map((message, i) => {
                        let dt = new Date(message.get('time'));
                        return (
                            <p key={i.toString()}><strong>{`${("0" + dt.getDate()).slice(-2)}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${dt.getFullYear()} ${("0" + dt.getHours()).slice(-2)}:${("0" + dt.getMinutes()).slice(-2)}:${("0" + dt.getSeconds()).slice(-2)}`}</strong> - {message.get('message')}</p>
                        )
                    })}
                </DialogBody>
                <DialogFooter>
                    <Button role="close" onClick={onClose}>Exit</Button>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default ScanDialog;