import React, { Fragment, useState } from 'react';
import Immutable from 'immutable';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/solid';
import PointSelectionDialog from './PointSelectionDialog';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';


export default function PointListControl ({pointList, onAddPoint, onRemovePoint}) {
        const [dialogDisplay, setDialogDisplay]= useState(false);

        /*this.state = {
            selectedcomponent: props.selecteditem.setIn(['component'], Immutable.fromJS({
                "type": "pointlist",
                "componentid": "layer1",
                "name": "pointlist",
                "description": "Pointlist",
                "layer": "layer1user",
                "stateids": []
            })),
            dialogshowing : false
        };*/
    
   
    const addPoint = (point) => {
        onAddPoint(point);
        setDialogDisplay(false);
    }

    const removePoint = (point, index) => {
        onRemovePoint(point);
    }


    const displayDialog = () => {
        setDialogDisplay(true);
    }

    const hideDialog = () => {
        setDialogDisplay(false);
    }

    return (
        <Fragment>
            <PointSelectionDialog selecteditem={dialogDisplay} onClose={() => hideDialog()} onSelect={(point) => addPoint(point)} />
            <div className="my-4">
                <h4>Point Selection</h4> 
                <div>
                <Grid>
                    <Grid.Header>
                        <Grid.Column classes="col-span-16 md:col-span-6">Name</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-5">Address</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-5" name="delete"  type="complex">
                            <Grid.Controls>
                                <Grid.Control>
                                    <PlusIcon className="w-6 text-white cursor-pointer" title="add new" onClick={() => displayDialog()} />
                                </Grid.Control>
                            </Grid.Controls>
                        </Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        {pointList.map((point, index) => {
                            return (
                                <Grid.Row key={index}>
                                    <Grid.Column classes="col-span-16 md:col-span-6">{point.get('label')}</Grid.Column>
                                    <Grid.Column classes="col-span-16 md:col-span-5">{point.get('value')}</Grid.Column>
                                    <Grid.Column classes="col-span-16 md:col-span-5">
                                        <Grid.Controls>
                                            <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500" onClick={() => removePoint(index)} /></Grid.Control> 
                                        </Grid.Controls>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid.Data>
                </Grid>
                </div>
            </div>
        </Fragment>
    );
}



