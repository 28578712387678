import React, {useState, useEffect} from 'react';
import Immutable from 'immutable';
import Grid from 'Controls/GridV2';
import PointGroupSelectionDialog from '../PointGroupSelectionDialog';
import {TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid';

const GroupDetails = ({customer, selectedgroups, saveGroups, removeGroup, pointgroups}) => {
    let [groups, setGroups] = useState(undefined);
    let [currentgroups, setCurrentGroups] = useState(undefined);  

    useEffect(() => {
        let grouplist = selectedgroups.map((sg) => {
            let g = (pointgroups || []).filter((group) => {
                return group.get('_id') == sg.get('groupid');
            });
            return Immutable.Map(g.get(0));
        }) 

        setCurrentGroups(grouplist);
    }, [selectedgroups]); 

    const onSaveGroups = (value) => {
        saveGroups(value);
        clearSelection();
    }

    const clearSelection = () => {
        setGroups(undefined);
    }
    
    const setSelectedGroups = () => {
        let newgroups = currentgroups.map((group) => {
            return group.get('name');
        });

        setGroups(newgroups);
    }

    return(
        <div>
            <PointGroupSelectionDialog isOpened={!!groups} customer={customer} selecteditem={groups} filter={undefined} onClose={() => clearSelection()} onSave={(value) => onSaveGroups(value)}/>
            <Grid>
                <Grid.Header>
                    <Grid.Column classes="col-span-16 md:col-span-8">Name</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-4">Type</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-4" name="add" type="complex">
                        <Grid.Controls>
                            <Grid.Control>
                                <PencilSquareIcon  className="w-6 text-white cursor-pointer" title="Select Groups" onClick={() => setSelectedGroups()} />
                            </Grid.Control>
                        </Grid.Controls>
                    </Grid.Column>
                </Grid.Header>
                <Grid.Data>
                    {(currentgroups || []).map((group, index) => {
                        // console.log('g ', group);
                        return(
                            <Grid.Row key={index} className="hover:bg-gray-300 group">
                                <Grid.Column classes="col-span-16 md:col-span-8">{group.get('name')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-4">{group.get('type')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-4"/>
                            </Grid.Row>
                        );
                    })}
                </Grid.Data>
            </Grid>
        </div>
    )
}

export default GroupDetails;