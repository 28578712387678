import { routerDataService } from 'BmsView/Context/BMS';
import { fetchCustomers, getCustomerUserGroups } from 'BmsView/Actions/Customers';
import { getSite, updateSite, fetchSite } from 'BmsView/Customer/Site/Actions';
import { fetchCurrentUser } from 'Actions/Authentication';
import { fromJS } from 'immutable';
import debuggerService from 'Services/DebuggerService';
import { BrowserRouter as Router, useRevalidator, Route, Routes, Outlet, useParams, useMatches, useLocation, redirect, createBrowserRouter, createRoutesFromElements, RouterProvider, useLoaderData, useRouteLoaderData, useNavigation } from 'react-router-dom';


const logger = debuggerService.getDebugger("Route Loaders", "gold");



export async function checkAuthenticationLoader() {
    // console.log("check authentication loader called");
    logger.log("check authentication loader called")
    let currentUser = null;
    try {
        routerDataService.prepare('currentUser');
        currentUser = await fetchCurrentUser();
        // console.log("resolved currentUser", currentUser);
        routerDataService.set('currentUser', currentUser);
    } catch(e) {
        // console.log("no user found");
        routerDataService.set('currentUser', null);
    }
    return currentUser
}

export async function loginLoader() {
    logger.log("login loader called")
    let currentUser = await routerDataService.get('currentUser');
    if(currentUser) {
        let lastRoutePath = await routerDataService.get('currentRoutePath');
        if(lastRoutePath) {
            return redirect(lastRoutePath);
        }
        return redirect('/');
    }
    return true;
}

export function authenticatedLoader(loader) {
    return async (loaderParams) => {
        const { params, request } = loaderParams;
        logger.log("authenticated loader called")
        routerDataService.prepare('currentRoutePath');   
        let currentUser = await routerDataService.get('currentUser');
        if(!currentUser) {
            let currentRoutePath = request.url;
            routerDataService.set('currentRoutePath', currentRoutePath);
            return true;
        }
        return await loader && loader(loaderParams);
    }
}

export async function mainLoader({ params, request }) {  
    logger.log("main loader called")
    routerDataService.prepare('customers');
    // await dependencies to prevent the router from rendering the route before the data is loaded
    await routerDataService.get('currentUser');
    let customers = await fetchCustomers();
    // console.log("111 customers loaded", customers);
    routerDataService.set('customers', customers);
    return { 
        params, customers
    }
}

export async function customerLoader({ params, request, ...rest}) {
    // console.log("111 Loading customer en route");
    logger.log("customer loader called")
   

    routerDataService.prepare('customer');
    routerDataService.prepare('customerGroups');

     // await dependencies to prevent the router from rendering the route before the data is loaded, and they are also needed in this loader
    // console.log("111 Loading customer en route... CUSTOMERS")
     const customers = await routerDataService.get('customers');
     logger.log("customers loaded customers", customers);
    //  console.log("111 Loading customer en route... CURRENTUSER")
    const currentUser = await routerDataService.get('currentUser');
    // console.log("111 Loading customer en route... FOUND CURRENT USER");

    if(customers.size < 1) {
        // console.log("111 NO CUSTOMERSSSSS FOUND EN ROUTE");
        logger.log("no customers found");
        let currentRoute = request.url;
        logger.log("current route", currentRoute); 
        logger.log("current params", params);
        routerDataService.set('customer', null);
            routerDataService.set('customerGroups', null);
        if(!currentRoute.match('/no-customer')) {
            logger.log("trying to redirect to no-customers");
            return redirect('/no-customer');
        }
        return {customerParams: params};
    }
    // console.log("111 params", params);
    let routeParams = [];



    let pathParams = [params.customer, params['*']?.split('/')[1]];
    let validsite = currentUser.get('sites', []).filter((site) => {
        if (site) routeParams = site.get('name').split(':');
        return ((routeParams[0] == params.customer && routeParams[1] == pathParams[1]) || ((pathParams[0]) != "site") && (routeParams[0] == params.customer));
    });

    let customer = getCustomerFromValidSite(currentUser, params, pathParams, customers);
    logger.log("value of customer", customer);
    if(customer !== false) {
        // console.log("%c111 customer found en route", "color:lime", customer);
        let _customerGroups = null;
        if (customer) {
            routerDataService.set('customer', customer);
            let customerGroups = await getCustomerUserGroups(customer.get('name') || []);
            if(customerGroups) {
                _customerGroups = fromJS(customerGroups);
                routerDataService.set('customerGroups', _customerGroups);
            }
        } else {
            routerDataService.set('customer', null);
            routerDataService.set('customerGroups', null);
        }
        return {customer , customerGroups: _customerGroups, customerParams: params };
    }

        
    // console.log("111 NO CUSTOMER FOUND EN ROUTE");
    pathParams = currentUser.get('customer').split(':');
    validsite = currentUser.get('sites', []).filter((site) => {
        if (site) routeParams = site.get('name').split(':');
        return (routeParams[0] == params.customer && routeParams[1] == pathParams[1]);
    });
    if (validsite.size != 0) {
        return redirect(`/customer/${pathParams[0]}/${params['*']}`);
    }
    let site = currentUser.get('sites').first();
    routeParams = site.get('name').split(':');
    return redirect(`/customer/${routeParams[0]}/site/${routeParams[1]}`);
}


export async function siteLoader({params, ...rest}) {
    // console.log("%c111 loading en route site", 'color:gold', params);
    // console.log("%c111 loading en route site...rest", 'color:gold', rest);
    logger.log("site loader called")
    // await dependencies to prevent the router from rendering the route before the data is loaded
    await routerDataService.get('customers');
    await routerDataService.get('customer');
    await routerDataService.get('currentUser');
    
  

    routerDataService.prepare('site');
    let site = await fetchSite(params?.customer, params?.site, params?.['*']);
    site = site.set('nodepath', params?.['*']);
    routerDataService.set('site', site);
    // console.log("%c 111 site loaded", "color:lime");
    return {site, siteParams: params};
}



export async function userLoader() { 
    logger.log("user loader called")
    return true 
}

export async function areaLoader({ params }) {
    logger.log("area loader called")
    // await dependencies to prevent the router from rendering the route before the data is loaded
    let site = await routerDataService.get('site');
    await routerDataService.get('customer');
    await routerDataService.get('customers');
    await routerDataService.get('currentUser');
    
   
   
    // console.log("111 area loaded", params);
    if(site && site.get('nodepath') != params?.['*']) {
        // console.log("1001 the nodepath of site is changed", site.get('nodepath'), params?.['*']);
        site = site.set('nodepath', params?.['*']);
        routerDataService.set('site', site);
    }

    let _params = {...params, params: [params?.['*']]};
    routerDataService.set('params', _params);
    return {areaParams: params?.['*'] || null, allParams: params, site};
}


export function getCustomerFromValidSite(currentUser, params, pathParams, customers) {
    let routeParams = [];
    let validSite = currentUser.get('sites', []).filter((site) => {
        if (site) routeParams = site.get('name').split(':');
        return ((routeParams[0] == params.customer && routeParams[1] == pathParams[1]) || ((pathParams[0]) != "site") && (routeParams[0] == params.customer));
    });
    if (validSite.size != 0) {
        let customer = customers.filter((customer) => {
            return customer.get('name').toLowerCase().split(' ').join('-') == params?.customer;
        }).first();
        logger.log("getCustomerFromValidSite customer", customer);
        logger.log("getCustomerFromValidSite currentUser", currentUser);
        logger.log("getCustomerFromValidSite customers", customers);
        return customer;
    }

    

    return false;
}