import React from 'react';

class Row extends React.Component{
    render(){
        let {children, classes, fields, onClick, className, style} = this.props;

        if (typeof classes === "string" || classes instanceof String){
            let arr = classes.split(' ');
            let classes = {};
            arr.forEach((item) => {
                classes[item] = true;
            });
        }

        return (
            <div className={`grid py-1 col-span-16 grid-cols-16 hover:cursor-pointer ${className || ''}`} onClick={onClick} style={style}>
                {children && React.Children.map(children, (column, index) => {
                    try{
                        return React.cloneElement(column, {
                            classes: fields && fields.get(index) && fields.get(index).get('classes') || "",
                            field: fields && fields.get(index) && fields.get(index)
                        });
                    }
                    catch(err){

                    }
                   
                })}
            </div>
        )
    }
}

export default Row;