import React, { useState } from 'react';
import { getComponents } from '../Actions';
import Select from 'Controls/Form/Select';
import FileInput from 'Controls/Form/FileInput';
import Input from 'Controls/Form/Input';
import Immutable, { List, Map } from 'immutable';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function ImageSelectionDialog({selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <ImageSelectionDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class ImageSelectionDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentRule: props.selecteditem
        }
    }

    async componentDidMount() {
        let components = await getComponents();
        this.setState({
            components: Immutable.fromJS(components)
        });
    }

    getPath(component) {
        let { sitebasepath } = this.state;

        if (component.get('thumbnail')) {
            if (component.get('thumbnail').indexOf('~') == 0) {
                return `${sitebasepath}_thumbnails_${component.get('thumbnail').replace('~', '')}.svg`;
            }
            return `/files/editor/thumbnails_${component.get('thumbnail')}.svg`;
        }
        if (component.get('name').indexOf('~') == 0) {
            return `${sitebasepath}_components_${component.get('name').replace('~', '')}.svg`;
        }
        return `/files/editor/components_${component.get("name")}.svg`;
    }

    imageSelected(component) {
        let { onChange } = this.props;

        if (onChange) {
            onChange(component);
        }
    }

    render() {
        let {onClose} = this.props;
        let {components} = this.state;

        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Text Buttons</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-16 gap-2">
                        {components && components.getIn(["text-buttons", "components"],[]).map((component, index) => {
                            return (
                                <div key={index} className="col-span-12 md:col-span-2" onClick={this.imageSelected.bind(this, component)}>
                                    <div className={`border border-grey-light cursor-pointer rounded hover:bg-blue-lightest`}>
                                        <img className="object-scale-down h-24 pointer-events-none group-hover:opacity-75" height="100" width="100"  src={this.getPath(component)} />
                                    </div>
                            </div> );
                        })}
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={onClose}>Cancel</Button></div>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default ImageSelectionDialog;


