import React, { Suspense, useDeferredValue, useEffect, useLayoutEffect, useMemo, Fragment } from 'react';
import { BrowserRouter as Router, useRevalidator, Route, Routes, Outlet, useParams, useMatches, useLocation, redirect, createBrowserRouter, createRoutesFromElements, RouterProvider, useLoaderData, useRouteLoaderData, useNavigation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import './Reducers';
import { Map, List } from 'immutable';
import { setTopMenu } from './Layout/TopMenu/Actions';
import MainLoader from './MainLoader';
import { TransitionGroup } from 'react-transition-group';
import ServiceBus from 'Services/ServiceBus';
import { useServiceBus } from './Context/ServiceBus';
import ServiceBusContext from './Context/ServiceBus';
import NotificationContext from './Context/Notifications';
import { useBMS } from './Context/BMS';
import { Site } from './Customer/Site';
import Authentication from '../Authentication';

import {
    HistoriesRoute,
    HistoriesTemplatesRoute,
    CalendarsRoute,
    TimeTemplatesRoute,
    SubsystemsRoute,
    SubsystemStatusRoute,
    DatabaseMaintenanceRoute,
    PointEditorRoute,
    PointGroupsRoute,
    UserGroupsRoute,
    CustomerSetupRoute,
    AlarmSetupRoute,
    AlarmFilterGroupsRoute,
    PointListStatusRoute,
    AssetListRoute,
    DocumentListRoute,
    IpTablesRoute
} from './Customer';


import {
    checkAuthenticationLoader,
    loginLoader,
    authenticatedLoader,
    mainLoader,
    customerLoader,
    siteLoader,
    userLoader,
    areaLoader
} from './Router/Loaders';
import {
    authenticatorCheckerRevalidator,
    mainRevalidator,
    usersRevalidator,
    customerRevalidator,
    siteRevalidator,
    areaRevalidator
} from './Router/Revalidators';
import AllAlarmsLog from './Customer/AllAlarmsLog';

import debuggerService from '../Services/DebuggerService';

const logger = debuggerService.getDebugger("BMSView (Component)", "lime");


// import history from './History';
const Users = React.lazy(() => import('./Users'));
const Customer = React.lazy(() => import('./Customer'));
// const PasswordReset = React.lazy(() => import('./Components/Passwordreset'))


export const ApplicationRouter = createBrowserRouter(createRoutesFromElements(<Route id="authentication-checker" shouldRevalidate={authenticatorCheckerRevalidator} loader={checkAuthenticationLoader}>
    <Route path="/login" loader={loginLoader} element={<Authentication><BMSView /></Authentication>} />
    <Route id="main" path='/' shouldRevalidate={mainRevalidator} loader={authenticatedLoader(mainLoader)} element={<Authentication><BMSView /></Authentication>}>
        <Route index path="/" element={<ExactInPath />} />
        <Route path="/users" shouldRevalidate={usersRevalidator} loader={authenticatedLoader(userLoader)} element={ <Users />} />
        <Route path="/no-customer" element={<CustomerReduxLoader><CustomerRoute /></CustomerReduxLoader>} />
        <Route path="/customer/:customer/" id="customer" shouldRevalidate={customerRevalidator} loader={authenticatedLoader(customerLoader)} element={<CustomerReduxLoader><CustomerRoute /></CustomerReduxLoader>}>
            
            <Route path="site/:site/"  id="site" shouldRevalidate={siteRevalidator}
                    element={<SiteReduxLoader><Outlet /></SiteReduxLoader>}
                    loader={authenticatedLoader(siteLoader)}>
                <Route 
                    id="area"
                    shouldRevalidate={areaRevalidator}
                    loader={authenticatedLoader(areaLoader)}
                    path="*?"
                    element={<AreaReduxLoader><SiteRoute /></AreaReduxLoader>} />
            
            </Route>
            <Route path="alarms" element={<AllAlarmsLog />} />
            <Route path="site/:site" exact element={<SiteLoadRedirect />} />
            <Route path="histories" element={<HistoriesRoute  />} />
            <Route path="historiestemplates" element={<HistoriesTemplatesRoute  />} />
            <Route path="calendars" element={<CalendarsRoute  />} />
            <Route path="time-templates" element={<TimeTemplatesRoute  />} />
            <Route path="subsystems" element={<SubsystemsRoute  />} />
            <Route path="subsystemstatus" element={<SubsystemStatusRoute  />} />
            <Route path="databasemaintenance" element={<DatabaseMaintenanceRoute  />} />
            <Route path="pointeditor" element={<PointEditorRoute  />} />
            <Route path="pointgroups" element={<PointGroupsRoute  />} />
            <Route path="usergroups" element={<UserGroupsRoute  />} />
            <Route path="customersetup" element={<CustomerSetupRoute  />} />
            <Route path="alarmsetup" element={<AlarmSetupRoute  />} />
            <Route path="alarmfiltergroups" element={<AlarmFilterGroupsRoute  />} />
            <Route path="pointliststatus" element={<PointListStatusRoute  />} />
            <Route path="assets" element={<AssetListRoute  />} />
            <Route path="documents" element={<DocumentListRoute  />} />
            <Route path="iptables" element={<IpTablesRoute  />} />
        </Route>
    </Route>
</Route>))


function DefaultLayout() {

    const { customer, customers, currentUser } = useBMS();

    logger.log("Default Layout rendering");

    useEffect(() => {
        if(customer && customer.get('name')) {
            const registerForAlarms = (msg) => {
                ServiceBus.send('WEB_MESSAGE_REQ', {
                    type:'REGISTER_FOR_ALARMS',
                    target:'webserver',
                    customer:customer.get('name').toLowerCase().replace(/ /g, ""),
                    userid:currentUser.get('_id'),
                    email:currentUser.get('email'),
                    lastloggedinaddress:currentUser.get('currentloggedinaddress'),
                    lastloggedindatetime:currentUser.get('currentloggedindatetime'),
                    receivealarms:currentUser.get('alarms')
                });
            }
            let unsubscribe = ServiceBus.on('REGISTER_FOR_ALARMS', registerForAlarms);

            registerForAlarms();

            return () => {
                unsubscribe();
            }
        }
    }, [customer, currentUser]);

    useEffect(() => {
        let switchMenu = null;
        if(customers) {
           switchMenu = Map({
                title: `Switch site ${customer && `- ${customer.get('name')}` || ""}`,
                children: customers.reduce((menu, currentCustomer) => {
                    let submenu = List([]);
                    if (currentCustomer.get('sites')) {
                        currentCustomer.get('sites').forEach((site) => {
                            if (currentUser.get('sites', List([])).filter((currentSite) => {
                                if (!currentSite) {
                                    return false;
                                }
                                return currentSite.get('name') == `${currentCustomer.get('name')}:${site.get('name')}`.toLowerCase().split(' ').join('-');
                            }).size > 0) {
                                submenu = submenu.push(Map({
                                    title: site.get('name'),
                                    link: `/customer/${currentCustomer.get('name').toLowerCase().split(' ').join('-')}/site/${site.get('name').toLowerCase().split(' ').join('-')}/`
                                }));
                            }
                        });

                        if (submenu.size > 0) {
                            submenu = submenu.unshift(Map({
                                title: currentCustomer.get('name')
                            }));
                        }
                    }
                    if (submenu.size > 0) {
                        menu = menu.merge(submenu);
                    }
                    return menu;
                }, List([]))
            });
            setTopMenu(List([switchMenu]));
        }
    }, [customer])
    if(!customers) {
        return null;
    }
    return (
        <Suspense fallback={<MainLoader />}>
            <ServiceBusContext>
                <NotificationContext>
                        <TransitionGroup>
                            <Outlet />
                        </TransitionGroup>
                    <ServiceBusConnection />
                </NotificationContext>
            </ServiceBusContext>
        </Suspense>
    )

}


function SiteReduxLoader({ children }) {

    // useEffect(() => {
    //     console.log('%c 111 SiteReduxLoader useEffect', 'color:yellow')
    //     // setSite(site);
    // }, []);



    // console.log('%c 111 SiteReduxLoader render', 'color:yellow')

    return children;
}


function CustomerReduxLoader({ children }) {
    // console.log('%c 111 CustomerReduxLoader render', 'color:yellow')
    return children;
}

function AreaReduxLoader({ children }) {

    const { site } = useRouteLoaderData('area');
    const navigation = useNavigation();
    const revalidator = useRevalidator();
    const { params } = useBMS();

    // console.log("1001 Area Redux Loader", navigation.state);
    // console.log("1001 Area Revalidation State", revalidator.state);
    
    /*
        This setup was really necessary because loading is never turned on on the area redux loader and i need
        the router data to sync with the area
    */
    useSignalLoadedEffect(site);



    // console.log('%c 1001 AreaReduxLoader render', 'color:yellow')

    return children;
}

function SiteLoadRedirect() {
    

    const params = useParams();
    const match = useMatches();
    // console.log("trying to load site redirect");
    // console.log("match", match);
    // console.log("params", params);

    return <Navigate to={`/customer/${params.customer}/site/${params.site}/`} />


}

function useParamsBMS() {
    const { params: mainParams } = useRouteLoaderData('main');
    const {customerParams} = useRouteLoaderData('customer');
    const {siteParams} = useRouteLoaderData('site');
    const {allParams} = useRouteLoaderData('area');

    let params = allParams || siteParams || customerParams || mainParams;

    return params;

}

function SiteRoute() {

    // console.log("1001 i reached site route");
    
    const { site } = useBMS();
    const { customer } = useBMS();
    const { params } = useBMS();

    // console.log("111 customerLoaderData ", customer);
    // console.log("111 siteLoaderData ", site);
    // console.log("%c 111 params",'color:green', params);

    // let siteKey = `${params.customer}:${params.site}:${params['*']}`;
    return <Site site={site} customer={customer} params={params} />
}

function EmptyElement() {

    return null
}

function useSignalLoadedEffect(customDep) {
    const { signalLoaded } = useBMS();
    const revalidator = useRevalidator();

    // console.log("111 SIGNAL LOADED EFFECT render");

    useEffect(() => {
        // console.log("111 SIGNAL LOADED EFFECT useEffect");
        // console.log("1001 SIGNAL LOADED EFFECT");
        if(revalidator.state == "idle") {
            signalLoaded();
        }
        
    }, [revalidator.state, customDep])

}



function LoadedSignalComponent() {
    const { customer, customers, currentUser, signalLoaded } = useBMS();
    
    const navigation = useNavigation();
    
    useEffect(() => {
        if(navigation.state == "idle") {
            // console.log("111 instruction to load signal");
            // console.log("1001 instruction to load signal")
            signalLoaded();
        }
    }, [navigation.state])

    return null;

}

function BMSView() {
    const { customer, customers, currentUser, signalLoaded } = useBMS();
    // console.log("111 BMSView", customer, customers, currentUser);
    return <Fragment>
        <DefaultLayout customer={customer} customers={customers} currentUser={currentUser} />
        <LoadedSignalComponent />
    </Fragment>
}



export default BMSView;

function CustomerRoute() {
    const {params} = useBMS();
    logger.log("customer route rendering");
    return <Customer params={params} />
}

/*
    The site is accesable if the site is in the customers list and the site is in the currentUser
*/
function isSiteAccessible(currentUser, customers, routeParams) {
    let validSite = currentUser.get('sites', List([])).filter((site) => {
        if (!site) {
            return false;
        }
        let siteParams = site.get('name').split(':');
        return siteParams[0] == routeParams[0] && siteParams[1] == routeParams[1];
    });
    if (validSite.size == 0) {
        return false;
    }
    let validCustomer = customers.filter((customer) => {
        return customer.get('name').toLowerCase().split(' ').join('-') == routeParams[0];
    });
    if (validCustomer.size == 0) {
        return false;
    }
    return true;
}


function returnNextAccessibleSite(currentUser, customers, routeParams) {
    let accessibleCustomer = undefined;
    let routeCustomer = customers.filter((customer) => {
        return customer.get('name').toLowerCase().split(' ').join('-') == routeParams[0];
    }).get(0);
    if (!routeCustomer) {
        let nextCustomer = customers.get(0);
        if(!nextCustomer) {
            return false;
        }
        accessibleCustomer = nextCustomer;
    } else {
        accessibleCustomer = routeCustomer;
    }
    if(accessibleCustomer != routeCustomer) {
        return [accessibleCustomer.get('name').toLowerCase().split(' ').join('-'), accessibleCustomer.get('sites').get(0).get('name').toLowerCase().split(' ').join('-')];
    } else {
        let sites = accessibleCustomer.get('sites');
        let nextSite = sites.filter((site) => {
            let siteParams = site.get('name').split(':');
            return siteParams[0] == routeParams[0] && siteParams[1] != routeParams[1];
        });
        if (nextSite.size == 0) {
            return false;
        }
        return nextSite.get(0).get('name').split(':');
    }
}
/*
    There are a couple scenario's that could go wrong here
    nr 1. There is no customer in the currentUser
    nr 2. There is no site in the currentUser
    nr 3. The site in the currentUser is not in the customers list
    nr 4. The site in the currentUser is not in the customer
*/
function ExactInPath() {

    const {currentUser, customers } = useBMS();

    logger.log("Exact in path rendering customers", customers);

    if(!currentUser.get('customer')) {
        return <Navigate to="/no-customer" />
    }

    var routeParams;
    if (currentUser.get('customer')) {
        routeParams = currentUser.get('customer').split(':');
    }    

    if (!routeParams) {
        let sites = currentUser.get('sites');
        if (sites && sites.size > 0) {
            let site = sites.get(0);
            routeParams = site.get('name').split(':');
        } else {
            routeParams = ['demosite','enfield-office']
        }
    }

    if(!isSiteAccessible(currentUser, customers, routeParams)) {
        let nextSite = returnNextAccessibleSite(currentUser, customers, routeParams);
        logger.log("Exact in path nextSite", nextSite, routeParams);
        if(nextSite) {
            return <Navigate to={`/customer/${nextSite[0]}/site/${nextSite[1]}`} />
        }
    } 

    return (
        <Navigate to={`/customer/${routeParams[0]}/site/${routeParams[1]}`} />
    );
}

// service bus connection
function ServiceBusConnection() {

    const { authenticated, offline } = useServiceBus();

    if(authenticated && !offline) {
        return null;
    }

    return <div className="h-6 text-align-center bg-red-700 text-white fixed bottom-0 left-0 w-full text-center">
        There is a problem connecting to the points. { offline ? "It seems that you are not connected to the internet" : ""}
    </div>

}