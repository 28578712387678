import { useState, useEffect } from 'react';
import { alarmDisplayInfo, backgroundBorderColour, backgroundColour, alarmTextColour } from "../Logs/AlarmListPanel"
import ServiceBus from 'Services/ServiceBus';
import {getFileCommunic} from 'BmsView/Actions/Common';
import { useBMS } from '../../Context/BMS';
import {getLog} from 'BmsView/Customer/Logs/Actions';
import Immutable, { get } from 'immutable';
import AlarmLogFilter from 'BmsView/Customer/Logs/AlarmLogFilter';
import AlarmDetailsDialog from 'BmsView/Customer/Logs/AlarmDetailsDialog';
// import AlarmListPanel from 'BmsView/Customer/Logs//AlarmListPanel';
import { AlarmListPanel, AlarmListGrid, AlarmListGridColumn } from 'BmsView/Customer/Logs/AlarmListPanel';
import AlarmRecord from '../../../Components/Alarms/AlarmRecord';
import { updateAlarmRecord } from '../Logs/Actions';
import debuggerService from '../../../Services/DebuggerService';


const logger = debuggerService.getDebugger("All Alarms Log", "gold");

const livePeriods = ['Today','This Week','This Month','This Year'];



export default function AllAlarmsLog() {

    const { customer, currentUser } = useBMS();

    console.log("currentUser", currentUser)

    const [log, setLog] = useState([]);
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType:'Today',
        startDate:new Date(new Date().setHours(0,0,0)),
        endDate:new Date(new Date().setHours(23,59,59))
    });

    const [subsystem, setSubsystem] = useState(-1);
    const [alarmTypes, setAlarmTypes] = useState([]);
    const [type, setType] = useState("All Alarms");
    const [open, setOpen] = useState(false);
    const [selectedAlarm, setSelectedAlarm] = useState(undefined);
    const [liveAlarmMessages, setLiveAlarmMessages] = useState([]);

    const processAlarm = (msg) => {
        logger.log("Processing Alarm", msg);
        if(livePeriods.indexOf(dateTimeRange.rangeType) > -1) {
            getLogRecords();
        } else {
            setLiveAlarmMessages((prev) => {
                return [msg,...prev];
            });
        }
    }

    const onAck = async (alarm, refresh = true) => {
        let alarmrecord = alarm.toJS();
        let ack = {
            user:`${currentUser.get('firstname')} ${currentUser.get('lastname') ? currentUser.get('lastname') : ""}`,
            datetime:Date.now()
        };

        alarmrecord.ackedby ? alarmrecord.ackedby.push(ack):alarmrecord.ackedby = [ack];
        let newalarmrecord = Immutable.fromJS(alarmrecord);

        console.log("Ack Alarm", newalarmrecord);

        await updateAlarmRecord(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, newalarmrecord);
        
            getLogRecords();

    } 

    useEffect(() => {
        let unsubscribe = ServiceBus.on('BROADCAST_ALARM', processAlarm);
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        let unsubscribe = ServiceBus.on('309-11', getFileHandler);
        if(customer.get('subsystems')) {
            let subsystemtype = customer.get('subsystems').filter((subsystem) => {
                return subsystem.get('type') != 5;
            });

            if (!subsystemtype) { 
                getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,0,'trendtemplates','alarmtypelist.json');
            }
        }    

        if (customer) {
            getLogRecords();
        }

        return () => unsubscribe() //  ServiceBus.off('309-11', getFileHandler);
    }, [dateTimeRange, type, customer]);

    useEffect(() => {
        if(customer) {

            const unsubscribe = ServiceBus.on('API_MESSAGE', async (data) => {
                // console.log("API_MESSAGE");
                // console.log("passed data", data);
                getLogRecords();
            });

            return () => {
                unsubscribe();
            }

        }
    }, [customer]);

    const getFileHandler = (msg) => {
        setAlarmTypes(JSON.parse(msg.m_jsonFileData));
    }

    const getLogRecords = () => { 
        let query = {}; 

        if (subsystem >= 0 && type == "All Alarms" )
            query = {'addr.0': subsystem};

        if (subsystem >= 0 && type != "All Alarms" )
            query = {'addr.0': subsystem, messagetext: { $eq: type}};

        getLog('alarm', `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, query, dateTimeRange.startDate, dateTimeRange.endDate).then((data) => {
                let log = Immutable.fromJS(data.map((record) => {
                    // record.datetime = new Date(record.datetime).toLocaleString();
                    return record;
                }));

                setLog(log);
            })
        }  
        
    const subsystemNumberChanged = (number) => {  
        let subsystem = parseInt(number);

        setSubsystem(subsystem);
        setAlarmTypes([]);
        setType("All Alarms");

        if ( subsystem >= 0 ) 
            getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,subsystem,'trendtemplates','alarmtypelist.json');
    }

    const displayAlarmDetails  = (alarm) => {
        setSelectedAlarm(alarm);  
    }

    const clearSelection = () => {
        setSelectedAlarm(undefined);
    }

    let currentUserString = `${currentUser.get('firstname')} ${currentUser.get('lastname') ? currentUser.get('lastname') : ""}`
            
    // Critical alarmas / General alarms / Low levels alarms
    // Priority 1-10 / Priority 11-20 / Priority 21-30

    // console.log("log", log);

    const groupedLogs = (log || []).reduce((col, v, i) => {
        let priority = v.get('priority');
        
        // TODO: This is placed only temporary for development purposes
        // if(i % 3 == 0) {
        //     priority = 1;
        // } else if(i % 3 == 1) {
        //     priority = 11;
        // } else {
        //     priority = 21;
        // }

        let key = ""//priority > 0 && priority < 11 ? "Critical Alarms" : priority > 10 && priority < 21 ? "General Alarms" : "Low Level Alarms";
        
        col[key] = col[key] || [];
        col[key].push(v);
        return col;
    }, {});

    // console.log("groupedLogs", groupedLogs);



    return (
        <div className="pt-4">

            <div className="container mx-auto ">
                {selectedAlarm && <AlarmDetailsDialog selecteditem={selectedAlarm} onClose={clearSelection} />}
                <div className="flex flex-row items-center p-2">
                    <div className="text-2xl">All alarms log</div>
                    <div>
                        <AlarmLogFilter defaultOpen={true} customer={customer} type={type} setType={(value) => setType(value)} subsystem={subsystem} setSubsystem={(value) => subsystemNumberChanged(value)} alarmTypes={alarmTypes}
                                        dateTimeRange={dateTimeRange} setDateTimeRange={setDateTimeRange}/>  
                    </div>
                </div>
            </div>
           
            
            <div>
                <div className="px-3">
                    <AlarmListGrid>
                        {Object.keys(groupedLogs).map((key, index) => {
                            return (
                                <AlarmListGridColumn key={index}>
                                    <div className="text-2xl">{key}</div>
                                    <AlarmListPanel>
                                        {groupedLogs[key].map((alarm, index) =>  { 
                                            
                                            let ABU = alarm.get('ackedby') && alarm.get('ackedby').find((ack) => ack.get('user') == currentUserString);
                                            let acknowledged = ABU ? true : false;

                                            let LAB = undefined;
                                            if (alarm.get('ackedby')) {
                                                LAB = alarm.get('ackedby').last();
                                            }

                                            return (
                                            <AlarmListPanel.AlarmRecordPanel key={index}>
                                                <AlarmRecord alarm={alarm}>
                                                { LAB && <div className="flex gap-1">
                                                        <div>Last acknowledged by {LAB == ABU ? 'you' : LAB.get('user') }</div>
                                                        <div>{(new Date(LAB.get('datetime'))).toLocaleString()}</div>    
                                                    </div>}
                                                    <AlarmRecord.ShowDetailsButton alarm={alarm} displayAlarmDetails={displayAlarmDetails} />
                                                    { !acknowledged && <AlarmRecord.AcknowledgeButton alarm={alarm} onAck={onAck} /> }
                                                    
                                                </AlarmRecord>
                                            </AlarmListPanel.AlarmRecordPanel>)}
                                        )}
                                    </AlarmListPanel>
                                </AlarmListGridColumn>
                            )
                        })}
                    </AlarmListGrid>
                    {/* <AlarmListPanel>
                        {(log || []).map((alarm, index) =>  { 
                            
                            let ABU = alarm.get('ackedby') && alarm.get('ackedby').find((ack) => ack.get('user') == currentUserString);
                            let acknowledged = ABU ? true : false;

                            let LAB = undefined;
                            if (alarm.get('ackedby')) {
                                LAB = alarm.get('ackedby').last();
                            }

                            return (
                            <AlarmListPanel.AlarmRecordPanel key={index}>
                                <AlarmRecord alarm={alarm}>
                                { LAB && <div className="flex gap-1">
                                        <div>Last acknowledged by {LAB == ABU ? 'you' : LAB.get('user') }</div>
                                        <div>{(new Date(LAB.get('datetime'))).toLocaleString()}</div>    
                                    </div>}
                                    <AlarmRecord.ShowDetailsButton alarm={alarm} displayAlarmDetails={displayAlarmDetails} />
                                    { !acknowledged && <AlarmRecord.AcknowledgeButton alarm={alarm} onAck={onAck} /> }
                                    
                                </AlarmRecord>
                            </AlarmListPanel.AlarmRecordPanel>)}
                        )}
                    </AlarmListPanel> */}
                </div>
                {/* <div className="px-3">
                    <AlarmListPanel>
                        {(log || []).map((alarm, index) =>  { 
                            
                            let ABU = alarm.get('ackedby') && alarm.get('ackedby').find((ack) => ack.get('user') == currentUserString);
                            let acknowledged = ABU ? true : false;

                            let LAB = undefined;
                            if (alarm.get('ackedby')) {
                                LAB = alarm.get('ackedby').last();
                            }

                            return (
                            <AlarmListPanel.AlarmRecordPanel key={index}>
                                <AlarmRecord alarm={alarm}>
                                { LAB && <div className="flex gap-1">
                                        <div>Last acknowledged by {LAB == ABU ? 'you' : LAB.get('user') }</div>
                                        <div>{(new Date(LAB.get('datetime'))).toLocaleString()}</div>    
                                    </div>}
                                    <AlarmRecord.ShowDetailsButton alarm={alarm} displayAlarmDetails={displayAlarmDetails} />
                                    { !acknowledged && <AlarmRecord.AcknowledgeButton alarm={alarm} onAck={onAck} /> }
                                    
                                </AlarmRecord>
                            </AlarmListPanel.AlarmRecordPanel>)}
                        )}
                    </AlarmListPanel>
                </div> */}
            </div>
        </div>
    );

    return <div className="container mx-auto pt-4">
        
        
        <div className="text-2xl">All alarms log</div>



    </div>
}