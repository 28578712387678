import { PointList, Text, Image, LegacyBackdropLayer, EzyButton, Compass, TextDigital, TextButton, CustomTextButton,StatusButton, DynamicTable, Dial, Hotspot, Chart, Report, DateTimeText, TextMultiline, Shape, Pipe, SystemObject, Gauge, Duct, Line } from '../SVGComponents';
export const svgComponentConfigurations = {
    'duct': {
        length: true,
        height: true,
        rotation: true
    }
}

export const svgComponents = {
    'label': Text,
    'animation': Image,
    'striped-text': Text,
    'pointlist': PointList,
    'text': Text,
    'image': Image,
    'layer': LegacyBackdropLayer,
    'ezybutton': EzyButton,
    'compass': Compass,
    'text-digital': TextDigital,
    'text-button': TextButton,
    'custom-text-button': CustomTextButton,
    'text-multiline': TextMultiline,
    'status-button': StatusButton,
    'dynamic-table': DynamicTable,
    'dial': Dial,
    'hotspot': Hotspot,
    'chart': Chart,
    'report': Report,
    'date-time-text': DateTimeText,
    'shape': Shape,
    'pipe': Pipe,
    'systemobject': SystemObject,
    'gauge': Gauge,
    'duct': Duct,
    'line': Line
}


export function convertNodeTypeLabelToTypeText(node) {
    if (node.getIn(['component', 'type']) == 'label') {
        node = node.setIn(['component', 'type'], 'text');
        node = node.setIn(['component', 'name'], 'text');
        node = node.setIn(['configuration','displaystyle'], 'boxed');
    }
    return node;
}

export function convertNodeTypeStripedTextToTypeText(node) {
    if (node.getIn(['component', 'type']) == 'striped-text') {
        node = node.setIn(['component', 'type'], 'text');
        node = node.setIn(['component', 'name'], 'text');
        node = node.setIn(['configuration','displaystyle'], 'striped');
    }
    return node;
}

export function transformXYOnElement(element, dx, dy) {
    let transform = element.attr('transform');
    let transformMatch = transform.match(/matrix\((?<a>[\-\s\d\.]+),(?<b>[\-\s\d\.]+),(?<c>[\-\s\d\.]+),(?<d>[\-\s\d\.]+),(?<tx>[\-\s\d\.]+),(?<ty>[\-\s\d\.]+)?\)/)
    let rotationMatch = transform.match(/rotate\((?<r>[\-\s\d\.]+)?\)/)
    if(!transformMatch) {
        transformMatch = transform.match(/translate\((?<tx>[\-\s\d\.]+),(?<ty>[\-\s\d\.]+)?\)/);
    }
    let { groups } = transformMatch;
    let { tx, ty } = groups;
    let currentX = Number(tx);
    let currentY = Number(ty);
    let boxX = currentX + dx;
    let boxY = currentY + dy;
    if(transform.match(/matrix/)) {
        if(rotationMatch) {
            element.attr('transform', `matrix(${groups.a},${groups.b},${groups.c},${groups.d},${boxX}, ${boxY})rotate(${rotationMatch.groups.r})`);
        } else {
            element.attr('transform', `matrix(${groups.a},${groups.b},${groups.c},${groups.d},${boxX}, ${boxY})`);
        }
   } else {
        if(rotationMatch) {
            element.attr('transform', `translate(${boxX}, ${boxY})rotate(${rotationMatch.groups.r})`);
        } else {
            element.attr('transform', `translate(${boxX}, ${boxY})`);
        }
   }
   return {
       x: boxX, y: boxY
   }
}