import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import Checkbox from 'Controls/Form/Checkbox';
import Input from 'Controls/Form/Input';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import MessageListDialog from './MessageListDialog';

function DeviceDialog({ selectedItem, selectedSubsystem, onClose, ...props }) {
    return <Dialog opened={!!selectedItem} onClose={onClose} >
        <DeviceDialogPanel selectedItem={selectedItem} subsystem={selectedSubsystem} onClose={onClose} {...props}/>
    </Dialog>
}

const DeviceDialogPanel = ({selectedItem, subsystem, saveDevice, customer, onClose}) => {
    let [device, setDevice] = useState(selectedItem);
    let [displayDetails, setDisplayDetails] = useState(null);

    useEffect(() => {
    }, []);

    const fieldChanged  = (field, value, transform) => {
        if (field == 'ismessagerouted' && !device.get('ismessagerouted')){
            setDevice(device.set('ismessagerouted', 1).set('routednet', '0').set('routedaddr', Immutable.List([0])));
            return;
        }

        if (transform) {
            value = transform(value);
        }

        setDevice(device.setIn(field, value));
    }

    const convertIPAddressStringToArray = (ipAddress) => {
        let addr = ipAddress.split(".");

        let ipAddr = addr.map((byte) => {
            return parseInt(byte);
        });

        return ipAddr;
    }

    /*

    arrayOfIps
.sort((a, b) => {
        const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3) ).join(""));
        const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3) ).join(""));
        return num1-num2;
}); */

    const onSave = () => {
        saveDevice(device);
    }

    const addAddrField = () => {
        let addr = device.get('routedaddr');
        addr = addr.push(0);
        setDevice(device.set('routedaddr', addr));
    }

    const deleteAddrField = () => {
        let addr = device.get('routedaddr');
        addr = addr.pop();
        setDevice(device.set('routedaddr', addr));
    }

    const displayDeviceDetails = (type) => {
        setDisplayDetails(type);
    }

    const clearSelection = () => {
        setDisplayDetails(null);
    }
        
    return (
        <React.Fragment>
            {(displayDetails != null) && <MessageListDialog customer={customer} selectedItem={device} subsystem={subsystem} type={displayDetails} onClose={() => clearSelection()} />}
           
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Device Definition</DialogTitle>
            </DialogHeader>
            <DialogBody>
            <div className="container-fluid">
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16 md:col-span-12">
                        <Input label="Name" value={device.get('devicename')} onChange={ value => fieldChanged(['devicename'], value)} />
                    </div>
                    <div className="col-span-16 md:col-span-12">
                        <Input label="Descriptor" value={device.get('devicedescriptor')} onChange={ value => fieldChanged(['devicedescriptor'], value)} />
                    </div>
                    <div className="col-span-16 md:col-span-12">
                        <Input label="Location" value={device.get('location')} onChange={ value => fieldChanged(['location'], value)} />
                    </div>
                    <div className="col-span-16 md:col-span-6">
                        <Input label="Instance Number"  type="number" value={device.get('instancenumber')} onChange={ value => fieldChanged(['instancenumber'], value, parseInt)} />
                    </div>
                    <div className="col-span-16 md:col-span-6">
                        <Input label="Controller IP Address" value={device.get('ipaddress')} onChange={ value => fieldChanged(['ipaddress'], value)} minLength="7" maxLength="15" size="15" pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" />
                    </div>
                    <div className="col-span-16 md:col-span-16 mt-2">
                        <Checkbox label="Is Message Routed" checked={(device.get('ismessagerouted', 0) == 1) ? true : false} onChange={ value => fieldChanged(['ismessagerouted'], value, Number)} />
                    </div>
                </div>   
                    { device.get('ismessagerouted') && (
                        <div className="grid grid-cols-16 gap-2 mt-2">
                            <div className="col-span-16 md:col-span-6">
                                <Input label="DNET"  type="number" value={device.get('routednet', '0')} onChange={value => fieldChanged(['routednet'], value, parseInt)} />
                            </div>
                            {device.get('routedaddr') && device.get('routedaddr', []).map((a, i) => {
                                return (
                                    <div className="col-span-16 md:col-span-2">
                                        {i == 0 &&
                                            <Input label="DADR"  type="number" value={a || '0'} key={i} onChange={value => fieldChanged(['routedaddr',i], value, parseInt)} />
                                        } 
                                        {i > 0 &&
                                            <Input label="."  type="number" value={a || '0'} key={i} onChange={value => fieldChanged(['routedaddr',i], value, parseInt)} />
                                        }     
                                    </div>  
                                )
                            })} 
                            {!device.get('routedaddr') &&  (
                                <div className="col-span-16 md:col-span-2">
                                    <Input label="DADR" type="number" value={''} onChange={value => fieldChanged(['routedaddr'], value, parseInt)} />
                                </div>
                            )} 
                            <div className="mt-8"><Button role="secondary" onClick={ () => addAddrField()}>Add</Button></div>
                        </div>  
                    )}
                    <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16 md:col-span-4 mt-2">
                        <Input label="Max APDU"  type="number" value={device.get('maxapdu')} onChange={ value => fieldChanged(['maxapdu'], value, parseInt)} />
                    </div>
                    <div className="col-span-16 md:col-span-12 ml-4 mt-12">
                        <Checkbox label="Segmentation Supported" check={device.get('segmentation')} onChange={ value => fieldChanged(['segmentation'], value, Number)} />
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input label="Vendor ID"  type="number" value={device.get('vendorid')} onChange={ value => fieldChanged(['vendorid'], value, parseInt)} />
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input label="Vendor Name" value={device.get('vendorname')} onChange={ value => fieldChanged(['vendorname'], value )} />
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input label="Model" value={device.get('modelname')} onChange={ value => fieldChanged(['modelname'], value)} />
                    </div>
                    <div className="col-span-16 md:col-span-4"/>
                    <div className="col-span-16 md:col-span-4">
                        <Input label="Software" disabled={true} value={device.get('applicationsoftwareversion')}/>
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input label="Protocol Revision" disabled={true} value={device.get('protocolrevision')}/>
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input label="Protocol Version" disabled={true} value={device.get('protocolversion')}/>
                    </div>
                    <div className="col-span-16 md:col-span-8">
                        <Checkbox label="Read Multiple Properties Supported" disabled={true} checked={device.get('readpropertymultiplesupported')}/>
                    </div>
                </div>
            </div>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={ () => onClose()}>Close</Button></div>
                <div className="pl-1"><Button role="primary" onClick={ () => displayDeviceDetails(2)}>Services Supported</Button></div>
                <div className="pl-1"><Button role="primary" onClick={ () => displayDeviceDetails(3)}>Objects Supported</Button></div>
                <div className="pl-1"><Button role="primary" onClick={ () => onSave()}>Save changes</Button></div>
            </DialogFooter>
        </DialogContent>
        </React.Fragment>
    )
};

export default DeviceDialog;