import * as d3 from 'd3';
import ComponentCache from '../ComponentCache';

export default function Compass(options, path) {
    return new Promise((resolve, reject) => {
        ComponentCache.getXml(path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = drawCompass(image, options);

            ComponentCache.getXml('/files/editor/components-v2_dials_compasspointer.svg').then((pointerxml) => {
                let elementPointer = d3.select(pointerxml.documentElement).select('#layer1');
                let pointerElement = drawCompassPointer(placementElement, elementPointer, options);
  
                resolve({
                    setValue: (point) => {
                        options.node = options.node.setIn(['pointconfiguration'], point);
                        let value = point.m_value;

                        let box1 = placementElement.node().getBBox();
                        let pointerWidth = 16;
                        let pointerHeight = 140;            
                        let x2 = box1.width / 2 - pointerWidth / 2;
                        let y2 = (box1.height - pointerHeight) / 2;
                        pointerElement.attr('transform', `rotate(${value},${x2 + pointerWidth / 2}, ${box1.height / 2} ) translate(${x2}, ${y2})`);
                    },
                    setContextMenu: (editmode) => {
                        options.editmode = editmode;
                    },
                    getElement: () => {
                        return placementElement;
                    },
                    setOptions: (newOptions) => {
                        options = {...options, ...newOptions}
                    },
                    redrawElement: (node, isSelected) => {
                        placementElement.remove();
                        pointerElement.remove();
                        options.node = node;
                        placementElement = drawCompass(image, options);
                        pointerElement = drawCompassPointer(placementElement, elementPointer, options);
                        if(isSelected) {
                            placementElement.attr('class', 'node-selected');
                        }
                    },
                    remove: () => {
                        placementElement.remove();
                        pointerElement.remove();
                    },
                    resetIndex: (index) => {
                        options.index = index;
                        placementElement.attr('data-index', options.index);
                    }
                });
        })
        })
    })
}

function drawCompass(image, options) {
    let element = d3.select(options.target)
        .append('g').attr('data-index', options.index).html(image.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

    return element;
}

function drawCompassPointer(element, elementPointer, options) {
    let box1 = element.node().getBBox();
    let pointerWidth = 16;
    let pointerHeight = 140;

    let x2 = box1.width / 2 - pointerWidth / 2;
    let y2 = (box1.height - pointerHeight) / 2;
    
    //TODO: Make it work using a dynamic value, the 95 represents the value that needs to change, according to value. The minimum is 0, and the maximum is 360
    let pointerElement = element.append('g').attr('data-index', options.index).html(elementPointer.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `rotate(0,${x2 + pointerWidth / 2}, ${box1.height / 2} ) translate(${x2}, ${y2})`);

    return pointerElement;    
}