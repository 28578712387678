import React from 'react';
import Grid from 'Controls/GridV2';
import Immutable from 'immutable';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';


function PointListStatusDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} zIndex="z-20" layer="top" onClose={onClose}>
        <PointListStatusDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class PointListStatusDialogPanel extends React.Component {
    onSelect(point) {
        let { onSelect } = this.props;
        if (onSelect) {
            onSelect(Immutable.Map({}).setIn(['configuration', 'boundto'], point.get('value')).set('pointconfiguration', point.get('pointconfiguration')));
        }
    }
    render() {
        let { selecteditem, lastupdated } = this.props;

        return (
            <DialogContent className="md:w-[500px] lg:w-[800px] xl:w-[1140px]">
                <DialogBody>
                    <div>
                        <h1 className="flex">{selecteditem.getIn(['configuration', 'value'])}</h1>
                        <Grid className="mt-3" key={lastupdated}>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-10">Point Name</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">Status</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">Alarm</Grid.Column>
                            </Grid.Header>
                            {selecteditem.getIn(['configuration', 'points'], []).map((point) => {
                                return (
                                    <Grid.Row onClick={this.onSelect.bind(this, point)}>
                                        <Grid.Column classes="col-span-16 md:col-span-10">{point.get('label')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-3">{point.get('currentvalue', 'pending')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-3">{point.get('currentalarmstate', 'pending')}</Grid.Column>
                                    </Grid.Row>
                                )
                            })}
                        </Grid>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={this.props.onClose}>Cancel</Button></div>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default PointListStatusDialog;