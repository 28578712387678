import { List } from 'immutable';
import React, { useState, useMemo, useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

const EditModeContext = React.createContext({});

export const EditModeProvider = EditModeContext.Provider;



export function useEditMode() {
    return useContext(EditModeContext);
}



export default function EditModeWrapper({ children }) {
    const [editmode, setEditmode] = useStateIfMounted();
    const [disableEditmode, setDisableEditmode] = useStateIfMounted();
    const value = useMemo(() => ({ disableEditmode, setDisableEditmode }), [disableEditmode]);
    return <EditModeProvider value={value}>
        {children}
    </EditModeProvider>
}