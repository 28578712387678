import axios from 'axios';
import Configuration from 'Configuration';

export function getControllers(customer, connection) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/cbuscontrollers?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    })
}



