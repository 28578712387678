

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function getDaysInMonth(m, y) {
    return m == 2 ? (!((y % 4) || (!(y % 100) && (y % 400))) ? 29 : 28) : (30 + ((m >> 3 ^ m) & 1));
}

export function nextDay(date) {
    let [year, month, day] = date.split('-').map(x => Number(x));
    let daysInMonth = getDaysInMonth(month, year);
    let newYear = year; 
    let newMonth = month;
    let newDay = day;
    if((day + 1) > daysInMonth) {
        newDay = 1;
        if((month + 1) > 12) {
            newMonth = 1;
            newYear = year + 1;
        } else {
            newMonth = month + 1;
        }
    } else {
        newDay = day + 1;
    }
    return `${newYear}-${("0" + (newMonth)).slice(-2)}-${("0" + newDay).slice(-2)}`
}

function getMonth(month, year) {
    let days = []
    let current = new Date();
    let startOfMonth = new Date();
    startOfMonth.setFullYear(year, month, 1);
    // console.log("startOfMonth", startOfMonth);
    let dowStart = startOfMonth.getDay() ;
    // console.log("dowStart", dowStart);
    let currentMonthDays = getDaysInMonth(month + 1, year);
    let nextMonth = (month + 1) % 12;
    let nextYear = (month + 1) > 11 ? year + 1 : year;
    let previousMonth = month == 0 ? 11 : month - 1;
    let previousYear = month == 0 ? year - 1 : year
    let nextMonthDays = getDaysInMonth(nextMonth + 1, nextYear);
    let previousMonthDays = getDaysInMonth(previousMonth + 1, previousYear);
    
    let prevMonthDays = 0;
    if(dowStart > 1) {
        prevMonthDays = dowStart -1;
    } 
    if(dowStart == 0) {
        prevMonthDays = 6;
    }

    let prevDays = [];
    while(prevMonthDays > 0) {
        prevDays.push({ 
            isCurrentMonth: false,
            date: `${previousYear}-${("0" + (previousMonth + 1)).slice(-2)}-${("0" + previousMonthDays--).slice(-2)}`
        })
        prevMonthDays--;
    }
    days.push(...prevDays.reverse())


    let day = 1;
    while(day <= currentMonthDays) {
        days.push({ 
            isCurrentMonth: true,
            isToday: current.getFullYear() == year && current.getMonth() == month && current.getDate() == day,
            date: `${year}-${("0" + (month + 1)).slice(-2)}-${("0" + day).slice(-2)}`
        });
        day++;
    }

    day = 1;
    while(days.length < 42) {
        days.push({ 
            isCurrentMonth: false,
            date: `${nextYear}-${("0" + (nextMonth + 1)).slice(-2)}-${("0" + day).slice(-2)}`
        })
        day++;
    }

    return days;


}

export function generateMonths(baseMonth, baseYear) {
    const dt = new Date();
    let startYear = baseYear || dt.getFullYear();
    let startMonth = baseMonth || dt.getMonth();
    
    let months = [];
    for (var i = startMonth; i < startMonth + 12; i++) {
        
        let month = (i % 12);
        let year = i > 11 ? startYear + 1 : startYear;
        let firstDay = (new Date());
        firstDay.setFullYear(year, month, 1);
        // console.log(`processing ${month}/${year} - ${i}`)
        let days = getMonth(month, year);
        


        let monthToAdd = {
            'name': firstDay.toLocaleString('default', { month: 'long' }),
        };

        monthToAdd.days = days;
        monthToAdd.year = year;
        months.push(monthToAdd);
    };
    return months;
}

export function _generateMonths() {
    const dt = new Date();
    let year = dt.getFullYear();
    let thismonth = dt.getMonth();
    let currentmonth;
    let currentday = dt.getDate();

    const yearstart = new Date();

    const mstart = new Date();
    var dstart;
    var dow;
    var date;
    var days = [];
    var newmonths = [];
    var month;
    var mt;

    for (var m = 0; m < 12; m++) {
        days = [];
        currentmonth = m;
        mstart.setFullYear(year, m, 1);
        dstart = new Date(mstart.getTime());
        mt = m + 1;
        let daysinmonth = getDaysInMonth(mt, year);

        dow = mstart.getDay();

        if (m == 0) { // processing january
            if (dow == 0) {
                daysinmonth = 31;
                year--;
                dstart.setFullYear(year, 11, 26);
            } else if (dow > 1) {
                daysinmonth = 31;
                year--;
                dstart.setFullYear(year, 11, 31 - dow + 2)
            }
        }

        else {
            if (dow == 0) {
                mt = m - 1;
                daysinmonth = getDaysInMonth(m, year);

                dstart.setFullYear(year, mt, daysinmonth - 7 + 2);

            } else if (dow > 1) {
                mt = m - 1;
                daysinmonth = getDaysInMonth(m, year);
                dstart.setFullYear(year, mt, daysinmonth - dow + 2)
            }

        }

        month = {
            'name': mstart.toLocaleString('default', { month: 'long' }),
        };

        let day = dstart.getDate();
        let mtemp = dstart.getMonth();

        for (var d = 0; d < 42; d++) {
            if (day > daysinmonth) {
                day = 1;
                if (mtemp == 11) { mtemp = 0; year++; }
                else mtemp++;
                mt = mtemp + 1;
                daysinmonth = getDaysInMonth(mt, year);
            }
            date = { 'date': `${dstart.getFullYear()}-${("0" + (mtemp + 1)).slice(-2)}-${("0" + day).slice(-2)}` };
            if (mtemp == currentmonth) {
                date.isCurrentMonth = true;
            }
            if (mtemp == thismonth && day == currentday) {
                date.isToday = true;
            }
            day++;
            days.push(date);
        }
        month.days = days;
        newmonths.push(month);
    };
    year--;
    return newmonths;
}
