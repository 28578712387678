import { List } from 'immutable';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveUser } from 'BmsView/Users/Actions';
import { Map } from 'immutable';
import { getCurrentUser, login } from 'Actions/Authentication';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useBMS } from '../BmsView/Context/BMS';

const UserSettingsContext = React.createContext({});

export const UserSettingsProvider = UserSettingsContext.Provider;



export function useUserSettings() {
    return useContext(UserSettingsContext);
}

export function withUserSettings(Component) {
    return (props) => {
        //const { userSettings, setUserSettings, saveUserSettings, resetUserSettings } = useUserSettings();
        const userSettings = useUserSettings();
        return <Component userSettings={userSettings} {...props} />
    }
}

// @connect((state) => ({
//     currentUser: state.get('currentUser')
// }))
function UserSettingsWrapper({ children }) {
    
    const { currentUser } = useBMS();
    const { customer } = useBMS();

    const [userSettings, setUserSettings] = useStateIfMounted(Map());

    const saveUserSettings = () => {
        return saveUser(currentUser.setIn(['systemDisplayProperties', getCustomerName(customer)], userSettings)).then(() => {
            return getCurrentUser();
        });
    }

    const resetUserSettings = () => {
        setUserSettings(currentUser.getIn(['systemDisplayProperties',getCustomerName(customer)], Map()))
    }

    const getCustomerName = (customer) => {
        return customer.get('name').toLowerCase().split(' ').join('-')
    }

    useEffect(() => {
        // console.log("a change in user settings was found");
        // console.log(currentUser);
        if(currentUser && customer) {
            setUserSettings(currentUser.getIn(['systemDisplayProperties',getCustomerName(customer)], Map()))
        }

    }, [currentUser, customer]);

    const value = useMemo(() => ({ userSettings, setUserSettings, saveUserSettings, resetUserSettings }), [userSettings, customer, currentUser]);

    return <UserSettingsProvider value={value}>
        {children}
    </UserSettingsProvider>
}

export default UserSettingsWrapper;