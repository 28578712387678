import React from 'react';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';
import Immutable from 'immutable';

class Multistate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {           
            priority:8,
            value:0
        }
    }
    componentDidMount() {
        let { value, priority } = this.props;

        this.setState({
            value: value,
            priority: priority | 8
        });
    }

    onChange(value){
        this.setState({
            value: value
        });
    }

    commitChange(){
        let {value, priority} = this.state;
        let {onChange} = this.props;

       onChange(2, value, priority);
    }

    render() {
        let { value } = this.state;
        let {selectedpoint, onShowPointPriorities  } = this.props;

        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');

        let texts = ["0","1","2","3","4","5"]
        if (selectedpoint && selectedpoint.getIn(['pointconfiguration', 'm_stateText'])) {
            try {
                texts = selectedpoint.getIn(['pointconfiguration', 'm_stateText'], Immutable.List([])).toJS();
            }
            catch (e) {
                let raw = selectedpoint.toJS();
            }
        }
        return (
            <div className="col-span-16 md:col-span-8">
                <Select
                    label="Select Value"
                    value={value}
                    onChange={this.onChange.bind(this)}
                    options={[...texts.map((item, index) => {
                        return {
                            text: item,
                            value: index + 1
                        }})]
                    }    
                />  
                <Button role="success" className="mt-4" onClick={this.commitChange.bind(this)}>Set</Button>   
                {address[1] == 9 &&
                    <Button role="success" className="mt-4 ml-8" onClick={onShowPointPriorities}>Priorities</Button>} 
            </div>
        )
    }
}

export default Multistate;

