import axios from 'axios';
import Configuration from 'Configuration';

export function getDocumentConfiguration() {
    return new Promise((resolve, reject) => {
        axios.get('/files/common/documents.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getDocuments(customer, connection) {
    return new Promise((resolve,reject) => {
        axios.get(`/api/documents?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function saveDocument(customer, connection, document) {
    return new Promise((resolve,reject) => {
        if (document.get('_id')) {
            axios.put(`/api/documents/${document.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, document.toJS()).then(resolve).catch(reject);
        }
        else {
            axios.post(`/api/documents?database=${Configuration.product}${customer}&connection=${connection}`, document.toJS()).then(resolve).catch(reject);
        }
    });
}

export function deleteDocument(customer, connection, document) {
    return new Promise((resolve,reject) => {
        if (document.get('_id')) {
            axios.delete(`/api/documents/${document.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            })
        }
    });
}

