import { List } from 'immutable';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveUser } from 'BmsView/Users/Actions';
import { Map, fromJS } from 'immutable';
import { login } from 'Actions/Authentication';
import axios from 'axios';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useBMS } from '../BmsView/Context/BMS';

async function _saveCustomerSettings(customerName, customerSettings) {
    let response = await axios.post(`/files/customers/${customerName}_customer-default-properties.json`, customerSettings)
    return true;
}

async function getCustomerSettings(customerName) {
    let response = await axios.get(`/files/customers/${customerName}_customer-default-properties.json`);
    return fromJS(response.data);
}

const CustomerSettingsContext = React.createContext({});

export const CustomerSettingsProvider = CustomerSettingsContext.Provider;



export function useCustomerSettings() {
    return useContext(CustomerSettingsContext);
}

export function withCustomerSettings(Component) {
    return (props) => {
        //const { customerSettings, setCustomerSettings, saveCustomerSettings, resetCustomerSettings } = useCustomerSettings();
        const customerSettings = useCustomerSettings();
        return <Component customerSettings={customerSettings} {...props} />
    }
}

function CustomerSettingsWrapper({ children, customer:customCustomer }) {

    const { customer:mainCustomer } = useBMS();
    
    const customer = customCustomer || mainCustomer;

    const [initialCustomerSettings, setInitialCustomerSettings] = useStateIfMounted(Map());
    const [customerSettings, setCustomerSettings] = useStateIfMounted(Map());

    const saveCustomerSettings = () => {
        return _saveCustomerSettings(getCustomerName(customer), customerSettings);
    }

    const resetCustomerSettings = () => {
        setCustomerSettings(initialCustomerSettings);
    }

    const getCustomerName = (customer) => {
        return customer.get('name').toLowerCase().split(' ').join('-')
    }

    useEffect(() => {
        if(customer) {
            getCustomerSettings(getCustomerName(customer)).then((data) => {
                setCustomerSettings(data);
                setInitialCustomerSettings(data);
            }).catch((err) => {
                // console.log("customer settings not found");
                // console.log(err)
                setCustomerSettings(Map());
            })
            // console.log("mounting settings ", customer.get('name'));
        }

    }, [customer]);

    const value = useMemo(() => ({ customerSettings, setCustomerSettings, saveCustomerSettings, resetCustomerSettings }), [customerSettings, customer]);

    return <CustomerSettingsProvider value={value}>
        {children}
    </CustomerSettingsProvider>
}

export default CustomerSettingsWrapper