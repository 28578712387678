import React, { Fragment } from 'react';
import Input from 'Controls/Form/Input';
import Immutable, { List } from 'immutable';

import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function NodesTemplateDialog({selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <NodesTemplateDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class NodesTemplateDialogPanel extends React.Component{
    constructor(props){
        super(props);

        // console.log("NodesTemplateDialog constructor");
        // console.log("props", props);

        this.state = {
            editing: !!props.selecteditem.get('variables'),
            selectednodetemplate: props.selecteditem,
            variableconfiguration: props.selecteditem.get('variables') || Immutable.List([])
        }

        // props.setSaveHandler(this.save.bind(this));
    }

    // saving in the template dialog
    save(){
        let {selectednodetemplate, variableconfiguration} = this.state;
        let {onSave} = this.props;

        if (onSave){
            onSave(selectednodetemplate, variableconfiguration);
        }
    }

    valueChanged(fields, value){
        this.setState(({selectednodetemplate}) => ({
            selectednodetemplate: selectednodetemplate.setIn(fields, value)
        }));
    }

    valuePointlistChanged(fields, value){
        this.setState(({selectednodetemplate}) => ({
            selectednodetemplate: selectednodetemplate.setIn(fields, value)
        }));
    }

    configurationChanged(field, value){
        this.setState(({variableconfiguration}) => ({
            variableconfiguration: variableconfiguration.setIn(field, value)
        }));
    }

    addVariable(){
        this.setState(({variableconfiguration}) => ({
            variableconfiguration: variableconfiguration.push(Immutable.Map({}))
        }))
    }

    render(){
        let {selectednodetemplate, variableconfiguration, editing } = this.state;

        // console.log("NodesTemplateDialog");
        // console.log("props", this.props);
        // console.log("state", this.state);

        let templateNodes = List();
        if(selectednodetemplate.get('isV2')){

            selectednodetemplate.get('layers').forEach((layer, layerindex) => {


                templateNodes = templateNodes.merge(layer.get('nodes').map((node, index) => { 
                    return (
                        (node.getIn(['component', 'type']) != "pointlist") ? (
                            <Fragment key={index.toString()}>
                                <div className="grid-column w-g-2 col-span-2">{node.getIn(['component', 'type'])}</div>
                                <div className="grid-column w-g-4 col-span-4"><Input noMargin value={node.getIn(['configuration', 'boundto'])} onChange={this.valueChanged.bind(this, ['layers',layerindex, 'nodes', index, 'configuration', 'boundto'])} /></div>
                                <div className="grid-column w-g-4 col-span-4"><Input noMargin value={node.getIn(['configuration', 'value'])} onChange={this.valueChanged.bind(this, ['layers',layerindex, 'nodes', index, 'configuration', 'value'])} /></div>
                                <div className="grid-column w-g-6 col-span-6"><Input noMargin value={node.getIn(['configuration', 'linkto'])} onChange={this.valueChanged.bind(this, ['layers',layerindex, 'nodes', index, 'configuration', 'linkto'])} /></div>
                            </Fragment>) : ( node.getIn(['configuration', 'points'], []).map((point, index2) => {
                                return (
                                <Fragment key={index2.toString()}>
                                    <div className="grid-column w-g-2 col-span-2">{node.getIn(['component', 'type'])}</div>
                                    <div className="grid-column w-g-4 col-span-4"><Input noMargin value={point.get('value')} onChange={this.valuePointlistChanged.bind(this, ['layers',layerindex, 'nodes', index, 'configuration', 'points', index2, 'value'])} /></div>
                                    <div className="grid-column w-g-4 col-span-4"></div>
                                    <div className="grid-column w-g-6 col-span-6"></div>
                                </Fragment>)
                            })
                        )
                    )
                }))

            })
        } else {
            templateNodes = selectednodetemplate.get('nodes').map((node, index) => { 
                return (
                    (node.getIn(['component', 'type']) != "pointlist") ? (
                        <Fragment key={index.toString()}>
                            <div className="grid-column w-g-2 col-span-2">{node.getIn(['component', 'type'])}</div>
                            <div className="grid-column w-g-4 col-span-4"><Input noMargin value={node.getIn(['configuration', 'boundto'])} onChange={this.valueChanged.bind(this, ['nodes', index, 'configuration', 'boundto'])} /></div>
                            <div className="grid-column w-g-4 col-span-4"><Input noMargin value={node.getIn(['configuration', 'value'])} onChange={this.valueChanged.bind(this, ['nodes', index, 'configuration', 'value'])} /></div>
                            <div className="grid-column w-g-6 col-span-6"><Input noMargin value={node.getIn(['configuration', 'linkto'])} onChange={this.valueChanged.bind(this, ['nodes', index, 'configuration', 'linkto'])} /></div>
                        </Fragment>) : ( node.getIn(['configuration', 'points'], []).map((point, index2) => {
                            return (
                            <Fragment key={index2.toString()}>
                                <div className="grid-column w-g-2 col-span-2">{node.getIn(['component', 'type'])}</div>
                                <div className="grid-column w-g-4 col-span-4"><Input noMargin value={point.get('value')} onChange={this.valuePointlistChanged.bind(this, ['nodes', index, 'configuration', 'points', index2, 'value'])} /></div>
                                <div className="grid-column w-g-4 col-span-4"></div>
                                <div className="grid-column w-g-6 col-span-6"></div>
                            </Fragment>)
                        })
                        )
            )})
        }


        return (
            <DialogContent>
                <DialogBody>
                    <h1>{ editing ? <Fragment>Create area template</Fragment> : <Fragment>Edit area template</Fragment>}</h1>
                    <Input 
                        disabled={editing}
                        label="Name" 
                        value={selectednodetemplate.get('name')}
                        onChange={this.valueChanged.bind(this, ['name'])} 
                    />

                    <div className="grid grid-cols-16 gap-4">
                        {/* <div className="row grid-header md:flex"> */}
                            <div className="w-g-2 col-span-2">Component</div>
                            <div className="w-g-4 col-span-4">Bound</div>
                            <div className="w-g-4 col-span-4">Value</div>
                            <div className="w-g-6 col-span-6">Link</div>
                        {/* </div> */}
                        {/* <div className="grid-data"> */}
                            {templateNodes}
                        {/* </div> */}
                    </div>

                    <div className="grid grid-cols-16 gap-2">
                        {/* <div className="row grid-header md:flex"> */}
                            <div className="grid-column w-g-6 col-span-6">Variable name</div>
                            <div className="grid-column w-g-10 col-span-10">Displayname</div>
                        {/* </div> */}
                        {/* <div className="grid-data"> */}
                            {variableconfiguration.map((configuration, index) => {
                                return (
                                    <Fragment key={index.toString()}>
                                        <div className="grid-column w-g-6 col-span-6"><Input value={configuration.get('name')} onChange={this.configurationChanged.bind(this, [index, 'name'])} /></div>
                                        <div className="grid-column w-g-10 col-span-10"><Input value={configuration.get('displayname')} onChange={this.configurationChanged.bind(this, [index, 'displayname'])} /></div>
                                    </Fragment>

                                )
                            })}
                        {/* </div> */}
                        {/* <div className="grid-data"> */}
                            {/* <div className="grid-row row"> */}
                                <div className="grid-column w-g-6 col-span-6"></div>
                                <div className="grid-column w-g-10 col-span-10"><a className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={this.addVariable.bind(this)}>Add variable</a></div>
                            {/* </div>
                        </div> */}
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button role="close" onClick={this.props.onClose}>Cancel</Button>
                    <Button role="save" onClick={this.save.bind(this)}>Save</Button>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default NodesTemplateDialog;