import React, {useState} from 'react';
import Input from 'Controls/Form/Input';
import Checkbox from 'Controls/Form/Checkbox';
import TextArea from 'Controls/Form/TextArea'

const AlarmDetails = ({index, changedInput, pointconfiguration, engunit}) => {
    let [change, setChange] = useState(false);

    const changeInput = (value) => {
        changedInput(['engunits', index], undefined, value);
        setChange(false);
    }

    const changeEngUnit = () => {
        setChange(true);
    }

    let enable = pointconfiguration.getIn(['extendedalarmactions','enabled'], false) ? true: false;

    return(
        <div className="flex flex-col md:flex-row px-8 py-4 min-h-full">
            <div className="grid flex-col-1 grid-cols-12 gap-4">
                <div className="col-span-3">
                    Alarm active text
                </div> 
                <div className="col-span-9">
                    <Input type="text" onChange={(value) => changedInput(['alarmparameters', 'acttxt'], undefined, value)} value={pointconfiguration.getIn(['alarmparameters', 'acttxt'])} />
                </div>
                <div className="col-span-3">
                    Alarm cleared text
                </div> 
                <div className="col-span-9">
                    <Input type="text" onChange={(value) => changedInput(['alarmparameters', 'clrtxt'], undefined, value)} value={pointconfiguration.getIn(['alarmparameters', 'clrtxt'])} />
                </div>
                <div className="col-span-3">
                    Alarm Level
                </div> 
                <div className="col-span-9">
                    <Input type="number" onChange={(value) => changedInput(['alarmlevel'], parseInt, value)} value={pointconfiguration.get('alarmlevel')} />
                </div>
                <div className="col-span-3">
                    Alarm jump to location
                </div> 
                <div className="col-span-9">
                    <Input type="text" onChange={(value) => changedInput(['alarmparameters', 'link'], undefined, value)} value={pointconfiguration.getIn(['alarmparameters', 'link'])} />
                </div>
                <div className="col-span-3">
                    Alarm sound
                </div> 
                <div className="col-span-9">
                    <Input type="text" onChange={(value) => changedInput(['alarmparameters', 'snd'], undefined, value)} value={pointconfiguration.getIn(['alarmparameters','snd'])} />
                </div>
                <div className="col-span-12 truncate">
                    <TextArea
                        label="Detailed Text"
                        rows="10"
                        value={pointconfiguration.getIn(['alarmparameters', 'exttxt'])}
                        onChange={(value) => changedInput(['alarmparameters', 'exttxt'], undefined, value)}
                    />
                </div> 
                <div className="col-span-8">
                    <Checkbox label="Suppress Alarm" onChange={(value) => changedInput(['suppressalarm'], undefined, value ? 1 : 0)} checked={enable} />
                </div>
            </div>
        </div>
        )           
}

export default AlarmDetails;

