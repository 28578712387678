import React from 'react';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';
import {MagnifyingGlassIcon} from '@heroicons/react/24/solid';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Grid from 'Controls/GridV2';
import ModuleTypes from 'Configuration/ModuleTypes';
import { searchPoints } from 'BmsView/Customer/Site/Actions';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { setPointselection } from '../Actions';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { useBMS } from 'BmsView/Context/BMS';
import useAreaCustomer from '../../Hooks/useAreaCustomer';
import useAreaSite from '../../Hooks/useAreaSite';

function PointSelectionDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <PointSelectionDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class PointSelectionDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedpoint: props.pointselection && props.pointselection.getIn(['filter']) || Immutable.Map({}),
            points: props.pointselection && props.pointselection.getIn(['points']) || undefined,
            performancetype: 0
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.pointselection != this.props.pointselection) {
            this.setState({
                selectedpoint: this.props.pointselection && this.props.pointselection.getIn(['filter']) || Immutable.Map({}),
                points: this.props.pointselection && this.props.pointselection.getIn(['points']) || undefined
            });
        }
    }
    // PointSelection
    valueChanged(field, value) {
        if ( field == "subsystem") {      
            let selectedpoint = Immutable.Map({
                'subsystem': value,
                'pointtype': 0 });
                 
            this.setState({
                selectedpoint: selectedpoint
            });

            setPointselection({
                filter: selectedpoint.toJS(),
                points: undefined
            });

            return;
        }

        this.setState(({ selectedpoint }) => ({
            selectedpoint: selectedpoint.setIn(field, value)
        }))
    }

    changePerformanceType(value){
        this.setState({performancetype:parseInt(value)});
    }

    async searchPoints() {
        let { customer } = this.props;
        let { selectedpoint } = this.state;
        let query = {}

        let selectedSubsystem = customer.get('subsystems', Immutable.List([])).get(selectedpoint.get('subsystem', 0));
        query['addr.0'] = parseInt(selectedSubsystem.get('number'));

        if (selectedpoint.get('lan') && selectedpoint.get('lan').length > 0) {
            query['addr.3'] = parseInt(selectedpoint.get('lan'))
        }
        if (selectedpoint.get('outstation') && selectedpoint.get('outstation').length > 0) {
            query['addr.4'] = parseInt(selectedpoint.get('outstation'))
        }
        query['addr.2'] = parseInt(selectedpoint.get('pointtype', 0));

        let points = await searchPoints(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase().replace(/ /g, ""), query);

        setPointselection({
            filter: selectedpoint.toJS(),
            points: points
        });
    }

    onSelect(point) {
        let addr = point.get('addr').toArray();
        let {performancetype} = this.state;
        let { onSelect } = this.props;

        if (addr[1] == 5 && addr[2] == 13 && performancetype != 0) {
            addr[4] = performancetype;
            point = point.set('addr', addr);
        }

        if (onSelect) {
            onSelect(point);
        }
    }

    render() {
        let { onClose, customer } = this.props;
        let { selectedpoint, points } = this.state;

        let selectedSubsystem = customer.get('subsystems', Immutable.List([])).get(selectedpoint.get('subsystem', 0));
        if (!selectedSubsystem) return (
            <DialogHeader>
                <DialogTitle>Point Selection - No Subsystems Available</DialogTitle>
            </DialogHeader>    
        );
        let selectedSubsystemType = ModuleTypes[selectedSubsystem.get('type')];
        let pointtype = selectedSubsystemType.pointtypes[selectedpoint.get('pointtype', 0)];

        let gridFields = [{ label: 'Name', value: 'name', name: 'Point', width: 'md:col-span-6' }];

        let selectionWidth = 'md:col-span-4';

        if (selectedSubsystemType.moduleid == 9) {
           gridFields = [{ label: 'Name', value: 'name', name: 'Point', width: 'md:col-span-6' },
            { label: 'Descriptor', value: 'description', name: 'Descriptor', width: 'md:col-span-4' }];
            selectionWidth = 'md:col-span-2'
        }

        if (pointtype) {
            (pointtype.fields || []).forEach((field, index) => {
                gridFields.push({
                    label: field.label,
                    value: 'addr',
                    byte: index + 3,
                    name: field.label.toLowerCase(),
                    width: 'md:col-span-2'
                });
            });
        }

        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Point Selection</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-16 gap-2">
                        <div className="col-span-12 md:col-span-5">
                            <Select
                                label="Subsystem"
                                value={selectedpoint.getIn(['subsystem'], 0)}
                                onChange={this.valueChanged.bind(this, ['subsystem'])}
                                options={customer.get('subsystems', []).map((subsystem, index) => {
                                    return {
                                        value: index,
                                        text: subsystem.get('name')
                                    }
                                })}
                            />
                        </div>
                        <div className="col-span-16 md:col-span-5">
                            <Select
                                label="Point Type"
                                value={selectedpoint.getIn(['pointtype'], 0)}
                                onChange={this.valueChanged.bind(this, ['pointtype'])}
                                options={selectedSubsystemType.pointtypes.map((pointtype, index) => {
                                    return {
                                        value: index,
                                        text: pointtype.name
                                    }
                                })}
                            />
                        </div>
                        <div className="col-span-16 md:col-span-2">
                            {selectedSubsystemType.moduleid == 5 && selectedpoint.getIn(['pointtype']) == 13 && <Input label="Type" value={selectedpoint.getIn(['point'])} onChange={this.changePerformanceType.bind(this)} />}
                            {selectedSubsystemType.moduleid == 5 && selectedpoint.getIn(['pointtype']) != 13 && <Input label="Lan" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                            {selectedSubsystemType.moduleid == 6 && <Input label="Controller" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                            {selectedSubsystemType.moduleid == 9 && <Input label="Device" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                            {selectedSubsystemType.moduleid == 14 && <Input label="Device" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                            {selectedSubsystemType.moduleid == 4 && <Input label="Device" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                            {selectedSubsystemType.moduleid == 11 && <Input label="Site" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                        </div>
                        <div className="col-span-16 md:col-span-2">
                            {selectedSubsystemType.moduleid == 5 && selectedpoint.getIn(['pointtype']) != 13 && <Input label="Outstation" value={selectedpoint.getIn(['outstation'])} onChange={this.valueChanged.bind(this, ['outstation'])} />}
                            {selectedSubsystemType.moduleid == 11 && <Input label="UCC4/UCC32" value={selectedpoint.getIn(['outstation'])} onChange={this.valueChanged.bind(this, ['outstation'])} />}
                        </div>
                        <div className="col-span-16 md:col-span-2 min-w-full flex items-center mt-4">
                            <MagnifyingGlassIcon className="w-6 cursor-pointer" title="Search Points" onClick={this.searchPoints.bind(this)} />
                        </div>
                    </div>
                    <Grid>
                        <Grid.Header key={gridFields.map((field) => field.label).join('-')}>
                            {gridFields.map((field, index) => {
                                return <Grid.Column key={index} classes={`${field.width}`}>{field.label}</Grid.Column>
                            })}
                            <Grid.Column name="selection" classes={`${selectionWidth}`}></Grid.Column>
                        </Grid.Header>
                        <Grid.Data className="max-h-96 h-96 overflow-y-scroll overflow-x-hidden">
                            {(points || []).map((point, index) => {
                                return (
                                    <Grid.Row key={index}>
                                        {gridFields.map((field, _index) => {
                                            return (field.byte ?
                                                <Grid.Column key={_index}>{point.getIn([field.value, field.byte])}</Grid.Column> :
                                                <Grid.Column key={_index}>{point.get(field.value)}</Grid.Column>
                                            )
                                        })}
                                        <Grid.Column>
                                            <Grid.Controls>
                                                <Grid.Control><ArrowRightCircleIcon className="w-6 cursor-pointer" title="Select Point" onClick={this.onSelect.bind(this, point)} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>
                                        
                                    </Grid.Row>
                                )
                            })}
                        </Grid.Data>
                    </Grid>
                </DialogBody>
                <DialogFooter>
                    <Button role="close" onClick={onClose}>Close</Button>
                </DialogFooter>
            </DialogContent>
        )
    }
}

// export default connect((state) => ({
//     customer: state.get('customer'),
//     site: state.get('site'),
//     pointselection: state.get('pointselection')
// }))(PointSelectionDialog);

export default connect((state) => ({
    // customer: state.get('customer'),
    // site: state.get('site'),
    pointselection: state.get('pointselection')
}))((props) => {

    // let { customer } = useBMS();
    // let { site } = useBMS();

    const { customer } = useAreaCustomer();
    const { site } = useAreaSite();

    return <PointSelectionDialog {...props} customer={customer} site={site} />



});

