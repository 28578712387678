import React from 'react';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';
import {connect} from 'react-redux';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';
import ModuleTypes from 'Configuration/ModuleTypes';
import { searchPoints } from 'BmsView/Customer/Site/Actions';
import Immutable from 'immutable';
import ServiceBus from 'Services/ServiceBus';
import PointStatusDialog from 'BmsView/Customer/Site/Area/PointStatusDialog';
import { setPointselection } from '../Actions';
import { withBMS } from 'BmsView/Context/BMS';


export const POLLING_TIME = 30000;

class PointListStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedpoint: props.pointselection && props.pointselection.getIn(['filter']) || Immutable.Map({}),
            points: props.pointselection && props.pointselection.getIn(['points']) || undefined,
            pointstates: {},
            point: null
        }
    }

    componentDidMount() {
        this.unbindPointStatus = ServiceBus.on('300-11', this.processPointStatus.bind(this), true);
        this.polling = setInterval(this.getPointsStatusses.bind(this), POLLING_TIME);
    }

    componentWillUnmount() {
        this.unbindPointStatus();
        clearInterval(this.polling);
    }

    processPointStatus(message) {
        let {pointstates} = this.state;

        let m_pointStatusData = message;

        let address = m_pointStatusData.m_pointAddr.join('-');

        pointstates[address] = m_pointStatusData.m_formattedValueText;

        this.setState({
            pointstates : pointstates
        });
    }    

    // PointSelection
    valueChanged(field, value) {
        this.setState(({ selectedpoint }) => ({
            selectedpoint: selectedpoint.setIn(field, value)
        }))
    }

    async searchPoints() {
        let { customer } = this.props;
        let { selectedpoint } = this.state;
        let query = {};

        let selectedSubsystem = customer.get('subsystems', Immutable.List([])).get(selectedpoint.get('subsystem', 0));
        query['addr.0'] = parseInt(selectedSubsystem.get('number'));

        if (selectedpoint.get('lan') && selectedpoint.get('lan').length > 0) {
            query['addr.3'] = parseInt(selectedpoint.get('lan'))
        }
        if (selectedpoint.get('outstation') && selectedpoint.get('outstation').length > 0) {
            query['addr.4'] = parseInt(selectedpoint.get('outstation'))
        }
        query['addr.2'] = parseInt(selectedpoint.get('pointtype', 0));

        let points = await searchPoints(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase().replace(/ /g, ""), query);

        this.setState({
            pointstates: {},
            points: Immutable.fromJS(points)}, this.getPointsStatusses
        );
    }

    onSelect(point) {
        // console.log('point ', point)
        this.setState({
            point:point
        })
    }

    clearSelection() {
        this.setState({
            point:null
        })         
    }

    pointUpdated() {
      
    }

    getPointsStatusses() {
        let {customer} = this.props;
        let {points} = this.state;

        if (!points) return;

        points.map((point) => {
            let request = {
                trackMessage: true,
                m_level: 10,
                m_communicNum: 300,
                target: 'signalr',
                type: "GENERIC",
                m_subsystemNumber: point.get('addr').first(),
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
            }
            let comm = {
                m_pointAddr: point.get('addr').toJS()
            }
            ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
        }) 
    }

    render() {
        let { customer } = this.props;
        let { selectedpoint, points, pointstates, point } = this.state;

        let selectedSubsystem = customer.get('subsystems', Immutable.List([])).get(selectedpoint.get('subsystem', 0));
        let selectedSubsystemType = ModuleTypes[selectedSubsystem.get('type')];
        let pointtype = selectedSubsystemType.pointtypes[selectedpoint.get('pointtype', 0)];

        let gridFields = [{ label: 'Name', value: 'name', name: 'Point', width: 'col-span-16 md:col-span-4' }];

        let site = "";
        let currentuser = "";

        if (selectedSubsystemType.moduleid == 9) {
           gridFields = [{ label: 'Name', value: 'name', name: 'Point', width: 'col-span-16 md:col-span-3' },
            { label: 'Descriptor', value: 'description', name: 'Descriptor', width: 'col-span-16 md:col-span-3' }];
        }

        if (pointtype) {
            (pointtype.fields || []).forEach((field, index) => {
                gridFields.push({
                    label: field.label,
                    value: 'addr',
                    byte: index + 3,
                    name: field.label.toLowerCase(),
                    width: 'col-span-16 md:col-span-2'
                });
            });
        }

        

        return (
            <div>
                <PointStatusDialog 
                    currentuser={currentuser} 
                    customer={customer} 
                    site={site} 
                    selecteditem={point && point.get('addr').join('-')} 
                    onClose={this.clearSelection.bind(this)} 
                    updatePoint={this.pointUpdated.bind(this)} />

            <div className="container my-4 mx-auto">
                <h2>Point Status List</h2>
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16 md:col-span-5">
                        <Select
                            label="Subsystem"
                            value={selectedpoint.getIn(['subsystem'], 0)}
                            onChange={this.valueChanged.bind(this, ['subsystem'])}
                            options={customer.get('subsystems', []).map((subsystem, index) => {
                                return {
                                    value: index,
                                    text: subsystem.get('name')
                                }
                            })}
                        />
                    </div>
                    <div className="col-span-16 md:col-span-5">
                        <Select
                            label="Point Type"
                            value={selectedpoint.getIn(['pointtype'], 0)}
                            onChange={this.valueChanged.bind(this, ['pointtype'])}
                            options={selectedSubsystemType.pointtypes.map((pointtype, index) => {
                                return {
                                    value: index,
                                    text: pointtype.name
                                }
                            })}
                        />
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        {selectedSubsystemType.moduleid == 5 && <Input label="Lan" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                        {selectedSubsystemType.moduleid == 6 && <Input label="Controller" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                        {selectedSubsystemType.moduleid == 9 && <Input label="Device" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                        {selectedSubsystemType.moduleid == 14 && <Input label="Device" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                        {selectedSubsystemType.moduleid == 4 && <Input label="Device" value={selectedpoint.getIn(['lan'])} onChange={this.valueChanged.bind(this, ['lan'])} />}
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        {selectedSubsystemType.moduleid == 5 && <Input label="Outstation" value={selectedpoint.getIn(['outstation'])} onChange={this.valueChanged.bind(this, ['outstation'])} />}
                    </div>
                    <div className="col-span-16 md:col-span-2 min-w-full flex items-center mt-4">
                        <ArrowRightCircleIcon className="w-8 cursor-pointer" title="Search Points" onClick={this.searchPoints.bind(this)} />
                    </div>
                </div>
                <Grid>
                    <Grid.Header key={gridFields.map((field) => field.label).join('-')}>
                        {gridFields.map((field, index) => {
                            return <Grid.Column key={index} classes={`${field.width}`}>{field.label}</Grid.Column>
                        })}
                        <Grid.Column classes="col-span-16 md:col-span-2">Status</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-2">Alarm</Grid.Column>
                        <Grid.Column name="selection" classes="col-span-16 md:col-span-2"></Grid.Column>
                    </Grid.Header>
                    <Grid.Data className="max-h-[70vh] overflow-x-hidden">
                        {(points || []).map((point, index) => {
                            let address = point.get('addr').join('-');
                            return (
                                <Grid.Row key={index}>
                                    {gridFields.map((field, _index) => {
                                        return (field.byte ?
                                            <Grid.Column key={_index}>{point.getIn([field.value, field.byte])}</Grid.Column> :
                                            <Grid.Column key={_index}>{point.get(field.value)}</Grid.Column>                                                       
                                        )
                                    })}
                                    <Grid.Column>{pointstates[address]}</Grid.Column>
                                    <Grid.Column/>
                                    <Grid.Column><Button role="success" className="w-100" onClick={this.onSelect.bind(this, point)}>Select</Button></Grid.Column>
                                </Grid.Row>
                            )
                        })}
                    </Grid.Data>
                </Grid>
            </div>
            </div>
        )
    }
}
// export default connect((state) => ({
//     customer: state.get('customer'),
//     site: state.get('site')
// }))(PointListStatus);


export default withBMS(PointListStatus);