import React from 'react';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import AssetDialog from './AssetDialog';
import { getAssets, saveAsset, deleteAsset } from './Actions';
import Grid from 'Controls/GridV2';
import Select from 'Controls/Form/Select';
import { PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/solid';

class AssetList extends React.Component {
    constructor(props) {
        super(props);

        let { customer } = this.props;

        let sites = customer.get('sites') || Immutable.List();
        sites  = sites.push(Immutable.Map({'name':'All Sites'}));

        this.state = {
            assets: null,
            currentsite: 'All Sites',
            sites: sites
        }

        this.saveAsset = this.saveAsset.bind(this);
        this.removeAsset = this.removeAsset.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
    }

    componentDidMount() {
        let { customer } = this.props;

        getAssets(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                this.setState({
                    assets: Immutable.fromJS(data.map((asset) => {
                        return asset;
                    }))
                })
            });
    }

    selectAsset(asset) {
        this.setState({
            selectedasset: asset
        });
    }

    saveAsset(asset) {
        let { customer } = this.props;

        saveAsset(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, asset).then(() => {
                this.clearSelection();

                getAssets(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                        this.setState({
                            assets: Immutable.fromJS(data.map((asset) => {
                                return asset;
                            }))
                        })
                    });

                Swal.fire("Success", "The asset has been succesfully saved.", "success");
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while saving the asset, please contact the administrator.", "error");
            });
    }

    removeAsset(asset) {
        let { customer } = this.props;

        Swal.fire({
            title: `Are you sure you want to delete the asset ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value) {
                await deleteAsset(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`, asset);
                await getAssets(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
                        this.setState({
                            assets: Immutable.fromJS(data.map((asset) => {
                                return asset;
                            }))
                        })
                    });
                Swal.fire(
                    'Deleted!',
                    'The asset has been deleted',
                    'success'
                );
            }
        })
    }

    siteChanged(value) {
        this.setState({
            currentsite: value
        });
    }

    clearSelection() {
        this.setState({
            selectedasset: null
        });
    }

    render() {
        let { customer } = this.props;
        let { selectedasset, sites, currentsite } = this.state || {};
        let { assets } = this.state;

        let gridFields = [
            { value: 'location', name: 'Location', width: 'col-span-16 md:col-span-3' },
            { value: 'component', name: 'Asset', width: 'col-span-16 md:col-span-4' },
            { value: 'manufacturer', name: 'Manufacturer', width: 'col-span-16 md:col-span-4' }
        ];

        return (
            <div className='container mx-auto my-4'>
                <AssetDialog selecteditem={selectedasset} onClose={this.clearSelection} customer={customer} onSave={this.saveAsset} />
                <h2>Assets Setup</h2>
                <div className='grid grid-cols-16 my-4'>
                    <div className="col-span-16 md:col-span-4">
                        <Select
                            value={currentsite}
                            onChange={this.siteChanged.bind(this)}
                            options={sites.map((site) => {
                                return ({
                                    text: site.get('name'),
                                    value: site.get('name')
                                });
                            })
                        }></Select>
                    </div> 
                </div>
                <Grid className="container mx-auto">
                    <Grid.Header>
                        {currentsite == 'All Sites' && <Grid.Column classes={'col-span-16 md:col-span-3'}>Site</Grid.Column>}
                        {gridFields.map((field) => {
                            return (
                                <Grid.Column key={field.name} classes={`${field.width}`}>{field.name}</Grid.Column>
                            );
                        })}
                        {currentsite != 'All Sites' && <Grid.Column  key={"model"} classes={'col-span-16 md:col-span-3'}>Model</Grid.Column>}
                        <Grid.Column classes={'col-span-16 md:col-span-2'} name="add" type="complex">
                            <Grid.Controls>
                                <Grid.Control>
                                    <PlusIcon title="Add new asset" className="w-6 text-white cursor-pointer" onClick={this.selectAsset.bind(this, Immutable.Map({}))} />
                                </Grid.Control>
                            </Grid.Controls>
                        </Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        {(assets || []).filter((asset) => {
                            return (currentsite == 'All Sites' || asset.get('site') == currentsite)
                        }).map((asset, index) => {
                            return (
                                <Grid.Row key={index} className="hover:bg-gray-300 group">
                                    {currentsite == 'All Sites' && <Grid.Column classes={'col-span-16 md:col-md-3'}>{asset.get('site')}</Grid.Column>}
                                    <Grid.Column>
                                        {asset.get('location')}
                                    </Grid.Column>
                                    <Grid.Column>
                                        {asset.get('component')}
                                    </Grid.Column>
                                    <Grid.Column >
                                        {asset.get('manufacturer')}
                                    </Grid.Column>
                                    {currentsite != 'All Sites' && <Grid.Column classes={'col-span-16 md:col-md-3'}>{asset.get('model')}</Grid.Column>}
                                    <Grid.Column  classes={'col-span-16 md:col-span-3'}>
                                        <Grid.Controls>
                                            <Grid.Control>
                                                <PencilSquareIcon title="Edit asset" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue w-6" onClick={this.selectAsset.bind(this, asset)} />
                                            </Grid.Control>
                                            <Grid.Control>
                                                <TrashIcon title="Delete asset" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue w-6" onClick={this.removeAsset.bind(this, asset)} />
                                            </Grid.Control>
                                        </Grid.Controls>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        })}
                    </Grid.Data>
                </Grid>
            </div>
        );
    }
}

export default AssetList;




