import React from 'react';
import Input from 'Controls/Form/Input';
import Immutable from 'immutable';
import Ex500TimeProgramListDialog from 'BmsView/Customer/Configuration/Subsystems/Ex500/TimeProgramListDialog';
import GroupDetails from 'BmsView/Customer/Configuration/PointEditor/GroupDetails';
import {getGroups} from 'BmsView/Customer/Configuration/Actions';
import Button from 'Controls/Button/UserDefined';

class SystemObject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            component:undefined,
            pointgroups: undefined
        }
    }

    componentDidMount() {
        let {customer, selectedcomponent} = this.props;

        if ( selectedcomponent.getIn(['component', 'subtype']) == "schedulepointgroup" ) {
            getGroups(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, 'pointgroups').then((data) => {
                let groups = Immutable.fromJS(data.map((group) => {return group;}));
                this.setState({
                    pointgroups:  groups
                })
            });
        }
    }    

    clearSelection() {
        this.setState({
            component:false
        });
    }

    saveGroups(groups) {
        let currentgroups = Immutable.List([]);

        // console.log('groups ', groups);

        (groups || []).map((group) => {
            let groupinfo = Immutable.Map({
                'groupid': group.get('_id'),
                'type': group.get('type') });
            currentgroups = currentgroups.push(groupinfo);
        });

        // console.log('currentgroups ', currentgroups);

    /*    this.setState( ({pointconfiguration}) => ({
            pointconfiguration: pointconfiguration.set('pointgroups', currentgroups), 
            groups:undefined
        })); */
    }

    removeGroup(selectedgroup) {     
        // console.log('remove groups ', selectedgroup);
        let currentgroups = this.state.pointconfiguration.get('pointgroups');

        let newgroups = (currentgroups || []).filter((group) => {
            return group.get('id') != selectedgroup.get('_id');
        });

        this.setState( ({pointconfiguration}) => ({
            pointconfiguration: pointconfiguration.set('pointgroups', newgroups), 
            groups:undefined
        }));
    }
    

    saveEx500Program(selectedsubsystem, selectedcontroller, selectedprogram, selection) {
        this.setState({
            component:false,
            selectedobject:selection
        });

        this.valueChanged(['configuration', 'objectName'], selection);
        this.valueChanged(['configuration', 'subsystemNumber'], parseInt(selectedsubsystem));
        this.valueChanged(['configuration', 'controllerNumber'], parseInt(selectedcontroller));
        this.valueChanged(['configuration', 'timeProgramIndex'], parseInt(selectedprogram));
    }

    openDialog() {
        this.setState({
            component:true
        });
    }

    valueChanged(field, float, value) {
        let { onChange } = this.props;

        if (float) value = parseFloat(value);

        onChange(field, value);
    }

    render() {
        let { customer, selectedcomponent, selectedobject } = this.props;
        let { component, pointgroups } = this.state;

        // console.log('component ', selectedcomponent, selectedobject);

        let selectedgroups = Immutable.List([]);

        return (        
            <div className="my-4">
                {selectedcomponent.getIn(['component', 'subtype']) == "ex500timeprogram" &&
                <div>
                    <Ex500TimeProgramListDialog selecteditem={component} onClose={this.clearSelection.bind(this)} onSave={this.saveEx500Program.bind(this)}/>
                    <div className="grid grid-cols-16">  
                        <div className="col-span-12 md:col-span-10">
                            <Input label="Selected Object" disabled={true} value={selectedobject}/>
                        </div>          
                    </div> 
                    <Button role="success" onClick={this.openDialog.bind(this)}>Select Program</Button>
                </div>} 
                {selectedcomponent.getIn(['component', 'subtype']) == "controllerpointlist" &&
                <div>
                    <div className="grid grid-cols-16">  
                        <div className="col-span-12 md:col-span-10">
                            <Input label="Lan" value={selectedcomponent.getIn(['configuration', 'networkNumber'])} onChange={this.valueChanged.bind(this, ['configuration', 'networkNumber'], true)}/>
                            <Input label="Outstation" value={selectedcomponent.getIn(['configuration', 'controllerNumber'])} onChange={this.valueChanged.bind(this,['configuration', 'controllerNumber'], true)}/>
                        </div>          
                    </div> 
                </div>} 
                {selectedcomponent.getIn(['component', 'subtype']) == "schedulepointgroup" &&
                <div>
                    <div className="grid grid-cols-16">  
                        <div className="col-span-12 md:col-span-10">
                            <GroupDetails
                                customer={customer}
                                pointgroups={pointgroups}
                                saveGroups={this.saveGroups.bind(this)}
                                removeGroup={this.removeGroup.bind(this)}
                                selectedgroups={selectedgroups} />
                        </div>          
                    </div> 
                    <Button role="success" onClick={this.openDialog.bind(this)}>Select Group</Button>
                </div>}   
            </div>
        )
    }
}

export default SystemObject;
