import React, {useState, useEffect} from 'react';
import ServiceBus from 'Services/ServiceBus';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function MessageListDialog({selectedItem, onClose, subsystem, type, ...props}) {
    return <Dialog opened={!!selectedItem} onClose={onClose}>
        <MessageListDialogPanel selecteditem={selectedItem} subsystem={subsystem} type={type} onClose={onClose} {...props} />
    </Dialog>
}

function MessageListDialogPanel({selecteditem, subsystem, type, onClose, customer}) {
    const headers = ["", "", "Services Supported", "Objects Supported"];
    
    const [properties, setProperties] = useState([]);

    useEffect( ()=> {
        let unsubscribeGetPropertiesResp = ServiceBus.on('22-151', processGetPropertiesResp);
        getProperties();;

        return() => {
            unsubscribeGetPropertiesResp();
        } 
    },[]);

    const getProperties = () => {
        let device = selecteditem.get('instancenumber');

        let request = {
            trackMessage: true,
            m_level: 150,
            m_communicNum: 22,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: subsystem,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_deviceInstance: device,
            m_informationType: type
        }
    
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const processGetPropertiesResp = (msg) => {
        setProperties(msg.m_text); 
    }

    return(
        <DialogContent>
            <DialogHeader>
                <DialogTitle>{headers[type]}</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div>
                    {(properties || []).map((p) => {
                        return <pre>{p}</pre>
                    })}
                </div>
            </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={onClose}>Close</Button></div>
            </DialogFooter>
        </DialogContent>          
    )
}
export default MessageListDialog;