import React, {useState} from 'react';
import Immutable from 'immutable';
import { TrashIcon, Cog6ToothIcon, SignalIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import DeviceDialog from './DeviceDialog';
import Grid from 'Controls/GridV2';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ControllerList = ({customer, selectedsubsystem, selectedcontroller, controllers, scan, setCurrentController, saveItem, deleteItem}) => {
    const [selecteditem, setSelectedItem] = useState(undefined);

    const addItem = () => {
      setSelectedItem(Immutable.Map({ 'subsystem': selectedsubsystem }));
    }

    const saveDevice = (device) => {
      saveItem(device);
    }

    const buttonClick = (task) => {
      task();
    }

    const editSettings = (selectedcontroller) => {
      setSelectedItem(selectedcontroller);
    }

    const clearDialog = () => {
      setSelectedItem(undefined);
    } 

    return (
      <div>    
        { selecteditem && 
        <DeviceDialog customer={customer} selectedItem={selecteditem} selectedSubsystem={selectedsubsystem} saveDevice={(item) => saveDevice(item)} onClose={() => clearDialog()}></DeviceDialog> }
        <div>
          <div className="border-b border-gray-200 bg-white px-2">
            <div className=" flex justify-between sm:flex-nowrap pb-2">
              <div>
                <h1 className="text-lg leading-6 font-medium text-gray-900">Devices</h1>
              </div>
              <div className="flex flex-shrink-0 text-right">
                <a className="pl-2 cursor-pointer text-blue" onClick={() => scan('scanforcontrollers')}>Start Network Discovery</a>
              </div>
              <div className="pl-4">
								<PlusCircleIcon className="h-6 w-6 text-gray-700 cursor-pointer" onClick={() => addItem()} />
							</div>
            </div>
          </div>
        </div>
        <div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
				<table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
					<thead className="bg-blue-500">
						<tr>
							<th
								scope="col"
								className="sticky w-3/12 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 px-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
							>
								Name
							</th>
							<th
								scope="col"
								className="sticky w-2/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
							>
								Instance
							</th>
              <th
								scope="col"
								className="sticky w-2/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
							>
								IP Address
							</th>
              <th
								scope="col"
								className="sticky w-3/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
							>
								Location
							</th>
              <th className="sticky w-4/12 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-2 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
              </th>
						</tr>
					</thead>
					<tbody className="bg-white">
            {(controllers || []).sort((a,b) => {  if (a < b) {return 1;} if (a > b) {return -1;} return 0;}).map((controller, index) => (
                  <tr key={controller.get('_id')} onClick={() => setCurrentController(controller)} className={selectedcontroller && (controller.get('_id') == selectedcontroller.get('_id')) ? "relative px-1 bg-green-100 group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500" :
									    "relative hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset group focus-within:ring-indigo-500"}>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 '
                      )}
                    >
                      {controller.get('devicename')}
                    </td>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 '
                      )}
                    >
                      {controller.get('instancenumber')}
                    </td>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 '
                      )}
                    >
                      {controller.get('ipaddress')}
                    </td>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 '
                      )}
                    >
                      {controller.get('location')}
                    </td>
                    <td className={classNames(
                        'border-b border-gray-200',
                        'whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900'
                      )}>
                      <Grid.Controls>
                        <Grid.Control>
                          <SignalIcon title="Scan for points" className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {
                            setCurrentController(controller);
                            scan('scanforpoints')
                          }}/>
                        </Grid.Control>
                        <Grid.Control>
                          <Cog6ToothIcon className="cursor-pointer hidden text-gray-500 group-hover:block w-5" onClick={() => {
                            setCurrentController(controller);
                            editSettings(controller);
                          }} />
                        </Grid.Control>
                        <Grid.Control>
                          <TrashIcon className="cursor-pointer  hidden w-5 text-gray-500 group-hover:block " onClick={() => {
                            setCurrentController(controller);
                            deleteItem('bacnetcontrollers')();
                          }} />
                        </Grid.Control> 
                      </Grid.Controls>
                    </td>
                  </tr>
							))}
					</tbody>
				</table>
			</div>
		</div>);
}

export default ControllerList;
