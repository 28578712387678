import React, {useState, useEffect} from 'react';
import Immutable, { List } from 'immutable';
import Grid from 'Controls/GridV2';
import ServiceBus from 'Services/ServiceBus';
import TimezoneExceptionDialog from './TimezoneExceptionDialog';
import swal from 'sweetalert2';
import { PlusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid';

const TimezoneExceptions = ({customer, site, address, currentuser}) => {
    const useValues = ['Next', 'Every', 'Free'];

    let [exceptionList, setExceptionList] = useState(List());
    let [selectedItem, setSelectedItem] = useState();
    let [addButtonDisable, setAddButtonDisable] = useState(false);

    useEffect(() => {
        // console.log('use effect');
        
        let unsubscribeGetExceptionResponse = ServiceBus.on('513-51', processGetExceptionsResponse);
        let unsubscribeSetExceptionResponse = ServiceBus.on('509-51', processSetExceptionsResponse);
        
        getExceptionList();

        return() => {
            unsubscribeGetExceptionResponse();
            unsubscribeSetExceptionResponse();
            // ServiceBus.off('513-51', processGetExceptionsResponse);
            // ServiceBus.off('509-51', processSetExceptionsResponse);
        }
    }, []);

    const getExceptionList = () => {
        let addressAsInt = address.map((byte) => parseInt(byte));

        let request = {
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            trackMessage: true,
            m_level: 50,
            m_communicNum: 513,
            m_subsystemNumber:addressAsInt[0],
            target: 'signalr'
        };

        let comm = {
            m_lanIndex:addressAsInt[3],
            m_outstationNumber:addressAsInt[4],
            m_zoneNumber:addressAsInt[5]
        };
    
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
        // console.log('send for exceptions');
    }

    const addYear = (dt) =>{
        var d = dt.getDate();

        dt.setMonth(dt.getMonth() + 12);
        if (dt.getDate() != d) {
          dt.setDate(0);
        }

        return dt;
    }

    const processGetExceptionsResponse = (msg) => {
        // console.log('receive exceptions', msg);
        let parsedMessage = msg; //JSON.parse(msg);


        if (parsedMessage.m_errorNumber != 0) {
            swal.fire("Error", `Error number ${parsedMessage.m_errorNumber}`, "error");
        }

        let exceptions = Immutable.fromJS(parsedMessage.m_exceptions);

        let exceptionList = (exceptions || []).map((exception) => {
            var dateStart = new Date();
            var dateEnd = new Date();

            let sDay = exception.get('sDay');
            let sMon = exception.get('sMon') - 1;
            let eDay = exception.get('eDay');
            let eMon = exception.get('eMon') - 1;

            if ( exception.get('sMon') < dateStart.getMonth() ) {
                dateStart = addYear(dateStart);
                dateEnd = addYear(dateEnd);
            }

            if ( exception.get('sMon') == dateStart.getMonth() && exception.get('sDay') < dateStart.getDate() ) {
                dateStart = addYear(dateStart);
                dateEnd = addYear(dateEnd);
            }

            dateStart.setMonth(sMon);
            dateStart.setDate(sDay);
            dateEnd.setMonth(eMon);
            dateEnd.setDate(eDay);
            return exception.set('dateStart', dateStart).set('dateEnd', dateEnd);
        });

        setExceptionList(exceptionList);

        setAddButtonDisable(false);
    }

    const processSetExceptionsResponse = (msg) => {
    //    let parsedMessage = JSON.parse(msg);
        if (msg.m_errorNumber != 0) {
            swal.fire("Error", `Error number ${msg.m_errorNumber}`, "error");
        }
        else {
            swal.fire("Success", "The exceptions have been updated at the panel", "success");
            getExceptionList();
            setSelectedItem(undefined);
        }
    }

    const getNextNumber = () => {
        let numbers = (exceptionList || []).map((exception) => {
            return exception.get('num');
        });

        for ( var i = 1; i < 50; i++ ) {
            for ( var j = 0; j< numbers.size; j++ )
            {
                if (numbers.get(j) == i) break;
            }
            if (numbers.get(j) != i) return i;
        }  
        
        return -1;
    }    

    const selectException = (exception) => {
        setSelectedItem(exception);
    }

    const getPin = (subsystemNumber) => {
        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
 
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }


    const saveException = (exception, deleteException) => {
        let newException = Immutable.Map(
            {'num': exception.get('num'),
             'desc':exception.get('desc'),
             'use': exception.get('use'),
             'prty': exception.get('prty'),
             'sMon': exception.get('dateStart').getMonth() + 1,
             'sDay': exception.get('dateStart').getDate(),
             'eMon': exception.get('dateEnd').getMonth() + 1,
             'eDay': exception.get('dateEnd').getDate(),
             'm_prds': exception.get('m_prds')});

        let addressAsInt = address.map((byte) => parseInt(byte));
        let subsystemNumber = addressAsInt[0];

        let request = {
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            trackMessage: true,
            m_level: 50,
            m_communicNum: 509,
            m_subsystemNumber:subsystemNumber,
            target: 'signalr'
        };

        let comm = {
            m_password: getPin(subsystemNumber),
            m_lanIndex:addressAsInt[3],
            m_outstationNumber:addressAsInt[4],
            m_zoneNumber:addressAsInt[5],
            m_deleteException:deleteException,
            m_exceptionNumber:exception.get('num'),
            m_iq3Exception:newException,
            m_whoCommanded:`${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`
        };
   
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }
     
    const onClose = () => {
        setSelectedItem(undefined);
    }

    const formatDateTime = (dt, format) => {
        const yyyy = dt.getFullYear();
        let mm = dt.getMonth() + 1; // Months start at 0
        let dd = dt.getDate();
    
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
    
        let h = dt.getHours();
        let m = dt.getMinutes();
    
        if (h < 10) h = '0' + h;
        if (m < 10) m = '0' + m;
    
        var formattedToday = '';
       
        if ( format == 'DD/MM/YYYY HH:mm') {
            formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m;
        }
    
        if ( format == 'DD/MM/YYYY H:mm a') {
            if ( h >= 13 ){
                h = h - 12;
                formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ' pm';
            } else {
                formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ' am';
            }
        }
    
        if ( format == 'DD MMM') {
            let month = dt.toLocaleString('default', { month: 'short' });
            formattedToday = dd + ' ' + month;
        }
    
        return formattedToday;
    }

    return(
        <div className="mb-2">
            {selectedItem && <TimezoneExceptionDialog customer={customer} selectedItem={selectedItem} saveException={(exception, del) => saveException(exception, del)} onClose={() => onClose()}/>}
            <Grid>
                <Grid.Header>
                    <Grid.Column classes='col-span-16 md:col-span-5'>Identifier</Grid.Column>
                    <Grid.Column classes='col-span-16 md:col-span-2'>Start</Grid.Column>
                    <Grid.Column classes='col-span-16 md:col-span-2'>End</Grid.Column>
                    <Grid.Column classes='col-span-16 md:col-span-2'>Use</Grid.Column>
                    <Grid.Column classes='col-span-16 md:col-span-2'>Priority</Grid.Column>
                    <Grid.Column classes='col-span-16 md:col-span-3' name="add" type="complex">
                        {(() => {
                            let onClick = () => {};
                            if(!addButtonDisable) {
                                onClick = () => selectException(Immutable.Map({'num': getNextNumber(), 'desc':"New exception", 'use':0,'prty':1, 'dateStart': new Date(), 'dateEnd': new Date()}));
                            }
                            return <Grid.Controls>
                                <Grid.Control>
                                    <PlusIcon className="w-6 text-white cursor-pointer" onClick={onClick} />
                                </Grid.Control>
                            </Grid.Controls>
                        })()}
                    </Grid.Column>
                </Grid.Header>
                <Grid.Data>
                    {(exceptionList || []).map((exception) => {
                        return (
                            <Grid.Row>
                                <Grid.Column classes='col-span-16 md:col-span-5'>{exception.get('desc')}</Grid.Column>
                                <Grid.Column classes='col-span-16 md:col-span-2'>
                                    {formatDateTime(new Date(exception.get('dateStart')), 'DD MMM')}
                                </Grid.Column>
                                <Grid.Column classes='col-span-16 md:col-span-2'>
                                    {formatDateTime(new Date(exception.get('dateEnd')), 'DD MMM')}
                                </Grid.Column>
                                <Grid.Column classes='col-span-16 md:col-span-2'>
                                    {useValues[exception.get('use')]}
                                </Grid.Column>
                                <Grid.Column classes='col-span-16 md:col-span-2'>
                                    {exception.get('prty')}
                                </Grid.Column>
                                <Grid.Column classes='col-span-16 md:col-span-3'>
                                    <Grid.Controls>
                                        <Grid.Control>
                                            <PencilSquareIcon 
                                                title="edit" 
                                                className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-blue-500 w-6"  
                                                onClick={() => selectException(exception)} />
                                        </Grid.Control>
                                        <Grid.Control>
                                            <TrashIcon 
                                                title="remove" 
                                                className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 " 
                                                onClick={() => saveException(exception, 1)} />
                                        </Grid.Control>
                                    </Grid.Controls>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })}
                    
                </Grid.Data>
            </Grid>           
        </div>
    );
}

export default TimezoneExceptions;
