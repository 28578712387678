import React from 'react';
import Area from './';
import AreaV2 from './indexV2';
import AreaContext from '../../../../Context/Area';
import AreaPointProvider from '../../../../Context/AreaPoint';
import TransitioningDialog from '../../../../Controls/Dialog/TransitioningDialog';
import Dialog, { DialogBody, DialogFooter, DialogContent } from 'Controls/Dialog/Dialog';

// could be used to show another area in a split
export function AreaDialogV2Panel(props) {

    const { site, customer, selecteditem, customerMainDirectory } = props;
    // console.log('AreaDialogV2Panel props', props);

    let link = selecteditem;
    let nodepath = (link || "").replace(/.*?\/customer\/.*?\/site\/.*?\/(.*)/,'$1');

    let lvl1 = nodepath && nodepath.split('/')[0] || undefined;
    let lvl2 = nodepath && nodepath.split('/')[1] || undefined;
    let lvl3 = nodepath && nodepath.split('/')[2] || undefined;
    let lvl4 = nodepath && nodepath.split('/')[3] || undefined;
    let lvl5 = nodepath && nodepath.split('/')[4] || undefined;
    let lvl6 = nodepath && nodepath.split('/')[5] || undefined;

    let indexOfCurrent = -1;

    let lvl1node = site.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl1).first();
    if (lvl1node){
        indexOfCurrent = site.get('treenodes').indexOf(lvl1node);
    }
    if (!lvl1node && site.get('treenodes')) {
        if (site.get('treenodes').size > 0) {
            lvl1node = site.get('treenodes').first();
            lvl1 = lvl1node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl1node.get('name').split(' ').join('-').toLowerCase();
        }
    }
    let lvl2node = lvl1node && lvl1node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl2).first();
    if (lvl2node){
        indexOfCurrent = lvl1node.get('treenodes').indexOf(lvl2node);
    }
    if (lvl1node && !lvl2node && !lvl1node.get('backdrop') && lvl1node.get('treenodes')) {
        if (lvl1node.get('treenodes').size > 0) {
            lvl2node = lvl1node.get('treenodes').first();
            lvl2 = lvl2node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl2node.get('name').split(' ').join('-').toLowerCase();
        }
    }
    let lvl3node = lvl2node && lvl2node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl3).first();
    if (lvl3node){
        indexOfCurrent = lvl2node.get('treenodes').indexOf(lvl3node);
    }
    if (lvl2node && !lvl3node && !lvl2node.get('backdrop') && lvl2node.get('treenodes')) {
        if (lvl2node.get('treenodes').size > 0) {
            lvl3node = lvl2node.get('treenodes').first();
            lvl3 = lvl3node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl3node.get('name').split(' ').join('-').toLowerCase();
        }
    }
    let lvl4node = lvl3node && lvl3node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl4).first();
    if (lvl4node){
        indexOfCurrent = lvl3node.get('treenodes').indexOf(lvl4node);
    }
    if (lvl3node && !lvl4node && !lvl3node.get('backdrop') && lvl3node.get('treenodes')) {
        if (lvl3node.get('treenodes').size > 0) {
            lvl4node = lvl3node.get('treenodes').first();
            lvl4 = lvl4node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl4node.get('name').split(' ').join('-').toLowerCase();
        }
    }

    let lvl5node = lvl4node && lvl4node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl5).first();
    if (lvl5node){
        indexOfCurrent = lvl4node.get('treenodes').indexOf(lvl5node);
    }
    if (lvl4node && !lvl5node && !lvl4node.get('backdrop') && lvl4node.get('treenodes')) {
        if (lvl4node.get('treenodes').size > 0) {
            lvl5node = lvl4node.get('treenodes').first();
            lvl5 = lvl5node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl5node.get('name').split(' ').join('-').toLowerCase();
        }
    }

    let lvl6node = lvl5node && lvl5node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl6).first();
    if (lvl6node){
        indexOfCurrent = lvl5node.get('treenodes').indexOf(lvl6node);
    }
    if (lvl5node && !lvl6node && !lvl5node.get('backdrop') && lvl5node.get('treenodes')) {
        if (lvl5node.get('treenodes').size > 0) {
            lvl6node = lvl5node.get('treenodes').first();
            lvl6 = lvl6node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl6node.get('name').split(' ').join('-').toLowerCase();
        }
    }

    let currentNode = lvl6node || lvl5node || lvl4node || lvl3node || lvl2node || lvl1node;

    let baseDirectory = `${customer.get('name').toLowerCase().split(' ').join('-')}_${site.get('name').split(' ').join('-').toLowerCase()}`;
    if (lvl1node && lvl2node) {    
        baseDirectory += "_" + lvl1node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }
    if (lvl2node && lvl3node) {     
        baseDirectory += "_" + lvl2node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }
    if (lvl3node && lvl4node) {
        baseDirectory += "_" + lvl3node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }

    if (lvl4node && lvl5node) {
        baseDirectory += "_" + lvl4node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }

    if (lvl5node && lvl6node) {
        baseDirectory += "_" + lvl5node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }
    if (nodepath.indexOf('/') == 0) {
        nodepath = nodepath.substr(1);
    }

    let AreaVersionComponent = Area;
    if(currentNode.get('isV2')) {
        AreaVersionComponent = AreaV2;
    }

    console.log("currentNode component", currentNode);

    // return <div>Test dialog</div>

    if(currentNode.get('isV2')) {
         return <AreaContext>
            <AreaPointProvider customer={customer.get('name').toLowerCase().replace(/ /g, "")}>
                <AreaVersionComponent 
                    renderedInDialog={true}
                    key={`${site.get('name')}-${nodepath}`} 
                    currentNodeConfiguration={currentNode} 
                    site={site} 
                    nodepath={link} 
                    baseDirectory={baseDirectory} 
                    customerMainDirectory={customerMainDirectory}
                    indexOfCurrentNodeConfiguration={indexOfCurrent}
                />
            </AreaPointProvider>
         </AreaContext>
    }

    return <AreaContext><AreaVersionComponent 
        renderedInDialog={true}
        key={`${site.get('name')}-${nodepath}`} 
        currentNodeConfiguration={currentNode} 
        site={site} 
        nodepath={link} 
        baseDirectory={baseDirectory} 
        customerMainDirectory={customerMainDirectory}
        indexOfCurrentNodeConfiguration={indexOfCurrent}
    /></AreaContext>

}

export default (props) => {
    
    if(!props.selecteditem) {
        return <data></data>
    }

    return <Dialog onClose={props.onClose} opened={!!props.selecteditem} className="max-w-max" >
            <DialogContent className="w-fit max-h-min">
                <DialogBody>
                    <div>
                        <AreaDialogV2Panel {...props} />
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="p-2 flex justify-end">
                        <button className="bg-[#6c757d] p-2 text-[white]" onClick={props.onClose}>Cancel</button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
};
