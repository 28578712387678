import React from 'react';
import Immutable from 'immutable';

class Select extends React.Component {
    constructor() {
        super();
        this.state = { value: "" }
    }
    compponentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value })
        }
    }
    handleChange(e) {
        let { onChange, returnObject } = this.props;
        this.setState({ value: e.target.value })
        onChange(e.target.value, e)

    }
    render() {
        let { className, options, label, placeholder, onChange, ...others } = this.props;

        if (Immutable.Iterable.isIterable(options)) {
            options = options.toJS();
        }
        className += placeholder && !this.state.value ? ' text-gray-400' : '';

        return (
            <div className="">
                {label && <label>{label}</label>}
                <select className={`h-[calc(1.5em + 0.75rem + 2px)] border border-[#ced4da] py-1.5 px-3 rounded ${className}`} value={this.state.value || ''} onChange={this.handleChange.bind(this)} {...others}>
                    { placeholder && <option value="">{placeholder}</option>}
                    {options.map((option, index) => {
                        return <option value={option.value} key={index}>{option.text}</option>
                    })}
                </select>
            </div>
        )
    }
}
export default Select;