import React from 'react';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import {savePoint, getGroups, loadAllEngUnits} from '../Actions';
import {searchPoints} from 'BmsView/Customer/Site/Actions';
import ModuleTypes from 'Configuration/ModuleTypes';
import PointDetails from './PointDetails';
import TrendingDetails from './TrendingDetails';
import GroupDetails from './GroupDetails';
import AlarmDetails from './AlarmDetails';
import PerformanceDetails from './PerformanceDetails';

const editComponents = {
    'pointdetails': PointDetails,
    'trendingdetails': TrendingDetails,
    'groupdetails': GroupDetails,
    'alarmdetails': AlarmDetails,
    'performancedetails': PerformanceDetails
}


function PointEditDialog({ selectedItem, onClose, ...props }) {
    return <Dialog className="md:min-w-[60vw] md:max-w-[80vw]" opened={!!selectedItem} onClose={onClose} >
        <PointEditDialogPanel selectedItem={selectedItem} onClose={onClose} {...props}/>
    </Dialog>
}

class PointEditDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeComponent: 'pointdetails',
            currentpoint: props.selectedItem,
            selectedpoint: undefined,
            pointgroups: undefined,
            multistate: false,
            states: 1
        }
    }

    async componentDidMount() {
        let  address = this.state.currentpoint;
        let {customer, editPoint} = this.props;

        loadAllEngUnits().then((data) => {
            this.setState({
                engunitlist: data
            });
        });

        getGroups(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, 'pointgroups').then((data) => {
            let groups = Immutable.fromJS(data.map((group) => {return group;}));
            // console.log('edit ', groups);
            this.setState({
                pointgroups:  groups
            })
        });
     
        if ( !editPoint ) { 
            let engunittype = ModuleTypes[address[1]].pointtypes[address[2]].engunit;
    
            let engunits = [''];
    
            if (engunittype == 'digital') {
                engunits = ['Off', 'On'];
            }

            if (address[1] == 5 && address[2] == 13) {
                engunits = ['Normal', 'Out Of Deadband', 'Out of Timezone'];
            }

            if (address[1] == 14 && address[2] > 2) {
                this.setState( {
                    editPoint:true,
                    pointconfiguration: Immutable.Map({
                        name: 'New Point',
                        addr: Immutable.List(address),
                        engunits: engunits,
                        modbusregisterformat:'int16',
                        scaling:'1.0',
                        legacyfields: {
                            engunitnum:1,
                            category:1,
                            guierrormessages:0,
                            numberdecplaces:1
                        }
                    })
                }); 
            }

            else {
                this.setState( {
                    editPoint:true,
                    pointconfiguration: Immutable.Map({
                        name: 'New Point',
                        addr: Immutable.List(address),
                        engunits: engunits,
                        legacyfields: {
                            engunitnum:1,
                            category:1,
                            guierrormessages:0,
                            numberdecplaces:1
                        }
                    })
                });
            }
        } else {
            let points = await searchPoints(`${customer.get('name').replace(/ /g, "").toLowerCase()}`, `${customer.get('ipaddress').toLowerCase()}`, {
                addr: address.map((byte) => { return parseInt(byte) })
            });
    
            if (points.length > 0) {
                let point = Immutable.fromJS(points[0]);

                this.setState({
                    pointconfiguration: point,
                    states: point.get('engunits').size,
                    multistate: point.get('engunits').size > 1 ?true:false
                });
            }
        }
    }

    changedInput(field,transform, value) {
        let {pointconfiguration} = this.state;

        if (transform){
            value = transform(value);
        }
      
        if (field[0] == 'historysetup' && field[1] == 'enabled' && value) {
            if (pointconfiguration.getIn(['historysetup', 'interval'])) {
                this.setState({
                    pointconfiguration: pointconfiguration
                        .setIn(field, value)
                        .setIn(['historysetup', 'type'], 1)
                });
            } else {
                this.setState({
                    pointconfiguration: pointconfiguration
                        .setIn(field, value)
                        .setIn(['historysetup', 'type'], 1)
                        .setIn(['historysetup', 'interval'], 15)
                });
            }
        }

        else if (field[0] == 'alarmparameters' && value) {
            this.setState({
                pointconfiguration: pointconfiguration
                    .setIn(['alarmparameters', field[1]], value)
            });
        }
        

        else {
            this.setState({
                pointconfiguration: pointconfiguration.setIn(field, value)
            });
        }

        this.setState ({
            engUnitChange:undefined
        });
    }

    removePerformancePoint(field) {
        let {pointconfiguration} = this.state;
        this.setState(({ pointconfiguration }) => ({
            pointconfiguration: pointconfiguration.deleteIn(field)
        }));
    }


    async savePointConfiguration() {
        let {customer} = this.props;
        let {pointconfiguration} = this.state;
        let addr = pointconfiguration.get('addr').toArray();

        // console.log('saving ', pointconfiguration);

        //check if modbus scaling needs to be converted
        if ( pointconfiguration.get('scaling') && pointconfiguration.get('scaling') != '' ) {
            let scaling = parseFloat(pointconfiguration.get('scaling'));

            if (Number.isNaN(scaling)) {
                Swal.fire("Error", "Error with scaling value", "error");
                return;
            }

            else pointconfiguration.set('scaling', scaling);
        }

        if ( addr[1] == 5 && addr[2] == 13 ){
            if ( !pointconfiguration.getIn(['performanceinfo', 'setpoint']) ) { Swal.fire("Error", "A Setpoint must be selected", "error"); return;}
            if ( !pointconfiguration.getIn(['performanceinfo', 'controlpoint']) ) { Swal.fire("Error", "A Control Point must be selected", "error"); return;}
            if ( !pointconfiguration.getIn(['performanceinfo', 'timezonepoint']) ) { Swal.fire("Error", "A Time Zone must be selected", "error"); return;}
            
            /*
            let setpointaddr = pointconfiguration.getIn(['performanceinfo', 'setpoint']).toArray();

            addr[3] = setpointaddr[3];
            addr[4] = setpointaddr[4];
   
            pointconfiguration = pointconfiguration.set('addr', addr); */
                 
        //    this.setState({
        //        pointconfiguration: pointconfiguration
        //    });
        }

        await savePoint(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, pointconfiguration).then((response) => {
            Swal.fire("Success", "The point has been successfully saved", "success");

            if ( this.props.onSave ) {
                let {onSave} = this.props;
                onSave();
            }
            
        }).catch((err) => {
            Swal.fire("Error", "An error occured saving the point", "error");
        });
    }

    groupSelect() {
        let {pointconfiguration} = this.state;

        this.setState({
            groups: pointconfiguration.get('pointgroups', [])
        });    
    }

    /*
    saveGroups(groups) {
        let currentgroups = this.state.pointconfiguration.get('groups', Immutable.List([]));

        (groups || []).map((group) => {
            currentgroups = currentgroups.push(group);
        });

        console.log('currentgroups ', currentgroups);

        this.setState( ({pointconfiguration}) => ({
            pointconfiguration: pointconfiguration.set('groups', currentgroups), 
            groups:undefined
        })); 
    }*/



    saveGroups(groups) {
        let currentgroups = Immutable.List([]);

        // console.log('groups ', groups);

        (groups || []).map((group) => {
            let groupinfo = Immutable.Map({
                'groupid': group.get('_id'),
                'type': group.get('type') });
            currentgroups = currentgroups.push(groupinfo);
        });

        // console.log('currentgroups ', currentgroups);

        this.setState( ({pointconfiguration}) => ({
            pointconfiguration: pointconfiguration.set('pointgroups', currentgroups), 
            groups:undefined
        })); 
    }

    removeGroup(selectedgroup) {     
        // console.log('remove groups ', selectedgroup);
        let currentgroups = this.state.pointconfiguration.get('pointgroups');

        let newgroups = (currentgroups || []).filter((group) => {
            return group.get('id') != selectedgroup.get('_id');
        });

        this.setState( ({pointconfiguration}) => ({
            pointconfiguration: pointconfiguration.set('pointgroups', newgroups), 
            groups:undefined
        }));
    }

    clearSelection() {
        this.setState({
            groups:undefined
        })
    }

    incrementMultistate() {
        let {pointconfiguration, states} = this.state;

        let engunits = pointconfiguration.get('engunits');
        engunits = engunits.setIn([states], '');

        states++;

        this.setState({
            states: states,
            pointconfiguration: pointconfiguration
                .set('engunits', engunits)
        });
    }

    changeEngUnitType(value) {
        // console.log('changeengunittype', value);
        if (value) {
            let engunits = ['Off', 'On'];

            this.setState( ({pointconfiguration}) => ({
                pointconfiguration: pointconfiguration.set('engunits', engunits)            
                })
                //,
                //engUnitType = 'digital'
                );
        }
        
        else {
            this.setState( ({pointconfiguration}) => ({
                pointconfiguration: pointconfiguration.set('engunits', Immutable.List(["\u00B0C"])),
                multistate: false,
                })
                //,
                //engUnitType = undefined
            );
        }
    }

    changeMultistate(value) {
        this.setState({
            multistate:value
        });

        if (!value) {
            let {pointconfiguration} = this.state;
            let engunits = Immutable.List([pointconfiguration.getIn(['engunits',0])]);

            this.setState({
                states: 1,
                pointconfiguration: pointconfiguration
                    .set('engunits', engunits)
            });
        }
    }

    render() {
        let {pointconfiguration, engunitlist, engUnitChange, multistate, states, activeComponent, pointgroups} = this.state;
        let {editPoint, customer, onClose} = this.props;
        
        if (!pointconfiguration || !engunitlist) {
            return <data>Point Not Defined</data>;
        }
        
        let addr = pointconfiguration.get('addr').toArray();
        let engunittype = ModuleTypes[addr[1]].pointtypes[addr[2]].engunit;

        if ( (addr[1] == 5 && (addr[2] == 5 || addr[2] == 11)) && pointconfiguration.getIn(['engunits', 1])) engunittype = 'digital';

        let selectedgroups = pointconfiguration.get('pointgroups', []);

        let selectedsubsystem = customer.get('subsystems').filter((subsystem) => {
            return subsystem.get('number') == addr[0];
        });  

        let EmptyComponent = () => { return <data></data> };
        let ActiveComponent = editComponents[activeComponent] || EmptyComponent;

        return (
            <DialogContent>
                <DialogBody>
                    <div className="flex flex-row w-full p-2">
                        <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'pointdetails' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'pointdetails' }) }}>Point Editor</a>
                        <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'trendingdetails' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'trendingdetails' }) }}>Trending Details</a>
                        <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'groupdetails' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'groupdetails' }) }}>Group Details</a>
                        <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'alarmdetails' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'alarmdetails' }) }}>Alarm Details</a>
                        {/* <a className={`flex-grow text-md text-center border-b-2 py-3 font-semibold ${activeComponent == 'performancedetails' ? 'border-blue' : 'border-grey-light hover:border-grey'}`} onClick={() => { this.setState({ activeComponent: 'performancedetails' }) }}>Performance Details</a> */}
                    </div>
                    <div className="bg-white w-full h-full md:h-auto mx-auto rounded">
                        <ActiveComponent
                            customer={customer}
                            pointconfiguration={pointconfiguration}
                            selectedsubsystem={selectedsubsystem}
                            changedInput={this.changedInput.bind(this)}
                            changeEngUnitType={this.changeEngUnitType.bind(this)}
                            changeMultistate={this.changeMultistate.bind(this)}
                            editPoint={editPoint}
                            engunitlist={engunitlist}
                            engunitchange={engUnitChange}
                            states={states}
                            incrementMultistate={this.incrementMultistate.bind(this)}
                            multistate={multistate}
                            pointgroups={pointgroups}
                            saveGroups={this.saveGroups.bind(this)}
                            removeGroup={this.removeGroup.bind(this)}
                            selectedgroups={selectedgroups} />
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="primary" onClick={this.savePointConfiguration.bind(this)}>Save</Button></div>
                </DialogFooter>
        </DialogContent>
        )
    }
}

export default PointEditDialog;

//<GroupDetails customer={customer} selectedgroups={selectedgroups} saveGroups={this.saveGroups.bind(this)} removeGroup={this.removeGroup.bind(this)}/>

