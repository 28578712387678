import { useBMS } from "../../../../Context/BMS";
import { useActiveAreaDialog } from "../../../../AreaDialog/Context";

export default function useAreaSite() {

    const { site:mainSite } = useBMS();
    const { site:dialogSite } = useActiveAreaDialog();

    const { isAreaInDialog } = useActiveAreaDialog();

    return { site: isAreaInDialog ? dialogSite : mainSite };



}