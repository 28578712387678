import * as d3 from 'd3';
import { drawText, drawTextBox, drawTspan, displayTooltip } from '../Utils';


export default function TextMultiline(options) {
    return new Promise((resolve, reject) => {

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacementElement(options);
            

        let textNode = drawText(placementElement, options.node, null, options.getCustomerSettings, options.getUserSettings);
        drawTspan(textNode, 0, options.node.getIn(['configuration','style','fontsize'], 16), options.node.getIn(['configuration', 'value'], 'Text Area')); 
        displayTooltip(placementElement, options); 

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                textNode.text(point.m_formattedValueText);
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                textNode.remove();
                placementElement.remove();
                options.node = node;
                placementElement = createPlacementElement(options);
                textNode = drawText(placementElement, options.node, null, options.getCustomerSettings, options.getUserSettings);
                displayTooltip(placementElement, options); 
            //    drawTextBox(placementElement, textNode, options);
                drawTspan(textNode, 0, options.node.getIn(['configuration','style','font-size'], 16), options.node.getIn(['configuration', 'value'], 'Text Area'));  
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            remove: () => {
                placementElement.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}