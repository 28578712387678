import React from 'react';
import CommmandPoint from './Command';
class AdvancedPointStatus extends React.Component {
    render() {
        let {selectedpoint, activeModuleType, address, updatePoint} = this.props;

        return (
            <div className="flex flex-col md:flex-row  mt-3 px-8 py-4">
                <div className="flex-1 flex-grow flex flex-col p-2">
                    <div className="flex text-2xl border-b border-grey py-1 font-semibold">Point details</div>
                    <div className="flex w-full my-4">
                        <table className="w-full border-collapse border-none">
                            <tbody>
                                <tr>
                                    <td className="w-2/5" align="left">Subsystem type:</td>
                                    <td>{activeModuleType.name}</td>
                                </tr>
                                <tr>
                                    <td className="w-2/5" align="left">Point type:</td>
                                    <td>{activeModuleType.pointtypes[address[2] || 0].name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex w-full  my-4">
                        <table className="w-full border-collapse border-none">
                            <tbody>
                                <tr><td className="w-2/5" align="left">Subsystem number:</td><td>{address[0]}</td></tr>
                                {address[1] == 4 &&
                                    <React.Fragment>
                                        <tr><td className="w-2/5" align="left">Device ID:</td><td>{address[3]}</td></tr>
                                        <tr><td className="w-2/5" align="left">Object ID:</td><td>{address[4]}</td></tr>
                                    </React.Fragment>
                                }
                                {address[1] == 5 &&
                                    <React.Fragment>
                                        <tr><td className="w-2/5" align="left">Lan:</td><td>{address[3]}</td></tr>
                                        <tr><td className="w-2/5" align="left">Outstation:</td><td>{address[4]}</td></tr>
                                        <tr><td className="w-2/5" align="left">Point:</td><td>{address[5]}</td></tr>
                                    </React.Fragment>
                                }
                                {address[1] == 6 &&
                                    <React.Fragment>
                                        <tr><td className="w-2/5" align="left">Controller:</td><td>{address[3]}</td></tr>
                                        <tr><td className="w-2/5" align="left">I/O Board:</td><td>{address[4]}</td></tr>
                                        <tr><td className="w-2/5" align="left">Point:</td><td>{address[5]}</td></tr>
                                    </React.Fragment>
                                }
                                {address[1] == 9 &&
                                    <React.Fragment>
                                        <tr><td className="w-2/5" align="left">Device ID:</td><td>{address[3]}</td></tr>
                                        <tr><td className="w-2/5" align="left">Object ID:</td><td>{address[4]}</td></tr>
                                    </React.Fragment>
                                }
                                {address[1] == 14 &&
                                    <React.Fragment>
                                        <tr><td className="w-2/5" align="left">Controller:</td><td>{address[3]}</td></tr>
                                        <tr><td className="w-2/5" align="left">Address:</td><td>{address[4]}</td></tr>
                                    </React.Fragment>
                                }
                                {address[1] == 15 &&
                                    <React.Fragment>
                                        <tr><td className="w-2/5" align="left">Point:</td><td>{address[5]}</td></tr>
                                    </React.Fragment>
                                }
                                {address[1] == 11 &&
                                    <React.Fragment>
                                        <tr>
                                            <td className="w-2/5" align="left">Site</td>
                                            <td>{address[3]}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-2/5" align="left">UCC4</td>
                                            <td>{address[4]}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-2/5" align="left">UCC16</td>
                                            <td>{address[5]}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-2/5" align="left">Point</td>
                                            <td>{address[6]}</td>
                                        </tr>
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex-1 flex-grow flex flex-col p-2">
                    <CommmandPoint address={address} selectedpoint={selectedpoint} activeModuleType={activeModuleType} updatePoint={updatePoint} />
                    <div className="flex text-2xl border-b border-grey py-1 font-semibold">Status</div>
                    <div className="flex w-full  my-4">
                        <table className="w-full border-collapse border-none">
                            <tbody>
                                <tr>
                                    <td className="w-2/5 font-medium" align="left">Point name:</td>
                                    <td>{selectedpoint.getIn(['pointconfiguration', 'm_pointName'], 'pending')}</td>
                                </tr>
                                {address[1] == 9 && 
                                    <tr>
                                        <td className="w-2/5 font-medium" align="left">Descriptor:</td>
                                        <td>{selectedpoint.getIn(['pointconfiguration', 'm_description'], 'pending')}</td>
                                    </tr>
                                }
                                <tr>
                                    <td className="w-2/5 font-medium" align="left">Alarm state:</td>
                                    <td>{selectedpoint.getIn(['pointconfiguration', 'm_formattedAlarmText'], 'pending')}</td>
                                </tr>
                                {address[1] == 6 &&
                                    <React.Fragment>
                                        <tr>
                                            <td className="w-2/5 font-medium" align="left">Mode:</td>
                                            <td>{selectedpoint.getIn(['pointconfiguration', 'm_formattedModeText'], 'pending')}</td>
                                        </tr>
                                    </React.Fragment>
                                }
                                <tr>
                                    <td className="w-2/5 font-medium" align="left">Actual value:</td>
                                    <td>{selectedpoint.getIn(['pointconfiguration', 'm_formattedValueText'], 'pending')}</td>
                                </tr>
                                <tr>
                                    <td className="w-2/5 font-medium" align="left">Who Commanded:</td>
                                    <td>{selectedpoint.getIn(['pointconfiguration', 'm_whoCommanded'], 'pending')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        )
    }
}

export default AdvancedPointStatus;