import React from 'react';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checked: props.checked }
    }

    handleChange(e) {
        let { onChange } = this.props;

        // console.log(this.props.onChange);
     

        if (!onChange) {
            this.setState({ checked: e.target.checked });
            return;
        } else {
            onChange(e.target.checked);
        }
      

    }
    render() {
        let { className, label, onChange, checked, id, ...others } = this.props;

        // console.log("checkbox value of checked: ", checked);

        return (
            <div className={`relative pl-[1.25rem] ${className}`}>
                <input type="checkbox" className={`absolute mt-[0.3rem] ml-[-1.25rem] box-border`}
                    name={id || label}
                    checked={!onChange ? !!this.state.checked : !!checked}
                    onChange={this.handleChange.bind(this)}
                    id={id || label}
                    {...others} 
                />
                <label className="mb-0 inline-block " htmlFor={id || label}>{label}</label>
            </div>
        )

        return (
            <div className="my-2">
                <p>
                    <input
                        name={label}
                        type="checkbox"
                        checked={!!this.state.checked}
                        onChange={this.handleChange.bind(this)}
                        id={label}
                        {...others} />
                    <label htmlFor={label}>{label}</label>
                </p>
            </div>
        )
    }
}
export default Checkbox;