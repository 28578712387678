import Input from 'Controls/Form/Input';

export default function Compound({ selectedcomponent, onChange}) {

    return <div>
        <div className="grid grid-cols-16 gap-2">
            <div className="col-span-12 md:col-span-2 my-2">
                <Input
                    label="Rotate Angle"
                    type="number"
                    value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                    onChange={(value) =>  onChange(['configuration', 'rotation'], value)}
                /> 
            </div>
            <div className="col-span-12 md:col-span-2 my-2">
                <Input
                    label="Scale %"
                    type="number"
                    value={selectedcomponent.getIn(['configuration', 'scale'], '') || ''}
                    onChange={(value) => onChange(['configuration', 'scale'], value)}
                /> 
            </div>
        </div>
    </div>

}