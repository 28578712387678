import React from 'react';

class Data extends React.Component {
    render() {
        let { className, children, fields, style, ...others } = this.props;

        return (
            <div className={`grid col-span-16 grid-cols-16 ${className}`} style={style}>
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child, {
                        fields: fields,
                        key: Date.now()
                    });
                })}
            </div>
        );
    }
}

export default Data;