import * as d3 from 'd3';
import { createPolygonShape, displayTooltip, setDefs } from '../Utils';

export default function Duct(options) {
    return new Promise((resolve, reject) => {
        let createPlacement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
        .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacement(options);

        options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'),
            options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
            options.node.getIn(['configuration', 'grad-x2'], -0.45) ,options.node.getIn(['configuration', 'grad-y2'], 0),
            options.node.getIn(['configuration', 'grads']));
  
        let shape =  createPolygonShape(placementElement, options,
            options.node.getIn(['configuration', 'height'],85),
            options.node.getIn(['configuration', 'length'],400),
            options.node.getIn(['configuration', 'x1'],0),
            options.node.getIn(['configuration', 'y1'],0),
            options.node.getIn(['configuration', 'x2'],0),
            options.node.getIn(['configuration', 'opacity'],1));

        let tooltip = displayTooltip(placementElement, options);
        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;       
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                placementElement.remove();
            },
            getElement: () => {
                return placementElement;
            },
            redrawElement: (node) => {
                options.node = node;
                placementElement.remove();
                placementElement = createPlacement(options);
                options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'), 
                    options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                    options.node.getIn(['configuration', 'grad-x2'], -0.45) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                    options.node.getIn(['configuration', 'grads']));
                shape =  createPolygonShape(placementElement, options,
                    options.node.getIn(['configuration', 'height'],85),
                    options.node.getIn(['configuration', 'length'], 400),
                    options.node.getIn(['configuration', 'x1'],0), options.node.getIn(['configuration', 'y1'],0),
                    options.node.getIn(['configuration', 'x2'],0),
                    options.node.getIn(['configuration', 'opacity'],1));
                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}
