import React, { useState } from 'react';
import { Map, List } from 'immutable';
import { SketchPicker } from 'react-color';
import { Popover } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

export default function SettingsPanel({ label, setSettings, settings }) {

    

    const ls = settings || Map();

    // console.log("0503 rotationEnabled", ls.get('rotationEnabled'));

    return <div className="">
        <h3 className="text-2xl pb-4">{label}</h3>
        <hr />
        <div className="">
            <div className="flex items-center border-b">
                <h3 className="text-lg p-2 w-64">Area rotation</h3>
                <div className="p-2">
                    <label className="flex flex-row justify-center gap-2 border p-2 px-4 cursor-pointer">
                        <input 
                            className="p-2 border " 
                            checked={ls.get('rotationEnabled')}
                            onChange={(e)=> setSettings((c) => c.set('rotationEnabled', !c.get('rotationEnabled')))}
                            type="checkbox" /> 
                        Enable rotation
                    </label>
                </div>
                <div className="p-2">
                    <label className="block">Seconds</label>
                    <input 
                        className="p-2 border w-20" 
                        value={ls.get('rotationInterval')} 
                        placeholder={30}
                        type="text" 
                        onChange={(e)=> setSettings((c) => c.set('rotationInterval', e.target.value))} />
                </div>
            </div>
            <div className="flex items-center border-b">
                <h3 className="text-lg p-2 w-64">Font size</h3>
                <div className="p-2">
                    <label className="block">Default</label>
                    <input 
                        className="p-2 border w-20" 
                        value={ls.get('defaultFontSize')}
                        onChange={(e)=> setSettings((c) => c.set('defaultFontSize', e.target.value))}
                        type="text" />
                </div>
                <div className="p-2">
                    <label className="block">Fallback</label>
                    <input 
                        className="p-2 border w-20" 
                        value={ls.get('defaultFallbackFontSize')} 
                        type="text" 
                        onChange={(e)=> setSettings((c) => c.set('defaultFallbackFontSize', e.target.value))} />
                </div>
            </div>
            <div className="flex items-center border-b ">
                <h3 className="text-lg p-2 w-64">Textbox offset</h3>
                <div className="p-2">
                    <label className="block">Default</label>
                    <div className="flex">
                        <div className="border flex">
                            <div className="bg-gray-200 p-2">x</div>
                            <input 
                                className="p-2 w-16" 
                                value={ls.get('defaultTextboxXOffset')}
                                onChange={(e)=> setSettings((c) => c.set('defaultTextboxXOffset', e.target.value))}
                                type="text" />
                        </div>
                        <div className="border flex ml-4">
                            <div className="bg-gray-200 p-2">y</div>
                            <input 
                                className="p-2 w-16" 
                                value={ls.get('defaultTextboxYOffset')}
                                onChange={(e)=> setSettings((c) => c.set('defaultTextboxYOffset', e.target.value))}
                                type="text" />
                        </div>
                    </div>
                </div>
                <div className="p-2">
                    <label className="block">Fallback</label>
                    <div className="flex">
                        <div className="border flex">
                            <div className="bg-gray-200 p-2">x</div>
                            <input 
                                className="p-2 w-16" 
                                value={ls.get('fallbackTextboxXOffset')}
                                onChange={(e)=> setSettings((c) => c.set('fallbackTextboxXOffset', e.target.value))}
                                type="text" />
                        </div>
                        <div className="border flex ml-4">
                            <div className="bg-gray-200 p-2">y</div>
                            <input 
                                className="p-2 w-16" 
                                value={ls.get('fallbackTextboxYOffset')}
                                onChange={(e)=> setSettings((c) => c.set('fallbackTextboxYOffset', e.target.value))}
                                type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center  border-b">
                <h3 className="text-lg p-2 w-64">Calendar header</h3>
                <div className="p-2">
                    <label className="block">Text color</label>
                    <ColorPickerField 
                        value={ls.get('defaultCalendarHeaderTextColor')} 
                        onChange={(v)=> setSettings((c) => c.set('defaultCalendarHeaderTextColor', v))} />
                </div>
                <div className="p-2">
                    <label className="block">Background color</label>
                    <ColorPickerField 
                        value={ls.get('defaultCalendarHeaderBgColor')} 
                        onChange={(v)=> setSettings((c) => c.set('defaultCalendarHeaderBgColor', v))} />
                </div>
            </div>
            <div className="flex items-center border-b">
                <h3 className="text-lg p-2 w-64">Polling</h3>
                <div className="p-2">
                    <label className="block">Start after</label>
                    <input 
                        className="p-2 border w-24" 
                        value={ls.get('startPollingAfter')}
                        onChange={(e)=> setSettings((c) => c.set('startPollingAfter', e.target.value))}
                        type="text" />
                </div>
                <div className="p-2">
                    <label className="block">Interval</label>
                    <input 
                        className="p-2 border w-24" 
                        value={ls.get('pollingInterval')}
                        onChange={(e)=> setSettings((c) => c.set('pollingInterval', e.target.value))}
                        type="text" />
                </div>
            </div>
            <div className="flex items-center  border-b pr-96">
                <h3 className="text-lg p-2 w-64 ">Button colors</h3>
                <div>
                    <div className="flex items-center ">
                        <h3 className="text-lg p-2 w-32">Background</h3>
                        <div className="p-2">
                            <label className="block">Save</label>
                            <ColorPickerField 
                                value={ls.get('defaultSaveButtonBackground')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultSaveButtonBackground', v))} />
                        </div>
                        <div className="p-2">
                            <label className="block">Ok</label>
                            <ColorPickerField 
                                value={ls.get('defaultOkButtonBackground')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultOkButtonBackground', v))} />
                        </div>
                        <div className="p-2">
                            <label className="block">Cancel</label>
                            <ColorPickerField 
                                value={ls.get('defaultCancelButtonBackground')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultCancelButtonBackground', v))} />
                        </div>
                        <div className="p-2">
                            <label className="block">Close</label>
                            <ColorPickerField 
                                value={ls.get('defaultCloseButtonBackground')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultCloseButtonBackground', v))} />
                        </div>
                    </div>
                    <div className="flex items-center ">
                        <h3 className="text-lg p-2 w-32">Text</h3>
                        <div className="p-2">
                            <ColorPickerField 
                                value={ls.get('defaultSaveButtonText')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultSaveButtonText', v))} />
                        </div>
                        <div className="p-2">
                            <ColorPickerField 
                                value={ls.get('defaultOkButtonText')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultOkButtonText', v))} />
                        </div>
                        <div className="p-2">
                            <ColorPickerField 
                                value={ls.get('defaultCancelButtonText')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultCancelButtonText', v))} />
                        </div>
                        <div className="p-2">
                            <ColorPickerField 
                                value={ls.get('defaultCloseButtonText')} 
                                onChange={(v)=> setSettings((c) => c.set('defaultCloseButtonText', v))} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center  border-b pr-96">
                <h3 className="text-lg p-2  w-64">Zoom level</h3>
                <div className="">
                    <ZoomControl value={ls.get('defaultZoomLevel', 100)} onChange={(v) => setSettings((c) => c.set('defaultZoomLevel', v))}/>
                </div>
            </div>
            <div className="flex items-center border-b">
                <h3 className="text-lg p-2 w-64">Point Alarm Notification</h3>
                <div className="p-2">
                    <label className="flex flex-row justify-center gap-2 border p-2 px-4 cursor-pointer">
                        <input 
                            className="p-2 border " 
                            checked={ls.get('pointAlarmNotificationEnabled')}
                            onChange={(e)=> setSettings((c) => c.set('pointAlarmNotificationEnabled', !c.get('pointAlarmNotificationEnabled')))}
                            type="checkbox" /> 
                        Point Alarm Notification
                    </label>
                </div>
            </div>
        </div>
    </div>
}

function ZoomControl({ value, onChange }) {

    const incrementValue = () => {
        // console.log("incrementValue", value);
        onChange(Math.min(Number(value) + 5, 300))
    }

    const decrementValue = () => {
        // console.log("decrementValue", value);
        onChange(Math.max(Number(value) - 5, 0))
    }

    return <div className="flex items-center p-2">
        <div className="p-2 cursor-pointer" onClick={decrementValue}>
           <MinusIcon className="w-6 h-6" />
        </div>
        <input 
            className="block p-2 border w-24" 
            value={value}
            onChange={(e)=> { onChange(e.target.value) }}
            type="number" />
        <div className='p-2 cursor-pointer' onClick={incrementValue}>
            <PlusIcon className="w-6 h-6" />
        </div>
    </div>
}

function ColorPickerField({ onChange, value }) {
    const onPickerChange = (color) => {
        onChange(color.hex);
    }
    let colorStyle = { backgroundColor: value }
    return <div className="border flex border-gray-400 ">
        <input className="p-2 w-24" onChange={(e) => onChange(e.target.value)} type="text" value={value || ""}/>
        <div style={colorStyle} className="border-l border-gray-400  w-10 text-center">
            <PickerPopover><SketchPicker color={value || "#FFF"} onChange={onPickerChange}  /></PickerPopover>
        </div>
    </div>
}

function PickerPopover({ children }) {
    return (
      <Popover className="relative w-full h-full">
        <Popover.Button className={`w-full h-full`}>
            <div className="cursor-pointer h-full p-2 "></div>
        </Popover.Button>
        <Popover.Panel className="absolute bottom-0 left-full z-10">
            {children}
        </Popover.Panel>
      </Popover>
    )
  }
