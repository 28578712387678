import React, { useState, useEffect, useRef } from 'react';
import { useSubscription } from '../../../../../Context/AreaPoint';
import Button from 'Controls/Button/UserDefined';

export default function PointDataListPoint({ point, localConfigureNode, address }) {
    const subscription = useSubscription();
    const [pointData, setPointData] = useState(undefined);
    useEffect(() => {
        if(!subscription) {
            return () => {}
        }
        subscription.subscribe(address, (data) => {
            setPointData(() => data);
        })
    }, [subscription, address]);

    if(!(pointData || {}).pointconfiguration) {
        return <div className="row border-b mb-2 pb-2">
            <div className="col-16"><strong>Please wait while we are getting the point data for: </strong>{address}</div>
        </div>
    } 
    let info = pointData || {};
    return <div>
        <div className="row border-b mb-2 pb-2" key={address}>
            <div className="col-16">
                {info.pointconfiguration.m_pointName}
            </div>
            <div className="col-8">
                {info.pointconfiguration.m_formattedValueText}
            </div>
            <div className="col-8">
                <Button role="primary" className="w-100" onClick={() => localConfigureNode(point.node)}>Point status</Button>
            </div>
        </div>
    </div>
}