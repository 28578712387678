class DesktopService {


    constructor() {
        this.handles = {};

    }

    send(channel, message) {
        if(window && window.bmsDeskEnv) {
            window.bmsDeskEnv.sendMenuUpdateRequest(channel, message);
        }
    }

    initialize() {
        // console.log("Initializing desktop service")
        if(window && window.bmsDeskEnv) {
            // console.log("Registering menu action on desktop service")
            window.bmsDeskEnv.handleMenuActions(this.processHandles.bind(this, 'menu-action'));
            window.bmsDeskEnv.handleReactNavigation(this.processHandles.bind(this, 'react-navigation'));
        }

    }

    processHandles(channel, event, message) {
        if(this.handles[channel]) {
            this.handles[channel].forEach((handle) => {
                handle(message);
            });
        }
    }

    subscribe(channel, handle) {
        
        if(!this.handles[channel]) {
            this.handles[channel] = [];
        }

        // console.log("subscribing listener to channel " + channel);
        // console.log("amount of listeners before subscribing: " + this.handles[channel].length);
        // console.log("subscribed!")

        this.handles[channel].push(handle);
    }

    unsubscribe(channel, handle) {
        let handleToRemove = this.handles[channel].findIndex((h) => h === handle);
        // console.log("unsubscribing listener to channel " + channel);
        // console.log("index of listener to remove: " + handleToRemove);
        // console.log("amount of listeners before unsubscribing: " + this.handles[channel].length);
        // console.log("unsubscribed!")
        delete this.handles[channel][handleToRemove];
    }

}


export default new DesktopService();