import React, {useState, useEffect} from 'react';
import ServiceBus from 'Services/ServiceBus';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function PointPropertiesDialog({selectedItem, onClose, type, ...props}) {
    return <Dialog opened={!!selectedItem} onClose={onClose}>
        <PointPropertiesDialogPanel selecteditem={selectedItem} type={type} onClose={onClose} {...props} />
    </Dialog>
}

function PointPropertiesDialogPanel({selecteditem, type, onClose, customer}) {
    const headers = ["Point Details", "Priority Information"];  
    const [properties, setProperties] = useState([]);

    useEffect( ()=> {
        let unsubscribeGetPointPropertiesResp = ServiceBus.on('21-151', processGetPointPropertiesResp);
        getPointProperties();

        return() => {
            unsubscribeGetPointPropertiesResp();
        } 
    },[]);

    const getPointProperties = () => {
        let address = selecteditem.getIn(['configuration', 'boundto']);

        let request = {
            trackMessage: true,
            m_level: 150,
            m_communicNum: 21,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: address.split('-')[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: address.split('-').map((bit) => { return parseInt(bit); }),
            m_informationType: type
        }
    
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const processGetPointPropertiesResp = (msg) => {
        setProperties(msg.m_text); 
    }

    return(
        <DialogContent>
            <DialogHeader>
                <DialogTitle>{headers[type]}</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div>
                    {(properties || []).map((p) => {
                        return <pre>{p}</pre>
                    })}
                </div>
            </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={onClose}>Close</Button></div>
            </DialogFooter>
        </DialogContent>          
    )
}
export default PointPropertiesDialog;