import { alarmDisplayInfo, backgroundColour, backgroundBorderColour, alarmTextColour, evaluateCondition } from "./constants";
import { ExclamationTriangleIcon, BellSlashIcon, BellAlertIcon, InformationCircleIcon, WrenchScrewdriverIcon, CheckCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useBMS } from "../../BmsView/Context/BMS";
import { useAlarmFilterGroups } from "../../BmsView/Customer/Context/AlarmFilterGroups";
import { OrderedMap } from 'immutable';

function convertDateString(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
}
function AlarmRecord({ alarm, children, siteAlarmFilterGroups = OrderedMap() }) {
    let Icon = alarmDisplayInfo[alarm.get('icon')].Icon;
      
    const { currentUser } = useBMS();

    const ackedby = alarm.get('ackedby');
    let userToAcknowledge = currentUser.get('firstname') + " " + currentUser.get('lastname');

    // console.log("Filters applied", siteAlarmFilterGroups);
    // console.log("AlarmRecord", currentUser);

    let icon = alarm.get('icon');
    // console.log("icon value before");
    
    if (siteAlarmFilterGroups && siteAlarmFilterGroups.size > 0) {
        siteAlarmFilterGroups.forEach((filter) => {
            if(alarm.get('priority') == filter.get('priority') && filter.get('priorityenabled') && filter.get('alarmprofileenabled')) {
                
                if(!icon) {
                    return;
                }
                
                let shouldFilter = evaluateCondition(filter.get('prioritycondition'), filter.get('priority'), alarm.get('priority'));
                    if(shouldFilter) {
                    let index = alarmDisplayInfo.findIndex((item) => item.type == filter.get('alarmprofile'));
                    if(index > -1) {
                        icon = index;
                    }
                }
            }
        });
    }

    // console.log("icon value", icon);

    

    return (  
        icon != undefined &&
            <div className={`@container rounded-md ${backgroundColour[alarmDisplayInfo[icon].colour]} p-2 border-4 border-double ${backgroundBorderColour[alarmDisplayInfo[icon].colour]}`}>
                <div className="flex item-center justify-between ">
                    <Icon className={`${alarmDisplayInfo[icon].animate ? 'animate-ping h-4 w-4' : 'h-6 w-6'} ${alarmTextColour[alarmDisplayInfo[icon].colour]} aria-hidden=true`} />
                    <div className="ml-4"><h3>{alarmDisplayInfo[icon].type}</h3></div>
                    <div className="ml-4">{convertDateString(alarm.get('datetime'))}</div>
                </div>
                <div className="flex ml-8 item-center justify-between  flex-col @2xl:flex-row">
                    <div className="text-nowrap">
                        Point : { alarm.get('point')}
                    </div>
                    <div className="flex flex-row gap-4">
                        <div  className="text-nowrap">
                            Panel : { alarm.get('panel')}
                        </div>
                        <div  className="text-nowrap">
                            Priority : { alarm.get('priority')}
                        </div>
                        
                    </div>
                </div>
                <div className="flex ml-8 item-center justify-between">
                    <pre className="text-wrap">
                        {alarm.get('paneltext')}
                    </pre>
                </div>
                <div className="flex ml-8 item-center justify-between">
                    <div>
                        { alarm.get('messagetext')}
                    </div>
                </div>
                <div className="flex ml-8 gap-3 justify-end">
                    {/* <div>
                        Priority : { alarm.get('priority')}
                    </div> */}
                        {children}
                </div>
                { alarm.get('exttxt') && icon != 0 &&
                <div className="ml-8 mt-4">
                    Detailed Information :</div> }
                { alarm.get('exttxt') && icon != 0 && <div className="ml-8">
                    <pre>{alarm.get('exttxt')}</pre></div>}
            </div>
    )
}

AlarmRecord.AcknowledgeButton = AlarmRecordAcknowledgeButton;
AlarmRecord.ShowDetailsButton = AlarmRecordShowDetailsButton;

function AlarmRecordAcknowledgeButton({ alarm, onAck }) {
    return <div className="flex" onClick={() => onAck(alarm)}>
        Ack
        <div><CheckCircleIcon className="ml-2 h-6 w-6 text-red-900 cursor-hand" aria-hidden="true" /></div>
    </div>
}

function AlarmRecordShowDetailsButton({ alarm, displayAlarmDetails }) {
    return <div className="flex">
        <div><MagnifyingGlassIcon className="ml-2 h-6 w-6 text-red-900 cursor-hand" aria-hidden="true" onClick={() => displayAlarmDetails(alarm)} /></div>
    </div>
}


export default AlarmRecord;