import ReducersService from 'Services/ReducersService';
import Immutable from 'immutable';


ReducersService.registerReducer('LOGIN', (state, action) => {
    return state.setIn(['currentUser'], Immutable.fromJS(action.currentuser));
});

ReducersService.registerReducer('LOGOUT', (state, action) => {
    return state.deleteIn(['currentUser']);
});
