import Store from 'Store';
import axios from 'axios';

export function toggleEditmode(){
    Store.dispatch({
        type: 'TOGGLE_EDITMODE'
    });
}


export function uploadBackground(file) {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('file', file, file.name);
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(`/files/backgrounds/${file.name}/upload`, data, config).then(resolve).catch(reject);
    })
}