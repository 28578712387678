import React from 'react';
import {fromJS} from 'immutable';
import Input from 'Controls/Form/Input';
import ColorPicker from 'Controls/ColorPicker';
import Gradient from './Gradient';


class Duct extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    render() {
        let { selectedcomponent, onChange } = this.props;
        let grads = fromJS([
            { "stop": "0%", "colour":"#EBEBEB" },
            { "stop": "100%", "colour":"#6B6B6B"}]);

        return (
            <div className="my-4">
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            label="Length"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'length'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'length'])}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            label="Height"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'height'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'height'])}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            label="x2"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'x2'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'x2'])}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>
                </div> 
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            label="Opacity"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'opacity'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'opacity'])}
                        />
                    </div>
                </div> 
                <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradient'])} label={"Gradient"}  gradientField={""}
                    gradients={selectedcomponent.getIn(['configuration', 'grads'])} defaultGrads={grads}
                    onChange={onChange}/>
                <div className="grid grid-cols-16 mt-8 gap-2">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Border" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'border-color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Stripe" value={selectedcomponent.getIn(['configuration', 'style', 'top-border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'top-border-color'])} /></div>
                </div>
            </div>
        )
    }
}

export default Duct;