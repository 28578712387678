import React, {useState} from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function SerialPortSetupDialog({ selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <SerialPortSetupDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

const SerialPortSetupDialogPanel = ({selectedPort, savePort, onClose}) => {
    let [port, setPort] = useState(selectedPort);

    const baudRate = [1200, 2400, 4800, 9600, 19200, 38400, 57600, 115200];
    const parity = ['None','Odd','Even'];
    const stopBits = ['0','1','2','1.5'];

    const fieldChanged = (field, value, transform) => {
        if (transform) {
            value = transform(value);
        }

        setPort(port.set(field, value));
    }

    const onSave = () => {
        savePort(port);
    }

    return (
        <DialogContent className="md:w-[500px] lg:w-[800px] xl:w-[1140px]">
            <DialogHeader>
                <DialogTitle>Port Definition</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="col-16 col-md-8">
                    <Input label="Serial Port Number" type="number" value={port.get('serialportnumber')} onChange={ (value) => {fieldChanged('serialportnumber', value, parseInt)}} />
                </div>
                <div className="col-16 col-md-8">
                    <Select label="Baud Rate" 
                        value={port.get('baudrate')}
                        onChange={ (value) => fieldChanged('baudrate', value, parseInt)}
                        options={ baudRate.map((rate) => {
                            return {
                                text: rate,
                                value: rate 
                            }
                        })}    
                    />
                </div>
                <div className="col-16 col-md-8">
                    <Input label="Data Bits" type="number" value={port.get('databits')} onChange={ (value) => fieldChanged('databits', value, parseInt)} />
                </div>
                <div className="col-16 col-md-8">
                    <Select label="Parity" 
                        value={port.get('parity')}
                        onChange={ (value) => fieldChanged('parity', value)}
                        options={ parity.map((value, index) => {
                            return {
                                text: value,
                                value: index 
                            }
                        })}    
                    />
                </div>
                <div className="col-16 col-md-8">
                    <Select label="Stop Bits" 
                        value={port.get('stopbits')}
                        onChange={ (value) => fieldChanged('stopbits', value)}
                        options={ stopBits.map((value, index) => {
                            return {
                                text: value,
                                value: index 
                            }
                        })}    
                    />
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={() => onClose()}>Close</Button></div>
                <div className="pl-1"><Button role="success" onClick={ () => onSave()}>Save</Button></div>
            </DialogFooter>
        </DialogContent>
    )
}

export default SerialPortSetupDialog;
