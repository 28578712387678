import React, { useState, useRef, useLayoutEffect, Fragment, useEffect } from 'react';
import Level from './Level';
import LinkItem from './LinkItem';
import Immutable, { Map, List, set } from 'immutable';
import { connect, useSelector } from 'react-redux';
import AreaDialog from './AreaDialog';
import './Reducers';
import ContextMenu from 'Controls/ContextMenu';
import {toast} from 'react-toastify';
import swal from 'sweetalert2';
import Configuration from 'Configuration';
import {duplicateArea, getArea, saveArea, getTemplate} from '../Area/Actions';
import NodesTemplateDialog from './NodesTemplateDialog';
import {saveAreaTemplate} from '../Actions';
import axios from 'axios';
import { useAreaMenu } from '../../../../Context/AreaMenu';
import { useArea } from '../../../../Context/Area';
import { Link, useNavigate, useRevalidator, redirect } from 'react-router-dom';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import { useCustomerSettings } from '../../../../Context/CustomerSettings';
import { useUserSettings } from '../../../../Context/UserSettings';
import { useBMS } from '../../../Context/BMS';
import debuggerService from 'Services/DebuggerService';
import { useTransparentLoader } from '../../../../Context/TransparentLoader';
import { useClipboard } from '../../../../Context/Clipboard';

const logger = debuggerService.getDebugger("AreaMenu", "yellow");



function AreaMenu(props) {


    const { customerSettings } = useCustomerSettings();
    const { userSettings } = useUserSettings();
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const transparentLoader = useTransparentLoader();

    let rotationEnabled = customerSettings.get('rotationEnabled');
    rotationEnabled = rotationEnabled || userSettings.get('rotationEnabled') || false;

    const { currentUser, revalidateSite } = useBMS();
    const editmode = useSelector((state) => state.get('editmode'));
    const {areaMenu, setAreaMenu} = useAreaMenu(); 

    // const { copyCurrentArea } = useAreaCopy(props.currentNode);

    logger.log("AreaMenu render", areaMenu);

    const addArea = (path) => {
        let { nodes } = props;
        let { lvl1node } = nodes;
        let selectedarea = Immutable.fromJS({ treenodes: [] });
        selectedarea = selectedarea.set('$$newlyCreated', true);
        /*
            Omar: 25-06-2023 The statement
            USED TO CONTAIN path[0] == lvl1node.get('path') however i noticed
            that not all nodes have a path, also when path length is higher then 0
            the menu should path on 0 is always a path in the tree. Maybe look at this one
            more critically later?
        */
        if(lvl1node && path.length > 0) {
            selectedarea = selectedarea.set('isV2', true);
            if(selectedarea.get('isV2')) {
                selectedarea = selectedarea.set('backdrop',true);
            }
        }

        setAreaMenu({...areaMenu,
            currentpath: path,
            disableVersion: false,
            selectedarea: selectedarea,
            indexOfNode: -1
        });
    }

    const clearSelection = () => {
        setAreaMenu({...areaMenu,
            selectedarea: undefined,
            contextMenu: undefined,
            toduplicate: undefined,
            indexOfNode: -1,
            areatoduplicate: undefined
        })
    }

    const saveArea = async (areaToSave) => {
        let { onSave, baseDirectory,customer, site, currentareapath, customerMainDirectory } = props;
        let { currentpath, indexOfNode, toduplicate, selectedarea, toconvert } = areaMenu;

        let newlyCreated = areaToSave.get('$$newlyCreated');
        areaToSave = areaToSave.delete('$$newlyCreated');

        if (!areaToSave.get('name')){
            swal.fire('Error', "You need to name the area.", "error");
            return;
        }
        let nodes = undefined;
        if (areaToSave.getIn(['hasTemplate']) && areaToSave.getIn(['template'])){
            logger.log("saveArea saving template");
            let nodesResponse = undefined;
            if(areaToSave.getIn(['isV2'])) {
                nodesResponse = await axios.get(`/files/customers/${customerMainDirectory}_templates-v2_${areaToSave.get('template')}`);
            } else {
                nodesResponse = await axios.get(`/files/customers/${customerMainDirectory}_templates_${areaToSave.get('template')}`);
            }
            
            let toProcess = JSON.stringify(nodesResponse.data).split('[:');
            let newNodesString = [toProcess[0]];
            for (var i = 1; i < toProcess.length; i++){
                let parts = toProcess[i].split(':]');
                let value = areaToSave.getIn(['templatevariables', parts[0]], '');
                newNodesString.push(value);
                newNodesString.push(parts[1]);
            }   
            nodes = JSON.parse(newNodesString.join(''));
        }
        if (toduplicate){
            logger.log("saveArea saving duplicate");
            let sourcepath = `${currentpath.join('/')}/${toduplicate.toLowerCase().split(' ').join('-')}.json`;
            let destinationpath = `${currentpath.join('/')}/${areaToSave.get('name').toLowerCase().split(' ').join('-')}.json`;
            let newName = areaToSave.get('name').toLowerCase().split(' ').join('-');
            if(areaToSave.get('isV2')) {
                let fullpath = [...currentpath];
                fullpath.push(newName);
                areaToSave = areaToSave.set('path', fullpath.join('_'));
            }
            await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, sourcepath, destinationpath, nodes));           
            clearSelection();
            revalidateSite();
            let linkUrl = getAreaPath(customer, site, currentpath, newName);
            navigate(linkUrl, { replace: true, relative: '..', state: { from: 'save' } });
            return true;
        } 
        else if(selectedarea.get('name') && selectedarea.get('name') != areaToSave.get('name')) {
            logger.log("saveArea saving name changes");
            logger.log(`saveArea var:currentpath =`, currentpath);
            let oldName = selectedarea.get('name').toLowerCase().split(' ').join('-');
            let newName = areaToSave.get('name').toLowerCase().split(' ').join('-');
            // This is based on nodepath which is in its turn based on params[*]
            let urlOnArea = "/" + currentareapath;
            let urlBeforeEdit = currentpath.join('/') + `/${oldName}`;
            let urlAfterEdit = currentpath.join('/') + `/${newName}`;
            if(currentpath.length > 0) {
                urlBeforeEdit = `/${urlBeforeEdit}`;
                urlAfterEdit = `/${urlAfterEdit}`;
            }
            let sourcepath = `${urlBeforeEdit}.json`;
            let destinationpath = `${urlAfterEdit}.json`;

            if(areaToSave.get('isV2')) {
                let fullpath = [...currentpath];
                fullpath.push(newName);
                areaToSave = areaToSave.set('path', fullpath.join('_'));
            }
            clearSelection();
            await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, sourcepath, destinationpath, nodes));
            
            const query = {pathid: urlOnArea};
            let areaConfigurationResponse = undefined
             /*
                What are the implications of an error in this, now it is just occuring but it is still saved,
                The only thing that an error here is preventing is a reload or redirect. Just doing a temporary try catch
                but even without it, it is an unexpected consequence. 
            */
            try {
                areaConfigurationResponse = await axios.post(`/api/areas/searchone?database=${Configuration.product}${customer.get('name').toLowerCase().replace(/ /g, "")}&connection=${customer.get('ipaddress').toLowerCase()}`, { selector: query });
            } catch(e) {
                // console.log("error in axios post", e);
            }
            
            if (areaConfigurationResponse?.data) {
                let newpath = currentpath.length == 0 ? newName: currentpath.join('/') + `/${newName}`;   
                let areaconfiguration = Immutable.fromJS(areaConfigurationResponse.data);
                areaconfiguration = areaconfiguration.set('pathid', newpath);
                /*
                    What are the implications of an error in this, now it is just occuring but it is still saved,
                    The only thing that an error here is preventing is a reload or redirect. Just doing a temporary try catch
                    but even without it, it is an unexpected consequence
                */
               try {
                await transparentLoader.executePromise(axios.put(`/api/areas/${areaconfiguration.get('_id')}?database=${Configuration.product}${customer.get('name').toLowerCase().replace(/ /g, "")}&connection=${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, areaconfiguration.toJS()));
               } catch (e) {
                    //  console.log("error in axios put", e);
               }
                
            }

            logger.log(`saveArea var:urlOnArea = ${urlOnArea}`);
            logger.log(`saveArea var:urlBeforeEdit = ${urlBeforeEdit}`);
            logger.log(`saveArea var:urlAfterEdit = ${urlAfterEdit}`);
            if(urlOnArea == urlBeforeEdit && urlBeforeEdit != urlAfterEdit) {
                logger.log("saveArea saving name changes // urls are not equal");
                revalidateSite();
                let linkUrl = getAreaPath(customer, site, currentpath, newName);
                navigate(linkUrl, { replace: true, relative: '..', state: { from: 'save' } });
                return true;
            } else {
                logger.log("saveArea saving name changes // urls are equal");
            }
            revalidateSite();
            revalidator.revalidate();
            return true;
        }
        
        // here is where path generation takes place
        let fullpath = [...currentpath];
        fullpath.push(areaToSave.get('name').toLowerCase().split(' ').join('-'));
        if(areaToSave.get('isV2')) {
            areaToSave = areaToSave.set('path', fullpath.join('_'));
        }
        let customerUrlPart = customer.get('name').toLowerCase().split(' ').join('-');
        let siteUrlPart = site.get('name').toLowerCase().split(' ').join('-');
        let newName = areaToSave.get('name').toLowerCase().split(' ').join('-');
        let oldName = newName;
        let urlBeforeEdit = `${customerUrlPart}_${siteUrlPart}_${currentpath.join('_')}_${oldName}`;
        let urlAfterEdit = `${customerUrlPart}_${siteUrlPart}_${currentpath.join('_')}_${newName}`;
        let sourcepath = `${urlBeforeEdit}.json`;
        let destinationpath = `${urlAfterEdit}.json`;
        // this is the part where the nodes are being set
        if(areaToSave.get('isV2') && !areaToSave.get('hasTemplate') && areaToSave.get('composedTemplate')) {
            
            let composedTemplateFile = areaToSave.getIn(['composedTemplate','file']);
            let customerComposedTemplatePath = `/files/customers/${customer.get('name').toLcHyphen()}_${site.get('name')}_backdrops-v2`;
            let completeComposedTemplatePath = composedTemplateFile.indexOf('~') == 0 ? `${customerComposedTemplatePath}_${composedTemplateFile.replace('~', '')}.json` : `/files/editor/backdrops-v2_${composedTemplateFile}.json`            
            let nodesResponse = await axios.get(completeComposedTemplatePath);
            let backdropLayers = nodesResponse.data;
            if(selectedarea.get('composedTemplate') && selectedarea.getIn(['composedTemplate','file']) != areaToSave.getIn(['composedTemplate','file'])) {
                // at this point something else needs to happen, namely all components in the area should be searched for the components of the old composed templatem, removed and replaced with the new one
                let areaNodesResponse = await axios.get(`/files/customers/${sourcepath}`);
                const highestId = calculateHighestIdFromAreaLayers(areaNodesResponse.data);
                const newBackdropLayers = prepareBackdropLayersForMerge(backdropLayers, highestId, areaToSave.getIn(['composedTemplate','file']));
                const filteredAreaLayers = removeBackdropFromAreaLayers(areaNodesResponse.data, selectedarea.getIn(['composedTemplate','file']));
                const mergedLayers = mergeBackdropLayersWithAreaLayers(filteredAreaLayers, newBackdropLayers);
                await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, sourcepath, sourcepath, mergedLayers));
            } else if(selectedarea.get('composedTemplate')) {
                await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, undefined, undefined, nodes));
            } else { 
                const referenceBackdropLayers = prepareBackdropLayersForMerge(backdropLayers, undefined, areaToSave.getIn(['composedTemplate','file']));
                await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, sourcepath, destinationpath, referenceBackdropLayers));
            }
        } else if(areaToSave.get('isV2') && newlyCreated) {
            await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, sourcepath, sourcepath, [{ name: "layer1", nodes: [] }]));
        } else {
            await transparentLoader.executePromise(onSave(currentpath, areaToSave, indexOfNode, undefined, undefined, nodes));
        }

        clearSelection();
        revalidateSite();

        if(newlyCreated) {
            logger.log("saveArea saving newly created");
            let linkUrl = getAreaPath(customer, site, currentpath, newName);
            // console.log("1001 redirecting to", linkUrl);
            navigate(linkUrl, { replace: true, relative: '..', state: { from: 'save' } });

        } else {
            logger.log("saveArea saving other situations");
            revalidator.revalidate();
        }
        
    }

    const activateContextMenu = (node, path, indexOfNode, evt) => {
        if (editmode) {
           
            // console.log("0503 activateContextMenu", node, path, indexOfNode, evt);

            evt.preventDefault();
            let position = {
                x: evt.clientX,
                y: evt.clientY,
                area: node,
                path: path,
                nodeindex: indexOfNode
            }
            logger.log("activateContextMenu", position);
            setAreaMenu({...areaMenu,
                contextMenu: position
            });
        }
    }

    // important because here is a dependency on path
    const removeNodeFromAreaTree = async (node, nodepath) => {
        const { site, customer } = props;
        const siteName = site.get('siteName');
        const customerName = site.get('customerName');
        let newPath = [...nodepath, node.get('name').toLowerCase().split(' ').join('-')];
        // console.log("contents of newPath", newPath);
        if(node.get('treenodes') && node.get('treenodes').size > 0) {
            await Promise.all(node.get('treenodes').map(async (subnode) => {
                
                await removeNodeFromAreaTree(subnode, newPath);
            }));
        }

        // TODO: version 1 graphics have no path, we need to think of a way to remove them, the concern is mostly subarea's
        let path = newPath.join('/'); //node.get('path', '').replaceAll('/', '_');
        let pathToRemove = `${customerName}_${siteName}_${path}.json`;
        
        // console.log(`1311 node to remove ${path}`);
        // console.log(`1311 path to remove ${pathToRemove}`);

        const query = {pathid: path};

        

        let areaConfigurationResponse = undefined;
        try {
            await axios.post(`/api/areas/searchone?database=${Configuration.product}${customer.get('name').toLowerCase().replace(/ /g, "")}&connection=${customer.get('ipaddress').toLowerCase()}`, { selector: query });
        } catch(err) {
            
        }
        
        
        if (areaConfigurationResponse?.data) {                      
            let areaconfiguration = Immutable.fromJS(areaConfigurationResponse.data);
            try {
                await axios.delete(`/api/areas/${areaconfiguration.get('_id')}?database=${Configuration.product}${customer.get('name').toLowerCase().replace(/ /g, "")}&connection=${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`);
            } catch (e) {}
        }
        if(node.get('isV2')) {
            await axios.delete(`/files/customers/${pathToRemove}`);
        }
        return true;

    }

    // here is the remove area
    // start of remove process 
    const removeArea = async () => {
        let { onDelete, customer, site } = props;
        let { contextMenu } = areaMenu;
        const amountOfSubAreas = contextMenu.area.get('treenodes').size;
        const siteName = site.get('siteName');
        const customerName = site.get('customerName');
        const nodepath = contextMenu.path;

        const deleteSource = `${customerName}_${siteName}_${nodepath}.json`;
        let subAreaMessage = '';
        if(amountOfSubAreas > 0) {
            subAreaMessage = `This area also has sub areas which will be deleted as well. `;
        }
        let swalResult = await swal.fire({
            title: 'Are you sure?',
            text: `You are about to delete ${contextMenu.area.get('name')}. ${subAreaMessage}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            reverseButtons: true
        });
        
        if (!swalResult.isConfirmed) {
            return;
        }
        await removeNodeFromAreaTree(contextMenu.area, nodepath);
        onDelete(contextMenu.path, contextMenu.area);
        setAreaMenu({...areaMenu,
            contextMenu: undefined
        });

    }

    const changeArea = () => {
        let { contextMenu } = areaMenu;
        setAreaMenu({...areaMenu,
            selectedarea: contextMenu.area,
            currentpath: contextMenu.path,
            indexOfNode: contextMenu.nodeindex
        })
    }

    const getAreaPath = (customer, site, path, name) => {
        let link = `/customer/${customer.get('name').toLcHyphen()}/site/${site.get('name').toLcHyphen()}`;
        path.forEach((slug) => {
            link += `/${slug}`;
        });
        link += `/${name}`
        return link;
    }

    const copyAreaPath = () => {
        let {site, customer} = props;
        let {contextMenu} = areaMenu;
        let link = getAreaPath(customer, site, contextMenu.path, contextMenu.area.get('name').toLcHyphen())
        let tempText = document.createElement('textarea');
        document.querySelector('body').appendChild(tempText);
        tempText.value = link;
        tempText.select();
        document.execCommand('copy');
        tempText.remove();
        clearSelection();
        toast.success('Area path copied to clipboard', { autoClose: 2000 });
    }

    // start of duplicate area
    const duplicateArea = () => {
        let {site} = props;
        let {contextMenu} = areaMenu;
       
        setAreaMenu({...areaMenu,
            toduplicate: contextMenu.area.get('name'),
            selectedarea: contextMenu.area.delete('name'),
            currentpath: contextMenu.path,
            indexOfNode: -1
        });
    }

    const moveMenu = async (direction) => {
        let { site, onOrderChange } = props;
        let { contextMenu } = areaMenu;
        let currentIndex = contextMenu.nodeindex;
        let newIndex = currentIndex + 1;
        if(direction == 'left') {
            newIndex = currentIndex - 1;
        } 
        let updateInPath = [];
        let path = contextMenu.path || [];
        let currentNodes = site.get('treenodes', Immutable.List([]));
        if (path.length > 0) {
            path.forEach((slug) => {
                let currentNode = currentNodes.filter((cNode) => { return cNode.get('name').toLowerCase().split(' ').join('-') == slug; }).first();
                if (currentNode) {
                    let indexOfLevel = currentNodes.indexOf(currentNode);
                    updateInPath.push(indexOfLevel);
                    updateInPath.push('treenodes');
                    currentNodes = currentNode.get('treenodes', Immutable.List([]));
                }
            });
            let nodes = site.getIn(['treenodes']);
            let currentItem = nodes.getIn([...updateInPath, currentIndex]);
            let nextItem = nodes.getIn([...updateInPath, newIndex]);
            if(currentItem && nextItem && newIndex > -1) {
                nodes = nodes.setIn([...updateInPath, currentIndex], nextItem);
                nodes = nodes.setIn([...updateInPath, newIndex], currentItem);
            }
            site = site.setIn(['treenodes'], nodes)
        } else {
            let currentItem = site.getIn(['treenodes', currentIndex]);
            let nextItem = site.getIn(['treenodes', newIndex]);
            if(currentItem && nextItem && newIndex > -1) {
                site = site.setIn(['treenodes', currentIndex], nextItem);
                site = site.setIn(['treenodes', newIndex], currentItem);
            }
        }
        if(onOrderChange) {
            
            await onOrderChange(site.get('treenodes'))
            clearSelection();

        }
    }


    const onMenuOrderChange = async ({ destinationNode, node }) => {
        let { onGraphOrderChange } = props;
        const performSave = async (node) => {
            node.recreateTree();
            node.generateNewPaths();
            let pathsToMove = node.getPathsToMove();
            // console.log("pathsToMove", pathsToMove);
            let newSite = node.recreateSite();
            await transparentLoader.executePromise(onGraphOrderChange(newSite.get('treenodes'), pathsToMove));
        }
        let isSameLevel = node.parent == destinationNode.parent;
        if(isSameLevel) {
            destinationNode.placeLeft(node);
            performSave(node)
            
        } else if(destinationNode && typeof destinationNode !== 'number') {
            if(destinationNode.level > node.level && node.active) {
                return;
            }
            if(destinationNode.level > node.level) {
                let amountOfSubLevels = node.getAmountOfSubLevels();
                let destinationNodeLevel = destinationNode.level;
                if((amountOfSubLevels + destinationNodeLevel) > 5) {
                    return;
                }
            }

            destinationNode.placeLeft(node);
            performSave(node)
        }

        if(typeof destinationNode == 'number') {
            if(destinationNode > node.level) {
                let amountOfSubLevels = node.getAmountOfSubLevels();
                let destinationNodeLevel = destinationNode;
                if((amountOfSubLevels + destinationNodeLevel) > 5) {
                    return;
                }
            }

            if(node.level == destinationNode) {
                let lastNode = node.getLastSibling();
                lastNode.placeRight(node);
                performSave(node)
            }

            if(node.level > destinationNode) {
                let correctLevelNode = node.parent;
                while(correctLevelNode.level != destinationNode) {
                    correctLevelNode = correctLevelNode.parent;
                }
                let lastNode = correctLevelNode.getLastSibling();
                lastNode.placeRight(node);
                performSave(node)
            } else {
                let correctLevelNode = node.activeLevelsReference[destinationNode];
                if(node.active) {
                    return;
                }
                if(correctLevelNode) {
                    let lastNode = correctLevelNode.getLastSibling();
                    lastNode.placeRight(node);
                    performSave(node)
                    
                } else {
                    correctLevelNode = node.activeLevelsReference[destinationNode - 1];
                    if(correctLevelNode && correctLevelNode.firstChild) {
                        let lastNode = correctLevelNode.firstChild.getLastSibling();
                        lastNode.placeRight(node);
                        performSave(node)
                    } else if(correctLevelNode) {
                        correctLevelNode.placeLastChild(node);
                        performSave(node)
                    }
                }
            }
        }
        return;
    };

    const copyArea = () => {
        let {site} = props;
        let {contextMenu} = areaMenu;
        setAreaMenu({...areaMenu,
            toduplicate: contextMenu.area.get('name'),
            areatoduplicate: contextMenu.area.delete('name'),
            contextMenu: undefined
        });
        // copyCurrentArea();
        // setAreaMenu({...areaMenu,
        //     contextMenu: undefined
        // });
    }

    // Conversion
    const convertToV2 = () => {
        let {site, convertRootAreaToV2} = props;
        let {contextMenu} = areaMenu;
        
        if(convertRootAreaToV2) {

            convertRootAreaToV2(contextMenu.area);

        }
        clearSelection();
    }

    const pasteArea = () => {
        let {site} = props;
        let {areatoduplicate, contextMenu} = areaMenu;
        setAreaMenu({...areaMenu,
            indexOfNode: -1,
            selectedarea: areatoduplicate,
            currentpath: [...contextMenu.path, contextMenu.area.get('name').toLcHyphen()],
            contextMenu: undefined
        });
    }

    const editTemplate = async () => {
        let {contextMenu} = areaMenu;
        let {baseDirectory, customerMainDirectory} = props;
        let templateName = contextMenu.area.get('template');
        let selectedArea = contextMenu.area;
    
        let fileName = `${customerMainDirectory}_templates_${templateName}`;
        if(selectedArea.get('isV2')) {
            fileName = `${customerMainDirectory}_templates-v2_${templateName}`;
        }
        let areaConfigurationResponse = await getTemplate(fileName);
        let templateConfigResponse = await getTemplate(fileName.replace('.template.json', '.config.json'));
        // console.log("templateConfigResponse", templateConfigResponse);
        let templateConfiguration = Immutable.fromJS({
            name: `${templateName.replace('.template.json', '')}`,
            isV2: contextMenu.area.getIn(['isV2']),
            
            backdrop: Immutable.fromJS(templateConfigResponse.backdrop),
            variables: Immutable.fromJS(templateConfigResponse.variables)
        })

        if(contextMenu.area.getIn(['isV2'])) {
            templateConfiguration = templateConfiguration.set('layers', Immutable.fromJS(areaConfigurationResponse));
        } else {
            templateConfiguration = templateConfiguration.set('nodes', Immutable.fromJS(areaConfigurationResponse));
        }
        setAreaMenu({...areaMenu,
            contextMenu: undefined,
            templateconfiguration: templateConfiguration
        })
    }

    // creating template 
    const createTemplate = async () => {
        let {contextMenu} = areaMenu;
        let {baseDirectory} = props;
        let selectedArea = contextMenu.area;
        let fileName = `${baseDirectory}_${selectedArea.get('name').toLcHyphen()}.json`;
        let areaConfigurationResponse = await getArea(fileName).promise;
        let templateConfiguration = Immutable.fromJS({
            name: `${selectedArea.get('name')} Template`,
            
            backdrop: contextMenu.area.getIn(['backdrop']),
            isV2: contextMenu.area.getIn(['isV2'])
        });

        // console.log("create template area configuration response", areaConfigurationResponse);

        if(contextMenu.area.getIn(['isV2'])) {
            templateConfiguration = templateConfiguration.set('layers', Immutable.fromJS(areaConfigurationResponse));
        } else {
            templateConfiguration = templateConfiguration.set('nodes', Immutable.fromJS(areaConfigurationResponse));
        }

        setAreaMenu({...areaMenu,
            contextMenu: undefined,
            templateconfiguration: templateConfiguration
        })
    }

    // clear template configuration
    const clearTemplateConfiguration = () => {
        setAreaMenu({...areaMenu,
            templateconfiguration: undefined
        });
    }

    const saveTemplateConfiguration = async (nodeconfiguration, variableconfiguration) => {
        let {customer, site} = props;

        let templateConfiguration = Map();
        templateConfiguration = templateConfiguration.set('variables', variableconfiguration);
        templateConfiguration = templateConfiguration.set('backdrop', nodeconfiguration.get('backdrop'))

        await saveAreaTemplate(
            customer.get('name').toLcHyphen(), 
            site.get('name').toLcHyphen(), 
            nodeconfiguration, 
            templateConfiguration);
        clearTemplateConfiguration();
        swal.fire("Success", "The area template has been successfully created.", "success");
    }

    let { site, nodepath, sitename, customername, nodes, customer, siteGraph } = props;
    let { selectedarea, contextMenu, toduplicate, templateconfiguration, disableVersion } = areaMenu;

    return (
        <div className="flex-col" id="area-menu">
            <NodesTemplateDialog selecteditem={templateconfiguration} onClose={clearTemplateConfiguration} onSave={saveTemplateConfiguration} />
            {editmode &&
                <ContextMenu contextMenu={contextMenu} onClose={() => { setAreaMenu({...areaMenu, contextMenu: undefined }) }}>
                    <ContextMenu.Item onClick={removeArea}>Remove</ContextMenu.Item>
                    <ContextMenu.Item onClick={changeArea}>Edit</ContextMenu.Item>
                    <ContextMenu.Item onClick={copyAreaPath}>Copy link location</ContextMenu.Item>
                    <ContextMenu.Item onClick={duplicateArea}>Duplicate</ContextMenu.Item>
                    {/* <ContextMenu.Item onClick={copyArea}>Copy area</ContextMenu.Item> */}
                    {/* { rotationEnabled && <ContextMenu.Item onClick={toggleForRotating}>{contextMenu && contextMenu.area && contextMenu.area.get('rotating') ? 'Disable rotation' : 'Enable rotation'}</ContextMenu.Item> } */}
                    {contextMenu &&  contextMenu.path.length < 1 && <ContextMenu.Item onClick={convertToV2}>Convert to V2 graphics</ContextMenu.Item> }
                    {/* {toduplicate && <ContextMenu.Item onClick={pasteArea}>Paste area</ContextMenu.Item>} */}
                    { contextMenu && contextMenu.area && contextMenu.area.get('hasTemplate') && <ContextMenu.Item onClick={editTemplate}>Edit template</ContextMenu.Item> || <ContextMenu.Item onClick={createTemplate}>Create template</ContextMenu.Item> }
                    <ContextMenu.Item onClick={() => moveMenu('left')}>Move left in menu</ContextMenu.Item>
                    <ContextMenu.Item onClick={() => moveMenu('right')}>Move right in menu</ContextMenu.Item>
                </ContextMenu>
            }
            <AreaDialog rotationEnabled={rotationEnabled} disableVersion={disableVersion} currentUser={currentUser} selecteditem={selectedarea} onClose={clearSelection} onSave={saveArea} site={site} customer={customer} />
            
            <FullTreeMenuGraph siteGraph={siteGraph} customername={customername}
                sitename={sitename}
                site={site}
                activateContextMenu={activateContextMenu}
                addArea={addArea}
                editmode={editmode} 
                onOrderChange={onMenuOrderChange}/>
        </div>
    )


}


export default AreaMenu;

function DropArea({ dragging, editmode, onDrop }) {
    const dropAreaRef = useRef();
    const dropAreaLabelRef = useRef();
    const _onDrop = (e) => {
        dropAreaLabelRef.current.classList.add('hidden');
        dropAreaRef.current.classList.remove('bg-white/30');
        dropAreaRef.current.classList.add('w-4');
        onDrop(e);
    }
    useEffect(() => {
        const onDragEnter = (e) => {
            // console.log("drag enter");
            dropAreaLabelRef.current.classList.remove('hidden');
            dropAreaRef.current.classList.add('bg-white/30');
            dropAreaRef.current.classList.remove('w-4');
        }
        const onDragLeave = (e) => {
            // console.log("drag leave");
            if(!e.currentTarget.contains(e.relatedTarget)) {
                dropAreaLabelRef.current.classList.add('hidden');
                dropAreaRef.current.classList.remove('bg-white/30');
                dropAreaRef.current.classList.add('w-4');
            }
        }
        const onDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.dropEffect = 'move';
        };
        dropAreaRef.current.addEventListener('dragenter', onDragEnter);
        dropAreaRef.current.addEventListener('dragleave', onDragLeave);
        dropAreaRef.current.addEventListener('dragover', onDragOver);
        return () => {
            if(dropAreaRef.current) {
                dropAreaRef.current.removeEventListener('dragenter', onDragEnter);
                dropAreaRef.current.removeEventListener('dragleave', onDragLeave);
                dropAreaRef.current.removeEventListener('dragover', onDragOver);
            }
        }

    }, [])
    return <div ref={dropAreaRef} onDrop={_onDrop} className={` w-4 ${dragging ? 'border border-white border-dashed' : ''} ${ editmode ? 'md:block' : 'hidden'}`}>
        <div className="hidden" ref={dropAreaLabelRef}>{dragging && <DragOverTreeMenuItem>{dragging.label}</DragOverTreeMenuItem> || '-'}</div>
    </div>
}

function FullTreeMenuGraph({ siteGraph, customername, sitename, site, activateContextMenu, addArea, editmode, onOrderChange }) {

    const [expanded, setExpanded] = useState(false);
    const [dragging, setDragging] = useState(false);

    const onDrag = (e, data, lvl, graphNode) => {
        // console.log("onDrag data ", data);
        let invisibleImage = new Image(0, 0);
        invisibleImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='; // Transparent GIF
        e.dataTransfer.setDragImage(document.createElement('div'), 0, 0);
        setDragging({
            label: data,
            lvl: lvl,
            node: graphNode
        });
    }

    const onDragEnd = (e) => {
        // console.log("dropped", e);
        
    }

    const onDrop = (e, lvl, graphNode) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log(`Dropped! level: ${lvl} index: ${graphNode}`);
        onOrderChange({
            destinationLevel: lvl,
            destinationNode: graphNode || lvl,
            node: dragging.node 
        });
        setDragging(false);

        
    }

    const onEmptyDrop = (e) => {
        // console.log("empty dropped!!!");
        setDragging(false);
    }

    

    // console.log("15-12-2023 render")
    // console.log("")

    const levelColors = [
        'bg-blue-darker',
        'bg-blue-dark',
        'bg-blue',
        'bg-blue-light',
        'bg-blue-light/80',
        'bg-blue-light/60',
    ]

    const generations = [
        'first-gen',
        'second-gen',
        'third-gen',
        'fourth-gen',
        'fifth-gen',
        'sixth-gen'
    ]

    // const levels = [lvl1, lvl2, lvl3, lvl4];
    // console.log("15-12-2023 value of levels", levels);

    const generateLink = (graphNode) => {
        let link = ``;
        let currentNode = graphNode;
        let trials = 0;
        while(currentNode && trials < 1000) {
            let node = currentNode.originalNode;
            link = `/${node.get('name').split(' ').join('-').toLowerCase()}${link}`;
            currentNode = currentNode.parent;
            trials++;
        }
        if( trials >= 999) {
            // console.log("15-12-2023 infinite loop detected");
            throw new Error("15-12-2023 Infinite loop detected in generateLink");
        }
        return link;
    }

    /*
        @params nodeGraphNode: ConfigurationNode
    */
    const createItems = (nodeGraphNode, lvl = 0, activeTree = lvl == 0) => {
        let levels = siteGraph.getActiveLevelPaths();
        if(!nodeGraphNode) {
            let menu = [];
            menu.push(<TreeMenu expanded={expanded} bg={`${levelColors[lvl] || ''}`} key={`level-${lvl}`}>
                <TreeMenuItems>
                  
                    <DropArea dragging={dragging} editmode={editmode} onDrop={(e) => onDrop(e, lvl)} />
                    {editmode && <TreeMenuItem 
                        editmode={editmode} 
                        expanded={expanded} 
                        toggleExpanded={() => setExpanded((v) => !v)} 
                        level={generations[lvl]} 
                        onClick={() => addArea(levels.slice(0, lvl))}> + Add</TreeMenuItem>}
                </TreeMenuItems>
            </TreeMenu>)
            return menu;

        }
        
        let children = nodeGraphNode.children.filter((x => !x.originalNode.get('hidden') || editmode));
        // console.log("15-12-2023 value of activeTree", activeTree);
        // console.log("15-12-2023 value of lvl", lvl);
        // console.log("15-12-2023 children", children);
        // let isActive = activeTree;
        let subLevel = null;
        let lastActive = false;
        let menu = [];
        
        let lastLevel = false;
        menu.push(<TreeMenu expanded={expanded} bg={`${levelColors[lvl] || ''}`} key={`level-${lvl}`}>
            <TreeMenuItems>
                { children && children.map((child, index) => {
                    let node = child.originalNode;
                    let nodeIndex = child.index;
                    let nodestub = node.get('name').split(' ').join('-').toLowerCase();
                    
                    let level = levels[lvl]
                    let isActive = nodestub == level;
                    lastActive = isActive;
                    let hasSublevels = child.children && child.children.filter((x => !x.originalNode.get('hidden') || editmode)).length > 0
                    if(hasSublevels && isActive) {
                        subLevel = child;
                    } else if(isActive && !hasSublevels) {
                        lastLevel = true;
                    }
                    let linkString = generateLink(child);
                    return (<div className={`${lvl == 0 && 'pl-2 md:pl-0' || ''} md:flex md:flex-row`} key={`${lvl}-${index}`}>
                            <DropArea dragging={dragging} editmode={editmode} onDrop={(e) => onDrop(e, lvl, child)} />
                            <TreeMenuItem 
                                editmode={editmode} 
                                onDrag={(e, label) => onDrag(e, label, lvl, child)} 
                                onDragEnd={onDragEnd}  
                                expanded={expanded} 
                                toggleExpanded={() => setExpanded((v) => !v)} 
                                onContextMenu={(e) => activateContextMenu(node, levels.slice(0, lvl), nodeIndex, e)} 
                                level={generations[lvl]} 
                                active={isActive} 
                                item={node.set('link', `/customer/${customername}/site${linkString}`)} 
                                key={`/customer/${customername}/site${linkString}`}/>
                                { hasSublevels && isActive && <div className="block md:hidden">
                                    {createItems(child, lvl + 1, true)}
                                </div>}
                        </div>
                    )
                })}
                <DropArea dragging={dragging} editmode={editmode} onDrop={(e) => onDrop(e, lvl)} />
                {editmode && <TreeMenuItem 
                    editmode={editmode} 
                    expanded={expanded} 
                    toggleExpanded={() => setExpanded((v) => !v)} 
                    level={generations[lvl]} 
                    onClick={() => addArea(levels.slice(0, lvl))}> + Add</TreeMenuItem>}
            </TreeMenuItems>
        </TreeMenu>)
        if(subLevel) {
            menu.push(createItems(subLevel, lvl + 1, lastActive));
        }
        if(lastLevel && lvl < 5) {
            // console.log("can i expect another level?");
            menu.push(createItems(undefined, lvl + 1, lastActive));
        }
        return menu;


    }

    return <Fragment>
        <FallbackDrop onEmptyDrop={onEmptyDrop}>
            {createItems(siteGraph)}
        </FallbackDrop>
    </Fragment>


}

function FallbackDrop({ children, onEmptyDrop }) {

    const fallbackRef = useRef();

    useEffect(() => {

        const onDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.dropEffect = "move";
           
        }

        const onDrop = (e) => {
            // console.log("emtpty drop")
            onEmptyDrop();
        }

        
        
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', onDrop);

        return () => {
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', onDrop);
        }
    }, [])

    return <div ref={fallbackRef}>
        {children}
    </div>

}

function TreeMenuItems({ children }) {

    let className = `flex flex-col md:flex-row`;

    return <div className={className}>
        {children}
    </div>
}

function TreeMenu({ bg, children }) {

    const areaMenuRef = useRef();
    const [menuOverflowing, setMenuOverflowing] = useState(false);

    const scrollLeft = (e) => {
        areaMenuRef.current.scrollLeft -= 50;
        e.preventDefault();
    }

    const scrollRight = (e) => {
        // console.log("trying to scroll right");
        areaMenuRef.current.scrollLeft += 50;
        e.preventDefault();
        
    }

    let overflowingClassName = "";
    let overflowingInnerClassName = "";
    let overflowScrollButtons = null;
    // console.log("menuOverflowing", menuOverflowing);

    let genericClassForItems = "md:whitespace-nowrap text-sm px-1 py-1 md:py-1  cursor-pointer  md:rounded-t flex-grow-1 ";
    if(menuOverflowing) {
        overflowingInnerClassName = "p-r-20 ";
        overflowScrollButtons = <Fragment>
            <div className="flex-none flex flex-row top-0 h-full px-2 select-none">
                <div className={`${genericClassForItems} ${bg} cursor-pointer`} onClick={scrollLeft} >
                    <ArrowLeftCircleIcon className="h-6 w-6 text-white" />
                </div>
                <div className={`${genericClassForItems} ${bg} cursor-pointer`} onClick={scrollRight}>
                    <ArrowRightCircleIcon className="h-6 w-6 text-white" />
                </div>
            </div>
        </Fragment>
    }

    useLayoutEffect(() => {

        if(!menuOverflowing && areaMenuRef.current && areaMenuRef.current.scrollWidth > window.innerWidth){
            setMenuOverflowing(true);
        }

        if(menuOverflowing && areaMenuRef.current && areaMenuRef.current.scrollWidth <= window.innerWidth){
            setMenuOverflowing(false);
        }


    });

    let className = ``;

    return <div className={`w-full flex flex-row ${overflowingClassName} ${bg}`}>
        <div className={`h-full flex-initial overflow-x-hidden ${className} ${overflowingInnerClassName} `} ref={areaMenuRef}>
        
            {children}
        </div>
        {overflowScrollButtons}
    </div>

} 

function TreeMenuItem({ active, item, level, onClick, onContextMenu, toggle, children, expanded, toggleExpanded, onDrag, onDragEnd, editmode }) {

    const { customerSettings } = useCustomerSettings();
    const { userSettings } = useUserSettings();

    let rotationEnabled = customerSettings.get('rotationEnabled');
    rotationEnabled = rotationEnabled || userSettings.get('rotationEnabled') || false;


    let navigate = useNavigate();

    const toggleOrRedirect = (url, ignoreToggle) => {
        if (!expanded && !ignoreToggle){
           return toggleExpanded();
        }

        navigate(url);
        // toggleExpanded();

    }

    let className = `md:whitespace-nowrap text-sm px-2 py-2 md:py-1 md:mt-1 cursor-pointer ${editmode ? '' : 'md:mx-2'} md:rounded-t flex-grow-1 w-full md:max-w-min`;
    if(!expanded && !active) {
        className += ` hidden md:block`;

    }
    
    if (onClick) {
        return (
            <div className={`${className} ${level} ${active ? `selected ` : ``} order-md-0`} onContextMenu={onContextMenu}><a className="block w-full h-full" onClick={onClick}>{children}</a></div>
        )
    }

    const composedClassName = `${className} ${level} ${active ? `selected  ` : ``} order-md-0`;
    const itemLabel = `${item.get('name')}${item.get('hidden') && ' (-)' || ''}`

    return (
        <div draggable={true} onDragStart={(e) => onDrag(e, itemLabel)} onDragEnd={onDragEnd} className={composedClassName} onContextMenu={onContextMenu}>
            <a className="block md:hidden h-full w-full" onClick={() => toggleOrRedirect(item.get('link'), false)} to={item.get('link')}>
              {item.get('name')}{item.get('hidden') && ' (-)'}
            </a>
            <a className="hidden md:flex h-full w-full flex-row gap-1 items-center" onClick={() => toggleOrRedirect(item.get('link'), true)} to={item.get('link')}>
                { item.get('rotating') && rotationEnabled && <PlayCircleIcon className="w-4 h-4 text-white"/> || "" }
                <span>{item.get('name')}{item.get('hidden') && ' (-)'}</span>
            </a>
        </div>
    )
}

function DragOverTreeMenuItem({ children }) {
    
        return (
            <div className="md:whitespace-nowrap text-sm px-2 py-2 md:py-1 md:mt-1 cursor-pointer md:mx-2 md:rounded-t flex-grow-1 w-full md:max-w-min text-white">
                <a className="block w-full h-full">{children}</a>
            </div>
        )
}

function prepareBackdropLayersForMerge(backdropLayers, highestId, backdropReference) {
    let counter = highestId;
    return backdropLayers.map((layer) => {
        let { nodes, ...rest } = layer;
        return {
            ...rest,
            backdropref: backdropReference,
            nodes: nodes.map((node) => {
                return {
                    ...node,
                    backdropref: backdropReference,
                    id: highestId ? ++counter : node.id
                }
            })
        }
    })
}

function removeBackdropFromAreaLayers(areaLayers, oldBackdropReference) {
    let newLayers = areaLayers.map((layer) => {
        let { nodes, ...rest } = layer;
        return {
            ...rest,
            nodes: nodes.filter((node) => {
                return node.backdropref != oldBackdropReference;
            })
        }
    })
    return newLayers.filter((layer) => {
        if(layer.backdropref && layer.backdropref != oldBackdropReference) {
            return true;
        }
        return (layer.nodes || []).length > 0;
    })
}

function mergeBackdropLayersWithAreaLayers(areaLayers, backdropLayers) {
    let mergedLayers = [...areaLayers];
    mergedLayers.unshift(...backdropLayers);
    return mergedLayers;
}

function calculateHighestIdFromAreaLayers(areaLayers) {
    return areaLayers.reduce((acc, layer) => {
        return layer.nodes.reduce((acc, node) => {
            return node.id > acc ? node.id : acc;
        }, acc);
    }, 0);
}
