import React, { useState } from 'react';
import { getComponents } from './Actions';
import Select from 'Controls/Form/Select';
import axios from 'axios';
import FileInput from 'Controls/Form/FileInput';
import Input from 'Controls/Form/Input';
import Immutable, { List, Map } from 'immutable';
import { uploadComponent, saveCustomerComponents } from '../Actions';
import { updateSelectedComponentCategory } from './Actions';
import { withUserSettings } from '../../../../Context/UserSettings';
import { withCustomerSettings } from '../../../../Context/CustomerSettings';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function ComponentSelectionDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} className="min-w-[60vw] max-w-[60vw]">
        <ComponentSelectionDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}


class ComponentSelectionDialogPanel extends React.Component {
    constructor(props) {
        super(props);
        let { customer, site, selectedCategory } = this.props;

        this.state = {
            selectedcategory: selectedCategory || "general",
            currentNode: props.selecteditem,
            sitebasepath: `/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}`
        }
    }

    async reloadComponents() {
        let { customer, site } = this.props;
        let { sitebasepath } = this.state;

        let components = await getComponents();
        let siteComponentsResponse = await axios.get(`${sitebasepath}_components_components.json`);
        let siteComponents = siteComponentsResponse.data;

        components['site-specific'] = {
            name: 'site-specific',
            description: 'Site specific',
            components: siteComponents
        }

        this.setState({
            components: Immutable.fromJS(components)
        });
    }

    async componentDidMount() {
        let { customer, site, selectedCategory } = this.props;
        let { sitebasepath } = this.state;

        let components = await getComponents();
        let siteComponentsResponse = await axios.get(`${sitebasepath}_components_components.json`);
        let siteComponents = siteComponentsResponse.data;

        components['site-specific'] = {
            name: 'site-specific',
            description: 'Site specific',
            components: siteComponents
        }

        this.setState({
            selectedcategory: selectedCategory || "general",
            components: Immutable.fromJS(components)
        });
    }

    componentDidUpdate(oldProps, oldState) {
        let { state } = this.state;
    }

    categoryChange(value) {
        updateSelectedComponentCategory(value);
        this.setState(({ selectedcategory }) => ({
            selectedcategory: value
        }))
    }

    componentSelected(component) {
        let { currentNode } = this.state;
        let { placeNode } = this.props;

        let { userSettings } = this.props.userSettings;
        let { customerSettings } = this.props.customerSettings;

        if (placeNode) {

            let defaultFontSize, defaultTextboxYOffset, defaultTextboxXOffset = undefined;
            if(customerSettings && customerSettings.get('defaultFontSize')) {
                defaultFontSize = customerSettings.get('defaultFontSize');
            }
    
            if(userSettings && userSettings.get('defaultFontSize')) {
                defaultFontSize = userSettings.get('defaultFontSize') || defaultFontSize;
            }

            if(customerSettings && customerSettings.get('defaultTextboxYOffset')) {
                defaultTextboxYOffset = customerSettings.get('defaultTextboxYOffset') || defaultTextboxYOffset;
            }

            if(customerSettings && customerSettings.get('defaultTextboxXOffset')) {
                defaultTextboxXOffset = customerSettings.get('defaultTextboxYOffset') || defaultTextboxXOffset;
            }

            if(userSettings && userSettings.get('defaultTextboxYOffset')) {
                defaultTextboxYOffset = userSettings.get('defaultTextboxYOffset') || defaultTextboxYOffset;
            }

            if(userSettings && userSettings.get('defaultTextboxXOffset')) {
                defaultTextboxXOffset = userSettings.get('defaultTextboxYOffset') || defaultTextboxXOffset;
            }
    
            switch(component.get('name')) {
                case 'label':
                case 'striped-text':
                case 'text':
                case 'text-multiline':
                case 'date-time-text':
                    currentNode = currentNode.setIn(['configuration','style', 'fontsize'], defaultFontSize); 
                    currentNode = currentNode.setIn(['configuration', 'style', 'boxYOffset'], defaultTextboxYOffset);
                    currentNode = currentNode.setIn(['configuration', 'style', 'boxXOffset'], defaultTextboxXOffset);
                break;
            }


            if (component.get('name') == 'label') {
                placeNode(currentNode.set('type', 'animation').set('component', component).setIn(['configuration','displaystyle'], 'boxed'));
                return;
            }

            if (component.get('name') == 'striped-text') {
                placeNode(currentNode.set('type', 'animation').set('component', component).setIn(['configuration','displaystyle'], 'striped'));
                return;
            }

            placeNode(currentNode.set('type', 'animation').set('component', component));
        }
    }

    getPath(component) {
        let { sitebasepath } = this.state;

        if (component.get('thumbnail')) {
            if (component.get('thumbnail').indexOf('~') == 0) {
                return `${sitebasepath}_thumbnails_${component.get('thumbnail').replace('~', '')}.svg`;
            }
            return `/files/editor/thumbnails_${component.get('thumbnail')}.svg`;
        }
        if (component.get('name').indexOf('~') == 0) {
            return `${sitebasepath}_components_${component.get('name').replace('~', '')}.svg`;
        }
        return `/files/editor/components_${component.get("name")}.svg`;
    }

    render() {
        let { customer, site } = this.props;
        let { selectedcategory, currentNode, components } = this.state;

        return (
            <DialogContent className="min-w-[60vw] min-h-[30vh]">
                <DialogHeader>
                    <DialogTitle>Add component</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="w-1/3">
                        <Select onChange={this.categoryChange.bind(this)} options={(components || []).reduce((list, component) => {
                            list.push({
                                value: component.get('name'),
                                text: component.get('description')
                            });
                            return list;
                        }, []).sort((a,b) => (a.text || "").localeCompare(b.text))} value={selectedcategory} />
                    </div>
                    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-8 xl:gap-x-4">
                        {components && components.getIn([selectedcategory, "components"]).map((component, index) => (
                            <li key={index} onClick={this.componentSelected.bind(this, component)}>
                            <div className="group p-2 block rounded-lg bg-gray-100 focus-within:ring-2 hover:ring-4 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                <img src={this.getPath(component)} alt="" className="object-scale-down h-24 pointer-events-none group-hover:opacity-75" height="100" width="100"  />
                            </div>
                            <p className="mt-2 block text-sm text-gray-900 pointer-events-none">{component.get('description')}</p>
                            </li>
                        ))}
                    </ul>
                    <UploadComponentInput onUploadFinish={() => this.reloadComponents()} components={(components || List()).getIn([selectedcategory, 'components']) || List()} customer={customer.get('name').toLcHyphen()} site={site.get('name')} showUploadField={selectedcategory == 'site-specific'}/>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={this.props.onClose}>Close</Button></div>
                </DialogFooter>

            </DialogContent>


           
        )
    }
}

export default withCustomerSettings(withUserSettings(ComponentSelectionDialog));


function UploadComponentInput({ showUploadField, customer, site, components, onUploadFinish }) {

    const [file, setFile] = useState(undefined);
    const [directory, setDirectory] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [isUploading, setIsUploading] = useState(false);

    const uploadFile = () => {
        let filename = name.toLowerCase().split(' ').join('-');
        let directoryName = (directory || "").split(' ').join('-');
        let extension = (file.name || "").replace(/.*?(\.\w*$)/,'$1');
        filename = directoryName ? `${directoryName}_${filename}` : filename;
        let filenameWithExtension = filename + extension;
        setIsUploading(true);
        uploadComponent(customer, site, filenameWithExtension, file).then(() => {
            components = components.push(Map({
                "name": `~${filename}`,
                "type": "image",
                "componentid": "layer1",
                "description": name,
                 "layer": "layer1user",
                "stateids": []
            }));
            return saveCustomerComponents(customer, site, components)
        }).then(() => {
            setIsUploading(false);
            setFile(undefined);
            setDirectory(undefined);
            setName(undefined);
            onUploadFinish && onUploadFinish();
        })
    }

    if(!showUploadField) {
        return null;
    }

    if(isUploading) {
        return <React.Fragment>Uploading...</React.Fragment>
    }

    return <React.Fragment>
        <div className="grid grid-cols-16" style={{background:"#f8f8f8",padding:"10px",marginTop:"10px"}}>

            <h4 className="col-span-16">Upload new component</h4>

            <div className="col-span-12 md:col-span-4">
                <FileInput style={{height:"auto"}} onChange={(file) => setFile(file)} />
            </div>

            <div className="col-span-12 md:col-span-4">
                <Input type="text" onChange={(value) => setName(value)} value={name} placeholder="Name"  />
            </div>
            <div className="col-span-12 md:col-span-4">
                <Input type="text" onChange={(value) => setDirectory(value)} value={directory} placeholder="Directory name" />
            </div>
            <div className="col-span-12 md:col-span-4">
                 <Button role="success" onClick={() => { uploadFile() }}>Upload</Button>
            </div>

            
            
            
           
        </div>
    </React.Fragment>

}


//<img className="p-4 w-100 mw-100 mh-100" src={this.getPath(component)} />