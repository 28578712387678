import { useBMS } from "../../../../Context/BMS";
import { useActiveAreaDialog } from "../../../../AreaDialog/Context";

export default function useAreaCustomer() {

    const { customer:mainCustomer } = useBMS();
    const { customer:dialogCustomer } = useActiveAreaDialog()

    const { isAreaInDialog } = useActiveAreaDialog();

    return { customer: isAreaInDialog ? dialogCustomer : mainCustomer };

}

export function withAreaCustomer(Component) {
    return (props) => {
        const areaCustomer = useAreaCustomer();
        return <Component {...areaCustomer} {...props} />
    }
}