import * as d3 from 'd3';
import {fromJS} from 'immutable';
import { displayTooltip, setDefs, drawPath } from '../Utils';


export default function Path(options) {
    return new Promise((resolve, reject) => {
        let rotation = options.node.getIn(['configuration','rotation'], 0);

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`)
        
        let createPath = (placementElement, options) => {
            let w = `${options.node.getIn(['configuration', 'width'], 20)}`;
            let l = `${options.node.getIn(['configuration', 'length'], 20)}`;
            let p = `m 0,0 -${l},0 c -${w/2} ,${w/4}  -${w/2} ,${w/4 * 3}  -0,${w} l ${l},0 z`;

            let path = options.node.getIn(['configuration', 'path'], p);

            let idPath = options.node.get('id');

            let grads = fromJS([
                { "stop": "0%", "colour": "#6B6B6B" },
                { "stop": "50%", "colour": "#EBEBEB" },
                { "stop": "100%", "colour": "#6B6B6B" }]);

            options.node.getIn(['configuration', 'gradient'], false ) && setDefs(placementElement, idPath,
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads'], grads));
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradient']), idPath, options.node.getIn(['configuration', 'style', 'background-color'], 'lightgrey'), 0);
        } 
         
        let placementElement = createPlacementElement(options);
        var shape;

        shape =  createPath(placementElement, options);

        let tooltip = displayTooltip(placementElement, options);
        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;

                if (value != oldvalue) {
                    oldvalue = value;       
                    shape.attr('fill', options.node.getIn(['configuration', 'style', 'background-color'], '#222222'));
                }    
            },
            remove: () => {
                d3.select(placementElement).remove();
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                options.node = node;
                placementElement.remove();
                placementElement = createPlacementElement(options);
            //    shapetype = options.node.getIn(['configuration', 'shapetype']); 

            createPath(placementElement, options);
            //    defs(placementElement, options);   
            //    shape =  createPolygonShape(placementElement, options);

                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}