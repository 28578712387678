import React, {useState, useEffect} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ServiceBus from 'Services/ServiceBus';
import Swal from 'sweetalert2';

import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function DateTimeSettingsDialog({ selectedOutstation, onClose, ...props }) {
    return <Dialog opened={!!selectedOutstation} onClose={onClose} >
        <DateTimeSettingsDialogPanel selectedOutstation={selectedOutstation} onClose={onClose} {...props}/>
    </Dialog>
}


function DateTimeSettingsDialogPanel({selectedOutstation, customer, onClose}) {
    //const [outstation, setOutstation] = useState(selectedOutstation);
    const [dateTime, setDateTime] = useState(new Date());

    useEffect(() => {
        let unsubscribeGetPanelTimeResponse = ServiceBus.on('511-51', processGetPanelTimeResponse);
        let unsubscribeSetPanelTimeResposne = ServiceBus.on('512-51', processSetPanelTimeResponse);
        getDateTime();

        return () => {
            // ServiceBus.off('511-51', processGetPanelTimeResponse);
            unsubscribeGetPanelTimeResponse();
            unsubscribeSetPanelTimeResposne();
            // ServiceBus.off('512-51', processSetPanelTimeResponse);
        };
    }, []);

    const CustomTimeInput = ({ date, value, onChange }) => (
        <input
          value={value}
          onChange={e => onChange(e.target.value)}
          style={{ border: "solid 1px pink" }}
        />
      );

    const getDateTime = () => {
        let request = {
            trackMessage: true,
            m_level:50,
            m_communicNum:511,
            m_subsystemNumber: selectedOutstation.get('subsystem'),
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr'
        } 

        let comm = {
            m_lanIndex: selectedOutstation.get('lannumber'),
            m_outstationNumber: selectedOutstation.get('outnumber')
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const getPin = (subsystemNumber) => {
        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
 
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }

    const sendDateTime = (dateTime) => {
        let request = {
            trackMessage:true,
            m_level:50,
            m_communicNum:512,
            m_subsystemNumber: selectedOutstation.get('subsystem'),
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr'
        } 

        let comm = {
            m_pin: getPin(selectedOutstation.get('subsystem')),
            m_lanIndex: selectedOutstation.get('lannumber'),
            m_outstationNumber: selectedOutstation.get('outnumber'),
            m_year: dateTime.getFullYear(), 
            m_month: dateTime.getMonth() + 1, 
            m_day: dateTime.getDate(),
            m_hour: dateTime.getHours(),
            m_minutes: dateTime.getMinutes()
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const processGetPanelTimeResponse = (message) => {
        setDateTime(new Date(message.m_year, message.m_month - 1, message.m_day, message.m_hour, message.m_minutes));
    }

    const processSetPanelTimeResponse = (message) => {
        if (message.m_errorNumber == 0) {
            Swal.fire("Success", "Date & Time Changed Successfully", "success");
            getDateTime(); }
            else Swal.fire("Error", "Error setting Date & Time", "error");
    }

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Date / Time Settings</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="grid grid-cols-16">
                    <div className="col-span-6 px-2">
                        <label className="block mb-4">Panel Date/Time</label>
                        <DatePicker className="my-2"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            placeholderText="Click to select a date"
                            selected={dateTime}
                            onChange={date => setDateTime(date)}
                            isClearable
                            showTimeInput
                        />
                        <Button role="primary" onClick={ () => sendDateTime(dateTime)}>Set Date/Time</Button>
                    </div>
                    <div className="col-span-8 px-4">
                        <label className="block mb-4">PC Date / Time</label>
                        <DatePicker className="my-2"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            selected={new Date()}
                            disabled
                        />
                        <Button role="primary" onClick={ () => sendDateTime(new Date())}>Set To PC Date/Time</Button>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={ () => onClose()}>Close</Button>
            </DialogFooter>
        </DialogContent>    
        
    );
}

export default DateTimeSettingsDialog;