import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/solid';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import AlarmGroupSetupDialog from './AlarmGroupSetupDialog';
import Button from 'Controls/Button/UserDefined';

import {getGroups, saveGroup, deleteGroup, getSiteNotificationSettings, saveSiteNotificationSettings} from '../Actions';
import { withBMS } from 'BmsView/Context/BMS';

class AlarmSetup extends React.Component {
    constructor(props) {
        super(props);

        let {customer} = this.props;      
        
        this.state = {
            currentcustomer: customer,
            currentgroup: undefined,
            sitenotificationsettings: Immutable.Map({
                'smtpsetup': 0,
                'smssetup': 0,
                'smtpfromaddress': 'admin@bmsview.com'
            })
        };
    }
    
    componentDidMount() {
        let { customer } = this.props;

        this.getSiteNotificationSettings();
        this.getGroups();
    }

    getSiteNotificationSettings() {
        let {customer} = this.props;

        getSiteNotificationSettings(  `${customer.get('name').toLowerCase().replace(/ /g,"")}`,
                    `${customer.get('ipaddress').toLowerCase()}`).then((data) => {
            let settings = Immutable.fromJS(data);
            if ( settings && settings.get(0)) {
                this.setState({
                    sitenotificationsettings: settings.get(0)
                }); 
            }       
        });
    }

    saveSiteNotificationSettings(group) {
        let {customer} = this.props;
        let {sitenotificationsettings} = this.state;

        saveSiteNotificationSettings(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(), sitenotificationsettings).then(() => {
            Swal.fire("Success", "The settings have been successfully saved", "success");
            if (!sitenotificationsettings.get('_id') ) this.getSiteNotificationSettings();
        }).catch((err) => {
            Swal.fire("Error", "Error saving settings", "error");
        });
    }

    getGroups() {
        let {customer} = this.props;

        getGroups(  `${customer.get('name').toLowerCase().replace(/ /g,"")}`,
                    `${customer.get('ipaddress').toLowerCase()}`,'alarmnotificationgroup').then((data) => {
                        
            this.setState({
                groups: Immutable.fromJS(data.map((group) => {
                    return group;
                }))
            });    
        });
    }

    saveGroup(newgroup) {
        let {customer} = this.props;
        let {groups} = this.state;

        let existinggroup = (groups || []).filter((group) => {
            if ( group.get('notificationgroupname') == newgroup.get('notificationgroupname')) {
                return group;
            }    
        });

        if (existinggroup.get(0) && !newgroup.get('_id')) {
            Swal.fire("Error", "Duplicate group name", "error");
            return;
        }    

        saveGroup(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(), 'alarmnotificationgroup', newgroup).then(() => {
            Swal.fire("Success", "The group has been successfully saved", "success");
            this.getGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error adding group", "error");
        });
    }

    removeGroup(group) {
        let {customer} = this.props;

        deleteGroup(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(),'alarmnotificationgroup', group.get('_id')).then(() => {
            Swal.fire("Success", "The group has been successfully removed", "success");
            this.getGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error removing group", "error");
        });
    }

    addGroup() {
        let {customer} = this.props;

        this.setState({
            selecteditem: Immutable.Map({
                'notificationgroupname': 'Alarm Group',
                'notificationtype': 1,
                'segregationtype': 0,
                'sitenameheader':customer.get('name')
            })
        });
    }

    selectGroup(group) {
        this.setState({
            selecteditem: group
        });
    }

    clearSelection() {
        this.setState({
            selecteditem: undefined
        });
    }

    changeInput(field, transform, value) {
        let { sitenotificationsettings } = this.state;

        if (transform) {
            value = transform(value);
        }

        this.setState({
            sitenotificationsettings: sitenotificationsettings.set(field, value)
        });
    }

    render() {
        let {groups, currentcustomer,sitenotificationsettings} = this.state;

        let GroupTypes = ['Email', 'SMS'];
        let SmtpOptions = ['Disabled', 'Default', 'External'];
        let SmsOptions = ['Disabled', 'Default', 'External'];
        let AuthorisationOptions = ['No', 'Yes'];
        let SegregationTypes = ['All Alarms', 'All Points', 'Group Points', 'Subsystem Point Alarms'];

        return (
            <div className="container mx-auto my-4">
                <AlarmGroupSetupDialog selecteditem={this.state.selecteditem} customer={currentcustomer} onSave={this.saveGroup.bind(this)} onClose={this.clearSelection.bind(this)} />
                <h2>Alarm Retransmission Setup</h2>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-3">
                        <Input className="" disabled={true} label="Customer" value={currentcustomer.get('name')}/>
                    </div>
                    
                </div>
                <h4>Site Notification Settings</h4>
                <div className="mt-4">
                    <h6>Email SMTP</h6>
                </div>
                <div className="grid grid-cols-16">
                    <div className="col-span=16 md:col-span-3">
                        <Select
                            value={sitenotificationsettings.get('smtpsetup')}
                            onChange={this.changeInput.bind(this, 'smtpsetup', parseInt)}
                            options={[{ name: "Select the type.", groupeid: -1 }, ...SmtpOptions.map((type,index) => {
                                return {
                                    text: type,
                                    value: index
                                }
                            })]}
                        />
                    </div>  
                </div>
                
                {sitenotificationsettings.get('smtpsetup') == 2 && 
                    <div> 
                        <div className="grid grid-cols-16 gap-2">
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Server Address" value={sitenotificationsettings.get('smtpserveraddress')} onChange={this.changeInput.bind(this, 'smtpserveraddress', undefined)}/>
                            </div>
                            <div className="col-span-16 md:col-span-2">
                                <Input label="Port Number" value={sitenotificationsettings.get('smtpportnumber')} onChange={this.changeInput.bind(this, 'smtpportnumber', parseInt)}/>
                            </div>
                            <div className="col-span-16 md:col-span-3">
                                <Input label="Username" value={sitenotificationsettings.get('smtpusername')} onChange={this.changeInput.bind(this, 'smtpusername', undefined)}/>
                            </div>    
                            <div className="col-span-16 md:col-span-3">    
                                <Input label="Password" type="password" value={sitenotificationsettings.get('smtppassword')} onChange={this.changeInput.bind(this, 'smtppassword', undefined)}/>
                            </div>
                            <div className="col-span-16 md:col-span-3">    
                                <Input label="From Address"  value={sitenotificationsettings.get('smtpfromaddress', 'admin@bmsview.com')} onChange={this.changeInput.bind(this, 'smtpfromaddress', undefined)}/>
                            </div>
                            <div className="col-span-16 md:col-span-3">
                                <Select
                                    label="Authorisation Required"
                                    value={sitenotificationsettings.get('smtpisauthorisationrequired')}
                                    onChange={this.changeInput.bind(this, 'smtpisauthorisationrequired', parseInt)}
                                    options={[{ name: "Authorisation", groupeid: -1 }, ...AuthorisationOptions.map((type,index) => {
                                        return {
                                            text: type,
                                            value: index
                                        } 
                                    })]}
                                />
                            </div> 
                        </div>  
                    </div>                  
                }  
                {sitenotificationsettings.get('smtpsetup') == 1 && 
                    <div> 
                        <div className="grid grid-cols-16">
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Authorisation Key" type="password" value={sitenotificationsettings.get('keysmtp')} onChange={this.changeInput.bind(this, 'keysmtp', undefined)}/>
                            </div>    
                        </div> 
                    </div>                  
                } 
                <div className="mt-4">
                    <h6>Text SMS</h6>
                </div>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-3">
                        <Select
                            value={sitenotificationsettings.get('smssetup')}
                            onChange={this.changeInput.bind(this, 'smssetup', parseInt)}
                            options={[{ name: "Select the type.", groupeid: -1 }, ...SmsOptions.map((type,index) => {
                                return {
                                    text: type,
                                    value: index
                                }
                            })]}
                        />
                    </div> 
                </div>
                
                {sitenotificationsettings.get('smssetup') == 2 && 
                    <div> 
                        <div className="grid grid-cols-16">
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Server Address" value={sitenotificationsettings.get('smsserveraddress')} onChange={this.changeInput.bind(this, 'smsserveraddress', undefined)}/>
                            </div>
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Username" value={sitenotificationsettings.get('smsusername')} onChange={this.changeInput.bind(this, 'smsusername', undefined)}/>
                            </div>    
                            <div className="col-span-16 md:col-span-4">    
                                <Input label="Password" type="password" 
                                value={sitenotificationsettings.get('smspassword')} onChange={this.changeInput.bind(this, 'smspassword', undefined)}/>
                            </div>
                        </div> 
                    </div>                  
                }
                {sitenotificationsettings.get('smssetup') == 1 && 
                    <div> 
                        <div className="grid grid-cols-16">
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Authorisation Key" type="password" value={sitenotificationsettings.get('keysms')} onChange={this.changeInput.bind(this, 'keysms', undefined)}/>
                            </div>    
                        </div> 
                    </div>                  
                } 
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-4 px-2 py-2">
                        <Button role="success" className="w-100" onClick={this.saveSiteNotificationSettings.bind(this)}>Save Notification Settings</Button>
                    </div>
                </div>
                
                <div className="mt-4">
                    <h4>Alarm Group List</h4>
                </div>
                <div>
                    <Grid>
                        <Grid.Header>
                            <Grid.Column classes="col-span-16 md:col-span-3">Name</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-2">Type</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-3">Segregation Type</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-4">Message Header</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-4" name="add" type="complex">
                                <Grid.Controls>
                                    <Grid.Control>
                                        <PlusIcon className="w-6 text-white cursor-pointer" title="Add group" onClick={this.addGroup.bind(this)} />
                                    </Grid.Control>
                                </Grid.Controls>
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            {(groups || []).map((group, index) => {
                                return (
                                    <Grid.Row className={"hover:bg-gray-200 group"} key={index}>
                                        <Grid.Column classes="col-span-16 md:col-span-3">{group.get('notificationgroupname')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">{GroupTypes[group.get('notificationtype') - 1]}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-3">{SegregationTypes[group.get('segregationtype')]}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4">{group.get('sitenameheader')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4">
                                            <Grid.Controls>
                                                <Grid.Control><PencilSquareIcon className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-blue w-6" onClick={this.selectGroup.bind(this, group)} /></Grid.Control>
                                                <Grid.Control><TrashIcon className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500" onClick={this.removeGroup.bind(this, group)} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid.Data>
                    </Grid>
                </div>
            </div>
        );
    }
}

// export default connect((state) => ({
//     customer: state.get('customer')
// }))(AlarmSetup);

export default withBMS(AlarmSetup);