import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import { Provider } from 'react-redux';
import Store from './Store';
import './Reducers';
import Authentication from 'Authentication';
import { ToastContainer, toast } from 'react-toastify';// ES6
import BmsView from './BmsView';
import Clipboard from './Context/Clipboard';
import AreaMenuContext from './Context/AreaMenu';
import AreaContext from './Context/Area';
import EditModeContext from './Context/EditMode';
import UserSettingsContext from './Context/UserSettings';
import CustomerSettingsContext from './Context/CustomerSettings';
import DesktopService from './Services/DesktopService';
import BMSWrapper from "./BmsView/Context/BMS";
import { ApplicationRouter } from "./BmsView";
import { Outlet, RouterProvider } from "react-router-dom";
import MainLoader from "./BmsView/MainLoader";
import BMSView from "./BmsView";
import TransparentLoaderProvider from "./Context/TransparentLoader";
import WindowProvider from "./Context/Window";
import SystemSettingsProvider from "./Context/SystemSettings";
import AreaDialogProvider from "./BmsView/AreaDialog/Context";
import AreaDialog from "./BmsView/AreaDialog";
// toast.configure();
window.store = Store;

String.prototype.toLcHyphen = function () {
    return this.toLowerCase().split(' ').join('-')
}

window.onload = function () {

    const container =  document.getElementById("app");
    const root = ReactDOM.createRoot(container);

    DesktopService.initialize();


    root.render((
        <Provider store={Store}>
            <WindowProvider>
            <TransparentLoaderProvider>
                    <BMSWrapper>
                        
                    
                        <SystemSettingsProvider>
                            <CustomerSettingsContext>
                                <UserSettingsContext>
                                    <Clipboard>
                                        {/* <AreaMenuContext>
                                            <AreaContext> */}
                                            <AreaDialogProvider>
                                                <EditModeContext>
                                                <Suspense fallback={<MainLoader />}>
                                                    <RouterProvider router={ApplicationRouter}>
                                                        <Outlet />
                                                       
                                                    </RouterProvider>
                                             
                                                </Suspense>
                                                </EditModeContext>
                                                </AreaDialogProvider>
                                            {/* </AreaContext>
                                        </AreaMenuContext> */}
                                        <ToastContainer />
                                    </Clipboard>
                                </UserSettingsContext>
                            </CustomerSettingsContext>
                        </SystemSettingsProvider>
                        
                        
                    
                    </BMSWrapper>
                </TransparentLoaderProvider>
            </WindowProvider>
            
        </Provider>

    ));
}