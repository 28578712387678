import axios from 'axios';
import Store from 'Store';
import * as d3 from 'd3';

export function saveArea(components, file){
    // console.log('save :' + file)
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${file}`, components).then((response) => {
            // Store.dispatch({
            //     type: 'AREA_READY',
            //     data: response.data
            // });
            resolve();
        }).catch(reject);
    });
}

export function renameArea(file, dest, noRefresh = false){
    return new Promise((resolve, reject) => {
            axios.post(`/files/customers/${file}/rename`, {'dest' : `${dest}`}).then((response) => {
            // if(!noRefresh) {
            //     Store.dispatch({
            //         type: 'AREA_READY',
            //         data: response.data
            //     });
            // }
            resolve();
        }).catch(reject);
    });
}


export async function convertAreaToV2(source, destination, backdropUrl, customer, site) {
    // console.clear();
    // console.log("SHOULD HAVE CALLED CONVERT AREA TO V2")
    // console.log("backdropUrl", backdropUrl);

    let response = await axios.get(`/files/customers/${source}`);
    let toConvert = response.data;
    let svgLayerFiles = {};
    if(backdropUrl) {
        let xml = await d3.xml(backdropUrl);

    
        const svgElement = document.createElement('div');
        const originalSvg = xml.documentElement;
        const originalWidth = originalSvg.getAttribute('width') || '100%';
        const originalHeight = originalSvg.getAttribute('height') || '100%';
    
        svgElement.appendChild(originalSvg.cloneNode(true));
    
        const svgLayers = Array.from(svgElement.children[0].children).filter(
            (layer) => layer.tagName === 'g' || layer.tagName === 'svg:g'
        );
    
        
    
        for(let i = 0; i < svgLayers.length; i++){
            let layer = svgLayers[i];
            let index = i;
            const layerId = layer.getAttribute('id') || `layer-${index + 1}`;
            // console.log("processing layer layerId", layerId)
    
    
            
            const layerSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            layerSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
            layerSvg.setAttribute('version', '1.1');
            layerSvg.setAttribute('width', originalWidth);
            layerSvg.setAttribute('height', originalHeight);
    
            const svgDefs = svgElement.children[0].querySelector('defs');
            if (svgDefs) {
                const layerDefs = svgDefs.cloneNode(true);
                layerSvg.appendChild(layerDefs);
            }
    
            layer.setAttribute('id', layerId);
            layerSvg.appendChild(layer.cloneNode(true));
    
            const svgData = new XMLSerializer().serializeToString(layerSvg);
            const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    
            svgLayerFiles[layerId] = `${backdropUrl.replace('backdrops_',`backdrops_v2conversion_${layerId}_`)}`;
    
            const formData = new FormData();
            formData.append('file', svgBlob, `${layerId}.svg`);
    
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            await axios.post(`${svgLayerFiles[layerId]}/upload`, formData, config)
    
    
            // const downloadLink = document.createElement('a');
            // downloadLink.href = URL.createObjectURL(svgBlob);
            // downloadLink.download = `${layerId}.svg`;
            // downloadLink.click();
        }
    } else {
        svgLayerFiles["layer1"] = "";
        svgLayerFiles["layer1user"] = "";
        svgLayerFiles["layer2"] = "";
        svgLayerFiles["layer2user"] = "";
    }
    
    // console.log("reading posting");
    // console.log(svgLayerFiles);

    let newLayers = [];
    let id = 1;
    Object.keys(svgLayerFiles).forEach((layer, index) => {
        let layerComponents = toConvert.filter((component) => component.component.layer === layer);
        let newLayer = {
            "name": layer,
            "nodes": [
                {
                    "id": id++,
                    "name": "backdrop-component",
                    "type": "layer",
                    "component": {
                        "name": "backdrop-component",
                        "type": "layer",
                    },
                    "url": svgLayerFiles[layer]
                }
            ]
        }
        // console.log("layerComponents", layerComponents);



        layerComponents.forEach((component) => {
            component.layer = `layer`;
            newLayer.nodes.push({...component, id: id++ });
        });

        newLayers.push(newLayer);
        
    })

    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${destination}`, newLayers).then((response) => {
            // Store.dispatch({
            //     type: 'AREA_READY',
            //     data: response.data
            // });
            resolve();
        }).catch(reject);
    });
    // svgLayers.forEach((layer, index) => {
        
    // });

}

export function duplicateArea(file, dest, noRefresh = false){
    return new Promise((resolve, reject) => {
            axios.post(`/files/customers/${file}/copy`, {'dest' : `${dest}`}).then((response) => {
            // if(!noRefresh) {
            //     Store.dispatch({
            //         type: 'AREA_READY',
            //         data: response.data
            //     });
            // }
            resolve();
        }).catch(reject);
    });
}

// export async function duplicateArea(source, destination, noRefresh = false){
//     let response = await axios.get(`/files/customers/${source}`);
//     let toduplicate = response.data;

//     return new Promise((resolve, reject) => {
//         axios.post(`/files/customers/${destination}`, toduplicate).then((response) => {
//             if(!noRefresh) {
//                 Store.dispatch({
//                     type: 'AREA_READY',
//                     data: response.data
//                 });
//             }
//             resolve();
//         }).catch(reject);
//     });
// }

export async function copyBackdropToArea(source, destination){
    let response = await axios.get(`/files/editor/backdrops-v2_${source}`);
    let toduplicate = response.data;

    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${destination}`, toduplicate).then((response) => {
            // Store.dispatch({
            //     type: 'AREA_READY',
            //     data: response.data
            // });
            resolve();
        }).catch(reject);
    });
}

/*
export async function duplicateArea(source, destination){
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${file}`, components).then((response) => {
            Store.dispatch({
                type: 'AREA_READY',
                data: response.data
            });
            resolve();
        }).catch(reject);
    });
}*/

export function getArea(file){
    let abortController = new AbortController();
    return { 
        promise: new Promise((resolve, reject) => {
            axios.get(`/files/customers/${file}`, { signal: abortController.signal }).then((response) => {
                // Store.dispatch({
                //     type: 'AREA_READY',
                //     data: response.data
                // });
                resolve(response.data);
            }).catch(reject);
        }),
        abortController: abortController
    };
}

export function getTemplate(file){
    return new Promise((resolve, reject) => {
        axios.get(`/files/customers/${file}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getComponents(){
    return new Promise((resolve, reject) => {
        axios.get('/files/editor/components.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}


export function getComponentsV2(){
    return new Promise((resolve, reject) => {
        axios.get('/files/editor/components-v2.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

/*
export function getPoints(){
    return new Promise((resolve, reject) => {
        axios.get('/api/points?database=demointegrator&connection=demo-site').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}*/

export function updateNodeConfiguration(nodeconfiguration, index){
    Store.dispatch({
        type: 'UPDATE_NODE_CONFIGURATION',
        data: nodeconfiguration,
        index: index
    });
}

export function updateSelectedComponentCategory(selectedCategory) {
    Store.dispatch({
        type: 'UPDATE_SELECTED_COMPONENT_CATEGORY',
        selectedCategory: selectedCategory
    })
}