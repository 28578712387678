import React, { useEffect, useRef } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import debuggerService from 'Services/DebuggerService';

const logger = debuggerService.getDebugger('ChartHistories');


function ChartHistories({ records, selectedpoints, dateSelectionType, dateStart, dateEnd, charttype, period, interval}) {

    const chartRef = useRef(null);
    const elementRef = useRef(null);

    logger.log("render records", records);

    const drawChart = () => {
        logger.log("drawing chart");
        logger.log("selectedpoints", selectedpoints);
        logger.log("records", records);
        logger.log("dateStart", dateStart);
        logger.log("dateEnd", dateEnd);
        logger.log("charttype", charttype);

        // clear out memory of previously displayed chart
        if (chartRef.current) {
            // logger.log("disposing of previous chart");
            chartRef.current.dispose();
            chartRef.current = null;
        }

        if (!selectedpoints) {
            return;
        }

        if(selectedpoints.size < 1) {
            return;
        }

        let t1 = dateStart.getTime();
        let t2 = dateEnd.getTime();

        var samples = [];
        var series = [];
        var xAxis, yAxis;
        var value;
        var chart;
        let chartseriesX;
        var samplet = 0;

        switch (charttype) {
            case 'ColumnSeries':
                let period = 'day';
                let periodText = 'DD';
                chart = am4core.create(elementRef.current, am4charts.XYChart);
                if ( dateSelectionType == 'today' || dateSelectionType == 'yesterday' ) {
                    period = 'hour'; 
                    periodText = 'HH';      
                }
                chartseriesX = new am4charts.ColumnSeries();
                (records || []).map((record) => {
                    record.samples.map((sample) => {
                        record.samples.map((sample, index2) => {
                            samplet = Date.parse(sample.t);
                            if ( samplet <= t2 && samplet >= t1 ) {
                                value = record.name;
                                samples.push({
                                    date: sample.t,
                                    [value]: sample.v
                                });
                            }    
                        })
                    })
                })
                xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                xAxis.dataFields.category = "date"; 
                xAxis.renderer.grid.template.location = 0;
                xAxis.renderer.minGridDistance = 30;
                yAxis = chart.yAxes.push(new am4charts.ValueAxis());
                chart.data = samples;
                series[0] = chart.series.push(new am4charts.ColumnSeries());
                selectedpoints.map((point, index) => { 
                    series[0].dataFields.categoryX = "date";
                    series[0].dataFields.valueY = point.get('address');
                    series.name = point.get('name');
                });
                var columnTemplate = series[0].columns.template;
                columnTemplate.strokeWidth = 2;
                columnTemplate.strokeOpacity = 1;
                break;
            default:
                chart = am4core.create(elementRef.current, am4charts.XYChart);
                chartseriesX = new am4charts.XYChartScrollbar();
                (records || []).map((record, index) => {
                    // console.log('Number Of Samples for : ' + record.time + ' = ' + record.samples.length)
                    record.samples.map((sample, index2) => {                       
                        samplet = Date.parse(sample.t);
                        if ( samplet <= t2 && samplet >= t1 ) {
                            value = record.name;
                            samples.push({
                                date: new Date(sample.t),
                                [value]: sample.v
                            });
                        }    
                    })
                })
                    
                // add label
                if (samples.length == 0) {
                    let label = chart.createChild(am4core.Label);
                    label.text = "No Data To Display";
                    label.fontSize = 20;
                // label.align = "center";
                    label.isMeasured = false;
                    label.x = am4core.percent(40);
                    label.y = am4core.percent(50);
                }

                xAxis = chart.xAxes.push(new am4charts.DateAxis());
                xAxis.renderer.grid.template.location = 0;
                xAxis.tooltipDateFormat = "dd-MMM HH:mm";

                yAxis = chart.yAxes.push(new am4charts.ValueAxis());
                yAxis.tooltip.disabled = true;
                yAxis.renderer.minWidth = 35;
                yAxis.title.text = selectedpoints.get(0).get('engunits', ['DegC'])[0];

                chart.data = samples;
                var exportDataFields = {"date":"date"};
                var obj = {};
                chart.legend = new am4charts.Legend();
                chart.legend.fontSize = 12;

                selectedpoints.map((point, index) => { 
                    series[index] = chart.series.push(new am4charts.LineSeries());
                    series[index].dataFields.dateX = "date";
                    series[index].dataFields.valueY = point.get('address');
                    series[index].tooltipText = "{valueY.value}";
                    series[index].legendSettings.labelText = point.get('name'); // + " : [bold {color}]{name}[/]";

                    if (charttype == "AreaSeries") series[index].fillOpacity = 0.3;

                    if (point.get('colour')) {
                        series[index].stroke = am4core.color(point.get('colour'));
                    }
                
                    chartseriesX.series.push(series[index]);

                    obj[series[index].dataFields.valueY] = series[index].legendSettings.labelText;
                    Object.assign(exportDataFields, obj);
                });

                chart.scrollbarX = chartseriesX;
                break;
            }

            chart.paddingRight = 20;
            chart.cursor = new am4charts.XYCursor();
            chart.exporting.menu = new am4core.ExportMenu();

/*
            chart.exporting.adapter.add("data", function(samples, target) {
                if (samples.data.length == 0) return;
                // Assemble data from series
                var data = [];
                chartseriesX.series.each(function(series) {
                    for(var i = 0; i < samples.data.length; i++) {
                        addr = `${Object.keys(samples.data[i])[1]}`;
                        if ( series.dataFields.valueY == addr) {
                            samples.data[i].name = series.legendSettings.labelText;
                            data.push(samples.data[i]); }
                    }
                });
                return { data: data };
            }); */

        //    chart.exporting.dateFields.push("dateX");
        //    chart.exporting.dateFormat = "dd-MMM HH:mm";
            chart.exporting.dataFields = exportDataFields;
            chartRef.current = chart;
    }

    useEffect(() => {
        logger.log("change in records detected");
        if(records) {
            drawChart();
        }

        return () => {
            if (chartRef.current) {
                logger.log("disposing of previous chart");
                chartRef.current.dispose();
                chartRef.current = null;
            }
        }

    }, [records])

    useEffect(() => {
        logger.log("mounting");
        return () => {
            logger.log("unmounting");
        }
    }, []);

    return <div ref={elementRef} style={{ width: "100%", height: "500px" }}></div>


}



class _ChartHistories extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(oldProps) {
        let { selectedpoints, records, dateStart, dateEnd, charttype, period, interval } = this.props;

        logger.log("componentDidUpdate");

        if (records && records != oldProps.records) {
            logger.log("change in records detected");
            this.drawChart();
        }
    }  
    
    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
     
    drawChart() {     
        let { selectedpoints, records, dateSelectionType, dateStart, dateEnd, charttype, period, interval } = this.props;

        // console.log('draw chart');


        logger.log("drawing chart");
        logger.log("selectedpoints", selectedpoints);
        logger.log("records", records);
        // clear out memory of previously displayed chart
        if (this.chart) {
            this.chart.dispose();
        }

        if (!selectedpoints) {
            return;
        }

        let t1 = dateStart.getTime();
        let t2 = dateEnd.getTime();



        // console.log('drawchart selectedpoints' + selectedpoints);
        // console.log('Start Date : ' + dateStart);
        // console.log('Start Date : ' + t1);
        // console.log('End Date : ' + dateEnd);
        // console.log('End Date : ' + t2);

        var samples = [];
        var series = [];
        var xAxis, yAxis;
        var value;
        var chart;
        let chartseriesX;
        var samplet = 0;

        switch (charttype) {
            case 'ColumnSeries':
                let period = 'day';
                let periodText = 'DD';

                chart = am4core.create("chartdiv", am4charts.XYChart);

                if ( dateSelectionType == 'today' || dateSelectionType == 'yesterday' ) {
                    period = 'hour'; 
                    periodText = 'HH';      
                }

                chartseriesX = new am4charts.ColumnSeries();

                (records || []).map((record) => {
                    record.samples.map((sample) => {
                        record.samples.map((sample, index2) => {
                            samplet = Date.parse(sample.t);
                            if ( samplet <= t2 && samplet >= t1 ) {
                                value = record.name;
                                samples.push({
                                    date: sample.t,
                                    [value]: sample.v
                                });
                            }    
                        })
                    })
                })

                xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                xAxis.dataFields.category = "date"; 
                xAxis.renderer.grid.template.location = 0;
                xAxis.renderer.minGridDistance = 30;
                yAxis = chart.yAxes.push(new am4charts.ValueAxis());

                chart.data = samples;

                series[0] = chart.series.push(new am4charts.ColumnSeries());
                              
                selectedpoints.map((point, index) => { 
                    series[0].dataFields.categoryX = "date";
                    series[0].dataFields.valueY = point.get('address');
                    series.name = point.get('name');
                });

                var columnTemplate = series[0].columns.template;
                columnTemplate.strokeWidth = 2;
                columnTemplate.strokeOpacity = 1;
    
                break;

            default:
                chart = am4core.create("chartdiv", am4charts.XYChart);
                chartseriesX = new am4charts.XYChartScrollbar();

                

                (records || []).map((record, index) => {
                    // console.log('Number Of Samples for : ' + record.time + ' = ' + record.samples.length)
                    record.samples.map((sample, index2) => {                       
                        samplet = Date.parse(sample.t);
                        if ( samplet <= t2 && samplet >= t1 ) {
                            value = record.name;
                            samples.push({
                                date: new Date(sample.t),
                                [value]: sample.v
                            });
                        }    
                    })
                })
                    
                // add label
                if (samples.length == 0) {
                    let label = chart.createChild(am4core.Label);
                    label.text = "No Data To Display";
                    label.fontSize = 20;
                // label.align = "center";
                    label.isMeasured = false;
                    label.x = am4core.percent(40);
                    label.y = am4core.percent(50);
                }

                xAxis = chart.xAxes.push(new am4charts.DateAxis());
                xAxis.renderer.grid.template.location = 0;
                xAxis.tooltipDateFormat = "dd-MMM HH:mm";

                yAxis = chart.yAxes.push(new am4charts.ValueAxis());
                yAxis.tooltip.disabled = true;
                yAxis.renderer.minWidth = 35;
                yAxis.title.text = selectedpoints.get(0).get('engunits', ['DegC'])[0];

                chart.data = samples;
                var exportDataFields = {"date":"date"};
                var obj = {};
                chart.legend = new am4charts.Legend();
                chart.legend.fontSize = 12;

                selectedpoints.map((point, index) => { 
                    series[index] = chart.series.push(new am4charts.LineSeries());
                    series[index].dataFields.dateX = "date";
                    series[index].dataFields.valueY = point.get('address');
                    series[index].tooltipText = "{valueY.value}";
                    series[index].legendSettings.labelText = point.get('name'); // + " : [bold {color}]{name}[/]";

                    if (charttype == "AreaSeries") series[index].fillOpacity = 0.3;

                    if (point.get('colour')) {
                        series[index].stroke = am4core.color(point.get('colour'));
                    }
                
                    chartseriesX.series.push(series[index]);

                    obj[series[index].dataFields.valueY] = series[index].legendSettings.labelText;
                    Object.assign(exportDataFields, obj);
                });

                chart.scrollbarX = chartseriesX;
                break;
            }

            chart.paddingRight = 20;
            chart.cursor = new am4charts.XYCursor();
            chart.exporting.menu = new am4core.ExportMenu();

/*
            chart.exporting.adapter.add("data", function(samples, target) {
                if (samples.data.length == 0) return;
                // Assemble data from series
                var data = [];
                chartseriesX.series.each(function(series) {
                    for(var i = 0; i < samples.data.length; i++) {
                        addr = `${Object.keys(samples.data[i])[1]}`;
                        if ( series.dataFields.valueY == addr) {
                            samples.data[i].name = series.legendSettings.labelText;
                            data.push(samples.data[i]); }
                    }
                });
                return { data: data };
            }); */

        //    chart.exporting.dateFields.push("dateX");
        //    chart.exporting.dateFormat = "dd-MMM HH:mm";
            chart.exporting.dataFields = exportDataFields;
            this.chart = chart;
    }

    render() {
        return (
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        );
    }
}

export default ChartHistories;


