import React, {useState, useEffect, Fragment } from 'react';
import Immutable from 'immutable';
import CurrentPointAlarmsLog from './CurrentPointAlarmsLog';
import {getPointsInAlarmRecords, getAlarmRecord} from './Actions';
import ServiceBus from 'Services/ServiceBus';
import {getFileCommunic} from 'BmsView/Actions/Common';

import Dialog, { DialogContent, DialogHeader, DialogBody, DialogFooter, DialogTitle } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function CurrentPointAlarmsDialog({ show, onClose, ...props }) {

    return <Dialog className="md:max-w-[80vw]" opened={!!show} onClose={onClose}>
    <CurrentPointAlarmsDialogPanel  show={show} onClose={onClose} {...props} />
</Dialog>
}

function CurrentPointAlarmsDialogPanel({show, customer, onClose}) {
    const [points, setPoints] = useState([]);
    const [log, setLog] = useState([]);
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType:'Today',
        startDate:new Date(new Date().setHours(0,0,0)),
        endDate:new Date(new Date().setHours(23,59,59))
    });

    const [subsystem, setSubsystem] = useState(-1);
    const [alarmTypes, setAlarmTypes] = useState([]);
    const [type, setType] = useState("All Alarms");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let unsubscribe = ServiceBus.on('309-11', getFileHandler);

        if(customer.get('subsystems')) {
            let subsystemtype = customer.get('subsystems').filter((subsystem) => {
                return subsystem.get('type') != 5;
            });
    
            if (!subsystemtype) getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,0,'trendtemplates','alarmtypelist.json');
        }

        if (customer && show) getPointsInAlarm();



        return () => unsubscribe() //ServiceBus.off('309-11', getFileHandler);
    }, [show, dateTimeRange, type]);

    const getFileHandler = (msg) => {
        setAlarmTypes(JSON.parse(msg.m_jsonFileData));
    }

    const getPointsInAlarm = async () => { 
        let query = {'ids.0.state':1}; 

        getBacnetPointsInAlarm();

        if (subsystem >= 0 ) query = {'ids.0.state':1, 'addr.0': subsystem};

        let points = await getPointsInAlarmRecords( `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, query).then((data) => {
                let points = Immutable.fromJS(data);
                return points;
            });

        let data = await getPointAlarmRecords(points);
        let rawjs = data.toJS();
        setPoints(data);      
    } 

    const getBacnetPointsInAlarm = () => { 
        let subsystemNumber = 16; //address.map((value) => { return parseInt(value); })[0];
        
        let request = {
            type: 'GENERIC',
            m_level: 150,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 26,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }

        // console.log(request);
        
        ServiceBus.send('WEB_MESSAGE_REQ', request);
    } 

    const processGetBacnetPointsInAlarm = (message) => {
        if (message.m_errorNumber != 0) {
        //    toast.success('Error retrieving alarms. Error :' + message.m_errorNumber, { autoClose: 2000 });
            return;
        }
    }
        
    const getPointAlarmRecords = async (points) => { 
        for (let i = 0; i < points.size; i++){
            let currentpoint = points.get(i);

            let firstid = currentpoint.getIn(['ids', 0, 'docid']);

            let lastalarmrecord = await getAlarmRecord(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                `${customer.get('ipaddress').toLowerCase()}`, firstid);

            let mergefields = {
                alarm: Immutable.Map(lastalarmrecord),
                datetime: new Date(lastalarmrecord.datetime).toLocaleString(), 
                paneltext: lastalarmrecord.paneltext,
                point: lastalarmrecord.point
            };

            points = points.set(i, currentpoint.merge(mergefields));
        }

        return points;
    } 
            
    return (
        <DialogContent className="md:w-full md:min-w-[80vw]">
            <DialogHeader>
                <DialogTitle>Current Point Alarms</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div>
                    <div className="px-3 py-2">
                        <CurrentPointAlarmsLog points={points} />
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    );
}

export default CurrentPointAlarmsDialog;
