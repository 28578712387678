import React, {useState, useEffect} from 'react';
import ServiceBus from 'Services/ServiceBus';
import Immutable from 'immutable';

const PointProperties = ({customer, site, selectedpoint}) => {
    const [properties, setProperties] = useState([]); //]);

    useEffect( ()=> {
        let unsubscribeGetPointPropertiesResp = ServiceBus.on('21-151', processGetPointPropertiesResp);
        
        getPointProperties();;

        return() => {
            unsubscribeGetPointPropertiesResp();
        }
        
    },[]);

    const getPointProperties = () => {
        let address = selectedpoint.getIn(['configuration', 'boundto']);

        let request = {
            trackMessage: true,
            m_level: 150,
            m_communicNum: 21,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: address.split('-')[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: address.split('-').map((bit) => { return parseInt(bit); }),
            m_informationType: 0
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);

    //    comm.m_informationType = 1;
    //    ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const processGetPointPropertiesResp = (msg) => {
        // console.log('receive properties', msg);
            let parsedMessage = msg; //JSON.parse(msg);
        
            if (parsedMessage.m_errorNumber != 0) {

            //    swal.fire("Error", `Error number ${parsedMessage.m_errorNumber}`, "error");
            }

            // console.log(msg.m_text);
            let l = msg.m_text; //Immutable.List(msg.m_text);

            // console.log('l :',l);

            {(l || []).map((p) => {
                // console.log(p);
            })}



            setProperties(msg.m_text); //Immutable.List(msg.m_text));
    
        //    let exceptions = Immutable.fromJS(parsedMessage.m_exceptions);

    }

    return (
        <div>
            <h2>Properties</h2>
            <div>
                {(properties || []).map((p) => {
                    return <pre>{p}</pre>

                }

            )}
            </div>
        </div>
        
    )
}

export default PointProperties;