import React, {useState, useEffect, Fragment} from 'react';
import Immutable from 'immutable';
import ServiceBus from 'Services/ServiceBus';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';

function PointsInAlarmDialog({ show, onClose, ...props }) {
    return <Dialog opened={!!show} onClose={onClose} className="">
        <PointsInAlarmDialogPanel show={show} onClose={onClose} {...props} />
    </Dialog>
}


function PointsInAlarmDialogPanel({show, customer, onClose}) {

    const [open, setOpen] = useState(false);
    const [type, setType] = useState(-1);
    const [points, setPoints] = useState();

    let gridFields = [
        { value: 'm_pointName', name: 'Point', width: 'col-span-16 md:col-span-4' },
        { value: 'm_description', name: 'Description', width: 'col-span-16 md:col-span-4' },
        { value: 'm_deviceName', name: 'Device', width: 'col-span-16 md:col-span-4' },
        { value: 'm_eventStateName', name: 'Event State', width: 'col-span-16 md:col-span-3' }
    ];

    useEffect(() => {
        let unsubscribe = ServiceBus.on('26-151', processPointsInAlarm);
    
        if (customer && show) {
            console.log(customer.get('subsystems'));
            let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
                return subsystem.get('type') == 9;
            });

            getPointsInAlarm(selectedsubsystem.first().get('number'));
        }

        return () => unsubscribe();
    }, [show, type]);

    const processPointsInAlarm = (msg) => {
        setPoints(msg.m_pointsInAlarm);
    }

    const getPointsInAlarm = (subsystemNumber) => { 
       
        let request = {
            type: 'GENERIC',
            m_level: 150,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 26,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }

        let comm = {
            m_getAll: 1,
            m_deviceInstance: 0            
        }
       
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }


    return (
        <DialogContent className="md:w-full md:min-w-[80vw]">
            <DialogHeader>
                <DialogTitle>Points in Alarm</DialogTitle>
            </DialogHeader>
            <DialogBody>
            <Grid>
                <Grid.Header>
                    {gridFields.map((field) => {
                        return <Grid.Column key={field.name} classes={`${field.width}`}>{field.name}</Grid.Column>
                    })}
                    <Grid.Column key={"buttons"} classes={''} name="button"></Grid.Column>
                </Grid.Header>
                <Grid.Data className="max-h-100 h-100 overflow-x-scroll">
                    {(points || []).map((point, index) => {
                        console.log('point ', point);
                        return (<Grid.Row key={index}>
                            {gridFields.map((field2, _index) => {
                                return <Grid.Column key={_index} className={`${field2.width}`}>{point[field2.value]}</Grid.Column>;
                            })}
                            <Grid.Column>
                                <Grid.Column>
                                    <Grid.Controls>
                                        <Grid.Control>
                                            
                                        </Grid.Control>
                                    </Grid.Controls>
                                </Grid.Column>
                            </Grid.Column>
                        </Grid.Row>)
                    })}
                </Grid.Data>
            </Grid>
            </DialogBody>
            <DialogFooter>
                <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    )
}


export default PointsInAlarmDialog;

