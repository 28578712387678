import React, {useState, Fragment } from 'react';
import Input from 'Controls/Form/Input';
import Swal from 'sweetalert2';
import {checkPassword} from 'Actions/Authentication';
import {setPassword} from 'BmsView/Users/Actions';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

const ChangePasswordDialogPanel = ({user, onClose, currentUser}) => {
    let [credentials, setCredentials] = useState(currentUser);
    let [currentPassword, setCurrentPassword] = useState();
    let [confirmPassword, setConfirmPassword] = useState();

    const changePassword = () => {
        checkPassword(credentials.get('email'), currentPassword).then(() => {
            if (credentials.get('newpassword') == currentPassword) {
                Swal.fire('Error', "Cannot use same password", "error");
                return;
            }

            if (credentials.get('newpassword') != confirmPassword) {
                Swal.fire('Error', "Passwords do not match", "error");
                return;
            }

            setPassword(credentials).then(() => {
                Swal.fire("Success", "The password has been changed successfully.", "success");
                onClose();
            }).catch((err) => {
                Swal.fire("Error", "An error occurred while trying to change the password.", "error");
            });

        }).catch((err) => {
            Swal.fire("Error", "Current Password Invalid", "error");
            return;
        });
    }

    const changeNewPassword = (value) => {
        setCredentials(credentials.set('newpassword', value));
    }

    // console.log("changePasswordDialog panel");

    return( <Fragment>
            <DialogHeader>Change User Password</DialogHeader>
            <DialogBody>
                <div>
                    <Input label="Enter Current Password" type="password" onChange={(value)=>setCurrentPassword(value)}/>
                </div>
                <div>
                    <Input label="Enter New Password" type="password" onChange={(value) => changeNewPassword(value)}/>
                </div>
                <div>
                    <Input label="Retype Password" type="password" onChange={(value)=>setConfirmPassword(value)}/>
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="primary" onClick={()=>changePassword()}>Change Password</Button></div>
                <div className="pl-1"><Button role="secondary" onClick={()=>onClose()}>Close</Button></div>
            </DialogFooter>
        </Fragment> 
    )
}

function ChangePasswordDialog({ user, onClose, ...props }) {

    return <Dialog opened={!!user} onClose={onClose}>
        <ChangePasswordDialogPanel user={user} onClose={onClose} {...props} />
    </Dialog>

}

export default ChangePasswordDialog;
