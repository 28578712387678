import React, {useState, useEffect} from 'react';
import Immutable, { List } from 'immutable';
import TimezoneDialog from './../Timezones/TimezoneDialog';
import Calendar from 'Controls/Calendar';
import swal from 'sweetalert2';
import { convertDoubleToTimeString } from 'Utils/Timestamp';
import {searchPoints} from 'BmsView/Customer/Site/Actions';
import {savePoint} from 'BmsView/Customer/Configuration/Actions';

const AdvancedTimezones = ({customer, selectedpoint}) => {
    const [pointconfiguration, setPointconfiguration] = useState();
    const [events, setEvents] = useState(Immutable.List([]));
    const [tosave, setTosave] = useState(false);
    const [displaying, setDisplaying] = useState('current');
    const [selectedtimezone, setSelectedTimezone] = useState();
    const [selectedtimezoneindex, setSelectedTimezoneIndex] = useState(0);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        getTimezone();
    }, []);

    useEffect(() => {
        if (tosave) {
            savePoint(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, pointconfiguration).then((response) => {
                swal.fire("Success", "The timezone has been successfully saved", "success");
            }).catch((err) => {
                swal.fire("Error", "An error occured saving the timezone", "error");
            });

            setEventList(pointconfiguration.get('events'));
            setTosave(false);
        }  
    }, [pointconfiguration]);

    const getTimezone = async () => {
        let addr = selectedpoint.getIn(['configuration', 'boundto']).split('-');
        let points = await searchPoints(`${customer.get('name').replace(/ /g, "").toLowerCase()}`, `${customer.get('ipaddress').toLowerCase()}`, {
            addr: addr.map((byte) => { return parseInt(byte) })
        });

        if (points.length > 0) {
            setPointconfiguration(Immutable.fromJS(points[0]));

            if (points[0].events) {
                setEventList(points[0].events);
            }
        }  
    }

    const setEventList = (events) => {
        let eventsList = [];

        for (let dow = 0; dow < 7; dow++) {
            let dayProfile = events[dow];

            for (let period = 0; period < dayProfile.length; period += 2) {
                let startInDouble = dayProfile[period].hr + ((Math.round(dayProfile[period].mn / 5) * 5) / 60);
                let endInDouble = dayProfile[period + 1].hr + ((Math.round(dayProfile[period + 1].mn / 5) * 5) / 60);
                eventsList.push({
                    id: dow + "-" + period,
                    day: dow,
                    start: Math.floor(startInDouble * 2),
                    end: Math.floor(endInDouble * 2),
                    startindouble: startInDouble,
                    endindouble: endInDouble,
                    starttimeindex: dow * 24 + startInDouble,
                    type: 'current'
                });
            }
        }

        setEvents(Immutable.fromJS(eventsList));
    }

    const savePointToDisk = () => {
        setTosave(true);
        var m_events = [[],[],[],[],[],[],[]];

        events.filter((event) => !event.get('removed')).sort((a, b) => {
            return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
        }).forEach((event) => {              
            let dow = event.getIn(['day'], 0);

            m_events[dow].push({
                hr: (parseInt(event.get('startindouble'))),
                mn: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                v: 1,
                m_nm: undefined});

            m_events[dow].push({
                hr: (parseInt(event.get('endindouble'))),
                mn: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5,
                v: 0,
                m_nm: undefined});
        });

        let newpoint = pointconfiguration.toJS();
        newpoint.events = m_events;
        setPointconfiguration(Immutable.Map(newpoint)); 
    }

    const editTimezone = (toedit, index) => {
        setSelectedTimezone(toedit);
        setSelectedTimezoneIndex(index);
    }

    const timezoneSelected = (start, end) => {
        end = end + 1;

        let hoursstart = start * 0.5;
        let hoursend = end * 0.5;

        let starttimeHours = hoursstart % 24;
        let endtimeHours = hoursend % 24;

        let startDay = (hoursstart - starttimeHours) / 24;
        let endDay = (hoursend - endtimeHours) / 24;

        if (endDay > startDay) {
            end = 48;
        }

        setSelectedTimezone(Immutable.Map({
                day: (start - (start % 48)) / 48,
                start: start % 48,
                end: (end % 48) < (start % 48) ? 48 : (end % 48),
                startindouble: starttimeHours,
                endindouble: endtimeHours,
                starttimeindex: ((start - (start % 48)) / 48) * 24 + starttimeHours,
                dirty: true
            }));
        setSelectedTimezoneIndex(-1);
    }

    const clearSelection = () => {
        setSelectedTimezone(undefined);
    }

    const removeEvent = (index) => {
        setEvents((events) => events.setIn([index, 'removed'], true).setIn([index, 'dirty'], true));
        setDirty(true);
    }

    const saveTimezone = (timezone) => {
        // console.log('saving: ' + timezone);

        //Find index nearest to time.
        let starttime = parseFloat(timezone.get('startindouble'));
        let endtime = parseFloat(timezone.get('endindouble'));

        let startindex = (starttime - (starttime % 0.5)) * 2;
        let endindex = (endtime - (endtime % 0.5)) * 2;

        if (selectedtimezoneindex < 0) {
            //New
            setEvents((events) => events.push(timezone.set('start', startindex).set('end', endindex)));
            setDirty(true);
            setSelectedTimezone(undefined);
            return;
        }
        
        setEvents((events) => events.set(selectedtimezoneindex, timezone.set('start', startindex).set('end', endindex).set('startindouble', starttime).set('endindouble', endtime).set('dirty', true)));
        setDirty(true);
        setSelectedTimezone(undefined);
    }

    const renderCalendar = (colheight, colwidth, events) => {
        if (!events) {
            return <data></data>
        }

        return (events.map((event, index) => {
            let inbetween = event.get('end') - event.get('start');
            let starttime = convertDoubleToTimeString(event.get('startindouble'));
            let endtime = convertDoubleToTimeString(event.get('endindouble'));

            if (displaying != event.get('type')) {
                return (
                    <div className={`event ${event.get('removed') && 'removed' || ''}`} style={{
                        backgroundColor: event.get('type') == 'current' ? '#0088cc' : 'yellow',
                        width: `${colwidth * 0.95}px`,
                        height: `${inbetween * colheight}px`,
                        marginTop: `${(event.get('start') * colheight)}px`,
                        marginLeft: `${(event.get('day')) * colwidth}px`,
                        zIndex: 0
                    }}></div>
                )
            }

            return ( 
                <div className={`event ${event.get('removed') && 'removed' || ''}`} style={{
                    backgroundColor: event.get('type') == 'current' ? '#0088cc' : 'yellow',
                    width: `${colwidth * 0.8}px`,
                    height: `${inbetween * colheight}px`,
                    marginTop: `${(event.get('start') * colheight)}px`,
                    marginLeft: `${(event.get('day')) * colwidth}px`,
                    zIndex: 1
                }} onDoubleClick={() => editTimezone(event, index)}>
                    <div className="flex flex-row">
                        <span className="title flex-grow">{starttime} - {endtime}</span>
                        <a className="inline-block px-2 bg-red text-blue hover:bg-red-light" onClick={()=>removeEvent(index)}>x</a>
                    </div>
                </div>
            );
        }))
    }    
    
    return (
        <div>
            <TimezoneDialog selecteditem={selectedtimezone} onClose={() => clearSelection()} onSave={(timezone) => saveTimezone(timezone)} />
            Advanced Timeones
            <div>
                <Calendar onSelect={(start, end) => timezoneSelected(start, end)} events={events} render={({colheight, colwidth}) => renderCalendar(colheight,colwidth,events)} />
            </div> 
            <div className="box px-4 py-3 mt-8 mb-8 clear-both">
                {dirty && <a className="px-3 py-2 bg-green text-white hover:bg-green-dark float-right" onClick={() => savePointToDisk()}>Save</a>}
            </div>   
        </div>
    )    
}

export default AdvancedTimezones;