import * as d3 from 'd3';
import { drawText } from '../Utils';

const formatDateTime = (format) => {
    const dt= new Date();
    const yyyy = dt.getFullYear();
    let mm = dt.getMonth() + 1; // Months start at 0
    let dd = dt.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    let h = dt.getHours();
    let m = dt.getMinutes();

    if (h < 10) h = '0' + h;
    if (m < 10) m = '0' + m;

    var formattedToday = '';
   
    if ( format == 'DD/MM/YYYY HH:mm') {
        formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m;
    }

    if ( format == 'DD/MM/YYYY H:mm a') {
        if ( h >= 13 ){
            h = h - 12;
            formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ' pm';
        } else {
            formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ' am';
        }
    }

    if ( format == 'DD MMM YYYY HH:mm') {
        let month = dt.toLocaleString('default', { month: 'short' });
        formattedToday = dd + ' ' + month + ' ' + yyyy + ' ' + h + ':' + m;
    }

    return formattedToday;
}

export default function DateTimeText(options) {
    return new Promise((resolve, reject) => {
        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)
        
        let placementElement = createPlacementElement(options);

        var dateNow;
        let f = options.node.getIn(['configuration', 'format'], 'DD/MM/YYYY HH:mm');
        dateNow = formatDateTime(f);
        let textNode = drawText(placementElement, options.node, dateNow, options.getCustomerSettings, options.getUserSettings);

        let intervalS = setInterval(() => {
            dateNow = formatDateTime(f);
            textNode.text(dateNow);
        }, 5000);

        resolve({
            setValue: (point) => {
                dateNow = formatDateTime(f);
                textNode.text(dateNow);
            },
            remove: () => {
                clearInterval(intervalS);
                d3.select(placementElement).remove();
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                textNode.remove();
                placementElement.remove();
                options.node = node;
                placementElement = createPlacementElement(options);
                clearInterval(intervalS);
                dateNow = formatDateTime(f);
                textNode = drawText(placementElement, options.node, dateNow, options.getCustomerSettings, options.getUserSettings);
                intervalS = setInterval(() => {
                    dateNow = formatDateTime(f);
                    textNode.text(dateNow);
                }, 5000);
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}