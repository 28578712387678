import React, { useState } from 'react';
import Immutable from 'immutable';
import Input from 'Controls/Form/Input';
import axios from 'axios';
import Swal from 'sweetalert2';
import { saveSiteConfigFile, saveSiteSubDirectoryConfigFile } from 'BmsView/Customer/Configuration/Actions';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function CreateCustomerDialog({ onClose, selecteditem, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <CreateCustomerDialogPanel onClose={onClose} {...props} />
    </Dialog>
}

const CreateCustomerDialogPanel = ({onSave, onClose }) => {
    
    let [customer, updateCustomer] = useState(Immutable.Map({'ipaddress':'localhostdb'}));
    let [saving, setSaving] = useState(false);

    const save = async () => {
        let rawCustomer = customer.toJS();
        try {
            setSaving(true);

            let customername = customer.get('name').toLowerCase().split(' ').join('-');
            let sitename = customer.get('sites').get(0).name.toLowerCase().split(' ').join('-');

            await axios.post(`/files/customers/${customername}_customer.json`, rawCustomer);

            saveSiteSubDirectoryConfigFile(customer.get('name').toLowerCase().split(' ').join('-'), sitename, 'backdrops', 'backdrops', []);
            saveSiteSubDirectoryConfigFile(customer.get('name').toLowerCase().split(' ').join('-'), sitename, 'components', 'components', []);
            saveSiteConfigFile(customer.get('name').toLowerCase().split(' ').join('-'), sitename, 'configuration', []);

            Swal.fire("Success", "The customer has been created, you can now enable user access.", "success");
            onSave(customer.get('name').toLowerCase().split(' ').join('-'));
        } catch (e) {
            // console.log(e);
            Swal.fire("Error", "An error occurred while creating the customer, please contact the administrator.", "error");
        }
    }

    // setSaveHandler(save);

    return (
        <DialogContent className="w-[500px]">
            <DialogHeader>
                <DialogTitle>Create customer</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="grid grid-cols-16">
                    {saving && <div className="col-span-16">
                        <div className="py-3 px-5 mb-4 rounded text-[#721c24] bg-[#f8d7da] border-[#f5c6cb]">Please wait while we create the customer...</div>
                    </div>}
                    <div className="col-span-16 md:col-span-8 md:px-4">
                        <Input label="Name" value={customer.get('name')} onChange={(name) => { updateCustomer(customer.set('name', name)) }} />
                    </div>
                    <div className="col-span-16 md:col-span-8 md:px-4">
                        <Input label="Database Server" value={customer.get('ipaddress', 'locahostdb')} onChange={(value) => { updateCustomer(customer.set('ipaddress', value)) }} />
                    </div>
                    <div className="col-span-16 md:col-span-8 md:px-4">
                        <Input label="Site Name" value={customer.get('site')} onChange={(name) => { updateCustomer(customer.set('sites', Immutable.List([{'name': name}]))) }} />
                    </div>
                </div>

            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                <div className="pl-1"><Button role="success" onClick={save}>Save</Button></div>
            </DialogFooter>
        </DialogContent>
        
    )
};
export default CreateCustomerDialog;