import React, { useState, Fragment, useRef } from 'react';
import { Map, List } from 'immutable';
import { Menu, Transition, Popover } from '@headlessui/react'
import Resizable from './Resizable';
import Draggable from './Draggable';
import Expandable from './Expandable';
import EventLabel from './EventLabel';
import { useUserSettings } from '../../../../../Context/UserSettings';
import { useCustomerSettings } from '../../../../../Context/CustomerSettings';
import Swal from 'sweetalert2';

const dayLabels = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];

const show24 = false;

function timeIndexToString(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

function WeekTimes({ onRangeSelected, events, onChangeEvent, onRemove, onRemoveMultiple, onModifyRequested, onDayAction, extraActions }) {

    const [startSelection, setStartSelection] = useState(null);
    const [hoveredSelection, setHoveredSelection] = useState(null);

    const { userSettings } = useUserSettings();
    const { customerSettings } = useUserSettings();

    const calendarHeaderBackgroundColor = (userSettings || Map()).getIn(['defaultCalendarHeaderBgColor'], (customerSettings || Map()).getIn(['defaultCalendarHeaderBgColor'], 'rgb(18,40,58)'));
    const calendarHeaderTextColor = (userSettings || Map()).getIn(['defaultCalendarHeaderTextColor'], (customerSettings || Map()).getIn(['defaultCalendarHeaderTextColor'], 'white'));

    const currentGrid = useRef(null);

    let daysOfWeek = 7;
    let minutesPerDay = 48 * 30;
    let formattedEvents = [];
    
    (events || List()).forEach((x, xi) => {
        let startIndex = x.get('startindex');
        let endIndex = x.get('endindex');
        let starttime = x.get('starttime') || timeIndexToString(startIndex);
        let endtime = x.get('endtime') || timeIndexToString(endIndex);
        let [startHours, startMinutes] = starttime.split(':');
        let [endHours, endMinutes] = endtime.split(':');
        let bigStartIndex = Number(startHours) * 60 + Number(startMinutes);
        let bigEndIndex = Number(endHours) * 60 + Number(endMinutes);
        formattedEvents.push({
            dow: x.get('dow') + 1,
            starttime: x.get('starttime'),
            endtime: x.get('endtime'),
            startIndex: startIndex,
            endIndex: endIndex,
            bigStartIndex: bigStartIndex,
            bigEndIndex: bigEndIndex,
            label: `${starttime} - ${(endtime == "24:00" ? show24 && endtime || "00:00" : endtime)}` ,
            original: x,
            index: xi
        });
    });

    let weekMatrix = (() => {
        let matrix = [];
        for(let day = 0; day < (daysOfWeek); day++) {
            matrix[day] = [];
            for(let hour = 0; hour < (minutesPerDay); hour++) { 
                matrix[day][hour] = 1; 
            }
        }
        return matrix;
    })();

    formattedEvents.forEach((event) => {
        for(let i = event.bigStartIndex; i < event.bigEndIndex; i++) {
            try {
                weekMatrix[event.dow - 1][i] = event.bigStartIndex == i ? event : 0;
            } catch(e) {
                // console.log("here it went wrong", event);
                throw new Error(e);
            }
        }
    })

    const removeCollidingElements = (event, startIndex, endIndex) => {
        let elementsToRemove = List();
        formattedEvents.forEach((x) => {
            if(event == x) {
                return;
            }
            let sameDay = x.dow == event.dow;
            let startIsInOtherEventRange = x.bigStartIndex >= startIndex && x.bigStartIndex < endIndex;
            let endIsInOtherEventRange = x.bigEndIndex < endIndex && x.bigEndIndex > startIndex;
            if(sameDay && (startIsInOtherEventRange || endIsInOtherEventRange)) {
                elementsToRemove = elementsToRemove.push(x.original);
            } 
        })
        if(elementsToRemove.size > 0) {
            onRemoveMultiple(elementsToRemove)
        }
    }

    const getIndexFromTime = (time) => {
        let [startHours, startMinutes] = time.split(':');
        return Number(startHours) * 60 + Number(startMinutes);
    }


    const addTime = (elementIndex, startIndex, endIndex) => {
        // let endIndex = index + selectionLength ;
        // console.log("addTime", elementIndex, startIndex, endIndex)
        let dowStart = ((elementIndex - (elementIndex % (48 * 30))) /  (48 * 30));
        let eventList = List();
        eventList = eventList.push(Map({
            dow: dowStart,
            startindex: startIndex,
            endindex: endIndex,
            starttime: timeIndexToString(startIndex),
            endtime: timeIndexToString(endIndex)
        }))
        onRangeSelected(eventList)
    }

    const setSelection = (mode, index, selectionLength) => {
        if(startSelection || startSelection === 0) {
            if(mode == "hover") {
                setHoveredSelection(index)
            }
            if(mode == "click") {
                setStartSelection(null);
                setHoveredSelection(null);
                let endIndex = index + selectionLength ;
                let dowStart = ((startSelection - (startSelection % (48 * 30))) /  (48 * 30));
                let hourStart = startSelection %  (48 * 30);
                let hourEnd = endIndex %  (48 * 30);
                let dowEnd = ((endIndex - (endIndex %  (48 * 30))) /  (48 * 30));
                if(endIndex % (48 * 30) == 0) {
                    dowEnd--;
                    hourEnd = 48 * 30;   
                }
                let eventList = List();
                if(dowEnd > dowStart) {
                    eventList = eventList.push(Map({
                        dow: dowEnd,
                        startindex: 0,
                        endindex: hourEnd,
                        starttime: "00:00",
                        endtime: timeIndexToString(hourEnd)
                    }))
                    dowEnd--;
                    while(dowEnd > dowStart) {
                        eventList = eventList.push(Map({
                            dow: dowEnd,
                            startindex: 0,
                            endindex: 48 * 30,
                            starttime: "00:00",
                            endtime: "24:00"
                        }))
                        dowEnd--;
                    } 
                    eventList = eventList.push(Map({
                        dow: dowStart,
                        startindex: hourStart,
                        endindex: 48 * 30,
                        starttime: timeIndexToString(hourStart),
                        endtime: "24:00"
                    }))

                } else {
                    eventList = eventList.push(Map({
                        dow: dowStart,
                        startindex: hourStart,
                        endindex: hourEnd,
                        starttime: timeIndexToString(hourStart),
                        endtime: timeIndexToString(hourEnd)
                    }))
                }
                onRangeSelected(eventList)
            }
        } else {
            if(mode == "click") {
                let dowStart = ((index - (index % (48 * 30))) /  (48 * 30)) + 1;
                let eventsOnDay = formattedEvents.filter(x => x.dow == dowStart);
                if(eventsOnDay.length > 4) {
                    return Swal.fire('Maximum events', 'There is a limit of 5 maximum events you can add on a certain day', 'error')
                } 


                setStartSelection(index);
            }
        }
    }

    

    function EventElement({event, onModifyRequested, onRemove, onChangeEvent, span, elementIndex }) {
        const changeTimes = (newIndex) => {
            let newStart = newIndex;
            let newEnd = newIndex + span;
            removeCollidingElements(event, newStart, newEnd);
            onChangeEvent(event.original, Map({ starttime: timeIndexToString(newStart), endtime: timeIndexToString(newEnd)}))

        }

        const changeBothTimes = (startIndex, endIndex) => {
            removeCollidingElements(event, startIndex, endIndex);
            onChangeEvent(event.original, Map({ starttime: timeIndexToString(startIndex), endtime: timeIndexToString(endIndex)}))
        }

        // console.log(`20-03 EventElement span:${span} elementIndex: ${elementIndex}`);
        let eventLabel = <EventLabel label={event.label} showLabelInBubble={span < 150} bubbleOnTop={(elementIndex % (60 * 24)) > 120} onModifyRequested={() => onModifyRequested(event.original)} onRemove={() => onRemove(event.original)} onChange={(field, value) => onChangeEvent(event.original, Map({ [field]: value }) )} onChangeEvent={(e) => onChangeEvent(event.original, Map({ event: e })) } />
        // let eventClass = event.event == "on" ? "bg-green-100" : "bg-red-100";
        return <div style={{ gridRow: `span ${span} / span ${span}`, backgroundColor:'#0088cc'}} className="relative" >
            <Draggable onChange={changeTimes} show24={show24} elementIndex={elementIndex} span={span} className={`h-full w-full`}>
                <Resizable elementIndex={elementIndex} show24={show24} span={span} className={`h-full w-full`} onChange={changeBothTimes}>
                    {eventLabel}
                </Resizable>
            </Draggable>
        </div>
    }

    const headerStyle = {
        background: calendarHeaderBackgroundColor,
        color: calendarHeaderTextColor
    }
 
 // WEEKTIMES

    
    

    let gridElements = (() => {
        let elements = [];
        let lastEvent = undefined;
        for(let day = -1; day < 7; day++) {
            if(day == -1) {
                if(extraActions && extraActions.indexOf('selectWeeklyTemplate') > -1) {
                    elements.push(<div key={`#`} className="row-span-60 flex items-center" style={headerStyle}>
                        <div className="flex-1 px-2">{dayLabels[day]}</div>   
                        <div className="flex-none px-2">
                            <DayMenuButton 
                                extraActions={extraActions} 
                                actions={[]} 
                                onDayAction={(action) => onDayAction(day, action)} />
                        </div>
                    </div>);
                } else {
                    elements.push(<div key={`#`} className="row-span-60" style={headerStyle}></div>);
                }
                for(let hour = 0; hour < 24; hour++) {
                    let hourString = `0${(hour)}:00`.replace(/^[0-9]([0-9]{2})/, '$1');
                    elements.push(<div key={`#-${hour}`} className="row-span-60 px-2">{hourString}</div>) 
                }
            } else {
                elements.push(<div key={`${day}-#`} className="row-span-60 flex items-center" style={headerStyle}>
                    <div className="flex-1 px-2">{dayLabels[day]}</div>   
                    <div className="flex-none px-2">
                        <DayMenuButton onDayAction={(action) => onDayAction(day, action)} />
                    </div>
                    
                </div>) 
                let space = 0;
                for(let minute = 1; minute <= 1440; minute++) {
                    let elementIndex = day * 48 * 30 + minute - 1;
                    let event = weekMatrix[day][minute - 1];
                    if(typeof event == 'object') {
                        let initialSpace = space;
                        if(space > 0) {
                            elements.push(<EmptyCell addTime={(s,e) => addTime(elementIndex - space, s, e)} key={`${day}-${minute}-space`} startSelection={startSelection} hoveredSelection={hoveredSelection} setSelection={setSelection} selectionLength={space } elementIndex={elementIndex - space} span={space } />);
                            space = 0;
                        }
                        let span = event.bigEndIndex - event.bigStartIndex;
                        elements.push(<EventElement elementIndex={elementIndex} key={`${day}-${minute}`} onModifyRequested={onModifyRequested} onRemove={onRemove} onChangeEvent={onChangeEvent} span={span} event={event}/>)
                        lastEvent = 11;
                    } else if(event == 0) {
                        lastEvent = 0;
                    } else if(event == 1) {
                        space++
                        if(minute % 30 != 0) {

                            
                        } else {
                            if(space > 0) {
                                elements.push(<EmptyCell addTime={(s,e) => addTime(elementIndex - space, s, e)}  key={`${day}-${minute}-space`} startSelection={startSelection} hoveredSelection={hoveredSelection} setSelection={setSelection} selectionLength={space }  elementIndex={elementIndex - space + 1 } span={space } />);
                                space = 0;
                            }
                        }
                        lastEvent = 1
                    } else {
                        lastEvent = 99;
                        if(space > 0) {
                            elements.push(<EmptyCell addTime={(s,e) => addTime(elementIndex - space, s, e)}  key={`${day}-${minute}-space`} startSelection={startSelection} hoveredSelection={hoveredSelection} setSelection={setSelection} selectionLength={space } elementIndex={elementIndex - space + 1} span={space } />)
                        }
                    }
                }
            }
        }
        return elements;
    })()

    return <div className="grid grid-flow-col grid-cols-8 grid-rows-1500 divide-x divide-y border-1 auto-rows-fr ring-1 ring-gray-300 ring-inset " ref={currentGrid}>
        {gridElements}
    </div>

}

export default WeekTimes;


function EmptyCell({startSelection, hoveredSelection, setSelection, elementIndex, span, selectionLength, addTime }) {
    let elementClass = "";
    if(startSelection || startSelection === 0) {
        if(elementIndex == startSelection) {
            elementClass = " bg-red";
        } else {
            if(hoveredSelection || hoveredSelection === 0) {
                if(elementIndex > startSelection && elementIndex <= hoveredSelection) {
                    elementClass = " bg-blue"
                }
            }
        }
    } else {
        elementClass = " hover:bg-gray-200 cursor-pointer"
    }

    let timeIndex = elementIndex % 1440;
    let hours = ("0" + ((timeIndex - timeIndex % 60) / 60)).slice(-2);
    let minutes = ("0" + (timeIndex % 60)).slice(-2);

    return <div 
        dataSelectionLength={selectionLength} 
        dataIndex={elementIndex} 
        className={elementClass} 
        style={{ gridRow: `span ${span} / span ${span}`}} 
        // onMouseOut={() => setSelection('clear')} 
        // onMouseOver={() => setSelection("hover", elementIndex, selectionLength)} 
        // onClick={() => setSelection("click", elementIndex, selectionLength)}
        >
        <Tooltip content={<div>{hours}:{minutes}</div>}>
            <Expandable elementIndex={elementIndex} span={span} className="w-full h-full" onChange={addTime}/>
            {/* <div className="h-full w-full"></div> */}
        </Tooltip>
    </div>
}

const Tooltip = ({ children, content }) => {
    const [isHovering, setIsHovering] = useState(false);
  
    return (
        <div className="relative flex items-center h-full w-full">
            <div className="w-full h-full"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {children}
            </div>
            {isHovering && (
                <div className="absolute z-10 px-4 py-2 text-sm text-white bg-black rounded-lg bottom-full right-2 mb-2">
                    {content}
                </div>
            )}
        </div>
    );
};

  
export function DayMenuButton({ onDayAction, menuColor, actions = [
    'onAllDay','offAllDay','copyDay','pasteDay','repeatOnAllDays','selectTemplate'
], extraActions = [] }) {

    const buttonStyle = {
        backgroundColor: menuColor || "white"
    }

    let menuToLeft = false;
    if(extraActions.indexOf('selectWeeklyTemplate') > -1) {
        menuToLeft = true;
    }

    return <Menu as="div" className="-ml-px relative block flex">
        <Menu.Button className="relative inline-flex items-center focus:z-10 focus:outline-none">
            <span className="sr-only">Open options</span>
            <div aria-hidden="true">
                <div className="h-[2px] min-w-[15px] w-full" style={buttonStyle}></div>
                <div className="h-[2px] mt-[2px] min-w-[15px] w-full" style={buttonStyle}></div>
                <div className="h-[2px] mt-[2px] min-w-[15px] w-full" style={buttonStyle}></div>

            </div>
        </Menu.Button>
        <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        >
        <Menu.Items className={`origin-top-right z-[11] text-[black] absolute ${menuToLeft ? 'left' : 'right'}-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
            <div className="py-1">
                { actions.indexOf('onAllDay') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("onAllDay")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            On all day
                        </a>
                    )}
                </Menu.Item> }
                 { actions.indexOf('offAllDay') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("offAllDay")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            Off all day
                        </a>
                    )}
                </Menu.Item> }
                 { actions.indexOf('copyDay') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("copyDay")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            Copy
                        </a>
                    )}
                </Menu.Item> }
                 { actions.indexOf('pasteDay') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("pasteDay")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            Paste
                        </a>
                    )}
                </Menu.Item> }
                 { actions.indexOf('repeatOnAllDays') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("repeatOnAllDays")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            Repeat on all days
                        </a>
                    )}
                </Menu.Item> }
                 { actions.indexOf('selectTemplate') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("selectTemplate")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            Select template
                        </a>
                    )}
                </Menu.Item> }
                { extraActions.indexOf('selectWeeklyTemplate') > -1 && <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={() => onDayAction("selectWeeklyTemplate")}
                            className={'block px-4 py-2 text-sm cursor-pointer hover:bg-[blue] hover:text-[white]'}
                        >
                            Select template
                        </a>
                    )}
                </Menu.Item> }
            </div>
        </Menu.Items>
        </Transition>
    </Menu>
}