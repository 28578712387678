import ImageComponent, { ImageFactory as ImageFactoryComponent } from './Components/Image';
import EzyButtonComponent from './Components/EzyButton';
import DynamicTableComponent from './Components/DynamicTable';
import SystemObjectComponent from './Components/SystemObject';
import TextComponent from './Components/Text';
import TextButtonComponent from './Components/TextButton';
import CustomTextButtonComponent from './Components/CustomTextButton';
import StatusButtonComponent from './Components/StatusButton';
import TextMultilineComponent from './Components/TextMultiline';
import PointListComponent from './Components/PointList';
import LegacyBackdropLayerComponent from './Components/LegacyBackdropLayer';
import TextDigitalComponent from './Components/TextDigital';
import CompassComponent from './Components/Compass';
import DialComponent from './Components/Dial';
import HotspotComponent from './Components/Hotspot';
import ChartComponent from './Components/Chart';
import ReportComponent from './Components/Report';
import DateTimeTextComponent from './Components/DateTimeText';
import ShapeComponent from './Components/Shape';
import PipeComponent from './Components/Pipe';
import PathComponent from './Components/Path';
import GaugeComponent from './Components/Gauge';
import DuctComponent from './Components/Duct';
import LineComponent from './Components/Line';

export const DynamicTable = DynamicTableComponent;
export const EzyButton = EzyButtonComponent;
export const Image = ImageComponent;
export const SystemObject = SystemObjectComponent;
export const Text = TextComponent;
export const TextButton = TextButtonComponent;
export const CustomTextButton = CustomTextButtonComponent;
export const StatusButton = StatusButtonComponent;
export const TextMultiline = TextMultilineComponent;
export const PointList = PointListComponent;
export const LegacyBackdropLayer = LegacyBackdropLayerComponent;
export const TextDigital = TextDigitalComponent;
export const Compass = CompassComponent;
export const Dial = DialComponent;
export const Hotspot = HotspotComponent;
export const Chart = ChartComponent;
export const Report = ReportComponent;
export const DateTimeText = DateTimeTextComponent;
export const Shape = ShapeComponent;
export const Pipe = PipeComponent;
export const Path = PathComponent;
export const Gauge = GaugeComponent;
export const Duct = DuctComponent;
export const Line = LineComponent;
export const ImageFactory = ImageFactoryComponent;
