import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

export function TooltipPortal({ children }) {


    const elRef = useRef(document.createElement('div'));

    useEffect(() => {

        const el = elRef.current;
        const container = document.getElementById('tooltip-portal');
        container.appendChild(el);

        return () => {
            container.removeChild(el);
        }

    }, [])

    return ReactDOM.createPortal(
        children,
        elRef.current
    );


}

export function TooltipHoverable({ children, tooltip }) {

    const tooltipStyleRef = useRef({});
    const [visible, setVisible] = useState(false);

    const onMouseEnter = (e) => {

        let elementHeight = e.target.clientHeight;
        let elementYPosition = e.target.getBoundingClientRect().top;

        let tooltipTopLocation = elementYPosition + elementHeight;
        let elementRightBoundry = e.target.getBoundingClientRect().right;

        let screenWidth = window.innerWidth;


        tooltipStyleRef.current = {};
        tooltipStyleRef.current.right = (screenWidth - elementRightBoundry) + 'px';
        tooltipStyleRef.current.top = tooltipTopLocation + 'px';
        setVisible(true);
    }

    const onMouseLeave = (e) => {
        tooltipStyleRef.current = {};
        tooltipStyleRef.current.left = '-1000px';
        tooltipStyleRef.current.top = '-1000px';
        setVisible(false);
    }

    return <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
        {visible && <TooltipPortal>
            <div className="z-[10000] absolute px-3 py-2 text-base text-white rounded-lg bg-black/80 mt-2" style={tooltipStyleRef.current} >{tooltip}</div>
        </TooltipPortal>}
    </div>

}