import React, {useState} from 'react';
import TransitioningDialog from 'Controls/Dialog/TransitioningDialog';
import SettingsPanel from 'Controls/SettingsForm/SettingsPanel';
import Input from 'Controls/Form/Input';
import Swal from 'sweetalert2';
import { useUserSettings } from 'Context/UserSettings';
import { Map } from 'immutable';
import Button from 'Controls/Button/UserDefined'

const UserDisplaySettingsDialog = ({ onClose, active }) => {

    const { userSettings, setUserSettings, saveUserSettings, resetUserSettings } = useUserSettings();

    // console.log("%c userSettings", "color: gold;font-weight:bold;", userSettings);


    const changeSettings = () => {
        saveUserSettings().then(() => {
            onClose();
        })
       
    }

    const closeDialog = () => {
        resetUserSettings();
        onClose();
    }

    return (<TransitioningDialog panelWidthClass="min-w-min max-w-max " onClose={onClose} opened={!!active}>

    <SettingsPanel settings={userSettings} setSettings={setUserSettings} label={`User Settings`} />

        <div className="flex">
            <div className="flex-none p-2">
                <Button role="save" className="" onClick={() => changeSettings()}>Save</Button>
            </div>
            <div className="flex-none p-2">
                <Button role="close" className="" onClick={() => closeDialog()}>Close</Button>
            </div>
        </div>

    </TransitioningDialog>)

}

export default UserDisplaySettingsDialog;
