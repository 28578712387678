import axios from 'axios';
import Configuration from 'Configuration';

export function getAssetConfiguration() {
    return new Promise((resolve, reject) => {
        axios.get('/files/common/asset.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getAssets(customer, connection) {
    return new Promise((resolve,reject) => {
        axios.get(`/api/assets?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}


export function saveAsset(customer, connection, asset) {
    return new Promise((resolve,reject) => {
        if (asset.get('_id')) {
            axios.put(`/api/assets/${asset.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, asset.toJS()).then(resolve).catch(reject);
        }
        else {
            axios.post(`/api/assets?database=${Configuration.product}${customer}&connection=${connection}`, asset.toJS()).then(resolve).catch(reject);
        }
    });
}

export function deleteAsset(customer, connection, asset) {
    return new Promise((resolve,reject) => {
        if (asset.get('_id')) {
            axios.delete(`/api/assets/${asset.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            })
        }
    });
}

