import React, { useState } from 'react';
import Immutable, { fromJS } from 'immutable';
import ColorPicker from 'Controls/ColorPicker';
import Checkbox from 'Controls/Form/Checkbox';
import Select from 'Controls/Form/Select';

const Gradient = ({ gradient, label, gradients, onChange, type, gradientField, defaultGrads }) => {
    const gradientEnabled = (value) => {
        if (value) {
            onChange(['configuration', `grads${gradientField}`], defaultGrads);
        }

        onChange(['configuration', `gradient${gradientField}`], value);
    }

    const gradientChanged = (field, value) => {
        let idx = gradients.findIndex(g => g.get('stop') == field);

        onChange(['configuration', `grads${gradientField}`, idx, 'colour'], value);
    }

    const gradientTemplateChanged = (value) => {
        var grads;

        switch (value) {
            case 'pipe':
                grads = fromJS([
                    { "stop": "0%", "colour": "#6B6B6B" },
                    { "stop": "50%", "colour": "#EBEBEB" },
                    { "stop": "100%", "colour": "#6B6B6B" }]);
                break;

            case 'ezyButton':
                grads = fromJS([
                    { "stop": "0%", "colour": "#00aad4" },
                    { "stop": "100%", "colour": "#004050" }]);
                break;

            default:
                grads = fromJS([
                    { "stop": "0%", "colour": "#6B6B6B" },
                    { "stop": "100%", "colour": "#EBEBEB" }]);
                break;
        }
        onChange(['configuration', 'grads'], grads);
        onChange(['configuration', 'gradienttype'], value);
    }

    return (
        <div>
            <Checkbox
                label={label}
                checked={gradient}
                onChange={(value) => gradientEnabled(value)} />

            {gradient && type &&
                <div className="grid grid-cols-8 mt-2 gap-2">
                    <div className="col-span-8 md:col-span-2">
                        <Select
                            value={type}
                            onChange={(value) => gradientTemplateChanged(value)}
                            options={Immutable.List([{ 'value': 'simple', 'text': 'Simple' }, { 'value': 'pipe', 'text': 'Pipe' }, { 'value': 'ezybutton', 'text': 'Ezy Button' }])}
                        />
                    </div>
                </div>}
            {gradient &&
                <div className="grid grid-cols-8 gap-2">
                    {gradients.map((g) => {
                        return (
                            <div className="col-span-8 md:col-span-1">
                                <ColorPicker label={g.get('stop')} value={g.get('colour')} onChange={(value) => gradientChanged(g.get('stop'), value)} />
                            </div>)
                    })}
                </div>}
        </div>)
}

export default Gradient;

