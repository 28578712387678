import React, {useState} from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';

const EngUnit = ({engunitlist, changedInput, engunit}) => {
    let [change, setChange] = useState(false);

    return(
        <React.Fragment>
            <div className="col-span-3"></div>
            <div className="col-span-3">
                <Input onChange={(value) => changedInput(value)} value={engunit} />
            </div>
            <div className="col-span-3"> 
                <Button role="primary" onClick={() => setChange(!change)}>Change</Button>
            </div>    
            {change ? 
                <div className="col-span-3">
                    <Select
                        value={engunit}
                        onChange={(value) => changedInput(value)}
                        options={(engunitlist || []).map((engUnit) => {
                            return {
                                value: engUnit.engunit,
                                text: engUnit.engunit
                            }
                        })}
                    />
                </div>:<div className="col-span-3"/>} 
        </React.Fragment>
    )
}

export default EngUnit;
