import React, { useState, useEffect, useMemo } from 'react';
import {connect} from 'react-redux';
import Immutable, { List, Map, fromJS } from 'immutable';
import ServiceBus from 'Services/ServiceBus';
import Button from 'Controls/Button/UserDefined';
import ChartHistories from 'BmsView/Customer/Histories/Chart';
import { withBMS, useBMS } from 'BmsView/Context/BMS';
import { getStartOfWeek } from 'Utils/Date';
import debuggerService from 'Services/DebuggerService';
import { HistoriesTable } from '../../../../Histories/HistoriesDialog';
import useAreaCustomer from '../../Hooks/useAreaCustomer';

const logger = debuggerService.getDebugger('Logging');

function Logging({ selectedpoint, address }) {

    // const { customer } = useBMS();
    const { customer } = useAreaCustomer();

    const point = selectedpoint?.get('pointconfiguration');
    let _selectedPoints = List([]);
    if(point) {
        const pointselected = Map({
            'name': point.get('m_pointName'),
            'address': address.join('-'),
            'engunits': [point.get('m_stateText').get(0)],
            'colour': 'blue'
        });
        _selectedPoints = List([pointselected]);
    }

    const [selectedpoints] = useState(_selectedPoints);
    const [records, setRecords] = useState(List());
    const [dateStart, setDateStart] = useState(getStartOfWeek());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [displayMode, setDisplayMode] = useState('chart');

    const getLoggingData = () => {
        let addressAsInt = address.map((byte) => parseInt(byte));
        let request = {
            type: 'GENERIC',
            trackMessage: true,
            m_level: 10,
            m_communicNum: 307,
            m_guiPoll: 0,
            m_subsystemNumber: addressAsInt[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr'
        }
        let comm = {
            m_pointAddr: address.map((value) => { return parseInt(value); })
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const getLogginDataHandler = (msg) => {
        if (msg.m_errorNumber != 0) setRecords(undefined);

        if (!msg.m_sample) return;
        let dtStart = new Date(msg.m_unixTimeFirst * 1000);
        let dtCur = msg.m_unixTimeFirst - msg.m_intervalInSecs;

        let samples = (msg.m_sample || []).map((sample) => {
            dtCur= dtCur + msg.m_intervalInSecs;
            return {
                t: new Date(dtCur * 1000).toISOString(),
                v: sample
            }
        });

        let dtEnd = new Date(dtCur * 1000);
        
        setDateStart(dtStart);
        setDateEnd(dtEnd);
        setRecords(fromJS([{
            name: msg.m_pointAddr.join('-'),
            time: `${dtStart.getFullYear()}-${("0" + (dtStart.getMonth() + 1)).slice(-2)}-${("0" + dtStart.getDate()).slice(-2)}`,
            samples: samples
        }]));

    }

    useEffect(() => {
        ServiceBus.on('307-11', getLogginDataHandler);
        if (selectedpoints.size > 0) getLoggingData();
        return () => {
            ServiceBus.off('307-11', getLogginDataHandler);
        }
    }, []);

    let showChart = displayMode == 'chart' && records && selectedpoints.size > 0;
    let showTable = displayMode == 'table' && records && selectedpoints.size > 0;

    if(records && records.size < 1 && selectedpoints.size > 0 ) {
        return  <div className="text-center p-2">Please wait while we retrieve the logging data ...</div>
    }

    return (
        <div > 
            {!records && <div>Point not logging</div>}
            {selectedpoints.size < 1 && <div>Logging not available</div>}
            {records && records.size < 1 && selectedpoints.size > 0  && <div>Please wait while we retrieve the logging data ...</div>}

            {showChart && <div style={{ width: "100%", height: "500px" }}>
                <ChartHistories records={records?.toJS()} dateStart={dateStart} dateEnd={dateEnd} selectedpoints={selectedpoints}/>
            </div>}
            {showTable && <div>
                
                <HistoriesTable records={records?.toJS()} selectedpoints={selectedpoints} showDate={true} reverseOrder={true} />    
            </div>}
            <div className="flex flex-row justify-end p-2">
                
                <Button role="primary" onClick={() => setDisplayMode(x => x=='chart' ? 'table' : 'chart' )}>

                    { displayMode == 'chart' ? 'Show Table' : 'Show Chart'}

                </Button>

            </div>
        </div>    
    );


}



export default Logging;