import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import ColorPicker from 'Controls/ColorPicker';

export default function Report({customer, selectedcomponent, onChange}) {
    let dateRangeSelectionTypeFields = ['','This Year','Last Year','This Month', 'Last Month', 'This Week', 'Last Week'];
    let periodSelectionTypeFields = ['','Monthly','Weekly','Daily'];

    const valueChanged = (field, value) => {
        onChange(field, value);
    }

    return (
        <React.Fragment>          
            <Select
                label="Default Date Range"
                value={selectedcomponent.getIn(['configuration', 'duration'])}
                onChange={(value) => valueChanged(['configuration', 'duration'], value)}
                options={dateRangeSelectionTypeFields.map((type) => {
                    return {
                        value: type,
                        text: type
                    }
                })}
            />
            <Select
                    label="Default Period"
                    value={selectedcomponent.getIn(['configuration', 'period'])}
                    onChange={(value) => valueChanged(['configuration', 'period'], value)}
                    options={periodSelectionTypeFields.map((type) => {
                        return {
                            value: type,
                            text: type
                        }
                    })}
            />
            <Input
                label="Report Header"
                type="text"
                value={selectedcomponent.getIn(['configuration', 'header'])}
                onChange={(value) => valueChanged(['configuration', 'header'], value)}
            /> 
        </React.Fragment>
    )
}

   