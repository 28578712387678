import { forwardRef, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

export default forwardRef(function StatusBar({ children, className }, statusRef) {

    // console.log("rendering status bar")

    return createPortal(
        <div ref={statusRef} className="absolute hidden bottom-1 w-full">
            <div className="flex flex-row justify-center items-center w-full">
                <div className=" whitespace-nowrap w-min bg-black/50 text-white  px-4 py-1">{children}</div>
            </div>
        </div>,
        document.body
    )

})