import axios from 'axios';
import Store from 'Store';
import {fromJS} from 'immutable';

export function loadAllCustomers(){
    return new Promise((resolve, reject) => {
        axios.get('/customers/all').then((response) => {
            Store.dispatch({
                type: 'ALL_CUSTOMERS_READY',
                data: response.data
            });
            resolve(response.data);
        }).catch(reject);
    });
} 

export function loadCustomers(customerlist) {
    return new Promise((resolve, reject) => {
        axios.get('/customers', {
            params: {
                customers:customerlist
            }
        }).then((response) => {
            Store.dispatch({
                type:'CUSTOMERS_READY',
                data: response.data
            });
            resolve(response.data);
        }).catch(reject);
    });
}

export function dispatchCustomers(customers) {
    Store.dispatch({
        type: 'CUSTOMERS_READY',
        data: customers
    });

}

export function fetchCustomers() {
    return new Promise((resolve, reject) => {
        axios.get('/customers').then((response) => {
            resolve(fromJS(response.data));
        }).catch(reject);
    });

}

export function setCustomerAndSite(customer, site){
    Store.dispatch({
        type: 'CUSTOMER_AND_SITE_READY',
        customer: fromJS(customer),
        site: fromJS(site)
    });
}

export function setCustomer(customer){
    Store.dispatch({
        type: 'CUSTOMER_READY',
        customer: customer
    });
}

export function getCustomerUserGroups(customer){
    return new Promise((resolve, reject) => {
        let q = {
            customer: { $in: {customer} }
        };
        axios.get('/api/usergroups', { selector: q }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    })
}

export function setCustomerUserGroups(groups){
    Store.dispatch({
        type: 'CUSTOMER_USER_GROUPS_READY',
        customerusergroups: groups
    });
}