import React from 'react';


export default (props) => {
    let { fields, children } = props;
    try {
        return React.Children.map(children, (child) => {
            if (!child){
                return;
            }
            return React.cloneElement(child, {
                fields: fields
            });
        });
    }
    catch (err) {
    }
}