import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useNavigate, redirect, useRevalidator } from "react-router-dom";
import { getSiteFile } from "./Site/Actions";
import { PauseCircleIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { useUserSettings } from "../../Context/UserSettings";
import { useCustomerSettings } from "../../Context/CustomerSettings";
import { useBMS } from "BmsView/Context/BMS";

// import { useRotatings } from "./Context/RotatingContext";

const getRotationUrls = (treenodes, path = []) => {
    let rotations = [];
    
    for(let node of treenodes) {
        let localPath = [...path]

        if(node.get('rotating')) {
            localPath.push(node.get('name').split(' ').join('-').toLowerCase());
            rotations.push(localPath.join('/'));
        } else {
            localPath.push(node.get('name').split(' ').join('-').toLowerCase());
        }

        if(node.get('treenodes')) {
            rotations = [...rotations, ...getRotationUrls(node.get('treenodes'), [...localPath])];
        }

       
    }
    return rotations;
}

const getRotationsFromAllSites = async (customer) => {
    let sites = customer.get('sites');
    let customerName = customer.get('name').toLowerCase().split(' ').join('-');
    let rotations = [];
    for (let site of sites) {
        // console.log("26-02 site", site);
        let siteName = site.get('name').toLowerCase().split(' ').join('-');
        let response = await getSiteFile(customerName, siteName);
        rotations = [...rotations, ...getRotationUrls(response, ['customer',customerName,'site' ,siteName])];
    }
    // console.log("26-02 Rotations", rotations);
    return rotations;
}

const InternalRotatingScreen = ({ rotations, rotationInterval }) => {
    const navigate = useNavigate();
    const [currentRotationIndex, setCurrentRotationIndex] = useState(0);
    const rotationTimerRef = useRef();

    const scheduleNextRotation = () => {
        rotationTimerRef.current = setTimeout(() => {
            const nextIndex = (currentRotationIndex + 1) % rotations.length;
            setCurrentRotationIndex(nextIndex);
        }, rotationInterval);
    };

    useEffect(() => {
        // Ensure rotations array is not empty and navigation is needed
        if (rotations.length > 0) {
            const rotationUrl = rotations[currentRotationIndex];
            if (rotationUrl && window.location.pathname !== rotationUrl) {
                // console.log("rotationUrl", rotationUrl);
                navigate("/" + rotationUrl);
            }
            scheduleNextRotation();
        }

        // Cleanup function to clear the timeout when the component unmounts or rotations change
        return () => clearTimeout(rotationTimerRef.current);
    }, [currentRotationIndex, rotations, navigate, rotationInterval]);

    // Optionally, handle component unmount or other cleanup actions
    useEffect(() => {
        return () => clearTimeout(rotationTimerRef.current);
    }, []);

    // Render nothing or a loading state until rotations are ready
    if (!rotations.length) {
        return null; // Or some placeholder content
    }

    return (
        // Your rotating screen component content here
        null
    );
};

export default function RotatingScreen({ customer }) {

    const { customerSettings } = useCustomerSettings();
    const { userSettings } = useUserSettings();

    const { revalidateArea } = useBMS();

    let rotationEnabled = customerSettings.get('rotationEnabled');
    rotationEnabled = rotationEnabled || userSettings.get('rotationEnabled') || false;

    let rotationInterval = customerSettings.get('rotationInterval');
    rotationInterval = userSettings.get('rotationInterval') || rotationInterval || 30;
    rotationInterval = rotationInterval * 1000;

    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const [playRotation, setPlayRotation] = useState(false);
    const currentRotationRef = useRef(0);
    
    const [rotations, setRotations] = useState([]);
    const rotationsRef = useRef();


    // const { rotations } = useRotatings();

    useEffect(() => {
        if(customer) {
            
            // console.log("26-02 RotationScreen change in customer", customer);
            (async (customer) => {
                let _rotations = await getRotationsFromAllSites(customer);
                setRotations(_rotations);
                // console.log("26-02 Rotations", _rotations);
                rotationsRef.current = _rotations;
                currentRotationRef.current = 0; 
            })(customer);
        }

        return () => {
            // console.log("111 unmounting rotating screen");
        }

       

    }, [customer])


    useEffect(() => {
        // console.log("111 rotating screen mount");
    }, [])

    // useEffect(() => {

    //     console.log("0503 useEffect in rotation screen");
    //     console.log("0503 rotation set at ", rotationInterval);

    //     if(playRotation) {
    //         const interval = setInterval(() => {

    //             console.log(`111 current rotation ${currentRotationRef.current} of ${rotationsRef.current.length}`)


    //             if (currentRotationRef.current < rotationsRef.current.length - 1) {
    //                 currentRotationRef.current = (currentRotationRef.current || 0) + 1;
    //             } else {
    //                 currentRotationRef.current = 0;
    //             }
    //             console.log("26-02 CURRENT ROTATION", currentRotationRef.current)
    //             if(rotationsRef.current[currentRotationRef.current]) {
    //                 let rotationUrl = '/' + rotationsRef.current[currentRotationRef.current]
    //                 console.log("111 rotation url " + rotationUrl);
    //                 // revalidateArea();
    //                 navigate(rotationUrl);
                    
    //                 // revalidator.revalidate();
    //             }
    //             // history.go(0);
    //         }, rotationInterval)

    //         return () => {
    //             console.log("26-02 unmounting rotating screen");
    //             clearInterval(interval);
    //         }
    //     }

    // }, [playRotation, rotationEnabled, rotationInterval, navigate])

    useEffect(() => {

        // console.log("21-02 mounting rotating screen");
        // setRotations([
        //     '/customer/demo-site/site/enfield-office/dhws',
        //     '/customer/demo-site/site/enfield-office/plant-performance',
        //     '/customer/demo-site/site/enfield/'

        // ]);
        // setCurrentRotation(0);

        // const interval = setInterval(() => {
        //     if (currentRotationRef.current < rotations.length - 1) {
        //         setCurrentRotation((currentRotation) => currentRotation + 1);
        //     } else {
        //         setCurrentRotation(() => 0);
        //     }
        //     console.log("21-02 CURRENT ROTATION", currentRotationRef.current)
        //     history.push(rotations[currentRotationRef.current]);
        //     // history.go(0);
        // }, 3000)

        // return () => {
        //     console.log("21-02 unmounting rotating screen");
        //     clearInterval(interval);
        // }

    }, []);

    if(!rotationEnabled || !rotations?.length) {
        return null;
    }



    return (
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-blue-500">
            <RotatingButton>
            <div className="flex flex-row gap-2 items-center text-white bg-white/10 rounded-lg cursor-pointer py-3 px-6" onClick={() => setPlayRotation((x) => !x)} >
                {playRotation ? 
                    <PauseCircleIcon className="w-6 h-6 text-white cursor-pointer" /> : 
                    <PlayCircleIcon  className="w-6 h-6 text-white cursor-pointer" />}
               {playRotation ? '' : '' }
           </div>
           </RotatingButton>
           { playRotation && <InternalRotatingScreen rotations={rotations} rotationInterval={rotationInterval} /> }
        </div>
    );
}

export function RotatingButton({ children }) {

    const elRef = useRef(document.createElement('div'));

    useEffect(() => {

        const el = elRef.current;
        const container = document.getElementById('rotation-button');
        container.appendChild(el);

        return () => {
            container.removeChild(el);
        }

    }, [])

    return ReactDOM.createPortal(
        children,
        elRef.current
    );
}