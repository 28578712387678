import React from 'react';
import {SketchPicker} from 'react-color';

class ColorPicker extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            selecting: false
        }

        this.toggleSelectionMode = this.toggleSelectionMode.bind(this);
        this.selectColor = this.selectColor.bind(this);
    }

    toggleSelectionMode(){ 
        this.setState(({selecting}) => ({
            selecting: !selecting
        }));
    }

    selectColor(color){
        let {onChange} = this.props;

        if (onChange){
            onChange(color.hex);
        }
    }

    render(){
        let {label, error, value} = this.props;
        let {selecting} = this.state;

        return (
            <div className="w-full">
                {label &&
                    <label className="block label" htmlFor={label}>
                        {label}
                    </label>}
                <div className="w-100 h-10" style={{ border: '1px solid #eee', backgroundColor: value || '#fff' }} onClick={this.toggleSelectionMode}></div>
                {error && <p className="errorMsg">{error}</p>}
                <div className="absolute" style={{zIndex: 10}}>{selecting && <SketchPicker onChangeComplete={this.selectColor } color={value || '#fff'}/>}</div>
            </div>
        )
    }
}

export default ColorPicker;