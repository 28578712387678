import React, { useState } from 'react';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import { ExclamationTriangleIcon, BellSlashIcon, BellAlertIcon, InformationCircleIcon, WrenchScrewdriverIcon, CheckCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
// import { alarmDisplayInfo, backgroundColour, backgroundBorderColour, alarmTextColour } from '../../Logs/AlarmListPanel';
import AlarmRecord from '../../../../Components/Alarms/AlarmRecord';
import AlarmDetailsDialog from '../../Logs/AlarmDetailsDialog';

function AlarmDetailsListDialog({selecteditem, onClose, onSave, onAck, alarms, ...props}) {
    return <Dialog opened={!!selecteditem} alarms={alarms} onClose={onClose} onSave={onSave}>
        <AlarmDetailsListDialogPanel onAck={onAck} selecteditem={selecteditem} alarms={alarms} onClose={onClose} onSave={onSave}{...props} />
    </Dialog>
}

const AlarmDetailsListDialogPanel = ({alarms, onClose, onSave, onAck, siteAlarmFilterGroups, alarmsAcknowledged, alarmsToAcknowledge }) => {

    let timenow = new Date();
    const [selectedAlarm, setSelectedAlarm] = useState(undefined);

    const acknowledgingAlarms = alarmsAcknowledged > 0 && alarmsAcknowledged < alarmsToAcknowledge;


    return (
        <DialogContent> 
            {selectedAlarm && <AlarmDetailsDialog selecteditem={selectedAlarm} onClose={() => setSelectedAlarm(undefined)} />}
            <DialogHeader>
                <DialogTitle as="h3" className="text-lg leading-6 font-medium text-black">Alarm Details</DialogTitle>
            </DialogHeader> 
            <DialogBody className="md:w-[60vw] relative">
                {acknowledgingAlarms && <div className="absolute bg-white/70 w-full h-full top-0 left-0 flex flex-col justify-center items-center backdrop-blur-md">
                    <div className="text-2xl">Acknowledging alarms</div>
                    <div className="text-2xl">{alarmsAcknowledged}/{alarmsToAcknowledge}</div>
                </div> }
                <ul role="list" className="divide-y divide-gray-200 min-h-[10vh] max-h-[80vh] overflow-y-auto">
                    {(alarms || []).map((alarm, index) =>  { return (
                        <li key={index} className="py-1 text-black">
                            {/* {displayAlarm(alarm)} */}
                            <AlarmRecord alarm={alarm} siteAlarmFilterGroups={siteAlarmFilterGroups} >
                                <AlarmRecord.AcknowledgeButton alarm={alarm} onAck={onAck} />
                                <AlarmRecord.ShowDetailsButton alarm={alarm} displayAlarmDetails={(alarm) => setSelectedAlarm(alarm)} />
                                </AlarmRecord>
                        </li>)}
                    ).reverse()}
                </ul>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="success" disabled={acknowledgingAlarms} onClick={onSave}>Acknowledge All</Button></div>
                <div className="pl-1"><Button role="secondary" onClick={onClose}>Close</Button></div>
            </DialogFooter>
        </DialogContent>
    )
}


export default AlarmDetailsListDialog;

// function AlarmRecordAcknowledgeButton({ alarm, onAck }) {
//     return <div className="flex" onClick={() => onAck(alarm)}>
//         Ack
//         <div><CheckCircleIcon className="ml-2 h-6 w-6 text-red-900 cursor-hand" aria-hidden="true" /></div>
//     </div>
// }

// function AlarmRecordShowDetailsButton({ alarm, displayAlarmDetails }) {
//     return <div className="flex">
//         <div><MagnifyingGlassIcon className="ml-2 h-6 w-6 text-red-900 cursor-hand" aria-hidden="true" onClick={() => displayAlarmDetails(alarm)} /></div>
//     </div>
// }


// function AlarmRecord({ alarm, children }) {
//     let Icon = alarmDisplayInfo[alarm.get('icon')].Icon;
      
        

//     return (  
//         alarm.get('icon') != undefined &&
//             <div className={`rounded-md ${backgroundColour[alarmDisplayInfo[alarm.get('icon')].colour]} p-2 border-4 border-double ${backgroundBorderColour[alarmDisplayInfo[alarm.get('icon')].colour]}`}>
//                 <div className="flex item-center justify-between">
//                     <Icon className={`${alarmDisplayInfo[alarm.get('icon')].animate ? 'animate-ping h-4 w-4' : 'h-6 w-6'} ${alarmTextColour[alarmDisplayInfo[alarm.get('icon')].colour]} aria-hidden=true`} />
//                     <div className="ml-4"><h3>{alarmDisplayInfo[alarm.get('icon')].type}</h3></div>
//                     <div className="ml-4">{alarm.get('datetime')}</div>
//                 </div>
//                 <div className="flex ml-8 item-center justify-between">
//                     <div>
//                         Point : { alarm.get('point')}
//                     </div>
//                     <div>
//                         Panel : { alarm.get('panel')}
//                     </div>
//                 </div>
//                 <div className="flex ml-8 item-center justify-between">
//                     <pre>
//                         {alarm.get('paneltext')}
//                     </pre>
//                 </div>
//                 <div className="flex ml-8 item-center justify-between">
//                     <div>
//                         { alarm.get('messagetext')}
//                     </div>
//                 </div>
//                 <div className="flex ml-8  justify-between">
//                     <div>
//                         Priority : { alarm.get('priority')}
//                     </div>
//                     {children}
//                 </div>
//                 { alarm.get('exttxt') && alarm.get('icon') != 0 &&
//                 <div className="ml-8 mt-4">
//                     Detailed Information :</div> }
//                 { alarm.get('exttxt') && alarm.get('icon') != 0 && <div className="ml-8">
//                     <pre>{alarm.get('exttxt')}</pre></div>}
//             </div>
//     )
// }