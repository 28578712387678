import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { fromJS, List, Map, OrderedMap, set } from "immutable";
import { searchGroups, searchPoints } from '../Actions';
import { getTimeTemplates } from './TimeTemplates/Actions';
import CalendarPanel from './CalendarPanelView';
import PointGroupSelectionDialog from '../PointGroupSelectionDialog';
import { DayTemplateSelection } from './TimeTemplates';
import TransitioningDialog from '../../../../Controls/Dialog/TransitioningDialog';
import Button from 'Controls/Button/UserDefined';
import ContextMenu from '../../../../Controls/ContextMenu';
import { MdFaceRetouchingNatural } from 'react-icons/md';

const getTemplates = getTimeTemplates;

const emptyCalendar = fromJS({ name: 'New calendar', days: [], template: '', pointGroups: [] })

export default function CalendarPanelContainer({ calendar, onChange, onSave, onClose, customer, groups, calendars, onSelectCalendar, onAddCalendar, onDeleteCalendar, onDownloadCalendar }) {

    const [displayTemplateSelectionDialog, toggleTemplateSelectionDialog] = useState(false);
    const [templates, setTemplates] = useState(List());
    const [points, setPoints] = useState(List());
    const [currentContextMenu, setCurrentContextMenu] = useState(undefined);
    const [selectedRange, setSelectedRange] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(undefined);

    const customerName  = customer.get('name').toLowerCase().replace(/ /g,"");
    const customerLocation = customer.get('ipaddress').toLowerCase();
    const calendarSelected = calendar != undefined;
    const name = (calendar || emptyCalendar).get('name') || "";
    const dates = (calendar || emptyCalendar).get('days');
    const template = (calendar || emptyCalendar).get('template');
    const currentGroups = (calendar || emptyCalendar).get('pointgroups');
    const selectedGroups = (currentGroups || List()).map(x => groups.get(x) || Map()).map(x => x.set('label', x.get('name')));
    const availableGroups = (groups || List()).toList().map(x => x.set('label', x.get('name')) ).filter(x => selectedGroups.findIndex((y) => y.get('label') == x.get('label')) == -1);
    const selectedTemplate = templates.findIndex(x => x.get('_id') == template);

    const dateIsFuture = useMemo(() => {
        if(selectedDate) {
            let unixSelectedDate = Date.parse(`${selectedDate} 00:00:00.000 +00:00`);
            let unixToday = Date.parse(`${(new Date()).toISOString().replace(/T.*/g, '')} 00:00:00.000 +00:00`);
            return unixSelectedDate > unixToday;
        }
        return false;
    }, [selectedDate])

    useEffect(() => {
        if(customer) {
            (async () => {
                let data = await getTemplates(customerName, customerLocation);
                // console.log("templates got from promise", data);
                setTemplates(fromJS(data));
            })();
        }
    }, [customer]);

    useEffect(() => {
        if(groups && groups.size > 0) {
            (async () => {
                // At the moment i cannot add points to group im waiting for a response
                // in the meantime i continue on another part but should not forget to return
                // to this part.
                let fetchGroups = (currentGroups || List()).toJS();
                let points = [];
                while(fetchGroups.length > 0) {
                    let groupIdToFetch = fetchGroups.shift();
                    let groupToFetch = groups.get(groupIdToFetch);

                    let data = await searchPoints(customerName, customerLocation, { groups: groupToFetch.get('name') });
                    points.push(...data);
                }
                setPoints(fromJS(points));
            })();
        }
        
    }, [groups, currentGroups]);

    const onChangeName = (value) => {
        onChange(calendar.set('name', value));
    }

    const formatTSToSystemDate = (date) => {
        return (new Date(date)).toISOString().replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2}).*/g, '$3/$2/$1')
    }

    const onRemoveSingleDate = (date) => {
        onChange(calendar.update('days', x => x.filter(y => {
            return y != date
        })));
    }

    const onSelectedGroups = (leftList, rightList) => {
        let changedList = leftList.map(x => x.get('_id'));
        onChange(calendar.set('pointgroups', changedList))
    }

    const onChangeTemplate = (index) => {
        let selectedTemplate = templates.get(index);
        onChange(calendar.set('template', selectedTemplate && selectedTemplate.get('_id') || null))
    }

    const onAddRange = (range) => {
        // console.log("onAddRange is being called");
        setSelectedDate(undefined);
        setSelectedRange(undefined);
        toggleTemplateSelectionDialog(range);
    }

    const onEditRange = (range, selectedDate) => {
        // console.log("onEditRange is being called");
        toggleTemplateSelectionDialog(range);
        setSelectedRange(range);
        setSelectedDate(selectedDate);
    }

    const onSaveRange = (range, template) => {
        if(selectedRange) {
            let changedCalendar = calendar.update('days', List(), x => x.map(y => {
                if(y.get('range') == selectedRange.get('reference')) {
                    return y.set('template', template.get('_id'));
                }
                return y;
            }));
            onChange(changedCalendar);
            toggleTemplateSelectionDialog(undefined);
            setCurrentContextMenu(undefined);
            setSelectedRange(undefined);
            setSelectedDate(undefined);
            return;
        }
        let changedCalendar = calendar.update('days', List(), x => x.push(Map({
            range: range,
            template: template.get('_id')
        })));
        onChange(changedCalendar);
        toggleTemplateSelectionDialog(undefined);
    }

    const onRemoveRange = (range) => {
        if(currentContextMenu) {
            setSelectedDate(undefined);
            setSelectedRange(undefined);
            setCurrentContextMenu(undefined);
        }

        let changedCalendar = calendar.update('days', List(), x => x.delete(x.findIndex( y => y.get('range') == range)));
        onChange(changedCalendar);
    }

    const onContextMenu = (e, date, range) => {
        e.preventDefault();

        // console.log("onContextMenu", e, date, range);

        setSelectedRange(range);
        setSelectedDate(date);
        let template = null;
        if(range && calendar.get('days')) {
            let rangeIndex = calendar.get('days').findIndex(x => x.get('range') == range.get('reference'));
            if(rangeIndex > -1) {
                template = calendar.getIn(['days', rangeIndex, 'template']);
            }
        }
        setCurrentContextMenu({
            x: e.clientX,
            y: e.clientY,
            range: range.get('reference'),
            template: template
        })
        

    }

    const onRangeSelect = (start, end, selectedDate) => {

        if(selectedDate) {
            let unixSelectedDate = Date.parse(`${selectedDate} 00:00:00.000 +00:00`);
            let unixToday = Date.parse(`${(new Date()).toISOString().replace(/T.*/g, '')} 00:00:00.000 +00:00`);
            if(unixSelectedDate <= unixToday) {
                return;
            };
            setSelectedDate(selectedDate);
        } else if(start) {
            let unixSelectedDate = Date.parse(`${start} 00:00:00.000 +00:00`);
            let unixToday = Date.parse(`${(new Date()).toISOString().replace(/T.*/g, '')} 00:00:00.000 +00:00`);
            if(unixSelectedDate <= unixToday) {
                return;
            };
        }

        if(!end) {
            setSelectedRange(start);

        } else {
            let unixStart = Date.parse(`${start} 00:00:00.000 +00:00`);
            let startDate = formatTSToSystemDate(unixStart);
            let unixEnd = Date.parse(`${end} 00:00:00.000 +00:00`);
            let endDate = formatTSToSystemDate(unixEnd);
            let range = `${startDate}-${endDate}`;
            if((dates || List()).indexOf(range) > -1) {
                setSelectedRange(start);
            } else {
                onAddRange(range);
            }
        }       
    }

    let backwardsCompatibleDates = (() => {
        let _convertedDates = (dates || List()).map((x) => {
            if(x.get('range').match(/([0-9]{2})\/([0-9]{2})\/([0-9]{4})\-([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g)) {
                return Map({ template: x.get('template'), range: x.get('range') });
            } else if(x.get('range').match(/([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g)) {
                return Map({ template: x.get('template'), range: `${x.get('range')}-${x.get('range')}` });
            }
            return x;
        })
        return _convertedDates;
    })();


    // console.log("selected range in render", selectedRange);

    // console.log("currentContextMenu", currentContextMenu);
    // console.log("selectedDate", selectedDate);

    

    return <Fragment>
        <DayTemplateSelectionDialog template={currentContextMenu?.template} customer={customer} range={displayTemplateSelectionDialog} onSave={(range, template) => onSaveRange(range, template)} onClose={() => toggleTemplateSelectionDialog(() => undefined)} /> 
        <ContextMenu contextMenu={currentContextMenu} onClose={() => setCurrentContextMenu(undefined)}>
            <ContextMenu.Item onClick={() => onRemoveRange(currentContextMenu.range)}>Clear</ContextMenu.Item>
            { dateIsFuture && <ContextMenu.Item onClick={() => onEditRange(selectedRange, selectedDate)}>Edit</ContextMenu.Item> }
        </ContextMenu>
        <CalendarPanel 
            onSelectCalendar={onSelectCalendar}
            onAddCalendar={onAddCalendar}
            onDeleteCalendar={onDeleteCalendar}
            onDownloadCalendar={onDownloadCalendar}
            calendars={calendars}
            onRangeSelect={onRangeSelect}
            onContextMenu={onContextMenu}
            selectedDate={selectedDate}
            onChangeName={onChangeName}
            onSelectGroups={onSelectedGroups}
            onChangeTemplate={onChangeTemplate}
            onRemoveSingleDate={onRemoveSingleDate}
            onRemoveRange={onRemoveRange}
            calendar={calendar || Map()}
            onChangeCalendar={v => onChange(v)}
            name={name} 
            customer={customer}
            points={points}
            calendarSelected={calendarSelected}
            dates={backwardsCompatibleDates} 
            selectedGroups={selectedGroups} 
            selectedRange={selectedRange}
            availableGroups={availableGroups} 
            availableTemplates={templates} 
            selectedTemplate={selectedTemplate} />
    </Fragment>


}

export function DayTemplateSelectionDialog({ range, onSave, onClose, customer, template }) {
    
    const [selectedTemplate, setSelectedTemplate] = useState(template ? Map({ _id: template }) : null);

    // console.log("DayTemplateSelectionDialog template", template);

    const save = () => {
        // console.log("selectedTemplate", selectedTemplate);
        onSave(range, selectedTemplate);
        setSelectedTemplate(null);
    }

    return <TransitioningDialog panelWidthClass="max-w-fit" onClose={onClose} opened={!!range}>
        <div  className='container'>
            <div className="w-full m-2">
                <h2>Select Template</h2>              
            </div>
        </div>
        <div className="flex-initial ">
            <DayTemplateSelection 
                showAllTemplates={true} 
                value={selectedTemplate} 
                customer={customer} 
                onChange={(template) => setSelectedTemplate(template)} />
        </div>
        <div className="flex p-3 items-center justify-end"> 
            <div className="pl-1"><Button role="secondary" onClick={onClose}>Close</Button></div>
            <div className="pl-1"><Button role="success" onClick={save}>Ok</Button></div>        
        </div>

    </TransitioningDialog>
}


