import React from 'react';
import Immutable from 'immutable';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import ListSelection from 'Controls/ListSelection';
import Button from 'Controls/Button/UserDefined';
import {searchGroups} from './Actions';
import Grid from 'Controls/GridV2';
import Select from 'Controls/Form/Select';

function PointGroupSelectionDialog({ isOpened, selecteditem, onClose, ...props}) {
    return <Dialog opened={!!isOpened} onClose={onClose}>
        <PointGroupSelectionDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class PointGroupSelectionDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedgroups : this.props.selecteditem,
            selectedGroups : Immutable.List(),
            availableGroups : Immutable.List()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let {customer, filter} = this.props;
        if(this.props.customer != prevProps.customer) {
            searchGroups(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, 'pointgroups', filter).then((data) => {
                let groups = Immutable.fromJS(data.map((group) => {return group;}));
                this.setState({
                    ...this.recreateGroups(groups),
                    groups: groups
                });
            }).catch((err) => {});
        } else if(this.state.selectedgroups != prevState.selectedgroups) {
            this.setState(this.recreateGroups(this.state.groups));
        }
    }

    recreateGroups(groups) {
        let {selectedgroups} = this.state;
        let newSelectedGroups = (groups || Immutable.List()).filter((group) => {
            return selectedgroups && (selectedgroups.includes(group.get('name')) || selectedgroups.includes(group.get('_id')));
        }).map((group) => {
            let newgroup = group.set('label', `${group.get('name')} (${group.get('type')})`); //group.get('name'));
            return newgroup;
        });

        let newAvailableGroups = (groups || Immutable.List()).filter((group) => {
             return selectedgroups && !(selectedgroups.includes(group.get('name')) || selectedgroups.includes(group.get('_id'))) || !selectedgroups;
        }).map((group) => {
            let newgroup = group.set('label', `${group.get('name')} (${group.get('type')}s)`);
            return newgroup;
        });
        return {
            selectedGroups : newSelectedGroups,
            availableGroups: newAvailableGroups
        }
    }

    componentDidMount() {
        let {customer, filter} = this.props;
        let {selectedgroups} = this.state;
    
        searchGroups(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, 'pointgroups', filter).then((data) => {
            let groups = Immutable.fromJS(data.map((group) => {return group;}));
            this.setState({
                ...this.recreateGroups(groups),
                groups: groups
            });
        }).catch((reject) => {})
    }

    onSelectedGroups(leftList, rightList) {
        let {groups} = this.state;

        let newNonSelectedGroups = (groups || Immutable.List()).filter((group) => {
            return leftList.findIndex(i => i.get('name') === group.get('name')) == -1
        });

        let newAvailableGroups = (newNonSelectedGroups || Immutable.List()).map((group) => {
            let newgroup = group.set('label', `${group.get('name')} (${group.get('type')}s)`);
            return newgroup;
        });

        this.setState({
            selectedGroups : leftList,
            availableGroups : newAvailableGroups,   
        });
    }

    onSave() {
        let {selectedGroups} = this.state;
        let {onSave} = this.props;

        onSave(selectedGroups);
    }

    render() {
        let {selectedGroups, availableGroups} = this.state;
        let {onClose} = this.props;

        // const extraGroups = (() => {
        //     let groups = Immutable.List();
        //     for(let i = 0; i < 100; i++) {
        //         groups = groups.push(Immutable.Map({
        //             name: `Group ${i}`,
        //             label: `Group ${i}`,
        //             type: 'Calendar'
        //         }));
        //     }
        //     return groups;
        // })()

        // availableGroups = availableGroups.merge(extraGroups)


        return (
            <DialogContent className="min-w-[800px] max-h-[70vh] overflow-hidden"> 
                <DialogHeader>
                    <DialogTitle>Point Groups</DialogTitle>
                </DialogHeader> 
                <DialogBody className="flex flex-col max-h-full overflow-hidden">

                    <ListSelection leftList={selectedGroups} rightList={availableGroups} onChange={this.onSelectedGroups.bind(this)} />

                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.onSave.bind(this)}>Ok</Button></div>
                </DialogFooter>
            </DialogContent>
        );
    }       
}

export default PointGroupSelectionDialog;
