import axios from 'axios';
import Immutable from 'immutable';
import Configuration from 'Configuration';

export function saveCustomer(customer) {
    return new Promise((resolve,reject) => {
        axios.post(`/files/customers/${customer.get('name').toLcHyphen()}_customer.json`, customer).then((response) => {
            resolve();
        }).catch((err) => {
            reject();
        })    
    });
}

export function saveSiteConfigFile(customer, site, configfile, configuration) {
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${customer}_${site}_${configfile}.json`, configuration).then((response) => {
            resolve();
        }).catch((err) => {
            reject(err);
        });
    })
}

export function saveSiteSubDirectoryConfigFile(customer, site, subdirectory, configfile, configuration) {
    return new Promise((resolve, reject) => {
        axios.post(`/files/customers/${customer}_${site}_${subdirectory}_${configfile}.json`, configuration).then((response) => {
            resolve();
        }).catch((err) => {
            reject(err);
        });
    })
}

export function getGroups(customer, connection, type){
    return new Promise((resolve, reject) => {
        axios.get(`/api/${type}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    })
}

export function searchGroups(customer, connection, type, q){
    return new Promise((resolve, reject) => {
        axios.post(`/api/${type}/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: q }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function saveGroup(customer, connection, type, group){
    return new Promise((resolve, reject) => {
        if (group.get('_id')) {
            axios.put(`/api/${type}/${group.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`,group.toJS()).then(resolve).catch(reject);
        } else {
            axios.post(`/api/${type}?database=${Configuration.product}${customer}&connection=${connection}`,group.toJS()).then(resolve).catch(reject);
        }
    })
}

export function deleteGroup(customer, connection, type, id) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/${type}/${id}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    })
}

export function getIGroups(type){
    return new Promise((resolve, reject) => {
        axios.get(`/api/${type}`).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    })
}

export function saveIGroup(type, group){
    return new Promise((resolve, reject) => {
        if (group.get('_id')) {
            axios.put(`/api/${type}/${group.get('_id')}`,group.toJS()).then(resolve).catch(reject);
        } else {
            axios.post(`/api/${type}`,group.toJS()).then(resolve).catch(reject);
        }
    })
}

export function deleteIGroup(type, id) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/${type}/${id}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    })
}

export function loadAllEngUnits(){
    return new Promise((resolve, reject) => {
        axios.get('/files/common/engunits.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getAllPoints(customer, connection) {
    return new Promise((resolve, reject) => {
            axios.get(`/api/points?database=${Configuration.product}${customer}&connection=${connection}`).then((response) =>{
            resolve(response.data);
        }).catch(reject);
    });
}

export function getPoint(customer, connection, query) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/points/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: query }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getDatabaseRecords(database, customer, connection, query) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/${database}/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: query }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function deleteRecord(database, customer, connection, id) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/${database}/${id}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });    
}

export function deletePoint(customer, connection, id) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/points/${id}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function savePoint(customer, connection, point){
    let toSave = Immutable.Iterable.isIterable(point) ? point.toJS() : point;  
    return new Promise((resolve, reject) => {
        if (toSave._id){      
            axios.put(`/api/points/${toSave._id}?database=${Configuration.product}${customer}&connection=${connection}`, toSave).then(resolve).catch(reject);
        }
        else{
            axios.post(`/api/points?database=${Configuration.product}${customer}&connection=${connection}`, toSave).then(resolve).catch(reject);
        }
    });
}

export function searchPoints(customer, connection, query){
    return new Promise((resolve, reject) => {
        axios.post(`/api/points/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: query }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getTrendLans(customer, connection){
    return new Promise((resolve, reject) => {
        axios.get(`/api/trendlans?database=${Configuration.product}${customer}&connection=${connection}`).then(resolve).catch(reject); 
    });
}

export function getTrendOutstations(customer, connection, lan){
    return new Promise((resolve, reject) => {
        axios.get(`/api/trendoutstations?database=${Configuration.product}${customer}&connection=${connection}`).then(resolve).catch(reject); 
    });
}

export function getSiteNotificationSettings(customer, connection) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/sitenotificationsettings?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
} 

export function saveSiteNotificationSettings(customer, connection, sitenotificationsettings){
    return new Promise((resolve, reject) => {
        if (sitenotificationsettings.get('_id')) {
            axios.put(`/api/sitenotificationsettings/${sitenotificationsettings.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`,sitenotificationsettings.toJS()).then(resolve).catch(reject);
        } else {
            axios.post(`/api/sitenotificationsettings?database=${Configuration.product}${customer}&connection=${connection}`,sitenotificationsettings.toJS()).then(resolve).catch(reject);
        }
    })
}

export function getSerialPorts(customer, connection){
    return new Promise((resolve, reject) => {
        axios.get(`/api/modbusserialports?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });    
}


export function saveSerialPort(customer, connection, port) {
    return new Promise((resolve,reject) => {
        if (port.get('_id')) {
            axios.put(`/api/modbusserialports/${port.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, port.toJS()).then(resolve).catch(reject);
        }
        else {
            axios.post(`/api/modbusserialports?database=${Configuration.product}${customer}&connection=${connection}`, port.toJS()).then(resolve).catch(reject);
        }
    });
}

export function deleteSerialPort(customer, connection, id) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/modbusserialports/${id}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

