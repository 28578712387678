import React from 'react';


class MainLoader extends React.Component {
    render() {
        return (
            <div className="absolute pin bg-blue-darkest text-center pt-4 w-full h-full">
                <h1 className="text-white mt-4">Please wait while we are loading...</h1>
                <div className="row">
                    <div className="col-md-6 offset-md-5">
                        <img src="/images/loader.svg" alt="Please wait while we are loading..." className="w-100" />
                    </div>
                </div>
            </div>
        )
    }
}

export default MainLoader;