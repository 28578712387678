import * as d3 from 'd3';
import ComponentCache from '../ComponentCache';


export default function Dial(options, path) {
    return new Promise((resolve, reject) => {
        ComponentCache.getXml(path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);

            let placementElement = drawDial(image, options);

            ComponentCache.getXml('/files/editor/components-v2_dials_dial-pointer-style1.svg').then((pointerxml) => {
                let elementPointer = d3.select(pointerxml.documentElement).select('#layer1');
                let pointerElement = drawPointer(placementElement, elementPointer, options, 13, 70);
         
                resolve({
                    setValue: (point) => {
                        options.node = options.node.setIn(['pointconfiguration'], point);
                        setPointerValue(placementElement, pointerElement, options, 13, 70, point.m_value); 
                   },
                    setContextMenu: (editmode) => {
                        options.editmode = editmode;
                    },
                    getElement: () => {
                        return placementElement;
                    },
                    setOptions: (newOptions) => {
                        options = {...options, ...newOptions}
                    },
                    redrawElement: (node, isSelected) => {
                        placementElement.remove();
                        pointerElement.remove();
                        options.node = node;
                        placementElement = drawDial(image, options);
                        pointerElement = drawPointer(placementElement, elementPointer, options, 13, 70);
                        if(isSelected) {
                            placementElement.attr('class', 'node-selected');
                        }
                    },
                    remove: () => {
                        placementElement.remove();
                        image.remove();
                    },
                    resetIndex: (index) => {
                        options.index = index;
                        placementElement.attr('data-index', options.index);
                    }
                })
            })
        })
    })    
}



function drawDial(image, options) {
    let element = d3.select(options.target)
        .append('g').attr('data-index', options.index).html(image.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`);

    element.select('#background').style('fill', `${options.node.getIn(['configuration', 'colourbackground'], '#dbe3db')}`);
    element.select('#rim').style('fill', `${options.node.getIn(['configuration', 'colourrim'], '#003c80')}`);

    var interval = options.node.getIn(['configuration', 'interval'], 250).toString();
    var total;

    var ctr = options.node.getIn(['configuration', 'endvalue'], 1500) / options.node.getIn(['configuration', 'interval'], 250) + 1;

    for (var i = 0; i < ctr; i++) {
        total = (interval * i).toString();
        element.select(`${'#interval' + (i + 1)}`)
            .style('fill', `${options.node.getIn(['configuration', 'colourtext'], 'White')}`)
            .text(total)
    }

    return element;
}

function drawPointer(element, elementPointer, options, width, height) {
    let box1 = element.node().getBBox();

    let x = box1.width / 2 - width / 2;
    let y = box1.height / 2 - height;
    
    //TODO: Make it work using a dynamic value, the 95 represents the value that needs to change, according to value. The minimum is 0, and the maximum is 360
    let pointerElement = element.append('g').attr('data-index', options.index).html(elementPointer.html())
        .attr('x', options.node.getIn(['position', 'x']))
        .attr('y', options.node.getIn(['position', 'y']))
        .attr('transform', `rotate(${options.node.getIn(['configuration', 'startangle'], 0)},${x + width / 2}, ${box1.height / 2} ) translate(${x}, ${y})`);

    return pointerElement;    
}

function setPointerValue(element, pointerelement, options, width, height, value) {
    let box1 = element.node().getBBox();
    let x = box1.width / 2 - width / 2;
    let y = box1.height / 2 - height;

    let newvalue = value - options.node.getIn(['configuration', 'startvalue'], -10);
    let maxrotation = Math.abs(options.node.getIn(['configuration', 'startangle'], -150)) + Math.abs(options.node.getIn(['configuration', 'endangle'], 90));
    let rotate = maxrotation / (Math.abs(options.node.getIn(['configuration', 'endvalue'], 30)) + Math.abs(options.node.getIn(['configuration', 'startvalue'], -10)));
    pointerelement
        .attr('transform', `rotate(${options.node.getIn(['configuration', 'startangle'], -150) + (newvalue * rotate)},${x + width / 2}, ${box1.height / 2} ) translate(${x}, ${y})`);
}