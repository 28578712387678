import * as d3 from "d3";
import {fromJS} from 'immutable';
import { displayTooltip } from "../Utils";
import { setDefs, drawPath, drawRect, createTextNode, drawTspan} from '../Utils';

export  default function EzyButton3(options) {
    return new Promise((resolve, reject) => {
        let rotation = options.node.getIn(['configuration','rotation'], 0);

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .style('cursor', 'hand')
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`);

        let drawButton = (placementElement, options) => {
            let grads = fromJS([
                { "stop": "0%", "colour":"#00aad4" },
                { "stop": "100%", "colour":"#004050"}]);

            options.node.getIn(['configuration', 'gradient'], true ) && setDefs(placementElement, options.node.get('id'),
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads'], grads));
            drawRect(placementElement, 0, 0, options.node.getIn(['configuration', 'width'], 200), options.node.getIn(['configuration', 'height'], 200),
                options.node.getIn(['configuration', 'borderwidth'], 4),options.node.getIn(['configuration', 'style', 'border-color'], '#004455'),
                options.node.getIn(['configuration', 'ry'], 45), options.node.getIn(['configuration', 'gradient'], true),options.node.get('id'),
                options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'), 1); 
    
            let pathHeight = options.node.getIn(['configuration', 'height'], 200) / 5;
            let pathWidth = options.node.getIn(['configuration', 'width'], 200);
            let horizontal = pathWidth - pathWidth / 5;
            let idPath = 1000 - options.node.get('id');
    
            let gradsHeader = fromJS([
                { "stop": "0%", "colour":"#5fbcd3" },
                { "stop": "100%", "colour":"#00aad4"}]);
    
            let path = `M${options.node.getIn(['configuration', 'borderwidth'], 4) - 1}
                ${pathHeight} Q5 5 ${options.node.getIn(['configuration', 'height'], 200) / 4} ${options.node.getIn(['configuration', 'borderwidth'], 4) / 2} H${horizontal}
                Q${options.node.getIn(['configuration', 'width'], 200) - 10} 5 ${pathWidth - 2} ${pathHeight} V${pathHeight}z`;
    
            options.node.getIn(['configuration', 'gradientHeader'], true ) && setDefs(placementElement, idPath,
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'gradsHeader'], gradsHeader));
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], true ),idPath,
                options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'));
        
            drawEzyButtonText(placementElement, options);
            placementElement.attr('transform', `matrix(${options.node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${options.node.getIn(['configuration', 'scale'], 100.0) / 100},${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])})`);
        }

        let drawButtonStyle2 = (placementElement, options) => {
            let grads = fromJS([
                { "stop": "0%", "colour":"#00aad4" },
                { "stop": "100%", "colour":"#004050"}]);

            options.node.getIn(['configuration', 'gradient'], true ) && setDefs(placementElement, options.node.get('id'),
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads'], grads));
            drawRect(placementElement, 0, 0, options.node.getIn(['configuration', 'width'], 200), options.node.getIn(['configuration', 'height'], 200),
                options.node.getIn(['configuration', 'borderwidth'], 4),options.node.getIn(['configuration', 'style', 'border-color'], '#004455'),
                options.node.getIn(['configuration', 'ry'], 45), options.node.getIn(['configuration', 'gradient'], true),options.node.get('id'),
                options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'), 1); 
    
            let pathHeight = options.node.getIn(['configuration', 'height'], 200) / 5;
            let pathWidth = options.node.getIn(['configuration', 'width'], 200);
            let horizontal = pathWidth - pathWidth / 5;
            let idPath = 1000 - options.node.get('id');
    
            let gradsHeader = fromJS([
                { "stop": "0%", "colour":"#5fbcd3" },
                { "stop": "100%", "colour":"#00aad4"}]);
    
            let path = `M${options.node.getIn(['configuration', 'borderwidth'], 4) - 1}
                ${pathHeight} Q5 5 ${options.node.getIn(['configuration', 'height'], 200) / 4} ${options.node.getIn(['configuration', 'borderwidth'], 4) / 2} H${horizontal}
                Q${options.node.getIn(['configuration', 'width'], 200) - 10} 5 ${pathWidth - 2} ${pathHeight} V${pathHeight}z`;
    
            options.node.getIn(['configuration', 'gradientHeader'], true ) && setDefs(placementElement, idPath,
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'gradsHeader'], gradsHeader));
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], true ),idPath,
                options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'));
        
            drawEzyButtonText(placementElement, options);
            placementElement.attr('transform', `matrix(${options.node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${options.node.getIn(['configuration', 'scale'], 100.0) / 100},${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])})`);
        }

        let placementElement = createPlacementElement(options);
        drawButton(placementElement, options);   
        let tooltip = displayTooltip(placementElement, options); 

            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
        //            setMenu(placementElement, options);
                },
                getElement: () => {
                    return placementElement;
                },
                setOptions: (newOptions) => {
                    options = {...options, ...newOptions}
                },
                redrawElement: (node, isSelected) => {
                    placementElement.remove();
                    options.node = node;

                    placementElement = createPlacementElement(options);
                    drawButton(placementElement, options);

                    if (!tooltip) tooltip = displayTooltip(placementElement, options);
                    if(isSelected) {
                        placementElement.attr('class', 'node-selected');
                    }
                },
                remove: () => {
                    placementElement.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
        //    });
        })
    })
}

function drawEzyButtonText(element, options) {
    let box1 = element.node().getBBox();

    let yoffset = box1.y + (box1.height / 2) + options.node.getIn(['configuration', 'style', 'fontsize'], 24) / 2;
    let xoffset = box1.width / 2;

    let textNode = createTextNode(element, null, xoffset, yoffset, options.node.getIn(['configuration', 'style', 'fontsize'], '24'),
        options.node.getIn(['configuration', 'style', 'fontstyle'], 'normal'), `${options.node.getIn(['configuration', 'underline']) ? 'underline' : 'none'}`,
        options.node.getIn(['configuration', 'style', 'color'], 'white'));
    drawTspan(textNode,xoffset, options.node.getIn(['configuration', 'style', 'fontsize'], '16'),
        options.node.getIn(['configuration', 'value'], 'ezyButton')); 
}