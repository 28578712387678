import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import CalendarPanel from './CalendarPanel';
import { fromJS, List, OrderedMap } from 'immutable';
import { getCalendars, saveCalendar, deleteCalendar } from './Calendar/Actions';
import { searchGroups, searchPoints } from '../Actions';
import Button from 'Controls/Button/UserDefined';
import ServiceBus from '../../../../Services/ServiceBus';
import { useSelector } from 'react-redux';
import { useBMS } from '../../../Context/BMS';

const emptyCalendar = fromJS({ name: 'New calendar', days: [], pointgroups: [], use:0, priority:1 })

export default function CalendarScreen({ customer }) {
    return <div className="container mx-auto my-4">
        <CalendarTab customer={customer} />
    </div>
}

function CalendarTab({ customer }) {
    const customerName  = customer.get('name').toLowerCase().replace(/ /g,"");
    const customerLocation = customer.get('ipaddress').toLowerCase();
    // const currentuser = useSelector(state => state.get('currentUser'));
    const { currentUser:currentuser } = useBMS();
    const [calendar, setCalendar] = useState(undefined);
    const [calendars, setCalendars] = useState(List());
    const [groups, setGroups] = useState(OrderedMap()); 

    const loadCalendars = async (insertedId) => {
       
        let data = await getCalendars(customerName, customerLocation);
        let calendars = fromJS(data);
        let selectedId = calendar && calendar.get('_id') || insertedId || undefined;
        setCalendars(() => calendars);
        if(selectedId) {
            let selectedCalendar = calendars.find((cal) => cal.get('_id') == selectedId);
            setCalendar(() => selectedCalendar);
        }
        return true;
    }

    useEffect(() => {
        let unsubscribe = ServiceBus.on('312-11', processDownloadCalendarComplete, true);

        return () => {
            // ServiceBus.off('313-11', processDownloadCalendarComplete);
            unsubscribe();
        }
    }, []);

    useEffect(() => {
        if(customer) {
            loadCalendars();
        }
    }, [customer]);

    useEffect(() => {
        (async () => {
            if(customer) {
                let data = await searchGroups(customerName, customerLocation, 'pointgroups', {type : 'Calendar'});
                // console.log("groups got from promise", data);
                setGroups(fromJS(data).reduce( (col,x) => col.set(x.get('_id'), x), OrderedMap()));
            }            
        })();
    }, [customer]);


    const processDownloadCalendarComplete = (message) => {
        if (message.m_errorNumber != 0) {
            Swal.fire("Error", `Error number ${message.m_errorNumber}`, "error");
        }
        else {
            Swal.fire("Success", "The calendar has been saved", "success");
        }
    }


	const onChange = (changedCalendar) => {
		setCalendar(changedCalendar);
	}

    const addCalendar = () => {
        setCalendar(emptyCalendar);
    }

    const editCalendar = (selectedCalendar) => {
        setCalendar(selectedCalendar);
    }

    const onDelete = async (calendarToRemove) => {
        Swal.fire({
			title: `Delete the Calendar ?`,
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
            if ( result.value ) {
                await deleteCalendar(customerName, customerLocation, calendarToRemove);
                let selectedId = undefined;
                if(calendar && calendarToRemove.get('_id') != calendar.get('_id')) {
                    selectedId = calendar.get('_id');
                } else {
                    setCalendar(undefined);
                }
                await loadCalendars(selectedId);
            }
        })
    }

    const checkDuplicate = (calendar) => {
        let g = calendars.filter((cal) => {
            return cal.get('name') == calendar.get('name') && calendar.get('_id') != cal.get('_id');
        });

        return g.size;
    }

    const onSave = (calendar) => {
        if(checkDuplicate(calendar)) {
            Swal.fire(
                'Error!',
                'Duplicate Name',
                'error'
            );
            return;
        } 

        SaveGroup(calendar); 
    }

    const SaveGroup = async (calendar) => { 
        let saveData = await saveCalendar(customerName, customerLocation, calendar);
        let insertedId = undefined;
        if(saveData && saveData.data && saveData.data.insertedIds) {
            insertedId = saveData.data.insertedIds[0];
        }
        // setCalendar(undefined);
        await loadCalendars(insertedId);
        Swal.fire(
            'Calendar has been saved!',
            'The calendar has been saved',
            'success'
        );
    }

    const getSubsystemNumber = async (calendar) => {
        const currentGroups = (calendar || emptyCalendar).get('pointgroups');

        if (currentGroups && groups) {
            let groupIdToFetch = currentGroups.get(0);         
            let data = await searchPoints(customerName, customerLocation, {'pointgroups.groupid': groupIdToFetch }); 

            if (data.length == 0) {
                Swal.fire(
                    'Error!',
                    'Points Not Defined',
                    'warning'
                );
            } else {
                // console.log("data retrieved after searching for the point groups", data);
                // console.log('subsystem', data[0].addr[0])
                return data[0].addr[0];
            }
        }

        else {
            Swal.fire(
                'Error!',
                'Point Groups Not Defined',
                'warning'
            );
        }

        return undefined;
    }

    const onDownload = async (calendar) => {
        let subsystem = await getSubsystemNumber(calendar);
        let deleteExisting = 0;
        if (subsystem == undefined) return;

        Swal.fire({
			title: `Delete existing Exceptions on Controller ?`,
			text: "You won't be able to revert this!",
			type: 'warning',
            showDenyButton: true,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#6c757d',
            denyButtonText: 'No',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {

            let { isDismissed, isDenied, isConfirmed, value } = result;

            if(isDismissed || isDenied) return;
            
			if (result.value)  deleteExisting = 1;
            
            let request = {
                target: "signalr",
                type: 'GENERIC',
                trackMessage: true,
                m_guiPoll: deleteExisting,
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                m_subsystemNumber: subsystem,
                m_level: 10,
                m_communicNum: 312
            }

            let comm = {
                m_calendarName: calendar.get('name'),
                m_password: "1212", // Mark is going to have a word with Roy about which password/subsystem to use 
                m_deleteExisting: deleteExisting,
                m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`

            }
            ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
		});

    }

    const onRemove = async (calendar) => {
        let subsystem = await getSubsystemNumber(calendar);
        let deleteExisting = 0;
        if (subsystem == undefined) return;

        Swal.fire({
			title: `Delete existing Exceptions on Controller ?`,
			text: "You won't be able to revert this!",
			type: 'warning',
            showDenyButton: true,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#6c757d',
            denyButtonText: 'No',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {

            let { isDismissed, isDenied, isConfirmed, value } = result;

            if(isDismissed || isDenied) return;
            
			if (result.value)  deleteExisting = 1;
            
            let request = {
                target: "signalr",
                type: 'GENERIC',
                trackMessage: true,
                m_guiPoll: deleteExisting,
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                m_subsystemNumber: subsystem,
                m_level: 10,
                m_communicNum: 312
            }

            let comm = {
                m_calendarName: calendar.get('name'),
                m_password: "1212", // Mark is going to have a word with Roy about which password/subsystem to use 
                m_deleteExisting: deleteExisting,
                m_deleteOnly: 1,
                m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`

            }
            ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
		});

    }

    return <div>
        <div className="w-full m-2">
            <div className="">
                { calendar && <div className={``}> 
                    { calendar && calendar.get('_id' ) && <h2 className="text-2xl ">Edit Calendar</h2> || '' }
                    { calendar && !calendar.get('_id' ) && <h2 className="text-2xl ">Create Calendar</h2> || '' } 
                </div> || <h2 className="text-2xl ">Calendars</h2> }
            </div>
            <div className="">
                <div>
                    <CalendarPanel 
                        onAddCalendar={addCalendar}
                        onSelectCalendar={(c) => editCalendar(c)}
                        onDeleteCalendar={(c) => onDelete(c)}
                        onDownloadCalendar={(c) => onDownload(c)}
                        customer={customer} 
                        calendar={calendar} 
                        calendars={calendars}
                        onChange={onChange} 
                        groups={groups} /> 
                    <div className="flex justify-end relative">
                        { !calendar && <div className="absolute top-0 right-0 w-full h-full backdrop-blur-sm bg-white/70 z-20"></div> || ''}
                        <Button role="success" onClick={() => onDownload(calendar)} className="ml-2 flex-none">Download to Panel</Button>
                        <Button role="success" onClick={() => onRemove(calendar)} className="ml-2 flex-none">Delete exceptions</Button>
                        <Button role="save" onClick={() => onSave(calendar)} className="ml-2 flex-none">Save To Disk</Button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
}

