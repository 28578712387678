import React from 'react';
import Analog from './Analog';
import Digital from './Digital';
import Multistate from './Multistate';
import {connect} from 'react-redux';
import ServiceBus from 'Services/ServiceBus';
import ModuleTypes from 'Configuration/ModuleTypes';
import { toast } from 'react-toastify';
import Immutable from 'immutable';
import PointPrioritiesDialog from '../../PointPrioritiesDialog';
import { useBMS } from 'BmsView/Context/BMS';
import useAreaCustomer from '../../../Hooks/useAreaCustomer';
import useAreaSite from '../../../Hooks/useAreaSite';

const PointControls = {
    'Analog': Analog,
    'Digital': Digital,
    'Multistate': Multistate
}

class Command extends React.Component {
    constructor(props){
        super(props);
        this.processMessage = this.processMessage.bind(this);

        this.state = {
            updatepriorities: false,
            selectedpointforpriorities: undefined
        }
    }
    
    componentDidMount(){
        let rawConfig = this.props.selectedpoint.toJS();
        
        ServiceBus.on('301-11', this.processMessage);
        // ServiceBus.on('301-11', (message) => {
        //     if (message.m_errorNumber != 0) {
        //         toast.success('Error commanding point. Error :' + message.m_errorNumber, { autoClose: 2000 });
        //         return;
        //     }
        //     let {selectedpoint, updatePoint} = this.props;
        //     if (updatePoint){
        //         updatePoint(selectedpoint.set('pointconfiguration', Immutable.fromJS(message)));
        //     }
        //     toast.success('Point successfully commanded', { autoClose: 2000 });
        // });
    }

    componentWillUnmount(){
        // ServiceBus.off('301-11', (message) => {
        //     let {selectedpoint, updatePoint} = this.props;
        //     if (updatePoint){
        //         updatePoint(selectedpoint.set('pointconfiguration', Immutable.fromJS(message)));
        //     }
        //     toast.success('Point successfully commanded', { autoClose: 2000 });
        // });
        ServiceBus.off('301-11', this.processMessage);
    }

    processMessage(message) {
        if (message.m_errorNumber != 0) {
            toast.success('Error commanding point. Error :' + message.m_errorNumber, { autoClose: 2000 });
            return;
        }
        let {selectedpoint, updatePoint} = this.props;
        if (updatePoint){
            updatePoint(selectedpoint.set('pointconfiguration', Immutable.fromJS(message)));
        }
        toast.success('Point successfully commanded', { autoClose: 2000 });

        let  {selectedpointforpriorities, updatepriorities} = this.state;

        if (selectedpointforpriorities) {
            this.setState({
                updatepriorities:!updatepriorities
            })
        }
    }

    getPriority(subsystemNumber, priority) {
        let {customer} = this.props;

        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });

        if (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) 
            return selectedsubsystem.first().get('pin');

        return parseInt(priority);
    }

    /*
    getPin(subsystemNumber) {
        let {customer} = this.props;

        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
 
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }*/

    commandPoint(type, value, priority){
        let {customer, selectedpoint, address, currentuser} = this.props;

        let subsystemNumber = address.map((value) => { return parseInt(value); })[0];

        if (type >= 2){
            value = parseFloat(value);
        }
        else{
            type = value;
        }
        
        let request = {
            type: 'GENERIC',
            m_level: 10,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 301,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }

        let comm = {
            m_pointAddr: address.map((value) => { return parseInt(value); }),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_typeOfCommand: type,
            m_priority: this.getPriority(subsystemNumber, priority), //this.getPin(subsystemNumber),
            m_value: value,
            m_engUnitGuarded: 0,
            m_oldValue: selectedpoint.getIn(['pointconfiguration', 'm_value'])
        }

        // console.log(request, comm);
        
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    commandPointMode(type, value){
        let {customer, selectedpoint, address, currentuser} = this.props;

        let subsystemNumber = address.map((value) => { return parseInt(value); })[0];
        
        let request = {
            type: 'GENERIC',
            m_level: 10,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 301,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }

        let comm = {
            m_pointAddr: address.map((value) => { return parseInt(value); }),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_typeOfCommand: 12 + value,
            m_priority: this.getPin(subsystemNumber),
            m_value: value ? selectedpoint.getIn(['pointconfiguration', 'm_value']) : 0
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    isUserAuthorised() {
        let {currentuser, customerusergroups, selectedpoint} = this.props;

        if (currentuser.get('isReadOnly'))
            return false;

        if (!currentuser.get('group'))
            return true;

        if (!selectedpoint.get('pointconfiguration')) return true;

        if (!selectedpoint.getIn(['pointconfiguration', 'm_groups'])) return;

        let pointgroups = selectedpoint.getIn(['pointconfiguration', 'm_groups']);
        let customergroup = customerusergroups.filter((usergroup) => {
            return `${usergroup.get('customer')} - ${usergroup.get('name')}` == currentuser.get('group');
        }).first();

        if (!customergroup) return true;

        let groupselected = pointgroups.filter((pointgroup) => {
            return pointgroup == customergroup.get('name');
        }).first();

        if (groupselected) return true;
        
        return false; 
    }

    showPointPrioritiesDialog() {
        let {selectedpoint} = this.props

        this.setState({
            selectedpointforpriorities: selectedpoint
        })
    }

    clearSelection() {
        this.setState({
            selectedpointforpriorities: undefined
        })
    }

    render() {
        let  {selectedpointforpriorities, updatepriorities} = this.state;
        let {customer, selectedpoint, address, activeModuleType} = this.props;
        let PointType = activeModuleType.pointtypes[address[2]];
        let ActiveControl = PointControls[PointType.control];

        return (
            <React.Fragment>
                 {selectedpointforpriorities && <PointPrioritiesDialog customer={customer} updatePriorities={updatepriorities} selectedItem={selectedpointforpriorities} type={PointType.control} setValue={this.commandPoint.bind(this)} onClose={this.clearSelection.bind(this)} />}
                <div className="flex text-2xl border-b border-grey py-1 font-semibold">Command point</div>
                <div className="flex w-full my-4">
                {ActiveControl && this.isUserAuthorised() && <ActiveControl selectedpoint={selectedpoint} value={selectedpoint.getIn(['pointconfiguration', 'm_value'])} mode={selectedpoint.getIn(['pointconfiguration', 'm_mode'])} onShowPointPriorities={this.showPointPrioritiesDialog.bind(this)} onChange={this.commandPoint.bind(this)} onChangeMode={this.commandPointMode.bind(this)}  />}
                </div>
            </React.Fragment>
        )
    }
}

export default connect((state) => ({
    // customer: state.get('customer'),
    // site: state.get('site'),
    // currentuser: state.get('currentUser'),
    customerusergroups: state.get('customerusergroups')
}))((props) => {

    // const { customer } = useBMS();
    // const { site } = useBMS();
    const { customer } = useAreaCustomer();
    const { site } = useAreaSite();
    const { currentUser } = useBMS();

    return <Command {...props} customer={customer} site={site} currentuser={currentUser} />
});