import React from 'react';

class Compass extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }
    render() {
        let { selectedcomponent } = this.props;
        return (<div></div>);
    }
}

export default Compass;