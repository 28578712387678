import Immutable from 'immutable'

export function getLvlPath(nodepath, lvl) {
    return nodepath && nodepath.split('/')[lvl - 1] || undefined
}

export function getNodeMeta(nodepath, customer, site) {
    let lvl1 = getLvlPath(nodepath, 1);
    let lvl2 = getLvlPath(nodepath, 2);
    let lvl3 = getLvlPath(nodepath, 3);
    let lvl4 = getLvlPath(nodepath, 4);
    let lvl5 = getLvlPath(nodepath, 5);
    let lvl6 = getLvlPath(nodepath, 6);

    let indexOfCurrent = -1;

    let lvl1node = site.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl1).first();
    if (lvl1node){
        indexOfCurrent = site.get('treenodes').indexOf(lvl1node);
    }
    if (!lvl1node && site.get('treenodes')) {
        if (site.get('treenodes').size > 0) {
            lvl1node = site.get('treenodes').first();
            lvl1 = lvl1node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl1node.get('name').split(' ').join('-').toLowerCase();
        }
    }
    let lvl2node = lvl1node && lvl1node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl2).first();
    if (lvl2node){
        indexOfCurrent = lvl1node.get('treenodes').indexOf(lvl2node);
    }
    if (lvl1node && !lvl2node && !lvl1node.get('backdrop') && lvl1node.get('treenodes')) {
        if (lvl1node.get('treenodes').size > 0) {
            lvl2node = lvl1node.get('treenodes').first();
            lvl2 = lvl2node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl2node.get('name').split(' ').join('-').toLowerCase();
        }
    }
    let lvl3node = lvl2node && lvl2node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl3).first();
    if (lvl3node){
        indexOfCurrent = lvl2node.get('treenodes').indexOf(lvl3node);
    }
    if (lvl2node && !lvl3node && !lvl2node.get('backdrop') && lvl2node.get('treenodes')) {
        if (lvl2node.get('treenodes').size > 0) {
            lvl3node = lvl2node.get('treenodes').first();
            lvl3 = lvl3node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl3node.get('name').split(' ').join('-').toLowerCase();
        }
    }
    let lvl4node = lvl3node && lvl3node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl4).first();
    if (lvl4node){
        indexOfCurrent = lvl3node.get('treenodes').indexOf(lvl4node);
    }
    if (lvl3node && !lvl4node && !lvl3node.get('backdrop') && lvl3node.get('treenodes')) {
        if (lvl3node.get('treenodes').size > 0) {
            lvl4node = lvl3node.get('treenodes').first();
            lvl4 = lvl4node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl4node.get('name').split(' ').join('-').toLowerCase();
        }
    }

    let lvl5node = lvl4node && lvl4node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl5).first();
    if (lvl5node){
        indexOfCurrent = lvl4node.get('treenodes').indexOf(lvl5node);
    }
    if (lvl4node && !lvl5node && !lvl4node.get('backdrop') && lvl4node.get('treenodes')) {
        if (lvl4node.get('treenodes').size > 0) {
            lvl5node = lvl4node.get('treenodes').first();
            lvl5 = lvl5node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl5node.get('name').split(' ').join('-').toLowerCase();
        }
    }

    let lvl6node = lvl5node && lvl5node.get('treenodes').filter((node) => node.get('name').split(' ').join('-').toLowerCase() == lvl6).first();
    if (lvl6node){
        indexOfCurrent = lvl5node.get('treenodes').indexOf(lvl6node);
    }
    if (lvl5node && !lvl6node && !lvl5node.get('backdrop') && lvl5node.get('treenodes')) {
        if (lvl5node.get('treenodes').size > 0) {
            lvl6node = lvl5node.get('treenodes').first();
            lvl6 = lvl6node.get('name').split(' ').join('-').toLowerCase();
            nodepath += "/" + lvl6node.get('name').split(' ').join('-').toLowerCase();
        }
    }



    let currentNode = lvl6node || lvl5node || lvl4node || lvl3node || lvl2node || lvl1node;

    let customerMainDirectory = `${customer.get('name').toLowerCase().split(' ').join('-')}`;
    let baseDirectory = `${customer.get('name').toLowerCase().split(' ').join('-')}_${site.get('name').split(' ').join('-').toLowerCase()}`;
    let siteMainDirectory = baseDirectory;
    if (lvl1node && lvl2node) {
        baseDirectory += "_" + lvl1node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }
    if (lvl2node && lvl3node) {
        baseDirectory += "_" + lvl2node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }
    if (lvl3node && lvl4node) {
        baseDirectory += "_" + lvl3node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }

    if (lvl4node && lvl5node) {
        baseDirectory += "_" + lvl4node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }

    if (lvl5node && lvl6node) {
        baseDirectory += "_" + lvl5node.get('name').toLowerCase().split(' ').join('-').toLowerCase();
    }
    if (nodepath.indexOf('/') == 0) {
        nodepath = nodepath.substr(1);
    }

    let pathDictionary = generatePathDictionary(site);
    // console.log("pathDictionary", pathDictionary.values()); 
    // console.log("pathD site", site);
    // console.log("pathD levels", [lvl1node, lvl2node, lvl3node, lvl4node]);
    let siteGraph = new ConfigurationNode(site, -1, [lvl1node, lvl2node, lvl3node, lvl4node, lvl5node, lvl6node]);
    siteGraph.generateOriginalPaths();
    // console.log("15-12 pathD graph", siteGraph);

    return { 
        levels: [lvl1, lvl2, lvl3, lvl4, lvl5, lvl6],
        currentNode: currentNode,
        nodes: [lvl1node, lvl2node, lvl3node, lvl4node, lvl5node, lvl6node],
        baseDirectory: baseDirectory,
        customerMainDirectory,
        siteMainDirectory,
        indexOfCurrent,
        pathDictionary,
        siteGraph
    }
}

function generatePathDictionary(site, path = '') {
    let pathDictionary = new Map();
    // console.log("pathD site", path);
    site.get('treenodes').map((node) => {
        let pathName = (path ? path + "_" : '') +  node.get('name').toLowerCase().split(' ').join('-');
        
        pathDictionary.set(node, pathName);
        if(node.get('treenodes')){
            pathDictionary = new Map([...pathDictionary, ...generatePathDictionary(node, pathName)]);
        }
    });

    // site.get('treenodes').map((node) => {
    //     pathDictionary[node.get('name').toLowerCase().split(' ').join('-')] = node.get('path');
    //     node.get('treenodes').map((node) => {
    //         pathDictionary[node.get('name').toLowerCase().split(' ').join('-')] = node.get('path');
    //         node.get('treenodes').map((node) => {
    //             pathDictionary[node.get('name').toLowerCase().split(' ').join('-')] = node.get('path');
    //             node.get('treenodes').map((node) => {
    //                 pathDictionary[node.get('name').toLowerCase().split(' ').join('-')] = node.get('path');
    //             })
    //         })
    //     })
    // })
    return pathDictionary;


}



class ConfigurationNode {


    constructor(node, level = -1, activeLevels, activeLevelsReference = []) {
        this.originalNode = node;
        this.editableNode = node;
        this.parent = null;
        this.children = [];
        this.firstChild = null;
        this.left = null;
        this.right = null;
        this.index = 0;
        this.active = level < 0;
        this.level = level;
        this.activeLevelsReference = activeLevelsReference;
        this.originalPath = '';
        this.newPath = '';
        if(node.get('treenodes')) {
            let lastSibling = null;
            node.get('treenodes').map((node, index) => {
                let isActiveNode = activeLevels[level + 1] && activeLevels[level + 1] == node
                let child = new ConfigurationNode(node, level + 1, activeLevels, activeLevelsReference);
                if(isActiveNode) {
                    child.active = true;
                    this.activeLevelsReference.unshift(child);
                }
                if(index == 0) {
                    this.firstChild = child;
                }
                child.index = index;
                child.parent = this;
                if(lastSibling) {
                    lastSibling.right = child;
                    child.left = lastSibling;
                }
                lastSibling = child;
                this.children.push(child);
            })
        }

    }

    getActiveLevelPaths() {
        return this.activeLevelsReference.map((node) => {
            return node.originalNode.get('name').split(' ').join('-').toLowerCase();
        })
    }

    getRootNode() {
        let rootNode = this;
        let trials = 0;
        while(rootNode.parent && trials < 1000) {
            rootNode = rootNode.parent;
            trials++;
        }
        if(trials >= 999) {
            // console.log("Infinite loop in getRootNode going up direction")
            throw new Error("15-12 Infinite loop in getRootNode going up direction")
        }
        return rootNode;
    }

    generateOriginalPaths(node, path = '') {
        let currentNode = node || this.getRootNode();
        let fullPath = path;
        currentNode.originalPath = (fullPath ? fullPath + '_' : '') + currentNode.originalNode.get('name').split(' ').join('-').toLowerCase();
        if(currentNode.children) {
            currentNode.children.map((child) => {
                this.generateOriginalPaths(child, currentNode.originalPath);
            })
        }
    }

    generateNewPaths(node, path = '') {
        let currentNode = node || this.getRootNode();
        let fullPath = path;
        currentNode.newPath = (fullPath ? fullPath + '_' : '') + currentNode.originalNode.get('name').split(' ').join('-').toLowerCase();
       
       
        if(currentNode.children) {
            currentNode.children.map((child) => {
                this.generateNewPaths(child, currentNode.newPath);
            })
        }
    }

    searchNode(node) {
        if(this.originalNode == node) {
            return this;
        }
        let foundNode = null;
        this.children.map((child) => {
            if(!foundNode) {
                foundNode = child.searchNode(node);
            }
        })
        return foundNode;
    }

    getAmountOfSubLevels() {
        let amount = 0;
        if(this.firstChild) {
            amount++;
            amount += this.firstChild.getAmountOfSubLevels();
        }
        return amount;
    }

    getPathsToMove(node, paths = []) {
        let currentNode = node || this.getRootNode();
        let pathsToMove = paths;
        
        if(currentNode.children) {
            currentNode.children.forEach((child) => {
                this.getPathsToMove(child, pathsToMove);
            })
        }

        if(currentNode.originalPath != currentNode.newPath) {
            pathsToMove.push({ 
                originalPath: currentNode.originalPath + ".json", 
                newPath: currentNode.newPath + ".json" });
        }

        return pathsToMove;
    }


    recreateChildren(firstChild) {
        let children = [];
        if(firstChild) {
            let currentChild = firstChild;
            children.push(currentChild);
            if(currentChild.firstChild) {
                currentChild.children = this.recreateChildren(currentChild.firstChild);
            }
            let trials = 0;
            while(currentChild.right && trials < 1000) {
                
                currentChild = currentChild.right;
                children.push(currentChild);
                if(currentChild.firstChild) {
                    currentChild.children = this.recreateChildren(currentChild.firstChild);
                }
                trials++;
            }
            if(trials >= 999) {
                // console.log("Infinite loop in recreateChildren going right direction")
                throw new Error("15-12 Infinite loop in recreateChildren going right direction")
            }
        }
        return children;
    }

    recreateTree() {
        let rootNode = this;
        let trials = 0;
        while(rootNode.parent && trials < 1000) {
            rootNode = rootNode.parent;
            trials++;
        }

        if(trials >= 999) {
            // console.log("Infinite loop in recreateTree going up direction")
            throw new Error("15-12 Infinite loop in recreateTree going up direction")
        }

        let firstChild = rootNode.firstChild;
        
        if(firstChild) {
            rootNode.children = this.recreateChildren(firstChild);
        }
        
        return rootNode;

    }

    recreateSiteNode(node) {
        let originalNode = node.originalNode;
        if(node.children) {
            let formattedChildren = new Immutable.List();
            node.children.map((child) => {
                formattedChildren = formattedChildren.push(this.recreateSiteNode(child));
            })
            originalNode = originalNode.set('treenodes', formattedChildren);
        }
        return originalNode
    }

    recreateSite() {
        this.recreateTree();
        
        let rootNode = this;
        let trials = 0;
        while(rootNode.parent && trials < 1000) {
            rootNode = rootNode.parent;
            trials++;
        }
        if(trials >= 999) {
            // console.log("Infinite loop in recreateSite going up direction")
            throw new Error("15-12 Infinite loop in recreateSite going up direction")
        }

        // console.log("15-12 should recreated site")

        return this.recreateSiteNode(rootNode);

    } 

    getFirstSibling() {
        let sibling = this;
        let trials = 0;
        while(sibling.left && trials < 1000) {
            sibling = sibling.left;
            trials++;
        }
        if(trials >= 999) {
            // console.log("Infinite loop in getFirstSibling going left direction")    
            throw new Error("15-12 Infinite loop in getFirstSibling going left direction")
        }
        return sibling;
    }

    getLastSibling() {
        let sibling = this;
        let trials = 0;
        while(sibling.right && trials < 1000) {
            sibling = sibling.right;
            trials++;
        }
        if(trials >= 999) {
            // console.log("Infinite loop in getLastSibling going right direction")
            throw new Error("15-12 Infinite loop in getLastSibling going right direction")
        }
        return sibling;
    }

    placeLeft(node) {

        let destinationNodeLeft = this.left;
        let destinationNodeRight = this.right;
        let sourceNodeLeft = node.left;
        let sourceNodeRight = node.right;

        if(this == node) {
            // console.log("15-12 same placement movement");
            return;
        }

        if(destinationNodeLeft == node) {
            // console.log("15-12 same placement movement");
            return;
        }

        if(this == sourceNodeLeft) {
            // Zit ik aan de uiterste linkerkant van de node?
            if(!destinationNodeLeft) {
                this.parent.firstChild = node;
            }
            if(destinationNodeLeft) {
                destinationNodeLeft.right = node;
            }
            node.left = destinationNodeLeft;
            node.right = this;
            this.left = node;
            this.right = sourceNodeRight;
            // console.log("15-12 same movement");
            return;
        }

        if(!destinationNodeLeft) {
            this.parent.firstChild = node;
        }

        if(sourceNodeLeft) {
            sourceNodeLeft.right = sourceNodeRight;
        }

        if(sourceNodeRight) {
            sourceNodeRight.left = sourceNodeLeft;
            if(!sourceNodeLeft) {
                sourceNodeRight.parent.firstChild = sourceNodeRight;
            }
        }

        if(destinationNodeLeft) {
            destinationNodeLeft.right = node;
        }

        node.left = destinationNodeLeft;
        node.right = this;
        this.left = node;

        if(this.parent != node.parent) {
            node.parent = this.parent;
            node.level = this.level;
        }
    }

    placeRight(node) {
        let destinationNodeLeft = this.left;
        let destinationNodeRight = this.right;
        let sourceNodeLeft = node.left;
        let sourceNodeRight = node.right;

        if(this == node) {
            // console.log("15-12 same placement movement");
            return;
        }

        if(destinationNodeRight == node) {
            // console.log("15-12 same placement movement");
            return;
        }

        if(!sourceNodeLeft) {
            sourceNodeRight.parent.firstChild = sourceNodeRight;
        }
        if(sourceNodeLeft) {
            sourceNodeLeft.right = sourceNodeRight;
        }
        if(sourceNodeRight) {
            sourceNodeRight.left = sourceNodeLeft;
        }

        this.right = node;
        node.left = this;
        if(destinationNodeRight) {
            destinationNodeRight.left = node;
        }
        node.right = destinationNodeRight;
        if(this.parent != node.parent) {
            node.parent = this.parent;
            node.level = this.level;
        }
    }

    placeLastChild(node) {
        let destinationNodeLeft = this.left;
        let destinationNodeRight = this.right;
        let sourceNodeLeft = node.left;
        let sourceNodeRight = node.right;

        if(this.firstChild) {
            this.firstChild.placeRight(node);
            return;
        }

        if(this == node) {
            // console.log("15-12 same placement movement");
            return;
        }

        if(!sourceNodeLeft) {
            sourceNodeRight.parent.firstChild = sourceNodeRight;
        }
        if(sourceNodeLeft) {
            sourceNodeLeft.right = sourceNodeRight;
        }
        if(sourceNodeRight) {
            sourceNodeRight.left = sourceNodeLeft;
        }

        this.firstChild = node;
        node.parent = this;
        node.left = null;
        node.right = null;
        node.level = this.level + 1;
        

    }



    /*
        add to left
        remove from left
        add to right
        remove from right
        check amount of sublevels
    */

}