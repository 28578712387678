import React from 'react';

class TextArea extends React.Component {
    constructor() {
        super();
        this.state = { value: "" }
    }

    componentDidMount(){
        let {value} = this.props;

        this.setState({
            innerValue: value
        });
    }

    componentDidUpdate(oldProps) {
        if (this.props.value != oldProps.value) {
            this.setState({
                innerValue: this.props.value
            });
        }
    }

    handleKeyPress(e) {
        let { onEnter } = this.props;
        if (e.key === 'Enter' && onEnter) {
            onEnter(e.target.value)
        }
    }

    handleChange(e) {
        let { onChange } = this.props;

        if (onChange) {
            onChange(e.target.value);
        }
        this.setState({ innerValue: e.target.value })
    }

    handleFocus(e) {
        let { onFocus } = this.props;

        if (onFocus) {
            onFocus(e.target.value);
        }
    }

    handleBlur(e) {
        let { onBlur } = this.props;
        if (onBlur) {
            onBlur(e.target.value);
        }
    }

    render() {
        let { className, error, label, onChange, onEnter, onBlur, value, noMargin, ...others } = this.props;
        let { innerValue } = this.state;

        let focussedClass = "focus:border-[#80bdff] focus:outline-0 focus:shadow-[0_0_0_0.2rem_rgba(0,123,255,0.25)]"

        return (
            <div className="mb-4 text-left">
                {label && <label className="inline-block mb-2">{label}</label>}
                <textarea className={`block w-full py-1.5 rounded border border-[#ced4da] ${focussedClass} ${className}`} value={innerValue || ""} onBlur={this.handleBlur.bind(this)} onFocus={this.handleFocus.bind(this)} onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} id={label} {...others} />
                {error && <small class="form-text text-danger">{error}</small>}
            </div>
        )
    }
}
export default TextArea;