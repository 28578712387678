import ReducersService from 'Services/ReducersService';
import Immutable from 'immutable';

ReducersService.registerReducer('COMPONENTS_READY', (state, action) => {
    return state.setIn(['components'], Immutable.Iterable.isIterable(action.data) ? action.data : Immutable.fromJS(action.data));
});

ReducersService.registerReducer('UPDATE_NODE_CONFIGURATION', (state, action) => {
    return state.setIn(['nodeconfiguration'], Immutable.Iterable.isIterable(action.data) ? action.data : Immutable.fromJS(action.data)).set('nodeconfigurationindex', action.index);
});

ReducersService.registerReducer('TOGGLE_GRAPHIC', (state, action) => {
    return state.setIn(['showMobileGraphic'], !state.getIn(['showMobileGraphic']))
});

ReducersService.registerReducer('UPDATE_SELECTED_COMPONENT_CATEGORY', (state, action) => {
    return state.setIn(['componentSelectedCategory'], action.selectedCategory)
});

ReducersService.registerReducer('UPDATE_SELECTED_BACKDROP_CATEGORY', (state, action) => {
    return state.setIn(['backdropSelectedCategory'], action.selectedCategory)
});