import React from 'react';
import { connect } from 'react-redux';
import Dropdown, { Item as DropDownItem} from 'Controls/Dropdown';
import { Link } from 'react-router-dom';
import Immutable from 'immutable';
// import History from 'BmsView/History';
import Store from 'Store';
import { loadCustomers } from 'BmsView/Actions/Customers';
import CreateCustomerDialog from './CreateCustomerDialog';
import DesktopService from '../../Services/DesktopService';
import { redirect } from 'react-router-dom'
import { withBMS } from '../Context/BMS';


class SwitchSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = { customercreating: false };
        this.onDesktopNavigate = this.onDesktopNavigate.bind(this);
    }

    onDesktopNavigate(link) {
        // console.log("on desktop navigate was called");
        // console.log("this is the link", link);
        let location = { pathname: link }
        redirect(link);
        // History.replace(location);
    }

    componentDidMount() {
        let { customers } = this.props;
        DesktopService.subscribe('react-navigation', this.onDesktopNavigate);
        if (!customers) {
            loadCustomers();
        }
    }

    componentWillUnmount() {
        DesktopService.unsubscribe('react-navigation', this.onDesktopNavigate);
    }

    componentDidUpdate(prevProps) {
        if (this.props.customers && this.props.site && this.props.currentUser)  {
            DesktopService.send('update-switch-customer-menu', { customers: this.props.customers.toJS(), site: this.props.site.toJS(), currentUser: this.props.currentUser.toJS() })
        }
    }

    toggle() {
        this.setState(({ toggled }) => ({
            toggled: !toggled
        }));
    }

    visit(link) {
        redirect(link);
        Store.dispatch({ type: 'TOGGLE_LEFT_SLIDE' });
        this.setState({
            toggled: false
        })
    }

    clearDialog() {
        this.setState({ customercreating: false });
    }

    async customerCreated() {
        await loadCustomers();
        this.clearDialog();
    }

    openDialog() {
        this.setState({ customercreating: {} });
    }

    renderMobile() {
        let { site, customers, currentUser } = this.props;
        let {customercreating} = this.state || {};

        const defaultLinkClassName = 'text-gray-700 block px-4 py-1 hover:bg-[rgb(0,0,0,0.15)] whitespace-nowrap';

        return (
            <React.Fragment>
                <CreateCustomerDialog selecteditem={customercreating} onSave={this.customerCreated.bind(this)} onClose={this.clearDialog.bind(this)} />

                <Dropdown innerClassName="max-h-[85vh] overflow-y-scroll" title={`${site && `${site.get('name').split('-').map((name) => { return name[0].toUpperCase() + name.substring(1) }).join(' ')} - ` || ''}Switch site`}>
                    {(customers || []).filter((currentCustomer) => {
                        return currentCustomer.get('sites') && currentCustomer.get('sites').size > 0;
                    }).filter((currentCustomer) => {
                        return currentUser.get('sites', Immutable.List([])).filter((currentSite) => {
                            if (!currentSite) {
                                return false;
                            }
                            return currentSite.get('name').indexOf(currentCustomer.get('name').toLowerCase().split(' ').join('-')) >= 0;
                        }).size > 0;
                    }).map((currentCustomer, index) => {
                        return (
                            <React.Fragment key={index}>
                                <DropDownItem><div className="py-2 px-4 text-[#6c757d] text-sm">{currentCustomer.get('name')}</div></DropDownItem>
                                {currentCustomer.get('sites').filter((currentSite) => {
                                    return currentUser.get('sites', Immutable.List([])).filter((slug) => {
                                        if (!slug) {
                                            return false;
                                        }
                                        return slug.get('name') == `${currentCustomer.get('name').toLowerCase().split(' ').join('-')}:${currentSite.get('name').toLowerCase().split(' ').join('-')}`;
                                    }).size > 0
                                }).map((currentSite) => {
                                    
                                    return <DropDownItem key={currentSite} 
                                        to={`/customer/${currentCustomer.get('name').toLowerCase().split(' ').join('-')}/site/${currentSite.get('name').toLowerCase().split(' ').join('-')}`}
                                        className={defaultLinkClassName}
                                        as={Link}>
                                       {currentSite.get('name')}
                                    </DropDownItem>
                                })}
                                <hr className="my-2" />
                            </React.Fragment>
                        );
                    })}
                </Dropdown>
            </React.Fragment>
        )
    }

    render() {
        let { site, customers, currentUser, mobileOnly } = this.props;

        return this.renderMobile();
    }
}

// export default connect((state) => ({
//     currentUser: state.get('currentUser'),
//     customer: state.get('customer'),
//     site: state.get('site'),
//     customers: state.get('customers')
// }))(SwitchSite);

export default withBMS(SwitchSite);