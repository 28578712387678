import React from 'react';
import Input from 'Controls/Form/Input';
import TextArea from 'Controls/Form/TextArea';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function EmailFormDialog({ selecteditem, onClose, ...props }) {

    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <EmailFormDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class EmailFormDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        let {currentuser, customer} = this.props;

        this.state = {
            email: currentuser.get('email'),
            customer: customer.get('name'),
            message: ''
        }
    }

    emailChanged(value) {
        this.setState({
            email: value
        });
    }

    customerChanged(value) {
        this.setState({
            customer: value
        });
    }

    messageChanged(value) {
        this.setState({
            message: value
        });
    }

    onSend() {
    //    let {sendEmail} = this.props;
        let {email, customer, message} = this.state;

    //    sendEmail(this.state.email, this.state.customer, this.state.message);

        Swal.fire("Success", "Email sent successfully", "success")
    }

    render() {
        let {email, message, customer} = this.state;

        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Contact Us</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <form>
                        <div>
                            <Input label="From" value={email} onChange={this.emailChanged.bind(this)}/>
                            <Input label="Customer" value={customer} onChange={this.customerChanged.bind(this)}/>
                            <TextArea label="Message" value={message} onChange={this.messageChanged.bind(this)}/>
                        </div>
                    </form>
                    <Button role="save" onClick={this.onSend.bind(this)}>Send</Button>
                </DialogBody>
                <DialogFooter>
                    <Button role="secondary" onClick={this.props.onClose}>Cancel</Button>
                </DialogFooter>
            </DialogContent>          
        )
    }
}

export default EmailFormDialog;