import React, {useState, useEffect} from 'react';
import Button from 'Controls/Button/UserDefined';
import Immutable from 'immutable';
import {getGroups, saveGroup, deleteGroup} from '../Actions';
import AlarmFilterGroupSetupDialog from './AlarmFilterGroupSetupDialog';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Swal from 'sweetalert2';
import { PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/solid';

const AlarmFilterGroups = ({customer}) => {
    let [groups, setGroups] = useState();
    let [selectedGroup, setSelectedGroup] = useState();

    useEffect( ()=> {
        getAlarmFilterGroups();
    }, []);

    const getAlarmFilterGroups = ()=> {
        getGroups(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`,'alarmfiltergroups').then((data) => {
                setGroups(Immutable.fromJS(data));
            });
    }

    const displayDialog = (group) => {
        setSelectedGroup(group);
    }

    const closeDialog = () => {
        setSelectedGroup(undefined);
    }

    const removeGroup = (group) => {
        deleteGroup(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(),'alarmfiltergroups', group.get('_id')).then(() => {
            Swal.fire("Success", "The group has been removed successfully", "success");
            getAlarmFilterGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error removing group", "error");
        });
    }

    const checkDuplicate = (group) => {
        let grp = groups.filter((g) => {
            return g.get('name') == group.get('name') && g.get('_id') != group.get('_id');
        });

        return grp.size;
        
    }

    const saveFilterGroup = (group) => {
        let msg = "The group has been updated successfully";

        if (!group.get('_id')) {
          // check for duplicate
          
            if(checkDuplicate(group)) {
                Swal.fire(
                    'Error!',
                    'Duplicate Name',
                    'error'
                );
                return;
            } 

          msg = "The group has been added successfully";
        }  

        saveGroup(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(), 'alarmfiltergroups', group).then(() => {
            Swal.fire("Success", msg, "success");
            getAlarmFilterGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error adding group", "error");
        });


    }
    
    return(
        <div>
            { selectedGroup && 
            <AlarmFilterGroupSetupDialog selecteditem={selectedGroup} customer={customer} onClose={() => closeDialog()} onSave={(group) => saveFilterGroup(group)}/>}
            <div className='container mx-auto my-4'>
                <h2>Alarm Filter Groups</h2>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-4">
                        <Input className="" disabled={true} label="Customer" value={customer.get('name')}/>
                    </div>
                </div>
                <h4>Group List</h4>
                <div>
                    <Grid>
                        <Grid.Header>
                            <Grid.Column classes="col-span-16 md:col-span-5">Name</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-7">Description</Grid.Column>
                            {/* <Grid.Column classes="col-span-16 md:col-span-2" name="edit"></Grid.Column> */}
                            <Grid.Column classes="col-span-16 md:col-span-4" name="add" type="complex">
                                <Grid.Controls>
                                    <Grid.Control>
                                        <PlusIcon className="w-6 text-white cursor-pointer" title="add group list" onClick={() => displayDialog(Immutable.Map({customer:customer.get('name')}))} />
                                        
                                    </Grid.Control>
                                </Grid.Controls>
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            {(groups || []).map((group, index) => {
                                return(
                                    <Grid.Row key={index} className="hover:bg-gray-300 group">
                                        <Grid.Column classes="col-span-16 md:col-span-5">{group.get('name')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-7">{group.get('description')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4">
                                            <Grid.Controls>
                                                <Grid.Control><PencilSquareIcon title="Edit" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue w-6" onClick={() => displayDialog(group)} /></Grid.Control>
                                                <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 w-6" onClick={() => removeGroup(group)} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid.Data>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default AlarmFilterGroups;