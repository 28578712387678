import React, { useState, useEffect, useRef, Fragment, forwardRef } from 'react';
import * as d3 from 'd3';
import SVGLayer from './SVGLayer';

export default forwardRef(SVGPanel);

function SVGPanel(
    { openAreaDialog, renderedInDialog, nodeConfiguration, backdrop, pointDataMap, onSelectionBox, layers, editmode, selectionmode, onNodeChange, onNodeConfiguration, onShowContextMenu, onPanelContextMenu, toggleSelection, toggleSingleSelection, selection, mouseMovement, setMouseMovement, onMultiDragFinished, onNodeMount }
    , ref ) {

    const divRef =ref;
    const scaleFactorRef = useRef(1);
    const selectionBoxRef = useRef(null);
    const [activeSelectionBox, setActiveSelectionBox] = useState(null);
    const [defaultHeight, setDefaultHeight] = useState('100%');

    const activateSelectionBoxInSelectionMode = (evt) => {
        if(evt.nativeEvent.which != 1) {
            return;
        }
        if(editmode && selectionmode) {
            setActiveSelectionBox({ startX: evt.clientX, startY: evt.clientY, endX: undefined, endY: undefined });
        }
    }

    const moveSelectionBoxInSelectionMode = (evt) => {
        if(editmode && selectionmode && activeSelectionBox) {
            setActiveSelectionBox((current) => ({ ...current, endX: evt.clientX, endY: evt.clientY }));
        }
    }

    const deactiveSelectionBoxInSelectionMode = (evt) => {
        if(!activeSelectionBox) return;
        let { startX, startY, endX, endY } = activeSelectionBox;
        if(editmode && selectionmode && activeSelectionBox) {
            onSelectionBox(startX, startY, endX, endY);
            setActiveSelectionBox(() => undefined);
        }
    }

    let selectionDiv = { display: 'none' };
    if(activeSelectionBox) {
        let { startX, startY, endX, endY } = activeSelectionBox;
        if(endX !== undefined) {
            let left = startX;
            let top = startY;
            if(left > endX) {
                left = endX;
            }
            if(top > endY) {
                top = endY
            }
            selectionDiv['display'] = 'block';
            selectionDiv['position'] = 'absolute';
            selectionDiv['left'] = left;
            selectionDiv['top'] = top;
            selectionDiv['width'] = Math.abs(Math.round(endX - startX)) + "px";
            selectionDiv['height'] = Math.abs(Math.round(endY - startY)) + "px";
            selectionDiv['border'] = "1px dashed black";
        }

    }

    const handleResize = () => {
        let areaMenuElement = document.getElementById('area-menu');
        let areaMenuHeight = 0;
        if(areaMenuElement) {
            areaMenuHeight = areaMenuElement.clientHeight;
        }
    
        areaMenuElement = document.getElementById('nav-bar');
        let navBarHeight = 50;
        if(areaMenuElement) {
            navBarHeight = areaMenuElement.clientHeight;
        }
        setDefaultHeight(() => `${window.innerHeight - navBarHeight - areaMenuHeight}px`);
    }
 
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

   
/*
    useEffect(() => {
        /*
            This effect is needed to maintain responsiveness if a fixed width and height has been given in the 
            configuration.
        *//*
        const extraResizeHandler = () => {
            let div = divRef.current;
            if(div) {
                let settingsWidth = nodeConfiguration.getIn(['style', 'width'], 1920);
                let settingsHeight = nodeConfiguration.getIn(['style', 'height'], 800);
                let width = window.innerWidth;
                let height = window.innerHeight;
                let scale = Math.min(width / settingsWidth, height / settingsHeight);
                divRef.current.style.zoom = scale;
            }    
        }
        if(nodeConfiguration && (nodeConfiguration.getIn(['style', 'width']) || nodeConfiguration.getIn(['style', 'height']))) {
            window.addEventListener('resize', extraResizeHandler);
            extraResizeHandler();
        } 

        return () => {
            if(nodeConfiguration && (nodeConfiguration.getIn(['style', 'width']) || nodeConfiguration.getIn(['style', 'height']))) {
                window.removeEventListener('resize', extraResizeHandler);
            }
        }
    }, [nodeConfiguration])  */

    useEffect(() => {
        let panelContextMenuFunction = onPanelContextMenu;
        d3.select(divRef.current).on('contextmenu', panelContextMenuFunction);
        return () => {

            d3.select(divRef.current).on('contextmenu', null);
        }
    }, [editmode, selectionmode]);

    let settingsWidth = 1920;
    let settingsHeight = 800;
    let svgStyle = {
        height:defaultHeight,
    }
    
    if(nodeConfiguration && nodeConfiguration.get('style')) {
        svgStyle.backgroundColor = nodeConfiguration.getIn(['style','backgroundColor']);
        settingsWidth = nodeConfiguration.getIn(['style', 'width'], 1920);
        settingsHeight = nodeConfiguration.getIn(['style', 'height'], 800);
        svgStyle.width =  `min(${settingsWidth}px, 100%)`;
        svgStyle.height = `min(${settingsHeight}px, 100%)`;

    }

    // If commented this out, because it prevents resizing of area's that have 1920x800, it also seems to me
    // that we don't need that extra check
    if(!(settingsWidth == 1920 && settingsHeight == 800) && !renderedInDialog) {
    // if(!renderedInDialog) {
        svgStyle.width =  `${settingsWidth}px`;
        svgStyle.height = `${settingsHeight}px`;
        svgStyle['maxWidth'] =  settingsWidth + "px";
        svgStyle['maxHeight'] =  settingsHeight + "px";
        svgStyle['left'] =  "50%";
        svgStyle['transform'] = "translateX(-50%)";
        svgStyle['border'] = "1px solid #444";
    }

    if(renderedInDialog) {
        svgStyle.width =  `${settingsWidth}px`;
        svgStyle.height = `${settingsHeight}px`;
    }

    return <Fragment>
        <svg className={` ${renderedInDialog ? '' : 'fixed'} w-full mx-auto `} style={svgStyle}  viewBox={`0 0 ${settingsWidth} ${settingsHeight}`}
        
            preserveAspectRatio='xMidYMin meet'
            onMouseDown={(evt) => activateSelectionBoxInSelectionMode(evt)}  
            onMouseUp={(evt) => deactiveSelectionBoxInSelectionMode(evt)}
            onMouseMove={(evt) => activeSelectionBox && moveSelectionBoxInSelectionMode(evt)}
            ref={divRef}
           >
        { layers && layers.map((layer, layerIndex) => {
            return <SVGLayer 
                openAreaDialog={openAreaDialog}
                pointDataMap={pointDataMap}
                onNodeMount={onNodeMount}
                onShowContextMenu={onShowContextMenu}
                onNodeConfiguration={onNodeConfiguration}
                toggleSelection={toggleSelection}
                toggleSingleSelection={toggleSingleSelection}
                onMultiDragFinished={onMultiDragFinished}
                mouseMovement={mouseMovement}
                setMouseMovement={setMouseMovement}
                onNodeChange={onNodeChange}
                selection={selection}
                scale={scaleFactorRef.current}
                editmode={editmode}
                selectionmode={selectionmode}
                key={layerIndex} layer={layer} layerIndex={layerIndex} />
        })}
         
        </svg>
        <div ref={selectionBoxRef}
                onMouseDown={(evt) => activateSelectionBoxInSelectionMode(evt)}  
                onMouseUp={(evt) => deactiveSelectionBoxInSelectionMode(evt)}
                onMouseMove={(evt) => activeSelectionBox && moveSelectionBoxInSelectionMode(evt)}
                style={selectionDiv}></div>
    </Fragment>
}