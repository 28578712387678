import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import DateTimeSelection from 'Controls/DateTimeSelection';
import Immutable, { OrderedMap, Map, List, fromJS } from 'immutable';
import Button from 'Controls/Button/UserDefined';
import ServiceBus from 'Services/ServiceBus';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { useSubscription } from '../../../../Context/AreaPoint';
import { withBMS } from '../../../Context/BMS';

function ReportsDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <ReportDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}


function ReportDialogPanel({ selecteditem, customer, onClose }) {

    const subscription = useSubscription();
    const [records, setRecords] = useState(OrderedMap());
    const [selectedYear, setSelectedYear] = useState(2023);
    const [dateTimeRange, setDateTimeRange] = useState({ 
        rangeType: selecteditem.getIn(['configuration', 'period'], 'Today')
    });

    const selectedconfiguration = selecteditem.get('configuration');

    const selectedpoints = selectedconfiguration.get('points');

    const getHistory = () => {
        if(selectedpoints && selectedpoints.size > 0) {        
            getReportData(selectedpoints.map(x => x.getIn(['value']).split('-') ).toJS());
        }
    } 

    const getReportData = (points) => {
        let duration = selecteditem.getIn(['configuration', 'duration'], 'This Year');
        let dateTime = new Date();

        let year = dateTime.getFullYear();

        switch (duration) {
            case 'Last Year': 
                year--;
                break;
        }

        let address = points[0];
        let subsystemNumber = parseInt(address[0]);
        let request = {
            m_level :10,         
            m_communicNum : 313,
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            type: 'GENERIC',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            trackMessage: true
        };
        let comm = {
            m_year: year, //selectedYear || 2023, // hier nog de jaar invullen
            m_pointAddrs: points.map(address => address.map((byte) => parseInt(byte)))
        };

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }
        

    useEffect(() => {
        getHistory();
        const unsubscribe = ServiceBus.on('313-11', (message) => {
            setRecords((records) => {
                if(message?.m_monthlyValues) {
                    message.m_monthlyValues.forEach((point) => {
                        // console.log("point", point);
                        let address = (point?.m_pointAddr || []).join('-');
                        let record = records.get(address);
                        if(record) {
                            record = record.set('history', fromJS(point?.m_accumulatedValues || []));
                            records = records.set(address, record);
                        } else {
                            // console.log("no record found for", address);
                        }
                    })
                }
                // console.log("should be records", records.toJS());
                return records;
            })
        });
        return () => {
            unsubscribe();
        }
    }, []);

    useEffect(() => {
        if(selecteditem) {
            let records = OrderedMap();
            selectedpoints.forEach((point) => {
                records = records.set(point.get('value'), Map({ history: List(), name: point.get('label') }));
            })
            setRecords(records);
            getHistory();

        }
    }, [selecteditem]);

    useEffect(() => {
        getHistory();
    }, [dateTimeRange]);

    const headers = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'];
    
    return (
        <DialogContent className="md:w-[70rem]" >       
            <DialogBody>
                <div className="flex flex-row mt-4"> 
                    <DateTimeSelection dateTimeRange={dateTimeRange} updateDateTimeRange={(v) => setDateTimeRange(v)}/>
                </div>
                <div style={{ width: "100%", height: "500px" }}>     

                    <div className="grid grid-cols-13 gap-4">
                        {headers.map(x => <div key={`header${x}`}>{x}</div>)}
                        {records.map((record, address) => {
                            return <React.Fragment>
                                <div>{record.get('name')}</div>
                                {record.get('history').map((x,i) => <div key={`v${i}`}>
                                    {Number(x).toFixed(1)}
                                </div>)}
                            </React.Fragment>
                        }).toList()}

                    </div>


                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="close" onClick={onClose}>Close</Button>
            </DialogFooter>
        </DialogContent>    
    );

}


// export default connect((state) => ({
//     customer: state.get('customer')
// }))(ReportsDialog);

export default withBMS(ReportsDialog);