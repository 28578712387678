import React, { useEffect, useState, Fragment } from 'react';
import {connect} from 'react-redux';
import DateTimeSelection from 'Controls/DateTimeSelection';
import ChartHistories from 'BmsView/Customer/Histories/Chart';
import { getHistoryData } from 'BmsView/Customer/Histories/Actions';
import Immutable, { List, Map } from 'immutable';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { useBMS, withBMS  } from '../../../Context/BMS';
import { HistoriesTable, currentDay, previousDay, nextDay, formatDate } from '../../Histories/HistoriesDialog';

// TODO: has to be adjusted as well for histories
function HistoriesDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <HistoriesDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

function HistoriesDialogPanel({ selecteditem, onClose }) {

    const { customer } = useBMS();

    const selectedconfiguration = selecteditem.get('configuration');
    const point = selecteditem;
    const pointselected = Immutable.Map({
        'name': point.getIn(['configuration', 'pointname']), 
        'address': point.getIn(['configuration', 'boundto']),
        'engunits': [point.getIn(['configuration', 'engunit'])],
        'linecolour': point.getIn(['configuration', 'linecolour'])
    });

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    const [selectedpoints, setSelectedPoints] = useState(List([pointselected]));
    const [records, setRecords] = useState([]);
    const [charttype, setChartType] = useState(point.getIn(['configuration', 'charttype']));
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType: point.getIn(['configuration', 'period'], 'Today'),
        startDate: startDate,
        endDate: new Date()
    });
    const [displayMode, setDisplayMode] = useState('chart');
    const [currentDate, setCurrentDate] = useState(currentDay());

    const getHistory = async () => {
        let points = [];

        selectedpoints.map((point) => {
            points.push(point.get('address'));
        });

        let dateStartToMongo = new Date(dateTimeRange.startDate);
        dateStartToMongo.setHours(dateStartToMongo.getHours() - 1);

        let startDate = formatDate(dateStartToMongo);
        let endDate = formatDate(dateTimeRange.endDate);
        if(displayMode == 'table') {
            startDate = currentDate;
            endDate = currentDate;
        }

        var queryObject = {
            addrstring: { $in: points},
            datestring: { $gte: `${startDate}`,
             $lte: `${endDate}` }
        }

        const data = await getHistoryData(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`,
            queryObject);

        setRecords((data || []).map((record) => {
            return {
                name: record.addrstring,
                time: record.datestring,
                samples: record.sample
            }
        }));
    
    }

    useEffect(() => {
        (async () => {
            await getHistory();
        })()
    }, [dateTimeRange, currentDate, displayMode]);

    return (
        <DialogContent className="md:w-[70rem]" >       
            <DialogBody>
                { displayMode == 'chart' && <Fragment>
                    <div className="flex flex-row mt-4"> 
                        <DateTimeSelection dateTimeRange={dateTimeRange} updateDateTimeRange={setDateTimeRange}/>
                    </div>
                    <div style={{ width: "100%", height: "500px" }}>
                        <ChartHistories records={records} dateSelectionType={dateTimeRange.rangeType} dateStart={dateTimeRange.startDate} dateEnd={dateTimeRange.endDate} selectedpoints={selectedpoints} charttype={charttype}/>
                    </div>
                </Fragment> }
                { displayMode == 'table' && <Fragment>
                    <div className="flex flex-col gap-1">
                        <div className="whitespace-nowrap"><span className="font-bold">Date:</span><span> {currentDate}</span></div>
                        <div className="flex flex-row gap-3 items-center justify-between">
                            
                            <div className="flex flex-row gap-3">
                                <div><Button className="whitespace-nowrap" role="primary" onClick={() => setCurrentDate(previousDay(currentDate))}>Previous day</Button></div>
                                <div><Button className="whitespace-nowrap" role="primary" onClick={() => setCurrentDate(nextDay(currentDate))}>Next day</Button></div>
                            </div>
                        </div>
                    </div>
                    <HistoriesTable records={records} selectedpoints={selectedpoints} />
                </Fragment> }
                <div className="flex flex-row justify-end p-2">
                    <Button role="primary" onClick={() => setDisplayMode(x => x=='chart' ? 'table' : 'chart' )}>
                        { displayMode == 'chart' ? 'Show Table' : 'Show Chart'}
                    </Button>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="close" onClick={onClose}>Close</Button>
            </DialogFooter>
        </DialogContent>    
    );

}


export default HistoriesDialog;