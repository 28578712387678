import React from 'react';
import Input from 'Controls/Form/Input';
import ColorPicker from 'Controls/ColorPicker';

class Dial extends React.Component {
    valueChanged(field, int, value) {
        let { onChange } = this.props;

        if (int) value = parseInt(value);

        onChange(field, value);
    }

    render() {
        let { selectedcomponent } = this.props;
        return (
            <React.Fragment>
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-2">
                        <Input
                            label="Start Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'startangle'], '') + ''}
                            onChange={this.valueChanged.bind(this, ['configuration', 'startangle'], true)}
                        />
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="End Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'endangle'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'endangle'], true)}
                        />  
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="Start Value"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'startvalue'], '') + ''}
                            onChange={this.valueChanged.bind(this, ['configuration', 'startvalue'], true)}
                        />
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="End Value"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'endvalue'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'endvalue'], true)}
                        /> 
                    </div>
                </div>          
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="Interval"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'interval'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'interval'], true)}
                        /> 
                    </div>
                </div>
                <div className="grid grid-cols-16">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'colourtext'])} onChange={this.valueChanged.bind(this, ['configuration', 'colourtext'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'colourbackground'])} onChange={this.valueChanged.bind(this, ['configuration', 'colourbackground'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Rim" value={selectedcomponent.getIn(['configuration', 'colourrim'])} onChange={this.valueChanged.bind(this, ['configuration', 'colourrim'], false)} /></div>
                </div>
            </React.Fragment>
        )
    }
}

export default Dial;