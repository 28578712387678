import axios from 'axios';
import Configuration from 'Configuration';
import Immutable from 'immutable';


export function getLog(type, customer, connection, q, df, dt){
    return new Promise((resolve, reject) => {
        axios.post(`/api/${type}log/searchdates?database=${Configuration.product}${customer}&connection=${connection}`, { selector: q, gte: df, lte: dt, sort: { datetime: -1 }, limit:5000 }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getUserUnacknowledgedAlarms(customer, userName, connection, abortController){

    let query = {
            "datetime": {
                'gte': new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
            },
            "ackedby": {
                "$not": {
                    "$elemMatch": {
                        "user": userName
                    }
                }
            }
        
        
    };

    return new Promise((resolve, reject) => {
        axios.post(
            `/api/alarmlog/search?database=${Configuration.product}${customer}&connection=${connection}`, 
            { 
                selector: query, 
                sort: { datetime: -1 }, 
                limit:500 
            },
            { signal: abortController.signal }
        ).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getUnacknowledgedAlarms(customer, connection, abortController){

    let query = {

                "datetime": {
                    'gte': new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
                },

                $or: [
                    { ackedby: { $exists: false } },
                    { ackedby: { $size: 0 } }
                  ]
         
        
       
        
    };

    return new Promise((resolve, reject) => {
        axios.post(
            `/api/alarmlog/search?database=${Configuration.product}${customer}&connection=${connection}`, 
            { selector: query, sort: { datetime: -1 }, limit:500 },
            { signal: abortController.signal }
        ).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getPointsInAlarmRecords(customer, connection, q) {
    return new Promise((resolve,reject) => {
        axios.post(`/api/alarmids/search?database=${Configuration.product}${customer}&connection=${connection}`, {selector:q, sort: { datetime: -1 }}).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getAlarmRecord(customer, connection, id) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/alarmlog/${id}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function updateAlarmRecord(customer, connection, alarm){
    return new Promise((resolve, reject) => {
        axios.put(`/api/alarmlog/${alarm.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, alarm.toJS()).then(resolve).catch(reject);
    });
}

export function acknowledgeAlarms(customer, connection, alarmIds, ackBody) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/alarmlog/acknowledge?database=${Configuration.product}${customer}&connection=${connection}`, { selector: alarmIds, changes: ackBody }).then(resolve).catch(reject);
    });
}