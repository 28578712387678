import axios from 'axios';
import Store from 'Store';
import { fromJS } from 'immutable';

export function checkPassword(username, password) {
    return new Promise((resolve, reject) => {
        let credentials = {
            email: username,
            password: password
        };

        axios.post('/checkpassword', credentials).then((response) => {
            resolve();
        }).catch(reject);
    });
}

export function resetPassword(user) {
    return new Promise((resolve, reject) => {
        axios.post('/resetpassword', { email: user.get('email') }).then(resolve).catch(reject);
    });
}

export function resetPasswordByToken(token, password) {
    return new Promise((resolve, reject) => {
        axios.post(`/resetPassword/${token}`, { password: password }).then(resolve).catch(reject);
    });
}

export function getUserByToken(token) {
    return new Promise((resolve, reject) => {
        axios.get(`/getUserByResetToken/${token}`).then(resolve).catch(reject);
    });
}

export function login(username, password){
    return new Promise((resolve, reject) => {
        let credentials = {
            email: username,
            password: password
        };

        axios.post('/login', credentials).then((response) => {
            resolve();
        }).catch(reject);
    });
}

export function getCurrentUser(){
    return new Promise((resolve, reject) => {
        axios.get('/getCurrentUser').then((response) => {
            Store.dispatch({
                type: 'LOGIN',
                currentuser: response['data']
            });
            resolve(response['data']);
        }).catch(reject);
    });
}

export function fetchCurrentUser() {
    return new Promise((resolve, reject) => {
        axios.get('/getCurrentUser').then((response) => {
            resolve(fromJS(response.data));
        }).catch(reject);
    });

}

export function logout() {
    return new Promise((resolve, reject) => {
        axios.get('/logout').then((response) => {
            // Store.dispatch({
            //     type: 'LOGOUT'
            // });
            resolve();
        }).catch(reject)
    });
}