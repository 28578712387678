import React from 'react';
import Input from 'Controls/Form/Input';
import ColorPicker from 'Controls/ColorPicker';

class Gauge extends React.Component {
    valueChanged(field, float, value) {
        let { onChange } = this.props;

        if (float) value = parseFloat(value);

        onChange(field, value);
    }

    render() {
        let { selectedcomponent } = this.props;
        return (
            <div>
                <div className="grid grid-cols-16 my-4 gap-2">
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Gauge Radius (px)"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'gaugesize'], 200)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'gaugesize'], true)}
                        /> 
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="gauge Thickness"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'gaugethickness'], 0.2)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'gaugethickness'], true)}
                        /> 
                    </div>
                </div>
                <div className="grid grid-cols-16 gap-2">    
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Start Value"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'startvalue'], 0) + ''}
                            onChange={this.valueChanged.bind(this, ['configuration', 'startvalue'], true)}
                        />
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="End Value"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'endvalue'], 100)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'endvalue'], true)}
                        /> 
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Dial Indexes"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'dialindexes'], 10)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'dialindexes'], true)}
                        /> 
                    </div>    
                </div>
                <div className="grid grid-cols-16 gap-2">    
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Alarm Level 1"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'alarmlevel1'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'alarmlevel1'], true)}
                        /> 
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Alarm Level 2"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'alarmlevel2'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'alarmlevel2'], true)}
                        /> 
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Alarm Level 3"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'alarmlevel3'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'alarmlevel3'], true)}
                        /> 
                    </div>
                </div>  
                <div className="grid grid-cols-16 gap-2">    
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Needle Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'needlewidth'], 3)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'needlewidth'], true)}
                        /> 
                    </div>
                    <div className="col-span-8 md:col-span-2">
                        <Input
                            showDeleteControl={true}
                            label="Font Size"
                            type="number"
                            value={selectedcomponent.getIn(['configuration','style', 'fontsize'], 12)}
                            onChange={this.valueChanged.bind(this, ['configuration','style', 'fontsize'], true)}
                        /> 
                    </div>
                </div> 
                <div className="grid grid-cols-16 gap-2">  
                    <div className="col-span-8 md:col-span-4">
                        <Input
                            label="Label Radius Offset (fine tuning)"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'labelradiusoffset'], 4)}
                            onChange={this.valueChanged.bind(this, ['configuration','labelradiusoffset'], true)}
                        /> 
                    </div>  
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Test Value"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'testvalue'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'testvalue'], true)}
                        /> 
                    </div>
                </div>          
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'colourtext'])} onChange={this.valueChanged.bind(this, ['configuration', 'colourtext'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'colourbackground'])} onChange={this.valueChanged.bind(this, ['configuration', 'colourbackground'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Rim" value={selectedcomponent.getIn(['configuration', 'colourrim'])} onChange={this.valueChanged.bind(this, ['configuration', 'colourrim'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Alarm Colour 1" value={selectedcomponent.getIn(['configuration', 'colouralarm1'])} onChange={this.valueChanged.bind(this, ['configuration', 'colouralarm1'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Alarm Colour 2" value={selectedcomponent.getIn(['configuration', 'colouralarm2'])} onChange={this.valueChanged.bind(this, ['configuration', 'colouralarm2'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Alarm Colour 3" value={selectedcomponent.getIn(['configuration', 'colouralarm3'])} onChange={this.valueChanged.bind(this, ['configuration', 'colouralarm3'], false)} /></div>
                </div>
            </div>
        )
    }
}

export default Gauge;