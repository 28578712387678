import React, { useEffect, useState } from 'react';
import TransitioningDialog from 'Controls/Dialog/TransitioningDialog';
import { useArea } from 'Context/Area';
import { useSubscription } from 'Context/AreaPoint';

function AreaPointList() {   
    let { layers } = useArea();

    let points = {};
    layers && layers.forEach((layer) => {
        layer.get('nodes') && layer.get('nodes').forEach((node) => {
            let address = undefined;
            if (node.getIn(['configuration', 'boundto']) && node.getIn(['component', 'type']) != 'chart') {
                address = node.getIn(['configuration', 'boundto']);
                points[address] = { node: node }
            }
            if (node.getIn(['configuration', 'points'])) {
                node.getIn(['configuration', 'points']).forEach((point) => {
                    let address = point.get('value');
                    points[address] = { node: node }
                });
            }
        })
    })

    return (
        <React.Fragment>
            <div className="mt-2 sm:mt-3">
                <h2 className="text-xl leading-6 font-semibold text-gray-900">
                    Point Status List
                </h2>
            </div>
            <div className="sm:px-4 lg:px-6 p-2  h-full overflow-y-scroll"  style={{ minHeight: "70vh", maxHeight: "70vh" }}>
                <ul role="list" className="relative z-0">
                    <li key={'header'} className="bg-blue">
                    <div className="relative px-1 py-1">
                        <div className="grid grid-cols-12 text-white h-full">
                            <div className="col-span-3 py-1">Name</div>
                            <div className="col-span-3 py-1">Address</div>
                            <div className="col-span-3 py-1">Reference</div>
                            <div className="col-span-3 py-1">Value</div>
                        </div>
                    </div>    
                    </li>
                    {Object.keys(points).map((address) => {
                        return <AreaPointListPoint key={address} address={address} point={points[address]} />
                    })}                                   
                </ul>
            </div>
        </React.Fragment>       
    )
}

function AreaPointListPoint({ point, address }) {
    const subscription = useSubscription();
    const [pointData, setPointData] = useState(undefined);
    
    useEffect(() => {
        if(!subscription) {
            return () => {}
        }
        let unsubscribe = subscription.subscribe(address, (data) => {
            setPointData(() => data);
        })

        return () => {
            unsubscribe();
        }

    }, [subscription, address]);

    const formatPointAddress = (address) => {
        var prefix = ["I", "W", "S", "K", "TZ", "D", "PT", "PT", "PT", "PT", "PT", "PT", "TZ" ];
        if(!address) {
            return address;
        }

        let addr = address.split('-');
        if(addr[1] == 5) {
            let newAddress = `L${addr[3]} O${addr[4]} ${prefix[addr[2]]}${addr[5]}`;
            return newAddress;
        }
        return "Not Factored";
    }
    

    if(!(pointData || {}).pointconfiguration) {
        return <div className="col-span-8">
            <p className="text-sm text-gray-900">Please wait while we are getting the point data for: {address} </p>
        </div>
    } 
    let info = pointData || {};
    return <li key={address} className="bg-white" >
        <div className="relative px-1 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">        
            <div className="grid grid-cols-12 h-full">
                <div className="col-span-3">
                    <p className="text-sm text-gray-900 py-1">{pointData.pointconfiguration.m_pointName}</p>
                </div>
                <div className="col-span-3">
                    <p className="text-sm text-gray-900 py-1">{formatPointAddress(address)}</p>
                </div>
                <div className="col-span-3">
                    <p className="text-sm text-gray-900 py-1">{address}</p>
                </div>
                <div className="col-span-3">
                    <p className="text-sm text-gray-900 py-1">{pointData.pointconfiguration.m_formattedValueText}</p>
                </div>
            </div>
        </div>
    </li>
}

function AreaPointListDialog({selecteditem, customer, points, onClose }) {   
    useEffect(() => {
        // console.log('%c mounting AreaPointListDialog', 'color:red')
        return () => {
            // console.log('%c unmounting AreaPointListDialog', 'color:red')
        }
    }, [])

    return <TransitioningDialog zIndex="z-20" opened={!!selecteditem} onClose={onClose} > 
        <div> 
            <div>
                 <AreaPointList customer={customer} points={points} /> 
            </div>
            <div className="p-2 flex justify-end">
                <button className="bg-[#6c757d] p-2 text-[white]" onClick={onClose}>Close</button>
            </div>
        </div>
    </TransitioningDialog>
}

export default AreaPointListDialog;


