import * as d3 from 'd3';
import {displayTooltip, drawCircle} from '../Utils';


const StatusButton = (options) => {
    return new Promise((resolve, reject) => {
        let rotation = options.node.getIn(['component', 'defaultrotation'], 0);

        let createPlacementElement = (options) => 
            d3.select(options.target).append('g').attr('data-index',options.index)
                .attr('x', options.node.getIn(['position', 'x']))
                .attr('y', options.node.getIn(['position', 'y']))
                .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`);

        let placementElement = createPlacementElement(options);

        let radius = parseInt(options.node.getIn(['configuration', 'radius'], 11)) + ( parseInt(options.node.getIn(['configuration', 'buttonborderwidth'],2)) * 1);


        drawCircle(placementElement, 0,0,radius, 
            options.node.getIn(['configuration', 'borderwidth'], 1),'black',
            options.node.getIn(['configuration', 'gradient']),options.node.get('id'),
            options.node.getIn(['configuration', 'button-border-color'],'lightgrey')
        );

        drawCircle(placementElement, 0,0,options.node.getIn(['configuration', 'radius'], 10), 
            options.node.getIn(['configuration', 'borderwidth'], 1),'black',
            options.node.getIn(['configuration', 'gradient']),options.node.get('id') + 1000,
            options.node.getIn(['configuration', 'button-color'],'lightgrey')
        ); 

        let tooltip = displayTooltip(placementElement, options);
        let oldvalue = -1;
    
        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                let value = point.m_value;
                if (value != oldvalue) {
                    oldvalue = value;
                    options.node.getIn(['configuration', 'rules'], []).map((rule) => {
                        if (rule.get('matchvalue') == value) {
                            placementElement.select(`#circle${options.node.get('id')}`).style('fill', rule.get('button-border-color', 'darkgrey'));
                            placementElement.select(`#circle${options.node.get('id') + 1000}`).style('fill', rule.get('button-color', 'lightgrey'));
                        }
                    })
                }    
            },
            remove: () => {
                d3.select(placementElement).remove();
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                options.node = node;
                placementElement.remove();
                placementElement = createPlacementElement(options);

                    
                    drawCircle(placementElement, 0,0, options.node.getIn(['configuration', 'radius'], 50), 
                        options.node.getIn(['configuration', 'borderwidth'], 1),options.node.getIn(['configuration', 'border-color'], '#000000'),
                        options.node.getIn(['configuration', 'gradient']),options.node.get('id'),
                        options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey')
                    );            

                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }


        }) 
        
    })
}

export default StatusButton;

