import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import Checkbox from 'Controls/Form/Checkbox';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Swal from 'sweetalert2';
import ServiceBus from 'Services/ServiceBus';

import {getSerialPorts} from 'BmsView/Customer/Configuration/Actions';

function DeviceDialog({ selectedItem, onClose, ...props }) {
    return <Dialog opened={!!selectedItem} onClose={onClose}  >
        <DeviceDialogPanel selectedItem={selectedItem} onClose={onClose} {...props}/>
    </Dialog>
}

const DeviceDialogPanel = ({selectedItem, customer, selectedSubsystem, modbustype, saveItem, onClose}) => {
    let [device, setDevice] = useState(selectedItem);
    let [templates, setTemplates] = useState(['Generic Modbus']);
    let [serialports, setSerialPorts] = useState(Immutable.List([]));

    const getListOfSerialPorts = async () => {
        const response = await getSerialPorts(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`);
        
        setSerialPorts(Immutable.fromJS(response.filter((serialport) => {
            return serialport.subsystem == device.get('subsystem')} ))); 
    }; 


    useEffect(() => {
        let unsubscribe = ServiceBus.on('308-11', getListOfFilesHandler);
        if (modbustype) getListOfSerialPorts();
        getTemplates();

        return () => {
            // ServiceBus.off('308-11', getListOfFilesHandler);
            unsubscribe();
        };
    }, []);  

    

    const fieldChanged  = (field, value, transform) => {
        if (transform) {
            value = transform(value);
        }

        setDevice(device.set(field, value));
    } 
   
    const getTemplates = () => {
        let request = {
            trackMessage: true,
            m_level: 10,
            m_communicNum: 308,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: selectedSubsystem,
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_filesType: 0
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const getListOfFilesHandler = (msg) => {
        setTemplates(msg.m_files)
    }

    const convertIPAddressStringToArray = (ipAddress) => {
        let addr = ipAddress.split(".");

        let ipAddr = addr.map((byte) => {
            return parseInt(byte);
        });

        return ipAddr;
    }

    const onSave = () => {

        if ( modbustype && serialports.size == 0) {
            Swal.fire('Error', "No Serial Ports defined", "error");
            return};

        if ( modbustype && !device.get('_id') && !device.get('serialportnumber')) setDevice(device = device.set('serialportnumber', serialports.first().get('serialportnumber')));
        saveItem(device);
    }

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Device Definition</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="container-fluid">                
                    <div className="col-16 col-md-16">
                        <Input label="Identifier" value={device.get('identifier')} onChange={ (value) => {fieldChanged('identifier', value)}} />
                    </div> 
                    {modbustype ? <div className="col-16 col-md-8">
                        <Select label="Serial Port" 
                            value={device.get('serialportnumber')}
                            onChange={ (value) => fieldChanged('serialportnumber', value, parseInt)}
                            options={ serialports.map((serialport) => {
                                return {
                                    text: `Comm ${serialport.get('serialportnumber')}`,
                                    value: serialport.get('serialportnumber') 
                                }
                            })}    
                        /></div>
                        : <React.Fragment>    
                        <div className="col-16 col-m-8">
                            <Input label="Controller IP Address" value={device.get('ipaddress')} onChange={ (value) => {fieldChanged('ipaddress', value)}} minLength="7" maxLength="15" size="15" pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" />
                        </div>
                        <div className="col-16 col-md-8">
                            <Input label="Port Number" value={device.get('portnumber')} onChange={ (value) => {fieldChanged('portnumber', value)}} minLength="5" maxLength="5" size="5" />
                        </div>
                        </React.Fragment>          
                    }
                    <div className="col-16 col-md-8">
                        <Input label="Slave Address" type="number" value={device.get('slaveaddress')} onChange={ (value) => fieldChanged('slaveaddress', value, parseInt)} />
                    </div>
                    <div className="col-16 col-md-12">
                        <Select label="Type" 
                            value={device.get('devicetype')}
                            onChange={ (value) => fieldChanged('devicetype', value)}
                            options={ templates.map((template) => {
                                return {
                                    text: template,
                                    value: template 
                                }
                            })}    
                        />
                    </div>
                    {device.get('type') == 0 && 
                        <div className="col-16 col-md-8">
                            <Input label="Template Name" value={device.get('name')} onChange={ (value) => fieldChanged('name', value)} />
                        </div>}
                    <div className="col-16 col-md-4">
                        <Checkbox label="Disabled" checked={(device.get('disabled', 0) == 1) ? true : false} onChange={ (value) => fieldChanged('disabled', value ? 1:0)} />
                    </div>
                </div>
            </DialogBody>  
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={() => onClose()} >Close</Button></div>
                <div className="pl-1"><Button role="primary" onClick={()=>onSave()} >Save changes</Button></div>
            </DialogFooter>
        </DialogContent>    
    )
};

export default DeviceDialog;