import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Immutable from 'immutable';
import NotesDialog from './NotesDialog';
import Configuration from 'Configuration';
import Histories from './Histories';
import Store from 'Store';
import Button from 'Controls/Button/UserDefined';
import { toggleLeftSlide } from 'BmsView/Layout/TopMenu/Actions';
import { useBMS } from 'BmsView/Context/BMS';


class Sidebar extends React.Component {
    componentDidMount() {
        let { nodeconfiguration } = this.props;

        if (!nodeconfiguration) {
            return;
        }

        this.getAreaConfiguration();
    }

    async getAreaConfiguration() {
        let { customer, nodeconfiguration, match } = this.props;

        let customername = customer.get('name').toLowerCase().replace(/ /g, "");
        let ipaddress = customer.get('ipaddress').toLowerCase();

        const query = {pathid: { $eq: match.params[0] }};

        let areaConfigurationResponse = await axios.post(`/api/areas/searchone?database=${Configuration.product}${customername}&connection=${ipaddress}`, { selector: query });

        if (!areaConfigurationResponse.data) {
            return this.setState({
                areaconfiguration: Immutable.fromJS({
                    notes: []
                })
            });
        }

        let areaConfiguration = areaConfigurationResponse.data;
        this.setState({
            areaconfiguration: Immutable.fromJS(areaConfiguration)
        });
    }


    async saveAreaConfiguration(areaconfiguration) {
        let { customer, match } = this.props;

        if (!areaconfiguration.pathid) areaconfiguration = areaconfiguration.set('pathid', match.params[0]);

        if (areaconfiguration.get('_id')) {         
            await axios.put(`/api/areas/${areaconfiguration.get('_id')}?database=${Configuration.product}${customer.get('name').toLowerCase().replace(/ /g, "")}&connection=${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, areaconfiguration.toJS());
        } else {
            await axios.post(`/api/areas?database=${Configuration.product}${customer.get('name').toLowerCase().replace(/ /g, "")}&connection=${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, areaconfiguration.toJS())
                .then((response) => {
                    // Omar 25-12-2022 I've commented this out because resolve was not defined, are there implications to this?
                    // resolve(response.data)
                })
                .catch((err) => console.log(err));
        }

        this.setState({
            areaconfiguration: areaconfiguration
        });

        this.hideDialogs();
    }

    showDialog(name) {
        this.setState({
            dialog: name
        })
    }

    hideDialogs() {
        this.setState({
            dialog: undefined
        })
    }

    async componentDidUpdate(oldProps) {
        let { nodeconfiguration, customer } = this.props;

        if (nodeconfiguration && nodeconfiguration.get('backdrop') &&  nodeconfiguration.get('backdrop').get('name') == "Background Popup") return;

        if (nodeconfiguration != oldProps.nodeconfiguration ) {
            this.getAreaConfiguration();

            this.setState({
                nc: nodeconfiguration
            })
        }
    }

    render() {
        let { match,  currentuser, customer, site, showMobileGraphic, nodeconfiguration } = this.props;
        let { dialog, areaconfiguration, nc } = this.state || {};

        if (!nodeconfiguration) {
            return <data></data>;
        }

        return (
            <React.Fragment>
                <NotesDialog selecteditem={dialog == 'notes' && areaconfiguration} onClose={this.hideDialogs.bind(this)} currentuser={currentuser} onSave={this.saveAreaConfiguration.bind(this)} />
                <div className="p-2">
                    <a className=" px-3 py-2 bg-gray-200 hover:bg-gray-400 text-gray-700 w-full" onClick={this.showDialog.bind(this, 'notes')}>Notes ({areaconfiguration && areaconfiguration.getIn(['notes'], Immutable.List([])).size || 0})</a>
                </div>
                {areaconfiguration && <Histories customer={customer} site={site} nodeconfiguration={nc} areaconfiguration={areaconfiguration} onSave={this.saveAreaConfiguration.bind(this)} />}
                <div className="d-md-none lg:hidden row">
                    <div className="col-16 px-2">
                        <Button role="primary" className="w-100" onClick={() => {Store.dispatch({ type: 'TOGGLE_GRAPHIC'}); toggleLeftSlide() }}>{showMobileGraphic ? "Display pointlist" : "Display points in graphic"}</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect((state) => ({
    // customer: state.get('customer'),
    // site: state.get('site'),
    // nodeconfiguration: state.get('nodeconfiguration'),
    // currentuser: state.get('currentUser'),
    // nodeconfigurationindex: state.get('nodeconfigurationindex'),
    showMobileGraphic: state.get('showMobileGraphic')
}))((props) => {

    const { customer } = useBMS();
    const { site } = useBMS();
    const { currentUser } = useBMS();

    return <Sidebar {...props} currentuser={currentUser} site={site} customer={customer} />
});