import React from 'react';
import Immutable, { List } from 'immutable';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import { getDocumentConfiguration } from './Actions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';


function DocumentDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <DocumentDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class DocumentDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        let { customer, selecteditem } = this.props;
        
        if ( !selecteditem.get('site') && customer.get('sites') ) {
            selecteditem = selecteditem.set('site',customer.get('sites').get(0).get('name')).set('type','Floorplan');
        }

        this.state = {
            currentdocument: selecteditem,
            documentconfiguration: Immutable.Map({})
        }

        this.onSave = this.onSave.bind(this);
    }


    async componentDidMount() {
        let documents = await getDocumentConfiguration();

        this.setState({
            documentconfiguration: Immutable.fromJS(documents)
        });
    }

    onSave() {
        let { currentdocument } = this.state;
        let { onSave } = this.props;

        if (onSave) {
            onSave(currentdocument)
        }
    }

    valueChanged(field, value) {
        this.setState(({ currentdocument }) => ({
            currentdocument: currentdocument.setIn(field, value)
        }));
    }

    dateChanged(field, value) {
        this.setState(({ currentdocument }) => ({
            currentdocument: currentdocument.setIn(field, value)
        }));
    }

    render() {
        let { customer, onClose } = this.props;
        let { currentdocument } = this.state || {};
        let { documentconfiguration } = this.state;

        let yearrangeend = new Date().getFullYear();
        let yearrangestart = yearrangeend - 40;

        var yearrange = [],
            c = yearrangeend - yearrangestart + 1;
        while (c--) {
            yearrange[c] = yearrangestart++
        }

        yearrange.unshift('');

        return (
            <DialogContent className="md:w-[500px]">
                <DialogHeader>
                    <DialogTitle>Edit Document</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="flex flex-row mt-4">
                        <div className="w-g-4 px-2">
                            <Select
                                label="Site"
                                value={currentdocument.get('site')}
                                onChange={this.valueChanged.bind(this, ['site'])}
                                options={(customer.get('sites') || List()).map((site) => {
                                    return ({
                                        text: site.get('name'),
                                        value: site.get('name')
                                    });
                                })}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row mt-4">
                        <div className="w-g-4 px-2">
                            <Select
                                label="Type"
                                value={currentdocument.get('type')}
                                onChange={this.valueChanged.bind(this, ['type'])}
                                options={(documentconfiguration.get('type') || []).map((item) => {
                                    return ({
                                        text: item,
                                        value: item
                                    });
                                })}
                            />
                        </div>
                    </div>                  
                    <div className="flex flex-row mt-4">
                        <div className="w-g-4 px-2">
                            <label className="block label">Document Date</label>
                            <DatePicker className="my-4"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Click to select a date"
                                selected={currentdocument.get('') || null}
                                onChange={this.valueChanged.bind(this, ['documentdate'])}
                                isClearable
                            />
                        </div>
                    </div>
                    <div className="flex flex-row mt-4">
                        <div className="w-g-16 px-2">
                            <Input label="Notes" value={currentdocument.get('notes')} onChange={this.valueChanged.bind(this, ['notes'])} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="close" onClick={onClose}>Close</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.onSave}>Save</Button></div>
                </DialogFooter>

            </DialogContent>
        );
    }
}

export default DocumentDialog;