import axios from 'axios';
import ServiceBus from 'Services/ServiceBus';
import Configuration from 'Configuration'

export function loadCommonFile(filename){
    return new Promise((resolve, reject) => {
        axios.get(`/files/common/${filename}.json`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getFileCommunic(customer, subsystem, pathname, filename){
    let request = {
        trackMessage: true,
        m_level: 10,
        m_communicNum: 309,
        target: 'signalr',
        type: "GENERIC",
        m_subsystemNumber: subsystem,
        databaseName: customer
    }
    let comm = {
        m_pathname: pathname,
        m_fileName: filename
    }

    ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
}

export function setFileCommunic(customer, subsystem, pathname, filename, data){
    let request = {
        trackMessage: true,
        m_level: 10,
        m_communicNum: 310,
        target: 'signalr',
        type: "GENERIC",
        m_subsystemNumber: subsystem,
        databaseName: customer
    }
    let comm = {
        m_pathname: pathname,
        m_fileName: filename,
        m_jsonFileData: data
    }

    ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
}

export function saveEvent(customer, connection, event) {
    return new Promise((resolve,reject) => {                                                                    
        axios.post(`/api/eventlog?database=${Configuration.product}${customer}&connection=${connection}`, event.toJS()).then(resolve).catch(reject);
    });
}