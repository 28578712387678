import React from 'react';
import Select from 'react-select';
import Immutable from 'immutable';

const customStyles = {
    menu: (provided, state) => {
        return { ...provided, zIndex: 999 }
    }
}
class SelectSearch extends React.Component {
    onChange(option) {
        let { onChange, returnObject } = this.props;

        if (returnObject){
            return onChange(option);
        }
        onChange(option.value);
    }

    render() {
        let { label, options, textField, valueField, value, className } = this.props;
        let rawOptions = Immutable.Iterable.isIterable(options) ? options.toJS() : options;
        let selectedoption = null;

        if (value && value.length > 0) {
            selectedoption = rawOptions.filter((option) => { return option.value == value }).map((option) => {
                return {
                    label: option[textField || 'text'],
                    value: option[valueField || 'value']
                }
            })[0];
        }

        return (
            <div className="w-full my-2">
                {label &&
                    <label className="block label" htmlFor={label}>
                        {label}
                    </label>}
                <Select
                    styles={customStyles}
                    placeholder={label}
                    options={rawOptions.map((option) => {
                        return {
                            label: option[textField || 'text'],
                            value: option[valueField || 'value']
                        }
                    })}
                    onChange={this.onChange.bind(this)}
                    value={selectedoption}
                />
            </div>
        )
    }
}

export default SelectSearch;