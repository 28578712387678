export function stringTimeToIndex(time) {
    let [hours, minutes] = time.split(':');
    return Number(hours) * 60 + Number(minutes);
}

export function removeCollidingElements(event, periods) {
    return periods.map((x) => {
        if(event == x) {
            return x;
        }

        let currentStart = event.startindex;
        let currentEnd = event.endindex ;
        let comparingStart = x.startindex;
        let comparingEnd = x.endindex;

        let startIsInOtherEventRange = comparingStart >= currentStart && comparingStart < currentEnd;
        let endIsInOtherEventRange = comparingEnd < currentEnd && comparingEnd > currentStart;
        if(startIsInOtherEventRange || endIsInOtherEventRange) {
            return {...x, isDeleted: true}
        }
        return x; 
    })
}

export function getChangedRangeIndex(startIndex, endIndex, padding, offset, direction) {

    if(direction == 1) { // moved right
        let left = startIndex + ( Math.floor(startIndex) / 60 * padding);
        let width = calculateInitialWidth(startIndex, endIndex, padding);
        let newWidth = width + offset;
        let newDuration = newWidth - (Math.floor(newWidth / 60) * padding);
        let newEndIndex = startIndex + newDuration;
        return [startIndex, newEndIndex];
    } 

    if(direction == -1) { // moved left
        let left = startIndex + ( Math.floor(startIndex / 60) * padding);
        let width = calculateInitialWidth(startIndex, endIndex, padding);
        let newLeft = left + offset;
        let newWidth = width + (-1 * offset);
        let newStartIndex = newLeft - (Math.floor(newLeft / 60) * padding);
        let newDuration = newWidth - (Math.floor(newWidth / 60) * padding);
        let newEndIndex = newStartIndex + newDuration;
        return [newStartIndex, newEndIndex];
    }
    return [startIndex, endIndex];

}


export function calculateInitialLeft(startIndex) {
    return startIndex % 60;
}

export function calculateLeftBoundry(startIndex, padding) {
    return (Math.floor(startIndex / 60) * padding) + startIndex;
}

export function calculateRightBoundary(startIndex, endIndex, padding) {
    let maxRight = (23 * padding) + (24 * 60); 
    let diff = endIndex - startIndex;
    let widthCorrection = 0;
    if(diff > 60) {
        widthCorrection = Math.floor(diff / 60) * padding;
    }
    return maxRight - (diff + widthCorrection);
}

export function calculateInitialWidth(startIndex, endIndex, padding) {
    let diff = endIndex - startIndex;
    let widthCorrection = 0;
    if(diff > 60) {
        widthCorrection = Math.floor(diff / 60) * padding;
    }
    return diff + widthCorrection;
}

export function timeIndexToString(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}