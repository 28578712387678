import React from 'react';
import DD from './Dropdown';
import MI from './MenuItem';

class Menu extends React.Component {

    render() {
        let { children } = this.props.children;
        
        return (
            <React.Fragment>
                <div className="flex">
                    {this.props.children}
                </div>
            </React.Fragment>
        )
    }
}


export const Dropdown = DD;
export const MenuItem = MI;

export default Menu;