import { useState, useEffect } from 'react';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import Input from 'Controls/Form/Input';
import { uploadComponent, saveCustomerComponents } from '../../Actions';
import { useSelector } from 'react-redux';
import Immutable, { List, Map } from 'immutable';
import axios from 'axios';
import { useBMS } from 'BmsView/Context/BMS';

export default function CreateCompoundDialog({ compoundToCreate, onClose, ...props }) {

    return <Dialog opened={!!compoundToCreate} onClose={onClose}>
        <CreateCompoundDialogPanel {...props} compoundToCreate={compoundToCreate} onClose={onClose} />
    </Dialog>
}

export function CreateCompoundDialogPanel({ onClose, compoundToCreate }) {

    // const site = useSelector(state => state.get('site'));
    // const customer = useSelector(state => state.get('customer'));

    const { site } = useBMS();
    const { customer } = useBMS();

    const siteUrlPart = site && (site.get('name') || '').split(' ').join('-').toLowerCase() || '';
    const customerUrlPart = customer && (customer.get('name') || '').split(' ').join('-').toLowerCase() || '';

    const [components, setComponents] = useState(List());

    const [name, setName] = useState("");

    useEffect(() => {
        (async (customer, site) => {
            let sitebasePath = `/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}`
            let response = await axios.get(`${sitebasePath}_components_components.json`);
            let components = response.data;
            setComponents(Immutable.fromJS(components));
        })(customer, site);

    }, [customer, site])

    const onSave = async () => {
        let nameForFile = name.toLowerCase().split(' ').join('-');
        let nodes = compoundToCreate.nodes.toList().toJS();
        let compound = { name: name, nodes: nodes };
        let compoundString = JSON.stringify(compound);
        let compoundBlob = new Blob([compoundString], { type: "application/json" });
        let compoundFile = blobToFile(compoundBlob, nameForFile + ".json");
        await uploadComponent(customerUrlPart, siteUrlPart, `compound_${nameForFile}.json`, compoundFile);
        let changedComponents = components.push(Map({ 
            name: `~compound_${nameForFile}`,
            description: name,
            type: 'compound' 
        }));
        await saveCustomerComponents(customerUrlPart, siteUrlPart, changedComponents);
        let file = blobToFile(dataURLtoBlob(compoundToCreate.thumbnailImageUrl), nameForFile + ".png");
        await uploadComponent(customerUrlPart, siteUrlPart, `compound_${nameForFile}.png`, file);
       
        onClose();
    }

    // console.log("compoundToCreate", compoundToCreate);
    // console.log("components", components);

    return <DialogContent>
        <DialogHeader>
            <DialogTitle>Create compound</DialogTitle>
        </DialogHeader>
        <DialogBody>

            <div className="grid grid-cols-16">
                <div className="col-span-12">
                    <Input type="text" label="Compound name" value={name} onChange={(value) => setName(value)} />
                </div>
            </div>

            <img src={compoundToCreate?.thumbnailImageUrl} />
        </DialogBody>
        <DialogFooter>
            <Button role="save" onClick={onSave}>Save</Button>
            <Button role="close" onClick={onClose}>Close</Button>
        </DialogFooter>
    </DialogContent>

}


// Function to convert Data URL to Blob
function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }
    return new Blob([u8arr], {type: mime});
}

// Function to convert Blob to File
function blobToFile(blob, fileName) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
}