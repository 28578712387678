import * as d3 from "d3";
import ComponentCache from "../ComponentCache";
import ImageCache from "../ImageCache";
import { displayImage, setStateids, displayTooltip } from "../Utils";
import axios from "axios";


export default ImageFactory('svg');






export function ImageFactory(extension = 'svg') {
    if(extension === 'png') {
        return async function PNGImageAsSVG(options, path) {
            
            let imagePath = path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.${extension}`;
            let { imgURL, imageData } = await ImageCache.getImage(imagePath);
                        
            const svg = d3.create("svg")
            .append("svg").attr("width", imageData.width).attr("height", imageData.height);

            let width = options.node.getIn(['configuration', 'length'], imageData.width);
            let height = options.node.getIn(['configuration', 'height'], imageData.height);
            // Append the image to the SVG
            const svgImage = svg.append("image");
            svgImage.attr("href", imgURL).attr("width", width).attr("height", height).attr("x", 0).attr("y", 0).attr("preserveAspectRatio", "none");

            let image = svg;

            let placementElement = displayImage(options, image);
            let tooltip = displayTooltip(placementElement, options);

            if ( options.node.getIn(['configuration', 'islink'])) placementElement.style("cursor", "hand");

            if (options.node.getIn(['component', 'stateids'])) {
                if(options.editmode) {
                    let states = options.node.getIn(['component', 'stateids']);

                    states.forEach((state) => {
                        placementElement.select('#' + state.get('target')).style('display', 'block');
                    });   
                } 
            }   

            let oldvalue = -1;
    
            return {
                getImageData: () => {
                    return imageData;
                },
                setValue: (point) => {
                    options.node = options.node.setIn(['pointconfiguration'], point);

                    oldvalue = setStateids(placementElement, options, point.m_value, oldvalue);
                },
                getElement: () => {
                    return placementElement;
                },
                setContextMenu: (editmode) => {
                    options.editmode = editmode;

                    if (options.node.getIn(['component', 'stateids'])) {
                        if(editmode) {
                            let states = options.node.getIn(['component', 'stateids']);

                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        } else {
                            setStateids(placementElement, options, oldvalue, -1);
                        }
                    }    
                },
                setOptions: (newOptions) => {
                    options = {...options, ...newOptions}
                },
                redrawElement: (node, isSelected) => {
                    placementElement.remove();
                    
                    options.node = node;
                    let width = node.getIn(['configuration', 'length'], imageData.width);
                    let height = node.getIn(['configuration', 'height'], imageData.height);
                    svgImage.attr("width", width).attr("height", height).attr("x", 0).attr("y", 0).attr("preserveAspectRatio", "none");
                    placementElement = displayImage(options, image);
                    
                

                    tooltip = displayTooltip(placementElement, options);

                    if(options.editmode) {
                        if (options.node.getIn(['component', 'stateids'])) {
                            let states = options.node.getIn(['component', 'stateids']);

                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        }
                    } 
                    oldvalue = -1;

                    if(isSelected) {
                        placementElement.attr('class', 'node-selected');
                    }
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            };


                
        }
    }

    if(extension === 'svg') {
        return function Image(options, path) {
            return new Promise((resolve, reject) => {
                ComponentCache.getXml(path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.${extension}`).then((xml) => {
                //    let image = d3.select(xml.documentElement).select(`#${options.node.getIn(['component', 'componentid'])}`);
                let image = d3.select(xml.documentElement).select('#layer1');
        
                    let placementElement = displayImage(options, image);
                    let tooltip = displayTooltip(placementElement, options);
        
                    if ( options.node.getIn(['configuration', 'islink'])) placementElement.style("cursor", "hand");
        
                    if (options.node.getIn(['component', 'stateids'])) {
                        if(options.editmode) {
                            let states = options.node.getIn(['component', 'stateids']);
        
                            states.forEach((state) => {
                                placementElement.select('#' + state.get('target')).style('display', 'block');
                            });   
                        } 
                    }   
        
                    let oldvalue = -1;
        
                    resolve({
                        setValue: (point) => {
                            options.node = options.node.setIn(['pointconfiguration'], point);
        
                            oldvalue = setStateids(placementElement, options, point.m_value, oldvalue);
                        },
                        getElement: () => {
                            return placementElement;
                        },
                        setContextMenu: (editmode) => {
                            options.editmode = editmode;
        
                            if (options.node.getIn(['component', 'stateids'])) {
                                if(editmode) {
                                    let states = options.node.getIn(['component', 'stateids']);
        
                                    states.forEach((state) => {
                                        placementElement.select('#' + state.get('target')).style('display', 'block');
                                    });   
                                } else {
                                    setStateids(placementElement, options, oldvalue, -1);
                                }
                            }    
                        },
                        setOptions: (newOptions) => {
                            options = {...options, ...newOptions}
                        },
                        redrawElement: (node, isSelected) => {
                            placementElement.remove();
                            
                            options.node = node;
                            placementElement = displayImage(options, image);
                            
                        
        
                            tooltip = displayTooltip(placementElement, options);
        
                            if(options.editmode) {
                                if (options.node.getIn(['component', 'stateids'])) {
                                    let states = options.node.getIn(['component', 'stateids']);
        
                                    states.forEach((state) => {
                                        placementElement.select('#' + state.get('target')).style('display', 'block');
                                    });   
                                }
                            } 
                            oldvalue = -1;
        
                            if(isSelected) {
                                placementElement.attr('class', 'node-selected');
                            }
                        },
                        remove: () => {
                            placementElement.remove();
                            image.remove();
                        },
                        resetIndex: (index) => {
                            options.index = index;
                            placementElement.attr('data-index', options.index);
                        }
                    });
                });
            });
        }
    }
}

