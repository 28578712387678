import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import { List } from 'immutable';


export default function PointInGroupDialog({ selecteditem, onClose, ...props }) {

    // console.log("showing dialog??", selecteditem);

    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <PointInGroupDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>

}

function PointInGroupDialogPanel({ selecteditem, onClose }) {

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Group: { selecteditem.get('name') }</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="p-2">
                    <div>
                        {selecteditem.get('points', List()).map((point) => {
                            return <div>
                                {point.get('name')}
                            </div>
                        })}
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button onClick={onClose}>Close</Button>
            </DialogFooter>
        </DialogContent>
    )

}