import React from 'react';
import Immutable from 'immutable';

class Header extends React.Component{
    componentDidMount(){
        let {children, setHeaderFields} = this.props;

        let childrenToExtract = React.Children.map(children, (child) => {return child; });

        let fields = childrenToExtract.map((child) => {
            return { 
                field: child.props.name || child.props.children.toLowerCase(), 
                value: ['button', 'complex'].indexOf(child.props.type) >= 0 ? '' : child.props.children, 
                hideMobile: !!child.props.hideMobile, 
                classes: child.props.classes 
            }
        });

        setHeaderFields(fields);
    }
    render(){
        let {children, className} = this.props;

        return (
            <div className={`col-span-16 py-1 grid-cols-16 bg-gray-500 text-white font-bold hidden md:grid ${className || ''}`}>
                {children}
            </div>
        )
    }
}

export default Header;