import React, { useState, useRef } from 'react';
import DraggableVertical from '../Draggable';
import ResizableVertical from '../Resizable';
import Swal from 'sweetalert2';
import EventLabel from '../EventLabel';
import { DayMenuButton } from '../WeekTimes';

export default function DayTimesVertical({ onDayMenuSelect, onCreate, events, onChange, selectedEvent, onSelect, onModifyRequested }) {
    

    const [selectedStart, setSelectedStart] = useState(null);
    const [hoveredSelection, setHoveredSelection] = useState(null);

    const setSelection = (mode, index, selectionLength ) => {
        if(selectedStart !== null) {
            if(mode == "hover") {
                setHoveredSelection(index);
            }
            if(mode == "click") {
                setSelectedStart(null);
                setHoveredSelection(null);
                if((index + selectionLength) <= selectedStart) {
                    return;
                }
                onCreate({ startindex: selectedStart, endindex: index + selectionLength });

            }
        } else {
            if(mode == "click") {
                if(events.length > 4) {
                    return Swal.fire('Maximum events', 'There is a limit of 5 maximum events you can add on a certain day', 'error')
                } 
                setSelectedStart(index);
            }
        }
    }
    
    
    const createNewEvent = (startIndex, endIndex) => {
        onCreate({ startindex: startIndex, endindex: endIndex });
    }

    const onTimeChange = (event, startIndex, endIndex) => {
        onChange(event, { 
            startindex: startIndex,
            endindex: endIndex
        });
    }

    let sortedEvents = events.sort((a,b) => {
        return a.startindex - b.startindex;
    })

    let eventsObject = sortedEvents.reduce((col, v) => ({...col, [v.startindex]: v }), {});

    let singleCellClassName = "col-span-10 relative";
    let cells = [];
    
    const createCell = (span, i, selectionIndex, childEvent = null, isSelected) => {
        if(!span) {
            return null;
        }
        let selectionLength = span;
        let cellClassName = `${singleCellClassName}`;
        if(selectedStart !== null) {
            if(!childEvent && selectionIndex == selectedStart) {
                cellClassName += ` bg-[red]`; 
            }
            if(!childEvent && selectionIndex > selectedStart && i <= hoveredSelection) {
                cellClassName += ` bg-[blue]`
            }
        }
        if(childEvent) {
            let eventBg = isSelected ? 'bg-[#00cc88]' : 'bg-[#0088cc]';

            return <div 
                style={{ gridRow: `span ${span} / span ${span}`}} 
                className={`${cellClassName} relative ${eventBg}`} 
                key={`keytimes-${i}-${span}`}>{childEvent}</div>
        }
        return <div 
            onMouseOver={() => setSelection("hover", selectionIndex, selectionLength)} 
            onClick={() => setSelection("click", selectionIndex, selectionLength)}
            style={{ gridRow: `span ${span} / span ${span}`}} 
            className={cellClassName} 
            key={`keytimes-${i}-${span}`}>{childEvent}</div>
    };

    let currentCellSpan = 0;
    let minuteCounter = 0;
    let couldntBe = 0;
    while(minuteCounter < 48 * 30 && couldntBe < 2 * 48 * 30) {

        if(eventsObject[minuteCounter]) {
            let event = eventsObject[minuteCounter];
            
            let isSelected = event == selectedEvent;

            let eventLabel =  <EventLabel 
                label={`${timeIndexToString(event.startindex)} - ${timeIndexToString(event.endindex)}`} 
                onModifyRequested={() => {onModifyRequested(event)}}
                onRemove={() => {onChange(event, { isDeleted: true })}} 
                onChange={(field, value) => { onChange(event, { endindex:  30 * 48 })}} 
                onChangeEvent={(e) => {} } />

            let childEvent = <Event onTimeChange={onTimeChange} event={event} isSelected={isSelected} onSelect={onSelect}>
                {eventLabel}
            </Event>


            if(minuteCounter % 30 == 0) {
                cells.push(createCell(event.endindex - event.startindex, minuteCounter, (minuteCounter - currentCellSpan), childEvent, isSelected));
            } else {
                cells.push(createCell(currentCellSpan, minuteCounter + "-", (minuteCounter - currentCellSpan)));
                currentCellSpan = 0;
                cells.push(createCell(event.endindex - event.startindex, minuteCounter, (minuteCounter - currentCellSpan), childEvent, isSelected))
            }
            minuteCounter = minuteCounter + event.endindex - event.startindex;
        } else {
            if((minuteCounter + 1) % 30 == 0) {
                cells.push(createCell(currentCellSpan + 1, minuteCounter, (minuteCounter - currentCellSpan)));
                currentCellSpan = 0;
                minuteCounter++;
            } else {
                currentCellSpan++;
                minuteCounter++;
            }
        }
        couldntBe++;
    }
    // console.log("couldn't be", couldntBe);

    return <div className="grid w-full h-full">
       <div className="row-start-1 col-start-1 z-10 grid grid-cols-12  grid-rows-1500 grid-flow-col divide-x divide-y border-1 auto-rows-fr ring-1 ring-gray-300 ring-inset ">
            {getTimeLabels('col-span-2')}
            <div className="col-span-10 min-h-[10px] row-span-60 flex items-center">
                <div className="flex-1 px-2"></div>
                { onDayMenuSelect && <div className="flex-none px-2">
                    <DayMenuButton menuColor="gray" onDayAction={(onDayMenuSelect)} actions={['onAllDay','offAllDay','selectTemplate']} />
                    </div> } 
            </div>
            {cells}
        </div>
    </div>

}

function Event({ event, onTimeChange, children  }) {

    let elementIndex = event.startindex;
    let span = event.endindex  - elementIndex;

    const onChange = (startIndex, endIndex) => {
        if(!endIndex) {
            endIndex = startIndex + span;
        }
        onTimeChange(event, startIndex, endIndex)
    }

    return <DraggableVertical className="h-full w-full" elementIndex={elementIndex} span={span} onChange={onChange}>
        <ResizableVertical className="h-full w-full" elementIndex={elementIndex} span={span} onChange={onChange}>
            {children}
        </ResizableVertical>
    </DraggableVertical>
       

}

function getTimeLabels(className) {
    let divs = [<div className={className + ' row-span-60 px-2'}></div>];
    for(let i = 0; i < 24; i++) {
        let hourString = `0${(i)}:00`.replace(/^[0-9]([0-9]{2})/, '$1');
        divs.push(<div className={className + ' row-span-60 px-2 text-center'} key={`timelabel-${i}`}>{hourString}</div>)
    }
    return divs;
}

function timeIndexToString(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}