import React from 'react';
import "./Reducers";
import { connect } from 'react-redux';
import { toggleLeftSlide } from 'BmsView/Layout/TopMenu/Actions';
import {MdClose} from 'react-icons/md';
import { Transition } from '@headlessui/react';
import { useBMS } from 'BmsView/Context/BMS';

class LeftSlide extends React.Component {
    constructor(props){
        super(props);
        window.slide = () => toggleLeftSlide();
    }

    render() {
        let { currentuser, children, leftslide_toggled } = this.props;

        let slideClassName = "fixed  top-0 left-0 right-0 bottom-0 bg-[white] md:bg-[rgba(0,0,0,0.2)] z-40";
        let slideInnerClassName = "relative  bg-[white] w-full h-full overflow-x-hidden overflow-y-auto md:w-1/5";
        
        return (
            <Transition 
                show={!!leftslide_toggled} 
                className={slideClassName} 
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                onClick={() => toggleLeftSlide()}>
                    <Transition.Child 
                        enter="transition-transform duration-300"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition-transform duration-300"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                        className={slideInnerClassName}
                        >
                        <div onClick={evt => evt.stopPropagation()}>
                            <div className="bg-blue-darkest flex flex-row  text-white items-end justify-items-end p-2 cursor-hand text-right">
                               <div className="flex-1"></div>
                               <div className="flex-init">
                                    <MdClose size="2rem"  onClick={() => toggleLeftSlide()} />
                                </div>
                            </div>
                            <div className="w-full bg-gray-500 text-white px-3 py-2">
                                Welcome, {currentuser && currentuser.get('firstname')}
                            </div>
                            {children}
                        </div>
                    </Transition.Child>
            </Transition>
        )
    }
}

export default connect((state) => ({
    topmenu: state.get('topmenu'),
    treemenu: state.get('treemenu'),
    leftslide_toggled: state.get('leftslide_toggled'),
    // currentuser: state.get('currentUser')
}))((props) => {

    const { currentUser } = useBMS();

    return <LeftSlide {...props} currentuser={currentUser} />
});