import React from 'react';
import ColorPicker from 'Controls/ColorPicker';
import Select from 'Controls/Form/SelectSearch';
import Input from 'Controls/Form/Input';
import Immutable from 'immutable';
import Button from 'Controls/Button/UserDefined';

const displayTexts = {
    "$range": "In range of",
    "$eq": "Equal to",
    "$neq": "Not equal to",
    "$in": "In (comma seperated)",
    "$nin": "Not in (comma sepearted)"
}

class AnimationConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentvalue: Immutable.Map({})
        };
    }

    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    currentValueChanged(field, value) {
        this.setState(({ currentvalue }) => ({
            currentvalue: currentvalue.setIn(field, value)
        }));
    }

    ruleChanged(field, value) {
        let { onChange } = this.props;

        onChange(field, value);
    }

    addRule() {
        let { currentvalue } = this.state;
        let { selectedcomponent, onChange } = this.props;

        let rules = selectedcomponent.getIn(['configuration', 'rules'], Immutable.List([]));
        rules = rules.push(currentvalue);

        onChange(['configuration', 'rules'], rules);
        this.setState({
            currentvalue: Immutable.Map({})
        })
    }

    removeRule(index){
        let {onChange, selectedcomponent} = this.props;

        let rules = selectedcomponent.getIn(['configuration', 'rules']);
        rules = rules.delete(index);

        onChange(['configuration', 'rules'], rules);
    }

    displayColorPicker(selectedcomponent) {
        return (
            <div className="col-span-16 md:col-span-2"><ColorPicker label={selectedcomponent.getIn(['component', 'styles']).get(0).get('description')} value={selectedcomponent.getIn(['configuration', 'value'])} onChange={this.valueChanged.bind(this, ['configuration', 'value'])} /></div>
        )
    }

    render() {
        let { currentvalue } = this.state;
        let { selectedcomponent } = this.props;

        return (
            <div>
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Scale %"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'scale'], '') || ''}
                            onChange={this.valueChanged.bind(this, ['configuration', 'scale'])}
                        /> 
                    </div>
                </div>
                {selectedcomponent.getIn(['component', 'styles']) && ((selectedcomponent.getIn(['component', 'styles']).get(0).get('type') == 'color') ||
                (selectedcomponent.getIn(['component', 'styles']).get(0).get('type') == 'stop-color')) &&
                    <div className="grid grid-cols-16">
                        {this.displayColorPicker(selectedcomponent)}
                    </div>
                }
                {selectedcomponent.getIn(['component', 'stateids']) &&
                    <div className="my-4">
                        <div className="grid grid-cols-16 gap-2">
                            <div className="col-span-16 md:col-span-3">
                                <label className="block label">Condition</label>
                            </div>
                            <div className="col-span-16 md:col-span-3">
                                <label className="block label">Value</label>
                            </div>
                            <div className="col-span-16 md:col-span-3">
                                <label className="block label">Action</label>
                            </div>
                            <div className="col-span-16 md:col-span-3">
                                <label className="block label">Target</label>
                            </div>
                            {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' && 
                                <div className="col-span-16 md:col-span-1">
                                    <label className="block label">Colour</label>
                                </div>
                            } 
                        </div>
                        {selectedcomponent.getIn(['configuration', 'rules'], []).map((rule, index) => {
                            return (
                                <div className="grid grid-cols-16 gap-2">
                                    <div className="col-span-16 md:col-span-3">
                                        <Select
                                            options={[
                                                { value: "$range", text: "In range" },
                                                { value: "$eq", text: "Equals" },
                                                { value: "$neq", text: "Not equals" },
                                                { value: "$in", text: 'In' },
                                                { value: '$nin', text: "Not in" }
                                            ]}
                                            onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'condition'])}
                                            value={rule.get('condition')}
                                        />
                                    </div>
                                    <div className="col-span-16 md:col-span-3 mt-2">
                                        {rule.get('condition') && <Input
                                            onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'matchvalue'])}
                                            value={rule.get('matchvalue', null)}
                                        />}
                                    </div>
                                    <div className="col-span-16 md:col-span-3">
                                        {rule.get('action') == 'fill' && 
                                            <Select
                                                options={[
                                                    { value: 'fill', text: 'Fill' }
                                                ]}
                                                onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'action'])}
                                                value={rule.get('action')}
                                            />}
                                        {rule.get('action') != 'fill' &&
                                            <Select
                                                options={[
                                                    { value: 'show', text: 'Show' },
                                                    { value: 'hide', text: 'Hide' }
                                                ]}
                                                onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'action'])}
                                                value={rule.get('action')}
                                            />}    
                                    </div>
                                        {rule.get('action') != 'fill' && !selectedcomponent.getIn(['component', 'sitespecific']) && //rule.get('action') != 'show' && 
                                            <div className="col-span-16 md:col-span-4"> 
                                                <Select
                                                    options={selectedcomponent.getIn(['component', 'stateids']).map((state) => {
                                                        return {
                                                            value: state.get('target'),
                                                            text: state.get('description')
                                                        }
                                                    })}
                                                    onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'target'])}
                                                    value={rule.get('target')}
                                                />
                                            </div>}
                                        {rule.get('action') == 'show' && selectedcomponent.getIn(['component', 'sitespecific']) &&
                                            <div className="col-span-16 md:col-span-4  mt-2">
                                                <Input onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'target'])} value={rule.get('target')}/>
                                            </div> } 
                                        {rule.get('action') == 'fill' &&
                                            <div className="col-span-16 md:col-span-3  mt-2">
                                                <Input onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'target'])} value={rule.get('target')}/>
                                            </div> } 
                                        {rule.get('action') == 'fill' && selectedcomponent.getIn(['component', 'sitespecific']) && 
                                            <div className="col-span-16 md:col-span-1 mt-2">
                                                <ColorPicker value={rule.get('fill')} onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'fill'])}/>
                                            </div> }  
                                        {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' && 
                                            !selectedcomponent.getIn(['component', 'sitespecific']) &&
                                            <div className="col-span-16 md:col-span-1">
                                                <ColorPicker value={rule.get('fill')} onChange={this.ruleChanged.bind(this, ['configuration', 'rules', index, 'fill'])}/>
                                        </div> }  
                                    <div className="col-span-12 md:col-span-2 py-2">
                                        <Button role="danger" className="w-28" onClick={this.removeRule.bind(this, index)}>Remove</Button>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="grid grid-cols-16 gap-2">
                            <div className="col-span-16 md:col-span-3">
                                <Select
                                    options={[
                                        { value: "$range", text: "In range" },
                                        { value: "$eq", text: "Equals" },
                                        { value: "$neq", text: "Not equals" },
                                        { value: "$in", text: 'In' },
                                        { value: '$nin', text: "Not in" }
                                    ]}
                                    onChange={this.currentValueChanged.bind(this, ['condition'])}
                                    value={currentvalue.get('condition')}
                                />
                            </div>
                            <div className="col-span-16 md:col-span-3 mt-2">
                                {currentvalue.get('condition') && <Input
                                    onChange={this.currentValueChanged.bind(this, ['matchvalue'])}
                                    value={currentvalue.get('matchvalue')}
                                />}
                            </div>
                            <div className="col-span-16 md:col-span-3">
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' && 
                                    <Select
                                        options={[
                                            { value: 'fill', text: 'Fill' }
                                        ]}
                                        onChange={this.currentValueChanged.bind(this, ['action'])}
                                        value={currentvalue.get('action')}
                                    />}
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') != 'fill' && 
                                    <Select
                                        options={[
                                            { value: 'show', text: 'Show' },
                                            { value: 'hide', text: 'Hide' }
                                        ]}
                                        onChange={this.currentValueChanged.bind(this, ['action'])}
                                        value={currentvalue.get('action')}
                                    />
                                    }
                            </div>
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') != 'fill' &&
                                selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') != 'show' &&
                                    <div className="col-span-16 md:col-span-4"> 
                                    <Select
                                        options={selectedcomponent.getIn(['component', 'stateids']).map((state) => {
                                            return {
                                                value: state.get('target'),
                                                text: state.get('description')
                                            }
                                        })}
                                        onChange={this.currentValueChanged.bind(this, ['target'])}
                                        value={currentvalue.get('target')}
                                    /></div>} 
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'show' &&
                                    <div className="col-span-16 md:col-span-3 mt-2">
                                        <Input onChange={this.currentValueChanged.bind(this, ['target'])} value={currentvalue.get('target')}/>
                                    </div> } 
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' &&
                                    <div className="col-span-16 md:col-span-3 mt-2">
                                        <Input onChange={this.currentValueChanged.bind(this, ['target'])} value={currentvalue.get('target')}/>
                                    </div> }  
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('action') == 'fill' &&
                                    <div className="col-span-16 md:col-span-1 mt-2">
                                        <ColorPicker value={currentvalue.get('fill')} onChange={this.currentValueChanged.bind(this, ['fill'])}/>
                                    </div>}
  
                                {selectedcomponent.getIn(['component', 'stateids']).get(0) && selectedcomponent.getIn(['component', 'stateids']).get(0).get('type') == 'fill' && 
                                    <div className="col-span-12 md:col-span-1">
                                        <ColorPicker value={currentvalue.get('fill')} onChange={this.currentValueChanged.bind(this, ['fill'])}/></div> }   
                                    <div className="col-span-12 md:col-span-2 py-2">
                                <Button role="success" className="w-28" onClick={this.addRule.bind(this)}>Add</Button>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default AnimationConfiguration;