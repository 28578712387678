export default class EventEmitter {
    constructor() {
        this.listeners = [];
    }

    emit(event) {
        this.listeners.forEach((listener) => {
            listener(event);
        });
    }

    addListener(listener) {
        this.listeners.push(listener);
        return this.removeListener.bind(this, listener);
    }

    removeListener(listener) {
        this.listeners.splice(this.listeners.indexOf(listener));
    }
}
