import React, { useState, useEffect } from 'react';
import DateTimeSettingsDialog from './DateTimeSettingsDialog';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import ServiceBus from 'Services/ServiceBus';
import {getFileCommunic} from 'BmsView/Actions/Common';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function DeviceDialog({ selectedItem, onClose, ...props }) {
    return <Dialog opened={!!selectedItem} onClose={onClose} >
        <DeviceDialogPanel selectedItem={selectedItem} onClose={onClose} {...props}/>
    </Dialog>
}

function DeviceDialogPanel({customer, selectedItem, selectedSubsystem, type, saveItem, onClose}) {
    const definitionFields = [{
        'type' : 'trendlans',
        'fields' : [
            {
                'field': 'name',
                'label': 'Name',
                'functionname': undefined
            },{
                'field': 'number',
                'label': 'Lan Number',
                'functionname': parseInt
            },{
                'field': 'type',
                'label': 'Type',
                'functionname': parseInt
            }]
        },{
        'type' : 'trendoutstations',
        'fields' : [
            {
                'field': 'identifier',
                'label': 'Identifier',
                'functionname': undefined
            },{
                'field': 'outnumber',
                'label': 'Outstation Number',
                'functionname': parseInt
            },{
                'field': 'type',
                'label': 'Type',
                'functionname': parseInt
            },{
                'field': 'revision',
                'label': 'Revision',
                'functionname': undefined
            }]
    }];

    let [item, setItem] = useState(selectedItem);
    let [selectedOutstation, setOutstation] = useState();
    let [panelTypes, setPanelTypes] = useState(undefined);

    useEffect(() => {
        let unsubscribe = ServiceBus.on('309-11', getFileHandler);
        getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,selectedSubsystem,'trendtemplates','paneltype.json');
        setItem(selectedItem);

        return () => unsubscribe() //ServiceBus.off('309-11', getFileHandler);
    }, []);

    const getFileHandler = (msg) => {
        setPanelTypes(JSON.parse(msg.m_jsonFileData));
    }

    const fieldChanged  = (field, value, transform) => {
        if (transform) {
            value = transform(value);
        }

        setItem(item.set(field, value));
    }

    const onSave = () => {
        saveItem(item);
    }

    const onTimeSettings = () => {
        setOutstation(item);
    }

    const closeDialog = () => {
        setOutstation(undefined);
    }

    let addField = (field) => {
        let fieldname = field.field;
        let functionname = field.functionname;

        return (
            <div className="col-16 col-md-16">
                <Input label={field.label} value={item.get(fieldname)} onChange={ (value) => {fieldChanged(fieldname, value, functionname)}} />
            </div>
        )
    }

    let fields = definitionFields.filter((fieldtype) => {
        if (fieldtype.type == type ) return fieldtype;
    });

    fields = fields[0].fields;

    return (
        <DialogContent>
            { selectedOutstation && <DateTimeSettingsDialog selectedOutstation={selectedOutstation} customer={customer} onClose={() => closeDialog()}></DateTimeSettingsDialog> }
            <DialogHeader>
                <DialogTitle>Device Definition</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="container-fluid">
                    {addField(fields[0])}
                    {addField(fields[1])}
                    {type == 'trendoutstations' && <div className="col-16 col-md-16">
                        <Select 
                            label="Type"
                            value={item.get('type')}
                            onChange={(value) => fieldChanged('type', value, parseInt)}
                            options={(panelTypes || []).map((panel) => {
                                return {
                                    value: panel['number'],
                                    text: panel['text']
                                }
                            })}
                            />
                    </div>} 
                    {type == 'trendoutstations' && addField(fields[3])}   
                   
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={ () => onClose()}>Close</Button></div>
                { type == 'trendoutstations' &&
                <div className="pl-1"><Button role="secondary" onClick={ () => onTimeSettings()}>Date/Time Settings</Button></div> }
                <div className="pl-1"><Button role="primary" onClick={ () => onSave()}>Save changes</Button></div>
            </DialogFooter>  
        </DialogContent>
    )
};

export default DeviceDialog;