import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function StandardDialog({ opened, onClose, zIndex="z-10", layer="normal",  children, className = "md:max-w-[60vw]" }) {
    
    let classNameNormal = "z-[1050]";
    let classNameTop = "z-[1055]";

    const currentLayer = {
        'normal': classNameNormal,
        'top': classNameTop
    }
    
    return (
        <Dialog open={opened} as="div" className={`relative ${currentLayer[layer]}`} onClose={onClose}>
         <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className={`fixed ${zIndex} inset-0 overflow-y-auto`}>
                <div className="flex items-end sm:items-center md:items-start justify-center min-h-full text-left sm:p-0 md:p-4 ">
                    <Dialog.Panel className={`flex flex-col bg-white rounded-lg shadow-xl sm:w-full md:w-fit ${className} `}>
                        {children}
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    )
}

export default StandardDialog;


export function DialogHeader({ children, className = '' }) {

    return <div className={`flex p-4 border-b border-[#dee2e6] ${className}`}>
        {children}
    </div>
}

export function DialogBody({ children, className = '' }) {
    return <div className={`flex-initial p-4 ${className}`}>
        {children}
    </div>
}

export function DialogFooter({ children }) {


    return <div className="flex p-3 border-t border-[#dee2e6] items-center justify-end">
        {children}
    </div>
}

export function DialogContent({ children, className }) {


    return <div className={`flex flex-col ${className}`}>
        {children}
    </div>
}

export function DialogTitle({ children, className = ''}) {

    return <div className={`text-2xl leading-6 ${className}`}>
        {children}
    </div>

}