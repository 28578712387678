import React from 'react';
import classnames from 'classnames';
import Immutable from 'immutable';
import Button from 'Controls/Button/UserDefined';

class Digital extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            priority:8
        }
    }

    toggle() {
        let {priority} = this.state;
        let { onChange, value } = this.props;

        if (onChange) {
            onChange(value == 1 ? 0 : 1, value == 1 ? 0 : 1, priority);
        }
    }

    onChangeMode(){
        let { onChangeMode, mode } = this.props;

        if (onChangeMode) {
            onChangeMode(mode == 1 ? 0 : 1, mode == 1 ? 0 : 1);
        }
    }

    onChangePriority(priority){
        this.setState({
            priority:priority
        });
    }

    setRelease() {
        let {priority} = this.state;
        let {onChange, value} = this.props;

        onChange(3, value, priority);
        return;
    }

    render() {
        let {priority} = this.state;
        let { value, mode, selectedpoint, onShowPointPriorities } = this.props;
        let modes = ["Set To Auto", "Set To Manual"];

        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');

        let role = !value ? 'success' : 'secondary';
        
        let texts = ["Turn on", "Turn off"]
        if (selectedpoint && selectedpoint.getIn(['pointconfiguration', 'm_stateText'])) {
            try {
                texts = selectedpoint.getIn(['pointconfiguration', 'm_stateText'], Immutable.List([])).toJS();
            }
            catch (e) {
                let raw = selectedpoint.toJS();
            }
        }
        return (
            <div className="grid grid-cols-16 gap-2">
                {address[1] == 6 && 
                    <div className="col-span-10">
                        <Button role={role} className={`mt-2`} onClick={this.onChangeMode.bind(this)}>{modes[mode == 1 ? 0 : 1]}</Button>
                    </div>
                }
                <div className="mt-4 col-span-16 md:col-span-6">
                    <Button role={role} className={`mt-2`} onClick={this.toggle.bind(this)}>{texts[value == 1 ? 0 : 1]}</Button>
                    {address[1] == 9 &&
                       <Button role="success" className="mt-4 ml-8" onClick={onShowPointPriorities}>Priorities</Button>}
                </div>
            </div>
        )
    }
}
export default Digital;
