import React,{useState, useEffect} from 'react';
import ServiceBus from 'Services/ServiceBus';
import Immutable from 'immutable';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Grid from 'Controls/GridV2';
import {searchPoints} from './Actions';
import ModuleTypes from 'Configuration/ModuleTypes';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function ControllerPointListDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} >
        <ControllerPointListDialogPanel selectedItem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}


const ControllerPointListDialogPanel = ({currentuser, customer, onSelect, selecteditem, subsystemNumber, subsystemType, networkNumber, controllerNumber, pointtype, onClose}) => {
    let [points, setPoints] = useState();
    let [pointType, setPointType] = useState(pointtype);


    const pointsStateRef = React.useRef(points);
    const setPointsState = data => {
      pointsStateRef.current = data;
      setPoints(data);
    };

    let selectedSubsystemType = ModuleTypes[subsystemType];

    useEffect(() => {
        let unsubsribe = ServiceBus.on('300-11', processPointStatusResponse, true);
              
        return () => {
            // ServiceBus.off('300-11', processPointStatusResponse);
            unsubsribe();
        };
    }, []);

    useEffect(() => {
        let r = 2;
        getPoints();
    }, [pointType]);


    useEffect(() => {
        let r = 2;
        if (points && points.size > 0) getPointStatus();
    }, [points]);

    const getPoints = async() => {
        let query = {};

        query['addr.0'] = subsystemNumber;
        query['addr.2'] = pointType;
        query['addr.3'] = networkNumber;
        query['addr.4'] = controllerNumber;
      
        let pointList = await searchPoints(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase().replace(/ /g, ""), query);

        setPointsState(Immutable.fromJS(pointList));        
        //getPointStatus(Immutable.fromJS(pointList)); 
    }

    const getPointStatus = () => {
        let point = points.get(0);
        let status = point.get('status');
        if (points && status) return;
        (points || []).map((point) => {
            let request = {
                trackMessage: true,
                m_level: 10,
                m_communicNum: 300,
                target: 'signalr',
                type: "GENERIC",
                m_subsystemNumber: subsystemNumber,
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
            }
            
            let comm = {
                m_pointAddr: point.get('addr').toJS()
            }
            ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
        })
    }

    const processPointStatusResponse = (message) => {
        let { m_pointStatusData } = message;
        if (!m_pointStatusData) {
            m_pointStatusData = message;
        }

        let type = pointType;

        let pointList = pointsStateRef;

        let newpoints = (pointsStateRef.current || []).map((point) => {
            if (JSON.stringify(point.get('addr'))==JSON.stringify(m_pointStatusData.m_pointAddr)) {
                point = point.set('status', m_pointStatusData.m_formattedValueText);
                point = point.set('alarm', m_pointStatusData.m_formattedAlarmText);
                point = point.set('pointconfiguration', Immutable.fromJS(m_pointStatusData))
            }
            
            return point;
        });
    
        setPointsState(newpoints);
    } 
    
    const commandPoint = (event, point) => {
        // console.log(point);
        if (onSelect) {
            onSelect(Immutable.Map({}).setIn(['configuration', 'boundto'], point.get('addr').join('-')).set('pointconfiguration', point.get('pointconfiguration')));
        }
    }
    
    return (
        <DialogContent className="md:w-[500px] lg:w-[800px] xl:w-[1140px]"> 
            <DialogHeader>
                <DialogTitle>Controller Point Status List</DialogTitle>
            </DialogHeader> 
            <DialogBody>
                    <div className="container-fluid">
                        <div className="grid grid-cols-16">
                            <div className="col-span-16 md:col-span-2"> {`Subsystem`}</div>
                            <div className="col-span-16 md:col-span-1"> {subsystemNumber}</div>
                            <div className="col-span-16 md:col-span-2 gap-4"> Lan</div>
                            <div className="col-span-16 md:col-span-1"> {networkNumber}</div>
                            <div className="col-span-16 md:col-span-2 gap-4"> Outstation</div>
                            <div className="col-span-16 md:col-span-1"> {controllerNumber}</div>
                        </div>
                    </div>
                    <div className="my-4">
                        <Select
                            label="Point Type"
                            value={pointType}
                            onChange={(value) => setPointType(parseInt(value))}
                            options={selectedSubsystemType.pointtypes.map((pointtype, index) => {
                                return {
                                    value: index,
                                    text: pointtype.name
                                }
                            })}
                        />
                    </div>    
                    <Grid>
                        <Grid.Header>
                        <Grid.Column classes="col-span-16 md:col-span-6">Name</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-4">Status</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-4">Alarm</Grid.Column>
                            <Grid.Column name="selection" classes="col-span-16 md:col-span-2"></Grid.Column>
                        </Grid.Header>
                        <Grid.Data className="max-h-96 h-96 overflow-y-scroll overflow-x-hidden">
                            {(points || []).map((point, index) => {
                                return (
                                    <Grid.Row key={index}>
                                        <Grid.Column>{point.get('name')}</Grid.Column>                                                       
                                        <Grid.Column>{point.get('status', 'polling')}</Grid.Column>
                                        <Grid.Column>{point.get('alarm', 'polling')}</Grid.Column>
                                        <Grid.Column><Button role="secondary" className="" onClick={(evt) => commandPoint(evt, point)}>Select</Button></Grid.Column>
                                    </Grid.Row>
                                )
                            })}
                        </Grid.Data>
                    </Grid>
            </DialogBody>
            <DialogFooter>
                <div className="flex p-3 items-center justify-end gap-2">
                    <Button role="close" className="" onClick={() => onClose()}>Close</Button>
                </div>
            </DialogFooter>
        </DialogContent>

    );
}

export default ControllerPointListDialog;
