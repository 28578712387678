import React, { useState, useMemo, useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

const AreaMenuContext = React.createContext({});

export const AreaMenuProvider = AreaMenuContext.Provider;



export function useAreaMenu() {
    return useContext(AreaMenuContext);
}


export default function AreaMenuWrapper({ children }) {
    const [areaMenu, setAreaMenu] = useStateIfMounted({});
    const value = useMemo(() => ({ areaMenu, setAreaMenu }), [areaMenu]);
    return <AreaMenuProvider value={value}>
        {children}
    </AreaMenuProvider>
}