import React, { useState, useEffect, Fragment } from 'react';
import { fromJS, List, Map, OrderedMap } from "immutable";
import Select from 'Controls/Form/TailwindSelect';
import YearView from './Calendar/YearView';
import {PencilSquareIcon, TrashIcon, PlusIcon, ArrowDownTrayIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { nextDay } from './Calendar/Utils';
import Grid from 'Controls/GridV2';
import { DayTimesInput } from './TimeTemplates/DayTimes/DayTimesVerticalDialog';
import PointGroupSelectionDialog from '../PointGroupSelectionDialog';

const formatTSToSystemDate = (date) => {
    return (new Date(date)).toISOString().replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2}).*/g, '$3/$2/$1')
}

export default function CalendarPanel({ calendar, customer, onChangeCalendar, name, selectedDate, onRemoveSingleDate,selectedRange, points, onContextMenu, onRangeSelect, onChangeName, dates, selectedGroups, availableGroups, onSelectGroups, availableTemplates, selectedTemplate, onChangeTemplate, calendars, onSelectCalendar, onAddCalendar, onDeleteCalendar, onDownloadCalendar, calendarSelected  }) {
    
    const [displayGroupSelectionDialog, toggleGroupSelectionDialog] = useState(false);
    
    let currentTemplate = null;
    if(selectedTemplate > -1) {
        currentTemplate = availableTemplates.get(selectedTemplate)
    }

    const dayLabels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const useValues = [
        { text: "Next", value: 0 },
        { text: "Every", value: 1 },
        { text: "Free", value: 2 },
    ]
    const priorityValues = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map(p => ({ text:p, value:p }));
    
    let convertedDates = (() => {
        let datesOrderedMap = OrderedMap();
        (dates || List()).forEach(o => {
            let x = o.get('range') || "";
            let converted = (x).replace(/([0-9]{2})\/([0-9]{2})\/([0-9]{4})\-([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g, '$3-$2-$1/$6-$5-$4');
            let [start, end] = converted.split('/');
            let reference = x;
            let dayTemplate = availableTemplates.find(t => t.get('_id') == o.get('template'));
            let dayAfter = start;
            datesOrderedMap = datesOrderedMap.update(dayAfter, Map(), y => {
                if(dayTemplate.get('defaultcolor')) {
                    y = y.set('color', dayTemplate.get('defaultcolor'));
                }
                return y.set('reference', reference).set('first', true)
            });
            let whileCounter = 0;
            while(dayAfter != end && whileCounter < 100) {
                dayAfter = nextDay(dayAfter);
                datesOrderedMap = datesOrderedMap.update(dayAfter, Map(), y => { 
                    if(dayTemplate.get('defaultcolor')) {
                        y = y.set('color', dayTemplate.get('defaultcolor'));
                    }
                    return y.set('reference', reference) 
                });
                whileCounter++;
            }
        })
        return datesOrderedMap;

    })();

    return <div className=" p-2">
        <PointGroupSelectionDialog customer={customer} isOpened={displayGroupSelectionDialog} selecteditem={calendar.get('pointgroups')} filter={{type : 'Calendar'}} onClose={() => toggleGroupSelectionDialog(x => !x)} onSave={(groups) => { onSelectGroups(groups);toggleGroupSelectionDialog(x => !x) }}/>

        <div className="flex">

            <div className="flex-initial w-3/12 text-left">

            <   div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-16">
                        <h4>Select Calendar</h4>
                    </div>
                    <div className="col-span-16 md:col-span-16">
                     

                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-8">Name</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-8" name="add-buttons" type="complex">
                                    <Grid.Controls>
                                        <Grid.Control>
                                            <PlusIcon className="w-6 text-white cursor-pointer" onClick={onAddCalendar} />
                                        </Grid.Control>
                                    </Grid.Controls>
                                </Grid.Column>
                            </Grid.Header>
                            <Grid.Data>
                                { (calendars || List()).map((loopCalendar, index) => {
                                    return <Grid.Row 
                                        key={index.toString()} 
                                        onClick={() => onSelectCalendar(loopCalendar)}
                                        className={`hover:bg-gray-200 group ${loopCalendar.get('_id') == (calendar || Map()).get('_id') ? 'bg-green-100 hover:bg-green-200' : ''}`} >
                                        <Grid.Column classes="col-span-16 md:col-span-8">{loopCalendar.get('name')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-8" name="buttons" type="complex">
                                            <Grid.Controls>
                                                {/* <Grid.Control><PencilSquareIcon title="edit calendar" className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-blue-500 w-6" onClick={() => onSelectCalendar(loopCalendar)} /></Grid.Control> */}
                                                <Grid.Control><TrashIcon title="remove calendar" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 " onClick={() => onDeleteCalendar(loopCalendar)} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>
                                    </Grid.Row>
                                }) }
                            </Grid.Data>
                        </Grid>
                    </div>
                    
                </div>

                {/* Name component */}
                <div className="py-4 flex">
                    {/* <label className="p-2 flex-none">Name:</label>
                    <input type="text" className="border p-2 flex-1" onChange={(e) => onChangeName(e.target.value)} value={name} /> */}

                    

                </div>
                {/* Groups component */}
                <div className="relative">

                    { !calendarSelected && <div className="absolute top-0 right-0 w-full h-full backdrop-blur-sm bg-white/50 ">
                        
                    </div> }


                {/* <div>
                    <label className="p-2">Point groups:</label>
                    <ListSelection leftList={selectedGroups} rightList={availableGroups} onChange={onSelectGroups} />
                </div> */}
                <div className="col-span-16 md:col-span-16 y-4">
                    <h4>Point Groups</h4>
                </div>
                <div>
                    <Grid>
                        <Grid.Header>
                            <Grid.Column classes="col-span-16 md:col-span-12">Name</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-4" name="add" type="complex">
                                <Grid.Controls>
                                    <Grid.Control>
                                        <PencilSquareIcon className="w-6 text-white cursor-pointer" title="Select Groups" onClick={() =>toggleGroupSelectionDialog(x => !x)} />
                                    </Grid.Control>
                                </Grid.Controls>
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data>
                            {(selectedGroups || []).map((group, index) => {
                                return (
                                    <Grid.Row className={"hover:bg-gray-200 group"} key={index}>
                                        <Grid.Column classes="col-span-16 md:col-span-12">{group.get('name')}</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4"></Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid.Data>
                    </Grid>
                </div>
                {/* Points in group list component */}
                {/* <div className="py-4">
                    <div className="p-2 font-bold">Points:</div>
                    { points && points.size > 0 && points.map((point, index) => {

                        return <div key={index.toString()}>
                            { point.get('name') }
                        </div>
                    }) || <div className="italic">There are no points available in the selected groups</div> }
                </div> */}
                {/* Template selection component */}
                
                {/* <div className="py-4 flex">
                    <label className="flex-none p-2">Template:</label>
                    <select
                        value={selectedTemplate}
                        onChange={(e) => onChangeTemplate(e.target.value)}
                        className="flex-1 border border-gray-200 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                        <option value="">Select template...</option>
                        {(availableTemplates || []).map((template, index) => {
                            return (
                                <option  value={index}>{template.get('name')} ({template.get('type')})</option>
                            )}
                        )}
                    </select>
                </div>  */}


               <SelectedDateInformation 
                    selectedRange={selectedRange}
                    calendar={calendar}
                    availableTemplates={availableTemplates}
                    dayLabels={dayLabels}
                    selectedDate={selectedDate}  />

                {/* Template display component */}
                {/* { currentTemplate && <div className="py-4">
                    <div className="grid grid-cols-3">
                        <div className="font-bold">Day</div>
                        <div className="font-bold">Start</div>
                        <div className="font-bold">End</div>
                        { (currentTemplate.get('periods') || List()).sort((a,b) => a.get('dow') - b.get('dow')).map((period, i) => {
                            
                            return [
                                <div key={`dow${i.toString()}`}>Each selected {dayLabels[period.get('dow')] || 'day'}</div>,
                                <div key={`starttime${i.toString()}`}>{period.get('starttime')}</div>,
                                <div key={`endtime${i.toString()}`}>{period.get('endtime')}</div>
                            ]
                        })  }
                    </div>
                </div>} */}

                </div>
            </div>

            <div className="flex-initial w-9/12 relative">

            { !calendarSelected && <div className="absolute top-0 right-0 w-full h-full backdrop-blur-sm bg-white/70 z-[5]">
                <div className="flex items-center animate-bounce-horizontal">
                    <div className="flex-none">
                        <ArrowLeftIcon className="w-14 text-indigo-300 p-2" />
                    </div>
                    <div className="flex-1 text-2xl">
                        Select or add a calendar to start
                    </div>
                </div>
            </div> }


            <div className="flex items-center px-8">
                <DayTimesInput type="text" label="name" value={name || ""} onChange={(v) => onChangeName(v)} />
                <DayTimesSelect label="Use" options={useValues} onChange={(v) => onChangeCalendar(calendar.set('use', parseInt(v)))} value={calendar.get('use') || ""} />
                <DayTimesSelect label="Priority" options={priorityValues} onChange={(v) => onChangeCalendar(calendar.set('priority', parseInt(v)))} value={calendar.get('priority') || ""} />
            </div>

                {/* CalendarView component */}
                <YearView 
                selectedDate={selectedDate}
                onContextMenu={onContextMenu}
                    selected={convertedDates} 
                    onRangeSelect={onRangeSelect} />
                {/* Selected dates compponent*/}
                {/* <div className="text-left px-2">
                    <label className="p-2 px-4 font-bold">Selected days</label> 
                    <div className="px-4 py-2 grid grid-cols-4">

                        { dates && dates.map((date) => {
                            return <div key={date} className="flex px-2">
                                <div className="flex-1">
                                    { date }
                                </div>
                                <div className="flex-none">
                                    <button onClick={() => onRemoveSingleDate(date)}>[x]</button>
                                </div>
                            </div>
                        })}
                    </div>
                </div> */}
            </div>
        </div>
    </div>

}


export function DayTimesSelect({ className, label, options, onChange, value }) {

    return <div className={`flex py-1 px-8 items-center ${className || ''}`}>
        <div className="flex-none p-1 px-2">
            { label }
        </div>
        <div className="flex-2">
            <Select
                value={value}
                onChange={ (value) => onChange(value)}
                options={options}
            />
        </div>
    </div>

}

function SelectedDateInformation({ selectedRange, calendar, availableTemplates, selectedDate, dayLabels }) {

    // console.log("selected range", selectedRange);

    let selectedDay = null;
    let templateOfSelectedDay = null;
    if(selectedRange && calendar && calendar.get('days')) {
        selectedDay = calendar.get('days').find(d => d.get('range') == selectedRange.get('reference'));
        templateOfSelectedDay = availableTemplates.find(t => t.get('_id') == selectedDay.get('template'));
    }

    // console.log("selected day", selectedDay);
    // console.log("availableTemplates", availableTemplates);
    // console.log("templateOfSelectedDay", templateOfSelectedDay);
    // console.log("selectedDate", selectedDate);
    let dow = null;
    let formattedDate = null;
    if(selectedDate) {

        let unixDate = Date.parse(`${selectedDate} 00:00:00.000 +00:00`);
        formattedDate = formatTSToSystemDate(unixDate);
        dow = (new Date(unixDate)).getUTCDay();
        dow = dow === 0 ? 6 : dow - 1
        // console.log("formattedDate", formattedDate);

    }


    if(!templateOfSelectedDay) {
        return null;
    }

    let titleBackground = {}
    titleBackground.backgroundColor = templateOfSelectedDay.get('defaultcolor');
    titleBackground.color = "#fff";

    return <div className="py-4 flex flex-col">
        <h4 className="p-2" style={titleBackground}>Selected day {formattedDate && formattedDate.replaceAll('/','-')}</h4>
        { templateOfSelectedDay.get('type') == 'day' && <div className="">
            <div className="">Template name: {templateOfSelectedDay.get('name')}</div>
            <div className="">
                <strong>Times</strong>
                { templateOfSelectedDay.get('periods').map((period, index) => {
                    return <div key={index.toString()}>
                        { period.get('starttime') } - { period.get('endtime') }
                    </div>
                }) } 
            </div>
        </div> }

        
        { templateOfSelectedDay.get('type') == 'week' && <div className="">
            <div className="">Template name: {templateOfSelectedDay.get('name')}</div>
            <div className="">
                <strong>Times</strong>
                <div className="grid grid-cols-2">
                    { templateOfSelectedDay.get('periods').groupBy(x => x.get('dow')).map((periodGroup, index) => {
                        
                        let timeClass = "p-1";
                        let timeStyle = {};

                        if(dow !== null && dow == index) {
                            // console.log(`dow: ${dow}, index: ${index}`);
                            timeStyle.backgroundColor = "#eee";
                            timeStyle.borderColor = templateOfSelectedDay.get('defaultcolor');
                            timeClass += " animate-pulse border-t border-b";
                        }
                        
                        return <Fragment key={index.toString()}>
                            <div className={`${timeClass} pl-0`} style={timeStyle}>{ dayLabels[index] }</div>
                            <div className={`${timeClass}`} style={timeStyle}>
                                {periodGroup.map((period, index) => {
                                    return <div key={index.toString()}>
                                        { period.get('starttime') } - { period.get('endtime') }
                                    </div>
                                }).toList()}
                            </div>
                        </Fragment>
                    }).toList() } 
                </div>
            </div>
        </div> }
    </div> 

}