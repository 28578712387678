import Store from 'Store';

export function setPointselection(pointselection){
    Store.dispatch({
        type: 'POINT_SEARCH',
        data: pointselection
    })
}

// export function getAllPoints(customer, connection) {
//     return new Promise((resolve, reject) => {
//         axios.get(`/api/points?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
//             resolve(response.data);
//         }).catch(reject);
//     });
// }