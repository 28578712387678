import React, { useState, useEffect } from 'react';
import { classNames, generateMonths } from './Utils';


export default function CalendarView({ onRangeSelect, selected, onContextMenu, selectedDate }) {

    const [rangeSelectionMode, setRangeSelectionMode] = useState(false);
    const [rangeStart, setRangeStart] = useState();
    const [hoverRangeEnd, setHoverRangeEnd] = useState();
    const [rangeEnd, setRangeEnd] = useState();
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        if(!rangeSelectionMode) {
            setRangeStart(undefined);
            setHoverRangeEnd(undefined);
            setRangeEnd(undefined);
        }
    }, [rangeSelectionMode])

    const dayClickEvent = (date, range) => {

        // console.log("0910 dayClickEvent", date, range);

        if(range) {
            onRangeSelect(range, null, date);
            return;
        }

        if(!rangeSelectionMode) {
            onRangeSelect(date, date);
            return;
        }
        if (rangeStart && !rangeEnd) {
            let rangeStartNumber = Number(rangeStart.replaceAll('-', ''));
            let rangeEndNumber = Number(date.replaceAll('-', ''));
            if (rangeEndNumber >= rangeStartNumber) {
                setRangeEnd(date);
                setHoverRangeEnd(undefined);
            } else {
                setRangeStart(undefined);
                setHoverRangeEnd(undefined);
                setRangeEnd(undefined);
            }
        } else if (rangeStart && rangeEnd) {
            if(date == rangeEnd) {
                onRangeSelect(rangeStart, rangeEnd);
            }
            setRangeStart(undefined);
            setRangeEnd(undefined);
            setHoverRangeEnd(undefined);
        } else {
            setRangeStart(date);
            setRangeEnd(undefined);
            setHoverRangeEnd(undefined);
        }
    }

    const dayHoverEvent = (date) => {
        if (rangeStart && !rangeEnd) {
            setHoverRangeEnd(date);
        }
    }

    let baseYear = (new Date()).getFullYear();
    let baseMonth = (new Date()).getMonth();

    baseMonth = baseMonth + (offset * 12);
    if(baseMonth > 11) {
        baseYear = baseYear + offset;
        baseMonth = baseMonth % 12;
    }

    let newmonths = generateMonths(baseMonth, baseYear);
    let selectionHelperMessage = ``;
    let startRangeSelected = rangeStart && !rangeEnd;
    let endRangeSelected = rangeStart && rangeEnd;
    if(startRangeSelected) {
        selectionHelperMessage += "Select end date or same date if this isn't a range";
    }

    if(endRangeSelected) {
        selectionHelperMessage += "Click again to save the range, click on the end date to save, click on another block to cancel";
    }

    // console.log("selected in yearview", selectedDate);

    return <div className="bg-white px-1  ">
        <div className="flex px-2">
            <div className="flex-1">
                { selectionHelperMessage && <div className="py-2 h-full border border-[green] bg-[rgba(0,200,0,0.2)] ">
                    { selectionHelperMessage }
                </div> }
            </div>
            <div className="flex-none px-2">
                <button 
                    className={`p-2 border ${rangeSelectionMode ? `border-[green] text-[green]` : `border-[red] text-[red]`}`} 
                    onClick={() => setRangeSelectionMode(x => !x)}>Range select: <span className="font-bold">{rangeSelectionMode ? 'on' : 'off'}</span></button>
            </div>
        </div>
        <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-4 py-4 gap-y-8 sm:grid-cols-2 sm:px-6 xl:max-w-none xl:grid-cols-6 xl:px-8 2xl:grid-cols-6 ">
            {newmonths.map((month) => {
                return (<section key={month.name + Math.random()} className="text-center text-sm">
                    <h4 className="font-semibold text-gray-900">{month.name} {month.year}</h4>
                    <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>S</div>
                        <div>S</div>
                    </div>
                    <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-xs ring-1 ring-gray-200">
                        {month.days.map((day, dayIdx) => {
                            let startOfRange = rangeStart && rangeStart == day.date;
                            let endOfRange = rangeEnd && rangeEnd == day.date;
                            // let customColor = day.customColor;
                            let bgColor = day.isCurrentMonth && 'hover:bg-[deepskyblue] bg-white' || 'bg-gray-50';
                            bgColor = day.isCurrentMonth && startOfRange && 'bg-[red]' || bgColor;
                            bgColor = day.isCurrentMonth && endOfRange && 'bg-[gold]' || bgColor
                            if (!startOfRange && rangeStart && !rangeEnd && hoverRangeEnd) {
                                let rangeStartNumber = Number(rangeStart.replaceAll('-', ''));
                                let currentDateNumber = Number(day.date.replaceAll('-', ''));
                                let hoverRangeEndNumber = Number(hoverRangeEnd.replaceAll('-', ''));
                                if (rangeStartNumber < currentDateNumber && hoverRangeEndNumber >= currentDateNumber) {
                                    bgColor = day.isCurrentMonth && 'bg-[deepskyblue]' || bgColor;
                                }
                            }
                            if (rangeStart && rangeEnd) {
                                let rangeStartNumber = Number(rangeStart.replaceAll('-', ''));
                                let currentDateNumber = Number(day.date.replaceAll('-', ''));
                                let rangeEndNumber = Number(rangeEnd.replaceAll('-', ''));
                                if (rangeStartNumber < currentDateNumber && rangeEndNumber > currentDateNumber) {
                                    bgColor = day.isCurrentMonth && 'bg-[deepskyblue]' || bgColor;
                                }
                            }
                            let currentSelectedDate = day.date == selectedDate;
                            let isRange = selected.get(day.date);
                            let customColor = isRange && isRange.get('color');
                            let isEvent = isRange;
                            let customStyle = day.isCurrentMonth ? { backgroundColor: customColor } : {};
                            // console.log("isEvent", isEvent);
                            return (<button
                                onClick={() => dayClickEvent(day.date, isRange)}
                                onContextMenu={(e) => onContextMenu(e, day.date, isRange)}
                                onMouseOver={() => dayHoverEvent(day.date)}
                                key={day.date}
                                type="button"
                               
                                className={classNames(
                                    currentSelectedDate ? 'animate-pulse' : '',
                                    day.isCurrentMonth ? 'text-gray-900' : ' text-gray-400',
                                    dayIdx === 0 && 'rounded-tl-lg',
                                    dayIdx === 6 && 'rounded-tr-lg',
                                    dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                                    dayIdx === month.days.length - 1 && 'rounded-br-lg',
                                    'py-1 focus:z-10',
                                    bgColor
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    style={customStyle}
                                    className={classNames(
                                        day.isToday && day.isCurrentMonth && 'bg-indigo-600 font-semibold text-white',
                                        day.isCurrentMonth && isEvent && !isRange && 'bg-[green] text-white font-semibold',
                                        day.isCurrentMonth && isRange && 'bg-[green] text-white font-semibold',
                                        'mx-auto flex  items-center justify-center rounded-full'
                                    )}
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                            </button>)
                        })}
                    </div>
                </section>)
            })}
        </div>
        <div className="flex justify-end p-4">
            { offset != 0 && <button className="border p-2 mr-2" onClick={() => setOffset((offset) => offset - 1)}>Previous</button> }
            <button className="border p-2" onClick={() => setOffset((offset) => offset + 1)}>Next</button>
            
        </div>
        
    </div>
}