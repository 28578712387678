import React, {useState} from 'react';
import Immutable from 'immutable';
import Swal from 'sweetalert2';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ControllerList = ({customer, selectedsubsystem, selectedlan, outstations, selectedoutstation, setSelectedOutstation, scanForPoints, updateItem, deleteItem}) => {
    const [selecteditem, setSelectedItem] = useState(undefined);

    const addItem = () => {
      setSelectedItem(Immutable.Map({'subsystem':selectedsubsystem,'lannumber':selectedlan, 'intermmode':0, 'iqlstrategy': 0, 'revision': '', 'settime':0}));
    }

    const buttonClick = (task) => {
      if (selectedlan == undefined) {
        Swal.fire('Error', 'Outstation not selected', "error");
        return;
      }

      task();
    }

    const editSettings = () => {
      setSelectedItem(selectedoutstation);
    }

    const clearDialog = () => {
      setSelectedItem(undefined);
    } 

    return (
      <div>
      
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 bg-white px-4 mt-3 sm:px-6">
            <div className="-mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div>
                <h1 className="text-lg leading-6 font-medium text-gray-900">Outstations</h1>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <img src="/images/scan.svg" alt="Scan" onClick={() => buttonClick(scanForPoints)} data-bs-toggle="tooltip" title="Scan for points" />
                <img src="/images/delete.svg" alt="Del" onClick={() => buttonClick(deleteItem('trendoutstations'))} data-bs-toggle="tooltip" title="Delete" />
                <img src="/images/settings.svg" alt="Set" onClick={() => buttonClick(editSettings)} data-bs-toggle="tooltip" title="Settings" />
                <img src="/images/add.svg" alt="Add" onClick={() => addItem()} data-bs-toggle="tooltip" title="Add" />
              </div>
            </div>
          </div>

          <div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100 ">
            <table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
              <thead className="bg-blue-500">
                <tr>
                  <th
                    scope="col"
                    className="sticky w-1/6 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="sticky w-3/6 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Type
                  </th>
                  
                </tr>
              </thead>
              <tbody className="bg-white">
                {(outstations || []).map((outstation, index) => (
 
                  <tr key={outstation.get('_id')} onClick={() => setSelectedOutstation(outstation)} className= {selectedoutstation && (outstation.get('_id') == selectedoutstation.get('_id')) ? "relative px-1 bg-green-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500":
                  "relative px-1 hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"}>
                    <td
                      className={classNames(
                        index !== outstations.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                      )}
                    >
                      {outstation.get('outnumber')}
                    </td>
                    <td
                      className={classNames(
                        index !== outstations.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}
                    >
                      {outstation.get('identifier')}
                    </td>
                    <td
                      className={classNames(
                        index !== outstations.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}
                    >
                      {outstation.get('type')}
                    </td>
                  </tr>

                ))}  
              </tbody>
            </table>
          </div>
      </div>
      </div>

    );
}

export default ControllerList;
