import React, {useState, useEffect, Fragment} from 'react';
import Immutable from 'immutable';
import AlarmListPanel from './AlarmListPanel';
import {getLog} from './Actions';
import DateTimeSelection from 'Controls/DateTimeSelection';
import ServiceBus from 'Services/ServiceBus';
import {getFileCommunic} from 'BmsView/Actions/Common';
import AlarmLogFilter from './AlarmLogFilter';
import Dialog, { DialogContent, DialogHeader, DialogBody, DialogFooter, DialogTitle } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import AlarmDetailsDialog from './AlarmDetailsDialog';
import { Disclosure } from '@headlessui/react';

function AlarmLogDialog({ show, onClose, ...props }) {
    return <Dialog className="md:max-w-[80vw]" opened={!!show} onClose={onClose}>
        <AlarmLogDialogPanel show={show} onClose={onClose} {...props} />
    </Dialog>
}

function AlarmLogDialogPanel({show, customer, onClose}) {
    const [log, setLog] = useState([]);
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType:'Today',
        startDate:new Date(new Date().setHours(0,0,0)),
        endDate:new Date(new Date().setHours(23,59,59))
    });

    const [subsystem, setSubsystem] = useState(-1);
    const [alarmTypes, setAlarmTypes] = useState([]);
    const [type, setType] = useState("All Alarms");
    const [open, setOpen] = useState(false);
    const [selectedAlarm, setSelectedAlarm] = useState(undefined);

    useEffect(() => {
        let unsubscribe = ServiceBus.on('309-11', getFileHandler);

        if(customer.get('subsystems')) {
            let subsystemtype = customer.get('subsystems').filter((subsystem) => {
                return subsystem.get('type') != 5;
            });

            if (!subsystemtype) getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,0,'trendtemplates','alarmtypelist.json');
        }    

        if (customer && show) getLogRecords();

        return () => unsubscribe() //  ServiceBus.off('309-11', getFileHandler);
    }, [show, dateTimeRange, type]);

    const getFileHandler = (msg) => {
        setAlarmTypes(JSON.parse(msg.m_jsonFileData));
    }

    const getLogRecords = () => { 
        let query = {}; 

        if (subsystem >= 0 && type == "All Alarms" )
            query = {'addr.0': subsystem};

        if (subsystem >= 0 && type != "All Alarms" )
            query = {'addr.0': subsystem, messagetext: { $eq: type}};

        getLog('alarm', `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, query, dateTimeRange.startDate, dateTimeRange.endDate).then((data) => {
                let log = Immutable.fromJS(data.map((record) => {
                    // record.datetime = new Date(record.datetime).toLocaleString();
                    return record;
                }));

                setLog(log);
            })
        }  
        
    const subsystemNumberChanged = (number) => {  
        let subsystem = parseInt(number);

        setSubsystem(subsystem);
        setAlarmTypes([]);
        setType("All Alarms");

        if ( subsystem >= 0 ) 
            getFileCommunic(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,subsystem,'trendtemplates','alarmtypelist.json');
    }

    const displayAlarmDetails  = (alarm) => {
        setSelectedAlarm(alarm);  
    }

    const clearSelection = () => {
        setSelectedAlarm(undefined);
    }
            
    return (
        <DialogContent className="md:w-full md:min-w-[80vw]">
            {selectedAlarm && <AlarmDetailsDialog selecteditem={selectedAlarm} onClose={clearSelection} />}
            <DialogHeader className="items-center">
                <DialogTitle>Alarm Log</DialogTitle>
                <div>
                    <AlarmLogFilter customer={customer} type={type} setType={(value) => setType(value)} subsystem={subsystem} setSubsystem={(value) => subsystemNumberChanged(value)} alarmTypes={alarmTypes}
                        dateTimeRange={dateTimeRange} setDateTimeRange={setDateTimeRange}/>  
                </div>
            </DialogHeader>
            <DialogBody>
                
                <div className="px-3">
                    <AlarmListPanel alarms={log} displayAlarmDetails={(alarm) => displayAlarmDetails(alarm) } />
                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={() => onClose()}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    );
}

export default AlarmLogDialog;


/*

                    <div className="container-fluid">
                        <Button
                            role="primary"
                            onClick={() => setOpen(!open)}
                            aria-controls="filters"
                            aria-expanded={open} >Filters</Button>
                        { open &&  

                                <AlarmLogFilter customer={customer} type={type} setType={(value) => setType(value)} subsystem={subsystem} setSubsystem={(value) => subsystemNumberChanged(value)} alarmTypes={alarmTypes}
                                    dateTimeRange={dateTimeRange} setDateTimeRange={setDateTimeRange}/>                  

                         }
                    </div> */
