import React from 'react';
import Input from 'Controls/Form/Input';
import ColorPicker from 'Controls/ColorPicker';
import Gradient from './Gradient';
import Checkbox from 'Controls/Form/Checkbox';
import {fromJS} from 'immutable';

class TextDigital extends React.Component {
    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    render() {
        let { selectedcomponent, onChange } = this.props;
        let grads = fromJS([
            { "stop": "0%", "colour":"#00aad4" },
            { "stop": "100%", "colour":"#004050"}]);

        return (
            <div className="mt-4">
                <div className="grid grid-cols-16 pl-2">
                    <div className="col-span-16 md:col-span-4">
                        <Input
                            label="Height"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'height'])}
                            onChange={this.valueChanged.bind(this, ['configuration', 'height'])}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-16 pl-2">
                    <div className="col-span-16 md:col-span-4 mt-4">
                        <Checkbox
                            label="Background"
                            checked={selectedcomponent.getIn(['configuration', 'backgroundEnabled'], false)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'backgroundEnabled'])} />
                    </div>
                </div>
                { selectedcomponent.getIn(['configuration', 'backgroundEnabled']) && 
                <div className="grid grid-cols-16 gap-2 mt-2  pl-2">
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="Number of digits"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'digits'], 1)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'digits'])}
                        />    
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'rotation'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="Border Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'borderwidth'], 1)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'borderwidth'])}
                        />
                    </div>
                    <div className="col-span-16 md:col-span-2">
                        <Input
                            label="Radius Corner"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'ry'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'ry'])}
                        />
                    </div> 
                    <div className="col-span-16 md:col-span-8 mt-2">
                        <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradient'])} label={"Gradient"} gradientField={""}
                            gradients={selectedcomponent.getIn(['configuration', 'grads'])} defaultGrads={grads} onChange={onChange}/>
                    </div>
                </div>}
                <div className="grid grid-cols-16"> 
                    <div className="col-span-16 md:col-span-8">
                        <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradientText'])} label={"Text Gradient"} gradientField={"Text"}
                            gradients={selectedcomponent.getIn(['configuration', 'gradsText'])} defaultGrads={grads} onChange={onChange}/>
                    </div>
                </div>    
                <div className="grid grid-cols-16 my-4 gap-2 pl-2">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'])} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'])} /></div>
                </div>
            </div>
        )
    }
}

export default TextDigital;
