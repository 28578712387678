import React, {useState} from 'react';
import Immutable from 'immutable';
import DeviceDialog from './DeviceDialog';
import { TrashIcon, Cog6ToothIcon, SignalIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// var tooltipTriggerList = [].slice.call(
//   document.querySelectorAll('[data-bs-toggle="tooltip"]')
// );

// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//   return new Tooltip(tooltipTriggerEl);
// });

const ControllerList = ({customer, selectedsubsystem, selectedcontroller, controllers, scan, setCurrentController, scanForPoints}) => {
    const [selecteditem, setSelectedItem] = useState(undefined);

    const addItem = () => {
      setSelectedItem(Immutable.Map({ 'subsystem': selectedsubsystem }));
    }

    // const addItem = () => {
    //   setSelectedItem(Immutable.Map({'subsystem':selectedsubsystem,'lannumber':selectedlan, 'intermmode':0, 'iqlstrategy': 0, 'revision': '', 'settime':0}));
    // }

    const buttonClick = (task) => {
      task();
    }

    const editSettings = () => {
      setSelectedItem(selectedcontroller);
    }

    const clearDialog = () => {
      setSelectedItem(undefined);
    } 

    const updateItem = () => {

    }

    const deleteItem = () => {
      
    }

    return (
      <div>
      
{ selecteditem && 
  <DeviceDialog customer={customer} selectedItem={selecteditem} selectedSubsystem={selectedsubsystem} type={'ex500controllers'} saveItem={(item) => updateItem('ex500controllers', item)} onClose={() => clearDialog()}></DeviceDialog> }
    <div>
      <div className="border-b border-gray-200 bg-white px-2">
        <div className=" flex justify-between sm:flex-nowrap pb-2">
          <div>
            <h1 className="text-lg leading-6 font-medium text-gray-900">Controllers</h1>
          </div>
          <div className="flex flex-shrink-0 text-right">
            <a className="pl-2 cursor-pointer text-blue" onClick={() => scan('scanforcontrollers')}>Start Network Discovery</a>
          </div>
          <div className="pl-4">
            <PlusCircleIcon className="h-6 w-6 text-gray-700 cursor-pointer" onClick={() => addItem()} />
          </div>
        </div>
      </div>
    </div>
        <div className="mt-2 flex flex-col min-h-[60vh] max-h-[60vh] overflow-y-auto bg-gray-100">
            <table className="min-w-full divide-y border-separate table-fixed" style={{ borderSpacing: 0 }}>
              <thead className="bg-blue-500">
                <tr>
                  <th
                    scope="col"
                    className="sticky w-1/6 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="sticky w-3/6 top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Type
                  </th>
                  
                </tr>
              </thead>
              <tbody className="bg-white">
                {(controllers || []).filter((_controller) => 
                  (_controller.get('subsystem') == selectedsubsystem)).map((controller, index) => (
                  <tr key={controller.get('_id')} onClick={() => setCurrentController(controller)} className= {selectedcontroller && (controller.get('_id') == selectedcontroller.get('_id')) ? "relative px-1 bg-green-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500":
                      "relative px-1 hover:bg-blue-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"}>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                      )}
                    >
                      {controller.get('controllernumber')}
                    </td>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}
                    >
                      {controller.get('identifier')}
                    </td>
                    <td
                      className={classNames(
                        index !== controllers.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-2 py-1 text-sm text-gray-500 hidden sm:table-cell'
                      )}
                    >
                      {controller.get('type')}
                    </td>
                  </tr>
                ))}  
              </tbody>
            </table>
          </div>
      </div>
    );
}

export default ControllerList;
