import React, {useState} from 'react';
import ModuleTypes from 'Configuration/ModuleTypes';

const PerformanceDetails = ({pointconfiguration, selectedsubsystem, changedInput, changeEngUnit, changeEngUnitType, changeMultistate, editPoint, engunitlist, engUnitChange, states, incrementMultistate, multistate}) => {

    let interval = [1, 5, 10, 15, 30, 60];
    let trendingtypes = ['Continuous', 'Metering kWH', 'Metering Gas', 'Metering Water', 'Metering Heat', 'Metering Oil'];
    let modbusregisterformat = ['int16', 'int32', 'int64', 'float32', 'schneiderpf'];
    
    let [change, setChange] = useState(false);

    let addr = pointconfiguration.get('addr').toArray();
    let engunittype = ModuleTypes[addr[1]].pointtypes[addr[2]].engunit;

    return(
        <div className="flex flex-col md:flex-row px-8 py-4 min-h-full">
            <div className="flex flex-col basis-5/12 m-1">
                <div className="grid grid-cols-12 gap-4 font-mono text-white text-sm text-center font-bold leading-6">
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400 col-span-5">01</div>
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400 col-span-7">02</div>
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">03</div>
                    <div className="p-4 rounded-lg shadow-lg bg-indigo-500 col-span-2">04</div>
                    <div className = "p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">05</div>
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">06</div>
                    <div className="p-4 rounded-lg shadow-lg bg-indigo-500 col-span-1">07</div>
                </div>
            </div>
            <div className="flex flex-col basis-7/12 m-1">
                <div className="grid grid-cols-3 gap-4 font-mono text-white text-sm text-center font-bold leading-6">
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">01</div>
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">02</div>
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">03</div>
                    <div className="p-4 rounded-lg shadow-lg bg-indigo-500 col-span-2">04</div>
                    <div className = "p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">05</div>
                    <div className="p-4 rounded-lg bg-indigo-300 dark:bg-indigo-800 dark:text-indigo-400">06</div>
                    <div className="p-4 rounded-lg shadow-lg bg-indigo-500 col-span-1">07</div>
                </div>
            </div>    
        </div>
    )
}

export default PerformanceDetails;