import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Immutable, { List, Map } from 'immutable';
import Swal from 'sweetalert2';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/solid';
import SiteEditDialog from './SiteEditDialog';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Button from 'Controls/Button/UserDefined';
import { useBMS } from 'BmsView/Context/BMS';

import { loadCustomers } from 'BmsView/Actions/Customers';
import { saveCustomer, saveSiteConfigFile, saveSiteSubDirectoryConfigFile } from './Actions';
import { withBMS } from '../../Context/BMS';


class CustomerSetup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentcustomer: this.props.customer
        };
    }

    saveSite(site) {
        let { currentcustomer } = this.state;

        let sites = currentcustomer.get('sites');

        let siteindex = sites.findIndex((item) => {
            return (site.get('name') === item.get('name'));
        })

        if ( siteindex != -1 ){
            Swal.fire("Error", "Name already defined", "error");
            return;
        }

        sites = sites.push(site);

        currentcustomer = currentcustomer.set('sites', sites);

        saveCustomer(currentcustomer).then(() => {
            loadCustomers();

            saveSiteSubDirectoryConfigFile(currentcustomer.get('name').toLcHyphen(), site.get('name').toLcHyphen(), 'backdrops', 'backdrops', []);
            saveSiteSubDirectoryConfigFile(currentcustomer.get('name').toLcHyphen(), site.get('name').toLcHyphen(), 'components', 'components', []);
            saveSiteConfigFile(currentcustomer.get('name').toLcHyphen(), site.get('name').toLcHyphen(), 'configuration', []);
            
            this.setState({
                currentcustomer: currentcustomer
            });

            Swal.fire("Success", "Site Added Successfully", "success");
        }).catch((err) => {
            Swal.fire("Error", "Error Adding Site", "error");
        });
    }

    addSite() {
        this.setState({
            selecteditem: Immutable.Map({})
        });
    }

    removeSite(site) {
        let { currentcustomer } = this.state;

        let sites = currentcustomer.get('sites');
        let index = sites.findIndex((item) => {
            return item.get('name') === site.get('name');
        });

        sites = sites.delete(index);

        currentcustomer = currentcustomer.set('sites', sites);

        saveCustomer(currentcustomer).then(() => {
            loadCustomers();
            this.setState({
                currentcustomer: currentcustomer
            });
            Swal.fire("Success", "Site Removed Successfully", "success");
        }).catch((err) => {
            Swal.fire("Error", "Error Removing Site", "error");
        });
    }

    editSite(site) {

    }

    moveUp(index) {
        let { currentcustomer } = this.state;

        let sites = currentcustomer.get('sites');
        let siteTemp = sites.get(index);

        sites = sites.set(index, sites.get(index - 1));
        sites = sites.set((index - 1), siteTemp);

        currentcustomer = currentcustomer.set('sites', sites);

        saveCustomer(currentcustomer).then(() => {
            loadCustomers();
            this.setState({
                currentcustomer: currentcustomer
            });
        }).catch((err) => {
            Swal.fire("Error", "Error Moving Site", "error");
        });
    }

    moveDown(index) {
        let { currentcustomer } = this.state;

        let sites = currentcustomer.get('sites');
        let siteTemp = sites.get(index);

        sites = sites.set(index, sites.get(index + 1));
        sites = sites.set((index + 1), siteTemp);

        currentcustomer = currentcustomer.set('sites', sites);

        saveCustomer(currentcustomer).then(() => {
            loadCustomers();
            this.setState({
                currentcustomer: currentcustomer
            });
        }).catch((err) => {
            Swal.fire("Error", "Error Moving Site", "error");
        });
    }

    clearSelection() {
        this.setState({
            selecteditem: undefined
        });
    }

    render() {
        let { selecteditem } = this.state;
        let { currentcustomer } = this.state;

        let lastsite = ((currentcustomer || Map()).get('sites') || List()).get(-1);

        return (
            <div className="container mx-auto my-4">
                <SiteEditDialog selecteditem={selecteditem} onSave={this.saveSite.bind(this)} onClose={this.clearSelection.bind(this)} />

                <h2>Customer Setup</h2>
                <div>
                    <Input className="" disabled={true} label="Customer" value={currentcustomer.get('name')} />
                </div>
                <h4>Site List</h4>
                <Grid>
                    <Grid.Header>
                        <Grid.Column classes="col-span-16 md:col-span-10">Site</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-6" name="delete" type="complex">
                            <Grid.Controls>
                                <Grid.Control>
                                    <PlusIcon className="w-6 text-white cursor-pointer" onClick={this.addSite.bind(this)} />
                                </Grid.Control>
                            </Grid.Controls>
                        </Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        {((currentcustomer || Map()).get('sites') || List()).map((site, index) => {
                            return (
                                <Grid.Row className="hover:bg-gray-200 group" key={index}>
                                    <Grid.Column classes="col-span-16 md:col-span-10">{site.get('name')}</Grid.Column>
                                    <Grid.Column classes="col-span-16 md:col-span-6">
                                        <Grid.Controls>
                                            {(index > 0) && <Grid.Control><ChevronUpIcon className="text-gray-300 group-hover:text-gray-800 w-6 cursor-pointer" onClick={this.moveUp.bind(this, index)}/></Grid.Control> }  
                                            {(site != lastsite) && <Grid.Control><ChevronDownIcon className="text-gray-300 group-hover:text-gray-800 w-6" onClick={this.moveDown.bind(this, index)}/></Grid.Control> } 
                                            <Grid.Control><PencilSquareIcon className="text-gray-300 group-hover:text-gray-800 w-6" onClick={this.editSite.bind(this, site)} /></Grid.Control>
                                            <Grid.Control><TrashIcon className="w-6 text-gray-300 group-hover:text-gray-800 " onClick={this.removeSite.bind(this, site)} /></Grid.Control> 
                                        </Grid.Controls>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid.Data>
                </Grid>

            </div>
        );
    }
}


// export default connect((state) => ({
//     customers: state.get('customers'),
//     customer: state.get('customer'),
// }))(CustomerSetup);

export default withBMS(CustomerSetup);