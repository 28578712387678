import axios from 'axios';
import Store from 'Store';

export function getUsers(q){
    return new Promise((resolve, reject) => {
        axios.post('/api/users/search', { selector: q , sort:({email:1})}).then((response) => {
            Store.dispatch({
                type: "USERS_READY",
                users: response.data
            });
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export function saveUser(user){
    return new Promise((resolve, reject) => {
        if (user.get('_id')){
            axios.put(`/api/users/${user.get('_id')}`, user.toJS()).then(resolve).catch(reject);
        }
        else{
            axios.post(`/api/users`, user.toJS()).then(resolve).catch(reject);
        }
    });
}

export function resetPassword( user){
    return new Promise((resolve, reject) => {
        axios.put(`/api/users/${user.get('_id')}/resetpassword`, user.toJS()).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export function getUserByResetToken(token){
    return new Promise((resolve, reject) => {
        axios.post('/api/users/search', { resettoken: token }).then((response) => {
        });
    });
}

export function deleteUser(userid){
    return new Promise((resolve, reject) => {
        axios.delete(`/api/users/${userid}`).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export function setPassword(user){
    return new Promise((resolve, reject) => {
        axios.put('/setpassword', { email: user.get('email'), password: user.get('newpassword') }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        })
    });
}