import React from 'react';
import Calendar from 'Controls/Calendar';
import ServiceBus from 'Services/ServiceBus';
import Immutable, { Map, List } from 'immutable';
import { convertDoubleToTimeString, convertTimeStringToDouble } from 'Utils/Timestamp';
import swal from 'sweetalert2';
import WeekTimes from '../../../../Configuration/Calendars/TimeTemplates/WeekTimes';
import { ModifyPeriodDialog, DayTemplateSelectionDialog } from '../../../../Configuration/Calendars/TimeTemplates/WeekTimesDialog';
import TimezoneDialog from './TimezoneDialog';
import PointGroupPanel from './PointGroupPanel';

// timezones
class Timezone extends React.Component {
    constructor(props) {
        super(props);

        this.iq2Handler = this.iq2Handler.bind(this);
        this.iq3Handler = this.iq3Handler.bind(this);
        this.setTrendIQ3TimezoneComplete = this.setTrendIQ3TimezoneComplete.bind(this);
        this.setTrendIQ2TimezoneComplete = this.setTrendIQ2TimezoneComplete.bind(this);
        this.setBacnetTimezoneComplete = this.setBacnetTimezoneComplete.bind(this);
        this.bacnetHandler = this.bacnetHandler.bind(this);

        window.backnetHandler = this.bacnetHandler;
        window.setBacnetTimezoneComplete = this.setBacnetTimezoneComplete;

        this.ucc4Handler = this.ucc4Handler.bind(this);
        this.setCylonUCC4TimezoneComplete = this.setCylonUCC4TimezoneComplete.bind(this);

        this.state = { events: Immutable.List([]), displaying: 'current' }
    }

    componentDidMount() {
        let { address } = this.props;

        ServiceBus.on('505-51', this.iq2Handler);
        ServiceBus.on('507-51', this.iq3Handler);
        ServiceBus.on('508-51', this.setTrendIQ3TimezoneComplete);
    

        // 24-151 for the bacnet timezone
        ServiceBus.on('24-151', this.bacnetHandler);

        ServiceBus.on('25-151', this.setBacnetTimezoneComplete);
    
    
        ServiceBus.on('506-51', this.setTrendIQ2TimezoneComplete);
        ServiceBus.on('0-121', this.ucc4Handler);
        ServiceBus.on('SET_CYLON_UCC4_TIMEZONES_RESPONSE', this.setCylonUCC4TimezoneComplete);
        //TODO: Remove when dealing with real IQ2 timezones
        this.setState({
            isIQ2: parseInt(address[2]) == 4
        }, this.requestTimezones.bind(this));
    }

    componentWillUnmount() {
        ServiceBus.off('505-51', this.iq2Handler);
        ServiceBus.off('507-51', this.iq3Handler);
        ServiceBus.off('508-51', this.setTrendIQ3TimezoneComplete);
        ServiceBus.off('24-151', this.bacnetHandler);
        ServiceBus.off('25-151', this.setBacnetTimezoneComplete);
        ServiceBus.off('506-51', this.setTrendIQ2TimezoneComplete);
        ServiceBus.off('0-121', this.ucc4Handler);
        ServiceBus.off('SET_CYLON_UCC4_TIMEZONES_RESPONSE', this.setCylonUCC4TimezoneComplete);
    }

    iq2Handler(msg) {
        let events = [];

        for (let dow = 0; dow < msg.m_day.length; dow++) {
            let dayProfile = msg.m_day[dow];

            for (let i = 1; i < 3; i++) {
                if (dayProfile['bH' + i] != dayProfile['eH' + i] || dayProfile['bM' + i] != dayProfile['eM' + i]) {

                    let startInDouble = dayProfile['bH' + i] + ((Math.round(dayProfile['bM' + i] / 5) * 5) / 60);
                    let endInDouble = dayProfile['eH' + i] + ((Math.round(dayProfile['eM' + i] / 5) * 5) / 60);

                    events.push({
                        id: dow + "-" + i,
                        day: dow % 7,
                        start: Math.floor(startInDouble * 2),
                        end: Math.floor(endInDouble * 2),
                        startindouble: startInDouble,
                        endindouble: endInDouble,
                        starttimeindex: (dow % 7) * 24 + startInDouble,
                        type: dow >= 7 ? 'standard' : 'current'
                    });
                }
            }
            this.setState({
                events: Immutable.fromJS(events),
                dirty: false
            });
        }
    }

    iq3Handler(msg) {
        let events = [];

        for (let dow = 0; dow < msg.m_prds.length; dow++) {
            let dayProfile = msg.m_prds[dow];

            let length = dayProfile.length;

            // console.log('dayprofile', dayProfile);

            if (length % 2) length--;

            for (let period = 0; period < length; period += 2) {
                let startInDouble = dayProfile[period].h + ((Math.round(dayProfile[period].m / 5) * 5) / 60);
                let endInDouble = dayProfile[period + 1].h + ((Math.round(dayProfile[period + 1].m / 5) * 5) / 60);
                events.push({
                    id: dow + "-" + period,
                    day: dow,
                    start: Math.floor(startInDouble * 2),
                    end: Math.floor(endInDouble * 2),
                    startindouble: startInDouble,
                    endindouble: endInDouble,
                    starttimeindex: dow * 24 + startInDouble,
                    type: 'current'
                });
            }
        }

        this.setState({
            events: Immutable.fromJS(events),
            dirty: false
        });
    }

    ucc4Handler(msg) {
        let events = [];

        for (let dow = 0; dow < 7; dow++) { 
            let dayProfile = msg.m_scheduleTimes[dow];

            let startInDouble = dayProfile.m_bHr + ((Math.round(dayProfile.m_bMn / 5) * 5) / 60);
            let endInDouble = dayProfile.m_eHr + ((Math.round(dayProfile.m_eMn / 5) * 5) / 60);          
            events.push({
                id: dow, // + "-" + period,
                day: dow,
                start: Math.floor(startInDouble * 2),
                end: Math.floor(endInDouble * 2),
                startindouble: startInDouble,
                endindouble: endInDouble,
                starttimeindex: dow * 24 + startInDouble,
                type: 'current'
            });
        }

        this.setState({
            events: Immutable.fromJS(events),
            dirty: false
        });
    }

    getPin(subsystemNumber) {
        let {customer} = this.props;

        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
 
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }

    saveToPanel() {
        let { customer, address, currentuser } = this.props;
        let { events } = this.state;

        // console.log("events to save to panel");
        // console.log(events);

        let subsystemNumber = parseInt(address[0]);

        let saveRequest = {
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            type: 'GENERIC',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            trackMessage: true
        };

        let comm = {
            m_password: this.getPin(subsystemNumber),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_pointAddr: address.map((byte) => parseInt(byte))
        };

        if (address[1] == 5 && address[2] == 4) {
            //IQ2 timezone
            saveRequest.m_level = 50;
            saveRequest.m_communicNum = 506;
            comm.m_sendToPanel = events.reduce((dayToSend, event) => {
                let indexToUpdate = (event.getIn(['day'], 0)) < 0 ? 6 : event.getIn(['day'], 0);
                if (event.get('dirty')) {
                    if (event.get('type') == 'standard'){
                        indexToUpdate += 7;
                    }
                    dayToSend[indexToUpdate] = 1;
                }
                return dayToSend;
            }, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            comm.m_day = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
            comm.m_warmUpLimit = 0;
            comm.m_coolDownLimit = 0;

            events.sort((a, b) => {
                return a.getIn(['starttimeindex']) - b.getIn(['starttimeindex']);
            }).forEach((event, index) => {
                let dow = event.getIn(['day'], 0);
                if (dow < 0) {
                    dow = 6;
                }
                if (event.get('type') == 'standard'){
                    dow += 7;
                }
                let alreadyProcessed = false;
                for (var i = 1; i < 4; i++){
                    if (!comm.m_day[dow][`bH${i}`] && !alreadyProcessed){
                        comm.m_day[dow][`bH${i}`] = (parseInt(event.get('startindouble')));
                        comm.m_day[dow][`bM${i}`] = Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5;
                        comm.m_day[dow][`eH${i}`] = (parseInt(event.get('endindouble')));
                        comm.m_day[dow][`eM${i}`] = Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5;
                        alreadyProcessed = true;
                    }
                }
            });
            
        }
        if (address[1] == 5 && address[2] == 12) {
            //IQ3 timezone
            saveRequest.m_level = 50;            
            saveRequest.m_communicNum = 508;
            comm.m_sendToPanel = events.reduce((dayToSend, event) => {
                if (event.get('dirty')) {
                    dayToSend[event.getIn(['day'])] = 1;
                }
                return dayToSend;
            }, [0, 0, 0, 0, 0, 0, 0]);
            comm.m_prds = [[], [], [], [], [], [], []];

            events.filter((event) => !event.get('removed')).sort((a, b) => {
                return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
            }).forEach((event) => {              
                let dow = event.getIn(['day'], 0);

                comm.m_prds[dow].push({
                    h: (parseInt(event.get('startindouble'))),
                    m: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                    v: 1});

                comm.m_prds[dow].push({
                    h: (parseInt(event.get('endindouble'))),
                    m: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5,
                    v: 0});
            });
        }

        if (address[1] == 9 && address[2] == 17) {
            saveRequest.m_level = 150;            
            saveRequest.m_communicNum = 25;
            comm.m_prds = [[], [], [], [], [], [], []];
            let eventsToProcess =  events.filter((event) => !event.get('removed')).sort((a, b) => {
                return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
            });
            let lastProcessedEvent = undefined;
            eventsToProcess.forEach((event) => {              
                let dow = event.getIn(['day'], 0);
                let onlyProcessEndTime = false;
                if(lastProcessedEvent && ((lastProcessedEvent.get('day') + 1) % 7) == event.get('day')) {   
                    if(event.get('startindouble') == 0) {
                        comm.m_prds[dow].push({
                            h: 0,
                            m: 0,
                            v: 1
                        });
                        onlyProcessEndTime = true;
                    } else {
                        comm.m_prds[dow].push({
                            h: 0,
                            m: 0,
                            v: 0
                        });
                    }
                    lastProcessedEvent = undefined;
                }
                if(!onlyProcessEndTime) {
                    comm.m_prds[dow].push({
                        h: (parseInt(event.get('startindouble'))),
                        m: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                        v: 1
                    });
                }
                if(event.get('endindouble') == 24) {
                    lastProcessedEvent = event;
                    return;
                }
                comm.m_prds[dow].push({
                    h: (parseInt(event.get('endindouble'))),
                    m: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5,
                    v: 0});
            });
            if(lastProcessedEvent) {
                let dow = (lastProcessedEvent.getIn(['day'], 0) + 1) % 7;
                comm.m_prds[dow].push({
                    h: 0,
                    m: 0,
                    v: 0
                });
            }
            comm.m_pointGroupIds = [];
        }

        if (address[1] == 11 ) {
            //Cylon timezone
            saveRequest.m_level = 120;
            saveRequest.m_communicNum = 1;
            comm.m_scheduleTimes = [];

            // console.log('Events : ', events);

            events.filter((event) => !event.get('removed')).sort((a, b) => {
                return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
            }).forEach((event) => {              
                let dow = event.getIn(['day'], 0);

                comm.m_scheduleTimes.push({
                    m_bHr: (parseInt(event.get('startindouble'))),
                    m_bMn: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                    m_eHr: (parseInt(event.get('endindouble'))),
                    m_eMn: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5});
            });
        }

        // console.log("WEB_MESSAGE_REQ saveRequest", saveRequest);
        // console.log("WEB_MESSAGE_REQ comm", comm);

        ServiceBus.send('WEB_MESSAGE_REQ', saveRequest, comm);
    }

    bacnetHandler(msg) {
        // console.log("this is what i get back", msg);
        // console.log("message: ", msg);
        // console.log(msg)
        let events = [];

        let originalPeriods = msg.m_prds;

        
        const convertPeriods = (periods) => {
            let newPeriods = [[], [], [], [], [], [], []];
            for(let dow = 0; dow < periods.length; dow++) {
                let currentDay = periods[dow];
                let lastRecord = currentDay[currentDay.length - 1];
                let firstRecord = currentDay[0];
                if(!firstRecord) {
                    continue;
                }
                if(firstRecord.v == 0 && firstRecord == lastRecord) {
                    newPeriods[dow] = [];
                    continue;
                } else if(firstRecord.v == 0 && firstRecord.m == 0 && firstRecord.h == 0) {
                    newPeriods[(dow + 6) % periods.length].push({
                        h: 24,
                        m: 0,
                        v: 1
                    });
                    newPeriods[dow].push(...currentDay.filter(x => x != firstRecord && x != lastRecord));
                } else if(firstRecord.v == 0) {
                    newPeriods[dow].push({
                        h: 0,
                        m: 0,
                        v: 1
                    });
                    newPeriods[dow].push(...currentDay.filter(x => x != firstRecord && x != lastRecord));
                } else {
                    newPeriods[dow].push(...currentDay.filter(x => x != lastRecord));
                }
                if(lastRecord.v == 1) {
                    newPeriods[dow].push(lastRecord);
                    newPeriods[dow].push({
                        h: 24,
                        m: 0,
                        v: 0
                    });
                } else {
                    newPeriods[dow].push(lastRecord);
                }
            }
            return newPeriods;

        };

        let periods = convertPeriods(originalPeriods);
        // console.log("bacnet converted periods", periods);

        for (let dow = 0; dow < periods.length; dow++) {
            let dayProfile = periods[dow];

            let length = dayProfile.length;

            // console.log('dayprofile', dayProfile);

            if (length % 2) length--;

            for (let period = 0; period < length; period += 2) {
                let startInDouble = dayProfile[period].h + ((Math.round(dayProfile[period].m / 5) * 5) / 60);
                let endInDouble = dayProfile[period + 1].h + ((Math.round(dayProfile[period + 1].m / 5) * 5) / 60);
                events.push({
                    id: dow + "-" + period,
                    day: dow,
                    start: Math.floor(startInDouble * 2),
                    end: Math.floor(endInDouble * 2),
                    startindouble: startInDouble,
                    endindouble: endInDouble,
                    starttimeindex: dow * 24 + startInDouble,
                    type: 'current'
                });
            }
        }

        this.setState({
            events: Immutable.fromJS(events),
            bacnetScheduleDefault: msg.m_scheduleDefault || '',
            dirty: false
        });
    }

    setBacnetTimezoneComplete(message) {
        if (message.m_errorNumber != 0) {
            swal.fire("Error", `Error number ${message.m_errorNumber}<br />${(message.m_responseText || []).join("<br />")}`, "error");
        }
        else {
            swal.fire("Success", `The new schedule have been saved to the panel<br /><br />${(message.m_responseText || []).join("<br />")}`, "success");

            this.setState(({ events }) => ({
                events: events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }),
                dirty: false
            }));
        }
        this.requestTimezones();
    }

    setTrendIQ3TimezoneComplete(message) {
        if (message.m_errorNumber != 0) {
            swal.fire("Error", `Error number ${message.m_errorNumber}`, "error");
        }
        else {
            swal.fire("Success", "The new timezones have been saved to the panel", "success");

            this.setState(({ events }) => ({
                events: events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }),
                dirty: false
            }));
        }
        this.requestTimezones();
    }

    setTrendIQ2TimezoneComplete(message){
        if (message.m_errorNumber != 0) {
            swal.fire("Error", `Error number ${message.m_errorNumber}`, "error");
        }
        else {
            swal.fire("Success", "The new timezones have been saved to the panel", "success");

            this.setState(({ events }) => ({
                events: events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }),
                dirty: false
            }));
        }
        this.requestTimezones();
    }

    setCylonUCC4TimezoneComplete(message) {
        let parsedMessage = JSON.parse(message);
        if (parsedMessage.m_errorNumber != 0) {
            swal.fire("Error", `Error number ${parsedMessage.m_errorNumber}`, "error");
        }
        else {
            swal.fire("Success", "The new timezones have been saved to the panel", "success");

            this.setState(({ events }) => ({
                events: events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }),
                dirty: false
            }));
        }
        this.requestTimezones();
    }



    getPointNumber(address) {
        let buffer = new ArrayBuffer(10);
        let view = new DataView(buffer);

        address.forEach((value, index) => {
            view.setUint8(index, value);
        });

        return view.getUint16(5, true);
    }

    requestTimezones() {
        let { customer, address } = this.props;
        let addressAsInt = address.map((byte) => parseInt(byte));

        let request = {
            trackMessage: true,
            m_subsystemNumber: addressAsInt[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            target: 'signalr'
        }

        if (address[1] == 5 && address[2] == 4) {
            //IQ2 timezone
            request.m_level = 50;
            request.m_communicNum = 505;

        }
        if (address[1] == 5 && address[2] == 12) {
            //IQ3 timezone
            request.m_level = 50;
            request.m_communicNum = 507;
        }

        if (address[1] == 9 && address[2] == 17) {
            //Bacnet timezone
            // console.log("Trying to retrieve bacnet timezone");
            request.m_level = 150;
            request.m_communicNum = 24;
            
        }

        if (address[1] == 11) {
            //UCC4 timezone
            request.m_level = 120;
            request.m_communicNum = 0;
        }

        let comm = {};

        if (address[1] == 5) {
            comm.m_lanIndex = addressAsInt[3];
            comm.m_outstationNumber = addressAsInt[4];
            comm.m_zoneNumber = this.getPointNumber(addressAsInt);
        }

        if (address[1] == 11) {
            comm.m_pointAddr = address.map((value) => { return parseInt(value); });
        }

        if(address[1] == 9 && address[2] == 17) {
            comm.m_pointAddr = address.map((value) => { return parseInt(value); });
        }

        // console.log("request", request);

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    timezoneSelected(start, end) {
        //0 = Monday 00:00
        // console.log("timezone selected ", start, end);
        end = end + 1;

        let hoursstart = start * 0.5;
        let hoursend = end * 0.5;

        let starttimeHours = hoursstart % 24;
        let endtimeHours = hoursend % 24;

        let startDay = (hoursstart - starttimeHours) / 24;
        let endDay = (hoursend - endtimeHours) / 24;

        if (endDay > startDay) {
            end = 48;
        }

        this.setState({
            selectedtimezone: Immutable.Map({
                day: (start - (start % 48)) / 48,
                start: start % 48,
                end: (end % 48) < (start % 48) ? 48 : (end % 48),
                startindouble: starttimeHours,
                endindouble: endtimeHours,
                starttimeindex: ((start - (start % 48)) / 48) * 24 + starttimeHours,
                dirty: true
            }),
            selectedtimezoneindex: -1
        });
    }

    editTimezone(toedit, index) {
        this.setState({
            selectediq2timezone: toedit,
            selectedtimezoneindex: index
        });
    }

    removeEvent(index) {
        this.setState(({ events }) => ({
            events: events.setIn([index, 'removed'], true).setIn([index, 'dirty'], true),
            dirty: true
        }))
    }

    onDayAction(day, action) {
        let dayIndex = day - 1;
        switch(action) {
            case 'onAllDay': this.setTimezoneToAllDay(dayIndex); break;
            case 'offAllDay': this.setTimezoneToOffAllDay(dayIndex); break;
            case 'copyDay': this.copyDay(dayIndex); break;
            case 'pasteDay': this.pasteDay(dayIndex); break;
            case 'repeatOnAllDays': this.repeatOnAllDays(dayIndex); break;
            case 'selectTemplate': 
            case 'selectWeeklyTemplate': 
                this.setState({
                    isSelectedDayTemplate: day
                })
            break;
            default:
        }
    }

    copyDay(day) {
        let { events } = this.state;
        this.setState({
            copiedEvents: events.filter(x => x.get('day') == day)
        })
    }

    pasteDay(day) {
        let { events, copiedEvents } = this.state;
        if(copiedEvents) {
            events = events.map(x => {
                if(x.get('day') == day) {
                    x = x.set("removed", true);
                    x = x.set("dirty", true);
                }
                return x;
            });
            copiedEvents.forEach((copiedEvent) => { 
                copiedEvent = copiedEvent.set('day', day);
                copiedEvent = copiedEvent.set('starttimeindex', (day * 24) + copiedEvent.get('starttimedouble'));
                copiedEvent = copiedEvent.delete('id');
                events = events.push(copiedEvent)
            })
            this.setState({
                events: events,
                dirty: true
            })
        }
    }

    repeatOnAllDays(day) {
        let { events } = this.state;
        let dayEvents = events.filter(x => x.get('day') == day && !x.get('removed'));
        events = events.map((event) => {
            if(event.get('day') != day) {
                event = event.set('removed', true);
                event = event.set('dirty', true);
            }
            return event;
        });
        for(let i = 0; i < 7; i++) {
            if(i != day) {
                events = events.merge(dayEvents.map((event) => {
                    event = event.set('day', i);
                    event = event.set('starttimeindex', (i * 24) + event.get('starttimedouble'));
                    event = event.delete('id');
                    event = event.set('dirty', true);
                    return event;
                }))
            }
        }
        this.setState({ 
            dirty: true,
            events: events
        })
    }

    setTimezoneToAllDay(day) {
        let { events } = this.state;
        // console.log("selectedday", day);
        // console.log("events", events);
        events = events.map(event => {
            if(event.get('day') == day) {
                event = event.set('removed', true).set('dirty', true);
            }
            return event;
        })
        events = events.push(Map({
            day: day,
            dirty: true,
            end: 48,
            endindouble: 24,
            start: 0,
            startindouble: 0,
            starttimeindex: day * 24,
            type: "current"
        }))
        this.setState({
            events: events,
            dirty: true
        })
    }

    setTimezoneToOffAllDay(day) {
        let { events } = this.state;
        events = events.map(event => {
            if(event.get('day') == day) {
                event = event.set('removed', true).set('dirty', true);
            }
            return event;
        });
        this.setState({
            events: events,
            dirty: true
        })
    }

    saveTimezone(timezone) {
        // console.log('saving: ' + timezone);
        let { selectedtimezoneindex } = this.state;

        //Find index nearest to time.
        let starttime = parseFloat(timezone.get('startindouble'));
        let endtime = parseFloat(timezone.get('endindouble'));

        let startindex = (starttime - (starttime % 0.5)) * 2;
        let endindex = (endtime - (endtime % 0.5)) * 2;

        if (selectedtimezoneindex < 0) {
            //New
            timezone = timezone.set('start', startindex).set('end', endindex);
            
            

            return this.setState(({ events }) => {
                let convertedEvents = this.convertEvents(events);
                let newEvents = this.convertEvents(List().push(timezone));
                let convertedTimezone = newEvents.first();
                convertedEvents = this.removeCollidingElements(convertedTimezone, convertedEvents);
                convertedEvents.forEach((e,i) => {
                    if(e.get('isDeleted')) {
                        events = events.update(i, x => x.set('removed', true).set('dirty', true))
                    }
                })
                return ({
                    events: events.push(timezone),
                    dirty: true,
                    selectedtimezone: undefined
                })
            });
        }
        return this.setState(({ events }) => { 
            
            let originalEvent = events.get(selectedtimezoneindex);
            originalEvent = originalEvent.set('start', startindex).set('end', endindex).set('startindouble', starttime).set('endindouble', endtime).set('dirty', true);
            let convertedEvents = this.convertEvents(events);
            let newEvents = this.convertEvents(List().push(timezone));
            let convertedTimezone = newEvents.first();
            convertedEvents = this.removeCollidingElements(convertedTimezone, convertedEvents);
            convertedEvents.forEach((e,i) => {
                if(e.get('isDeleted')) {
                    events = events.update(i, x => x.set('removed', true).set('dirty', true))
                }
            })

            return ({
                events: events.set(selectedtimezoneindex, originalEvent),
                dirty: true,
                selectedtimezone: undefined
            })
        });
    }

    clearSelection() {
        this.setState({
            selectedtimezone: undefined,
            selectediq2timezone: undefined
        });
    }

    display(name) {
        this.setState({
            displaying: name
        });
    }

    onDayActionAdapter(day, action) {
        // console.log("day", day);
        // console.log("action", action)
        this.onDayAction(day + 1, action);
    }

    onModifyRequestedAdapter(event) {
        let { events } = this.state;
        // console.log("modify requested adapter");
        // console.log("event", event);
        // console.log("events", events);
        let e = events.get(event.get('$originalIndex'));

        this.setState({
            selectedPeriod: event
        })

        // this.editTimezone(e, event.get('$originalIndex'))
    }

    onRemoveMultipleAdapter(toRemove) {
        this.setState(({ events }) => { 
            toRemove.forEach((event) => {
                let index = event.get(['$originalIndex']);
                events = events.setIn([index, 'removed'], true).setIn([index, 'dirty'], true);
            })
            
            return ({
                events:events,
                dirty: true
            })
        })
    }

    onRemoveAdapter(event) {
        let index = event.get('$originalIndex');
        this.removeEvent(index);
    }

    onChangeEventAdapter(event, changes) {
        let index = event.get('$originalIndex');
        let changedEvent = event.merge(changes);
        let dow = changedEvent.get('dow');
        let starttime = changedEvent.get('starttime');
        let endtime = changedEvent.get('endtime');

        let [startHours, startMinutes] = starttime.split(':');
        let [endHours, endMinutes] = endtime.split(':');

        let startindouble = Number(startHours) + (Number(startMinutes) / 60);
        let endindouble = Number(endHours) + (Number(endMinutes) / 60);

        let startindex = (startindouble - (startindouble % 0.5)) * 2;
        let endindex = (endindouble - (endindouble % 0.5)) * 2;
        
        return this.setState(({ events }) => {
            let originalEvent = events.get(index);
            originalEvent = originalEvent.set('start', startindex).set('end', endindex).set('startindouble', startindouble).set('endindouble', endindouble).set('dirty', true);
            let convertedEvents = this.convertEvents(events);

            convertedEvents = this.removeCollidingElements(changedEvent, convertedEvents);
            convertedEvents.forEach((e,i) => {
                if(e.get('isDeleted')) {
                    events = events.update(i, x => x.set('removed', true).set('dirty', true))
                }
            })

            return ({
            events: events.set(index, originalEvent),
            dirty: true,
            selectedtimezone: undefined
        })});
    }

    onRangeSelectedAdapter(range) {
        // console.log("on adapater range selected", range);
        range = range.sort((a,b) => a.get('dow') - b.get('dow'));
        let firstEvent = range.first();
        let lastEvent = range.last();
        let start = (firstEvent.get('dow') * 48) + Math.round(firstEvent.get('startindex') / 30);
        let end = (lastEvent.get('dow') * 48) + Math.round((lastEvent.get('endindex') - 30) / 30)
        
        // console.group('%c onRangeSelectedAdapter', 'color:yellow');
        // console.log("Passed range", range);
        // console.log("firstEvent", firstEvent);
        // console.log("lastEvent", lastEvent);
        // console.log("start", start);
        // console.log("end", end);
        // console.groupEnd();
        // console.log("selected range", range);
        let formattedEvents = List();
        range.forEach((block) => {

            let start = (block.get('dow') * 48) + Math.round(block.get('startindex') / 30);
            let end = (block.get('dow') * 48) + Math.round((block.get('endindex') - 30) / 30);

            end = end + 1;
            // console.log("block end", end);

            let hoursstart = start * 0.5;
            let hoursend = end * 0.5;

            let starttimeHours = hoursstart % 24;
            let endtimeHours = hoursend % 24;
            
            let startDay = (hoursstart - starttimeHours) / 24;
            let endDay = (hoursend - endtimeHours) / 24;

            // console.log(`block, startDay: ${startDay} endDay: ${endDay}`);

            if(endDay > startDay) {
                end = 48;
                endtimeHours = 24
            }

            formattedEvents = formattedEvents.push(Map({
                day: (start - (start % 48)) / 48,
                start: start % 48,
                end: (end % 48) < (start % 48) ? 48 : (end % 48),
                startindouble: starttimeHours,
                endindouble: endtimeHours,
                starttimeindex: ((start - (start % 48)) / 48) * 24 + starttimeHours,
                dirty: true
            }))

        });

        this.setState(({ events }) => {
            let convertedEvents = this.convertEvents(events);

            // console.log("state of events before mutation", events);
            // console.log("state of formattedEvents", formattedEvents);

            formattedEvents = formattedEvents.forEach((evt) => {
                let starttime = parseFloat(evt.get('startindouble'));
                let endtime = parseFloat(evt.get('endindouble'));
                let startindex = (starttime - (starttime % 0.5)) * 2;
                let endindex = (endtime - (endtime % 0.5)) * 2;
                evt = evt.set('start', startindex).set('end', endindex);

                let newEvents = this.convertEvents(List().push(evt));
                let convertedTimezone = newEvents.first();
                convertedEvents = this.removeCollidingElements(convertedTimezone, convertedEvents);
                convertedEvents.forEach((e,i) => {
                    if(e.get('isDeleted')) {
                        events = events.update(i, x => x.set('removed', true).set('dirty', true))
                    }
                })

                events = events.push(evt)
    
            })

            // console.log("state of events", events);

            return ({
                events: events,
                dirty: true,
                selectedtimezone: undefined
            })
        })


        

       


        // this.timezoneSelected(start, end);
    }
    
    removeCollidingElements = (event, periods) => {
        const getIndexFromTime = (time) => {
            let [startHours, startMinutes] = time.split(':');
            return Number(startHours) * 60 + Number(startMinutes);
        }
        return periods.map((x) => {
            if(event == x) {
                return x;
            }

            let currentStart = getIndexFromTime(event.get('starttime'));
            let currentEnd = getIndexFromTime(event.get('endtime'));
            let comparingStart = getIndexFromTime(x.get('starttime'));
            let comparingEnd = getIndexFromTime(x.get('endtime'));


            let sameDay = x.get('dow') == event.get('dow');
            let startIsInOtherEventRange = comparingStart >= currentStart && comparingStart < currentEnd;
            let endIsInOtherEventRange = comparingEnd < currentEnd && comparingEnd > currentStart;
            if(sameDay && (startIsInOtherEventRange || endIsInOtherEventRange)) {
                return x.set('isDeleted', true);
            }
            return x; 
        })

    }

    convertEvents(events) {
        return events.map((event, index) => {

            let startttime = convertDoubleToTimeString(event.get('startindouble'));
            let endtime = convertDoubleToTimeString(event.get('endindouble'));
            event = event.set('starttime', startttime);
            event = event.set('endtime', endtime);
            event = event.set('dow', event.get('day'));
            event = event.set('startindex', event.get('start'));
            event = event.set('endindex', event.get('end'));
            event = event.set('$originalIndex', index);
            return event;
        })
    }

    timeRangeToInfo(starttime, endtime) {
        let [startHours, startMinutes] = starttime.split(':');
        let [endHours, endMinutes] = endtime.split(':');
        let startindouble = Number(startHours) + (Number(startMinutes) / 60);
        let endindouble = Number(endHours) + (Number(endMinutes) / 60);
        let startindex = (startindouble - (startindouble % 0.5)) * 2;
        let endindex = (endindouble - (endindouble % 0.5)) * 2;
        return [startindex, endindex, startindouble, endindouble];
    }

    modifyPeriodDialogOnSave(firstPeriod, secondPeriod) {
        let index = firstPeriod.get('$originalIndex');
        let changedEvent = firstPeriod;//.merge(changes);
        let dow = changedEvent.get('dow');
        let starttime = changedEvent.get('starttime');
        let endtime = changedEvent.get('endtime');
        let [startindex, endindex, startindouble, endindouble] = this.timeRangeToInfo(starttime, endtime);
        return this.setState(({ events }) => {
            let originalEvent = events.get(index);
            originalEvent = originalEvent.set('start', startindex).set('end', endindex).set('startindouble', startindouble).set('endindouble', endindouble).set('dirty', true);
            let convertedEvents = this.convertEvents(events);
            convertedEvents = this.removeCollidingElements(changedEvent, convertedEvents);
            if(secondPeriod) {
                convertedEvents = this.removeCollidingElements(secondPeriod, convertedEvents);
            }
            convertedEvents.forEach((e,i) => {
                if(e.get('isDeleted')) {
                    events = events.update(i, x => x.set('removed', true).set('dirty', true))
                }
            })
            if(secondPeriod) {
                let starttime = secondPeriod.get('starttime');
                let endtime = secondPeriod.get('endtime');
                let [startindex, endindex, startindouble, endindouble] = this.timeRangeToInfo(starttime, endtime);
                let newEvent = Map({
                    start: startindex, 
                    end: endindex, 
                    startindouble: startindouble, 
                    endindouble: endindouble,
                    day: secondPeriod.get('dow'),
                    starttimeindex: ((startindex - (startindex % 48)) / 48) * 24 + startindouble
                })
                events = events.push(newEvent);
            }
            return ({
            events: events.set(index, originalEvent),
            dirty: true,
            selectedtimezone: undefined
        })});
    }

    modifyPeriodDialogOnClose() {
        this.setState({
            selectedPeriod: null
        })
    }

    onApplyDayTemplate(template) {
        let { isSelectedDayTemplate, events } = this.state;
        
      
        if(template) {
            
            let dow = isSelectedDayTemplate - 1;
            // console.log("arguments", arguments);
            // console.log("day template selected", template);
            let periods = template.get('periods', List());
            let newEvents = List();
            let isWholeWeek = isSelectedDayTemplate == 0;
            
            // console.log("periods", periods)
            periods.forEach((period) => {
                let starttime = timeIndexToString60(period.get('startindex'));
                let endtime = timeIndexToString60(period.get('endindex'));
                let [startindex, endindex, startindouble, endindouble] = this.timeRangeToInfo(starttime, endtime);
                newEvents = newEvents.push(Map({
                    start: startindex, 
                    end: endindex, 
                    startindouble: startindouble, 
                    endindouble: endindouble,
                    day: isWholeWeek ? period.get('dow') : dow,
                    starttimeindex: ((startindex - (startindex % 48)) / 48) * 24 + startindouble,
                    dirty: true
                }))
            })
  
            events = events.map(x => {
                if(x.get('day') == dow || isWholeWeek) {
                    x = x.set("removed", true);
                    x = x.set("dirty", true);
                }
                return x;
            });
            
            events = events.merge(newEvents);
            // console.log("these should be the new events", events);
            this.setState({
                events: events,
                dirty: true,
                isSelectedDayTemplate: undefined
            })

        } else {
            this.setState({
                isSelectedDayTemplate: undefined
            })
        }
    }

    onCloseChooseDayTemplate() {
        this.setState({
            isSelectedDayTemplate: undefined
        })
    }

    togglePointGroupPanel() {
        this.setState({
            showPointGroupPanel: !this.state.showPointGroupPanel
        })
    }

    selectGroup(group) {
        this.setState({
            selectedGroup: group
        })
    }

    setSelectedGroups(groups) {
        this.setState({
            selectedGroups: groups
        })
    }

    saveToPoints() {
        // TODO: here comes the logic once discussed with mark.
        // console.log("save to points");
        // console.log("selected group", this.state.selectedGroup);

        let { customer, address, currentuser } = this.props;
        let { events, selectedGroup, selectedGroups } = this.state;

        // console.log("events to save to panel");
        // console.log(events);

        let subsystemNumber = parseInt(address[0]);

        let saveRequest = {
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            type: 'GENERIC',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            trackMessage: true
        };

        // m_pointAddr is the current point, but should be ignored when sending groups
        let comm = {
            m_password: this.getPin(subsystemNumber),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_pointAddr: address.map((byte) => parseInt(byte))
        };

        if (address[1] == 9 && address[2] == 17) {
            saveRequest.m_level = 150;            
            saveRequest.m_communicNum = 25;
            comm.m_prds = [[], [], [], [], [], [], []];
            let eventsToProcess =  events.filter((event) => !event.get('removed')).sort((a, b) => {
                return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
            });
            let lastProcessedEvent = undefined;
            eventsToProcess.forEach((event) => {              
                let dow = event.getIn(['day'], 0);
                let onlyProcessEndTime = false;
                if(lastProcessedEvent && ((lastProcessedEvent.get('day') + 1) % 7) == event.get('day')) {   
                    if(event.get('startindouble') == 0) {
                        comm.m_prds[dow].push({
                            h: 0,
                            m: 0,
                            v: 1
                        });
                        onlyProcessEndTime = true;
                    } else {
                        comm.m_prds[dow].push({
                            h: 0,
                            m: 0,
                            v: 0
                        });
                    }
                    lastProcessedEvent = undefined;
                }
                if(!onlyProcessEndTime) {
                    comm.m_prds[dow].push({
                        h: (parseInt(event.get('startindouble'))),
                        m: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                        v: 1
                    });
                }
                if(event.get('endindouble') == 24) {
                    lastProcessedEvent = event;
                    return;
                }
                comm.m_prds[dow].push({
                    h: (parseInt(event.get('endindouble'))),
                    m: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5,
                    v: 0});
            });
            if(lastProcessedEvent) {
                let dow = (lastProcessedEvent.getIn(['day'], 0) + 1) % 7;
                comm.m_prds[dow].push({
                    h: 0,
                    m: 0,
                    v: 0
                });
            }



            comm.m_pointGroupIds = selectedGroups.map(x => x.get('_id')).toJS();//[selectedGroup._id];

            // console.log("comm.m_pointGroupsIds", comm.m_pointGroupIds);
        }

        // console.log("selectedGroup", selectedGroup);
        // console.log("saveRequest", saveRequest);
        // console.log("comm", comm);

        

        ServiceBus.send('WEB_MESSAGE_REQ', saveRequest, comm);

    }

    render() {
        let { dirty, isIQ2, displaying, selectedPeriod, isSelectedDayTemplate, selectediq2timezone, showPointGroupPanel, selectedGroup, bacnetScheduleDefault } = this.state || {};
        let { customer, selectedpoint, address } = this.props;

        let convertedEvents = this.convertEvents(this.state.events);

        // only show groups for bacnet schedules
        let showGroups = address && address[1] == 9 && address[2] == 17;
        
        
        // console.log(this.props);

        return (
            <div className="flex flex-row">
                { showGroups && <PointGroupPanel 
                    onSelect={this.setSelectedGroups.bind(this)} 
                    address={address} 
                    customer={customer} 
                    selectedpoint={selectedpoint} 
                    selectedGroups={this.state.selectedGroups || List()}
                    opened={showPointGroupPanel} className="" onToggle={this.togglePointGroupPanel.bind(this)}>
                    { this.state.selectedGroups && this.state.selectedGroups.size > 0 && <div>
                        <a className="rounded-md px-3 py-2 bg-green text-white hover:bg-green-dark float-right cursor-pointer" onClick={this.saveToPoints.bind(this)}>Save group points to panel</a>
                    </div> }
                </PointGroupPanel> }
                <div className="flex-1">
                    <DayTemplateSelectionDialog 
                        showWeek={isSelectedDayTemplate === 0}
                        customer={customer} 
                        isOpened={isSelectedDayTemplate > -1} 
                        onSave={this.onApplyDayTemplate.bind(this)} 
                        onClose={this.onCloseChooseDayTemplate.bind(this)} /> 
                    <ModifyPeriodDialog
                        tabindex={1}
                        isOpened={!!selectedPeriod}
                        onSave={this.modifyPeriodDialogOnSave.bind(this)}
                        period={selectedPeriod}
                        onClose={this.modifyPeriodDialogOnClose.bind(this)} />

                    <TimezoneDialog selecteditem={selectediq2timezone} onClose={this.clearSelection.bind(this)} onSave={this.saveTimezone.bind(this)} />
                    <div className="px-2 py-1">
                        {isIQ2 && <>
                            <h2 className="mb-4">Displaying {displaying} week.</h2>
                        </>}
                        { !isIQ2 && <WeekTimes
                            extraActions={['selectWeeklyTemplate']}
                            onDayAction={this.onDayActionAdapter.bind(this)} 
                            onModifyRequested={this.onModifyRequestedAdapter.bind(this)} 
                            events={convertedEvents.filter(x => !x.get('removed'))} 
                            onRemoveMultiple={this.onRemoveMultipleAdapter.bind(this)} 
                            onRemove={this.onRemoveAdapter.bind(this)} 
                            onChangeEvent={this.onChangeEventAdapter.bind(this)} 
                            onRangeSelected={this.onRangeSelectedAdapter.bind(this)} /> ||

                            <Calendar 
                                onDayAction={this.onDayAction.bind(this)}
                                onSelect={this.timezoneSelected.bind(this)} 
                                events={this.state.events} render={(props) => {
                                let { colheight, colwidth, events } = props;

                                if (!events) {
                                    return <data></data>
                                }

                                return events.map((event, index) => {
                                    let inbetween = event.get('end') - event.get('start');
                                    let starttime = convertDoubleToTimeString(event.get('startindouble'));
                                    let endtime = convertDoubleToTimeString(event.get('endindouble'));

                                    if (displaying != event.get('type')) {
                                        return (
                                            <div className={`event ${event.get('removed') && 'removed' || ''}`} style={{
                                                backgroundColor: event.get('type') == 'current' ? '#0088cc' : 'yellow',
                                                width: `${colwidth * 0.95}px`,
                                                height: `${inbetween * colheight}px`,
                                                marginTop: `${(event.get('start') * colheight)}px`,
                                                marginLeft: `${(event.get('day')) * colwidth}px`,
                                                zIndex: 0
                                            }}></div>
                                        )
                                    }

                                    return (
                                        <div className={`event ${event.get('removed') && 'removed' || ''}`} style={{
                                            backgroundColor: event.get('type') == 'current' ? '#0088cc' : 'yellow',
                                            width: `${colwidth * 0.8}px`,
                                            height: `${inbetween * colheight}px`,
                                            marginTop: `${(event.get('start') * colheight)}px`,
                                            marginLeft: `${(event.get('day')) * colwidth}px`,
                                            zIndex: 1
                                        }} onDoubleClick={this.editTimezone.bind(this, event, index)}>
                                            <div className="flex flex-row">
                                                <span className="title flex-grow">{starttime} - {endtime}</span>
                                                <a className="inline-block px-2 bg-red text-blue hover:bg-red-light" onClick={this.removeEvent.bind(this, index)}>x</a>
                                            </div>
                                        </div>
                                    );
                                })
                            }} />

                        }
                        {isIQ2 && <div className="px-2 py-1 flex-row">
                            <div className="grid grid-cols-16 mt-4">
                                <div className="col-span-12 md:col-span-5 md:offset-6">
                                    <div className="grid grid-cols-16 gap-4" onClick={this.display.bind(this, 'current')}>
                                        <div className="col-span-4 md:col-span-2 px-4 py-3" style={{ backgroundColor: '#0088cc' }}></div>
                                        <div className="col-span-8 md:col-span-10 text-sm">Display current week</div>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-5 md:offset-6 mt-2">
                                    <div className="grid grid-cols-16  gap-2" onClick={this.display.bind(this, 'standard')}>
                                        <div className="col-span-4 md:col-span-2 text-white px-4 py-3" style={{ backgroundColor: 'yellow' }}></div>
                                        <div className="col-span-8 md:col-span-8 text-sm">Display standard week</div>
                                    </div>

                                </div>
                            </div>


                        </div>}
                    </div>
                    <div className="box px-4 py-3 mt-8 mb-8 flex clear-both items-center">
                        <div className="flex-1 text-center">
                            {bacnetScheduleDefault && <div>Default value: {bacnetScheduleDefault}</div> }
                        </div>
                        <div>
                            {dirty && <a className="rounded-md px-3 py-2 bg-green text-white hover:bg-green-dark float-right cursor-pointer" onClick={this.saveToPanel.bind(this)}>Save to panel</a>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function timeIndexToString60(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

export default Timezone;