import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { fromJS, List, OrderedMap } from "immutable";
import { useStateIfMounted } from "use-state-if-mounted";
import { getGroups } from "../Configuration/Actions";
import debuggerService from "../../../Services/DebuggerService";

const logger = debuggerService.getDebugger("Alarm Filter Groups", "gold");

const AlarmFilterGroupsContext = React.createContext({});

export function useAlarmFilterGroups() {
    return useContext(AlarmFilterGroupsContext);
}

export default function AlarmFilterGroupsProvider({ children, customer }) {
    const [alarmFilterGroups, setAlarmFilterGroups] = useStateIfMounted(OrderedMap());
    
    const refreshAlarmFilterGroups = async () => {

        const customerUrl = customer.get('name').toLowerCase().replace(/ /g, "");
        const ipAddress = customer.get('ipaddress').toLowerCase();
        let data = await getGroups(`${customerUrl}`,`${ipAddress}`,'alarmfiltergroups')
        logger.log("Alarm Filter Groups", data);
        let filterList = fromJS(data);
        filterList = filterList.reduce((groupedList, group) => {
            return groupedList.set(group.get('_id'), group);
        }, OrderedMap())
        setAlarmFilterGroups(fromJS(filterList));
    }

    useEffect(() => {

        // logger.log("Customer", customer);
        (async (customer) => {
            if(customer) {
                logger.log("AlarmFilterGroups Provider Executed")
                await refreshAlarmFilterGroups();
            }
        
        })(customer)
    }, [customer]);

    const value = useMemo(() => ({ alarmFilterGroups, refreshAlarmFilterGroups }), [
        alarmFilterGroups
    ]);

    logger.log("AlarmFilterGroups Rendered", alarmFilterGroups);

    return (
        <AlarmFilterGroupsContext.Provider value={value}>
            {children}
        </AlarmFilterGroupsContext.Provider>
    );
}