import io from 'socket.io-client';
import EventEmitter from './EventEmitter';

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

class ServiceBus{

    // i should add an auth method that would disconnect, an reconnect the socket when an auth request is made.
    async authenticate(token) {
        // console.log(`1309 trying to authenticate with token ${token}`);
        // console.log(`1309 currenytly authenticated ${this.authenticated}`)
        if(!this.authenticated && token) {
            // console.log("1309 trying to set auth on token");
            this.socket.auth.token = token;
            // console.log("1309 im trying to disconnect and connect");
            this.socket.disconnect().connect();
        } 
    }

    registerListeners(socket) {


        this.socket.on('WHAT_ARE_YOU', (message) => {

            // maybe only listen when authorized in the first place.

            if(this.authenticated) {
                this.myId = message;
                const d = new Date();
                // console.log('what are you received @ : ',d.toTimeString(), message);

                this.socket.emit('WEB_CLIENT_RESPONSE');

               

                this.events['REGISTER_FOR_ALARMS'].emit(message);

                this.socket.on('disconnect', (data) => {
                    let ms = Date.now();
                    const d = new Date();
                    // console.log('disconnect occured. Reason : ',d.toTimeString(), data);

                    this.socket.removeListener('WEB_MESSAGE_RESP');
                    this.socket.removeListener('SUBSYSTEM_STATUS_RESP');
                    this.socket.removeListener('CONNECTED_USERS_RESP');
                    this.socket.removeListener('BROADCAST_ALARM');
                    this.socket.removeListener('disconnect');
                });

                this.socket.on("SUBSYSTEM_STATUS_RESP", (message) => {
                    if (this.events['SUBSYSTEM_STATUS_RESP']) {
                        this.events['SUBSYSTEM_STATUS_RESP'].emit(message);
                    }    
                });

                this.socket.on("CONNECTED_USERS_RESP", (message) => {
                    if (this.events['CONNECTED_USERS_RESP']) {
                        this.events['CONNECTED_USERS_RESP'].emit(message);
                    } 
                });

                // window.emitMessage = (message) => {
                //     if (message.m_communicNum == 1 && message.m_level == 12){
                //         if (this.events['BROADCAST_ALARM']){
                //             this.events['BROADCAST_ALARM'].emit(message);
                //         }
                //     }
                // }
                this.socket.on('API_MESSAGE', (message) => {
                    // console.log("INCOMING API MESSAGE");
                    if (this.events['API_MESSAGE']){
                        this.events['API_MESSAGE'].emit(message);
                    }
                });

                this.socket.on('BROADCAST_ALARM', (response) => {
                    let message = JSON.parse(response);

                    if (message.m_communicNum == 1 && message.m_level == 12){
                        if (this.events['BROADCAST_ALARM']){
                            this.events['BROADCAST_ALARM'].emit(message);
                        }
                    }
                });  

                this.socket.on('WEB_MESSAGE_RESP', (response) => {
                    let message = JSON.parse(response);

                    if (message.type && this.events[message.type]){
                        this.events[message.type].emit(message);
                    }

                    if (this.events[message.m_communicNum + '-' + message.m_level]){
                        this.events[message.m_communicNum + '-' + message.m_level].emit(message);
                    }

                    else if (message.m_communicNum == 510 && message.m_level == 51){
                        if (this.events['GET_PANEL_TYPE_REVISION_MSG']){
                            this.events['GET_PANEL_TYPE_REVISION_MSG'].emit(message);
                        }
                    }
                    else if (message.m_communicNum == 20 && message.m_level == 61){
                        if (this.events['GET_TIME_PROGRAM_NAMES_RESPONSE']){
                            this.events['GET_TIME_PROGRAM_NAMES_RESPONSE'].emit(message);
                        }
                    }
                    else if (message.m_communicNum == 21 && message.m_level == 61){
                        if (this.events['GET_TIME_PROGRAM_DAILY_SCHEDULE_NAMES_RESPONSE']){
                            this.events['GET_TIME_PROGRAM_DAILY_SCHEDULE_NAMES_RESPONSE'].emit(message);
                        }
                    }
                    else if (message.m_communicNum == 22 && message.m_level == 61){
                        if (this.events['GET_TIME_PROGRAM_WEEKLY_SCHEDULE_INDEXES_RESPONSE']){
                            this.events['GET_TIME_PROGRAM_WEEKLY_SCHEDULE_INDEXES_RESPONSE'].emit(message);
                        }
                    }
                    else if (message.m_communicNum == 23 && message.m_level == 61){
                        if (this.events['GET_TIME_PROGRAM_DAILY_SCHEDULE_DETAILS_RESPONSE']){
                            this.events['GET_TIME_PROGRAM_DAILY_SCHEDULE_DETAILS_RESPONSE'].emit(message);
                        }
                    }
                    else if (message.m_communicNum == 21 && message.m_level == 151){
                        if (this.events['BACNET_GET_POINT_DETAIL_INFO_RESPONSE']){
                            this.events['BACNET_GET_POINT_DETAIL_INFO_RESPONSE'].emit(message);
                        }
                    }
                    else{
                        
                    }
                });
            }
            


        });
    }

    setContextCallback(callback) {
        this.contextCallback = callback;
        if(this.authenticated && this.contextCallback) {
            this.contextCallback(true, null);
        }
    }

    constructor(){
        this.authenticated = false;
        let socketOptions = {
            auth: {
                token: null
            }
        };
        let socketUrl = getCookie('socketUrl') || `${window.location.protocol}//${window.location.host}/`;
        if (window.location.protocol.indexOf('https') == 0){
            socketOptions.secure = true;
        }
        
        
        this.socket = io.connect(socketUrl, socketOptions);

        const d = new Date();
        // console.log('connect ',d.toTimeString());
        this.events = {};

        this.socket.on('connect', (data) => {
            // console.log("1309 this is returned to you from connection")
            this.authenticated = true;
            if(this.contextCallback) {
                this.contextCallback(true, null);
            }
        })

        this.socket.on('connect_error', (error) => {
            // console.log("1309 this is returned to you from connect_error")
            // console.log("1309 error", error.message);
            window.lastConnectError = error;
            this.authenticated = false;
            if(this.contextCallback) {
                this.contextCallback(false, error);
            }
        })

        this.socket.on('connected', () => {
            // console.log("1309 client connected");
       
        })

        this.socket.on('disconnect', () => {
            // console.log("1309 client disconnected");
        })
        

        this.testBus = [];

            this.events['REGISTER_FOR_ALARMS'] = new EventEmitter();
//        this.targetMaps = {};

/*
        this.socket.on("reconnect_attempt", (attempt) => {
            console.log('reconnect attempt occured. Reason : ',attempt);
        });

        this.socket.on("reconnect", (attempt) => {
            console.log('reconnect occured. Reason : ',attempt);
        });
*/
        this.registerListeners(this.socket);
        
    }

    on(eventname, handler){
        if (!this.events[eventname]){
            this.events[eventname] = new EventEmitter();
        }

        // console.log("1112 ServicveBus ON eventname", eventname);
        // console.log("1112 ServiceBus listenerers ON event", this.events[eventname].listeners.length);

        return this.events[eventname].addListener(handler);
    }

    off(eventname, handler){
        // console.log("1112 ServicveBus OFF eventname", eventname);
        if (this.events[eventname]){
            this.events[eventname].removeListener(handler);
            // console.log("1112 ServicveBus OFF eventname", eventname);
            // console.log("1112 ServiceBus listenerers OFF event", this.events[eventname].listeners.length);
        }
    }

    send(type, message, comm, broadcast){
        if (!broadcast){
            message.from = this.myId;
        }
        if (message.target){
            this.socket.emit(type, message, comm);
        }

        // if(type == 'WEB_MESSAGE_REQ' && message.m_communicNum == 301 && message.m_level == 10){
        //     setTimeout(() => {
        //         console.log("setTimeout type", type);
        //         console.log("thisEvents", this.events);
                
        //         if (this.events['301-11']){
    
        //             this.events['301-11'].emit({...message, m_level: 11, m_errorNumber: 0  });
        //         }
        //     }, 3000);
        // }
    
       

    }

    keepalive() {
        this.socket.emit('keepalive');
    }
}

export default new ServiceBus();