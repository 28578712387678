import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import AreaPointService from '../BmsView/Customer/Site/Area/services/AreaPointService';

const AreaPointContext = React.createContext({});

export const _AreaPointProvider = AreaPointContext.Provider;



export function useSubscription() {
    return useContext(AreaPointContext);
}

export default function AreaPointProvider({ children, customer }) {
    
    const [subscription, setSubsription] = useStateIfMounted(undefined);

    useEffect(() => {
        if(!customer) {
            return () => {}
        } 

        const _subscription = AreaPointService.subscribe(customer);
        const { unsubscribe } = _subscription;
        setSubsription(_subscription.subscription);

        return () => {
            unsubscribe();
            setSubsription(undefined);
        }
    }, [customer])



    return <_AreaPointProvider value={subscription}>
        {children}
    </_AreaPointProvider>
}