import ReducersService from 'Services/ReducersService';
import Immutable from 'immutable';

ReducersService.registerReducer('SET_HEADER_TITLE', (state, action) => {
    return state.setIn(['header', 'title'], action.title);
});

ReducersService.registerReducer('SET_HEADER_NAVIGATION', (state, action) => {
    return state.setIn(['header', 'navigation'], Immutable.fromJS(action.navigation));
});

ReducersService.registerReducer("CUSTOMER_READY", (state, action) => {
    return state.setIn(['customer'], Immutable.Iterable.isIterable(action.customer) ? action.customer : Immutable.fromJS(action.customer));
});

ReducersService.registerReducer("CUSTOMER_USER_GROUPS_READY", (state, action) => {
    return state.setIn(['customerusergroups'], Immutable.Iterable.isIterable(action.customerusergroups) ? action.customerusergroups : Immutable.fromJS(action.customerusergroups));
});

ReducersService.registerReducer("UPDATE_HEADER_NAVIGATION", (state, action) => {
    return state.setIn(['header', 'navigation'], action.navigation);
});

ReducersService.registerReducer('CUSTOMERS_READY', (state, action) => {
    return state.setIn(['customers'], Immutable.fromJS(action.data));
});

ReducersService.registerReducer('ALL_CUSTOMERS_READY', (state, action) => {
    return state.setIn(['customers'], Immutable.fromJS(action.data));
});

ReducersService.registerReducer('UPDATE_CUSTOMER', (state, action) => {
    return state.setIn(['customer'], action.customer);
});

ReducersService.registerReducer('UPDATE_SITE', (state, action) => {
    return state.setIn(['site'], action.site);
});

ReducersService.registerReducer('TRENDING_TEMPLATES_READY', (state, action) => {
    return state.setIn(['trending'], Immutable.fromJS(action.trending));
});

ReducersService.registerReducer('POINTS_READY', (state, action) => {
    return state.setIn(['points'], Immutable.fromJS(action.points));
});

ReducersService.registerReducer('ASSETS_READY', (state, action) => {
    return state.setIn(['assets'], Immutable.fromJS(action.assets));
});

ReducersService.registerReducer('ACTIVE_EDITMODE', (state, action) => {
    return state.setIn(['editmode'], true);
});

ReducersService.registerReducer('DISABLE_EDITMODE', (state, action) => {
    return state.setIn(['editmode'], false);
});

ReducersService.registerReducer('TOGGLE_EDITMODE', (state, action) => {
    return state.setIn(['editmode'], !state.get('editmode'));
});

ReducersService.registerReducer('PLATFORM_READY', (state, action) => {
    return state.setIn(['platform'], Immutable.fromJS(action.data));
});
