import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import Configuration from 'Configuration';
import Button from 'Controls/Button/UserDefined';
import AssetDialog from 'BmsView/Customer/Assets/AssetDialog';
import Input from 'Controls/Form/Input';
import {saveAsset} from 'BmsView/Customer/Assets/Actions';

function Asset({customer, selectedpoint, address}) {
    const [asset, setAsset] = useState(undefined);
    const [selectedasset, setSelectedAsset] = useState(undefined);
   
    useEffect(() => {
        // console.log('asset');
        getAsset(address);
    }, []);


    const getAsset = async (address) => {
        const query = {pointreference: {$eq: address.join('-')}};

        // console.log(' quesry asset is ', query);

        let customername = customer.get('name').toLowerCase().replace(/ /g, "");
        let ipaddress = customer.get('ipaddress').toLowerCase();

        let selectedasset = await axios.post(`/api/assets/searchone?database=${Configuration.product}${customername}&connection=${ipaddress}`, { selector: query });

        // console.log(' asset is ', selectedasset);

        if (!selectedasset.data) {
            return;
        }

        setSelectedAsset(Immutable.fromJS(selectedasset.data));
    }

    const addEntry = () => {
        setAsset(Immutable.Map({'pointreference':address.join('-'), 'pointname':selectedpoint.getIn(['pointconfiguration', 'm_pointName'], '')}));
    }

    const editEntry = () => {
        setAsset(selectedasset);
    }

    const clearSelection = () => {
        setAsset(null);
    }

    const saveNewAsset = (newasset) => {
        saveAsset(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, newasset).then(() => {
                Swal.fire("Success", "The asset has been succesfully saved.", "success");
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while saving the asset, please contact the administrator.", "error");
            });
    }

    return(
        <div>
            <AssetDialog selecteditem={asset} onClose={() => clearSelection()} customer={customer} onSave={(asset) => saveNewAsset(asset)} />
            { selectedasset && 
                <div>
                    <div className="flex flex-row mt-2 gap-2">
                        <div className="basis-1/4">
                            <Input disabled={true} label="Site" value={selectedasset.get('site', null)}/>   
                        </div>
                        <div className="basis-1/4">
                            <Input disabled={true} label="Location" value={selectedasset.get('location', null)} />
                        </div>
                        <div className="basis-1/4">
                            <Input disabled={true} label="Component" value={selectedasset.get('component', null)}/>   
                        </div>
                        <div className="basis-1/4">
                            <Input disabled={true} label="Manufacturer" value={selectedasset.get('manufacturer', null)} />
                        </div>
                    </div>
                    <Button role="save" onClick={() => editEntry()}>Edit Entry</Button>
                </div>}
            {!selectedasset && <Button role="save" onClick={() => addEntry()}>Add Entry</Button>}
        </div>
    )
}

export default Asset;