import React from 'react';
import ServiceBus from 'Services/ServiceBus';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import ModuleTypes from 'Configuration/ModuleTypes';
import Dialog, {DialogBody, DialogFooter,  DialogContent} from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function ScanForPoints({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} >
        <ScanForPointsPanel selecteditem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}

class ScanForPointsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.processScanPointsResponse = this.processScanPointsResponse.bind(this);

        ServiceBus.on('305-11', this.processScanPointsResponse);
    }

    componentDidMount() {
        this.scan();
    }

    componentWillUnmount() {
        ServiceBus.off('305-11', this.processScanPointsResponse);
    }

    processScanPointsResponse(message) {

        if (message.m_scanFinished) {
            this.setState(({ messages }) => ({
                messages: messages.push(Immutable.Map({message: 'Scan Completed', time: Date.now() }))
            }))

            Swal.fire("Scan For Points", "Scan Completed", "success");
            return;
        }

        let pointtype = ModuleTypes[message.m_pointAddr[1]].pointtypes[message.m_pointAddr[2]].name;

        this.setState(({ messages }) => ({
            messages: messages.push(Immutable.Map({ message: pointtype + ' - ' + message.m_name, time: Date.now() }))
        }));
    }

    scan() {
        let {selectedSubsystem, selectedlan, selectedcontroller} = this.props;
        let {customer, overwrite, histories } = this.props;
       
        // scan all points - discover site
        if (selectedlan == undefined) {
            ServiceBus.send('WEB_MESSAGE_REQ', {
                m_level: 10,
                m_communicNum: 306,
                m_subsystemNumber: selectedSubsystem,
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                target: 'signalr',
                type: 'GENERIC'
            },{
                m_scanLanZero: 0,
                m_overWriteExisting: overwrite,
                m_addHistories: histories
            });
        } else {
            ServiceBus.send('WEB_MESSAGE_REQ', {
                m_level: 10,
                m_communicNum: 305,
                m_subsystemNumber: selectedSubsystem,
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                target: 'signalr',
                type: 'GENERIC'
            },{
                m_lanIndex: selectedlan,
                m_controllerNumber: selectedcontroller,
                m_overWriteExisting: overwrite,
                m_addHistories: histories
            });
        }    

        this.setState({
            messages: Immutable.List([]).push(Immutable.Map({ time: Date.now(), message: "Started scanning" }))
        });
    }


    setMessage(lan, controller) {
        let message = <h3>Please wait while we are scanning for points on lan {lan} outstation {controller}</h3>

        if (this.props.type == "bacnet") 
            message = <h3>Please wait while we are scanning for points on Bacnet Device {controller}</h3>

        if (this.props.type == "modbus") 
            message = <h3>Please wait while we are scanning for points on the Modbus Device {controller}</h3>

        if (this.props.type == "ex500") 
            message = <h3>Please wait while we are scanning for points on the CBUS for Controller {controller}</h3>
        return message;
    }

    formatDateTime(dt) {
        const yyyy = dt.getFullYear();
        let mm = dt.getMonth() + 1; // Months start at 0
        let dd = dt.getDate();
    
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
    
        let h = dt.getHours();
        let m = dt.getMinutes();
        let s = dt.getSeconds();
    
        if (h < 10) h = '0' + h;
        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        
        let formattedToday = dd + '/' + mm + '/' + yyyy + ' ' + h + ':' + m + ':' + s;
  
        return formattedToday;
    }

    render() {
        let {  messages } = this.state || {};
        let { selectedlan, selectedcontroller, onClose } = this.props;

        return (
            <DialogContent className="md:min-w-[50vw]">
                <DialogBody>
                    {this.setMessage(selectedlan,selectedcontroller)}
                    {messages && messages.sort((a, b) => b.get('time') - a.get('time')).map((message,index) => {
                        this.formatDateTime(new Date(message.get('time')));
                        return (
                            <p><strong>{ this.formatDateTime(new Date(message.get('time')))}</strong> - {message.get('message')}</p>
                        )
                    })}
                </DialogBody>
                <DialogFooter>
                    <Button role="close" onClick={onClose}>Exit</Button>
                </DialogFooter>
            </DialogContent>
        );
    }
}

export default ScanForPoints;