import React from 'react';
import Immutable from 'immutable';

import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import Input from 'Controls/Form/Input';

import PointGroupSelectionDialog from './PointGroupSelectionDialog';

function UserGroupEditDialog({ selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <UserGroupEditDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class UserGroupEditDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openPointGroupSelectionDialog: false,
            currentgroup: this.props.selecteditem,
            selectedpointgroups: null
        }

        this.onSave = this.onSave.bind(this);

        // console.log('UserGroupEditDialog constructor  ', this.props.selecteditem);
    }

    valueChanged(field, value){
        this.setState(({currentgroup}) => ({
            currentgroup: currentgroup.setIn(field, value)
        }));
    }

    onSave() {
        let {currentgroup} = this.state;

        let {onSave} = this.props;

        onSave(currentgroup);
    }

    pointGroupSelect() {
        let {currentgroup} = this.state;

        this.setState({
            selectedpointgroups: currentgroup.get('pointgroups', []),
            openPointGroupSelectionDialog: true
        });    
    }

    savePointGroups(groups) {
        this.setState( ({currentgroup}) => ({
            currentgroup: currentgroup.set('pointgroups', Immutable.List(groups))
        }));
    }

    savePointGroups(groups) {
        let {currentgroup} = this.state;

        let newgroups = groups.map((group) => {
            return group.get('name');
        });

        currentgroup = currentgroup.set('pointgroups', newgroups);

        // console.log('updatePointGroups(groups)', newgroups);

        this.setState( {
            currentgroup: currentgroup,
            selectedpointgroups: null, //currentgroup.get('pointgroups', []),
            openPointGroupSelectionDialog: false
        });
    }

    clearSelection() {
        this.setState({
            openPointGroupSelectionDialog: false,
            selectedpointgroups: null
        })
    }

    render() {
        let {customer, onClose} = this.props;
        let {currentgroup, openPointGroupSelectionDialog, selectedpointgroups} = this.state;
        let pointgroups = currentgroup.get('pointgroups', Immutable.List[{}])

        // console.log('UserGroupEditDialog render  ', this.props.selecteditem, currentgroup);

        return (  
            <DialogContent className="md:w-[40vw] lg:w-[40vw] xl:w-[40vw] text-left"> 
                <DialogHeader>
                    <DialogTitle>User Group Settings</DialogTitle>
                </DialogHeader>      
            
                <PointGroupSelectionDialog customer={customer} isOpened={openPointGroupSelectionDialog} selecteditem={selectedpointgroups} onClose={this.clearSelection.bind(this)} onSave={this.savePointGroups.bind(this)}/>            
                <DialogBody>
                    <div className="container-fluid">
                        <Input label="Name" value={currentgroup.get('name')} onChange={this.valueChanged.bind(this, ['name'])} />
                        <Input label="Description" value={currentgroup.get('description')} onChange={this.valueChanged.bind(this, ['description'])} />
                    </div>    
                    <div className="text-lg border-b font-medium py-1 border-grey">Point Groups</div>
                    <div className="grid grid-cols-16 mt-4">
                        {(pointgroups && (pointgroups.size != 0) && pointgroups.map((group) => {
                            return (
                                <div className="col-span-12 md:col-span-4">
                                    {group}
                                </div>
                            )
                        })) || <div className="col-span-12">No Groups Selected</div>}
                    </div>
                    <div className="px-2 mt-4">
                        <Button role="save" onClick={this.pointGroupSelect.bind(this)}>Group Selection</Button>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.onSave.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>  
        );
    }
}

export default UserGroupEditDialog;
