import axios from 'axios';
import Configuration from 'Configuration';
import Immutable from 'immutable';

export function searchPoints(customer, connection, query) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/points/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: query }).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getHistoryData(customer, connection, q) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/historydata/search?database=${Configuration.product}${customer}&connection=${connection}`, { selector: q }).then((response) => {
            resolve(response.data)
        }).catch(reject);
    });
}

export function getHistoryTemplates(customer, connection) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/historytemplates?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    })
}

export function saveTemplate(customer, connection, template) {
    let toSave = Immutable.Iterable.isIterable(template) ? template.toJS() : template;
    return new Promise((resolve, reject) => {
        if (toSave._id){
            axios.put(`/api/historytemplates/${toSave._id}?database=${Configuration.product}${customer}&connection=${connection}`, toSave).then(resolve).catch(reject);
        }
        else {
            axios.post(`/api/historytemplates?database=${Configuration.product}${customer}&connection=${connection}`, toSave).then(resolve).catch(reject);
        }
    })  
}

export function deleteTemplate(customer, connection, template) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/historytemplates/${template.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err);
        })
    })
}