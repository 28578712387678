import React from 'react';
import Immutable from 'immutable';
import Select from 'Controls/Form/Select';
import Input from 'Controls/Form/Input';
import Checkbox from 'Controls/Form/Checkbox';
import {getGroups} from './../Actions';

import Swal from 'sweetalert2';

import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';

function AlarmGroupSetupDialog({selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <AlarmGroupSetupDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}


// @Dialog({ 'width': 'xl', noContainer: false })
class AlarmGroupSetupDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedgroup: this.props.selecteditem,
            currentrecipient:  null,
            subsystemlist: null,
            groups: []
        }

        // props.setSaveHandler(this.save.bind(this));
    }

    componentDidMount() {
        let {customer} = this.props;
        let {selectedgroup} = this.state;

        let subsystems = customer.get('subsystems').map((subsystem) => {
            return Immutable.Map({
                'name' : subsystem.get('name'),
                'number' : subsystem.get('number'),
                'enabled': selectedgroup && selectedgroup.get('subsystemnumbers') && selectedgroup.get('subsystemnumbers').contains(subsystem.get('number'))
            })
        });

        this.setState({
            subsystemlist: subsystems
        });

        this.getPointGroups();
    }

    checkSubsystemSelection(subsystemnumber) {
        let {selectedgroup} = this.state;

        return selectedgroup.get('subsystemnumbers').contains(subsystemnumber);
    }

    save() {
        let { selectedgroup, subsystemlist } = this.state;

        if ( !selectedgroup.get('recipients') || selectedgroup.get('recipients').length == 0 ) {
            Swal.fire("Error", "Please specify a recipient", "error");
            return;
        }    

        let subsystemnumbers = subsystemlist.filter((subsystem) => {
            return subsystem.get('enabled')
        }).map((system) => {
            return system.get('number');
        });

        selectedgroup = selectedgroup.set('subsystemnumbers', subsystemnumbers);
        
        let { onSave } = this.props;

        onSave(selectedgroup);
    }

    changeInput(field, transform, value) {
        let { selectedgroup } = this.state;

        if (transform) {
            value = transform(value);
        }

        this.setState({
            selectedgroup: selectedgroup.setIn(field, value)
        });
    }

    changeSubsystemEnable(index) {
        let {subsystemlist} = this.state;

        let subsystem = subsystemlist.get(index);

        subsystem = subsystem.set('enabled', !subsystem.get('enabled'));

        this.setState({
            subsystemlist: subsystemlist.set(index, subsystem)
        });
    }

    currentValueChanged(value) {
        this.setState({
            currentrecipient: value
        });
    }

    addRecipient() {
        let { selectedgroup, currentrecipient } = this.state;

        if (!currentrecipient ) {
            Swal.fire("Error", "Please specify a recipient", "error");
            return;
        }    

        let recipients = selectedgroup.get('recipients', Immutable.List([]));
        recipients = recipients.push(currentrecipient);

        this.setState({
            currentrecipient : null,
            selectedgroup: selectedgroup.set('recipients', recipients)
        });
    }

    removeRecipient(index) {
        let { selectedgroup} = this.state;

        let recipients = selectedgroup.get('recipients', Immutable.List([]));
        recipients = recipients.delete(index);

        this.setState({
            selectedgroup: selectedgroup.set('recipients', recipients)
        });
    }

    getPointGroups() {
        let {customer} = this.props;

        getGroups(`${customer.get('name').toLowerCase().replace(/ /g,"")}`,
                  `${customer.get('ipaddress').toLowerCase()}`,'pointgroups').then((data) => {
            this.setState({
                groups: Immutable.fromJS(data)
            });    
        });
    }

    render() {
        let { onClose } = this.props;
        let { selectedgroup, currentrecipient, subsystemlist, groups } = this.state;
        let NotificationTypes = ['Email', 'SMS'];
        let SegregationTypes = ['All Alarms', 'All Points', 'Group Points', 'Subsystem Point Alarms'];

        return (
            <DialogContent className="text-left">
                <DialogHeader>
                    <DialogTitle>Alarm Group Setup</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-16 gap-2">
                        <div className="col-span-16 md:col-span-6">
                            <Input label="Name" value={selectedgroup.get('notificationgroupname')} onChange={this.changeInput.bind(this, ['notificationgroupname'], undefined)} />
                        </div>   
                        <div className="col-span-16 md:col-span-6">
                            <Select label="Notification Type"
                                value={selectedgroup.get('notificationtype')}
                                onChange={this.changeInput.bind(this, ['notificationtype'], parseInt)}
                                options={[{ name: "Select the type.", groupeid: -1 }, ...NotificationTypes.map((type,index) => {
                                    return {
                                        text: type,
                                        value: index + 1
                                    }
                                })]
                                }
                            />
                        </div>
                        <div className="col-span-16 md:col-span-4 mt-3">
                            <Checkbox className="mt-4" label="Disabled" checked={selectedgroup.get(['disabled'], false)} onChange={this.changeInput.bind(this, ['disabled'])}/>
                        </div>
                    </div>
                    <div className="grid grid-cols-16">    
                        <div className="col-span-16 md:col-span-6">
                            <Input label="Site Name Header" value={selectedgroup.get('sitenameheader')} onChange={this.changeInput.bind(this, ['sitenameheader'], undefined)} />
                        </div>
                    </div>
                    <div className="grid grid-cols-16 gap-2">    
                        <div className="col-span-16 md:col-span-6">
                            <Input label="Sub Header (Optional)" value={selectedgroup.get('optionalheader')} onChange={this.changeInput.bind(this, ['optionalheader'], undefined)} />
                        </div>
                        <div className="col-span-16 md:col-span-6">
                            <Input label="Trailer (Optional)" value={selectedgroup.get('optionaltrailer')} onChange={this.changeInput.bind(this, ['optionaltrailer'], undefined)} />
                        </div>
                    </div>
                    <div className="grid grid-cols-16 gap-2">     
                        <div className="col-span-16 md:col-span-6">
                            <Select label="Segregation Type"
                                value={selectedgroup.get('segregationtype')}
                                onChange={this.changeInput.bind(this, ['segregationtype'], parseInt)}
                                options={SegregationTypes.map((type,index) => {
                                    return {
                                        text: type,
                                        value: index
                                    }
                                })}
                            />
                        </div>
                        {selectedgroup.get('segregationtype') == 2 &&
                            <div className="col-span-16 md:col-span-6">
                                <Select label="Point Group"
                                    value={selectedgroup.get('pointgroupid')}
                                    onChange={this.changeInput.bind(this, ['pointgroupid'], undefined)}
                                    options={[{ text: "Select the group...", value: -1}, ...groups.map((group) => {
                                        return {
                                            text: group.get('name'),
                                            value: group.get('_id')
                                        }
                                    })]}
                                />
                            </div>}
                    </div>
                    <div className="grid grid-cols-16 gap-2">
                        {selectedgroup.get('segregationtype') == 3 &&
                            (subsystemlist || []).map((subsystem, index) => {
                                return ( <div className="col-span-16 md:col-span-4 mt-3">
                                        <Checkbox className="mt-4" label={subsystem.get('name')} checked={subsystem.get('enabled', false)} onChange={this.changeSubsystemEnable.bind(this, index)}/>
                                    </div>
                                )})   
                        }
                     </div>
                    <div className="grid grid-cols-16 mt-4" >
                        <div className="col-span-16 md:col-span-6">
                            <label className="block label">Recipients</label>
                        </div>
                    </div>
                    {selectedgroup.get('recipients', []).map((recipient, index) => {
                        return (
                            <div className="grid grid-cols-16 gap-2" key={index}>
                                <div className="col-span-12 md:col-span-6">
                                    <Input
                                        onChange={this.currentValueChanged.bind(this)}
                                        value={recipient}
                                    />
                                </div>
                                <div className="col-span-12 md:col-span-2">
                                    <Button role="danger" className="w-100" onClick={this.removeRecipient.bind(this, index)}>Remove</Button>
                                </div>
                            </div>
                        )})}
                    <div className="grid grid-cols-16 gap-2">
                        <div className="col-span-12 md:col-span-6">
                            <Input
                                onChange={this.currentValueChanged.bind(this)}
                                value={currentrecipient}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <Button role="success" className="w-100" onClick={this.addRecipient.bind(this)}>Add</Button>
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                        <div className="pl-1"><Button role="cancel" onClick={onClose}>Cancel</Button></div>
                        <div className="pl-1"><Button role="success" onClick={this.save.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>
        );
    }
}

export default AlarmGroupSetupDialog;
