import React, {useState} from 'react';
import Checkbox from 'Controls/Form/Checkbox';
import Select from 'Controls/Form/Select';

const TrendingDetails = ({pointconfiguration, changedInput}) => {
    let interval = [1, 5, 10, 15, 30, 60];
    let trendingtypes = ['Continuous', 'Metering kWH', 'Metering Gas', 'Metering Water', 'Metering Heat', 'Metering Oil'];

    let addr = pointconfiguration.get('addr').toArray();

    // console.log('point config ', pointconfiguration);
    let enable = pointconfiguration.getIn(['historysetup','enabled'], false) ? true: false;
    let type = pointconfiguration.getIn(['historysetup','type'], 0 );
   
    return(
        <div className="flex flex-col md:flex-row px-8 py-4 min-h-full">
            <div className="grid grid-cols-12 gap-4">
                { !(addr[1] == 5 && addr[2] == 13) &&
                    <React.Fragment>
                        <div className="col-span-12">
                            <Checkbox label="Enable Trending" onChange={(value) => changedInput(['historysetup', 'enabled'], undefined, value ? 1 : 0)} checked={enable} />
                        </div>
                        <div className="col-span-4 mt-4">
                            Type
                        </div> 
                        <div className="col-span-8 mt-4">
                            <Select
                                disabled={!enable}
                                value={pointconfiguration.getIn(['historysetup','type'], 1)}
                                onChange={(value) => changedInput(['historysetup','type'], undefined, value)}
                                options={trendingtypes.map((type, index) => {
                                    return {
                                        value: index + 1,
                                        text: type
                                    }
                                })}
                            />
                        </div>    
                        <React.Fragment>
                        <div className="col-span-4">
                            Interval (Mins)
                        </div>
                        <div className="col-span-8">
                        <Select
                            disabled={!enable || type > 1}
                            value={pointconfiguration.getIn(['historysetup','interval'], 15)}
                            onChange={(value) => changedInput(['historysetup','interval'], parseInt, value)}
                            options={interval.map((time) => {
                                return {
                                    value: time,
                                    text: time
                                }
                            })}
                        />
                    </div>
                    </React.Fragment> 
                    <div className="col-span-4">
                            <Checkbox disabled={!enable} label="Add to CSV File" onChange={(value) => changedInput(['historysetup', 'csvfile'], undefined, value ? 1 : 0)} checked={pointconfiguration.getIn(['historysetup', 'csvfile'])} />
                            </div>  
                            <div className="col-span-8"/>
                    </React.Fragment>    }
            </div>
        </div>
    )
}

export default TrendingDetails;