


import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useRevalidator } from 'react-router-dom';
import { fetchCurrentUser } from 'Actions/Authentication';
import { routerDataService, useBMS } from '../BmsView/Context/BMS';
import debuggerService from 'Services/DebuggerService';


const logger = debuggerService.getDebugger('InactivityDetector');

export  function useInactivityDetector(currentUser) {
    
    const [inactivity, setInactivity] = useStateIfMounted(false);
    const revalidator = useRevalidator();
    const { setLoginExpired } = useBMS();

    const onLocalLoggedOut = () => {
        setLoginExpired(() => true);
        routerDataService.resetRouterData();
        revalidator.revalidate();
    }

    const checkSessionExpiration = async () => {
        try {
            let user = await fetchCurrentUser();
        } catch(e) {
            if(e?.response?.status == 403) {
                onLocalLoggedOut();
            }
        }
    }

    useEffect(() => {
        if(currentUser && Number(currentUser.get('sessionTimeout'))) {

            let sessionTimeout = currentUser.get('sessionTimeout');
            
            const onVisibilityChange = async () => {
                if(document.visibilityState === "visible") {
                    checkSessionExpiration();
                    logger.log("Page became visible");
                    // revalidator.revalidate();
                }
            }

            
            
            let inactivityTimeout = setTimeout(() => {
                logger.log("timeout was set")
                setInactivity(true);
            }, Number(sessionTimeout) * 60 * 1000);
    
            const resetInactivity = () => {
                clearTimeout(inactivityTimeout);
                inactivityTimeout = setTimeout(() => {
                    logger.log("timeout was set")
                    setInactivity(true);
                }, Number(sessionTimeout) * 60 * 1000);
                setInactivity((x) => false);
            }

    
            window.addEventListener("mousemove", resetInactivity);
            window.addEventListener("keydown", resetInactivity);
            window.addEventListener("scroll", resetInactivity);
            document.addEventListener("visibilitychange", onVisibilityChange);
    
            return () => {
                window.removeEventListener("mousemove", resetInactivity);
                window.removeEventListener("keydown", resetInactivity);
                window.removeEventListener("scroll", resetInactivity);
                document.removeEventListener("visibilitychange", onVisibilityChange);
            }
        }
        
        
    }, [currentUser])

    useEffect(() => {

        if(!inactivity && currentUser) {
            logger.log("Element just became active");
            (async () => {
                try {
                    let user = await fetchCurrentUser();
                } catch(e) {
                    if(e?.response?.status == 403) {
                        onLocalLoggedOut();
                    }
                }
            })();

            logger.log("inactvity is false");
        }

        if(inactivity) {
            logger.log("Element just became inactive");
            checkSessionExpiration();
        }

    }, [inactivity])

    return true
}