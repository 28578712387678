import React from 'react';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
import ModuleTypes from 'Configuration/ModuleTypes';
import Select from 'Controls/Form/Select';
import Input from 'Controls/Form/Input';
import Checkbox from 'Controls/Form/Checkbox';
import Grid from 'Controls/GridV2';
import {getSerialPorts, saveSerialPort, deleteSerialPort} from '../Actions';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import SerialPortSetupDialog from './SerialPortSetupDialog';

function SubsystemEditDialog({ selecteditem, onClose, ...props}) {
    return <Dialog opened={!!selecteditem} onClose={onClose} className="">
        <SubsystemEditDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class SubsystemEditDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedsubsystem: this.props.selecteditem,
            serialports: undefined
        }

        // props.setSaveHandler(this.save.bind(this));
    }

    componentDidMount() {
        this.getSerialPorts();
    }

    getSerialPorts() {
        let {customer} = this.props;
    
        getSerialPorts(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`).then((data) => {
                this.setState({
                    serialports: Immutable.fromJS(data)
                });
            });
    }

    save() {
        let { selectedsubsystem } = this.state;
        let { onSave } = this.props;

        if (!selectedsubsystem.get('local', false)) {
            selectedsubsystem = selectedsubsystem.delete('autostart').delete('ipaddress').delete('ipport').delete('signalR');
        }

        onSave(selectedsubsystem);
    }

    changeInput(field, transform, value) {
        let { selectedsubsystem } = this.state;

        if (transform) {
            value = transform(value);
        }

        /*
        if (field == "nohistory" && !value ) {
            this.setState({
                selectedsubsystem: selectedsubsystem.delete('historysubtouse').delete('nohistory')
            });

            return;
        }*/

        this.setState({
            selectedsubsystem: selectedsubsystem.setIn(field, value)
        });
    }

    getHistoriesType() {
        let {selectedsubsystem} = this.state;

        if (selectedsubsystem.get('nohistory')) {
            return "Disabled";
        }

        if (selectedsubsystem.get('historysubtouse') != undefined) {
            return "Proxy";
        }

        else return "Enabled";
    }

    changeHistoriesType(value) {
        let {selectedsubsystem} = this.state;

        switch(value) {
            case "Proxy": 
                this.setState({selectedsubsystem: selectedsubsystem.set('historysubtouse',99).delete('nohistory')})
                break;
            case "Disabled":
                this.setState({selectedsubsystem: selectedsubsystem.set('nohistory',true).delete('historysubtouse')})
                break;
            default:
                this.setState({selectedsubsystem: selectedsubsystem.delete('historysubtouse').delete('nohistory')});
        }
    }

    selectPort(port) {
        this.setState({
            selectedPort: port
        }); 
    }

    addPort() {
        let {selectedsubsystem} = this.state;

        if ( !selectedsubsystem.get('number') ) {
            Swal.fire("Error", "Please enter a subsystem number first", "error");
            return; }
       
        this.setState({
            selectedPort: Immutable.Map({'subsystem':selectedsubsystem.get('number'), 'databits':8, 'baudrate':19200, 'parity':0, 'stopbits':0})
        }); 
    }

    removePort(port) {
        let {customer} = this.props;
      
        deleteSerialPort(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, port.get('_id')).then(() => {
                Swal.fire("Success", "The port has been succesfully removed.", "success");
                this.getSerialPorts();              
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while removing the port, please contact the administrator.", "error");
            }) 
    }

    saveItem(item) {
        let {customer} = this.props;
        let {serialports} = this.state;

        if ( item.get('serialportnumber') == 0 ) {
            Swal.fire("Error", "Invalid Serial Port", "error");  
            return; 
        }

        let serialport = serialports.find((port) => { return port.get('serialportnumber') == item.get('serialportnumber') });

        if (serialport && !item.get('_id') || (serialport && item.get('_id') && (serialport.get('serialportnumber') != item.get('serialportnumber')))) {
            Swal.fire("Error", "Serial Port already used", "error"); 
            return;
        }

        saveSerialPort(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, item).then(() => {
                Swal.fire("Success", "The port has been succesfully saved.", "success");
                this.getSerialPorts();              
            }).catch((err) => {
                Swal.fire('Error', "An error occurred while saving the port, please contact the administrator.", "error");
            }) 
    }    

    clearDialog() {
        this.setState({
            selectedPort: undefined
        });
    }


    render() {
        let {selectedsubsystem, serialports, selectedPort, modbustype, proxyTrending} = this.state;
        let { onClose } = this.props;
        let validModule = (module) => {return module.pointtypes.length > 0;}

        const ThrottleValue = [5,10,15,20];
        const parity = ['None','Odd','Even'];
        const stopBits = ['0','1','2','1.5'];
        const modbustypes = ['IP', 'Serial'];

        const historyTypes = ["Enabled", "Disabled", "Proxy"];

        return (
            <DialogContent className="md:w-[500px] lg:w-[800px] xl:w-[1140px]">
                
                { selectedPort && 
                <SerialPortSetupDialog selecteditem={selectedPort} selectedPort={selectedPort} savePort={this.saveItem.bind(this)} onClose={this.clearDialog.bind(this)}></SerialPortSetupDialog> }
                <DialogHeader>
                    <DialogTitle>Subsystem Setup</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="container-fluid">
                        <div className="grid grid-cols-16 gap-2">
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Name" value={selectedsubsystem.get('name')} onChange={this.changeInput.bind(this, ['name'], undefined)} />
                            </div>
                            <div className="col-span-16 md:col-span-4">
                                <Select label="Type"
                                    value={selectedsubsystem.get('type')}
                                    onChange={this.changeInput.bind(this, ['type'], parseInt)}
                                    options={[{ name: "Select the type.", moduleid: -1 }, ...ModuleTypes.filter(validModule)].map((subsystem) => {
                                        return {
                                            text: subsystem.name,
                                            value: subsystem.moduleid
                                        }
                                    })}
                                />
                            </div>
                            <div className="col-span-16 md:col-span-4">
                                <Input label="Subsystem Number" value={selectedsubsystem.get('number', '') + ''} onChange={this.changeInput.bind(this, ['number'], parseInt)} />
                            </div>
                        </div>
                        <div className="grid grid-cols-16 my-4">
                            <div className="col-span-16 md:col-span-4">
                                <Checkbox label="Local Subsystem" checked={selectedsubsystem.get('local')} onChange={this.changeInput.bind(this, ['local'], undefined)} />
                            </div>
                            {selectedsubsystem.get('local', false) &&
                                <div className="col-span-16 md:col-span-4">
                                    <Checkbox label="Auto Start" checked={selectedsubsystem.get('autostart', false)} onChange={this.changeInput.bind(this, ['autostart'], undefined)} />
                                </div>
                            }    
                        </div>
                        {selectedsubsystem.get('local', false) && selectedsubsystem.get('type') != 14 && 
                            <React.Fragment>
                                <div className="grid grid-cols-16 my-4 gap-2">    
                                    <div className="col-span-16 md:col-span-4">
                                        <Input label="Controller IP Address" value={selectedsubsystem.get('ipaddress')} onChange={this.changeInput.bind(this, ['ipaddress'], undefined)} minlength="7" maxlength="15" size="15" pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" />
                                    </div>
                                    <div className="col-span-16 md:col-span-4">
                                        <Input label={selectedsubsystem.get('type') == 6 ? "Local Controller" : "Port Number"} value={selectedsubsystem.get('ipport')} onChange={this.changeInput.bind(this, ['ipport'], parseInt)} minlength="5" maxlength="5" size="5" />
                                    </div>
                                </div>    
                            </React.Fragment>
                        }  
                        {selectedsubsystem.get('type') == 14 && 
                            <React.Fragment>
                            <div className="grid grid-cols-16 my-4">     
                                <div className="col-span-16 md:col-span-4">
                                    <Select label="Modbus Type"
                                        value={selectedsubsystem.get('modbustype', 0)}
                                        onChange={this.changeInput.bind(this, ['modbustype'], parseInt)}
                                        options={modbustypes.map((type, index) => {
                                        return {
                                            text:type,
                                            value:index }
                                    })}/>
                                </div>
                            </div>
                            </React.Fragment>
                        }  
                        {selectedsubsystem.get('type') == 14 && !selectedsubsystem.get('modbustype', 0) &&
                            <React.Fragment>
                                <div className="grid grid-cols-16 my-4 gap-2">    
                                    <div className="col-span-16 md:col-span-4">
                                        <Input label="Controller IP Address" value={selectedsubsystem.get('ipaddress')} onChange={this.changeInput.bind(this, ['ipaddress'], undefined)} minlength="7" maxlength="15" size="15" pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" />
                                    </div>
                                    <div className="col-span-16 md:col-span-4">
                                        <Input label="Port Number" value={selectedsubsystem.get('ipport')} onChange={this.changeInput.bind(this, ['ipport'], parseInt)} minlength="5" maxlength="5" size="5" />
                                    </div>
                                </div>    
                            </React.Fragment>
                        } 
                        {selectedsubsystem.get('type') == 14 && (selectedsubsystem.get('modbustype', 0) == 1) && 
                            <React.Fragment>
                                <Grid className="my-4">
                                    <Grid.Header>
                                        <Grid.Column classes="col-span-16 md:col-span-2">Serial Port</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">Speed</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">Data Bits</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2">Parity</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-4">Stop Bits</Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2" name="edit"></Grid.Column>
                                        <Grid.Column classes="col-span-16 md:col-span-2" name="delete" type="complex">
                                            <Button role="success" className="w-100" onClick={this.addPort.bind(this)}>Add</Button>
                                        </Grid.Column>
                                    </Grid.Header>
                                    <Grid.Data>
                                        {(serialports || []).filter((serialport) => {return serialport.get('subsystem') == selectedsubsystem.get('number')}).map((port) => {
                                            return (
                                                <Grid.Row className="hover:bg-green-lighter">
                                                    <Grid.Column classes="col-span-16 md:col-span-2">{port.get('serialportnumber')}</Grid.Column>
                                                    <Grid.Column classes="col-span-16 md:col-span-2">{port.get('baudrate')}</Grid.Column>
                                                    <Grid.Column classes="col-span-16 md:col-span-2">{port.get('databits')}</Grid.Column>
                                                    <Grid.Column classes="col-span-16 md:col-span-2">{parity[port.get('parity')]}</Grid.Column>
                                                    <Grid.Column classes="col-span-16 md:col-span-4">{stopBits[port.get('stopbits')]}</Grid.Column>
                                                    <Grid.Column classes="col-span-16 md:col-span-2">
                                                        <Button role="primary" className="w-100" onClick={this.selectPort.bind(this, port)}>Edit</Button>
                                                    </Grid.Column>
                                                    <Grid.Column classes="col-span-16 md:col-span-2">
                                                        <Button role="danger" className="w-100" onClick={this.removePort.bind(this, port)}>Remove</Button>
                                                    </Grid.Column>                                                
                                                </Grid.Row>
                                            );
                                        })}
                                    </Grid.Data>
                                </Grid>    
                            </React.Fragment>
                        }
                        {selectedsubsystem.get('local', false) && 
                            <React.Fragment>
                            <div className="grid grid-cols-16">
                                <div className="col-span-16 md:col-span-4">
                                    <Input label="Service Bus IP Address" value={selectedsubsystem.get('signalR')} onChange={this.changeInput.bind(this, ['signalR'], undefined)} minlength="7" maxlength="15" size="15" pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" />
                                </div>
                            </div>
                            <div className="grid grid-cols-16 my-4">
                                <div className="col-span-16 md:col-span-4">
                                    <Checkbox label="Detached Mode" checked={selectedsubsystem.get('detach')} onChange={this.changeInput.bind(this, ['detach'], undefined)} />
                                </div>
                                <div className="col-span-16 md:col-span-4">
                                    <Checkbox label="Debug Enabled" checked={selectedsubsystem.get('trace', false)} onChange={this.changeInput.bind(this, ['trace'], undefined)} />
                                </div>
                            </div> 
                            {(selectedsubsystem.get('type') === 5 ) && 
                            <div className="grid grid-cols-16">
                                <div className="col-span-16 md:col-span-4">
                                    <Select label="Messages Per Second"
                                        value={selectedsubsystem.get('throttle', 10)}
                                        onChange={this.changeInput.bind(this, ['throttle'], parseInt)}
                                        options={[ ...ThrottleValue.map((value) => {
                                            return {
                                                text: value,
                                                value: value
                                            }
                                        })]}
                                    />
                                </div>
                            </div>}
                            </React.Fragment>    
                        }
                        {(selectedsubsystem.get('type') === 5 ) && 
                            <React.Fragment>
                                <div className="md:w-1/4">
                                    <Input label="PIN" value={selectedsubsystem.get('pin')} onChange={this.changeInput.bind(this, ['pin'], parseInt)} minlength="4" maxlength="4" size="4" />
                                </div>
                                <div className="grid grid-cols-16">
                                    <div className="col-span-16 md:col-span-4 my-4">
                                        <Select label="Histories"
                                            value={this.getHistoriesType()}
                                            onChange={this.changeHistoriesType.bind(this)}                           
                                            options={historyTypes.map((type) => {
                                                return {
                                                    text:type,
                                                    value:type
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="col-span-16 md:col-span-4 my-4"> 
                                        {this.getHistoriesType() == "Proxy" &&  
                                            <Input label="Histories Subsystem Number" type="number" value={selectedsubsystem.get('historysubtouse')} onChange={this.changeInput.bind(this, ['historysubtouse'], parseInt)} />}
                                    </div>    
                                </div>
                            </React.Fragment>
                        }    
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.save.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>
        );
    }
}

export default SubsystemEditDialog;
