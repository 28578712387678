import React, { useState, useEffect } from 'react';
import WeekTimesDialog from './WeekTimesDialog';
import DayTimesDialog from './DayTimes/DayTimesVerticalDialog';
import { Map, List, fromJS } from 'immutable';
import { getTimeTemplates, saveTimeTemplate, deleteTimeTemplate } from './Actions';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/solid';
import Swal from 'sweetalert2';
import Grid from 'Controls/GridV2';

export function DayTemplateSelection({ onChange, className, customer, value, showWeek = false, showAllTemplates = false }) {

    // debugger;

    const [templates, setTemplates] = useState();
    
    const customerUrlName = (customer || Map()).get('name', '').toLowerCase().replace(/ /g, "");
    const customerIpAddress = (customer || Map()).get('ipaddress').toLowerCase();

    let typeFilter = showWeek ? 'week' : 'day';

    let v = "none";
    if(value && templates) {
        // console.log("value", value);
        v = templates.indexOf(value);
        
        if(v < 0) {
            v = templates.findIndex(x => x.get('_id') == value.get('_id'));
        }

        // console.log("value of v", v);
    }

    

    useEffect(() => {
        getTimeTemplates(customerUrlName, customerIpAddress).then((data) => {
            if(showAllTemplates) {
                setTemplates(fromJS(data));
                
            } else {
                setTemplates(fromJS(data).filter(x => x.get('type') == typeFilter));
            }
           
        })
    }, [customerUrlName, customerIpAddress])
    
    const onSelect = (e) => {
        // console.log("onSelect e", e.target.value);
        let value = e.target.value;
        if(value == "none") {
            value = null;
            return onChange(null);
        }
        // console.log("selection:", templates.get(value));
        onChange(templates.get(value))
    }

    // console.log("templates", templates);
    // console.log("value", value);

    return <select
        value={v}
        name="daytemplate"
        onChange={onSelect}
        className={`mt-4 border block pl-3 pr-20 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
    >
        <option value="none"></option>
        {(templates || List()).map((template, index) => {
            return (
                <option  value={index}>{template.get('name')}</option>
            )}
        )}
    </select>


}

function TimeTemplates({ customer }) {

    const [dayTemplate, setDayTemplate] = useState(null);
    const [weekTemplate, setWeekTemplate] = useState(null);
    const [templates, setTemplates] = useState(List());

    // console.log("customer", customer);

    const customerUrlName = (customer || Map()).get('name', '').toLowerCase().replace(/ /g, "");
    const customerIpAddress = (customer || Map()).get('ipaddress').toLowerCase();

    useEffect(() => {
        getTimeTemplates(customerUrlName, customerIpAddress).then((data) => {
            setTemplates(fromJS(data));
        })
    }, [customerUrlName, customerIpAddress])

    const removeTemplate = async (template) => {
        await deleteTimeTemplate(customerUrlName, customerIpAddress, template);
        let data = await getTimeTemplates(customerUrlName, customerIpAddress);
        setTemplates(fromJS(data));
        setWeekTemplate(null);
        setDayTemplate(null);
    }

    const saveTemplate = async (template) => {

        // check for duplicates
        let selectedTemplate = weekTemplate || dayTemplate;
        let otherTemplates = templates.filter(x => x.get('_id') != selectedTemplate.get('_id'));
        let otherTemplateNames = otherTemplates.map(x => x.get('name'));
        if(otherTemplateNames.indexOf(template.get('name')) > -1) {
            return Swal.fire('The name has already been used', 'You need to specify a unique name for this template', 'error');
        } else {
            await saveTimeTemplate(customerUrlName, customerIpAddress, template);
            let data = await getTimeTemplates(customerUrlName, customerIpAddress);
            setTemplates(fromJS(data));
            setWeekTemplate(null);
            setDayTemplate(null);
        }
    }

    const selectTemplate = (template) => {
        if(template.get('type') == "week") {
            setWeekTemplate(template);
        } else {
            setDayTemplate(template);
        }
    }

    return <div>
        <WeekTimesDialog customer={customer} isOpened={!!weekTemplate} onSave={(template) => saveTemplate(template) } template={weekTemplate} onClose={() => setWeekTemplate(null)} />
        <DayTimesDialog isOpened={!!dayTemplate} onSave={(template) => saveTemplate(template) } template={dayTemplate} onClose={() => setDayTemplate(null)} />
        <div className="container">
            <Grid>
                <Grid.Header>
                    <Grid.Column classes="col-span-16 md:col-span-6">Name</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-6">Type</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-4" name="add-buttons" type="complex">
                        <Grid.Controls>
                            <Grid.Control>
                                <div className="flex px-2 hover:bg-blue-700 bg-gray-600 rounded-md  cursor-pointer" onClick={() => selectTemplate(Map({ type: "day" }))} >
                                    <PlusIcon className=" w-5 text-white cursor-pointer" />
                                    <div className="">Day</div>
                                </div>
                            </Grid.Control>
                            <Grid.Control>
                                <div className="flex px-2 hover:bg-blue-700 bg-gray-600 rounded-md  cursor-pointer" onClick={() => selectTemplate(Map({ type: "week" }))} >
                                    <PlusIcon className=" w-5 text-white cursor-pointer" />
                                    <div className="">Week</div>
                                </div>
                            </Grid.Control>
                        </Grid.Controls>
                    </Grid.Column>
                </Grid.Header>
                <Grid.Data>
                    {templates.map((template) => {
                        return <Grid.Row className="hover:bg-gray-200 group" key={template.get('_id').toString()}>
                            <Grid.Column classes="col-span-16 md:col-span-6">{template.get('name')}</Grid.Column>
                            <Grid.Column classes="col-span-16 md:col-span-6">{template.get('type')}</Grid.Column>
                            <Grid.Column>
                                <Grid.Controls>
                                    <Grid.Control><PencilSquareIcon className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-blue-500 w-6" onClick={() => selectTemplate(template)} /></Grid.Control>
                                    <Grid.Control><TrashIcon className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 " onClick={() => removeTemplate(template)} /></Grid.Control> 
                                </Grid.Controls>
                            </Grid.Column>
                        </Grid.Row>
                    })}
                </Grid.Data>
            </Grid>
        </div>
    </div>

}




export default TimeTemplates;