import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import Immutable, { set } from "immutable";
import { useStateIfMounted } from "use-state-if-mounted";
import { useSelector } from "react-redux";
import { useNavigation, useRevalidator } from "react-router-dom";
import debuggerService from 'Services/DebuggerService';


const logger = debuggerService.getDebugger('BMS Context');

export const REVALIDATE_ALL = 0;
export const REVALIDATE_MAIN = 1;
export const REVALIDATE_CUSTOMER = 2;
export const REVALIDATE_SITE = 3;
export const REVALIDATE_AREA = 4;

class RouterDataService {


    constructor() {
        this.routerData = {};
        this.routerPreparations = {};
        this.routerPreparationsResolve = {};
        this.revalidationSignal = 0;
    }

    resetRouterData() {
        // console.log("ROUTERDATESERVICE RESET")
        this.routerData = {};
        this.routerPreparations = {};
        this.routerPreparationsResolve = {};
    }

    registerRouterData(key, data) {
        this.routerData[key] = data;
    }

    revalidationSignalPresent() {
        return this.revalidationSignal != 0;
    }

    setRevalidationSignal(value) {
        // console.log("1001 revalidation signal set to", value);
        this.revalidationSignal = value;
    }

    shouldRevalidate(value) {
        // console.log("1001 should revalidate?", value, this.revalidationSignal)
        if(value >= this.revalidationSignal) {
            // console.log("1001 should revalidate", value, this.revalidationSignal)
            return true;
        }
        return false;
    }

    has(key) {
        // console.log("ROUTERDATESERVICE HAS", key, this.routerData[key] !== undefined)
        return this.routerData[key] !== undefined;
    }

    prepare(key) {
      
        const preparation = new Promise((resolve, reject) => {
            this.routerPreparationsResolve[key] = resolve;
        });
        this.routerPreparations[key] = preparation;
       

    }

    async get(key) {
        // console.log("ROUTERDATESERVICE GET", key, this.routerData[key]);
        if(this.routerPreparations[key]) {
            // console.log("awaiting", key);
            await this.routerPreparations[key];
            // console.log("returning resolved", key);
        }
        return this.routerData[key];
    }

    set(key, value) {
        // console.log("ROUTERDATESERVICE SET", key, value);
        this.routerData[key] = value;
        if(this.routerPreparationsResolve[key]) {
            this.routerPreparationsResolve[key](value);
        }
      
        
    }
}



export const routerDataService = new RouterDataService();

window.routerDataService = routerDataService;

const BMSContext = React.createContext({});

export const BMSProvider = BMSContext.Provider;

export function useBMS() {
    return useContext(BMSContext);
}

export function withBMS(Component) {

    // console.log("withBMS", Component);

    return props => {
        const bmsProps = useBMS();
        // console.log("bmsProps", bmsProps);
        return <Component {...bmsProps} {...props} />;
    };
}

export default function BMSWrapper({ children }) {
    const [loggedIn, setLoggedIn] = useStateIfMounted(false);
    const [customer, setCustomer] = useStateIfMounted();
    const [customers, setCustomers] = useStateIfMounted();
    const [currentUser, setCurrentUser] = useStateIfMounted();
    const [site, setSite] = useStateIfMounted();
    const [customerGroups, setCustomerGroups] = useStateIfMounted();
    const [params, setParams] = useStateIfMounted();
    const [loginExpired, setLoginExpired] = useStateIfMounted(false);

    // const revalidator = useRevalidator();

    useEffect(() => {

        logger.log("change detected in loginExpired", loginExpired);

        if(loginExpired) {
            setLoggedIn(() => false)
            logger.log("login expired")
            const timeout = () => {
                setLoginExpired(false);
            }

            setTimeout(timeout, 10000);
            return () => {
                clearTimeout(timeout);
            }
            
        }

    }, [loginExpired])

    const revalidateAll = () => {
        routerDataService.setRevalidationSignal(REVALIDATE_ALL);
    }

    const revalidateSite = () => {
        routerDataService.setRevalidationSignal(REVALIDATE_SITE);
    }

    const revalidateCustomer = () => {
        routerDataService.setRevalidationSignal(REVALIDATE_CUSTOMER);
    }

    const revalidateCustomers = () => {
        routerDataService.setRevalidationSignal(REVALIDATE_MAIN);
    }

    const revalidateArea = () => {
        routerDataService.setRevalidationSignal(REVALIDATE_AREA);
    }

    

    const signalLoaded = async () => {
        let customers = await routerDataService.get("customers");
        let currentUser = await routerDataService.get("currentUser");
        let site = await routerDataService.get("site");
        let customerGroups = await routerDataService.get("customerGroups");
        let customer = await routerDataService.get("customer");
        let params = await routerDataService.get("params");

        // console.log("111 signal loaded site", site?.get('name'))
        // console.log("111 signal loaded params", params);


        setCustomers(customers);
        setCurrentUser(currentUser);
        setSite(site);
        setCustomerGroups(customerGroups);
        setCustomer(customer);
        setParams(params);

        if(currentUser) {
            setLoggedIn(() => true);
        }

        routerDataService.setRevalidationSignal(REVALIDATE_ALL);
        // console.log("%c 1001 reset signalLoaded", 'color:pink')
    }


    const value = {
        customer,
        setCustomer,
        customers,
        setCustomers,
        currentUser,
        setCurrentUser,
        site,
        setSite,
        customerGroups,
        setCustomerGroups,
        signalLoaded,
        revalidateSite,
        revalidateArea,
        revalidateAll,
        setLoginExpired,
        loginExpired,
        loggedIn,
        setLoggedIn,
        params
    };

    // console.log("%c 111 Rendering BMSWrapper", "color: yellow", value)

    return (
        <BMSProvider value={value}>
            {children}
        </BMSProvider>
    );
}

