import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';

class Input extends React.Component {
    constructor() {
        super();
        this.state = { value: "" }
    }

    componentDidMount(){
        let {value} = this.props;

        this.setState({
            innerValue: value
        });
    }

    componentDidUpdate(oldProps) {
        if (this.props.value != oldProps.value) {
            this.setState({
                innerValue: this.props.value
            });
        }
    }

    handleKeyPress(e) {
        let { onEnter } = this.props;
        if (e.key === 'Enter' && onEnter) {
            onEnter(e.target.value)
        }
    }

    handleChange(e) {
        let { onChange } = this.props;

        if (this.props.type == 'number' && e.target.value == '') return;
        
        if (onChange) {
            onChange(e.target.value);
        }
        this.setState({ innerValue: e.target.value })
    }

    handleDelete() {
        let { onChange } = this.props;

        if (onChange) {
            onChange(null);
        }
        this.setState({ innerValue: '' });
    }

    handleFocus(e) {
        let { onFocus } = this.props;

        if (onFocus) {
            onFocus(e.target.value);
        }
    }

    handleBlur(e) {
        let { onBlur } = this.props;
        if (onBlur) {
            onBlur(e.target.value);
        }
    }

    render() {
        let { className, containerClassName = "", error, label, onChange, onEnter, onBlur, value, noMargin, showDeleteControl, ...others } = this.props;
        let { innerValue } = this.state;

        if (this.props.type == 'number' && innerValue == 0) innerValue = '0';

        let standardClass = "";
        let focussedClass = "focus:border-[#80bdff] focus:outline-0 focus:shadow-[0_0_0_0.2rem_rgba(0,123,255,0.25)]"

        if(showDeleteControl) {
            return <InputWithDeleteControl 
                onBlur={this.handleBlur.bind(this)} 
                onFocus={this.handleFocus.bind(this)} 
                onChange={this.handleChange.bind(this)} 
                onKeyPress={this.handleKeyPress.bind(this)} 
                onDelete={this.handleDelete.bind(this)}
                error={error}
                innerValue={innerValue}
                label={label}
                containerClassName={containerClassName} 
                className={className}
                {...others} />
        }

        return (
            <div className={`mb-4 text-left ${containerClassName}`}>
                {label && <label className={`inline-block mb-2`}>{label}</label>}
                <input className={`w-full h-[calc(1.5em + 0.75rem + 2px)] py-1.5 px-3 rounded border border-[#ced4da] ${focussedClass} ${className} ${error ? `error` : ``}`} value={innerValue || ""} onBlur={this.handleBlur.bind(this)} onFocus={this.handleFocus.bind(this)} onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} id={label} {...others} />
                {error && <small className="text-xs text-[#dc3545] mt-1 block">{error}</small>}
                
            </div>
        )
    }
}
export default Input;

function InputWithDeleteControl({ containerClassName, label, focussedClass, className, error, innerValue, onBlur, onChange, onKeyPress, onFocus, onDelete, ...others}) {

    return <div className={`mb-4 relative ${containerClassName}`}>
        {label && <label className={`inline-block mb-2`}>{label}</label>}
        <div className={`flex flex-row items-center w-full h-[calc(1.5em + 0.75rem + 2px)] rounded border border-[#ced4da] ${focussedClass} ${className} ${error ? `error` : ``}`}>
            <input 
                className={`h-full w-full py-1.5 px-3 `} 
                value={innerValue || ""} 
                onBlur={onBlur} 
                onFocus={onFocus} 
                onChange={onChange} 
                onKeyPress={onKeyPress} 
                id={label} {...others} />
            <div className="p-1 cursor-pointer" onClick={onDelete}>
                <XMarkIcon className="h-5 w-5 text-red-500" />
            </div>
        </div>
        {error && <small className="text-xs text-[#dc3545] mt-1 block">{error}</small>}
    </div>
}

// used text-xs for the small element, was formatted on font-size: 80%