import React from 'react';
import PointSelectionDialog from './PointSelectionDialog';
import Input from 'Controls/Form/Input';
import {connect} from 'react-redux';
import {searchPoints} from 'BmsView/Customer/Site/Actions';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';
import { PointDetailsPanel } from './PointDetailsPopup';
import { withBMS } from '../../../../Context/BMS';
import { withAreaCustomer } from '../Hooks/useAreaCustomer';

class PointControl extends React.Component {
    
    showPointDialog(){
        let {customer} = this.props;

        if ( !customer.get('subsystems') ) {
            Swal.fire("Error", "No points available", "error");
            return;
        }

        this.setState({
            dialogshowing: true
        });
    }

    changePoint(point){
        let {onChange} = this.props;

        if (onChange){
            onChange(point.get('addr').join('-'), point.get('name'));
        }
        this.hideDialog();
    }

    hideDialog(){
        this.setState({
            dialogshowing: false
        });
    }

    componentDidMount(){
        let {name, value} = this.props;

        if (!name && value){
            this.getPointName(value);
        }


        // This is invalid, i also see the state isn't used anyway so i commented it out.
        // this.state = {
        //     showPointDetails: false
        // }
    }

    componentDidUpdate(oldProps){
        if (oldProps.value != this.props.value && this.props.value){
            this.getPointName(this.props.value);            
        }
    }

    async getPointName(addr){
        let {customer} = this.props;
        
        let addrarray = addr.split('-').map((byte) => {
            return parseInt(byte);
        });

        if (addrarray[1] == 5 && addrarray[2] == 13 && addrarray[4] != 0) {
            addrarray[4] = 0;
        }

        let customername = customer.get('name').replace(/ /g, "").toLowerCase();
        let ipaddress = customer.get('ipaddress').toLowerCase();
        let points = await searchPoints(customername, ipaddress, {addr: addrarray});

        if (points.length > 0){
            let firstpoint = points[0];
            this.setState({
                pointname: firstpoint.name
            });
        }
    }


    render() {
        let {dialogshowing, pointname} = this.state || {};
        let {customer, value, onRemove} = this.props;

        return (
            <React.Fragment>
                <PointSelectionDialog selecteditem={dialogshowing} onClose={this.hideDialog.bind(this)} onSelect={this.changePoint.bind(this)} displayPointEditModal={true}/>
            
                <div className="grid grid-cols-16 gap-2">
                    {value &&
                        <div className="col-span-16 md:col-span-5 relative group">

                            <div className="absolute invisible z-10 top-[100%] group-hover:visible group-hover:opacity-100 transition ease-in-out duration-500 opacity-0 bg-white text-black px-4 mb-3 py-2 text-sm right-0 rounded-md border shadow-md ease-in duration-300">  
                                <PointDetailsPanel customer={customer}  value={value}  />
                                
                            </div>

                            <Input
                                label="Bound point"
                                value={value}
                                disabled={true}
                            />
                        </div>
                    }
                    {value && pointname &&
                        <div className="col-span-16 md:col-span-5">
                            <Input
                                label="Pointname"
                                value={pointname}
                                disabled={true}
                            />
                        </div>
                    }
                    <div className="col-span-16 md:col-span-4 mt-8 flex">											
                        <div className="pl-1">
                            <Button
                                role="primary" onClick={this.showPointDialog.bind(this)}
                                >{value && "Change point" || "Configure point"}</Button>	
                        </div>
                        <div className="pl-1">
                            {value && <Button role="secondary" onClick={onRemove}>Remove point</Button>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

// export default connect((state) => ({
//     customer: state.get('customer')
// }))(PointControl);

// export default withBMS(PointControl);
export default withAreaCustomer(PointControl);