import * as d3 from "d3";
import { displayImage, displayTooltip } from "../Utils";
import ComponentCache from '../ComponentCache';

export default function SystemObject(options, path) {
    return new Promise((resolve, reject) => {
        ComponentCache.getXml(path || `/files/editor/components-v2_${options.node.getIn(['component', 'name'])}.svg`).then((xml) => {
            let image = d3.select(xml.documentElement).select('#layer1');

            let placementElement = displayImage(options, image);
            let tooltip = displayTooltip(placementElement, options);

            resolve({
                setContextMenu: (editmode) => {
                    options.editmode = editmode;
                },
                getElement: () => {
                    return placementElement;
                },
                setOptions: (newOptions) => {
                    options = {...options, ...newOptions}
                },
                redrawElement: (node, isSelected) => {
                    placementElement.remove();
                    options.node = node;
                    placementElement = displayImage(options, image);
                    tooltip = displayTooltip(placementElement, options);
                    if(isSelected) {
                        placementElement.attr('class', 'node-selected');
                    }
                },
                remove: () => {
                    placementElement.remove();
                    image.remove();
                },
                resetIndex: (index) => {
                    options.index = index;
                    placementElement.attr('data-index', options.index);
                }
            });
        });
    });
}