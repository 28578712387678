import React, {useState, useEffect} from 'react';
import Immutable from 'immutable';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import {getDatabaseRecords, deleteRecord} from './../Actions';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';

const DatabaseMaintenance = ({customer}) => {
    const [records, setRecords] = useState();

    useEffect(() => {
        getRecords();
        return () => {      
        };
    },[]);

    const getRecords = () => { 
        let query = {}; 
 
        getDatabaseRecords('areas', `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, query).then((data) => {
                let records = Immutable.fromJS(data.map((record) => {
                    return record;}
                ));

                setRecords(records);
            })
    }

    const selectRecord = (evt, record) => {

    }

    const removeRecord = (evt, record) => {
        let id = record.get('_id');

        deleteRecord('areas', `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, id).then(() => {
                Swal.fire("Success", "The record has been removed successfully", "success");
                getRecords();
            }).catch((err) => {
                Swal.fire("Error", "Error removing record", "error");
            });
    }    



    return (
        <div className="container mx-auto my-4">
            <h2>Database Maintenance</h2>
                <div className="grid grid-cols-16">
                    <div className="col-span-16 md:col-span-4">
                        <Input className="" disabled={true} label="Customer" value={customer.get('name')} />
                    </div>
                    
                </div>
            <h4>Record List</h4>
            <Grid>
                <Grid.Header>
                    <Grid.Column classes="col-span-16 md:col-span-6">Id</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-2">Path Id</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-2">Last Updated</Grid.Column>
                    <Grid.Column classes="col-span-16 md:col-span-6" name="edit"></Grid.Column>
                </Grid.Header>
                <Grid.Data>
                    {(records || []).map((record, index) => {
                        return (
                            <Grid.Row key={index} className="hover:bg-gray-300 group">
                                <Grid.Column classes="col-span-16 md:col-span-6">{record.get('_id')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2">{record.get('pathid')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2">{record.get('lastupdated')}</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-6">
                                    <Grid.Controls>
                                        <Grid.Control><PencilSquareIcon title="Edit" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue w-6" onClick={(evt) => selectRecord(evt, record)} /></Grid.Control>
                                        <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 w-6" onClick={(evt) => removeRecord(evt, record)} /></Grid.Control> 
                                    </Grid.Controls>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    })}
                </Grid.Data>
            </Grid>
        </div>
    )
}

export default DatabaseMaintenance;