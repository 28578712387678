import * as d3 from 'd3';
import { displayTooltip } from '../Utils';




export default function Line(options) {
    return new Promise((resolve, reject) => {

        let appendTo = d3.select(options.target).append('g').attr('data-index', options.index);
        let createPlacementElement = (options) => appendTo.append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacementElement(options);    
        
        let createLine = (placementElement, options) => {
            let angle = options.node.getIn(['configuration', 'angle'],0) * (Math.PI / 180);
            var rads = Math.abs(Math.sin(angle));
            var hyp =  options.node.getIn(['configuration', 'length'], 400);       
            var y2 = rads * hyp;
            rads = Math.abs(Math.cos(angle));
            var x2 = rads * hyp;
        
            if (options.node.getIn(['configuration', 'angle'],1.85) > 90) {
                x2 = -x2;
            }

            let p = drawLine(placementElement, 0, 0, x2, y2, 
                options.node.getIn(['configuration', 'strokecolour'], 'black'),
                options.node.getIn(['configuration', 'strokewidth'], 2) );
          
            return p;
        }

        let line = createLine(placementElement, options);
        let tooltip = displayTooltip(placementElement, options);
        
        resolve({
            draw: (target) => {
                
            },
            remove: () => {
                d3.select(placementElement).remove();
                appendTo && appendTo.remove();
            },
            getElement: () => {
                return placementElement;
            },

            redrawElement: (node, isSelected) => {
                options.node = node;
                placementElement.remove();
                appendTo && appendTo.remove();
                appendTo = d3.select(options.target).append('g').attr('data-index', options.index);
                placementElement = createPlacementElement(options);
                line = createLine(placementElement, options);
                tooltip = displayTooltip(placementElement, options);
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}






function drawLine(element, x1, y1, x2, y2, strokecolour, strokewidth ) {

    let newnode = element.append('line')
    .attr('x1', x1)
    .attr('y1', y1)
    .attr('x2', x2)
    .attr('y2', y2)
    .attr('stroke', strokecolour)
    .attr('fill', strokecolour)
    .attr('stroke-width', strokewidth)

    return newnode;
}