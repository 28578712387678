import React, { useEffect, useState } from 'react';
import TransitioningDialog from "../../../../../Controls/Dialog/TransitioningDialog";
import Button from 'Controls/Button/UserDefined';
import { Dialog } from "@headlessui/react";
import WeekTimes from './WeekTimes';
import { Map, List } from 'immutable';
import Input from 'Controls/Form/Input';
import { DayTemplateSelection } from '.';
import Swal from 'sweetalert2';
import {  ColorPickerField } from './DayTimes/DayTimesVerticalDialog';

function WeekTimesDialog({ isOpened, onClose, onSave, template = Map(), customer }) {

    const [templateName, setTemplateName] = useState("");
    const [periods, setPeriods] = useState(List());
    const [copiedPeriods, setCopiedPeriods] = useState(List());
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [isSelectedDayTemplate, setIsSelectedDayTemplate] = useState(false);
    const [defaultColor, setDefaultColor] = useState((template || Map()).get('defaultcolor') || '#0088cc');

    useEffect(() => {
        setTemplateName((template || Map()).get('name'), "");
        setPeriods((template || Map()).get('periods', List()))
    }, [template])

    const onApplyDayTemplate = (template) => {
        if(template) {
            let periods = template.get('periods', List());
            let periodsToAdd = List();
            let dow = isSelectedDayTemplate - 1;
            
            periods.forEach((period) => {
                periodsToAdd = periodsToAdd.push(Map({
                    starttime: timeIndexToString60(period.get('startindex')),
                    endtime: timeIndexToString60(period.get('endindex')),
                    dow: dow
                }))
            })

            setPeriods((periods) => {
                periods = periods.map(x => { 
                    if(x.get('dow') == dow) {
                        x = x.set('isDeleted', true)
                    }
                    return x;
                });

                periods = periods.merge(periodsToAdd);
                return periods;
                
            });

        }
        setIsSelectedDayTemplate(null);
    }
    
    const onCloseChooseDayTemplate = () => {
        setIsSelectedDayTemplate(false);
    }


    const onRangeSelected = (range) => {
        setPeriods((periods) => { 
            range.forEach((p) => {
                periods = removeCollidingElements(p, periods)
            })
           
            periods = periods.merge(range) 
            return periods;
        });
    }

    const onChangeEvent = (event, changes) => {
        // console.log("changed an event");
        setPeriods((periods) => {
            let index = periods.indexOf(event);
            periods = periods.updateIn([index], x => x.merge(changes));
            return periods;
        });
    }

    const onRemove = (event) => {
    
        setPeriods((periods) => {
            let index = periods.indexOf(event);
            return periods.deleteIn([index]);  
        })
    }

    const onRemoveMultiple = (toRemove) => {
        setPeriods((periods) => {
            let newPeriods = periods;
            toRemove.forEach((event) => {
                let index = newPeriods.indexOf(event);
                // console.log("index of old elment found to be ", index);
                if(index > -1) {
                    newPeriods = newPeriods.updateIn([index], x => x.set('isDeleted', true));
                }
            })
            // console.log("should have set this as periods", newPeriods);
            return newPeriods
        });
    }

    const onModifyRequested = (event) => {
        let index = periods.indexOf(event);
        setSelectedPeriod(index);
    }

    const getIndexFromTime = (time) => {
        let [startHours, startMinutes] = time.split(':');
        return Number(startHours) * 60 + Number(startMinutes);
    }

    const removeCollidingElements = (event, periods) => {

        return periods.map((x) => {
            if(event == x) {
                return x;
            }

            let currentStart = getIndexFromTime(event.get('starttime'));
            let currentEnd = getIndexFromTime(event.get('endtime'));
            let comparingStart = getIndexFromTime(x.get('starttime'));
            let comparingEnd = getIndexFromTime(x.get('endtime'));


            let sameDay = x.get('dow') == event.get('dow');
            let startIsInOtherEventRange = comparingStart >= currentStart && comparingStart < currentEnd;
            let endIsInOtherEventRange = comparingEnd < currentEnd && comparingEnd > currentStart;
            if(sameDay && (startIsInOtherEventRange || endIsInOtherEventRange)) {
                return x.set('isDeleted', true);
            }
            return x; 
        })

    }

    const savePeriod = (period, secondPart) => {
        setPeriods((periods) => {
            periods = periods.updateIn([selectedPeriod], x => x.merge(period));
            let originalPeriod = periods.getIn([selectedPeriod]);
            periods = removeCollidingElements(originalPeriod, periods)
            
            if(secondPart) {
                periods = removeCollidingElements(secondPart, periods);
                periods = periods.push(secondPart);
            }
            
            
            return periods;
        });
    }

    const copyDay = (day) => {
        setCopiedPeriods( periods.filter(x => x.get('dow') == day ));
        
    }

    const pasteDay = (day) => {

        if(copiedPeriods) {
            setPeriods((periods) => {
                periods = periods.map(x => {
                    if(x.get('dow') == day) {
                        x = x.set("isDeleted", true);
                        x = x.set("dirty", true);
                    }
                    return x;
                });
                copiedPeriods.forEach((copy) => { 
                    copy = copy.set('dow', day);
                    // copy = copy.set('starttimeindex', (day * 24) + copiedEvent.get('starttimedouble'));
                    copy = copy.delete('id');
                    periods = periods.push(copy)
                })
                return periods;
            })
        }
    }

    const repeatOnAllDays = (day) => {
        setPeriods((periods) => {
            let dayPeriods = periods.filter(x => x.get('dow') == day);
            periods = periods.map((period) => {
                if(period.get('dow') != day) {
                    period = period.set('isDeleted', true);
                    period = period.set('dirty', true);
                }
                return period;
            });
            for(let i = 0; i < 7; i++) {
                if(i != day) {
                    periods = periods.merge(dayPeriods.map((period) => {
                        period = period.set('dow', i);
                        period = period.delete('id');
                        period = period.set('dirty', true);
                        return period;
                    }))
                }
            }
            return periods;
        })
    }

    const setTimezoneToAllDay = (day) => {
      
        setPeriods((periods) => {
            periods = periods.map(period => {
                if(period.get('dow') == day) {
                    period = period.set('isDeleted', true);
                }
                return period;
            })
            periods = periods.push(Map({
                dow: day,
                starttime: "00:00",
                endtime: "24:00"
            }))
            return periods;
        })

        
    }

    const setTimezoneToOffAllDay = (day) => {
        setPeriods((periods) => {
            periods = periods.map(period => {
                if(period.get('dow') == day) {
                    period = period.set('isDeleted', true);
                }
                return period;
            })
            return periods;
        })
    }

    const onDayAction = (day, action) => {
        let dayIndex = day;
        switch(action) {
            case 'onAllDay': setTimezoneToAllDay(dayIndex); break;
            case 'offAllDay': setTimezoneToOffAllDay(dayIndex); break;
            case 'copyDay': copyDay(dayIndex); break;
            case 'pasteDay': pasteDay(dayIndex); break;
            case 'repeatOnAllDays': repeatOnAllDays(dayIndex); break;
            case 'selectTemplate': setIsSelectedDayTemplate(dayIndex + 1); break;
            default:
        }
    }

    const save = () => {
        if(templateName) {
            let localPeriods = periods.filter(x => !x.get('isDeleted'));
            template = template.set('defaultcolor', defaultColor);
            return onSave(template.set('periods', localPeriods).set('name', templateName))
        }
        Swal.fire('Not complete', 'You need to specify a name for this template', 'error');
    }

   

    return (
        <TransitioningDialog panelWidthClass="min-w-[80vw] max-w-[80vw] " onClose={onClose} opened={isOpened}>
            <DayTemplateSelectionDialog customer={customer} isOpened={isSelectedDayTemplate} onSave={onApplyDayTemplate} onClose={onCloseChooseDayTemplate} /> 
            <ModifyPeriodDialog period={periods.get(selectedPeriod)} onSave={(period, secondPeriod) => savePeriod(period, secondPeriod)} onClose={() => setSelectedPeriod(null)} isOpened={(selectedPeriod || selectedPeriod === 0)} />
            <div>
                
                    <div>
                        <h3 className="text-lg font-medium text-gray-900">Week Times Template Setup</h3>
                    </div>
                    <div className="flex w-1/2 item-center mt-4 gap-4">
                        <label htmlFor="templateName" className="block flex-none my-2">
                            Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2 flex-1">
                            <Input value={templateName} onChange={(v) => setTemplateName(v)} />  
                        </div>
                        <div className="flex items-center pb-2">
                            <label className="block px-2">Default event color</label>
                            <ColorPickerField 
                                value={defaultColor} 
                                onChange={(v)=> {setDefaultColor(v)}} />
                        </div>
                    </div>
                    <div className="text-center">
                        <WeekTimes onDayAction={onDayAction} onModifyRequested={onModifyRequested} events={periods.filter(x => !x.get('isDeleted'))} onRemoveMultiple={onRemoveMultiple} onRemove={onRemove} onChangeEvent={onChangeEvent} onRangeSelected={onRangeSelected} />
                    </div>
                
            </div>
            <div className="flex justify-end pt-8">
            <div className="flex-none px-2">
                {/* <button className={greenButtonClassName} onClick={_onSave}>Save</button> */}
                <Button role="save" onClick={() => save()}>Save</Button>
            </div>
            <div className="flex-none">
                {/* <button className={redButtonClassName} onClick={() => onClose()}>Close</button> */}
                <Button role="close" onClick={() => onClose()}>Close</Button>
            </div>
        </div>
        </TransitioningDialog>
    )
}

function timeIndexToString(time) {
    let hours = (time - time % 2) / 2;
    let minutes = time % 2 == 0 ? "00" : "30";
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

function timeIndexToString60(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}



export function DayTemplateSelectionDialog({ isOpened, onSave, onClose, customer, showWeek }) {
    
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const save = () => {
        // console.log("should save ", selectedTemplate);
        onSave(selectedTemplate);
        setSelectedTemplate(null);
    }

    // console.log("selectedTemplate", selectedTemplate);
    return <TransitioningDialog panelWidthClass="max-w-fit" onClose={onClose} opened={!!isOpened}>
        <div  className='container'>
            <div className="w-full m-2">
                <h2>Select Template</h2>              
            </div>
        </div>
        <div className="flex-initial max-w-fit">
            <DayTemplateSelection showWeek={showWeek} value={selectedTemplate} customer={customer} onChange={(template) => setSelectedTemplate(template)} />
        </div>
        <div className="flex p-3 items-center justify-end"> 
            <div className="pl-1"><Button role="secondary" onClick={onClose}>Close</Button></div>
            <div className="pl-1"><Button role="success" onClick={save}>Ok</Button></div>        
        </div>

    </TransitioningDialog>
}

export function ModifyPeriodDialog({ period, onClose, onSave, isOpened, tabindex }) {

    const [startTimeString, setStartTimeString] = useState();
    const [endTimeString, setEndTimeString] = useState();

    useEffect(() => {
        if(period) {
            setStartTimeString(period.get('starttime') || timeIndexToString(period.get('startindex')));
            setEndTimeString(period.get('endtime') || timeIndexToString(period.get('endindex')));
        }
    }, [period])

    const savePeriod = () => {

        let startString = startTimeString;
        let endString = endTimeString;

        if(!(startString && startString.match(/^[0-9]{1,2}\:[0-9]{1,2}$/))) {
            startString = "00:00";
        } 

        if(!(endString && endString.match(/^[0-9]{1,2}\:[0-9]{1,2}$/))) {
            endString = "24:00";
        } 

        let startParts = startString.split(':');
        let startHours = Number(startParts[0]);
        let startMinutes = Number(startParts[1]);
        let startAddition = startMinutes >= 30 ? 1 : 0;
        let hourStart = startHours * 2;
        hourStart += startAddition;

        let endParts = endString.split(':');
        let endHours = Number(endParts[0]);
        let endMinutes = Number(endParts[1]);
        let endAddition = endMinutes > 30 ? 2 : endMinutes > 0 ? 1 : 0;
        let hourEnd = endHours * 2;
        hourEnd += endAddition;


        

        let adjustedPeriodFirstPart = period.set('starttime', startString).set('endtime', endString).set('startindex', hourStart).set('endindex', hourEnd)
        // console.log("adjustedPeriodFirstPart before correct", adjustedPeriodFirstPart);
        
        let adjustedPeriodSecondPart = null;
        if(hourEnd <= hourStart) {

            adjustedPeriodFirstPart = adjustedPeriodFirstPart.set('endtime', "24:00").set('endindex', 48);
            // console.log("adjustedPeriodFirstPart after correction", adjustedPeriodFirstPart);
            adjustedPeriodSecondPart = period.update('dow', x => (x + 1) % 7).set('starttime', '00:00').set('endtime', endString).set('startindex', 0).set('endindex', hourEnd)
            // console.log("adjustedPeriodSecondPart after addition", adjustedPeriodSecondPart);
        } 


        onSave(adjustedPeriodFirstPart, adjustedPeriodSecondPart);
        onClose();
    }

    return  <TransitioningDialog panelWidthClass="max-w-fit" onClose={onClose} opened={!!isOpened}>

        <div>
            <div>
                <h3 className="text-lg font-medium text-gray-900">Adjust time</h3>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label htmlFor="templateName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Start time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Input value={startTimeString} onChange={(v) => setStartTimeString(v)} />  
                </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                <label htmlFor="templateName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    End time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Input value={endTimeString} onChange={(v) => setEndTimeString(v)} />  
                </div>
            </div>
        </div>

        <div className="mt-5 sm:mt-6">
            <div className="grid grid-cols-2 gap-6">
                
                    <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        onClick={() => onClose()}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                        onClick={() => savePeriod()}
                    >
                        Save
                    </button>
                
            </div>
        </div>
    </TransitioningDialog>


}

export default WeekTimesDialog;