import React, { useState, Fragment, useRef } from 'react';
import { Map, List } from 'immutable';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function EventLabel({ label, onChangeEvent, onRemove, event, onModifyRequested, onChange, showLabelInBubble, bubbleOnTop }) {

    let defaultClassName = "relative z-1 inline-flex p-2";
    let defaultButtonClassName = "border-gray-300 bg-white text-gray-700 hover:bg-gray-50";
    let buttonLeftPartClassName = "";
    let buttonRightPartClassName = "";
    if(showLabelInBubble) {
        buttonLeftPartClassName = "rounded-l-lg pl-3";
        buttonRightPartClassName = "rounded-r-lg pr-3";
        defaultButtonClassName = "border-black bg-black text-white";
        let bubbleLocation = bubbleOnTop ? "-top-1 -translate-y-full" : "-bottom-1 translate-y-full";
        defaultClassName =  `absolute  w-max left-[50%] -translate-x-[50%] z-10 inline-flex ${bubbleLocation}`;
    }

    return (
        <div className={defaultClassName} onMouseDown={(e) => e.stopPropagation(e)}>
            <button
                type="button"
                className={`relative inline-flex items-center px-1 py-1 border text-sm font-medium ${defaultButtonClassName} ${buttonLeftPartClassName} focus:z-10 focus:outline-none `}
            >
                {label}
            </button>
        <Menu as="div" className="-ml-px relative block flex">
            <Menu.Button className={`relative inline-flex items-center pr-1 py-1 border-b border-t border-r text-sm font-medium ${defaultButtonClassName} ${buttonRightPartClassName} focus:z-10 focus:outline-none`}>
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="origin-top-right z-[11] absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    {/* <Menu.Item>
                        {({ active }) => (
                            <a
                                onClick={() => onChangeEvent("off")}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Set off
                            </a>
                        )}
                    </Menu.Item> */}
                    {/* <Menu.Item>
                        {({ active }) => (
                            <a
                                onClick={() => onChangeEvent("on")}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Set on
                            </a>
                        )}
                    </Menu.Item> */}
                    <Menu.Item>
                        {({ active }) => (
                            <a
                                onClick={() => onChange("endtime", "24:00")}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                On for the rest of the day
                            </a>
                        )}
                    </Menu.Item>
                    { onModifyRequested && <Menu.Item>
                        {({ active }) => (
                            <a
                                onClick={() => onModifyRequested()}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Modify
                            </a>
                        )}
                    </Menu.Item> }
                    { onRemove && <Menu.Item>
                        {({ active }) => (
                            <a
                                onClick={() => onRemove()}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Remove
                            </a>
                        )}
                    </Menu.Item> }
                </div>
            </Menu.Items>
            </Transition>
        </Menu>
        </div>
    )
}