import axios from 'axios';
import Configuration from 'Configuration';

export function getDeviceTypes() {
    return new Promise((resolve, reject) => {
        axios.get('/files/common/ipdevicetypes.json').then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function getAddresses(customer, connection) {
    return new Promise((resolve,reject) => {
        axios.get(`/api/iptables?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
            resolve(response.data);
        }).catch(reject);
    });
}

export function saveAddress(customer, connection, address) {
    return new Promise((resolve,reject) => {
        if (address.get('_id')) {
            axios.put(`/api/iptables/${address.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`, address.toJS()).then(resolve).catch(reject);
        }
        else {
            axios.post(`/api/iptables?database=${Configuration.product}${customer}&connection=${connection}`, address.toJS()).then(resolve).catch(reject);
        }
    });
}

export function deleteAddress(customer, connection, address) {
    return new Promise((resolve,reject) => {
        if (address.get('_id')) {
            axios.delete(`/api/iptables/${address.get('_id')}?database=${Configuration.product}${customer}&connection=${connection}`).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            })
        }
    });
}

